(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name eventanalytics.controller:EventanalyticsCtrl
   *
   * @description
   *
   */
  angular.module('eventanalytics').controller('EventanalyticsCtrl', EventanalyticsCtrl);

  function EventanalyticsCtrl(Api, $log, ApiAnalytic, $stateParams, $state, AnalyticsApi, $interval, $rootScope, $cookies) {
    var vm = this;

    vm.venueid = $stateParams.venueid;
    vm.eventid = $stateParams.eventid;
    vm.loading = true;
    vm.dwellLoading = true;
    vm.showActivityZoneTable = false;
    vm.showImpressionsTable = false;
    vm.showDwellTable = false;
    vm.showLoginTable = false;
    vm.showgenderTable = false;
    vm.showFootfallTable = false;
    vm.showProfileTable = false;
    vm.totalImpressions = 0;
    vm.userProfiles = [];
    var tmpProfiles = [];
    vm.activity = '';
    vm.genderChartColors = ['#2962ff', '#c51162', '#004d40', '#37474f'];
    vm.typeChartColors = [];
    vm.chartColors = [
      '#b71c1c',
      '#1a237e',
      '#f57f17',
      '#004d40',
      '#212121',
      '#880e4f',
      '#bf360c',
      '#1b5e20',
      '#263238',
      '#4a148c',
      '#0277bd',
      '#3e2723',
      '#558b2f',
      '#616161',
      '#311b92',
      '#ffd740',
      '#c6ff00',
      '#607d8b',
      '#d50000',
      '#0288d1',
      '#ffca28',
      '#795548',
      '#cddc39',
      '#757575',
      '#9c27b0',
      '#26c6da',
      '#ff9100',
      '#d4e157',
      '#7c4dff'
    ];

    vm.event = {};
    /* dates */

    var date = new Date();
    vm.today = date;
    vm.starttime = new Date(date.getFullYear(), date.getMonth(), 1);
    vm.endtime = new Date();
    vm.endtime.setHours(23);
    vm.endtime.setMinutes(59);
    vm.endtime.setSeconds(59);

    // user dates
    vm.userStartTime = vm.starttime;
    vm.userEndTime = vm.endtime;
    vm.uniqueUsers = 0;
    vm.dwellStart = vm.starttime;
    vm.dwellEnd = vm.endtime;

    vm.status = {
      openedSt: false,
      openedEnd: false,
      openedJourneyDate: false,
      openedUsrSt: false,
      openedUsrEnd: false
    };

    vm.activityFilter = [{
      'label': 'Online',
      'value': 'true'
    }, {
      'label': 'Offline',
      'value': 'false'
    }, {
      'label': 'All',
      'value': ''
    }];

    vm.dateOptions = {
      formatYear: 'yy',
      startingDay: 0
    };

    vm.dateFormat = 'dd-MMMM-yyyy';

    vm.openUserCalSt = function () {
      vm.status.openedUsrSt = true;
    };

    vm.openUserCalEnd = function () {
      vm.status.openedUsrEnd = true;
    };

    vm.openCalSt = function () {
      vm.status.openedSt = true;
    };

    vm.openCalEnd = function () {
      vm.status.openedEnd = true;
    };

    vm.liveDataChart = {
      labels: [
        '', '', '', '', ''
      ],
      series: [
        'Total', 'Logins', 'Inactive'
      ],
      data: [
        [
          0, 0, 0, 0, 0
        ],
        [
          0, 0, 0, 0, 0
        ],
        [0, 0, 0, 0, 0]
      ],
      datasetOverride: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMin: 0
            }
          }]
        }
      }
    };
    vm.visitorsLoginData = {
      colors: [
        '#45b7cd', '#ff6384', '#ff8e72'
      ],
      labels: [],
      data: [],
      datasetOverride: [{
        label: 'new visitors',
        borderWidth: 1,
        type: 'bar'
      }, {
        label: 'returned visitors',
        borderWidth: 1,
        type: 'bar'
      }, {
        label: 'total visitors',
        borderWidth: 3,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        type: 'line'
      }]
    };

    vm.connectedUsersBarChart = {
      data: [],
      apDAta: [],
      zoneData: [],
      series: [],
      labels: [],
      apLabels: [],
      zoneLabels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMin: 0
            }
          }],
          xAxes: []
        }
      }
    };

    vm.connectedTable = {
      zoneheaders: [
        'zones', 'count'
      ],
      aps: []
    };

    vm.getLiveAnalytics = function () {
      vm.connectedUsersBarChart.series = [];
      vm.connectedTable.zones = [];
      vm.connectedUsersBarChart.data = [];
      vm.connectedUsersBarChart.options.scales.xAxes = [];

      ApiAnalytic.getClientsOnlineByEvent(vm.venueid, vm.venue.infrastructure.provider, vm.eventid).then(function (response) {
        var expireDate = new Date((new Date()).getTime() + 10 * 60000);
        $cookies.put('unifitoken', response.data.cookie, {
          'expires': expireDate
        });
        /* Live User Totals */
        vm.liveLoading = false;
        vm.liveDataChart.data[0].push(response.data.totalUsers);
        vm.liveDataChart.data[1].push(response.data.logins);
        vm.liveDataChart.data[2].push(response.data.unauthorized);
        vm.liveDataChart.data[0].shift();
        vm.liveDataChart.data[1].shift();
        vm.liveDataChart.data[2].shift();

        vm.totalUsers = response.data.totalUsers;
        vm.logins = response.data.logins;
        vm.unauthorized = response.data.unauthorized;
        /* Live User Totals */
        var dataLength = response.data.zones.length;
        for (var item = 0; item < dataLength; item++) {
          var newAp = 0;
          var value = response.data.zones[item].count;
          newAp = [value];
          var name = response.data.zones[item].name;
          vm.connectedUsersBarChart.series.push(name);
          vm.connectedUsersBarChart.data.push(newAp);
          vm.connectedTable.zones.push({
            name: name,
            count: value
          });
        }

        if (vm.connectedUsersBarChart.data.length <= 1) {
          vm.connectedUsersBarChart.options.scales.xAxes.push({
            barPercentage: 0.3
          });
        } else {
          vm.connectedUsersBarChart.options.scales.xAxes = [];
        }
      }).catch(function (error) {
        if (error.data.message === 'This Provider is Not Supported') {
          vm.liveData = false;
        }
      });
    };

    var getLiveUsers = function () {
      ApiAnalytic.getOnlineUsersEvent(vm.venueid, vm.eventid, vm.venue.infrastructure.provider).then(function (result) {
        vm.liveUsers = result.data;
        if (vm.userProfiles.length <= 0) {
          var thisStart = vm.starttime;
          var thisEnd = vm.endtime;
          thisStart.setHours(0);
          thisStart.setMinutes(0);
          thisStart.setSeconds(0);
          thisEnd.setHours(23);
          thisEnd.setMinutes(59);
          thisEnd.setSeconds(59);
          getUserProfiles(thisStart.getTime(), thisEnd.getTime());
        }
      }, function (err) {
        console.log(err);
      });
    };

    var isLive = function (macarray) {
      var length = macarray.length;
      for (var i = 0; i < length; i++) {
        if (vm.liveUsers.indexOf(macarray[i]) >= 0) {
          return 'true';
        }
      }
      return 'false';
    };

    var intervalPromise;

    vm.startInterval = function () {
      vm.stopInterval();
      intervalPromise = $interval(function () {
        vm.setLive();
      }, 30000);
    };

    vm.stopInterval = function () {
      $interval.cancel(intervalPromise);
    };

    $rootScope.$on('$stateChangeStart', function () {
      vm.stopInterval();
      return;
    });

    var refreshLive = function () {
      if (tmpProfiles.length > 0) {
        vm.userProfiles = tmpProfiles.map(function (user) {
          user.isLive = isLive(user.usermac);
          return user;
        });
      } else {
        var thisStart = vm.starttime;
        var thisEnd = vm.endtime;
        thisStart.setHours(0);
        thisStart.setMinutes(0);
        thisStart.setSeconds(0);
        thisEnd.setHours(23);
        thisEnd.setMinutes(59);
        thisEnd.setSeconds(59);
        getUserProfiles(thisStart.getTime(), thisEnd.getTime());
      }
    };

    vm.setLive = function () {
      vm.getLiveAnalytics();
      getLiveUsers();
      refreshLive();
      vm.startInterval();
    };

    vm.footfallTable = {
      headers: [
        '', 'New Visitors', 'Returning Visitors', 'Total'
      ],
      rows: []
    };

    vm.getAllNewReturningVis = function (start, end) {
      ApiAnalytic.getFootFallByEvent(vm.eventid, start, end).success(function (data) {
        // console.log('footfall'); console.log(data);
        vm.footfallTable.rows = [];

        vm.mixedChartOptions = {
          scales: {
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: data.timeScale
              }
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true,
                suggestedMin: 0
              }
            }]
          }
        };

        var rowItem = {};

        if (data.timeScale === 'Weeks') {
          var labels = [];
          data.labels.shift();
          data.newVisitors.shift();
          data.returningVisitors.shift();
          data.allVisitors.shift();
          for (var i = 0; i < data.labels.length; i++) {
            rowItem = {
              type: '',
              newVisitors: 0,
              returningVisitors: 0,
              total: 0
            };
            labels[i] = 'Week ' + data.labels[i];
            rowItem.type = 'Week ' + data.labels[i];
            rowItem.newVisitors = data.newVisitors[i];
            rowItem.returningVisitors = data.returningVisitors[i];
            rowItem.total = data.allVisitors[i];
            vm.footfallTable.rows.push(rowItem);
          }
          vm.visitorsLoginData.labels = labels;
          vm.visitorsLoginData.data = [data.newVisitors, data.returningVisitors, data.allVisitors];
        } else if (data.timeScale === 'Hours') {
          var hourLabels = [];
          var now = new Date();
          var offset = (now.getTimezoneOffset() * -1) / 60;
          for (var k = 0; k < offset; k++) {
            data.newVisitors.unshift(data.newVisitors[data.newVisitors.length - 1]);
            data.returningVisitors.unshift(data.returningVisitors[data.returningVisitors.length - 1]);
            data.allVisitors.unshift(data.allVisitors[data.allVisitors.length - 1]);
            data.newVisitors.pop();
            data.returningVisitors.pop();
            data.allVisitors.pop();
          }
          for (var j = 0; j < data.labels.length; j++) {
            hourLabels[j] = data.labels[j] + ':00';
            rowItem = {
              type: '',
              newVisitors: 0,
              returningVisitors: 0,
              total: 0
            };
            rowItem.type = data.labels[j] + ':00';
            rowItem.newVisitors = data.newVisitors[j];
            rowItem.returningVisitors = data.returningVisitors[j];
            rowItem.total = data.allVisitors[j];
            vm.footfallTable.rows.push(rowItem);
          }

          vm.visitorsLoginData.labels = hourLabels;
          vm.visitorsLoginData.data = [data.newVisitors, data.returningVisitors, data.allVisitors];
        } else if (data.timeScale === 'Days') {
          // data.labels.shift(); data.newVisitors.shift();
          // data.returningVisitors.shift(); data.allVisitors.shift();
          var dayLabels = [];
          var monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ];

          for (var z = 0; z < data.labels.length; z++) {
            rowItem = {
              type: '',
              newVisitors: 0,
              returningVisitors: 0,
              total: 0
            };
            var date = new Date((new Date().getYear()), 0);
            date.setDate(data.labels[z]);
            dayLabels.push(date.getDate() + ' ' + monthNames[date.getMonth()]);
            rowItem.type = date.getDate() + ' ' + monthNames[date.getMonth()];
            rowItem.newVisitors = data.newVisitors[z];
            rowItem.returningVisitors = data.returningVisitors[z];
            rowItem.total = data.allVisitors[z];
            vm.footfallTable.rows.push(rowItem);
          }

          vm.visitorsLoginData.labels = dayLabels;
          vm.visitorsLoginData.data = [data.newVisitors, data.returningVisitors, data.allVisitors];
        } else {
          data.labels.shift();
          data.newVisitors.shift();
          data.returningVisitors.shift();
          data.allVisitors.shift();

          for (var b = 0; b < data.labels.length; b++) {
            rowItem = {
              type: '',
              newVisitors: 0,
              returningVisitors: 0,
              total: 0
            };
            rowItem.type = data.labels[b];
            rowItem.newVisitors = data.newVisitors[b];
            rowItem.returningVisitors = data.returningVisitors[b];
            rowItem.total = data.allVisitors[b];
            vm.footfallTable.rows.push(rowItem);
          }

          vm.visitorsLoginData.labels = data.labels;
          vm.visitorsLoginData.data = [data.newVisitors, data.returningVisitors, data.allVisitors];
        }
        vm.loading = false;
      }).error(function () {
        $log.info('get gender error');
      });
    };

    vm.horizontal = {
      data: [],
      series: [],
      labels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: []
        }
      }
    };

    vm.genderBarChart = {
      data: [],
      series: [],
      labels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: []
        }
      }
    };

    vm.splashLoadChart = {
      data: [],
      series: [
        'Impressions', 'Logins', 'Bounces'
      ],
      labels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: []
        }
      }
    };

    vm.getSplashLoadChartData = function (start, end) {
      ApiAnalytic.getImpressionsByEvent(vm.eventid, start, end).then(function successCallback(response) {
        var data = JSON.parse(JSON.stringify(response.data));
        vm.totalImpressions = data.totalImpressions;
        vm.splashLoadChart.data = [];
        vm.splashLoadChart.labels = [];
        var monthNames = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ];
        // Sort Returned Data
        data.impressions.sort(function sortNumber(a, b) {
          return a._id[
            data.type.substring(0, data.type.length - 1)
          ] - b._id[
            data.type.substring(0, data.type.length - 1)
          ];
        });
        data.logins.sort(function sortNumber(a, b) {
          return a._id[
            data.type.substring(0, data.type.length - 1)
          ] - b._id[
            data.type.substring(0, data.type.length - 1)
          ];
        });
        data.bounces.sort(function sortNumber(a, b) {
          return a._id[
            data.type.substring(0, data.type.length - 1)
          ] - b._id[
            data.type.substring(0, data.type.length - 1)
          ];
        });

        // correct the data set
        var impressions = [];
        var logins = [];
        var bounces = [];

        data.impressions.forEach(function (item) {
          var label = item._id[
            data.type.substring(0, data.type.length - 1)
          ];
          if (data.type === 'hours') {
            vm.splashLoadChart.labels.push(label + ':00');
          } else if (data.type === 'days') {
            var date = new Date((new Date().getYear()), 0);
            date.setDate(label);
            vm.splashLoadChart.labels.push(date.getDate() + ' ' + monthNames[date.getMonth()]);
          } else if (data.type === 'months') {
            vm.splashLoadChart.labels.push(monthNames[label - 1]);
          } else if (data.type === 'years') {
            var year = new Date(label, 0).getFullYear();
            vm.splashLoadChart.labels.push('' + year);
          } else {
            vm.splashLoadChart.labels.push(data.type.charAt(0).toUpperCase() + data.type.substring(1, data.type.length - 1) + ' ' + label);
          }
          impressions.push(item.count);
        });
        data.logins.forEach(function (item) {
          logins.push(item.count);
        });
        data.bounces.forEach(function (item) {
          bounces.push(item.count);
        });

        if (data.type === 'hours') {
          impressions.unshift(impressions[impressions.length - 1]);
          logins.unshift(logins[logins.length - 1]);
          bounces.unshift(bounces[bounces.length - 1]);

          impressions.pop();
          logins.pop();
          bounces.pop();
        }

        vm.splashLoadChart.data.push(impressions);
        vm.splashLoadChart.data.push(logins);
        vm.splashLoadChart.data.push(bounces);
      }, function errorCallback(response) {
        console.log(response);
      });
    };

    vm.dwellChart = {
      data: [],
      apData: [],
      zoneData: [],
      series: [],
      labels: [],
      apLabels: [],
      zoneLabels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: []
        }
      }
    };

    vm.dwellTable = {
      zoneheaders: [
        'zones', 'avg dwell time(mins)'
      ],
      zones: []
    };

    vm.getDwellTime = function (start, end) {
      vm.dwellLoading = true;
      vm.dwellTable.zones = [];
      vm.dwellChart.series = [];
      vm.dwellChart.data = [];

      ApiAnalytic.getDwellTimeByEvent(vm.venueid, vm.eventid, start, end).then(function (resp) {
        vm.dwellChart.data = resp.data.zones.map(function (ap) {
          var newAp = 0;
          vm.dwellChart.series.push(ap.name);
          var value = Math.round((ap.avgconnection / 1000) / 60);
          newAp = [value];
          vm.dwellTable.zones.push({
            name: ap.name,
            time: value
          });
          return newAp;
        });
        vm.dwellLoading = false;
      }, function (err) {
        console.log(err);
      });
    };

    vm.refresh = function () {
      vm.loading = true;
      var thisStart = vm.starttime;
      var thisEnd = vm.endtime;
      vm.visitorsLoginData.data = [];
      thisStart.setHours(0);
      thisStart.setMinutes(0);
      thisStart.setSeconds(0);
      thisEnd.setHours(23);
      thisEnd.setMinutes(59);
      thisEnd.setSeconds(59);
      vm.setHistoric(thisStart.getTime(), thisEnd.getTime());
      vm.setUsers(thisStart.getTime(), thisEnd.getTime());
    };

    vm.genderTable = {
      headers: [
        'demographic', 'count'
      ],
      rows: []
    };

    var processGender = function (genderArray) {
      if (genderArray.length <= 1) {
        vm.genderBarChart.options.scales.xAxes.push({
          barPercentage: 0.3
        });
      } else {
        vm.genderBarChart.options.scales.xAxes = [];
      }
      var total = parseInt(genderArray[0].female) + parseInt(genderArray[0].male);
      if (parseInt(genderArray[0].male) > 0) {
        vm.genderMale = Math.round((parseInt(genderArray[0].male) / total) * 100) + '%';
      } else if (parseInt(genderArray[0].male) <= 0 && parseInt(genderArray.female) > 0) {
        vm.genderMale = 0 + '%';
      } else {
        vm.genderMale = 'No Data';
      }

      if (parseInt(genderArray[0].female) > 0) {
        vm.genderFemale = Math.round((parseInt(genderArray[0].female) / total) * 100) + '%';
      } else if (parseInt(genderArray[0].female) <= 0 && parseInt(genderArray[0].male) > 0) {
        vm.genderFemale = 0 + '%';
      } else {
        vm.genderFemale = 'No Data';
      }

      var male = parseInt(genderArray[0].male);
      var female = parseInt(genderArray[0].female);
      var other = parseInt(genderArray[0].other);

      vm.genderBarChart.series = [];
      vm.genderBarChart.data = [];
      vm.genderTable.rows = [];
      var rowMale = {
        type: 'Male',
        count: male
      };
      var rowFemale = {
        type: 'Female',
        count: female
      };
      var rowOther = {
        type: 'Other',
        count: other
      };

      if (male > 0) {
        vm.genderBarChart.series.push('Male');
        vm.genderBarChart.data.push([male]);
        vm.genderTable.rows.push(rowMale);
      }
      if (female > 0) {
        vm.genderBarChart.series.push('Female');
        vm.genderBarChart.data.push([female]);
        vm.genderTable.rows.push(rowFemale);
      }
      if (other > 0) {
        vm.genderBarChart.series.push('Other');
        vm.genderBarChart.data.push([other]);
        vm.genderTable.rows.push(rowOther);
      }

      if (vm.genderBarChart.data.length <= 1) {
        vm.genderBarChart.options.scales.xAxes.push({
          barPercentage: 0.3
        });
      } else {
        vm.genderBarChart.options.scales.xAxes = [];
      }
    };

    vm.loginTable = {
      headers: [
        'type', 'count'
      ],
      rows: []

    };

    var processLoginTypes = function (logins) {
      vm.horizontal.series = [];
      vm.horizontal.data = [];
      vm.loginTable.rows = [];
      for (var x = 0; x < logins.length; x++) {
        var rowItem = {
          type: '',
          count: 0
        };
        vm.horizontal.data.push([parseInt(logins[x].count)]);
        rowItem.count = parseInt(logins[x].count);
        var type = logins[x].logintype;
        if (type === 'anonymous') {
          vm.horizontal.series.push('anonymous');
          rowItem.type = 'anonymous';
        } else {
          vm.horizontal.series.push(type);
          rowItem.type = type;
        }
        vm.loginTable.rows.push(rowItem);
        switch (type) {
          case 'anonymous':
            vm.typeChartColors.push('#455a64');
            break;
          case 'facebook':
            vm.typeChartColors.push('#304ffe');
            break;
          case 'linkedin':
            vm.typeChartColors.push('#1e88e5');
            break;
          case 'twitter':
            vm.typeChartColors.push('#00b0ff');
            break;
          case 'google':
            vm.typeChartColors.push('#d50000');
            break;
          case 'twilio':
            vm.typeChartColors.push('#263238');
            break;
        }
      }
    };

    var setLimits = function (n) {
      if (n <= 100) {
        vm.limits = [
          Math.ceil((n / 5) / 10) * 10,
          Math.ceil((n / 5) / 10) * 10 * 2,
          Math.ceil((n / 5) / 10) * 10 * 3,
          Math.ceil((n / 5) / 10) * 10 * 4,
          'all'
        ];
      } else if (n <= 500) {
        vm.limits = [
          Math.ceil((n / 5) / 50) * 50,
          Math.ceil((n / 5) / 50) * 50 * 2,
          Math.ceil((n / 5) / 50) * 50 * 3,
          Math.ceil((n / 5) / 50) * 50 * 4,
          'all'
        ];
      } else {
        vm.limits = [
          100, Math.ceil((n / 5) / 100) * 100,
          Math.ceil((n / 5) / 100) * 100 * 2,
          Math.ceil((n / 5) / 100) * 100 * 3,
          Math.ceil((n / 5) / 100) * 100 * 4,
          'all'
        ];
      }
      vm.limit = vm.limits[0];
    };

    vm.setLimit = function (activity) {
      console.log('activity: ' + activity);
      vm.activity = activity;
      if (activity === 'true') {
        vm.limit = 'all';
      } else {
        vm.limit = vm.limits[0];
      }
    };

    var getLoginAnalytics = function (start, end) {
      // console.log('called getlogin');
      ApiAnalytic.getLoginAnalyticsEvent(vm.venueid, vm.eventid, start, end).then(function (response) {
        // console.log(response);
        vm.uniqueUsers = response.data.unique;
        vm.totalLogin = response.data.total;
        processGender(response.data.gender);
        processLoginTypes(response.data.loginType);
      });
    };

    vm.setHistoric = function (start, end) {
      vm.getAllNewReturningVis(start, end);
      vm.getSplashLoadChartData(start, end);
      vm.getDwellTime(start, end);
      getLoginAnalytics(start, end);
    };

    var getUserProfiles = function (start, end) {
      ApiAnalytic.getUserProfilesEvent(vm.venueid, vm.eventid, start, end).then(function (response) {
        tmpProfiles = response.data.map(function (user) {
          return user;
        });
        vm.userProfiles = response.data.map(function (user) {
          user.isLive = isLive(user.usermac);
          return user;
        });
        setLimits(response.data.length);
      });
    };

    vm.setUsers = function (start, end) {
      getUserProfiles(start, end);
    };


        vm.getMarketingEmailList = function()
        {
          var emailList = [];

          emailList = vm.userProfiles
          .filter(function (userItem){
            return isNaN(userItem.login)
          })
          .map(function (userItem){
              if(userItem.tag && userItem.tag.length > 0)
              {
                return { Name:userItem.name, Email:userItem.login, Tag:userItem.tag[0]}
              }
              else {
                return { Name:userItem.name, Email:userItem.login}
              }
          })
          return emailList;
        };


        function convertArrayOfObjectsToCSV(args) {
                var result, ctr, keys, columnDelimiter, lineDelimiter, data;

                data = args.data || null;
                if (data == null || !data.length) {
                    return null;
                }

                columnDelimiter = args.columnDelimiter || ',';
                lineDelimiter = args.lineDelimiter || '\n';

                keys = Object.keys(data[0]);

                result = '';
                result += keys.join(columnDelimiter);
                result += lineDelimiter;

                data.forEach(function(item) {
                    ctr = 0;
                    keys.forEach(function(key) {
                        if (ctr > 0) result += columnDelimiter;

                        result += item[key];
                        ctr++;
                    });
                    result += lineDelimiter;
                });

                return result;
        }

        vm.downloadCSV = function()
        {
          var data, filename, link;
          var csv = convertArrayOfObjectsToCSV({
            data: vm.getMarketingEmailList()
          });
          if (csv == null)  return;

          var now = new Date();
          var nowString = now.toISOString().split('.')[0];
          filename = 'export_'+nowString+'.csv';

          var blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});

          if (navigator.msSaveBlob)
          { // IE 10+
            navigator.msSaveBlob(blob, filename)
          }
          else
          {
              var link = document.createElement("a");
              if (link.download !== undefined)
              {

                // feature detection, Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style = "visibility:hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
          }
        }

    vm.getVenue = function () {
      Api.getVenue(vm.venueid).then(function (response) {
        vm.venue = response.data.venue;
        // console.log(vm.venue);
        vm.setLive();
        vm.setHistoric(vm.starttime.getTime(), vm.endtime.getTime());
      });
    };

    vm.getEvent = function () {
      Api.getEvent(vm.eventid, vm.venueid).then(function (response) {
        vm.event = response.data.event;
        if (vm.event.eventstatus === 2) {
          var newStart = new Date(vm.event.starttime);
          var newEnd = new Date(vm.event.endtime);
          newStart.setHours(0);
          newStart.setMinutes(0);
          newStart.setSeconds(0);
          newEnd.setHours(23);
          newEnd.setMinutes(59);
          newEnd.setSeconds(59);
          vm.starttime = newStart;
          vm.endtime = newEnd;
          vm.tab = 'historic';
        }
        // console.log('event'); console.log(response);
      }, function (err) {
        console.log(err);
      });
    };

    vm.switchTab = function (tab) {
      switch (tab) {
        case 'live':
          vm.tab = tab;
          break;
        case 'historic':
          vm.tab = tab;
          break;
        case 'users':
          vm.tab = tab;
          break;
        case 'Segmentation':
          $state.go('admin.tags', {
          'venueid': vm.venueid
        });
          break;
        default:
          vm.tab = 'live';
      }
    };

    vm.setClass = function () {
      // console.log('class'); console.log($stateParams.class);
      if ($stateParams.class) {
        vm.tab = $stateParams.class;
      } else {
        vm.tab = 'live';
      }
    };

    vm.setClass();
    vm.getVenue();
    vm.getEvent();
  }
}());
