<div class="row">
  <div class="col-xs-9 col-sm-6 col-ms-6">
    <div class="panel panel-default panel-form">
      <div class="panel-heading clearfix">
        <h4>Splash Page Type</h4>
      </div>
      <div class="panel-body">
        <select class="form-control" name="splashType" ng-model="eventcreation.splashType" ng-options="type as type.name for type in eventcreation.splashPageTypes" ng-change="eventcreation.setSplashPageType(eventcreation.splashType)">
        </select>
        <div class="panel-body">
          <input type="checkbox" name="marketingOn" ng-model="eventcreation.marketingOn">
          Advertising On
          </input>
        </div>
      </div>
      <div ng-show="eventcreation.splashType <= 0 && eventcreation.stepThree">
        <p class="text-danger">
          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
          <strong> Splash Type is required</strong>
        </p>
      </div>
    </div>
    <div class="panel panel-default panel-form">
      <div class="panel-heading clearfix">
        <h4>Background Image</h4>
      </div>
      <div class="panel-body">
        <div class="form-group">
          <div class="row">
            <div ng-show="eventcreation.bckgrndImgAdded" class="col-md-4 col-md-offset-4">
              <img ng-src="{{staticUrl}}{{eventcreation.bckgrndImg}}" class="img-responsive" ng-if="eventcreation.bckgrndImg"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-danger" ng-click="eventcreation.selectBckgrndImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>
              <button class="btn btn-danger" ng-click="eventcreation.uploadBckgrndImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>
        <div ng-show="!eventcreation.bckgrndImgAdded && eventcreation.stepThree">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Background Image is required</strong>
          </p>
        </div>
      </div>
    </div>

    <div class="panel panel-default panel-form">
      <div class="panel-heading clearfix">
        <h4>Venue Logo</h4>
      </div>
      <div class="panel-body">
        <div class="form-group">
          <div class="row">
            <div ng-show="eventcreation.venueLogoAdded" class="col-md-4 col-md-offset-4">
              <img ng-src="{{staticUrl}}{{eventcreation.venueLogo}}" class="img-responsive" ng-if="eventcreation.venueLogo"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-danger" ng-click="eventcreation.selectLogoImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>
              <button class="btn btn-danger" ng-click="eventcreation.uploadLogoImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>
        <div ng-show="!eventcreation.venueLogoAdded && eventcreation.stepThree">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Venue Logo is required</strong>
          </p>
        </div>
      </div>
    </div>

    <div class="panel panel-default panel-form" ng-show="eventcreation.marketingOn">
      <div class="panel-heading clearfix">
        <h4>Offer Image 1</h4>
      </div>
      <div class="panel-body">
        <div class="form-group">
          <div class="row">
            <div ng-show="eventcreation.offerImage1Added" class="col-md-4 col-md-offset-4">
              <img ng-src="{{staticUrl}}{{eventcreation.offerImage1Uri}}" class="img-responsive" ng-if="eventcreation.offerImage1Uri"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-danger" ng-click="eventcreation.selectOfferImage1()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>
              <button class="btn btn-danger" ng-click="eventcreation.uploadOfferImage1()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              Enter Ad URL: <input type="text" ng-model="eventcreation.offerImage1Link" style="width:100%">
            </div>
          </div>
        </div>
        <div ng-show="!eventcreation.bckgrndImgAdded && eventcreation.stepThree">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Offers Image is required</strong>
          </p>
        </div>
      </div>
    </div>

    <div class="panel panel-default panel-form" ng-show="eventcreation.marketingOn">
      <div class="panel-heading clearfix">
        <h4>Offer Image 2</h4>
      </div>
      <div class="panel-body">
        <div class="form-group">
          <div class="row">
            <div ng-show="eventcreation.offerImage1Added" class="col-md-4 col-md-offset-4">
              <img ng-src="{{staticUrl}}{{eventcreation.offerImage2Uri}}" class="img-responsive" ng-if="eventcreation.offerImage2Uri"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-danger" ng-click="eventcreation.selectOfferImage2()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>
              <button class="btn btn-danger" ng-click="eventcreation.uploadOfferImage2()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              Enter Ad URL: <input type="text" ng-model="eventcreation.offerImage2Link" style="width:100%">
            </div>
          </div>
        </div>
        <div ng-show="!eventcreation.bckgrndImgAdded && eventcreation.stepThree">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Offers Image is required</strong>
          </p>
        </div>
      </div>
    </div>

    <div class="panel panel-default panel-form" ng-show="eventcreation.marketingOn">
      <div class="panel-heading clearfix">
        <h4>Offer Image 3</h4>
      </div>
      <div class="panel-body">
        <div class="form-group">
          <div class="row">
            <div ng-show="eventcreation.offerImage3Added" class="col-md-4 col-md-offset-4">
              <img ng-src="{{staticUrl}}{{eventcreation.offerImage3Uri}}" class="img-responsive" ng-if="eventcreation.offerImage3Uri"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-danger" ng-click="eventcreation.selectOfferImage3()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>
              <button class="btn btn-danger" ng-click="eventcreation.uploadOfferImage3()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              Enter Ad URL: <input type="text" ng-model="eventcreation.offerImage3Link" style="width:100%">
            </div>
          </div>
        </div>
        <div ng-show="!eventcreation.bckgrndImgAdded && eventcreation.stepThree">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Offers Image is required</strong>
          </p>
        </div>
      </div>
    </div>

    <div class="panel panel-default panel-form" ng-show="eventcreation.marketingOn">
      <div class="panel-heading clearfix">
        <h4>Offer Image 4</h4>
      </div>
      <div class="panel-body">
        <div class="form-group">
          <div class="row">
            <div ng-show="eventcreation.offerImage1Added" class="col-md-4 col-md-offset-4">
              <img ng-src="{{staticUrl}}{{eventcreation.offerImage4Uri}}" class="img-responsive" ng-if="eventcreation.offerImage4Uri"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-danger" ng-click="eventcreation.selectOfferImage4()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>
              <button class="btn btn-danger" ng-click="eventcreation.uploadOfferImage4()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              Enter Ad URL: <input type="text" ng-model="eventcreation.offerImage4Link" style="width:100%">
            </div>
          </div>
        </div>
        <div ng-show="!eventcreation.bckgrndImgAdded && eventcreation.stepThree">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Offers Image is required</strong>
          </p>
        </div>
      </div>
    </div>

    <div class="panel panel-default panel-form" ng-show="eventcreation.marketingOn">
      <div class="panel-heading clearfix">
        <h4>Offer Image 5</h4>
      </div>
      <div class="panel-body">
        <div class="form-group">
          <div class="row">
            <div ng-show="eventcreation.offerImage5Added" class="col-md-4 col-md-offset-4">
              <img ng-src="{{staticUrl}}{{eventcreation.offerImage5Uri}}" class="img-responsive" ng-if="eventcreation.offerImage5Uri"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-danger" ng-click="eventcreation.selectOfferImage5()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>
              <button class="btn btn-danger" ng-click="eventcreation.uploadOfferImage5()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              Enter Ad URL: <input type="text" ng-model="eventcreation.offerImage5Link" style="width:100%">
            </div>
          </div>
        </div>
        <div ng-show="!eventcreation.bckgrndImgAdded && eventcreation.stepThree">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Offers Image is required</strong>
          </p>
        </div>
      </div>
    </div>

    <div class="panel panel-default panel-form" ng-show="eventcreation.marketingOn">
      <div class="panel-heading clearfix">
        <h4>Offer Image 6</h4>
      </div>
      <div class="panel-body">
        <div class="form-group">
          <div class="row">
            <div ng-show="eventcreation.offerImage6Added" class="col-md-4 col-md-offset-4">
              <img ng-src="{{staticUrl}}{{eventcreation.offerImage6Uri}}" class="img-responsive" ng-if="eventcreation.offerImage6Uri"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-danger" ng-click="eventcreation.selectOfferImage6()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>
              <button class="btn btn-danger" ng-click="eventcreation.uploadOfferImage6()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              Enter Ad URL: <input type="text" ng-model="eventcreation.offerImage6Link" style="width:100%">
            </div>
          </div>
        </div>
        <div ng-show="!eventcreation.bckgrndImgAdded && eventcreation.stepThree">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Offers Image is required</strong>
          </p>
        </div>
      </div>
    </div>


    <div class="panel panel-default panel-form" ng-show="eventcreation.spSocial">
      <div class="panel-heading clearfix">
        <h4>Social Media Login</h4>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-xs-12">
            <div class="col-sm-3" ng-repeat="social in eventcreation.socialLogins">
              <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;" ng-click="eventcreation.addOpenAuth(social.id)" ng-disabled="social.name ==='Twilio' && !eventcreation.hasTwilio" title="{{social.name}}">
                <div class="content">
                  <span class="badge badgeSocial" ng-show="eventcreation.openAuthList.indexOf(social.id) > -1"><i class="fa fa-check-circle-o fa-2x"></i></span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="row" ng-show="eventcreation.anonymousLogin.name === 'Anonymous'">
          <div class="col-xs-12">
            <h5><input type="checkbox" id="anonymousLogin" name="anonymousLogin" ng-model="eventcreation.anonymousLogin.on" /> Allow anonymous signin.</h5>
          </div>
        </div>
        <div ng-show="eventcreation.openAuthList.length < 1 && eventcreation.stepThree">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Please select social log in</strong>
          </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default panel-form" ng-show="eventcreation.spVideo">
      <div class="panel-heading clearfix">
        <h4>Video Url</h4>
      </div>
      <div class="panel-body">
        <div class="form-group" ng-class="{ 'has-error' : createEvent.videoUrl.$error && eventcreation.stepThree}">
          <input type="text" class="form-control" id="videoUrl" ng-model="eventcreation.newEvent.splashDetails.videoUrl" name="videoUrl" placeholder="eg. http://mysite.com" required>
        </div>
        <div ng-messages="createEvent.videoUrl.$error" ng-show="createEvent.videoUrl.$error && eventcreation.stepThree">
          <p class="text-danger" ng-message="required">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Video Url is required</strong>
          </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default panel-form" ng-show="eventcreation.spAmount">
      <div class="panel-heading clearfix">
        <h4>Amount</h4>
      </div>
      <div class="panel-body">
        <div class="input-group" ng-class="{ 'has-error' : createEvent.amount.$error && eventcreation.stepThree}">
          <div class="input-group-addon">&euro;</div>
          <input type="number" min="0" step="1" class="form-control" id="amount" ng-model="eventcreation.newEvent.splashDetails.amount" name="amount" placeholder="0" required>
        </div>
        <div ng-messages="createEvent.amount.$error" ng-show="createEvent.amount.$error && eventcreation.stepThree">
          <p class="text-danger" ng-message="required">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Amount is required</strong>
          </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default panel-form" ng-show="eventcreation.spAmount">
      <div class="panel-heading clearfix">
        <h4>Duration (hrs)</h4>
      </div>
      <div class="panel-body">
        <div class="input-group" ng-class="{ 'has-error' : createEvent.period.$error && eventcreation.stepThree}">
          <input type="number" min="0" step="1" class="form-control" id="period" ng-model="eventcreation.newEvent.splashDetails.period" name="period" placeholder="0" required>
        </div>
        <div ng-messages="createEvent.amount.$error" ng-show="createEvent.period.$error && eventcreation.stepThree">
          <p class="text-danger" ng-message="required">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Duration is required</strong>
          </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default panel-form" ng-hide="true">
      <div class="panel-heading clearfix">
        <h4>Survey</h4>
      </div>
      <div class="panel-body">
        <div class="form-group" ng-class="{ 'has-error' : eventcreation.stepThree}">
          Survey Enabled
          <input type="checkbox" id="surveyCheckbox" name="surveyCheckbox" ng-model="eventcreation.selectedSurvey" />
        </div>
        <div class="form-group" ng-show="eventcreation.selectedSurvey">
          <div ng-repeat="survey in eventcreation.surveyObjects">
            <div class="panel panel-default panel-form" ng-show="eventcreation.surveyObjects[survey.id].show">
              <div class="panel-heading clearfix">
                <h5>Question:</h5>
                <input type="text" class="form-control" ng-model="eventcreation.surveyObjects[survey.id].survey.question" placeholder="eg. Which event are you on?" required>
              </div>
              <div class="panel-body">
                <div class="form-group">
                  <input type="text" class="form-control" ng-model="eventcreation.surveyObjects[survey.id].survey.answer1" placeholder="Option A">
                  <input type="text" class="form-control" ng-model="eventcreation.surveyObjects[survey.id].survey.answer2" placeholder="Option B">
                  <input type="text" class="form-control" ng-model="eventcreation.surveyObjects[survey.id].survey.answer3" placeholder="Option C">
                  <input type="text" class="form-control" ng-model="eventcreation.surveyObjects[survey.id].survey.answer4" placeholder="Option D">
                </div>
              </div>
            </div>
          </div>
          <div>
            <button ng-if="eventcreation.numberOfQuestions < eventcreation.numberOfQuestionsTotal" ng-click="eventcreation.addQuestion()" class="btn btn-danger"><i class="fa fa-plus" aria-hidden="true"></i></button>
            <button ng-if="eventcreation.numberOfQuestions > 0" ng-click="eventcreation.removeQuestion()" class="btn btn-danger"><i class="fa fa-minus" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
    </div>
    <div class="panel panel-default panel-form" ng-show="eventcreation.spSocial">
      <div class="panel-heading clearfix">
        <h4>Social Media Settings</h4>
      </div>
      <div class="panel-body">
        <div class="form-group" ng-class="{ 'has-error' : eventcreation.stepThree}">
          <h5><input type="checkbox" id="addlikebutton" name="addlikebutton" ng-model="eventcreation.addlikebutton" ng-change="eventcreation.setVenueDetails()"/> Facebook Like Button </h5>
          <div ng-show="eventcreation.addlikebutton">
            <div class="row" style="border-bottom: none; padding: 5px" ng-show="eventcreation.venue.customFbLikeUrl">
              <div class="col-xs-12">
                <div style="margin-top: 5px"><input type="checkbox" id="defaultFbLikeUrl" name="defaultFbLikeUrl" ng-model="eventcreation.defaultFbLikeUrl" ng-change="eventcreation.customFbLike = false;"/> Default - {{eventcreation.venue.customFbLikeUrl}}</div>
              </div>
            </div>
            <div class="row" style="border-bottom: none; padding: 5px">
              <div class="col-xs-4">
                <div style="margin-top: 5px"><input type="checkbox" id="customFbLike" name="customFbLike" ng-model="eventcreation.customFbLike" ng-change="eventcreation.defaultFbLikeUrl = false;"/> Custom.</div>
              </div>
              <div class="col-xs-8" ng-hide="eventcreation.defaultFbLikeUrl">
                <input type="url" class="form-control pull-right" id="customFbLikeUrl" ng-model="eventcreation.newEvent.splashDetails.customFbLikeUrl" name="customFbLikeUrl" placeholder="Custom Facebook Page Url" ng-change="eventcreation.defaultFbLikeUrl = false;">
              </div>
            </div>
            <div class="row" style="border-bottom: none; padding: 5px">
              <div class="col-xs-offset-6 col-xs-6">
                <div ng-show="!createEvent.customFbLikeUrl.$valid && eventcreation.stepThree" class="pull-right">
                  <p class="text-danger">
                    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                    <strong> Custom Url must be a valid url. Example https://www.facebook.com/Liquidedge/</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel panel-default panel-form" ng-show="eventcreation.conference">
      <div class="panel-heading clearfix">
        <h4>Conference Settings</h4>
      </div>
      <div class="panel-body">
        <div class="form-group" ng-class="{ 'has-error' : eventcreation.stepThree}">
          <div >
            <div class="row" style="border-bottom: none; padding: 5px">
              <div class="col-xs-8" >
                <input type="text" class="form-control pull-right" id="conferencepass" ng-model="eventcreation.newEvent.splashDetails.conferencepass" name="conferencepass" placeholder="Conference Passcode">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="panel panel-default panel-form" ng-show="eventcreation.isMagnet">
      <div class="panel-heading clearfix">
        <h4>County Dropdown</h4>
      </div>
      <div class="panel-body">
        <label>
          <input type="checkbox" ng-model="eventcreation.newEvent.splashDetails.countyDropDown" ng-true-value="true" ng-false-value="false"> Include County Dropdown
        </label>
      </div>
    </div> -->
    <div class="panel panel-default panel-form">
      <div class="panel-heading clearfix">
        <h4>Redirection Url</h4>
      </div>
      <div class="panel-body">
        <div class="form-group" ng-class="{ 'has-error' : createEvent.redirection.$error && eventcreation.stepThree}">
          <input type="text" class="form-control" id="redirectionURL" ng-model="eventcreation.newEvent.redirectionURL" name="redirection" placeholder="eg. http://mysite.com" required>
        </div>
        <div ng-messages="createEvent.redirection.$error" ng-show="createEvent.redirection.$error && eventcreation.stepThree">
          <p class="text-danger" ng-message="required">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Redirection Url is required</strong>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-9 col-sm-6 col-md-5 col-md-offset-1">
    <h3 class="text-center">Splash Page</h3>
    <div class="col-md-10 col-md-offset-1">
      <div class="splashBackground" ng-style="{ 'background-image': 'url(' + staticUrl + eventcreation.bckgrndImg + ')' }" ng-if="eventcreation.bckgrndImg">
        <div class="content">
          <div class="row venueLogo">
            <div class="col-xs-8 col-xs-offset-2" ng-show="eventcreation.venueLogoAdded">
              <img ng-src="{{staticUrl}}{{eventcreation.venueLogo}}" class="img-responsive" ng-if="eventcreation.venueLogo"/>
            </div>
          </div>
          <div class="row login-mod well well-sm" ng-if="eventcreation.bckgrndImgAdded && eventcreation.spEmail">
            <div class="scaled">
              <p class="railtext"> Connect to our FREE WIFI with </p>
              <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>
                <div class="form-group" ng-class="{ 'has-error' : loginForm.email.$error && authentication.formSubmitted }">
                  <input type="email" name="email" class="form-control" placeholder="Email">
                </div>
                <div class="form-group railtext">
                  <input type="checkbox" ng-model="authentication.terms" required name="terms">
                  <a ng-click="">Terms and Conditions</a>
                </div>
                <div class="row text-center">
                  <div class="col-md-12 col-xs-12 col-sm-12">
                    <button type="submit" class="btn btn-danger">
                      CONNECT
                    </button>
                  </div>
                </div>
              </form>
              <div class="leLogo">
                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">
              </div>
            </div>
          </div>
          <div class="row login-mod well well-sm" ng-show="eventcreation.bckgrndImgAdded && eventcreation.spRegister">
            <div class="scaled">
              <p class="railtext"> Connect to our FREE WIFI with </p>
              <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>
                <div class="form-group" ng-class="{ 'has-error' : loginForm.email.$error && authentication.formSubmitted }">
                  <input type="email" name="email" class="form-control" placeholder="Email">
                </div>
                <div class="form-group" ng-class="{ 'has-error' : loginForm.password.$error && authentication.formSubmitted }">
                  <input type="password" name="password" class="form-control" placeholder="password">
                </div>
                <div class="form-group railtext">
                  <input type="checkbox" ng-model="authentication.terms" required name="terms">
                  <a ng-click="">Terms and Conditions</a>
                </div>
                <div class="row text-center">
                  <div class="col-md-12 col-xs-12 col-sm-12">
                    <button type="submit" class="btn btn-danger">
                      CONNECT
                    </button>
                  </div>
                </div>
              </form>
              <div class="leLogo">
                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">
              </div>
            </div>
          </div>
          <div class="row video-mod" ng-show="eventcreation.bckgrndImgAdded && eventcreation.spVideo">
            <div class="scaled">
              <div class="leLogo">
                <img class="img-responsive" src="./images/youtube.jpg" alt="Youtube Video">
              </div>
            </div>
          </div>
          <div class="footer-mod" ng-show="eventcreation.bckgrndImgAdded && eventcreation.spVideo">
            <div class="centered">
              <strong class="railtext"> Connect to our FREE WIFI with </strong>
              <div class="btn-deck">
                <button type="submit" class="btn btn-danger btn-xs" ng-click="">
                  More Info
                </button>
                <button type="submit" class="btn btn-info btn-xs" ng-click="">
                  No, Thanks
                </button>
              </div>
              <div class="leLogo">
                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">
              </div>
            </div>
          </div>
          <div class="footer-mod" ng-show="eventcreation.bckgrndImgAdded && eventcreation.spLink">
            <div class="centered">
              <strong class="railtext"> Connect to our FREE WIFI with </strong>
              </br>
              <button type="submit" class="btn btn-danger btn-deck btn-xs" ng-click="">
                CONNECT
              </button>
              <div class="leLogo">
                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">
              </div>
            </div>
          </div>
          <div class="footer-mod" ng-show="eventcreation.bckgrndImgAdded && eventcreation.spSocial">
            <div class="socialCentred">
              <div class="DocumentList">
                <strong class="railtext"> Connect to our FREE WIFI with </strong>
                <div class="row">
                  <div class="socIconsList" ng-repeat="social in eventcreation.socialLogins" ng-if="eventcreation.openAuthList.indexOf(social.id) > -1">
                    <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;">
                      <div class="content">
                      </div>
                    </button>
                  </div>
                </div>
                <div class="row" ng-show="eventcreation.anonymousLogin.on">
                  <a>Continue Without Login</a> <br />
                  <br />
                </div>
                <div class="" style="margin-left: auto;margin-right: auto;  display: block;width: 10vw;height: auto;">
                  <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div class="panel-footer">
  <div class="pull-right">
    <button class="btn btn-default" ng-click="eventcreation.cancelCreate()">Cancel</button>
    <button class="btn btn-default btn-danger" ng-click="eventcreation.nextStep({splash : createEvent.redirection.$error }, 'admin.eventcreation.evcconfirm')">Next Step</button>
  </div>
  <div class="clearfix"></div>
</div>
