<div class="row">
  <div class="col-xs-9 col-sm-6 col-ms-6">
    <div class="panel panel-default panel-form">
      <div class="panel-heading clearfix">
        <h4>Splash Page Type</h4>
      </div>
      <div class="panel-body">
        <select class="form-control" name="splashType" ng-model="duplicateevent.splashType" ng-change="duplicateevent.setSplashPageType(duplicateevent.splashType, false)" ng-options="type as type.name for type in duplicateevent.splashPageTypes">
        </select>
      </div>
      <div ng-show="duplicateevent.splashType <= 0 && duplicateevent.stepThree">
        <p class="text-danger">
          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
          <strong> Splash Type is required</strong>
        </p>
      </div>
    </div>
    <div class="panel panel-default panel-form">
      <div class="panel-heading clearfix">
        <h4>Background Image</h4>
      </div>
      <div class="panel-body">
        <div class="form-group">
          <div class="row">
            <div ng-show="duplicateevent.bckgrndImgAdded" class="col-md-4 col-md-offset-4">
              <img ng-src="{{staticUrl}}{{duplicateevent.bckgrndImg}}" class="img-responsive" />
            </div>
          </div>
          <div class="row">
            <button class="btn btn-danger" ng-click="duplicateevent.selectBckgrndImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>
            <button class="btn btn-danger" ng-click="duplicateevent.uploadBckgrndImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>
          </div>
        </div>
        <div ng-show="!duplicateevent.bckgrndImgAdded && duplicateevent.stepThree">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong>
              Background Image is required</strong>
          </p>
        </div>
      </div>
    </div>

    <div class="panel panel-default panel-form">
      <div class="panel-heading clearfix">
        <h4>Venue Logo</h4>
      </div>
      <div class="panel-body">
        <div class="form-group">
          <div class="row">
            <div ng-show="duplicateevent.venueLogoAdded" class="col-md-4 col-md-offset-4">
              <img ng-src="{{staticUrl}}{{duplicateevent.venueLogo}}" class="img-responsive" />
            </div>
          </div>
          <div class="row">
            <button class="btn btn-danger" ng-click="duplicateevent.selectLogoImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>
            <button class="btn btn-danger" ng-click="duplicateevent.uploadLogoImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>
          </div>
        </div>
        <div ng-show="!duplicateevent.venueLogoAdded && duplicateevent.stepThree">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong>
              Venue Logo is required</strong>
          </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default panel-form" ng-show="duplicateevent.spSocial">
      <div class="panel-heading clearfix">
        <h4>Social Media Login</h4>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-xs-12">
            <div class="col-sm-3" ng-repeat="social in duplicateevent.socialLogins">
              <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;" ng-click="duplicateevent.addOpenAuth(social.id)" ng-disabled="social.name ==='Twilio' && !duplicateevent.hasTwilio">
                <div class="content">
                  <span class="badge badgeSocial" ng-show="duplicateevent.openAuthList.indexOf(social.id) > -1"><i class="fa fa-check-circle-o fa-2x"></i></span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div ng-show="duplicateevent.openAuthList.length < 1 && duplicateevent.stepThree">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong>
              Please select social log in</strong>
          </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default panel-form" ng-show="duplicateevent.spVideo">
      <div class="panel-heading clearfix">
        <h4>Video Url</h4>
      </div>
      <div class="panel-body">
        <div class="form-group" ng-class="{ 'has-error' : duplicateevent.videoUrl.$error && duplicateevent.stepThree}">
          <input type="text" class="form-control" id="videoUrl" ng-model="duplicateevent.event.splashDetails.videoUrl" name="videoUrl" placeholder="eg. http://mysite.com" required>
        </div>
        <div ng-messages="duplicateevent.videoUrl.$error" ng-show="duplicateevent.videoUrl.$error && duplicateevent.stepThree">
          <p class="text-danger" ng-message="required">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Video Url is required</strong>
          </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default panel-form" ng-show="duplicateevent.spAmount">
      <div class="panel-heading clearfix">
        <h4>Amount</h4>
      </div>
      <div class="panel-body">
        <div class="input-group" ng-class="{ 'has-error' : duplicateevent.amount.$error && duplicateevent.stepThree}">
          <div class="input-group-addon">&euro;</div>
          <input type="number" min="0" step="1" class="form-control" id="amount" ng-model="duplicateevent.event.splashDetails.amount" name="amount" placeholder="0" required>
        </div>
        <div ng-messages="duplicateevent.amount.$error" ng-show="duplicateevent.amount.$error && duplicateevent.stepThree">
          <p class="text-danger" ng-message="required">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Amount is required</strong>
          </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default panel-form" ng-show="duplicateevent.spAmount">
      <div class="panel-heading clearfix">
        <h4>Duration (hrs)</h4>
      </div>
      <div class="panel-body">
        <div class="input-group" ng-class="{ 'has-error' : duplicateevent.period.$error && duplicateevent.stepThree}">
          <input type="number" min="0" step="1" class="form-control" id="period" ng-model="duplicateevent.newEvent.splashDetails.period" name="period" placeholder="0" required>
        </div>
        <div ng-messages="duplicateevent.amount.$error" ng-show="duplicateevent.period.$error && duplicateevent.stepThree">
          <p class="text-danger" ng-message="required">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Duration is required</strong>
          </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default panel-form" ng-show="duplicateevent.spSocial">
      <div class="panel-heading clearfix">
        <h4>Social Media Settings</h4>
      </div>
      <div class="panel-body">
        <div class="form-group" ng-class="{ 'has-error' : duplicateevent.stepThree}">
          <h5>
            <input type="checkbox" id="addlikebutton" name="addlikebutton" ng-model="duplicateevent.addlikebutton" /> Facebook Like Button </h5>
          <div ng-show="duplicateevent.addlikebutton">
            <!-- <div class="row" style="border-bottom: none; padding: 5px">
              <div class="col-xs-6">
                <div style="margin-top: 5px">
                  <input type="checkbox" id="defaultFbLikeUrl" name="defaultFbLikeUrl" ng-model="duplicateevent.defaultFbLikeUrl" ng-change="duplicateevent.event.splashdetails.customFbLikeUrl = '';" /> Use Default.</div>
              </div>
              <div class="col-xs-6">
                <input type="url" class="form-control pull-right" id="customFbLikeUrl" ng-model="duplicateevent.event.splashdetails.customFbLikeUrl" name="customFbLikeUrl" placeholder="Custom Facebook Page Url" ng-change="duplicateevent.defaultFbLikeUrl = false;">
              </div>
            </div> -->
            <div class="row" style="border-bottom: none; padding: 5px" ng-show="duplicateevent.venue.customFbLikeUrl">
              <div class="col-xs-12">
                <div style="margin-top: 5px"><input type="checkbox" id="defaultFbLikeUrl" name="defaultFbLikeUrl" ng-model="duplicateevent.defaultFbLikeUrl" ng-change="duplicateevent.customFbLike = false;"/> Default - {{duplicateevent.venue.customFbLikeUrl}}</div>
              </div>
            </div>
            <div class="row" style="border-bottom: none; padding: 5px">
              <div class="col-xs-4" ng-show="duplicateevent.venue.customFbLikeUrl">
                <div style="margin-top: 5px"><input type="checkbox" id="customFbLike" name="customFbLike" ng-model="duplicateevent.customFbLike" ng-change="duplicateevent.defaultFbLikeUrl = false;"/> Custom.</div>
              </div>
              <div class="col-xs-8" ng-hide="duplicateevent.defaultFbLikeUrl">
                <input type="url" class="form-control pull-right" id="customFbLikeUrl" ng-model="duplicateevent.event.splashdetails.customFbLikeUrl" name="customFbLikeUrl" placeholder="Custom Facebook Page Url" ng-change="duplicateevent.defaultFbLikeUrl = false;">
              </div>
            </div>
            <div class="row" style="border-bottom: none; padding: 5px">
              <div class="col-xs-offset-6 col-xs-6">
                <div ng-show="!createEvent.customFbLikeUrl.$valid && eventcreation.stepThree" class="pull-right">
                  <p class="text-danger">
                    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                    <strong> Custom Url must be a valid url. Example https://www.facebook.com/Liquidedge/</strong>
                  </p>
                </div>
              </div>
            </div>
            <div class="row" style="border-bottom: none; padding: 5px">
              <div class="col-xs-offset-6 col-xs-6">
                <div ng-show="!createEvent.customFbLikeUrl.$valid && duplicateevent.stepThree" class="pull-right">
                  <p class="text-danger">
                    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                    <strong> Custom Url must be a valid url. Example https://www.facebook.com/Liquidedge/</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="panel panel-default panel-form" ng-show="duplicateevent.isMagnet">
      <div class="panel-heading clearfix">
        <h4>County Dropdown</h4>
      </div>
      <div class="panel-body">
        <label>
        <input type="checkbox" ng-model="duplicateevent.event.splashdetails.countyDropDown"
           ng-true-value="true" ng-false-value="false">
           Include County Dropdown
        </label>
      </div>
    </div> -->
    <div class="panel panel-default panel-form">
      <div class="panel-heading clearfix">
        <h4>Redirection Url</h4>
      </div>
      <div class="panel-body">
        <div class="form-group" ng-class="{ 'has-error' : duplicateevent.redirection.$error && duplicateevent.stepThree}">
          <input type="text" class="form-control" id="redirectionURL" ng-model="duplicateevent.event.redirectionurl" name="redirection" placeholder="eg. http://mysite.com" required>
        </div>
        <div ng-messages="duplicateevent.redirection.$error" ng-show="duplicateevent.redirection.$error && duplicateevent.stepThree">
          <p class="text-danger" ng-message="required">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong>
              Redirection Url is required</strong>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-9 col-sm-6 col-md-5 col-md-offset-1">
    <h3 class="text-center">Splash Page</h3>
    <div class="col-md-10 col-md-offset-1">
      <div class="splashBackground" ng-style="{ 'background-image': 'url('+ staticUrl + duplicateevent.bckgrndImg + ')' }">
        <div class="content">
          <div class="row venueLogo">
            <div class="col-xs-8 col-xs-offset-2" ng-show="duplicateevent.venueLogoAdded">
              <img ng-src="{{staticUrl}}{{duplicateevent.venueLogo}}" class="img-responsive" />
            </div>
          </div>
          <div class="row login-mod well well-sm" ng-show="duplicateevent.bckgrndImgAdded && duplicateevent.spEmail">
            <div class="scaled">
              <p class="railtext"> Connect to our FREE WIFI with </p>
              <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>
                <div class="form-group" ng-class="{ 'has-error' : loginForm.email.$error && authentication.formSubmitted }">
                  <input type="email" name="email" class="form-control" placeholder="Email">
                </div>
                <div class="form-group railtext">
                  <input type="checkbox" ng-model="authentication.terms" required name="terms">
                  <a ng-click="">Terms and Conditions</a>
                </div>
                <div class="row text-center">
                  <div class="col-md-12 col-xs-12 col-sm-12">
                    <button type="submit" class="btn btn-danger">
                      CONNECT
                    </button>
                  </div>
                </div>
              </form>
              <div class="leLogo">
                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">
              </div>
            </div>
          </div>
          <div class="row video-mod" ng-show="duplicateevent.bckgrndImgAdded && duplicateevent.spVideo">
            <div class="scaled">
              <div class="leLogo">
                <img class="img-responsive" src="./images/youtube.jpg" alt="Youtube Video">
              </div>
            </div>
          </div>
          <div class="footer-mod" ng-show="duplicateevent.bckgrndImgAdded && duplicateevent.spVideo">
            <div class="centered">
              <strong class="railtext"> Connect to our FREE WIFI with </strong>
              <div class="btn-deck">
                <button class="btn btn-danger btn-xs" ng-click="">
                  More Info
                </button>
                <button class="btn btn-info btn-xs" ng-click="">
                  No, Thanks
                </button>
              </div>
              <div class="leLogo">
                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">
              </div>
            </div>
          </div>
          <div class="footer-mod" ng-show="duplicateevent.bckgrndImgAdded && duplicateevent.spLink">
            <div class="centered">
              <strong class="railtext"> Connect to our FREE WIFI with </strong>
              </br>
              <button class="btn btn-danger btn-deck btn-xs" ng-click="">
                CONNECT
              </button>
              <div class="leLogo">
                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">
              </div>
            </div>
          </div>
          <div class="footer-mod" ng-show="duplicateevent.bckgrndImgAdded && duplicateevent.spSocial">
            <div class="socialCentred">
              <div class="DocumentList">
                <strong class="railtext"> Connect to our FREE WIFI with </strong>
                <div class="row">
                  <div class="socIconsList" ng-repeat="social in duplicateevent.socialLogins" ng-show="duplicateevent.openAuthList.indexOf(social.id) > -1">
                    <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;">
                      <div class="content">
                      </div>
                    </button>
                  </div>
                </div>
                <div class="leLogo">
                  <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div class="panel-footer">
  <div class="pull-right">
    <button class="btn btn-default" ng-click="duplicateevent.cancel()">Cancel</button>
    <button class="btn btn-default btn-danger" ng-click="duplicateevent.nextStep({splash : createEvent.redirection.$error }, 'admin.duplicateevent.confirm')">Next Step</button>
  </div>
  <div class="clearfix"></div>
</div>
