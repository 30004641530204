(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name newoperator.controller:NewoperatorCtrl
   *
   * @description
   *
   */
  angular
    .module('newoperator')
    .controller('NewoperatorCtrl', NewoperatorCtrl);

  function NewoperatorCtrl(Api, $log, $state, $rootScope, $modal) {
    var vm = this;
    vm.operator = {};
    vm.venues = [];
    vm.submitted = false;


    Api.getRoles().success(function (data) {
      vm.roles = data.organisationRoles;
      $log.info(vm.roles);
    }).error(function (err) {
      $log.info(err);
    });

    Api.getVenues().then(function(result){
      vm.venues = result.data.venues;
    }, function (err){
      console.log(err);
    });

    vm.setVenue = function(venue){
      for (var i = 0; i < vm.venues.length; i++){
        if(vm.venues[i].id === venue){
        vm.operator.details.venueName = vm.venues[i].venuetitle;
          break;
        }
      }

    };

    vm.createOperator = function (valid) {
      vm.submitted = true;
      if (valid) {
        Api.createOperator(vm.operator).success(function (data) {
          $log.info(data);
          var modalMessage = {
            ok: 'Ok',
            messageHeader: 'Email Sent to Operator With Login Details.',
          };

          var modalInstance = $modal.open({
            templateUrl: 'modalservice/views/modalservice.tpl.html',
            controller: 'ModalserviceCtrl as modalService',
            resolve: {
              message: function () {
                return modalMessage;
              }
            }
          });
          modalInstance.result.then(function () {
            $state.go('admin.operators');
          });
        }).error(function (err) {
          $log.info(err);
        });
      }
    };
  }
}());
