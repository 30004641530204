<h2>{{event.event.eventName}}</h2>
<div class="panel panel-default">
  <div class="eventPadding">
    <div class="row">
      <div class="col-md-6">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h4>Event Summary</h4>
          </div>
          <div class="panel-body">
            <p>Name: {{event.event.name}}</p>
            <p>Description: {{event.event.description}}
            <p>Start Date: {{event.event.starttime | date:'medium'}}</p>
            <p>End Date: {{event.event.endtime | date:'medium'}}</p>
            <p>Network Name: {{event.event.ssidname}}</p>
            <p>Network Type: {{event.event.networktypename}}</p>
            <p ng-if="event.event.categories[0].provider === 1">Bandwith Limit: {{event.event.trafficlimitsta}}</p>
            <p>Splash Page Type: {{event.event.splashtypename}}</p>
            <p>Redirection: {{event.event.redirectionurl}}</p>
          </div>
        </div>
      </div>
      <!--<div class="col-md-6">
        <div class="col-md-8 col-md-offset-2">
          <div class="splashBackground" ng-style="{ 'background-image': 'url(' + staticUrl + event.event.backgroundimage + ')' }">
            <div class="content">
              <div class="row venueLogo">
                <div class="col-xs-8 col-xs-offset-2">
                  <img ng-src="{{staticUrl}}{{event.event.logoimage}}" class="img-responsive" />
                </div>
              </div>
              <div class="row splashLogin">
                <div class="col-xs-10 col-xs-offset-1">
                  <div class="scaled">
                    <div class="content">
                      <div class="col-xs-12 socIcons">
                        <ul class="socIconsList">
                          <li ng-show="event.socialLoginTypes.indexOf(1) > -1">
                            <button class="btn btn-social btn-facebook">
                              <div class="content">

                              </div>
                            </button>
                          </li>
                          <li ng-show="event.socialLoginTypes.indexOf(2) > -1">
                            <button class="btn btn-social btn-google">
                              <div class="content">

                              </div>
                            </button>
                          </li>
                          <li ng-show="event.socialLoginTypes.indexOf(3) > -1">
                            <button class="btn btn-social btn-twitter">
                              <div class="content">

                              </div>
                            </button>
                          </li>
                          <li ng-show="event.socialLoginTypes.indexOf(4) > -1">
                            <button class="btn btn-social btn-linkedin">
                              <div class="content">

                              </div>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row liquidLogo">
                <div class="col-xs-6 col-xs-offset-3">
                  <img ng-src="./images/le-logo.png" class="img-responsive" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- new code -->
      <div class="col-xs-9 col-sm-6 col-md-5 col-md-offset-1">
        <div class="col-md-10 col-md-offset-1">
          <div class="splashBackground" ng-style="{ 'background-image': 'url(' + staticUrl + event.event.backgroundimage + ')' }">
            <div class="content">
              <div class="row venueLogo">
                <div class="col-xs-8 col-xs-offset-2">
                  <img ng-src="{{staticUrl}}{{event.event.logoimage}}" class="img-responsive" />
                </div>
              </div>
              <div class="row login-mod well well-sm" ng-show="event.event.splashtypeid === 3">
                <div class="scaled">
                  <p class="railtext"> Connect to our FREE WIFI with </p>
                  <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>
                    <div class="form-group" ng-class="{ 'has-error' : loginForm.email.$error && authentication.formSubmitted }">
                      <input type="email" name="email" class="form-control" placeholder="Email">
                    </div>
                    <div class="form-group railtext">
                      <input type="checkbox" ng-model="authentication.terms" required name="terms">
                      <a ng-click="">Terms and Conditions</a>
                    </div>
                    <div class="row text-center">
                      <div class="col-md-12 col-xs-12 col-sm-12">
                        <button type="submit" class="btn btn-danger">
                          CONNECT
                        </button>
                      </div>
                    </div>
                  </form>
                  <div class="leLogo leLogo-mod">
                    <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">
                  </div>
                </div>
              </div>
              <div class="row video-mod" ng-show="event.event.splashtypeid === 1">
                <div class="scaled">
                  <div class="leLogo">
                    <img class="img-responsive" src="./images/youtube.jpg" alt="Youtube Video">
                  </div>
                </div>
              </div>
              <div class="footer-mod" ng-show="event.event.splashtypeid === 1">
                <div class="centered">
                  <strong class="railtext"> Connect to our FREE WIFI with </strong>
                  <div class="btn-deck">
                    <button type="submit" class="btn btn-danger btn-xs" ng-click="">
                      More Info
                    </button>
                    <button type="submit" class="btn btn-info btn-xs" ng-click="">
                      No, Thanks
                    </button>
                  </div>
                  <div class="leLogo">
                    <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">
                  </div>
                </div>
              </div>
              <div class="footer-mod" ng-show="event.event.splashtypeid === 4">
                <div class="centered">
                  <strong class="railtext"> Connect to our FREE WIFI with </strong>
                  </br>
                  <button type="submit" class="btn btn-danger btn-deck btn-xs" ng-click="">
                    CONNECT
                  </button>
                  <div class="leLogo">
                    <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">
                  </div>
                </div>
              </div>
              <div class="footer-mod" ng-show="event.event.splashtypeid === 2">
                <div class="socialCentred">
                  <div class="DocumentList">
                    <strong class="railtext"> Connect to our FREE WIFI with </strong>
                    <div class="row">
                      <!-- <div class="socIconsList" ng-repeat="social in event.event.socialLoginTypes" ng-show="event.socialLoginTypes.indexOf(4) > -1">
                        <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;">
                          <div class="content">
                          </div>
                        </button>
                      </div> -->
                      <div class="row splashLogin">
                        <div class="col-xs-10 col-xs-offset-1">
                          <div class="scaled">
                            <div class="content">
                              <div class="col-xs-12 socIcons">
                                <div class="socIconsList">
                                  <div ng-show="event.socialLoginTypes.indexOf(1) > -1">
                                    <button class="btn btn-social btn-facebook">
                                      <div class="content">
                                      </div>
                                    </button>
                                  </div>
                                  <div ng-show="event.socialLoginTypes.indexOf(2) > -1">
                                    <button class="btn btn-social btn-google">
                                      <div class="content">
                                      </div>
                                    </button>
                                  </div>
                                  <div ng-show="event.socialLoginTypes.indexOf(3) > -1">
                                    <button class="btn btn-social btn-twitter">
                                      <div class="content">
                                      </div>
                                    </button>
                                  </div>
                                  <div ng-show="event.socialLoginTypes.indexOf(4) > -1">
                                    <button class="btn btn-social btn-linkedin">
                                      <div class="content">
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="leLogo">
                      <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- new code -->
    </div>
  </div>
</div>
