<div class="selectimage">
  <div class="modal-header">
    <img class="modal-img" src="./images/magnetnetworks/magnet_logo_small.png">
    <p class="input-group">
      <div class="input-group-addon"><i class="fa fa-search"></i></div>
      <input type="text" class="form-control" placeholder="Search Images" ng-model="imageSearch">
    </p>
  </div>
  <div class="modal-body imageList">
    <div class="row fix">
      <div class="col-md-3 thumb" ng-repeat="image in selectimage.imageList | filter:imageSearch">
        <label ng-dblclick="selectimage.ok()">
          <img class="img-responsive" ng-src="{{staticUrl}}{{image.imageuri}}" />
          <div class="row">
            <input type="radio" ng-model="selectimage.selectedImage" value="{{image}}">
          </div>
        </label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div>
      <button class="btn btn-danger" type="button" ng-click="selectimage.ok()">Done</button>
      <button class="btn btn-warning" type="button" ng-click="selectimage.cancel()">Cancel</button>
    </div>
  </div>
</div>