 (function () {
   'use strict';

   /* @ngdoc object
    * @name leoperations
    * @description
    *
    */
   angular
     .module('leoperations', [
       'ngAria',
       'ui.router',
       'ui.bootstrap',
       'ngDragDrop',
       'home',
       'admin',
       'main',
       'eventcreation',
       'base64',
       'checklist-model',
       'rzModule',
       'ngCookies',
       'events',
       'ngMessages',
       'chart.js',
       'ngFileUpload',
       'ngStorage',
       'event',
       'editevent',
       'manageimages',
       'editinfrastructure',
       'venues',
       'newvenue',
       'operators',
       'editoperator',
       'newoperator',
       'modalservice',
       'selectimage',
       'duplicateevent',
       'angularUtils.directives.dirPagination',
       'managenetwork',
       'editzone',
       'addap',
       'addzone',
       'networktype',
       'editnetworktype',
       'editap',
       'addInfrastructure',
       'editvenue',
       'editsurvey',
       'editeventnew',
       'addsurvey',
       'mailchimp',
       'newlist',
       'uploadorgimg',
       'userprofile',
       'editclservice',
       'addclservice',
       'cloudservices',
       'support',
       'ppolicy',
       'orgInfrastructures',
       'tags',
       'dndLists',
       'mailListModal',
       'twilioModal',
       'newvenueanalytics',
       'eventanalytics',
       'ngAnimate',
       'addvenuemap',
       'editvenuemap',
       'textAngular',
       'tandceditor',
       'ngSanitize',
       'ngCsv'
     ]);
 }());

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/rowTemplates/singleRow.tpl.html',
    '<div class="col-xs-12">\n' +
    '	<div ng-include="eventcreation.moduleTemplates[row.moduleId].template"></div>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/rowTemplates/doubleRow.tpl.html',
    '<div class="col-xs-6">\n' +
    '	<div ng-include="eventcreation.moduleTemplates[row.moduleId].template"></div>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/tabTemplates/layout.tpl.html',
    '<h3>Layout Options</h3>\n' +
    '<div class="row">\n' +
    '  <div class="col-xs-9 col-sm-6 col-ms-6" data-drag="true" data-jqyoui-options="{revert: \'invalid\'}" ng-model="eventcreation.rowTemplates[0]" jqyoui-draggable="{placeholder:\'keep\'}">\n' +
    '    <a href="#" class="thumbnail img-caption">\n' +
    '      <img src="http://placehold.it/250x150" alt="...">\n' +
    '    </a>\n' +
    '    <div class="caption">\n' +
    '      <p class="text-center">Single Column</p>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-9 col-sm-6 col-ms-6" data-drag="true" data-jqyoui-options="{revert: \'invalid\'}" ng-model="eventcreation.rowTemplates[1]" jqyoui-draggable="{placeholder:\'keep\'}">\n' +
    '    <a href="#" class="thumbnail img-caption">\n' +
    '      <img src="http://placehold.it/250x150" alt="...">\n' +
    '    </a>\n' +
    '    <div class="caption">\n' +
    '      <p class="text-center">Double Column</p>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/tabTemplates/content.tpl.html',
    '<h3>Conent Options</h3>\n' +
    '<div class="row">\n' +
    '  <div class="col-xs-9 col-sm-6 col-ms-6">\n' +
    '    <a href="#" class="thumbnail img-caption">\n' +
    '      <img src="http://placehold.it/250x150" alt="...">\n' +
    '    </a>\n' +
    '    <div class="caption">\n' +
    '      <p class="text-center">Text Box</p>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-9 col-sm-6 col-ms-6">\n' +
    '    <a href="#" class="thumbnail img-caption">\n' +
    '      <img src="http://placehold.it/250x150" alt="...">\n' +
    '    </a>\n' +
    '    <div class="caption">\n' +
    '      <p class="text-center">Image</p>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-9 col-sm-6 col-ms-6">\n' +
    '    <a href="#" class="thumbnail img-caption">\n' +
    '      <img src="http://placehold.it/250x150" alt="...">\n' +
    '    </a>\n' +
    '    <div class="caption">\n' +
    '      <p class="text-center">Adverisment</p>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-9 col-sm-6 col-ms-6">\n' +
    '    <a href="#" class="thumbnail img-caption">\n' +
    '      <img src="http://placehold.it/250x150" alt="...">\n' +
    '    </a>\n' +
    '    <div class="caption">\n' +
    '      <p class="text-center">Banner Advertisment</p>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/tabTemplates/colours.tpl.html',
    '<div>\n' +
    'Colours\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/moduleTemplates/textBox.tpl.html',
    '<div>\n' +
    '{{row.data.text}}\n' +
    '\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/moduleTemplates/image.tpl.html',
    '<div>\n' +
    'Image\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/moduleTemplates/blankModule.tpl.html',
    '<div style="min-height: 100px; background-color: #CCC; border: 1px solid;">\n' +
    '\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/moduleTemplates/bannerImage.tpl.html',
    '<div>\n' +
    '<a href="{{row.data.linkURL}}">\n' +
    '	<img class="media-object" src="{{row.data.imgURL}}" alt="..." style="width: 100%; height: auto;">\n' +
    '</a>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/moduleTemplates/adImage.tpl.html',
    '<div>\n' +
    '<a href="{{row.data.linkURL}}">\n' +
    '	<img class="media-object" src="{{row.data.imgURL}}" alt="..." style="width: 100%; height: auto;">\n' +
    '</a>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('venues/views/venues.tpl.html',
    '<div class="hidden-xs">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <div class="row row-align">\n' +
    '        <h3>Venues\n' +
    '          <button class="btn btn-danger btn-md pull-right" ng-click="venues.newvenue()" tooltip-placement="left" tooltip="Add Venue">\n' +
    '            <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '          </button>\n' +
    '        </h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div class="row tabrow">\n' +
    '        <div class="col-md-9 col-md-offset-3">\n' +
    '          <div class="col-md-6">\n' +
    '            <div class="input-group">\n' +
    '              <span class="input-group-addon">\n' +
    '                <i class="fa fa-search"></i>\n' +
    '              </span>\n' +
    '              <input type="text" class="form-control" placeholder="Search Venues" ng-model="venueSearch">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="col-md-6">\n' +
    '            <select class="form-control" ng-model="venues.limit" ng-options="limit for limit in venues.limits" ng-change="venues.setLimit(venues.limit)">\n' +
    '              <option value="">Limit Results</option>\n' +
    '            </select>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <table class="table table-responsive">\n' +
    '        <tbody>\n' +
    '          <tr ng-repeat="venue in venues.venues | filter:venueSearch | limitTo:venues.limit" ng-hide="venues.venueSelected.selected && venues.venueSelected.venue !== venue.id">\n' +
    '            <td class="evntIMG">\n' +
    '              <div>\n' +
    '                <img ng-src="{{adminStaticUrl}}{{venue.imageuri}}" onerror="this.src=\'./images/le-sq.png\'" class="img-responsive" />\n' +
    '              </div>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <h4>\n' +
    '                {{venue.venuetitle}}</h4>\n' +
    '              <h4>\n' +
    '                {{venue.location}}</h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <h4>Mail:\n' +
    '                <a ng-href="mailto:{{venue.contactemail}}">{{venue.contactemail}}</a>\n' +
    '              </h4>\n' +
    '              <h4>Phone: {{venue.contactphone}}\n' +
    '              </h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <div class="pull-right">\n' +
    '                <button class="btn btn-danger btn-md" ng-click="venues.deleteVenue(venue.id)" tooltip-placement="left" tooltip="Delete Venue">\n' +
    '                  <span class="fa fa-trash-o" aria-hidden="true"></span>\n' +
    '                </button>\n' +
    '                <button class="btn btn-danger btn-md" ng-click="venues.editvenue(venue.id)" tooltip-placement="left" tooltip="Edit Venue">\n' +
    '                  <span class="fa fa-pencil-square-o" aria-hidden="true"></span>\n' +
    '                </button>\n' +
    '                <!-- <button class="btn btn-danger btn-md" ng-click="venues.viewAnalytics(venue.id)" tooltip-placement="left" tooltip="View Analytics">\n' +
    '                  <i class="fa fa-bar-chart" aria-hidden="true"></i>\n' +
    '                </button> -->\n' +
    '                <button class="btn btn-danger btn-md" ng-click="venues.manageNetwork(venue.id)" tooltip-placement="left" tooltip="Manage Network">\n' +
    '                  <i class="fa fa-wifi" aria-hidden="true"></i>\n' +
    '                </button>\n' +
    '                <button class="btn btn-danger btn-md" ng-click="venues.externalServices(venue.id)" tooltip-placement="left" tooltip="Cloud Services">\n' +
    '                  <i class="fa fa-cloud" aria-hidden="true"></i>\n' +
    '                </button>\n' +
    '                <button class="btn btn-danger btn-md" ng-click="venues.viewNewAnalytics(venue.id)" tooltip-placement="left" tooltip="View Analytics">\n' +
    '                  <i class="fa fa-line-chart" aria-hidden="true"></i>\n' +
    '                </button>\n' +
    '                <button class="btn btn-danger btn-md" ng-click="venues.viewCustomerSeg(venue.id)" tooltip-placement="left" tooltip="View Customer Segmentation">\n' +
    '                  <i class="fa fa-user-plus"></i>\n' +
    '                </button>\n' +
    '                <a href="" data-ui-sref="admin.tandceditor({venueid: venue.id})" class="btn btn-danger btn-md"><i class="fa fa-file" tooltip-placement="left" tooltip="Terms &amp; Conditions"></i></a>\n' +
    '              </div>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="hidden-sm hidden-md hidden-lg">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <div class="row row-align">\n' +
    '        <h5>Venues</h5>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div class="input-group">\n' +
    '        <span class="input-group-addon">\n' +
    '          <i class="fa fa-search"></i>\n' +
    '        </span>\n' +
    '        <input type="text" class="form-control" placeholder="Search Venues" ng-model="venueSearch">\n' +
    '      </div>\n' +
    '      <ul class="list-unstyled list-margin event-list-xs">\n' +
    '        <li ng-repeat="venue in venues.venues | filter:venueSearch">\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-8">\n' +
    '              <p>\n' +
    '                <strong>{{venue.venuetitle}}</strong>\n' +
    '              </p>\n' +
    '              <p>Phone: {{venue.contactphone}}</p>\n' +
    '            </div>\n' +
    '            <div class="col-xs-3 col-xs-offset-1 text-centre">\n' +
    '              <button class="btn btn-danger btn-md" ng-click="venues.viewNewAnalytics(venue.id)" tooltip-placement="left" tooltip="View Analytics">\n' +
    '                <i class="fa fa-line-chart" aria-hidden="true"></i>\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('venues/views/venuecreatemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div ng-hide="venueCreateModal.error.length > 1">\n' +
    '    <h4>Would you like to create from controller?</h4>\n' +
    '\n' +
    '    <select class="form-control" name="selected" ng-model="venueCreateModal.selected" ng-options="option.id as option.controlleraddress for option in venueCreateModal.infrastructures" ng-change="venueCreateModal.selectedInfrastructure(venueCreateModal.selected)">\n' +
    '      <option value="" disabled selected>- Please Select An Infrastructure -</option>\n' +
    '    </select>\n' +
    '  </div>\n' +
    '  <h4>{{venueCreateModal.error}}</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="venueCreateModal.ok()" ng-disabled="venueCreateModal.disabled">Yes</button>\n' +
    '  <button class="btn btn-info" type="button" ng-click="venueCreateModal.no()" ng-hide="venueCreateModal.error.length > 1">No</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="venueCreateModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('venues/views/deletevenuemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this Venue?</h4>\n' +
    '  <p>Venues with existing associated elements such as events cannot be deleted you must delete all existing associated elements first</p>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="deleteVenueModal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="deleteVenueModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('venues/views/deletefailedmodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>This Venue Cannot Be Deleted!</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-success btn-block" type="button" ng-click="deleteVenueModal.ok()">OK</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('uploadorgimg/views/uploadorgimg.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div ng-hide="modal.image" class="image-box text-center" ngf-select ngf-drop accept="image/*" ngf-pattern="\'image/*\'" ngf-drag-over-class="dragover" ng-model="modal.image">\n' +
    '    <span class="glyphicon glyphicon-camera"></span>\n' +
    '    <h4>\n' +
    '      <strong>ADD AN IMAGE</strong>\n' +
    '    </h4>\n' +
    '    <!-- <p>Choose an image that captures your event</p> -->\n' +
    '  </div>\n' +
    '  <div class="row modalThumb">\n' +
    '    <img ngf-src="modal.image">\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-file btn-danger" type="file" ngf-select accept="image/*" ngf-max-height="1000" ngf-max-size="1MB" ng-model="modal.image">\n' +
    '        Select File</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label><h4>Keywords</h4></label>\n' +
    '    <textarea class="form-control" rows="3" ng-model="modal.description" placeholder="comma, seperated, values"></textarea>\n' +
    '  </div>\n' +
    '  <button ng-click="modal.uploadFiles(modal.image)" class="btn btn-sm btn-danger">Submit</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="modal.exit()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('userprofile/views/userprofile.tpl.html',
    '<div class="well well-small">\n' +
    '  <div class="row">\n' +
    '    <div class="col-sm-2">\n' +
    '      <div ng-show="userprofile.user.socialdata.provider == \'google\'">\n' +
    '        <img ng-src="{{userprofile.user.socialdata.photos[0].value}}" class="img-responsive" />\n' +
    '      </div>\n' +
    '      <div ng-show="userprofile.user.socialdata.provider == \'facebook\'">\n' +
    '        <img ng-src="https://graph.facebook.com/{{userprofile.user.socialdata.id}}/picture?type=large" class="img-responsive" />\n' +
    '      </div>\n' +
    '      <div ng-show="userprofile.user.socialdata.provider == \'twitter\'">\n' +
    '        <img ng-src="https://twitter.com/{{userprofile.user.socialdata.username}}/profile_image?size=original" class="img-responsive" />\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-sm-4 wrap-text">\n' +
    '      <h5>{{userprofile.user.name}}</h5>\n' +
    '      <ul class="list-unstyled gender-list">\n' +
    '        <li>\n' +
    '          <strong>Email: </strong>{{userprofile.user.login}}</li>\n' +
    '        <li>\n' +
    '          <strong>Gender: </strong>{{userprofile.user.socialdata.gender}}</li>\n' +
    '        <li>\n' +
    '          <strong>Social Media: </strong>\n' +
    '          <a ng-show="userprofile.user.logintype == \'facebook\'" ng-href="{{userprofile.user.socialdata.profileUrl}}" target="-_blank">{{userprofile.user.logintype}}</a>\n' +
    '          <a ng-show="userprofile.user.logintype == \'google\'" ng-href="https://plus.google.com/u/0/{{userprofile.user.socialdata.id}}" target="-_blank">{{userprofile.user.logintype}}</a>\n' +
    '          <a ng-show="userprofile.user.logintype == \'linkedin\'" ng-href="https://www.linkedin.com/in/{{userprofile.user.socialdata.id}}" target="-_blank">{{userprofile.user.logintype}}</a>\n' +
    '          <a ng-show="userprofile.user.logintype == \'twitter\'" ng-href="https://twitter.com/{{userprofile.user.socialdata.username}}" target="-_blank">{{userprofile.user.logintype}}</a>\n' +
    '        </li>\n' +
    '        <li>\n' +
    '          <ul class="list-group tagList">\n' +
    '            <li class="list-group-item list-group-item-success" ng-if="userprofile.isLive">Online</li>\n' +
    '            <li class="list-group-item list-group-item-danger" ng-if="!userprofile.isLive">Offline</li>\n' +
    '          </ul>\n' +
    '        </li>\n' +
    '      </ul>\n' +
    '      <br>\n' +
    '      <br>\n' +
    '      <br>\n' +
    '      <p><strong>Tags:</strong></p>\n' +
    '      <ul class="list-group tagList">\n' +
    '        <li ng-repeat="tag in userprofile.user.tags" class="list-group-item list-group-item-success">{{tag}}</li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '    <div>\n' +
    '      <div class="col-sm-3 col-sm-offset-3">\n' +
    '        <ul class="list-unstyled">\n' +
    '          <li>\n' +
    '            <h5> First Visit: </h5>\n' +
    '            <span>{{userprofile.dateOf(userprofile.firstSeen)| date: \'yyyy-MM-dd HH:mm\'}}</span>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <h5> Most Recent Visit: </h5>\n' +
    '            <span>{{userprofile.dateOf(userprofile.lastSeen)| date: \'yyyy-MM-dd HH:mm\'}}</span>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <h5> Visits: </h5>\n' +
    '            <span>{{userprofile.user.loginCount}}</span>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="hidden-sm hidden-md hidden-lg">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3>Visits</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <ul class="list-unstyled list-margin event-list-xs">\n' +
    '        <li ng-repeat="login in userprofile.user.logins">\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-6">\n' +
    '              <p>\n' +
    '                <strong>Date: </strong>\n' +
    '              </p>\n' +
    '              <p>{{userprofile.dateOf(login.timestamp)| date: \'yyyy-MM-dd HH:mm\'}}</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-6">\n' +
    '              <p>\n' +
    '                <strong>AP: </strong>\n' +
    '              </p>\n' +
    '              <p ng-if="!i.apname.length">{{login.apmac}}</p>\n' +
    '              <p ng-if="i.apname.length > 0">{{login.apname}}</p>\n' +
    '            </div>\n' +
    '            <div class="col-xs-6">\n' +
    '              <p>\n' +
    '                <strong>SSID: </strong>\n' +
    '              </p>\n' +
    '              <p>{{login.ssid}}</p>\n' +
    '            </div>\n' +
    '            <div class="col-xs-6" ng-hide="true">\n' +
    '              <button class="btn btn-danger btn-md" ng-click="userprofile.submit()" tooltip-placement="left" tooltip="User Journey">\n' +
    '                <span class="glyphicon" aria-hidden="true">Details</span>\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '<div class="panel panel-default hidden-xs">\n' +
    '  <div class="panel-heading">\n' +
    '    <h3>Visits</h3>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <table class="table table-responsive table-striped table-bordered">\n' +
    '      <thead>\n' +
    '        <tr>\n' +
    '          <th>Date</th>\n' +
    '          <th>AP Name</th>\n' +
    '          <th>SSID</th>\n' +
    '        </tr>\n' +
    '      </thead>\n' +
    '      <tbody>\n' +
    '        <tr ng-repeat="i in userprofile.user.logins">\n' +
    '          <td> {{userprofile.dateOf(i.timestamp)| date: \'yyyy-MM-dd HH:mm\'}}</td>\n' +
    '          <td ng-if="!i.apname.length">{{i.apmac}}</td>\n' +
    '          <td ng-if="i.apname.length > 0">{{i.apname}}</td>\n' +
    '          <td>{{i.ssid}}</td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('tags/views/tagupdate.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Create New Tag</h4>\n' +
    '  <form class="form">\n' +
    '    <div class="form-group">\n' +
    '      <label for="tagname">Name</label>\n' +
    '      <input type="text" class="form-control" id="tagname" placeholder="Tag Name" ng-model="tagupdatemodal.newTag.name">\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label for="tagdescript">Description</label>\n' +
    '      <input type="text" class="form-control" id="tagdescript" placeholder="Tag Description" ng-model="tagupdatemodal.newTag.description">\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label for="tagduration">Duration (minutes)</label>\n' +
    '      <input type="number" class="form-control" id="tagduration" ng-model="tagupdatemodal.newTag.duration">\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label>Zones</label>\n' +
    '    <ul class="">\n' +
    '      <li class="checkbox" dir-paginate="zone in tagupdatemodal.zones |itemsPerPage: 10">\n' +
    '        <label>\n' +
    '          <input type="checkbox" checklist-model="tagupdatemodal.selectedZones" checklist-value="{id:zone.id}">\n' +
    '          {{zone.name}}\n' +
    '        </label>\n' +
    '      </li>\n' +
    '    </ul>\n' +
    '    <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>\n' +
    '  </div>\n' +
    '  </form>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-warning" type="button" ng-click="tagupdatemodal.cancel()">Cancel</button>\n' +
    '  <button class="btn btn-danger" type="button" ng-click="tagupdatemodal.ok()">Submit</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('tags/views/tags.tpl.html',
    '<div class="simpleDemo">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <div class="row row-align">\n' +
    '        <h3>Customer Segmentation</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div class="row margin_top">\n' +
    '        <div class="col-md-6">\n' +
    '          <div class="panel panel-default">\n' +
    '            <div class="panel-heading">\n' +
    '              <div class="row row-align">\n' +
    '                <p>Users by Tag</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <canvas id="base" class="chart chart-bar" chart-data="tags.barChart.data" chart-labels="tags.barChart.labels" chart-options="tags.barChart.options" chart-series="tags.barChart.series" chart-colors="tags.chartColors">\n' +
    '              </canvas>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-6">\n' +
    '          <div class="panel panel-default">\n' +
    '            <div class="panel-heading">\n' +
    '              <div class="row row-align">\n' +
    '                <p>Location Tags\n' +
    '                  <button ng-click="tags.createTag()" class="btn btn-danger pull-right">\n' +
    '                    <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '                  </button>\n' +
    '                </p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <table class="table">\n' +
    '                <thead>\n' +
    '                  <tr>\n' +
    '                    <th>Tags</th>\n' +
    '                    <th>Description</th>\n' +
    '                    <th>Duration</th>\n' +
    '                    <th>Users</th>\n' +
    '                  </tr>\n' +
    '                </thead>\n' +
    '                <tbody>\n' +
    '                  <tr ng-repeat="tag in tags.tags">\n' +
    '                    <td>{{tag.name}}</td>\n' +
    '                    <td>{{tag.description}}</td>\n' +
    '                    <td>{{tag.duration}} mins</td>\n' +
    '                    <td>{{tag.users.length}}</td>\n' +
    '                    <td>\n' +
    '                      <div class="pull-right">\n' +
    '                        <button class="btn btn-danger" tooltip-placement="left" tooltip="Update Tag" ng-click="tags.updateTag(tag._id)">\n' +
    '                          <i class="fa fa-pencil-square-o"></i>\n' +
    '                        </button>\n' +
    '                        <button class="btn btn-danger btn-md" ng-click="tags.deleteTag(tag._id)" tooltip-placement="left" tooltip="Delete Tag">\n' +
    '                          <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '                        </button>\n' +
    '                      </div>\n' +
    '                    </td>\n' +
    '                  </tr>\n' +
    '                </tbody>\n' +
    '              </table>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-6">\n' +
    '          <div class="panel panel-default">\n' +
    '            <div class="panel-heading">\n' +
    '              <div class="row row-align">\n' +
    '                <p>Generate Email List from Tags</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-4">\n' +
    '                  <div class="panel panel-info pheight">\n' +
    '                    <div class="panel-heading">\n' +
    '                      Tags\n' +
    '                    </div>\n' +
    '                    <div class="panel-body">\n' +
    '                      <ul dnd-list="tags.selectFrom">\n' +
    '                        <li ng-repeat="item in tags.selectFrom" dnd-draggable="item" dnd-moved="tags.selectFrom.splice($index, 1)" dnd-effect-allowed="move" dnd-selected="models.selected = item" ng-class="{\'selected\': models.selected === item}">\n' +
    '                          {{item.name}}\n' +
    '                        </li>\n' +
    '                      </ul>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <div class="col-md-4">\n' +
    '                  <div class="panel panel-success">\n' +
    '                    <div class="panel-heading">\n' +
    '                      Include\n' +
    '                    </div>\n' +
    '                    <div class="panel-body">\n' +
    '                      <ul dnd-list="tags.include">\n' +
    '                        <li ng-repeat="item in tags.include" dnd-draggable="item" dnd-moved="tags.include.splice($index, 1)" dnd-effect-allowed="move" dnd-selected="models.selected = item" ng-class="{\'selected\': models.selected === item}">\n' +
    '                          {{item.name}}\n' +
    '                        </li>\n' +
    '                      </ul>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <div class="col-md-4">\n' +
    '                  <div class="panel panel-danger">\n' +
    '                    <div class="panel-heading">\n' +
    '                      Exclude\n' +
    '                    </div>\n' +
    '                    <div class="panel-body">\n' +
    '                      <ul dnd-list="tags.exclude">\n' +
    '                        <li ng-repeat="item in tags.exclude" dnd-draggable="item" dnd-moved="tags.exclude.splice($index, 1)" dnd-effect-allowed="move" dnd-selected="models.selected = item" ng-class="{\'selected\': models.selected === item}">\n' +
    '                          {{item.name}}\n' +
    '                        </li>\n' +
    '                      </ul>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class=col-md-12>\n' +
    '                  <button class="btn btn-danger" ng-click="tags.resetEmail()">\n' +
    '                    Reset\n' +
    '                  </button>\n' +
    '                  <button class="btn btn-danger" ng-click="tags.generateEmailList()" ng-disabled="tags.include.length <= 0 && tags.exclude.length <=0">\n' +
    '                    Generate Email List\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-6">\n' +
    '          <div class="panel panel-default" ng-show="tags.gotemail">\n' +
    '            <div class="panel-heading">\n' +
    '              <div class="row row-align">\n' +
    '                <p>Email List</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="row">\n' +
    '                <table class="table table-condensed table-bordered table-striped">\n' +
    '                  <tr dir-paginate="email in tags.emailList | itemsPerPage: 10">\n' +
    '                    <td>{{email.login}}</td>\n' +
    '                  </tr>\n' +
    '                </table>\n' +
    '                <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-8">\n' +
    '                  <p>Send to MailChimp - Select List</p>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-8">\n' +
    '                  <select class="form-control" ng-model="tags.selectedList" ng-options="item.id as item.name for item in tags.mailChimpList">\n' +
    '                  </select>\n' +
    '                </div>\n' +
    '                <div class="col-md-4">\n' +
    '                  <button class="btn btn-danger" ng-click="tags.sendToList()">\n' +
    '                    Submit\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('tags/views/createtag.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Create New Tag</h4>\n' +
    '  <form class="form">\n' +
    '    <div class="form-group">\n' +
    '      <label for="tagname">Name</label>\n' +
    '      <input type="text" class="form-control" id="tagname" placeholder="Tag Name" ng-model="tagmodal.tagName">\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label for="tagdescript">Description</label>\n' +
    '      <input type="text" class="form-control" id="tagdescript" placeholder="Tag Description" ng-model="tagmodal.tagDescription">\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label for="tagduration">Duration (minutes)</label>\n' +
    '      <input type="number" class="form-control" id="tagduration" ng-model="tagmodal.tagDuration">\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label>Zones</label>\n' +
    '    <ul class="">\n' +
    '      <li class="checkbox" dir-paginate="zone in tagmodal.zones |itemsPerPage: 10">\n' +
    '        <label>\n' +
    '          <input type="checkbox" checklist-model="tagmodal.zoneList" checklist-value="{zoneId:zone.id}">\n' +
    '          {{zone.name}}\n' +
    '        </label>\n' +
    '      </li>\n' +
    '    </ul>\n' +
    '    <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>\n' +
    '  </div>\n' +
    '  </form>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-warning" type="button" ng-click="tagmodal.cancel()">Cancel</button>\n' +
    '  <button class="btn btn-danger" type="button" ng-click="tagmodal.ok()">Submit</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('tandceditor/views/tandceditor.tpl.html',
    '<div class="tandceditor">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <div class="row row-align">\n' +
    '        <h3>Terms &amp; Conditions</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-10 col-md-offset-1">\n' +
    '          <text-angular ng-model="tandceditor.htmlVariable"></text-angular>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-10 col-md-offset-1">\n' +
    '          <button class="btn btn-large btn-danger pull-right" ng-click="tandceditor.createTandC()">Submit</button>\n' +
    '          <button class="btn btn-large btn-danger pull-right" ng-click="tandceditor.deleteTandC()">\n' +
    '            <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '          </button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('twilio-modal/views/twilio-modal.tpl.html',
    '<div class="twilioModal">\n' +
    '  <div class="modal-header">\n' +
    '    <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '  </div>\n' +
    '  <div class="modal-body">\n' +
    '    <div class="form-group">\n' +
    '      <label>Message:</label>\n' +
    '      <textarea class="form-control" rows="5" id="comment" ng-model="TwilioModalCtrl.message" maxlength="140" ng-change="TwilioModalCtrl.setLimit()"></textarea>\n' +
    '    </div>\n' +
    '    <div class="pull-right">\n' +
    '      <p ng-class="{warningText:TwilioModalCtrl.remainingLength < 30 && TwilioModalCtrl.remainingLength > 10, redText:TwilioModalCtrl.remainingLength < 10, greenText:TwilioModalCtrl.remainingLength > 30}">Remaining Characters: {{TwilioModalCtrl.remainingLength}}</p>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="modal-footer">\n' +
    '    <div>\n' +
    '      <button class="btn btn-warning" type="button" ng-click="TwilioModalCtrl.cancel()">Cancel</button>\n' +
    '      <button class="btn btn-danger" type="button" ng-click="TwilioModalCtrl.ok()">Send</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('support/views/support.tpl.html',
    '<div id="support">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Submit A Ticket</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div ng-show="support.ErrorMessage">\n' +
    '        <div class="alert alert-danger" role="alert">\n' +
    '          <button ng-click="support.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '            <span aria-hidden="true">&times;</span>\n' +
    '          </button>\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <span class="sr-only">Error:</span>\n' +
    '          {{support.Message}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <form name="form" ng-submit=" support.createTicket(form.$valid)" role="form" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div ng-hide="activeUser.role === 2">\n' +
    '          <div class="panel panel-default panel-form">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>Venue</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <select name="venue" id="venue" ng-model="support.venue" class="form-control" required>\n' +
    '                  <option ng-repeat="venue in support.venues" value="{{venue.venuetitle}}">{{venue.venuetitle}}</option>\n' +
    '                </select>\n' +
    '                </br>\n' +
    '                <span style="color:red" ng-show="form.venue.$invalid && support.submitted">\n' +
    '                  Venue is required!\n' +
    '                </span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Email</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="email" class="form-control" id="email" name="email" placeholder="eg.: example@gmail.com" ng-model="support.email" required/>\n' +
    '              </br>\n' +
    '              <span style="color:red" ng-show="form.email.$invalid && support.submitted">\n' +
    '                Email is required!\n' +
    '              </span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Name</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="name" name="name" placeholder="eg.: Name" ng-model="support.name" required/>\n' +
    '              </br>\n' +
    '              <span style="color:red" ng-show="form.name.$invalid && support.submitted">\n' +
    '                Name is required!\n' +
    '              </span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Subject</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <select name="subject" id="subject" ng-model="support.subject" class="form-control" required>\n' +
    '                <option ng-repeat="subject in support.subjects" value="{{subject}}">{{subject}}</option>\n' +
    '              </select>\n' +
    '              </br>\n' +
    '              <span style="color:red" ng-show="form.subject.$invalid && support.submitted">\n' +
    '                Subject is required!\n' +
    '              </span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Query</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <textarea rows="5" cols="50" class="form-control" id="query" name="query" placeholder="" ng-model="support.query" required>\n' +
    '              </textarea>\n' +
    '              </br>\n' +
    '              <span style="color:red" ng-show="form.query.$invalid && support.submitted">\n' +
    '                Please, specify your query!\n' +
    '              </span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="pull-right">\n' +
    '          <button type="submit" class="btn btn-danger">Send</button>\n' +
    '          <button type="submit" class="btn btn-info " onclick="history.back()">Cancel</button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div ng-show="support.EmailConfirmMessage">\n' +
    '      <div class="alert alert-success" role="alert" style="width: 40%;">\n' +
    '        <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '        <span class="sr-only">Alert:</span>\n' +
    '        {{support.EmailMessage}}\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div ng-show="support.EmailErrorMessage">\n' +
    '      <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '        <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '        <span class="sr-only">Alert:</span>\n' +
    '        {{support.EmailMessage}}\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('org-infrastructures/views/orginfraeditmodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <h3 class="text-center">Edit Infrastructure</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <form name="createinfrastructure" novalidate>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <!-- <h4>Provider</h4> -->\n' +
    '        <select class="form-control" name="provider" ng-model="orginfraeditModal.infrastructure.provider" ng-options="option.id as option.name for option in orginfraeditModal.providers" ng-change="orginfraeditModal.setProvider(orginfraeditModal.infrastructure.provider)">\n' +
    '          <option value="" disabled selected>- Please Select A Provider -</option>\n' +
    '        </select>\n' +
    '        <div ng-messages="createinfrastructure.provider.$error" ng-if="orginfraeditModal.control.submitted">\n' +
    '          <p ng-message="required" class="text-danger">Provider is required</p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div ng-show="orginfraeditModal.control.provider">\n' +
    '          <!-- Controller Address -->\n' +
    '          <div ng-hide="orginfraeditModal.matchProvider(\'xirrus\', orginfraeditModal.selectedProvider) || orginfraeditModal.matchProvider(\'meraki\', orginfraeditModal.selectedProvider)">\n' +
    '            <h5>Controller Address</h5>\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="controlleraddress" name="controlleraddress" ng-model="orginfraeditModal.infrastructure.controlleraddress" placeholder="eg. controller.example.com" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.controlleraddress.$error" ng-if="orginfraeditModal.control.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Controller Address is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- AUTHENTICATION -->\n' +
    '          <div ng-hide="orginfraeditModal.matchProvider(\'xirrus\', orginfraeditModal.selectedProvider) || orginfraeditModal.matchProvider(\'unifi\', orginfraeditModal.selectedProvider)">\n' +
    '            <h5 ng-if="orginfraeditModal.matchProvider(\'cisco\', orginfraeditModal.selectedProvider)">Authentication</h5>\n' +
    '            <h5 ng-if="orginfraeditModal.matchProvider(\'ruckus\', orginfraeditModal.selectedProvider)">Authentication</h5>\n' +
    '            <h5 ng-if="orginfraeditModal.matchProvider(\'meraki\', orginfraeditModal.selectedProvider)">Api Key</h5>\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="authentication" name="authentication" ng-model="orginfraeditModal.infrastructure.authentication" placeholder="eg. Auth Key">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- Login Credencials -->\n' +
    '          <div ng-hide="orginfraeditModal.matchProvider(\'meraki\', orginfraeditModal.selectedProvider)">\n' +
    '            <h5>Login credentials</h5>\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="username" name="username" ng-model="orginfraeditModal.infrastructure.username" placeholder="Username">\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructuoptionSelectre.username.$error" ng-if="orginfraeditModal.control.submitted">\n' +
    '              <p ng-message="required" class="text-danger">username is required</p>\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '              <input type="password" class="form-control" id="password" name="password" ng-model="orginfraeditModal.infrastructure.password" placeholder="Password">\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.password.$error" ng-if="orginfraeditModal.control.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Controller Address is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div ng-if="orginfraeditModal.matchProvider(\'meraki\', orginfraeditModal.selectedProvider)">\n' +
    '            <h5>Organisations</h5>\n' +
    '            <div class="form-group">\n' +
    '              <select class="form-control" ng-model="orginfraeditModal.infrastructure.username" ng-options="org.id as org.name for org in orginfraeditModal.orgs">\n' +
    '              </select>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- SSID Limit -->\n' +
    '          <div>\n' +
    '            <h5>SSID Limit</h5>\n' +
    '            <div class="form-group">\n' +
    '              <input type="number" class="form-control" id="ssidlimit" name="ssidlimit" ng-model="orginfraeditModal.infrastructure.ssidlimit" placeholder="0" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.ssidlimit.$error" ng-if="orginfraeditModal.control.submitted">\n' +
    '              <p ng-message="required" class="text-danger">SSID Limit is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </form>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="orginfraeditModal.ok()">Submit</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="orginfraeditModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('org-infrastructures/views/orginfradeletemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this Infrastructure?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="orginfradeleteModal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="orginfradeleteModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('org-infrastructures/views/org-infrastructures.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>Infrastructures\n' +
    '        <button class="btn btn-danger btn-md pull-right" ng-click="orgInfrastructures.createInfrastructure()" tooltip-placement="left" tooltip="Add Infrastructure">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-9 col-md-offset-3">\n' +
    '        <div class="col-md-6">\n' +
    '          <div class="input-group">\n' +
    '            <span class="input-group-addon">\n' +
    '              <i class="fa fa-search"></i>\n' +
    '            </span>\n' +
    '            <input type="text" class="form-control" placeholder="Search orgInfrastructures" ng-model="orgInfrastructuresearch">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-6">\n' +
    '          <select class="form-control" ng-model="orgInfrastructures.limit" ng-options="limit for limit in orgInfrastructures.limits" ng-change="orgInfrastructures.setLimit(orgInfrastructures.limit)">\n' +
    '            <option value="">Limit Results</option>\n' +
    '          </select>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-responsive">\n' +
    '      <tbody>\n' +
    '        <tr ng-repeat="infra in orgInfrastructures.infrastructures | filter:orgInfrastructuresearch | limitTo:orgInfrastructures.limit" ng-hide="orgInfrastructures.orgInfrastructureselected.selected && orgInfrastructures.orgInfrastructureselected.infra !== infra.id">\n' +
    '          <td>\n' +
    '            <h4>\n' +
    '              Controller:\n' +
    '            </h4>\n' +
    '            <h5>\n' +
    '              {{infra.controlleraddress}}\n' +
    '            </h5>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>\n' +
    '              User:\n' +
    '            </h4>\n' +
    '            <h5>\n' +
    '              {{infra.username}}\n' +
    '            </h5>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>\n' +
    '              Limit:\n' +
    '            </h4>\n' +
    '            <h5>\n' +
    '              {{infra.ssidlimit}}\n' +
    '            </h5>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-danger btn-md" ng-click="orgInfrastructures.deleteInfrastructure(infra.id)" tooltip-placement="left" tooltip="Delete Infrastructure">\n' +
    '                <span class="fa fa-trash-o" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <button class="btn btn-danger btn-md" ng-click="orgInfrastructures.editInfrastructure(infra.id)" tooltip-placement="left" tooltip="Edit Infrastructure">\n' +
    '                <span class="fa fa-pencil-square-o" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <button class="btn btn-danger btn-md" ng-click="orgInfrastructures.configController(infra)" tooltip-placement="left" tooltip="Manage Controller" ng-show="orgInfrastructures.matchProvider(\'unifi\', infra.provider) || orgInfrastructures.matchProvider(\'meraki\', infra.provider) || orgInfrastructures.matchProvider(\'ruckus\', infra.provider) || orgInfrastructures.matchProvider(\'xirrus\', infra.provider)">\n' +
    '                <i class="fa fa-wifi" aria-hidden="true"></i>\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('org-infrastructures/views/newinfrastructuremodel.tpl.html',
    '<div class="modal-header">\n' +
    '  <h3 class="text-center">Create Infrastructure</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <form name="createinfrastructure" novalidate>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <!-- <h4>Provider</h4> -->\n' +
    '        <select class="form-control" name="provider" ng-model="newinfrastructuremodel.infrastructure.provider" ng-options="option.id as option.name for option in newinfrastructuremodel.providers" ng-change="newinfrastructuremodel.setProvider(newinfrastructuremodel.infrastructure.provider)">\n' +
    '          <option value="" disabled selected>- Please Select A Provider -</option>\n' +
    '        </select>\n' +
    '        <div ng-messages="createinfrastructure.provider.$error" ng-if="newinfrastructuremodel.control.submitted">\n' +
    '          <p ng-message="required" class="text-danger">Provider is required</p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div ng-show="newinfrastructuremodel.control.provider">\n' +
    '          <!-- Controller Address -->\n' +
    '          <div ng-hide="newinfrastructuremodel.matchProvider(\'meraki\', newinfrastructuremodel.selectedProvider) || newinfrastructuremodel.matchProvider(\'xirrus\', newinfrastructuremodel.selectedProvider)">\n' +
    '            <h5>Controller Address</h5>\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="controlleraddress" name="controlleraddress" ng-model="newinfrastructuremodel.infrastructure.controlleraddress" placeholder="eg. controller.example.com" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.controlleraddress.$error" ng-if="newinfrastructuremodel.control.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Controller Address is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- AUTHENTICATION -->\n' +
    '          <div ng-hide="newinfrastructuremodel.matchProvider(\'unifi\', newinfrastructuremodel.selectedProvider)">\n' +
    '            <h5 ng-if="newinfrastructuremodel.matchProvider(\'cisco\', newinfrastructuremodel.selectedProvider)">Authentication</h5>\n' +
    '            <h5 ng-if="newinfrastructuremodel.matchProvider(\'ruckus\', newinfrastructuremodel.selectedProvider)">Northbridge Interface Password</h5>\n' +
    '            <h5 ng-if="newinfrastructuremodel.matchProvider(\'meraki\', newinfrastructuremodel.selectedProvider)">API Key</h5>\n' +
    '            <h5 ng-if="newinfrastructuremodel.matchProvider(\'xirrus\', newinfrastructuremodel.selectedProvider)">X-API-Key</h5>\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="authentication" name="authentication" ng-model="newinfrastructuremodel.infrastructure.authentication" placeholder="eg. Auth Key">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <!-- Login Credencials -->\n' +
    '          <div ng-hide="newinfrastructuremodel.matchProvider(\'meraki\', newinfrastructuremodel.selectedProvider)">\n' +
    '            <h5>Login credentials</h5>\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="username" name="username" ng-model="newinfrastructuremodel.infrastructure.username" placeholder="Username">\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.username.$error" ng-if="newinfrastructuremodel.control.submitted">\n' +
    '              <p ng-message="required" class="text-danger">username is required</p>\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '              <input type="password" class="form-control" id="password" name="password" ng-model="newinfrastructuremodel.infrastructure.password" placeholder="Password">\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.password.$error" ng-if="newinfrastructuremodel.control.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Controller Address is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div ng-hide="newinfrastructuremodel.validApiKey || !newinfrastructuremodel.matchProvider(\'meraki\', newinfrastructuremodel.selectedProvider) ">\n' +
    '            <button class="btn btn-danger" ng-click="newinfrastructuremodel.getOrgForInfrastructure()">Validate</button>\n' +
    '          </div>\n' +
    '\n' +
    '          <div ng-if="newinfrastructuremodel.validApiKey">\n' +
    '            <h5>Organisations</h5>\n' +
    '            <div class="form-group">\n' +
    '              <select class="form-control" ng-model="newinfrastructuremodel.infrastructure.username" ng-options="org.id as org.name for org in newinfrastructuremodel.orgs">\n' +
    '                <option value="" disabled selected>- Please Select An Organisations -</option>\n' +
    '              </select>\n' +
    '              <!-- <input type="text" class="form-control" id="username" name="username" ng-model="newinfrastructuremodel.infrastructure.username" placeholder="OrgID"> -->\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '\n' +
    '          <!-- SSID Limit -->\n' +
    '          <div>\n' +
    '            <h5>SSID Limit</h5>\n' +
    '            <div class="form-group">\n' +
    '              <input type="number" class="form-control" id="ssidlimit" name="ssidlimit" ng-model="newinfrastructuremodel.infrastructure.ssidlimit" placeholder="0" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.ssidlimit.$error" ng-if="newinfrastructuremodel.control.submitted">\n' +
    '              <p ng-message="required" class="text-danger">SSID Limit is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </form>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="newinfrastructuremodel.ok()">Submit</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="newinfrastructuremodel.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('ppolicy/views/ppolicy.tpl.html',
    '<div class="container">\n' +
    '  <div class="col-md-10 side-text">\n' +
    '    <style>\n' +
    '      #ppBody {\n' +
    '        font-size: 11pt;\n' +
    '        width: 100%;\n' +
    '        margin: 0 auto;\n' +
    '        text-align: justify;\n' +
    '      }\n' +
    '\n' +
    '      #ppHeader {\n' +
    '        font-family: verdana;\n' +
    '        font-size: 21pt;\n' +
    '        width: 100%;\n' +
    '        margin: 0 auto;\n' +
    '      }\n' +
    '\n' +
    '      .ppConsistencies {\n' +
    '        display: none;\n' +
    '      }\n' +
    '    </style>\n' +
    '    <div id=\'ppHeader\'>www.liquidedge.io Privacy Policy</div>\n' +
    '    <div id=\'ppBody\'>\n' +
    '      <div class=\'ppConsistencies\'>\n' +
    '        <div class=\'col-2\'>\n' +
    '          <div class="quick-links text-center">Information Collection</div>\n' +
    '        </div>\n' +
    '        <div class=\'col-2\'>\n' +
    '          <div class="quick-links text-center">Information Usage</div>\n' +
    '        </div>\n' +
    '        <div class=\'col-2\'>\n' +
    '          <div class="quick-links text-center">Information Protection</div>\n' +
    '        </div>\n' +
    '        <div class=\'col-2\'>\n' +
    '          <div class="quick-links text-center">Cookie Usage</div>\n' +
    '        </div>\n' +
    '        <div class=\'col-2\'>\n' +
    '          <div class="quick-links text-center">3rd Party Disclosure</div>\n' +
    '        </div>\n' +
    '        <div class=\'col-2\'>\n' +
    '          <div class="quick-links text-center">3rd Party Links</div>\n' +
    '        </div>\n' +
    '        <div class=\'col-2\'></div>\n' +
    '      </div>\n' +
    '      <div style=\'clear:both;height:10px;\'></div>\n' +
    '      <div class=\'ppConsistencies\'>\n' +
    '        <div class=\'col-2\'>\n' +
    '          <div class="col-12 quick-links2 gen-text-center">Google AdSense</div>\n' +
    '        </div>\n' +
    '        <div class=\'col-2\'>\n' +
    '          <div class="col-12 quick-links2 gen-text-center">\n' +
    '            Fair Information Practices\n' +
    '            <div class="col-8 gen-text-left gen-xs-text-center" style="font-size:12px;position:relative;left:20px;">Fair information\n' +
    '              <br> Practices</div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class=\'col-2\'>\n' +
    '          <div class="col-12 quick-links2 gen-text-center coppa-pad">\n' +
    '            COPPA\n' +
    '\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class=\'col-2\'>\n' +
    '          <div class="col-12 quick-links2 quick4 gen-text-center caloppa-pad">\n' +
    '            CalOPPA\n' +
    '\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class=\'col-2\'>\n' +
    '          <div class="quick-links2 gen-text-center">Our Contact Information\n' +
    '            <br>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div style=\'clear:both;height:10px;\'></div>\n' +
    '      <div class=\'innerText\'>This privacy policy has been compiled to better serve those who are concerned with how their \'Personally Identifiable Information\' (PII) is being used online. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.\n' +
    '        <br>\n' +
    '      </div>\n' +
    '      <span id=\'infoCo\'></span>\n' +
    '      <br>\n' +
    '      <div class=\'grayText\'>\n' +
    '        <strong>What personal information do we collect from the people that visit our blog, website or app?</strong>\n' +
    '      </div>\n' +
    '      <br />\n' +
    '      <div class=\'innerText\'>When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, Mac Address or other details to help you with your experience.</div>\n' +
    '      <br>\n' +
    '      <div class=\'grayText\'>\n' +
    '        <strong>When do we collect information?</strong>\n' +
    '      </div>\n' +
    '      <br />\n' +
    '      <div class=\'innerText\'>We collect information from you when you register on our site or enter information on our site.</div>\n' +
    '      <br>Login to WIFI\n' +
    '      <span id=\'infoUs\'></span>\n' +
    '      <br>\n' +
    '      <div class=\'grayText\'>\n' +
    '        <strong>How do we use your information? </strong>\n' +
    '      </div>\n' +
    '      <br />\n' +
    '      <div class=\'innerText\'> We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:\n' +
    '        <br>\n' +
    '        <br>\n' +
    '      </div>\n' +
    '      <div class=\'innerText\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
    '        <strong>&bull;</strong> To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</div>\n' +
    '      <div class=\'innerText\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
    '        <strong>&bull;</strong> To allow us to better service you in responding to your customer service requests.</div>\n' +
    '      <div class=\'innerText\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
    '        <strong>&bull;</strong> To administer a contest, promotion, survey or other site feature.</div>\n' +
    '      <span id=\'infoPro\'></span>\n' +
    '      <br>\n' +
    '      <div class=\'grayText\'>\n' +
    '        <strong>How do we protect your information?</strong>\n' +
    '      </div>\n' +
    '      <br />\n' +
    '      <div class=\'innerText\'>We do not use vulnerability scanning and/or scanning to PCI standards.</div>\n' +
    '      <div class=\'innerText\'>We only provide articles and information. We never ask for credit card numbers.</div>\n' +
    '      <div class=\'innerText\'>We do not use Malware Scanning.\n' +
    '        <br>\n' +
    '        <br>\n' +
    '      </div>\n' +
    '      <div class=\'innerText\'>Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology. </div>\n' +
    '      <br>\n' +
    '      <div class=\'innerText\'>We implement a variety of security measures when a user enters, submits, or accesses their information to maintain the safety of your personal information.</div>\n' +
    '      <br>\n' +
    '      <div class=\'innerText\'>All transactions are processed through a gateway provider and are not stored or processed on our servers.</div>\n' +
    '      <span id=\'coUs\'></span>\n' +
    '      <br>\n' +
    '      <div class=\'grayText\'>\n' +
    '        <strong>Do we use \'cookies\'?</strong>\n' +
    '      </div>\n' +
    '      <br />\n' +
    '      <div class=\'innerText\'>Yes. Cookies are small files that a site or its service provider transfers to your computer\'s hard drive through your Web browser (if you allow) that enables the site\'s or service provider\'s systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</div>\n' +
    '      <div class=\'innerText\'>\n' +
    '        <br>\n' +
    '        <strong>We use cookies to:</strong>\n' +
    '      </div>\n' +
    '      <div class=\'innerText\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
    '        <strong>&bull;</strong> Understand and save user\'s preferences for future visits.</div>\n' +
    '      <div class=\'innerText\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
    '        <strong>&bull;</strong> Keep track of advertisements.</div>\n' +
    '      <div class=\'innerText\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
    '        <strong>&bull;</strong> Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third-party services that track this information on our behalf.</div>\n' +
    '      <div class=\'innerText\'>\n' +
    '        <br>You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browser is a little different, look at your browser\'s Help Menu to learn the correct way to modify your cookies.\n' +
    '        <br>\n' +
    '      </div>\n' +
    '      <br>\n' +
    '      <div class=\'innerText\'>If you turn cookies off, Some of the features that make your site experience more efficient may not function properly.It won\'t affect the user\'s experience that make your site experience more efficient and may not function properly.</div>\n' +
    '      <br>\n' +
    '      <span id=\'trDi\'></span>\n' +
    '      <br>\n' +
    '      <div class=\'grayText\'>\n' +
    '        <strong>Third-party disclosure</strong>\n' +
    '      </div>\n' +
    '      <br />\n' +
    '      <div class=\'innerText\'>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it\'s release is appropriate to comply with the law, enforce our site policies, or protect ours or others\' rights, property or safety.\n' +
    '        <br>\n' +
    '        <br> However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses. </div>\n' +
    '      <div class=\'innerText\'>Email</div>\n' +
    '      <span id=\'trLi\'></span>\n' +
    '      <br>\n' +
    '      <div class=\'grayText\'>\n' +
    '        <strong>Third-party links</strong>\n' +
    '      </div>\n' +
    '      <br />\n' +
    '      <div class=\'innerText\'>Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</div>\n' +
    '      <span id=\'gooAd\'></span>\n' +
    '      <br>\n' +
    '      <div class=\'blueText\'>\n' +
    '        <strong>Google</strong>\n' +
    '      </div>\n' +
    '      <br />\n' +
    '      <div class=\'innerText\'>Google\'s advertising requirements can be summed up by Google\'s Advertising Principles. They are put in place to provide a positive experience for users. https://support.google.com/adwordspolicy/answer/1316548?hl=en\n' +
    '        <br>\n' +
    '        <br>\n' +
    '      </div>\n' +
    '      <div class=\'innerText\'>We have not enabled Google AdSense on our site but we may do so in the future.</div>\n' +
    '      <span id=\'calOppa\'></span>\n' +
    '      <br>\n' +
    '      <div class=\'blueText\'>\n' +
    '        <strong>California Online Privacy Protection Act</strong>\n' +
    '      </div>\n' +
    '      <br />\n' +
    '      <div class=\'innerText\'>CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law\'s reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared. - See more at: http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf\n' +
    '        <br>\n' +
    '      </div>\n' +
    '      <div class=\'innerText\'>\n' +
    '        <br>\n' +
    '        <strong>According to CalOPPA, we agree to the following:</strong>\n' +
    '        <br>\n' +
    '      </div>\n' +
    '      <div class=\'innerText\'>Users can visit our site anonymously.</div>\n' +
    '      <div class=\'innerText\'>Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant page after entering our website.\n' +
    '        <br>\n' +
    '      </div>\n' +
    '      <div class=\'innerText\'>Our Privacy Policy link includes the word \'Privacy\' and can easily be found on the page specified above.</div>\n' +
    '      <div class=\'innerText\'>\n' +
    '        <br>You will be notified of any Privacy Policy changes:</div>\n' +
    '      <div class=\'innerText\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
    '        <strong>&bull;</strong> On our Privacy Policy Page\n' +
    '        <br>\n' +
    '      </div>\n' +
    '      <div class=\'innerText\'>Can change your personal information:</div>\n' +
    '      <div class=\'innerText\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
    '        <strong>&bull;</strong> By logging in to your account</div>\n' +
    '      <div class=\'innerText\'>\n' +
    '        <br>\n' +
    '        <strong>How does our site handle Do Not Track signals?</strong>\n' +
    '        <br>\n' +
    '      </div>\n' +
    '      <div class=\'innerText\'>We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place. </div>\n' +
    '      <div class=\'innerText\'>\n' +
    '        <br>\n' +
    '        <strong>Does our site allow third-party behavioral tracking?</strong>\n' +
    '        <br>\n' +
    '      </div>\n' +
    '      <div class=\'innerText\'>It\'s also important to note that we do not allow third-party behavioral tracking</div>\n' +
    '      <span id=\'coppAct\'></span>\n' +
    '      <br>\n' +
    '      <div class=\'blueText\'>\n' +
    '        <strong>COPPA (Children Online Privacy Protection Act)</strong>\n' +
    '      </div>\n' +
    '      <br />\n' +
    '      <div class=\'innerText\'>When it comes to the collection of personal information from children under the age of 13 years old, the Children\'s Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, United States\' consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children\'s privacy and safety online.\n' +
    '        <br>\n' +
    '        <br>\n' +
    '      </div>\n' +
    '      <div class=\'innerText\'>We do not specifically market to children under the age of 13 years old.</div>\n' +
    '      <span id=\'ftcFip\'></span>\n' +
    '      <br>\n' +
    '      <div class=\'blueText\'>\n' +
    '        <strong>Fair Information Practices</strong>\n' +
    '      </div>\n' +
    '      <br />\n' +
    '      <div class=\'innerText\'>The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.\n' +
    '        <br>\n' +
    '        <br>\n' +
    '      </div>\n' +
    '      <div class=\'innerText\'>\n' +
    '        <strong>In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:</strong>\n' +
    '      </div>\n' +
    '      <div class=\'innerText\'>We will notify you via email</div>\n' +
    '      <div class=\'innerText\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
    '        <strong>&bull;</strong> Within 7 business days</div>\n' +
    '      <div class=\'innerText\'>\n' +
    '        <br>We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.</div>\n' +
    '      <span id=\'canSpam\'></span>\n' +
    '      <br>\n' +
    '      <div class=\'blueText\'>\n' +
    '        <strong>CAN SPAM Act</strong>\n' +
    '      </div>\n' +
    '      <br />\n' +
    '      <div class=\'innerText\'>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.\n' +
    '        <br>\n' +
    '        <br>\n' +
    '      </div>\n' +
    '      <div class=\'innerText\'>\n' +
    '        <strong>We collect your email address in order to:</strong>\n' +
    '      </div>\n' +
    '      <div class=\'innerText\'>\n' +
    '        <br>\n' +
    '        <strong>To be in accordance with CANSPAM, we agree to the following:</strong>\n' +
    '      </div>\n' +
    '      <div class=\'innerText\'>\n' +
    '        <strong>\n' +
    '          <br>If at any time you would like to unsubscribe from receiving future emails, you can email us at</strong>\n' +
    '      </div> and we will promptly remove you from\n' +
    '      <strong>ALL</strong> correspondence.</div>\n' +
    '    <br>\n' +
    '    <span id=\'ourCon\'></span>\n' +
    '    <br>\n' +
    '    <div class=\'blueText\'>\n' +
    '      <strong>Contacting Us</strong>\n' +
    '    </div>\n' +
    '    <br />\n' +
    '    <div class=\'innerText\'>If there are any questions regarding this privacy policy, you may contact us using the information below.\n' +
    '      <br>\n' +
    '      <br>\n' +
    '    </div>\n' +
    '    <div class=\'innerText\'>www.liquidedge.io</div>\n' +
    '    <div class=\'innerText\'>ArcLabs, Carriganore</div>Waterford,\n' +
    '    <div class=\'innerText\'>Ireland</div>\n' +
    '    <div class=\'innerText\'>support@liquidedge.io</div>\n' +
    '    <div class=\'innerText\'>\n' +
    '      <br>Last Edited on 2017-04-05</div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('selectimage/views/selectimage.tpl.html',
    '<div class="selectimage">\n' +
    '  <div class="modal-header">\n' +
    '    <img class="modal-img" src="./images/magnetnetworks/magnet_logo_small.png">\n' +
    '    <p class="input-group">\n' +
    '      <div class="input-group-addon"><i class="fa fa-search"></i></div>\n' +
    '      <input type="text" class="form-control" placeholder="Search Images" ng-model="imageSearch">\n' +
    '    </p>\n' +
    '  </div>\n' +
    '  <div class="modal-body imageList">\n' +
    '    <div class="row fix">\n' +
    '      <div class="col-md-3 thumb" ng-repeat="image in selectimage.imageList | filter:imageSearch">\n' +
    '        <label ng-dblclick="selectimage.ok()">\n' +
    '          <img class="img-responsive" ng-src="{{staticUrl}}{{image.imageuri}}" />\n' +
    '          <div class="row">\n' +
    '            <input type="radio" ng-model="selectimage.selectedImage" value="{{image}}">\n' +
    '          </div>\n' +
    '        </label>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="modal-footer">\n' +
    '    <div>\n' +
    '      <button class="btn btn-danger" type="button" ng-click="selectimage.ok()">Done</button>\n' +
    '      <button class="btn btn-warning" type="button" ng-click="selectimage.cancel()">Cancel</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('newvenueanalytics/views/newvenueanalytics.tpl.html',
    '<div class="newvenueanalytics">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <div class="row row-align">\n' +
    '        <h3>Venue Analytics: {{newvenueanalytics.venue.venuetitle}}</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <ul class="nav nav-tabs">\n' +
    '        <li ng-class="{\'active\': (newvenueanalytics.tab === \'live\')}"><a href="" ng-click="newvenueanalytics.switchTab(\'live\')">Live</a></li>\n' +
    '        <li ng-class="{\'active\': (newvenueanalytics.tab === \'historic\')}"><a href="" ng-click="newvenueanalytics.switchTab(\'historic\')">Historic</a></li>\n' +
    '        <li ng-class="{\'active\': (newvenueanalytics.tab === \'users\')}"><a href="" ng-click="newvenueanalytics.switchTab(\'users\')">Customer Profiles</a></li>\n' +
    '      </ul>\n' +
    '      <div ng-show="newvenueanalytics.tab === \'live\'">\n' +
    '        <div class="row">\n' +
    '          <div class="col-sm-6">\n' +
    '            <h3 class="margin-analytics-xs">Activity</h3>\n' +
    '            <canvas id="line" class="chart chart-line" chart-data="newvenueanalytics.liveDataChart.data" chart-labels="newvenueanalytics.liveDataChart.labels" chart-series="newvenueanalytics.liveDataChart.series" chart-options="newvenueanalytics.liveDataChart.options" chart-colors="newvenueanalytics.chartColors">\n' +
    '            </canvas>\n' +
    '          </div>\n' +
    '          <div class="col-sm-6">\n' +
    '            <h3 class="margin-analytics-xs">Activity By Zone\n' +
    '              <button ng-click="newvenueanalytics.showActivityZoneTable=!newvenueanalytics.showActivityZoneTable" class="btn btn-danger pull-right">\n' +
    '                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="newvenueanalytics.showActivityZoneTable"></i>\n' +
    '                <i class="fa fa-table" aria-hidden="true" ng-show="!newvenueanalytics.showActivityZoneTable"></i>\n' +
    '              </button>\n' +
    '            </h3>\n' +
    '            <div class="cWrap">\n' +
    '              <div ng-if="!newvenueanalytics.showActivityZoneTable" class="animated-class chartFront">\n' +
    '                <canvas id="base" class="chart chart-bar" chart-data="newvenueanalytics.connectedUsersBarChart.data" chart-labels="newvenueanalytics.connectedUsersBarChart.labels" chart-options="newvenueanalytics.connectedUsersBarChart.options" chart-series="newvenueanalytics.connectedUsersBarChart.series" chart-colors="newvenueanalytics.chartColors" chart-click="newvenueanalytics.setShowActivityZoneTable">\n' +
    '              </div>\n' +
    '              <div class="animated-class" ng-if="newvenueanalytics.showActivityZoneTable">\n' +
    '                <table class="table table-responsive table-striped table-bordered">\n' +
    '                  <thead>\n' +
    '                    <tr>\n' +
    '                      <th ng-repeat="labels in newvenueanalytics.connectedTable.zoneheaders">{{labels}}</th>\n' +
    '                    </tr>\n' +
    '                  </thead>\n' +
    '                  <tbody>\n' +
    '                    <tr ng-repeat="item in newvenueanalytics.connectedTable.zones | orderBy:\'-count\'">\n' +
    '                      <td>{{item.name}}</td>\n' +
    '                      <td>{{item.count}}</td>\n' +
    '                    </tr>\n' +
    '                  </tbody>\n' +
    '                </table>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div ng-show="newvenueanalytics.tab === \'historic\'">\n' +
    '        <div class="form-row margin_top dateFix">\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="startdate" class="control-label pull-left">START DATE</label>\n' +
    '            <input ng-change="newvenueanalytics.refresh()" ng-click="newvenueanalytics.openCalSt()" type="text" class="form-control" min-date="newvenueanalytics.minDate" max-date="newvenueanalytics.endtime" name="startDate" datepicker-popup="{{newvenueanalytics.dateFormat}}" ng-model="newvenueanalytics.starttime" is-open="newvenueanalytics.status.openedSt" datepicker-options="newvenueanalytics.dateOptions" ng-required="true" close-text="Close" />\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <button type="button" class="btn btn-date" ng-click="newvenueanalytics.openCalSt()">\n' +
    '              <i class="glyphicon glyphicon-calendar"></i>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="endtime" class="control-label pull-left">END DATE</label>\n' +
    '            <input ng-change="newvenueanalytics.refresh()" ng-click="newvenueanalytics.openCalEnd()" type="text" class="form-control" min-date="newvenueanalytics.starttime" max-date="newvenueanalytics.today" datepicker-popup="{{newvenueanalytics.dateFormat}}" ng-model="newvenueanalytics.endtime" is-open="newvenueanalytics.status.openedEnd" datepicker-options="newvenueanalytics.dateOptions" ng-required="true" close-text="Close" />\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <button type="button" class="btn btn-date" ng-click="newvenueanalytics.openCalEnd()">\n' +
    '              <i class="glyphicon glyphicon-calendar"></i>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="col-sm-6">\n' +
    '            <h3 class="margin-analytics-xs">Footfall\n' +
    '              <button ng-click="newvenueanalytics.showFootfallTable=!newvenueanalytics.showFootfallTable" class="btn btn-danger pull-right">\n' +
    '                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="newvenueanalytics.showFootfallTable"></i>\n' +
    '                <i class="fa fa-table" aria-hidden="true" ng-show="!newvenueanalytics.showFootfallTable"></i>\n' +
    '              </button>\n' +
    '            </h3>\n' +
    '            <div ng-show="newvenueanalytics.loading" class="loader">\n' +
    '              <img src="./images/gears.gif" alt="">\n' +
    '              <p>Generating Visitor Data</p>\n' +
    '              </dev>\n' +
    '            </div>\n' +
    '            <div class="cWrap" ng-hide="newvenueanalytics.loading">\n' +
    '              <div ng-if="!newvenueanalytics.showFootfallTable" class="animated-class chartFront">\n' +
    '                <canvas id="base" class="chart chart-bar" chart-data="newvenueanalytics.visitorsLoginData.data" chart-labels="newvenueanalytics.visitorsLoginData.labels" chart-colors="newvenueanalytics.chartColors" chart-dataset-override="newvenueanalytics.visitorsLoginData.datasetOverride" chart-options="newvenueanalytics.mixedChartOptions">\n' +
    '                </canvas>\n' +
    '              </div>\n' +
    '              <div class="animated-class" ng-if="newvenueanalytics.showFootfallTable">\n' +
    '                <table class="table table-striped table-bordered">\n' +
    '                  <thead>\n' +
    '                    <tr>\n' +
    '                      <th ng-repeat="header in newvenueanalytics.footfallTable.headers">{{header}}</th>\n' +
    '                    </tr>\n' +
    '                  </thead>\n' +
    '                  <tbody>\n' +
    '                    <tr ng-repeat="item in newvenueanalytics.footfallTable.rows">\n' +
    '                      <td>{{item.type}}</td>\n' +
    '                      <td>{{item.newVisitors}}</td>\n' +
    '                      <td>{{item.returningVisitors}}</td>\n' +
    '                      <td>{{item.total}}</td>\n' +
    '                    </tr>\n' +
    '                  </tbody>\n' +
    '                </table>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="col-sm-2 col-sm-offset-3 col-xs-offset-2 analytics-block-sm">\n' +
    '            <div class="row margin-analytics-xs margin-analytics-top">\n' +
    '              <p>Gender</p>\n' +
    '              <ul class="list-unstyled gender-list">\n' +
    '                <li class="blueicon">\n' +
    '                  <strong><i class="fa fa-male" aria-hidden="true"></i> : {{newvenueanalytics.genderMale}}</strong>\n' +
    '                </li>\n' +
    '                <li class="pinkicon">\n' +
    '                  <strong><i class="fa fa-female" aria-hidden="true"></i> : {{newvenueanalytics.genderFemale}}</strong>\n' +
    '                </li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '            <div class="row margin-analytics-xs">\n' +
    '              <p>Unique Visitors</p>\n' +
    '              <p class="analytictext greenicon">\n' +
    '                <strong><i class="fa fa-users" aria-hidden="true"></i> : {{newvenueanalytics.uniqueUsers}}</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '            <div class="row margin-analytics-xs">\n' +
    '              <p>Total Logins</p>\n' +
    '              <p class="analytictext darkicon">\n' +
    '                <strong><i class="fa fa-users" aria-hidden="true"></i> : {{newvenueanalytics.totalLogin}}</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '            <div class="row margin-analytics-xs">\n' +
    '              <p>Total Impressions</p>\n' +
    '              <p class="analytictext darkicon">\n' +
    '                <strong><i class="fa fa-mobile" aria-hidden="true"></i> : {{newvenueanalytics.totalImpressions}}</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="col-sm-6">\n' +
    '            <h3 class="margin-analytics-xs">Login Method\n' +
    '              <button ng-click="newvenueanalytics.showLoginTable=!newvenueanalytics.showLoginTable" class="btn btn-danger pull-right">\n' +
    '                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="newvenueanalytics.showLoginTable"></i>\n' +
    '                <i class="fa fa-table" aria-hidden="true" ng-show="!newvenueanalytics.showLoginTable"></i>\n' +
    '              </button>\n' +
    '            </h3>\n' +
    '            <div class="cWrap">\n' +
    '              <div ng-if="!newvenueanalytics.showLoginTable" class="animated-class chartFront">\n' +
    '                <canvas id="base" class="chart-horizontal-bar" chart-data="newvenueanalytics.horizontal.data" chart-labels="newvenueanalytics.horizontal.labels" chart-options="newvenueanalytics.horizontal.options" chart-series="newvenueanalytics.horizontal.series" chart-colors="newvenueanalytics.typeChartColors">\n' +
    '                </canvas>\n' +
    '              </div>\n' +
    '              <div class="animated-class" ng-if="newvenueanalytics.showLoginTable">\n' +
    '                <table class="table table-responsive table-striped table-bordered">\n' +
    '                  <thead>\n' +
    '                    <tr>\n' +
    '                      <th ng-repeat="header in newvenueanalytics.loginTable.headers">{{header}}</th>\n' +
    '                    </tr>\n' +
    '                  </thead>\n' +
    '                  <tbody>\n' +
    '                    <tr ng-repeat="row in newvenueanalytics.loginTable.rows">\n' +
    '                      <td>{{row.type}}</td>\n' +
    '                      <td>{{row.count}}</td>\n' +
    '                    </tr>\n' +
    '                  </tbody>\n' +
    '                </table>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="col-sm-6">\n' +
    '            <h3 class="margin-analytics-xs">Gender Demographics\n' +
    '              <button ng-click="newvenueanalytics.showgenderTable=!newvenueanalytics.showgenderTable" class="btn btn-danger pull-right">\n' +
    '                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="newvenueanalytics.showgenderTable"></i>\n' +
    '                <i class="fa fa-table" aria-hidden="true" ng-show="!newvenueanalytics.showgenderTable"></i>\n' +
    '              </button>\n' +
    '            </h3>\n' +
    '            <div class="cWrap">\n' +
    '              <div ng-if="!newvenueanalytics.showgenderTable" class="animated-class chartFront">\n' +
    '                <canvas id="base" class="chart chart-bar" chart-data="newvenueanalytics.genderBarChart.data" chart-labels="newvenueanalytics.genderBarChart.labels" chart-options="newvenueanalytics.genderBarChart.options" chart-series="newvenueanalytics.genderBarChart.series" chart-colors="newvenueanalytics.genderChartColors">\n' +
    '              </div>\n' +
    '              <div class="animated-class" ng-if="newvenueanalytics.showgenderTable">\n' +
    '                <table class="table table-responsive table-striped table-bordered">\n' +
    '                  <thead>\n' +
    '                    <tr>\n' +
    '                      <th ng-repeat="header in newvenueanalytics.genderTable.headers">{{header}}</th>\n' +
    '                    </tr>\n' +
    '                  </thead>\n' +
    '                  <tbody>\n' +
    '                    <tr ng-repeat="row in newvenueanalytics.genderTable.rows">\n' +
    '                      <td>{{row.type}}</td>\n' +
    '                      <td>{{row.count}}</td>\n' +
    '                    </tr>\n' +
    '                  </tbody>\n' +
    '                </table>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="col-sm-6">\n' +
    '            <h3 class="margin-analytics-xs">Splashpage Impressions\n' +
    '              <button ng-click="newvenueanalytics.showImpressionsTable=!newvenueanalytics.showImpressionsTable" class="btn btn-danger pull-right">\n' +
    '                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="newvenueanalytics.showImpressionsTable"></i>\n' +
    '                <i class="fa fa-table" aria-hidden="true" ng-show="!newvenueanalytics.showImpressionsTable"></i>\n' +
    '              </button>\n' +
    '            </h3>\n' +
    '            <div class="cWrap">\n' +
    '              <div ng-if="!newvenueanalytics.showImpressionsTable" class="animated-class chartFront">\n' +
    '                <canvas id="line" class="chart chart-line" chart-data="newvenueanalytics.splashLoadChart.data" chart-labels="newvenueanalytics.splashLoadChart.labels" chart-series="newvenueanalytics.splashLoadChart.series" chart-options="newvenueanalytics.splashLoadChart.options" chart-colors="newvenueanalytics.chartColors"></canvas>\n' +
    '              </div>\n' +
    '              <div class="animated-class" ng-if="newvenueanalytics.showImpressionsTable">\n' +
    '                <table class="table table-responsive table-striped table-bordered">\n' +
    '                  <thead>\n' +
    '                    <tr>\n' +
    '                      <th>Date/Time</th>\n' +
    '                      <th ng-repeat="series in newvenueanalytics.splashLoadChart.series">{{series}}</th>\n' +
    '                    </tr>\n' +
    '                  </thead>\n' +
    '                  <tbody>\n' +
    '                    <tr ng-repeat="label in newvenueanalytics.splashLoadChart.labels">\n' +
    '                      <td>{{label}}</td>\n' +
    '                      <td ng-repeat="item in newvenueanalytics.splashLoadChart.data"> {{item[$parent.$index]}}</td>\n' +
    '                    </tr>\n' +
    '                  </tbody>\n' +
    '                </table>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="col-sm-6">\n' +
    '            <h3 class="margin-analytics-xs">Average Dwell Time\n' +
    '              <button ng-click="newvenueanalytics.showDwellTable=!newvenueanalytics.showDwellTable" class="btn btn-danger pull-right">\n' +
    '                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="newvenueanalytics.showImpressionsTable"></i>\n' +
    '                <i class="fa fa-table" aria-hidden="true" ng-show="!newvenueanalytics.showImpressionsTable"></i>\n' +
    '              </button>\n' +
    '            </h3>\n' +
    '            <div ng-show="newvenueanalytics.dwellLoading" class="loader">\n' +
    '              <img src="./images/gears.gif" alt="">\n' +
    '              <p>Generating Visitor Data</p>\n' +
    '              </dev>\n' +
    '            </div>\n' +
    '            <div class="cWrap" ng-hide="newvenueanalytics.dwellLoading">\n' +
    '              <div ng-if="!newvenueanalytics.showDwellTable" class="animated-class chartFront">\n' +
    '                <canvas id="dwell" class="chart chart-bar" chart-data="newvenueanalytics.dwellChart.data" chart-labels="newvenueanalytics.dwellChart.labels" chart-options="newvenueanalytics.dwellChart.options" chart-series="newvenueanalytics.dwellChart.series" chart-colors="newvenueanalytics.chartColors">\n' +
    '                </canvas>\n' +
    '              </div>\n' +
    '              <div class="animated-class" ng-if="newvenueanalytics.showDwellTable">\n' +
    '                <table class="table table-striped table-bordered">\n' +
    '                  <thead>\n' +
    '                    <tr>\n' +
    '                      <th ng-repeat="header in newvenueanalytics.dwellTable.zoneheaders">{{header}}</th>\n' +
    '                    </tr>\n' +
    '                  </thead>\n' +
    '                  <tbody>\n' +
    '                    <tr ng-repeat="item in newvenueanalytics.dwellTable.zones | orderBy:\'-time\'">\n' +
    '                      <td>{{item.name}}</td>\n' +
    '                      <td>{{item.time}}</td>\n' +
    '                    </tr>\n' +
    '                  </tbody>\n' +
    '                </table>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row" ng-if="newvenueanalytics.tab === \'historic\' && newvenueanalytics.maps.length > 0">\n' +
    '          <div class="col-sm-12">\n' +
    '            <h3 class="margin-analytics-xs">HeatMap - {{newvenueanalytics.mapType}} </h3>\n' +
    '            <div class="mapControls row">\n' +
    '              <!-- DatePicker -->\n' +
    '              <div class="col-sm-4 dateFix">\n' +
    '                <div class="form-group text-box long cell cell-pad">\n' +
    '                  <label class="control-label pull-left">MAP TYPE <i class="fa fa-info-circle" aria-hidden="true" tooltip-placement="right" tooltip="{{newvenueanalytics.toolTipMessage}}"></i></label>\n' +
    '                  <select class="form-control" ng-model="newvenueanalytics.mapType" ng-change="newvenueanalytics.typeChange()">\n' +
    '                    <option value="Users">Users</option>\n' +
    '                    <option value="Dwell">Dwell</option>\n' +
    '                    <option value="Active Minutes">Active Minutes</option>\n' +
    '                  </select>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="col-sm-4 dateFix">\n' +
    '                <div class="form-group text-box long cell cell-pad">\n' +
    '                  <label class="control-label pull-left">HEATMAP DATE</label>\n' +
    '                  <input ng-change="newvenueanalytics.mapDayChange()" ng-click="newvenueanalytics.openCalHeatMap()" type="text" class="form-control" min-date="newvenueanalytics.minDate" max-date="newvenueanalytics.today" name="startDate" datepicker-popup="{{newvenueanalytics.dateFormat}}" ng-model="newvenueanalytics.heatMapDate" is-open="newvenueanalytics.status.openedHeatMap" datepicker-options="newvenueanalytics.dateOptions" ng-required="true" close-text="Close" />\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <!-- Floor Select -->\n' +
    '              <div class="col-sm-4">\n' +
    '                <div class="form-group text-box long cell cell-pad">\n' +
    '                  <label class="control-label pull-left">SELECT MAP</label>\n' +
    '                  <select class="form-control" ng-model="newvenueanalytics.map" ng-options="map.name for map in newvenueanalytics.maps" ng-change="newvenueanalytics.mapChange()">\n' +
    '                  </select>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row">\n' +
    '              <!-- Slider -->\n' +
    '              <div class="col-sm-12">\n' +
    '                <br/>\n' +
    '                <br/>\n' +
    '                <label class="control-label pull-left">Select Hour</label>\n' +
    '                <rzslider rz-slider-model="newvenueanalytics.heatmapSlider.value" rz-slider-options="newvenueanalytics.heatmapSlider.options"></rzslider>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row">\n' +
    '              <!-- Map -->\n' +
    '              <div class="col-sm-1">\n' +
    '                <br/>\n' +
    '                <br/>\n' +
    '                <br/>\n' +
    '                <div ng-hide="newvenueanalytics.mapType === \'Dwell\'">\n' +
    '                  <label class="control-label pull-left">Heat Scale</label>\n' +
    '                  <br/>\n' +
    '                  <br/>\n' +
    '                  <div id="HeatmapGradiant" class="pull-left" style="height: {{newvenueanalytics.scaleHeight}}px; width: 25%"></div>\n' +
    '                  <div class="pull-right" style="height: {{newvenueanalytics.scaleTextHeight}}px; width: 70%">\n' +
    '                    Max: {{newvenueanalytics.scaleMax}}\n' +
    '                  </div>\n' +
    '                  <div class="pull-right" style="width: 70%">Min: 0</div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="col-sm-10" ng-init="newvenueanalytics.initMap()">\n' +
    '                <br/>\n' +
    '                <div id="mapContainer" style="position: relative; height: {{ newvenueanalytics.mapHeight }}px;">\n' +
    '                  <canvas id="mapBackground" style="position: absolute; left: 0; top: 0; z-index: 0;"></canvas>\n' +
    '                  <canvas id="mapHeatMap" style="position: absolute; left: 0; top: 0; z-index: 1;" ng-hide="newvenueanalytics.mapType === \'Dwell\'"></canvas>\n' +
    '                  <canvas id="mapPointMap" style="position: absolute; left: 0; top: 0; z-index: 2;"></canvas>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="form-row margin_top dateFix">\n' +
    '          <div class="col-sm-8">\n' +
    '            <div class="form-group text-box long cell cell-pad">\n' +
    '              <label for="startdate" class="control-label pull-left">START DATE</label>\n' +
    '              <input ng-change="newvenueanalytics.refreshApActivity()" ng-click="newvenueanalytics.openApCal()" type="text" class="form-control" max-date="newvenueanalytics.yesterday" name="activityDate" datepicker-popup="{{newvenueanalytics.dateFormat}}" ng-model="newvenueanalytics.activityDate" is-open="newvenueanalytics.status.openedApCal" datepicker-options="newvenueanalytics.dateOptions" ng-required="true" close-text="Close" />\n' +
    '            </div>\n' +
    '            <div class="short cell">\n' +
    '              <button type="button" class="btn btn-date" ng-click="newvenueanalytics.openApCal()">\n' +
    '                <i class="glyphicon glyphicon-calendar"></i>\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="col-sm-12">\n' +
    '            <h3 class="margin-analytics-xs">Activity By AP</h3>\n' +
    '            <canvas id="line" class="chart chart-line" chart-data="newvenueanalytics.apActivity.data" chart-labels="newvenueanalytics.apActivity.labels" chart-series="newvenueanalytics.apActivity.series" chart-options="newvenueanalytics.apActivity.options" chart-colors="newvenueanalytics.chartColors">\n' +
    '            </canvas>\n' +
    '          </div>\n' +
    '        <!--  <div class="col-sm-4 analytics-block-sm">\n' +
    '            <div class="row margin-analytics-xs margin-analytics-top">\n' +
    '              <h3 class="margin-analytics-xs">Associations</h3>\n' +
    '              <ul class="list-unstyled busy-list">\n' +
    '                <li>Busy AP : {{newvenueanalytics.busyAp.mostBusy.ap}}</li>\n' +
    '                <li>associations : {{newvenueanalytics.busyAp.mostBusy.count}}</li>\n' +
    '                <li>\n' +
    '                  </br>\n' +
    '                </li>\n' +
    '                <li>Least Busy AP : {{newvenueanalytics.busyAp.leastBusy.ap}}</li>\n' +
    '                <li>associations : {{newvenueanalytics.busyAp.leastBusy.count}}</li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          -->\n' +
    '        </div>\n' +
    '        <!-- <div class="row">\n' +
    '          <div class="col-sm-10">\n' +
    '            <h3 class="margin-analytics-xs">User Journey (100 user sample)</h3>\n' +
    '            <canvas id="line" class="chart chart-line" chart-data="newvenueanalytics.usrJourney.data" chart-labels="newvenueanalytics.usrJourney.labels" chart-options="newvenueanalytics.usrJourney.options" chart-series="newvenueanalytics.usrJourney.series" chart-colors="newvenueanalytics.chartColors">\n' +
    '            </canvas>\n' +
    '          </div>\n' +
    '        </div> -->\n' +
    '      </div>\n' +
    '      <div ng-show="newvenueanalytics.tab === \'users\'">\n' +
    '        <div class="form-row margin_top dateFix">\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="startdate" class="control-label pull-left">START DATE</label>\n' +
    '            <input ng-change="newvenueanalytics.refresh()" ng-click="newvenueanalytics.openUserCalSt()" type="text" class="form-control" min-date="newvenueanalytics.minDate" max-date="newvenueanalytics.endtime" name="startDate" datepicker-popup="{{newvenueanalytics.dateFormat}}" ng-model="newvenueanalytics.starttime" is-open="newvenueanalytics.status.openedUsrSt" datepicker-options="newvenueanalytics.dateOptions" ng-required="true" close-text="Close" />\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <button type="button" class="btn btn-date" ng-click="newvenueanalytics.openUserCalSt()">\n' +
    '              <i class="glyphicon glyphicon-calendar"></i>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="endtime" class="control-label pull-left">END DATE</label>\n' +
    '            <input ng-change="newvenueanalytics.refresh()" ng-click="newvenueanalytics.openUserCalEnd()" type="text" class="form-control" min-date="newvenueanalytics.starttime" max-date="newvenueanalytics.today" datepicker-popup="{{newvenueanalytics.dateFormat}}" ng-model="newvenueanalytics.endtime" is-open="newvenueanalytics.status.openedUsrEnd" datepicker-options="newvenueanalytics.dateOptions" ng-required="true" close-text="Close" />\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <button type="button" class="btn btn-date" ng-click="newvenueanalytics.openUserCalEnd()">\n' +
    '              <i class="glyphicon glyphicon-calendar"></i>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="margin_top col-sm-12">\n' +
    '            <div class="row tabrow">\n' +
    '              <div class="col-sm-2">\n' +
    '                <button ng-click="newvenueanalytics.showProfileTable=!newvenueanalytics.showProfileTable" class="btn btn-danger">\n' +
    '                  <i class="fa fa-list" aria-hidden="true" ng-show="!newvenueanalytics.showProfileTable"></i>\n' +
    '                  <i class="fa fa-th-large" aria-hidden="true" ng-show="newvenueanalytics.showProfileTable"></i>\n' +
    '                </button>\n' +
    '                  <button type="button" ng-click="newvenueanalytics.downloadCSV()" class="btn btn-danger">Export</button>\n' +
    '              </div>\n' +
    '              <div class="col-sm-10">\n' +
    '                <div class="col-sm-4">\n' +
    '                  <div class="input-group">\n' +
    '                    <span class="input-group-addon">\n' +
    '                      <i class="fa fa-search"></i>\n' +
    '                    </span>\n' +
    '                    <input type="text" class="form-control" placeholder="Search Users" ng-model="userSearch">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <div class="col-sm-4">\n' +
    '                  <select class="form-control" ng-model="newvenueanalytics.limit" ng-options="limit for limit in newvenueanalytics.limits">\n' +
    '                    <option value="">Limit Results</option>\n' +
    '                  </select>\n' +
    '                </div>\n' +
    '                <div class="col-sm-4">\n' +
    '                  <select class="form-control" ng-model="activity" ng-options="filter.value as filter.label for filter in newvenueanalytics.activityFilter" ng-change="newvenueanalytics.setLimit(activity)">\n' +
    '                    <option value="">Limit by Activity</option>\n' +
    '                  </select>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-if="!newvenueanalytics.showProfileTable" class="animated-class fix">\n' +
    '              <div class="col-md-2 col-sm-3 cardFrame" ng-repeat="user in newvenueanalytics.userProfiles | orderBy:\'-logins\' | filter:userSearch | limitTo:newvenueanalytics.limit | filter: {isLive :newvenueanalytics.activity || undefined}">\n' +
    '\n' +
    '                <div class="col-xs-12 border card">\n' +
    '                  <div class="row">\n' +
    '                    <div ng-show="user.socialdata.provider == \'google\'">\n' +
    '                      <div class="col-xs-8 col-xs-offset-2" style="background-image: url(user.socialdata.photos[0].value); background-size : cover;">\n' +
    '                        <div class="liveAlert">\n' +
    '                          <div class="alert alert-success" role="alert" ng-if="user.isLive === \'true\'">Online</div>\n' +
    '                          <div class="alert alert-danger" role="alert" ng-if="user.isLive === \'false\'">Offline</div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                    <div ng-show="user.socialdata.provider == \'facebook\'">\n' +
    '                      <div class="col-xs-12 bckIMG" style="background-image: url(https://graph.facebook.com/{{user.socialdata.id}}/picture?type=large); background-size : cover;">\n' +
    '                        <div class="liveAlert">\n' +
    '                          <div class="alert alert-success" role="alert" ng-if="user.isLive === \'true\'">Online</div>\n' +
    '                          <div class="alert alert-danger" role="alert" ng-if="user.isLive === \'false\'">Offline</div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                    <div ng-show="user.socialdata.provider == \'twitter\'">\n' +
    '                      <div class="col-xs-12 bckIMG" style="background-image: url(https://twitter.com/{{user.socialdata.username}}/profile_image?size=original); background-size : cover;">\n' +
    '                        <div class="liveAlert">\n' +
    '                          <div class="alert alert-success" role="alert" ng-if="user.isLive === \'true\'">Online</div>\n' +
    '                          <div class="alert alert-danger" role="alert" ng-if="user.isLive === \'false\'">Offline</div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                    <div ng-show="!user.socialdata.provider">\n' +
    '                      <div class="col-xs-12 bckIMG" style="background-image: url(images/usericon.jpg); background-size : cover;">\n' +
    '                        <div class="liveAlert">\n' +
    '                          <div class="alert alert-success" role="alert" ng-if="user.isLive === \'true\'">Online</div>\n' +
    '                          <div class="alert alert-danger" role="alert" ng-if="user.isLive === \'false\'">Offline</div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                  <div class="row rowbtm">\n' +
    '                    <div class="col-xs-12">\n' +
    '                      <div class="row">\n' +
    '                        <a href="#/admin/userprofile/{{newvenueanalytics.venueid}}/{{user.id}}/{{newvenueanalytics.venue.infrastructure.provider}}">Name: {{user.name}}</a>\n' +
    '                      </div>\n' +
    '                      <div class="row">\n' +
    '                        <div class="col-xs-8">\n' +
    '                          <p>Visits: {{user.logins}}</p>\n' +
    '                        </div>\n' +
    '                        <div class="col-xs-4">\n' +
    '                          <a ng-show="user.socialdata.provider == \'facebook\'" class="btn btn-primary btn-social-link pull-right" ng-href="{{user.socialdata.profileUrl}}" target="-_blank"><i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i></a>\n' +
    '                          <a ng-show="user.socialdata.provider == \'google\'" class="btn btn-danger btn-social-link pull-right" ng-href="https://plus.google.com/u/0/{{user.socialdata.id}}" target="-_blank">\n' +
    '                            <i class="fa fa-google-plus-square fa-2x" aria-hidden="true"></i>\n' +
    '                            </a>\n' +
    '                          <a ng-show="user.socialdata.provider == \'linkedin\'" class="btn btn-primary btn-social-link pull-right" ng-href="https://www.linkedin.com/in/{{user.socialdata.id}}" target="-_blank">\n' +
    '                            <i class="fa fa-linkedin-square fa-2x" aria-hidden="true"></i>\n' +
    '                            </a>\n' +
    '                          <a ng-show="user.socialdata.provider == \'twitter\'" class="btn btn-primary btn-social-link pull-right" ng-href="https://twitter.com/{{user.socialdata.username}}" target="-_blank">\n' +
    '                            <i class="fa fa-twitter-square fa-2x" aria-hidden="true"></i>\n' +
    '                            </a>\n' +
    '                          <a ng-show="!user.socialdata.provider" class="btn btn-warning btn-social-link pull-right" ng-href="https://twitter.com/{{user.socialdata.username}}" target="-_blank">\n' +
    '                              <i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i>\n' +
    '                              </a>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                      <div class="row">\n' +
    '                        <ul class="list-group tagList">\n' +
    '                          <li ng-repeat="tag in user.tag" class="list-group-item list-group-item-success">{{tag}}</li>\n' +
    '                        </ul>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-if="newvenueanalytics.showProfileTable" class="animated-class">\n' +
    '              <table class="table table-responsive eventsTable">\n' +
    '                <thead>\n' +
    '                  <tr>\n' +
    '                    <th>Photo</th>\n' +
    '                    <th>Name</th>\n' +
    '                    <th>Visits</th>\n' +
    '                    <th>Social Media Profile</th>\n' +
    '                    <th>Tags</th>\n' +
    '                  </tr>\n' +
    '                </thead>\n' +
    '                <tbody ng-repeat="user in newvenueanalytics.userProfiles | orderBy:\'-logins\' | filter:userSearch | limitTo:newvenueanalytics.limit | filter: {isLive :activity || undefined}">\n' +
    '                  <tr>\n' +
    '                    <td class="profileList">\n' +
    '                      <div ng-show="user.socialdata.provider == \'google\'">\n' +
    '                        <a href="#/admin/userprofile/{{newvenueanalytics.venueid}}/{{user.id}}/{{newvenueanalytics.venue.infrastructure.provider}}">\n' +
    '                          <img ng-src="{{user.socialdata.photos[0].value}}" class="img-responsive" />\n' +
    '                        </a>\n' +
    '                      </div>\n' +
    '                      <div ng-show="user.socialdata.provider == \'facebook\'">\n' +
    '                        <a href="#/admin/userprofile/{{newvenueanalytics.venueid}}/{{user.id}}/{{newvenueanalytics.venue.infrastructure.provider}}">\n' +
    '                          <img ng-src="https://graph.facebook.com/{{user.socialdata.id}}/picture?type=large" class="scale-down" />\n' +
    '                        </a>\n' +
    '                      </div>\n' +
    '                      <div ng-show="user.socialdata.provider == \'twitter\'">\n' +
    '                        <a href="#/admin/userprofile/{{newvenueanalytics.venueid}}/{{user.id}}/{{newvenueanalytics.venue.infrastructure.provider}}">\n' +
    '                          <img ng-src="https://twitter.com/{{user.socialdata.username}}/profile_image?size=original" class="img-responsive" />\n' +
    '                        </a>\n' +
    '                      </div>\n' +
    '                      <div ng-show="!user.socialdata.provider">\n' +
    '                        <img ng-src="images/usericon.jpg" class="img-responsive" />\n' +
    '                      </div>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <a href="#/admin/userprofile/{{newvenueanalytics.venueid}}/{{user.id}}/{{newvenueanalytics.venue.infrastructure.provider}}">\n' +
    '                        {{user.name}}\n' +
    '                      </a>\n' +
    '                      <ul class="list-group tagList">\n' +
    '                        <li class="list-group-item list-group-item-success" ng-if="user.isLive === \'true\'">Online</li>\n' +
    '                        <li class="list-group-item list-group-item-danger" ng-if="user.isLive === \'false\'">Offline</li>\n' +
    '                      </ul>\n' +
    '                    </td>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <p>Visits: {{user.logins}}</p>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <div ng-show="user.socialdata.provider == \'facebook\'">\n' +
    '                        <a class="btn btn-primary btn-social-link" ng-href="{{user.socialdata.profileUrl}}" target="-_blank">\n' +
    '                          <i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i>\n' +
    '                        </a>\n' +
    '                      </div>\n' +
    '                      <div ng-show="user.socialdata.provider == \'google\'">\n' +
    '                        <a class="btn btn-danger btn-social-link" ng-href="https://plus.google.com/u/0/{{user.socialdata.id}}" target="-_blank">\n' +
    '                          <i class="fa fa-google-plus-square fa-2x" aria-hidden="true"></i>\n' +
    '                        </a>\n' +
    '                      </div>\n' +
    '                      <div ng-show="user.socialdata.provider == \'linkedin\'">\n' +
    '                        <a class="btn btn-primary btn-social-link" ng-href="https://www.linkedin.com/in/{{user.socialdata.id}}" target="-_blank">\n' +
    '                          <i class="fa fa-linkedin-square fa-2x" aria-hidden="true"></i>\n' +
    '                        </a>\n' +
    '                      </div>\n' +
    '                      <div ng-show="user.socialdata.provider == \'twitter\'">\n' +
    '                        <a class="btn btn-primary btn-social-link" ng-href="https://twitter.com/{{user.socialdata.username}}" target="-_blank">\n' +
    '                          <i class="fa fa-twitter-square fa-2x" aria-hidden="true"></i>\n' +
    '                        </a>\n' +
    '                      </div>\n' +
    '                      <div ng-show="!user.socialdata.provider">\n' +
    '                        <a class="btn btn-warning btn-social-link" ng-href="" target="-_blank">\n' +
    '                        <i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i>\n' +
    '                        </a>\n' +
    '                      </div>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <ul class="list-group tagList">\n' +
    '                        <li ng-repeat="tag in user.tag" class="list-group-item list-group-item-success">{{tag}}</li>\n' +
    '                      </ul>\n' +
    '                    </td>\n' +
    '                  </tr>\n' +
    '                </tbody>\n' +
    '              </table>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('newvenue/views/newvenue.tpl.html',
    '<div id="newVenue">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Create Venue</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createVenue" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>VENUE NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.venuename.$invalid && newvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="venuename" name="venuename" ng-model="newvenue.venue.venueTitle" placeholder="eg. Some Venue" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.venuename.$error" ng-if="newvenue.submitted">\n' +
    '              <p ng-message="required">venue name is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>VENUE IMAGE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-4 col-md-offset-4">\n' +
    '                  <img ng-src="{{staticUrl}}{{newvenue.venue.imageUrl}}" class="img-responsive"/>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  <!-- <button class="btn btn-danger" ng-click="newvenue.selectImage()" tooltip-placement="left" tooltip="Select Image">\n' +
    '                    <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '                  </button> -->\n' +
    '                  <button class="btn btn-danger" ng-click="newvenue.uploadImage()" tooltip-placement="left" tooltip="Upload Image">\n' +
    '                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-show="!newvenue.venue.imageUrl && newvenue.submitted">\n' +
    '              <p>Image is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>EMAIL</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.contactEmail.$invalid && newvenue.submitted }">\n' +
    '              <input type="email" class="form-control" id="contactEmail" name="contactEmail" ng-model="newvenue.venue.contactEmail" placeholder="eg. name@email.com" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.contactEmail.$error" ng-if="newvenue.submitted">\n' +
    '              <p ng-message="required">email is required.</p>\n' +
    '              <p ng-message="email">This needs to be a valid email</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>PHONE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.contactPhone.$invalid && newvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="contactPhone" name="contactPhone" ng-model="newvenue.venue.contactPhone" placeholder="eg. 081 555 556" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.contactPhone.$error" ng-if="newvenue.submitted">\n' +
    '              <p ng-message="required">phone is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>DESCRIPTION</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.description.$invalid && newvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="description" name="description" ng-model="newvenue.venue.description" placeholder="eg. description ..." required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.description.$error" ng-if="newvenue.submitted">\n' +
    '              <p ng-message="required">description is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>LOCATION</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.location.$invalid && newvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="location" name="location" ng-model="newvenue.venue.location" placeholder="eg. Address" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.location.$error" ng-if="newvenue.submitted">\n' +
    '              <p ng-message="required">location is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>WEBSITE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.website.$invalid && newvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="website" name="website" ng-model="newvenue.venue.website" placeholder="eg. someurl.com" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.website.$error" ng-if="newvenue.submitted">\n' +
    '              <p ng-message="required">website is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Social Media Accounts</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="row form-group" ng-repeat="socialmedia in newvenue.socialMediaAccounts" style="border-bottom: none; padding: 5px">\n' +
    '              <div class="col-xs-4 col-sm-4 col-md-3">\n' +
    '                <div style="margin-top: 10px;">{{socialmedia.title}}</div>\n' +
    '              </div>\n' +
    '              <div class="col-xs-8 col-sm-8 col-md-9">\n' +
    '                <input type="url" name="{{socialmedia.title}}" class="form-control pull-right" ng-model="newvenue.socialMediaAccounts[$index].value" placeholder="{{socialmedia.example}}">\n' +
    '              </div>\n' +
    '              <div class="ccol-xs-12">\n' +
    '                <div ng-show="!createVenue[socialmedia.title].$valid" class="pull-right">\n' +
    '                  <p class="text-danger">\n' +
    '                    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                    <strong> Custom Url must be a valid url. Example https://www.facebook.com/Liquidedge/</strong>\n' +
    '                  </p>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="newvenue.createVenue(!createVenue.$invalid)" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('newoperator/views/newoperator.tpl.html',
    '<div id="newoperator">\n' +
    '  <h2 class="text-center">Create Operator</h2>\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createOperator" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>OPERATOR NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createOperator.operatorname.$invalid && newoperator.submitted }">\n' +
    '              <input type="text" class="form-control" id="operatorname" name="operatorname" ng-model="newoperator.operator.name" placeholder="eg. First Last" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createOperator.operatorname.$error" ng-if="newoperator.submitted">\n' +
    '              <p ng-message="required">operator name is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>USER NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createOperator.login.$invalid && newoperator.submitted }">\n' +
    '              <input type="email" class="form-control" id="login" name="login" ng-model="newoperator.operator.login" placeholder="eg. somename@someemail.com" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createOperator.login.$error" ng-if="newoperator.submitted">\n' +
    '              <p ng-message="required">login is required.</p>\n' +
    '              <p ng-message="email">This needs to be a valid email</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <!-- <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>PASSWORD</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createOperator.password.$invalid && newoperator.submitted }">\n' +
    '              <input type="password" class="form-control" id="password" name="password" ng-model="newoperator.operator.password" placeholder="******" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createOperator.password.$error" ng-if="newoperator.submitted">\n' +
    '              <p ng-message="required">password is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div> -->\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>ROLE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createOperator.role.$invalid && newoperator.submitted }">\n' +
    '              <select id="role" name="role" class="form-control" ng-model="newoperator.operator.roleId" ng-options="role.id as role.name for role in newoperator.roles" required>\n' +
    '                <option value="">Select A Role</option>\n' +
    '              </select>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="help-block" ng-messages="createOperator.role.$error" ng-if="newoperator.submitted">\n' +
    '              <p ng-message="required">role is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="newoperator.operator.roleId === 2">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Venue</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createOperator.role.$invalid && newoperator.submitted }">\n' +
    '              <select id="venue" name="venue" class="form-control" ng-model="newoperator.operator.details.venue" ng-options="venue.id as venue.venuetitle for venue in newoperator.venues" ng-change="newoperator.setVenue(newoperator.operator.details.venue)">\n' +
    '                <option value="">Select A Venue</option>\n' +
    '              </select>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="help-block" ng-messages="createOperator.role.$error" ng-if="newoperator.submitted">\n' +
    '              <p ng-message="required">role is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="newoperator.createOperator(!createOperator.$invalid)" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('newlist/views/newlist.tpl.html',
    '<div id="mailchimp">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">New Mailchimp List</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createNewList" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>List Details</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.name.$invalid && newlist.submitted }">\n' +
    '              <label for="name">Name</label>\n' +
    '              <input type="text" class="form-control" id="name" name="name" ng-model="newlist.list.name" placeholder="name" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.name.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Name is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.company.$invalid && newlist.submitted }">\n' +
    '              <label for="company">Company</label>\n' +
    '              <input type="text" class="form-control" id="company" name="company" ng-model="newlist.list.company" placeholder="company" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.company.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Company is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.address1.$invalid && newlist.submitted }">\n' +
    '              <label for="address1">Address Line 1</label>\n' +
    '              <input type="text" class="form-control" id="address1" name="address1" ng-model="newlist.list.address1" placeholder="address1" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.address1.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Address Line 1 is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '              <label for="address2">Address Line 2</label>\n' +
    '              <input type="text" class="form-control" id="address2" name="address2" ng-model="newlist.list.address2" placeholder="address2">\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.city.$invalid && newlist.submitted }">\n' +
    '              <label for="city">City</label>\n' +
    '              <input type="text" class="form-control" id="city" name="city" ng-model="newlist.list.city" placeholder="city" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.city.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">City is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.state.$invalid && newlist.submitted }">\n' +
    '              <label for="state">State</label>\n' +
    '              <input type="text" class="form-control" id="state" name="state" ng-model="newlist.list.state" placeholder="state" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.state.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">State is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.zip.$invalid && newlist.submitted }">\n' +
    '              <label for="state">Zip</label>\n' +
    '              <input type="text" class="form-control" id="zip" name="zip" ng-model="newlist.list.zip" placeholder="zip" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.zip.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Zip is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.country.$invalid && newlist.submitted }">\n' +
    '              <label for="state">Country</label>\n' +
    '              <input type="text" class="form-control" id="country" name="country" ng-model="newlist.list.country" placeholder="country" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.country.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Country is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.permissionReminder.$invalid && newlist.submitted }">\n' +
    '              <label for="permissionReminder">Permission Reminder</label>\n' +
    '              <input type="text" class="form-control" id="permissionReminder" name="permissionReminder" ng-model="newlist.list.permissionReminder" placeholder="e.g. You are receiving this email because you signed up to xyz" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.permissionReminder.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Permission Reminder is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.campaignFromName.$invalid && newlist.submitted }">\n' +
    '              <label for="campaignFromName">Campaign From Name</label>\n' +
    '              <input type="text" class="form-control" id="campaignFromName" name="campaignFromName" ng-model="newlist.list.campaignFromName" placeholder="Campaign From Name" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.campaignFromName.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Campaign From Name is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.campaignFromEmail.$invalid && newlist.submitted }">\n' +
    '              <label for="campaignFromEmail">Campaign From Email</label>\n' +
    '              <input type="text" class="form-control" id="campaignFromEmail" name="campaignFromEmail" ng-model="newlist.list.campaignFromEmail" placeholder="Campaign From Email" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.campaignFromEmail.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Campaign From Email is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.campaignSubject.$invalid && newlist.submitted }">\n' +
    '              <label for="campaignSubject">Campaign Subject</label>\n' +
    '              <input type="text" class="form-control" id="campaignSubject" name="campaignSubject" ng-model="newlist.list.campaignSubject" placeholder="Campaign Subject" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.campaignSubject.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Campaign Subject is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.campaignLanguage.$invalid && newlist.submitted }">\n' +
    '              <label for="campaignLanguage">Campaign Language</label>\n' +
    '              <input type="text" class="form-control" id="campaignLanguage" name="campaignLanguage" ng-model="newlist.list.campaignLanguage" placeholder="Campaign Language" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.campaignLanguage.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Campaign Language is required.</p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createNewList.campaignLanguage.$invalid && newlist.submitted }">\n' +
    '              <label for="emailTypeOption">Email Type Option</label>\n' +
    '              <select name="emailTypeOption" class="form-control" ng-model="newlist.list.emailTypeOption" required>\n' +
    '                <option value="true">True</option>\n' +
    '                <option value="false">False</option>\n' +
    '              </select>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createNewList.emailTypeOption.$error" ng-if="newlist.submitted">\n' +
    '              <p ng-message="required">Email Type Option is required.</p>\n' +
    '            </div>\n' +
    '            <button ng-click="newlist.createList(!newList.createNewList.$invalid)" class="btn btn-danger right">Create List</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('operators/views/operators.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>Operators\n' +
    '        <button class="btn btn-md btn-danger pull-right" data-ui-sref="admin.newoperator"><i class="fa fa-plus" aria-hidden="true" tooltip-placement="left" tooltip="Add Operator"></i></button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-9 col-md-offset-3">\n' +
    '        <div class="col-md-6">\n' +
    '          <div class="input-group">\n' +
    '            <span class="input-group-addon">\n' +
    '              <i class="fa fa-search"></i>\n' +
    '            </span>\n' +
    '            <input type="text" class="form-control" placeholder="Search Operators" ng-model="operatorSearch">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-6">\n' +
    '          <select class="form-control" ng-model="operators.limit" ng-options="limit for limit in operators.limits" ng-change="operators.setLimit(operators.limit)">\n' +
    '            <option value="">Limit Results</option>\n' +
    '          </select>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-striped">\n' +
    '      <thead>\n' +
    '        <tr>\n' +
    '          <th>Name</th>\n' +
    '          <th>Username</th>\n' +
    '          <th>Venue</th>\n' +
    '          <th></th>\n' +
    '        </tr>\n' +
    '      </thead>\n' +
    '      <tbody>\n' +
    '        <tr ng-repeat="operator in operators.operators | filter:operatorSearch | limitTo:operators.limit">\n' +
    '          <td>{{operator.name}}</td>\n' +
    '          <td>{{operator.login}}</td>\n' +
    '          <td><span ng-show="operator.role === 2">{{operator.details.venueName}}</span><span ng-show="operator.role === 1">Admin</span></td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-danger btn-md" ng-click="operators.deleteOperator(operator)" tooltip-placement="left" tooltip="Delete Operator">\n' +
    '                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <a class="btn btn-danger btn-md" ui-sref="admin.editoperator({operatorid: operator.id})" tooltip-placement="left" tooltip="Edit Operator">\n' +
    '                <i class="fa fa-pencil-square-o">\n' +
    '                </i>\n' +
    '              </a>\n' +
    '              <button class="btn btn-danger btn-md" ng-click="operators.resendEmail(operator)" tooltip-placement="left" tooltip="Resend Email">\n' +
    '                <i class="fa fa-repeat">\n' +
    '                </i>\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('operators/views/modal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>{{modal.message}}</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="modal.cancel()">Cancel</button>\n' +
    '  <button class="btn btn-primary" type="button" ng-click="modal.ok()">OK</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('operators/views/emailRetryModal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div style="text-align: center;">\n' +
    '    <p>Warning this will reset the password of the operator and re-issue login credentials to <strong>{{emailModal.user.login}}<strong></p>\n' +
    '    <p>Please type the operators email to confirm: </p>\n' +
    '    <input class="form-control" type="text" name="email" value="" ng-model="emailModal.user.compare">\n' +
    '\n' +
    '    <strong style="color: red;">{{emailModal.error}}</strong>\n' +
    '  </div>\n' +
    '\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="emailModal.cancel()">Cancel</button>\n' +
    '  <button class="btn btn-primary" type="button" ng-click="emailModal.ok()">OK</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('modalservice/views/networktypemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/magnetnetworks/magnet_logo_small.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div ng-show="networkTypeModal.error" class="center-error">\n' +
    '    <h5>{{bulkVenueCreateModal.error}}</h5>\n' +
    '  </div>\n' +
    '  <div ng-hide="createFromController.error.length > 1">\n' +
    '    <h4>Would you like to create from controller?</h4>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="networkTypeModal.ok()">Yes</button>\n' +
    '  <button class="btn btn-info" type="button" ng-click="networkTypeModal.no()" ng-hide="networkTypeModal.error.length > 1">No</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="networkTypeModal.cancel()">Cancel</button>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('modalservice/views/modalservice.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/magnetnetworks/magnet_logo_small.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>{{modalService.message.messageHeader}}</h4>\n' +
    '  <p>{{modalService.message.messageBody}}</p>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <div ng-show="modalService.message.cancel.length > 0">\n' +
    '    <button class="btn btn-danger" type="button" ng-click="modalService.ok()">{{modalService.message.ok}}</button>\n' +
    '    <button class="btn btn-warning" type="button" ng-click="modalService.cancel()">{{modalService.message.cancel}}</button>\n' +
    '  </div>\n' +
    '  <div ng-hide="modalService.message.cancel.length > 0">\n' +
    '    <button class="btn btn-primary btn-block" type="button" ng-click="modalService.ok()">{{modalService.message.ok}}</button>\n' +
    '  </div>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('modalservice/views/createfromcontroller.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/magnetnetworks/magnet_logo_small.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div ng-show="createFromController.error" class="center-error">\n' +
    '    <h5>{{bulkVenueCreateModal.error}}</h5>\n' +
    '  </div>\n' +
    '  <div ng-hide="createFromController.error.length > 1">\n' +
    '    <h4 ng-hide="createFromController.infrastructure.provider === 1">Would you like to create from controller?</h4>\n' +
    '    <h4 ng-show="createFromController.infrastructure.provider === 1">Would you like to create from CSV File?</h4>\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="createFromController.infrastructure.provider === 1">\n' +
    '    <input type="file" fileread="createFromController.csvfile" required>\n' +
    '  </div>\n' +
    '\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="createFromController.ok()">Yes</button>\n' +
    '  <button class="btn btn-info" type="button" ng-click="createFromController.no()" ng-hide="createFromController.error.length > 1">No</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="createFromController.cancel()">Cancel</button>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('modalservice/views/bulkzonecreatemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <h4>{{bulkZoneCreateModal.controller.controlleraddress}}</h4>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div ng-show="bulkZoneCreateModal.error" class="center-error">\n' +
    '    <h5>{{bulkVenueCreateModal.error}}</h5>\n' +
    '  </div>\n' +
    '  <div ng-if="bulkZoneCreateModal.wlans.length < 1 && !bulkZoneCreateModal.error" class="centered">\n' +
    '    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>\n' +
    '    <span class="sr-only">Loading...</span>\n' +
    '  </div>\n' +
    '  <div ng-show="bulkZoneCreateModal.wlans.length > 0">\n' +
    '    <div class="scrollbox">\n' +
    '      <table class="table table-responsive eventsTable">\n' +
    '        <thead>\n' +
    '          <tr>\n' +
    '            <th>ID</th>\n' +
    '            <th>WLAN</th>\n' +
    '            <th>Status</th>\n' +
    '          </tr>\n' +
    '        </thead>\n' +
    '        <tbody ng-repeat="wlan in bulkZoneCreateModal.wlans">\n' +
    '          <tr>\n' +
    '            <td>\n' +
    '              {{wlan._id}}\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              {{wlan.name}}\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <button class="btn btn-danger" ng-show="wlan.status === \'Select\'" ng-click="bulkZoneCreateModal.selectedSite(wlan)">{{wlan.status}}</button>\n' +
    '              <button class="btn btn-success" ng-show="wlan.status === \'Selected\'" ng-click="bulkZoneCreateModal.deselectSite(wlan)">Select <i class="fa fa-check" aria-hidden="true"></i></button>\n' +
    '              <button class="btn btn-success" ng-show="wlan.status === \'Created\'" disabled>{{wlan.status}}</button>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </div>\n' +
    '    <hr width="100%">\n' +
    '    <button class="btn btn-danger pull-right" ng-click="bulkZoneCreateModal.selectAll()">\n' +
    '      {{bulkZoneCreateModal.selectAllButton}}\n' +
    '    </button>\n' +
    '    </br>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="bulkZoneCreateModal.bulkCreate()" ng-disabled="bulkZoneCreateModal.selected.length < 1">Create Selected</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="bulkZoneCreateModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('modalservice/views/bulknetworktypecreatemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <h4>{{bulkNetworkTypeCreateModal.controller.controlleraddress}}</h4>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div ng-show="bulkNetworkTypeCreateModal.error" class="center-error">\n' +
    '    <h5>{{bulkVenueCreateModal.error}}</h5>\n' +
    '  </div>\n' +
    '  <div ng-if="bulkNetworkTypeCreateModal.userGroups.length < 1 && !bulkNetworkTypeCreateModal.error" class="centered">\n' +
    '    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>\n' +
    '    <span class="sr-only">Loading...</span>\n' +
    '  </div>\n' +
    '  <div ng-show="bulkNetworkTypeCreateModal.userGroups.length > 0">\n' +
    '    <div class="scrollbox">\n' +
    '      <table class="table table-responsive eventsTable">\n' +
    '        <thead>\n' +
    '          <tr>\n' +
    '            <th>ID</th>\n' +
    '            <th>User Group</th>\n' +
    '            <th>Status</th>\n' +
    '          </tr>\n' +
    '        </thead>\n' +
    '        <tbody ng-repeat="userGroup in bulkNetworkTypeCreateModal.userGroups">\n' +
    '          <tr>\n' +
    '            <td>\n' +
    '              {{userGroup._id}}\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              {{userGroup.name}}\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <button class="btn btn-danger" ng-show="userGroup.status === \'Select\'" ng-click="bulkNetworkTypeCreateModal.selectedSite(userGroup)">{{userGroup.status}}</button>\n' +
    '              <button class="btn btn-success" ng-show="userGroup.status === \'Selected\'" ng-click="bulkNetworkTypeCreateModal.deselectSite(userGroup)">Select <i class="fa fa-check" aria-hidden="true"></i></button>\n' +
    '              <button class="btn btn-success" ng-show="userGroup.status === \'Created\'" disabled>{{userGroup.status}}</button>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </div>\n' +
    '    <hr width="100%">\n' +
    '    <button class="btn btn-danger pull-right" ng-click="bulkNetworkTypeCreateModal.selectAll()">\n' +
    '      {{bulkNetworkTypeCreateModal.selectAllButton}}\n' +
    '    </button>\n' +
    '    </br>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="bulkNetworkTypeCreateModal.bulkCreate()" ng-disabled="bulkNetworkTypeCreateModal.selected.length < 1">Create Selected</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="bulkNetworkTypeCreateModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('modalservice/views/bulkapcreatemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <h4>{{bulkApCreateModal.controller.controlleraddress}}</h4>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div ng-show="bulkApCreateModal.error" class="center-error">\n' +
    '    <h5>{{bulkVenueCreateModal.error}}</h5>\n' +
    '  </div>\n' +
    '  <div ng-if="bulkApCreateModal.aps.length < 1 && !bulkApCreateModal.error" class="centered">\n' +
    '    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>\n' +
    '    <span class="sr-only">Loading...</span>\n' +
    '  </div>\n' +
    '  <div ng-show="bulkApCreateModal.aps.length > 0">\n' +
    '    <div class="scrollbox">\n' +
    '      <table class="table table-responsive eventsTable">\n' +
    '        <thead>\n' +
    '          <tr>\n' +
    '            <th>Name</th>\n' +
    '            <th>Mac</th>\n' +
    '            <th>Status</th>\n' +
    '          </tr>\n' +
    '        </thead>\n' +
    '        <tbody ng-repeat="ap in bulkApCreateModal.aps">\n' +
    '          <tr>\n' +
    '            <td>\n' +
    '              {{ap.name}}\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              {{ap.mac}}\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <button class="btn btn-danger" ng-show="ap.status === \'Select\'" ng-click="bulkApCreateModal.selectedSite(ap)">{{ap.status}}</button>\n' +
    '              <button class="btn btn-success" ng-show="ap.status === \'Selected\'" ng-click="bulkApCreateModal.deselectSite(ap)">Select <i class="fa fa-check" aria-hidden="true"></i></button>\n' +
    '              <button class="btn btn-success" ng-show="ap.status === \'Created\'" disabled>{{ap.status}}</button>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </div>\n' +
    '    <hr width="100%">\n' +
    '    <button class="btn btn-danger pull-right" ng-click="bulkApCreateModal.selectAll()">\n' +
    '      {{bulkApCreateModal.selectAllButton}}\n' +
    '    </button>\n' +
    '    </br>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="bulkApCreateModal.bulkCreate()" ng-disabled="bulkApCreateModal.selected.length < 1">Create Selected</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="bulkApCreateModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('modalservice/views/bulkVenueCreateModal.tpl.html',
    '<div class="modal-header">\n' +
    '  <h4>{{bulkVenueCreateModal.controller.controlleraddress}}</h4>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div ng-show="bulkVenueCreateModal.error" class="center-error">\n' +
    '    <h5>{{bulkVenueCreateModal.error}}</h5>\n' +
    '  </div>\n' +
    '  <div ng-if="bulkVenueCreateModal.sites.length < 1 && !bulkVenueCreateModal.error" class="centered">\n' +
    '    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>\n' +
    '    <span class="sr-only">Loading...</span>\n' +
    '  </div>\n' +
    '  <div ng-show="bulkVenueCreateModal.sites.length > 0">\n' +
    '    <div class="scrollbox">\n' +
    '      <table class="table table-responsive eventsTable">\n' +
    '        <thead>\n' +
    '          <tr>\n' +
    '            <th>ID</th>\n' +
    '            <th>Site</th>\n' +
    '            <th>Status</th>\n' +
    '          </tr>\n' +
    '        </thead>\n' +
    '        <tbody ng-repeat="site in bulkVenueCreateModal.sites">\n' +
    '          <tr>\n' +
    '            <td>\n' +
    '              {{site.id}}\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              {{site.site}}\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <button class="btn btn-danger" ng-show="site.status === \'Select\'" ng-click="bulkVenueCreateModal.selectedSite(site)">{{site.status}}</button>\n' +
    '              <button class="btn btn-success" ng-show="site.status === \'Selected\'" ng-click="bulkVenueCreateModal.deselectSite(site)">Select <i class="fa fa-check" aria-hidden="true"></i></button>\n' +
    '              <button class="btn btn-success" ng-show="site.status === \'Created\'" disabled>{{site.status}}</button>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </div>\n' +
    '    <hr width="100%">\n' +
    '    <button class="btn btn-danger pull-right" ng-click="bulkVenueCreateModal.selectAll()">\n' +
    '      {{bulkVenueCreateModal.selectAllButton}}\n' +
    '    </button>\n' +
    '    </br>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="bulkVenueCreateModal.bulkCreate()" ng-disabled="bulkVenueCreateModal.selected.length < 1">Create Selected</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="bulkVenueCreateModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('networktype/views/networktype.tpl.html',
    '<div id="networktype">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Create Network Type</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createnetworktype" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="zonename" name="zonename" ng-model="networktype.networkType.name" placeholder="eg. Guest" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createnetworktype.zonename.$error" ng-if="networktype.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Name is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="networktype.provider == 2">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>USER GROUP</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="zonename" name="zonename" ng-model="networktype.networkType.details.usergroup" placeholder="eg. Guest">\n' +
    '            </div>\n' +
    '            <div ng-messages="createnetworktype.zonename.$error" ng-if="networktype.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Name is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="networktype.provider == 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>VLAN</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="number" class="form-control" id="details" name="vlan" ng-model="networktype.networkType.vlanId" placeholder="eg. 700" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createnetworktype.vlan.$error" ng-if="networktype.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Vlan is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="networktype.provider == 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>NETWORK OPTIMISATION STRATEGIES</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-heading">\n' +
    '            <div class="row">\n' +
    '              <div class="col-md-8 col-md-offset-2">\n' +
    '                <div class="input-group">\n' +
    '                  <div class="input-group-addon">\n' +
    '                    <i class="fa fa-search"></i>\n' +
    '                  </div>\n' +
    '                  <input type="text" class="form-control" placeholder="Search Strategies" ng-model="stSearch">\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <ul class="">\n' +
    '                <li class="checkbox" ng-repeat="st in networktype.ntstrategies | filter:stSearch">\n' +
    '                  <label>\n' +
    '                    <input type="checkbox" data-checklist-model="networktype.selectedStrategies" checklist-value="st.id">\n' +
    '                    {{st.name}} - {{st.description}}\n' +
    '                  </label>\n' +
    '                </li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="networktype.createNetworkType(!createnetworktype.$invalid)" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('managenetwork/views/managenetwork.tpl.html',
    '<h2>Manage {{managenetwork.venue.venuetitle}} Network\n' +
    '</h2>\n' +
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3 ng-show="managenetwork.showZones">Zones\n' +
    '        <button class="btn btn-sm btn-danger pull-right" ng-click="managenetwork.createZone()" tooltip-placement="left" tooltip="New Zone">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '      <h3 ng-show="managenetwork.showAPoints">Access Points\n' +
    '        <button class="btn btn-sm btn-danger pull-right" ng-click="managenetwork.createAccesspoint()" tooltip-placement="left" tooltip="New Accesspoint">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '      <h3 ng-show="managenetwork.showTypes">Network Types\n' +
    '        <button class="btn btn-sm btn-danger pull-right" ng-click="managenetwork.createNetworkType()" tooltip-placement="left" tooltip="New Network Type">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '      <h3 ng-show="managenetwork.showInfra">Infrastructure\n' +
    '        <button class="btn btn-sm btn-danger pull-right" ng-click="managenetwork.createInfrastructure()" tooltip-placement="left" tooltip="New Infrastructure" ng-hide="managenetwork.infrastructures.length > 0">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '      <h3 ng-show="managenetwork.showVenueMap">Venue Maps\n' +
    '        <button class="btn btn-sm btn-danger pull-right" ng-click="managenetwork.createVenueMap()" tooltip-placement="left" tooltip="New Venue Map" >\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-9">\n' +
    '        <ul class="nav nav-tabs" role="tablist">\n' +
    '          <li role="presentation" ng-class="{\'active\': (managenetwork.showInfra)}">\n' +
    '            <a href="" ng-click="managenetwork.setNetworkView(\'infra\')" aria-controls="network types" role="tab" data-toggle="tab">Infrastructure</a>\n' +
    '          </li>\n' +
    '          <li role="presentation" ng-class="{\'active\': (managenetwork.showZones)}" ng-show="managenetwork.infrastructures.length > 0">\n' +
    '            <a href="" ng-click="managenetwork.setNetworkView(\'zone\')" aria-controls="zones" role="tab">Zones</a>\n' +
    '          </li>\n' +
    '          <li role="presentation" ng-class="{\'active\': (managenetwork.showAPoints)}" ng-show="managenetwork.infrastructures.length > 0">\n' +
    '            <a href="" ng-click="managenetwork.setNetworkView(\'apoints\')" aria-controls="accesspoints" role="tab" data-toggle="tab">Access Points</a>\n' +
    '          </li>\n' +
    '          <li role="presentation" ng-class="{\'active\': (managenetwork.showTypes)}" ng-show="managenetwork.infrastructures.length > 0">\n' +
    '            <a href="" ng-click="managenetwork.setNetworkView(\'types\')" aria-controls="network types" role="tab" data-toggle="tab">Network Types</a>\n' +
    '          </li>\n' +
    '          <li role="presentation" ng-class="{\'active\': (managenetwork.showVenueMap)}" ng-show="managenetwork.infrastructures.length > 0">\n' +
    '            <a href="" ng-click="managenetwork.setNetworkView(\'venuemap\')" aria-controls="network types" role="tab" data-toggle="tab">Venue Map</a>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '      <div class="col-md-3">\n' +
    '        <div class="input-group">\n' +
    '          <div class="input-group-addon">\n' +
    '            <i class="fa fa-search"></i>\n' +
    '          </div>\n' +
    '          <input type="text" class="form-control" placeholder="Search Zones" ng-model="zoneSearch" ng-show="managenetwork.showZones">\n' +
    '          <input type="text" class="form-control" placeholder="Search Accesspoints" ng-model="apSearch" ng-show="managenetwork.showAPoints">\n' +
    '          <input type="text" class="form-control" placeholder="Search Network Types" ng-model="ntSearch" ng-show="managenetwork.showTypes">\n' +
    '          <input type="text" class="form-control" placeholder="Search Infrastructure" ng-model="inSearch" ng-show="managenetwork.showInfra">\n' +
    '          <input type="text" class="form-control" placeholder="Search Venue Maps" ng-model="inSearch" ng-show="managenetwork.showVenueMap">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div ng-show="managenetwork.showZones">\n' +
    '      <table class="table table-responsive">\n' +
    '        <tbody>\n' +
    '          <tr dir-paginate="zone in managenetwork.zones | orderBy:\'id\' | filter:zoneSearch | itemsPerPage: 10" pagination-id="zones">\n' +
    '            <td>\n' +
    '              <h4>{{zone.name}}</h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <h4>{{zone.details.description}}</h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <div class="pull-right">\n' +
    '                <button class="btn btn-danger btn-sm" ng-click="managenetwork.deleteZone(zone.id)" tooltip-placement="left" tooltip="Delete Zone">\n' +
    '                  <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '                </button>\n' +
    '                <button class="btn btn-danger btn-sm" ng-click="managenetwork.editZone(zone.id)" tooltip-placement="left" tooltip="Edit zone">\n' +
    '                  <i class="fa fa-pencil-square-o"></i>\n' +
    '                </button>\n' +
    '              </div>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '      <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true" pagination-id="zones"></dir-pagination-controls>\n' +
    '    </div>\n' +
    '    <div ng-show="managenetwork.showAPoints">\n' +
    '      <table class="table table-responsive">\n' +
    '        <tbody>\n' +
    '          <tr dir-paginate="accesspoint in managenetwork.accesspoints | orderBy:\'ipAddress\' | filter:apSearch | itemsPerPage: 10" pagination-id="aps">\n' +
    '            <td>\n' +
    '              <h4>Name : {{accesspoint.name}}</h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <h4>Radio MAC: {{accesspoint.hwaddr}}</h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <div class="pull-right">\n' +
    '                <button class="btn btn-danger btn-sm" ng-click="managenetwork.deleteAccesspoint(accesspoint.id)" tooltip-placement="left" tooltip="Delete Accesspoint">\n' +
    '                  <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '                </button>\n' +
    '                <button class="btn btn-danger btn-sm" ng-click="managenetwork.editAccesspoint(accesspoint.id)" tooltip-placement="left" tooltip="Edit Accesspoint">\n' +
    '                  <i class="fa fa-pencil-square-o"></i>\n' +
    '                </button>\n' +
    '              </div>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '      <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true" pagination-id="aps"></dir-pagination-controls>\n' +
    '    </div>\n' +
    '    <div ng-show="managenetwork.showTypes">\n' +
    '      <table class="table table-responsive">\n' +
    '        <tbody>\n' +
    '          <tr ng-repeat="type in managenetwork.networkTypes | filter:ntSearch">\n' +
    '            <td>\n' +
    '              <h4>Name: {{type.name}}\n' +
    '              </h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <div class="pull-right">\n' +
    '                <button class="btn btn-danger btn-sm" ng-click="managenetwork.deleteNetworkType(type.id)" tooltip-placement="left" tooltip="Remove Network Type">\n' +
    '                  <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '                </button>\n' +
    '                <button class="btn btn-danger btn-sm" ng-click="managenetwork.editNetworkType(type.id)" tooltip-placement="left" tooltip="Edit Network Type">\n' +
    '                  <i class="fa fa-pencil-square-o"></i>\n' +
    '                </button>\n' +
    '              </div>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </div>\n' +
    '    <div ng-show="managenetwork.showVenueMap">\n' +
    '      <table class="table table-responsive">\n' +
    '        <tbody>\n' +
    '          <tr dir-paginate="map in managenetwork.maps | orderBy:\'id\' | filter:mapSearch | itemsPerPage: 10" pagination-id="maps">\n' +
    '            <td class="evntIMG">\n' +
    '              <div>\n' +
    '                <img ng-src="{{adminStaticUrl}}{{map.imageUri}}" onerror="this.src=\'./images/le-sq.png\'" class="img-responsive" />\n' +
    '              </div>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <h4>{{map.name}}</h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <div class="pull-right">\n' +
    '                <button class="btn btn-danger btn-sm" ng-click="managenetwork.deleteMap(map._id)" tooltip-placement="left" tooltip="Delete Map">\n' +
    '                  <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '                </button>\n' +
    '                <button class="btn btn-danger btn-sm" ng-click="managenetwork.editMap(map._id)" tooltip-placement="left" tooltip="Edit Map">\n' +
    '                  <i class="fa fa-pencil-square-o"></i>\n' +
    '                </button>\n' +
    '              </div>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '      <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true" pagination-id="maps"></dir-pagination-controls>\n' +
    '    </div>\n' +
    '    <div ng-show="managenetwork.showInfra">\n' +
    '      <table class="table table-responsive">\n' +
    '        <tbody>\n' +
    '          <tr ng-repeat="type in managenetwork.infrastructures | filter:inSearch">\n' +
    '            <td>\n' +
    '              <h4>Provider: {{type.name}}\n' +
    '              </h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <h4>Controller Address: {{type.controlleraddress}}\n' +
    '              </h4>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <div class="pull-right">\n' +
    '                <button class="btn btn-danger btn-sm" ng-click="managenetwork.editInfrastructure(type.id)" tooltip-placement="left" tooltip="Edit Infrastructure">\n' +
    '                  <i class="fa fa-pencil-square-o"></i>\n' +
    '                </button>\n' +
    '              </div>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('manageimages/views/uploadmodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div ng-hide="modal.image" class="image-box text-center" ngf-select ngf-drop accept="image/*" ngf-pattern="\'image/*\'" ngf-drag-over-class="dragover" ng-model="modal.image">\n' +
    '    <span class="glyphicon glyphicon-camera"></span>\n' +
    '    <h4>\n' +
    '      <strong>ADD AN IMAGE</strong>\n' +
    '    </h4>\n' +
    '    <p>Choose an image that captures your event</p>\n' +
    '  </div>\n' +
    '  <div class="row modalThumb">\n' +
    '    <img ngf-src="modal.image">\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-file btn-danger" type="file" ngf-select accept="image/*" ngf-max-height="1000" ngf-max-size="1MB" ng-model="modal.image">\n' +
    '        Select File</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label><h4>Keywords</h4></label>\n' +
    '    <textarea class="form-control" rows="3" ng-model="modal.description" placeholder="comma, seperated, values"></textarea>\n' +
    '  </div>\n' +
    '  <button ng-click="modal.uploadFiles(modal.image)" class="btn btn-sm btn-danger">Submit</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="modal.exit()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('manageimages/views/manageimages.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>Images\n' +
    '        <button class="btn btn-md btn-danger pull-right mgn10r" ng-click="manageimages.uploadImage()" tooltip-placement="left" tooltip="Add Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div ng-show="manageimages.role === 1" class="row tabrow">\n' +
    '      <div class="col-md-9 col-md-offset-3">\n' +
    '        <div class="col-md-12">\n' +
    '          <label for="provider" class="control-label pull-left">Venue</label>\n' +
    '          <select class="form-control" ng-model="manageimages.venueId" ng-options="venue.id as venue.venuetitle for venue in manageimages.venues" ng-change="manageimages.getImagesByVenue(manageimages.venueId)">\n' +
    '          </select>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-9 col-md-offset-3">\n' +
    '        <div class="col-md-6">\n' +
    '          <div class="input-group">\n' +
    '            <span class="input-group-addon">\n' +
    '              <i class="fa fa-search"></i>\n' +
    '            </span>\n' +
    '            <input type="text" class="form-control" placeholder="Search Images" ng-model="imageSearch">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-6">\n' +
    '          <select class="form-control" ng-model="manageimages.limit" ng-options="limit for limit in manageimages.limits" ng-change="manageimages.setLimit(manageimages.limit)">\n' +
    '            <option value="">Limit Results</option>\n' +
    '          </select>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row fix">\n' +
    '      <div class="col-md-12">\n' +
    '        <div class="col-md-3 thumb" ng-repeat="image in manageimages.imageList | filter:imageSearch | limitTo:manageimages.limit">\n' +
    '          <img class="img-responsive" ng-src="{{staticUrl}}{{image.imageuri}}" />\n' +
    '          <div class="row">\n' +
    '            <button class="btn btn-danger btn-md pull-right" ng-click="manageimages.deleteImage(image)" tooltip-placement="left" tooltip="Delete Image">\n' +
    '              <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('main/views/main.tpl.html',
    '<div class="container-fluid main-wrap">\n' +
    '  <!-- <div class=\'navbar navbar-inverse navbar-head navbar-fixed-top\' role=\'navigation\'>\n' +
    '    <div class=\'container-fluid mainNav\'>\n' +
    '      <div class=\'navbar-header\'>\n' +
    '        <p class="navbar-brand">\n' +
    '          <img alt="Brand" src="./images/liquidedge-logo-small.png">\n' +
    '        </p>\n' +
    '        <button class=\'navbar-toggle collapsed\' type=\'button\' data-ng-init=\'navCollapsed = true\' data-ng-click=\'navCollapsed = !navCollapsed\'>\n' +
    '          <span class=\'sr-only\'>Toggle Navigation</span>\n' +
    '          <span class=\'icon-bar\'></span>\n' +
    '          <span class=\'icon-bar\'></span>\n' +
    '          <span class=\'icon-bar\'></span>\n' +
    '        </button>\n' +
    '      </div>\n' +
    '      <div class=\'navbar-collapse collapse navbar-right\' collapse=\'navCollapsed\'>\n' +
    '        <ul class=\'nav navbar-nav\'>\n' +
    '          <li data-ui-sref-active=\'active\'><a data-ui-sref="main.home">Home</a></li>\n' +
    '          <li data-ui-sref-active=\'active\'><a data-ui-sref="#">About</a></li>\n' +
    '          <li data-ui-sref-active=\'active\'><a data-ui-sref="#">PayWall</a></li>\n' +
    '          <li data-ui-sref-active=\'active\'><a data-ui-sref="#">Benefits</a></li>\n' +
    '          <li data-ui-sref-active=\'active\'><a data-ui-sref="#">Contact</a></li>\n' +
    '          <li data-ui-sref-active=\'active\'><a href="" ng-click="main.launchLogin()">Login</a></li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div> -->\n' +
    '  <div class="row gradient">\n' +
    '    <!-- <div class=\'navbar navbar-inverse navbar-main\' role=\'navigation\'>\n' +
    '      <div class=\'container-fluid\'>\n' +
    '        <div class=\'navbar-header\'>\n' +
    '          <p class="navbar-brand">\n' +
    '            <img alt="Brand" src="./images/magnetnetworks/magnet_logo_brang.png">\n' +
    '          </p>\n' +
    '          <button class=\'navbar-toggle collapsed\' type=\'button\' data-ng-init=\'navCollapsed = true\' data-ng-click=\'navCollapsed = !navCollapsed\'>\n' +
    '            <span class=\'sr-only\'>Toggle Navigation</span>\n' +
    '            <span class=\'icon-bar\'></span>\n' +
    '            <span class=\'icon-bar\'></span>\n' +
    '            <span class=\'icon-bar\'></span>\n' +
    '          </button>\n' +
    '        </div>\n' +
    '        <div class=\'navbar-collapse collapse navbar-right\' collapse=\'navCollapsed\'>\n' +
    '          <ul class=\'nav navbar-nav\'>\n' +
    '            <li data-ui-sref-active=\'active\'><a data-ui-sref="main.home">Home</a></li>\n' +
    '            <li data-ui-sref-active=\'active\'><a data-ui-sref="#">About</a></li>\n' +
    '            <li data-ui-sref-active=\'active\'><a data-ui-sref="#">PayWall</a></li>\n' +
    '            <li data-ui-sref-active=\'active\'><a data-ui-sref="#">Benefits</a></li>\n' +
    '            <li data-ui-sref-active=\'active\'><a data-ui-sref="#">Contact</a></li>\n' +
    '            <li data-ui-sref-active=\'active\'><a href="" ng-click="main.launchLogin()">Login</a></li>\n' +
    '          </ul>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div> -->\n' +
    '    <div data-ui-view>\n' +
    '\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('main/views/login.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '  <p>Enter your account details in the form below</p>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div ng-show="modal.message.type">\n' +
    '      <div class="alert alert-danger" role="alert">\n' +
    '          <button ng-click="modal.dismiss()" type="button" class="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <span class="sr-only">Error:</span> {{modal.message.msg}}\n' +
    '      </div>\n' +
    '  </div>\n' +
    '  <form name="form" ng-submit="modal.login()" role="form">\n' +
    '    <div class="form-group">\n' +
    '      <label for="username" class="control-label pull-left">LOGIN</label>\n' +
    '      <input type="text" class="form-control" id="username" placeholder="Username" ng-model="modal.loginDetails.username">\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label for="password" class="control-label pull-left">PASSWORD</label>\n' +
    '      <input type="password" class="form-control" id="password" placeholder="Credentials" ng-model="modal.loginDetails.password">\n' +
    '    </div>\n' +
    '    <div class="form-group" ng-hide="true">\n' +
    '      <label class="checkbox-inline pull-left">\n' +
    '        <input type="checkbox"> Keep me signed in\n' +
    '      </label>\n' +
    '      <label class="checkbox-inline pull-right modal-link">\n' +
    '        <a href="">Forgot Password?</a>\n' +
    '      </label>\n' +
    '    </div>\n' +
    '    <button type="submit" class="btn btn-danger btn-block">Login</button>\n' +
    '  </form>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('mailchimp/views/mailchimp.tpl.html',
    '<div id="mailchimp">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Mailchimp Settings</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="mailchimpSettings" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>API KEY</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': mailchimpSettings.apiKey.$invalid && editvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="apiKey" name="apiKey" ng-model="mailchimp.apiKey" placeholder="eg. xxxxxxxxxx-xyz" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="mailchimpSettings.apiKey.$error" ng-if="editvenue.submitted">\n' +
    '              <p ng-message="required">api key is required.</p>\n' +
    '            </div>\n' +
    '            <button ng-click="mailchimp.updateApiKey(!mailchimpSettings.$invalid)" class="btn btn-danger right">Update Api Key</button>\n' +
    '            <div>\n' +
    '              </br>\n' +
    '            </div>\n' +
    '            <div ng-show="mailchimp.ConfirmMessage">\n' +
    '              <div class="alert alert-success" role="alert" style="width: 40%;">\n' +
    '                <button ng-click="mailchimp.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '                  <span aria-hidden="true">&times;</span>\n' +
    '                </button>\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <span class="sr-only">Alert:</span>\n' +
    '                {{mailchimp.Message}}\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-show="mailchimp.ErrorMessage">\n' +
    '              <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '                <button ng-click="mailchimp.dismissError()" type="button" class="close" aria-label="Close">\n' +
    '                  <span aria-hidden="true">&times;</span>\n' +
    '                </button>\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <span class="sr-only">Alert:</span>\n' +
    '                {{mailchimp.Message}}\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="mailchimpSettings" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Lists</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <p>Current List ID: {{mailchimp.listId}}</p>\n' +
    '            <br/>\n' +
    '            <p>Choose new List from the dropdown menu or create a new list</p>\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-9">\n' +
    '                  <select class="form-control" ng-model="mailchimp.newListId">\n' +
    '                    <option ng-repeat="list in mailchimp.lists" value="{{list.id}}">{{list.name}} - {{list.id}}</option>\n' +
    '                  </select>\n' +
    '                </div>\n' +
    '                <div class="col-xs-3">\n' +
    '                  <button ng-click="mailchimp.updateList()" class="btn btn-danger right">Change List</button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div>\n' +
    '                </br>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-xs-12">\n' +
    '                  <button ng-click="mailchimp.createNewList()" class="btn btn-danger right">Create New List</button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div>\n' +
    '                </br>\n' +
    '              </div>\n' +
    '              <div ng-show="mailchimp.ListConfirmMessage">\n' +
    '                <div class="alert alert-success" role="alert" style="width: 40%;">\n' +
    '                  <button ng-click="mailchimp.listDismiss()" type="button" class="close" aria-label="Close">\n' +
    '                    <span aria-hidden="true">&times;</span>\n' +
    '                  </button>\n' +
    '                  <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                  <span class="sr-only">Alert:</span>\n' +
    '                  {{mailchimp.ListMessage}}\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div ng-show="mailchimp.ListErrorMessage">\n' +
    '                <div class="alert alert-danger" role="alert" style="width: 40%;">\n' +
    '                  <button ng-click="mailchimp.listDismissError()" type="button" class="close" aria-label="Close">\n' +
    '                    <span aria-hidden="true">&times;</span>\n' +
    '                  </button>\n' +
    '                  <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                  <span class="sr-only">Alert:</span>\n' +
    '                  {{mailchimp.ListMessage}}\n' +
    '                </div>\n' +
    '              </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('mail-list-modal/views/mail-list-modal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/magnetnetworks/magnet_logo_small.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>{{modal.mailList.listlength}} emails for this query</h4>\n' +
    '  <h4>add to mailchimp</h4>\n' +
    '  <div class="form-group">\n' +
    '    <label for="exampleInputEmail1">New List</label>\n' +
    '    <input type="text" class="form-control" id="list" placeholder="List Name">\n' +
    '  </div>\n' +
    '  <p>Or</p>\n' +
    '  <div class="form-group">\n' +
    '    <select class="form-control">\n' +
    '      <option value="">Choose Existing List</option>\n' +
    '      <option ng-repeat="list in modal.chimpList" value="{{list.id}}">{{list.name}}</option>\n' +
    '    </select>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-warning" type="button" ng-click="modal.cancel()">Cancel</button>\n' +
    '  <button class="btn btn-danger" type="button" ng-click="modal.ok()">Confirm</button>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('events/views/events.tpl.html',
    '<div class="eventspage">\n' +
    '  <div class="hidden-sm hidden-md hidden-lg">\n' +
    '    <div class="panel panel-default">\n' +
    '      <div class="panel-heading">\n' +
    '        <div class="row row-align">\n' +
    '          <h5>Events</h5>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <ul class="nav nav-tabs tabs-xs" role="tablist">\n' +
    '          <li role="presentation" ng-class="{\'active\': (events.showCurrent)}"><a href="" ng-click="events.showArchieved && events.setEventView()" aria-controls="home" role="tab">Current Events</a></li>\n' +
    '          <li role="presentation" ng-class="{\'active\': (events.showArchieved)}"><a href="" ng-click="events.showCurrent && events.setEventView()" aria-controls="profile" role="tab" data-toggle="tab">Archived Events</a></li>\n' +
    '        </ul>\n' +
    '        <ul class="list-unstyled list-margin event-list-xs">\n' +
    '          <li ng-repeat="event in events.events" ng-show="event.eventstatus != 2 && event.eventstatus != 5 && events.showCurrent">\n' +
    '\n' +
    '            <h5 class="mg-bt-10">{{event.venueName}}</h5>\n' +
    '\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-4">\n' +
    '                <img ng-src="{{staticUrl}}{{event.eventimageuri}}" class="img-responsive" />\n' +
    '              </div>\n' +
    '              <div class="col-xs-6">\n' +
    '                <h5>{{event.name}}</h5>\n' +
    '                <p class="label label-warning" ng-show="event.eventstatus === 0">Pending</p>\n' +
    '                <p class="label label-success" ng-show="event.eventstatus === 1">Active</p>\n' +
    '                <p class="label label-danger" ng-show="event.eventstatus === 2">Ended</p>\n' +
    '                <button class="btn btn-danger btn-xs" ng-show="event.eventstatus === 4" ng-click="events.showErrors(event.details)"><i class="fa fa-exclamation-circle fa-2x" aria-hidden="true"></i></button>\n' +
    '                <button class="btn btn-warning btn-xs" ng-show="event.eventstatus === 5" ng-click="events.showErrors(event.details)">Ended with Errors</button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row">\n' +
    '              <div class="mgn-top-20">\n' +
    '                <div class="col-xs-2 no-padding pull-right">\n' +
    '                  <a class="btn btn-md btn-danger btn-md" ui-sref="admin.eventanalytics({venueid: event.venue, eventid: event.id})" tooltip-placement="left" tooltip="Analytics">\n' +
    '                    <i class="fa fa-line-chart" aria-hidden="true"></i>\n' +
    '                  </a>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '        <!-- Set for archived events -->\n' +
    '        <ul class="list-unstyled list-margin event-list-xs">\n' +
    '          <li ng-repeat="event in events.events" ng-show="(event.eventstatus === 2 || event.eventstatus === 5) && events.showArchieved">\n' +
    '\n' +
    '            <h5 class="mg-bt-10">{{event.venueName}}</h5>\n' +
    '\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-4">\n' +
    '                <img ng-src="{{staticUrl}}{{event.eventimageuri}}" class="img-responsive" />\n' +
    '              </div>\n' +
    '              <div class="col-xs-6">\n' +
    '                <h5>{{event.name}}</h5>\n' +
    '                <p class="label label-warning" ng-show="event.eventstatus === 0">Pending</p>\n' +
    '                <p class="label label-success" ng-show="event.eventstatus === 1">Active</p>\n' +
    '                <p class="label label-danger" ng-show="event.eventstatus === 2">Ended</p>\n' +
    '                <button class="btn btn-danger btn-xs" ng-show="event.eventstatus === 4" ng-click="events.showErrors(event.details)"><i class="fa fa-exclamation-circle fa-2x" aria-hidden="true"></i></button>\n' +
    '                <button class="btn btn-warning btn-xs" ng-show="event.eventstatus === 5" ng-click="events.showErrors(event.details)">Ended with Errors</button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row">\n' +
    '              <div class="mgn-top-20">\n' +
    '                <div class="col-xs-2 no-padding pull-right">\n' +
    '                  <a class="btn btn-md btn-danger btn-md" ui-sref="admin.eventanalytics({venueid: event.venue, eventid: event.id})" tooltip-placement="left" tooltip="Analytics">\n' +
    '                    <i class="fa fa-line-chart" aria-hidden="true"></i>\n' +
    '                  </a>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="hidden-xs">\n' +
    '    <div class="panel panel-default">\n' +
    '      <div class="panel-heading">\n' +
    '        <div class="row row-align">\n' +
    '          <h3>Events\n' +
    '            <button class="btn btn-md btn-danger pull-right" data-ui-sref="admin.eventcreation.evcsetup" tooltip-placement="left" tooltip="Add Event"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '          </h3>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="row tabrow">\n' +
    '          <div class="col-md-9 col-md-offset-3">\n' +
    '            <div class="col-md-6">\n' +
    '              <div class="input-group">\n' +
    '                <span class="input-group-addon">\n' +
    '                  <i class="fa fa-search"></i>\n' +
    '                </span>\n' +
    '                <input type="text" class="form-control" placeholder="Search Events" ng-model="eventSearch">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="col-md-6">\n' +
    '              <select class="form-control" ng-model="events.limit" ng-options="limit for limit in events.limits" ng-change="events.setLimit(events.limit)">\n' +
    '                <option value="">Limit Results</option>\n' +
    '              </select>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <ul class="nav nav-tabs" role="tablist">\n' +
    '          <li role="presentation" ng-class="{\'active\': (events.showCurrent)}"><a href="" ng-click="events.showArchieved && events.setEventView()" aria-controls="home" role="tab">Current Events</a></li>\n' +
    '          <li role="presentation" ng-class="{\'active\': (events.showArchieved)}"><a href="" ng-click="events.showCurrent && events.setEventView()" aria-controls="profile" role="tab" data-toggle="tab">Archived Events</a></li>\n' +
    '        </ul>\n' +
    '        <table class="table table-responsive eventsTable">\n' +
    '          <thead>\n' +
    '            <tr>\n' +
    '              <th>Image</th>\n' +
    '              <th>Event</th>\n' +
    '              <th>Venue</th>\n' +
    '              <th>Duration</th>\n' +
    '              <th>Description</th>\n' +
    '            </tr>\n' +
    '          </thead>\n' +
    '\n' +
    '          <tbody ng-repeat="event in events.events | orderBy:\'-startDate\' | filter:eventSearch | limitTo:events.limit" ng-show="event.eventstatus != 2 && event.eventstatus != 5 && events.showCurrent">\n' +
    '            <tr>\n' +
    '              <td class="evntIMG">\n' +
    '                <div>\n' +
    '                  <img ng-src="{{staticUrl}}{{event.eventimageuri}}" class="img-responsive" />\n' +
    '                </div>\n' +
    '              </td>\n' +
    '              <td>\n' +
    '                <h4>{{event.name}}\n' +
    '                  <p class="label label-warning" ng-show="event.eventstatus === 0">Pending</p>\n' +
    '                  <p class="label label-success" ng-show="event.eventstatus === 1">Active</p>\n' +
    '                  <p class="label label-danger" ng-show="event.eventstatus === 2">Ended</p>\n' +
    '                  <button class="btn btn-danger btn-xs" ng-show="event.eventstatus === 4" ng-click="events.showErrors(event.details)"><i class="fa fa-exclamation-circle fa-2x" aria-hidden="true"></i></button>\n' +
    '                  <button class="btn btn-warning btn-xs" ng-show="event.eventstatus === 5" ng-click="events.showErrors(event.details)">Ended with Errors</button>\n' +
    '                </h4>\n' +
    '              </td>\n' +
    '              <td>\n' +
    '                <p>{{event.venueName}}</p>\n' +
    '              </td>\n' +
    '              <td>\n' +
    '                <p>Starts: {{event.starttime | date:\'dd/MM/yyyy @ h:mma\'}}</p>\n' +
    '\n' +
    '                <p ng-hide="event.endtime === events.maxDate">Ends: {{event.endtime | date:\'dd/MM/yyyy @ h:mma\'}}</p>\n' +
    '              </td>\n' +
    '              <td>\n' +
    '                <p>{{event.description}}</p>\n' +
    '              </td>\n' +
    '            </tr>\n' +
    '            <tr>\n' +
    '              <td colspan=5>\n' +
    '                <div class="pull-right">\n' +
    '                  <a class="btn btn-danger btn-md" ui-sref="admin.editsurvey({eventid: event.id, surveyid: event.survey})" tooltip-placement="left" tooltip="Edit Survey" ng-hide="true"><i class="fa fa-check-square-o"></i></a>\n' +
    '                  <!-- <a class="btn btn-md btn-danger btn-md" ui-sref="admin.analytics({venueid: event.venue, eventid: event.id})" tooltip-placement="left" tooltip="Analytics">\n' +
    '                  <i class="fa fa-bar-chart"></i>\n' +
    '                </a> -->\n' +
    '                  <a class="btn btn-md btn-danger btn-md" ui-sref="admin.eventanalytics({venueid: event.venue, eventid: event.id})" tooltip-placement="left" tooltip="Analytics">\n' +
    '                    <i class="fa fa-line-chart" aria-hidden="true"></i>\n' +
    '                  </a>\n' +
    '                  <button class="btn btn-danger btn-md" ng-click="events.deleteEvent(event.id, event.venue)" ng-hide="event.eventstatus === 1" tooltip-placement="left" tooltip="Delete Event">\n' +
    '                    <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '                  </button>\n' +
    '                  <!-- <a class="btn btn-danger btn-md" ui-sref="admin.event({eventid: event.id, venueid: event.venue})" tooltip-placement="left" tooltip="View Event"><i class="fa fa-eye" aria-hidden="true"></i></a> -->\n' +
    '                  <a class="btn btn-danger btn-md" ui-sref="admin.editeventnew({eventid: event.id, venueid: event.venue})" tooltip-placement="left" tooltip="Edit Event"><i class="fa fa-pencil-square-o"></i></a>\n' +
    '                  <button class="btn btn-danger btn-md" ng-click="events.sendMessage(event.venue, event.id)" tooltip-placement="left" tooltip="Send Message" ng-show="event.twilio">\n' +
    '                    <i class="fa fa-mobile fa-lg"></i>\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '              </td>\n' +
    '            </tr>\n' +
    '          </tbody>\n' +
    '\n' +
    '          <tbody ng-repeat="event in events.events | orderBy:\'-startDate\' | filter:eventSearch | limitTo:events.limit" ng-show="(event.eventstatus === 2 || event.eventstatus === 5) && events.showArchieved">\n' +
    '            <tr>\n' +
    '              <td class="evntIMG">\n' +
    '                <div>\n' +
    '                  <img ng-src="{{staticUrl}}{{event.eventimageuri}}" class="img-responsive" />\n' +
    '                </div>\n' +
    '              </td>\n' +
    '              <td>\n' +
    '                <h4>{{event.name}}</h4>\n' +
    '                <p class="label label-warning" ng-show="event.eventstatus === 0">Pending</p>\n' +
    '                <p class="label label-success" ng-show="event.eventstatus === 1">Active</p>\n' +
    '                <p class="label label-danger" ng-show="event.eventstatus === 2">Ended</p>\n' +
    '                <button class="btn btn-warning" ng-show="event.eventstatus === 4" ng-click="events.showErrors(event.details)">Active with Errors</button>\n' +
    '                <button class="btn btn-warning" ng-show="event.eventstatus === 5" ng-click="events.showErrors(event.details)">Ended with Errors</button>\n' +
    '              </td>\n' +
    '              <td>\n' +
    '                <p>{{event.venueName}}</h4>\n' +
    '              </td>\n' +
    '              <td>\n' +
    '                <p>Starts: {{event.starttime | date:\'dd/MM/yyyy @ h:mma\'}}</p>\n' +
    '\n' +
    '                <p>Ends: {{event.endtime | date:\'dd/MM/yyyy @ h:mma\'}}</p>\n' +
    '              </td>\n' +
    '              <td>\n' +
    '                <p>{{event.description}}</p>\n' +
    '              </td>\n' +
    '            </tr>\n' +
    '            <tr>\n' +
    '              <td colspan=5>\n' +
    '                <div class="pull-right">\n' +
    '                  <a class="btn btn-md btn-danger btn-md" ui-sref="admin.eventanalytics({venueid: event.venue, eventid: event.id})" tooltip-placement="left" tooltip="Analytics">\n' +
    '                    <i class="fa fa-line-chart" aria-hidden="true"></i>\n' +
    '                  </a>\n' +
    '                  <a class="btn btn-danger btn-md" ui-sref="admin.duplicateevent({eventid: event.id, venueid: event.venue})" tooltip-placement="left" tooltip="Duplicate Event"><i class="fa fa-files-o" aria-hidden="true"></i></a>\n' +
    '                  <a class="btn btn-danger btn-md" ui-sref="admin.event({eventid: event.id, venueid: event.venue})" tooltip-placement="left" tooltip="View Event"><i class="fa fa-eye" aria-hidden="true"></i></a>\n' +
    '                  <button class="btn btn-danger btn-md" ng-click="events.deleteEvent(event.id, event.venue)" ng-hide="event.eventstatus === 1" tooltip-placement="left" tooltip="Delete Event">\n' +
    '                    <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '              </td>\n' +
    '            </tr>\n' +
    '          </tbody>\n' +
    '\n' +
    '        </table>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('events/views/errormodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/magnetnetworks/magnet_logo_small.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>{{modalService.message.messageHeader}}</h4>\n' +
    '  <p>{{modalService.message.messageBody}}</p>\n' +
    '  <table class="table table-responsive eventsTable" ng-show="modalService.message.xirrus">\n' +
    '    <thead>\n' +
    '      <tr>\n' +
    '        <th>Access Point</th>\n' +
    '      </tr>\n' +
    '    </thead>\n' +
    '    <tbody>\n' +
    '      <tr ng-repeat="ap in modalService.message.accesspoints">\n' +
    '        <td>\n' +
    '          {{ap.ipaddress}}\n' +
    '        </td>\n' +
    '      </tr>\n' +
    '    </tbody>\n' +
    '  </table>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <div ng-hide="modalService.message.cancel.length > 0">\n' +
    '    <button class="btn btn-primary btn-block" type="button" ng-click="modalService.ok()">Done</button>\n' +
    '  </div>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('events/views/deletemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/magnetnetworks/magnet_logo_small.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this event?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="deleteModal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="deleteModal.cancel()">Cancel</button>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/views/home.tpl.html',
    '<div class="container">\n' +
    '  <div class="col-md-5 side-text">\n' +
    '    <div class="mgn-btm-20">\n' +
    '      <img alt="Brand" src="./images/magnetnetworks/magnet_logo_small.png">\n' +
    '    </div>\n' +
    '    <form name="form" ng-submit="home.login()" role="form">\n' +
    '      <div class="form-group">\n' +
    '        <label for="username" class="control-label pull-left">LOGIN</label>\n' +
    '        <input type="text" class="form-control" id="username" placeholder="Username" ng-model="home.loginDetails.username">\n' +
    '      </div>\n' +
    '      <div class="form-group">\n' +
    '        <label for="password" class="control-label pull-left">PASSWORD</label>\n' +
    '        <input type="password" class="form-control" id="password" placeholder="Credentials" ng-model="home.loginDetails.password">\n' +
    '      </div>\n' +
    '      <div class="form-group" ng-hide="true">\n' +
    '        <label class="checkbox-inline pull-left">\n' +
    '          <input type="checkbox"> Keep me signed in\n' +
    '        </label>\n' +
    '        <label class="checkbox-inline pull-right modal-link">\n' +
    '          <a href="">Forgot Password?</a>\n' +
    '        </label>\n' +
    '      </div>\n' +
    '      <button type="submit" class="btn btn-danger btn-small pull-right">Login</button>\n' +
    '    </form>\n' +
    '    <br />\n' +
    '    <br />\n' +
    '  </div>\n' +
    '  <div class="col-md-7">\n' +
    '    <img src="./images/connect2fi/home-graphic.png" alt="liquidedge phone graphic" class="img-responsive" />\n' +
    '  </div>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventanalytics/views/eventanalytics.tpl.html',
    '<div class="eventanalytics">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <div class="row row-align">\n' +
    '        <h3>Event Analytics: {{eventanalytics.event.name}}</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <ul class="nav nav-tabs">\n' +
    '        <li ng-class="{\'active\': (eventanalytics.tab === \'live\')}" ng-hide="eventanalytics.event.eventstatus === 2"><a href="" ng-click="eventanalytics.switchTab(\'live\')">Live</a></li>\n' +
    '        <li ng-class="{\'active\': (eventanalytics.tab === \'historic\')}"><a href="" ng-click="eventanalytics.switchTab(\'historic\')">Historic</a></li>\n' +
    '       <li ng-class="{\'active\': (eventanalytics.tab === \'users\')}"><a href="" ng-click="eventanalytics.switchTab(\'users\')">Customer Profiles</a></li>\n' +
    '      </ul>\n' +
    '      <div ng-show="eventanalytics.tab === \'live\'">\n' +
    '        <div class="row">\n' +
    '          <div class="col-sm-6">\n' +
    '            <h3 class="margin-analytics-xs">Activity</h3>\n' +
    '            <canvas id="line" class="chart chart-line" chart-data="eventanalytics.liveDataChart.data" chart-labels="eventanalytics.liveDataChart.labels" chart-series="eventanalytics.liveDataChart.series" chart-options="eventanalytics.liveDataChart.options" chart-colors="eventanalytics.chartColors">\n' +
    '            </canvas>\n' +
    '          </div>\n' +
    '          <div class="col-sm-6">\n' +
    '            <h3 class="margin-analytics-xs">Activity By Zone\n' +
    '              <button ng-click="eventanalytics.showActivityZoneTable=!eventanalytics.showActivityZoneTable" class="btn btn-danger pull-right">\n' +
    '                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="eventanalytics.showActivityZoneTable"></i>\n' +
    '                <i class="fa fa-table" aria-hidden="true" ng-show="!eventanalytics.showActivityZoneTable"></i>\n' +
    '              </button>\n' +
    '            </h3>\n' +
    '            <div class="cWrap">\n' +
    '              <div ng-if="!eventanalytics.showActivityZoneTable" class="animated-class chartFront">\n' +
    '                <canvas id="base" class="chart chart-bar" chart-data="eventanalytics.connectedUsersBarChart.data" chart-labels="eventanalytics.connectedUsersBarChart.labels" chart-options="eventanalytics.connectedUsersBarChart.options" chart-series="eventanalytics.connectedUsersBarChart.series" chart-colors="eventanalytics.chartColors">\n' +
    '              </div>\n' +
    '              <div class="animated-class" ng-if="eventanalytics.showActivityZoneTable">\n' +
    '                <table class="table table-responsive table-striped table-bordered">\n' +
    '                  <thead>\n' +
    '                    <tr>\n' +
    '                      <th ng-repeat="labels in eventanalytics.connectedTable.zoneheaders">{{labels}}</th>\n' +
    '                    </tr>\n' +
    '                  </thead>\n' +
    '                  <tbody>\n' +
    '                    <tr ng-repeat="item in eventanalytics.connectedTable.zones | orderBy:\'-count\'">\n' +
    '                      <td>{{item.name}}</td>\n' +
    '                      <td>{{item.count}}</td>\n' +
    '                    </tr>\n' +
    '                  </tbody>\n' +
    '                </table>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div ng-show="eventanalytics.tab === \'historic\'">\n' +
    '        <div class="form-row margin_top dateFix">\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="startdate" class="control-label pull-left">START DATE</label>\n' +
    '            <input ng-change="eventanalytics.refresh()" ng-click="eventanalytics.openCalSt()" type="text" class="form-control" min-date="eventanalytics.minDate" max-date="eventanalytics.endtime" name="startDate" datepicker-popup="{{eventanalytics.dateFormat}}" ng-model="eventanalytics.starttime" is-open="eventanalytics.status.openedSt" datepicker-options="eventanalytics.dateOptions" ng-required="true" close-text="Close" />\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <button type="button" class="btn btn-date" ng-click="eventanalytics.openCalSt()">\n' +
    '              <i class="glyphicon glyphicon-calendar"></i>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="endtime" class="control-label pull-left">END DATE</label>\n' +
    '            <input ng-change="eventanalytics.refresh()" ng-click="eventanalytics.openCalEnd()" type="text" class="form-control" min-date="eventanalytics.starttime" max-date="eventanalytics.today" datepicker-popup="{{eventanalytics.dateFormat}}" ng-model="eventanalytics.endtime" is-open="eventanalytics.status.openedEnd" datepicker-options="eventanalytics.dateOptions" ng-required="true" close-text="Close" />\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <button type="button" class="btn btn-date" ng-click="eventanalytics.openCalEnd()">\n' +
    '              <i class="glyphicon glyphicon-calendar"></i>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="col-sm-6">\n' +
    '            <h3 class="margin-analytics-xs">Footfall\n' +
    '              <button ng-click="eventanalytics.showFootfallTable=!eventanalytics.showFootfallTable" class="btn btn-danger pull-right">\n' +
    '                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="eventanalytics.showFootfallTable"></i>\n' +
    '                <i class="fa fa-table" aria-hidden="true" ng-show="!eventanalytics.showFootfallTable"></i>\n' +
    '              </button>\n' +
    '            </h3>\n' +
    '            <div ng-show="eventanalytics.loading" class="loader">\n' +
    '              <img src="./images/gears.gif" alt="">\n' +
    '              <p>Generating Visitor Data</p>\n' +
    '              </dev>\n' +
    '            </div>\n' +
    '            <div class="cWrap" ng-hide="eventanalytics.loading">\n' +
    '              <div ng-if="!eventanalytics.showFootfallTable" class="animated-class chartFront">\n' +
    '                <canvas id="base" class="chart chart-bar" chart-data="eventanalytics.visitorsLoginData.data" chart-labels="eventanalytics.visitorsLoginData.labels" chart-colors="eventanalytics.chartColors" chart-dataset-override="eventanalytics.visitorsLoginData.datasetOverride" chart-options="eventanalytics.mixedChartOptions">\n' +
    '                </canvas>\n' +
    '              </div>\n' +
    '              <div class="animated-class" ng-if="eventanalytics.showFootfallTable">\n' +
    '                <table class="table table-striped table-bordered">\n' +
    '                  <thead>\n' +
    '                    <tr>\n' +
    '                      <th ng-repeat="header in eventanalytics.footfallTable.headers">{{header}}</th>\n' +
    '                    </tr>\n' +
    '                  </thead>\n' +
    '                  <tbody>\n' +
    '                    <tr ng-repeat="item in eventanalytics.footfallTable.rows">\n' +
    '                      <td>{{item.type}}</td>\n' +
    '                      <td>{{item.newVisitors}}</td>\n' +
    '                      <td>{{item.returningVisitors}}</td>\n' +
    '                      <td>{{item.total}}</td>\n' +
    '                    </tr>\n' +
    '                  </tbody>\n' +
    '                </table>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="col-sm-2 col-sm-offset-3 col-xs-offset-2 analytics-block-sm">\n' +
    '            <div class="row margin-analytics-xs margin-analytics-top">\n' +
    '              <p>Gender</p>\n' +
    '              <ul class="list-unstyled gender-list">\n' +
    '                <li class="blueicon">\n' +
    '                  <strong><i class="fa fa-male" aria-hidden="true"></i> : {{eventanalytics.genderMale}}</strong>\n' +
    '                </li>\n' +
    '                <li class="pinkicon">\n' +
    '                  <strong><i class="fa fa-female" aria-hidden="true"></i> : {{eventanalytics.genderFemale}}</strong>\n' +
    '                </li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '            <div class="row margin-analytics-xs">\n' +
    '              <p>Unique Visitors</p>\n' +
    '              <p class="analytictext greenicon">\n' +
    '                <strong><i class="fa fa-users" aria-hidden="true"></i> : {{eventanalytics.uniqueUsers}}</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '            <div class="row margin-analytics-xs">\n' +
    '              <p>Total Logins</p>\n' +
    '              <p class="analytictext darkicon">\n' +
    '                <strong><i class="fa fa-users" aria-hidden="true"></i> : {{eventanalytics.totalLogin}}</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '            <div class="row margin-analytics-xs">\n' +
    '              <p>Total Impressions</p>\n' +
    '              <p class="analytictext darkicon">\n' +
    '                <strong><i class="fa fa-mobile" aria-hidden="true"></i> : {{eventanalytics.totalImpressions}}</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="col-sm-6">\n' +
    '            <h3 class="margin-analytics-xs">Login Method\n' +
    '              <button ng-click="eventanalytics.showLoginTable=!eventanalytics.showLoginTable" class="btn btn-danger pull-right">\n' +
    '                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="eventanalytics.showLoginTable"></i>\n' +
    '                <i class="fa fa-table" aria-hidden="true" ng-show="!eventanalytics.showLoginTable"></i>\n' +
    '              </button>\n' +
    '            </h3>\n' +
    '            <div class="cWrap">\n' +
    '              <div ng-if="!eventanalytics.showLoginTable" class="animated-class chartFront">\n' +
    '                <canvas id="base" class="chart-horizontal-bar" chart-data="eventanalytics.horizontal.data" chart-labels="eventanalytics.horizontal.labels" chart-options="eventanalytics.horizontal.options" chart-series="eventanalytics.horizontal.series" chart-colors="eventanalytics.typeChartColors">\n' +
    '                </canvas>\n' +
    '              </div>\n' +
    '              <div class="animated-class" ng-if="eventanalytics.showLoginTable">\n' +
    '                <table class="table table-responsive table-striped table-bordered">\n' +
    '                  <thead>\n' +
    '                    <tr>\n' +
    '                      <th ng-repeat="header in eventanalytics.loginTable.headers">{{header}}</th>\n' +
    '                    </tr>\n' +
    '                  </thead>\n' +
    '                  <tbody>\n' +
    '                    <tr ng-repeat="row in eventanalytics.loginTable.rows">\n' +
    '                      <td>{{row.type}}</td>\n' +
    '                      <td>{{row.count}}</td>\n' +
    '                    </tr>\n' +
    '                  </tbody>\n' +
    '                </table>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="col-sm-6">\n' +
    '            <h3 class="margin-analytics-xs">Gender Demographics\n' +
    '              <button ng-click="eventanalytics.showgenderTable=!eventanalytics.showgenderTable" class="btn btn-danger pull-right">\n' +
    '                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="eventanalytics.showgenderTable"></i>\n' +
    '                <i class="fa fa-table" aria-hidden="true" ng-show="!eventanalytics.showgenderTable"></i>\n' +
    '              </button>\n' +
    '            </h3>\n' +
    '            <div class="cWrap">\n' +
    '              <div ng-if="!eventanalytics.showgenderTable" class="animated-class chartFront">\n' +
    '                <canvas id="base" class="chart chart-bar" chart-data="eventanalytics.genderBarChart.data" chart-labels="eventanalytics.genderBarChart.labels" chart-options="eventanalytics.genderBarChart.options" chart-series="eventanalytics.genderBarChart.series" chart-colors="eventanalytics.genderChartColors">\n' +
    '              </div>\n' +
    '              <div class="animated-class" ng-if="eventanalytics.showgenderTable">\n' +
    '                <table class="table table-responsive table-striped table-bordered">\n' +
    '                  <thead>\n' +
    '                    <tr>\n' +
    '                      <th ng-repeat="header in eventanalytics.genderTable.headers">{{header}}</th>\n' +
    '                    </tr>\n' +
    '                  </thead>\n' +
    '                  <tbody>\n' +
    '                    <tr ng-repeat="row in eventanalytics.genderTable.rows">\n' +
    '                      <td>{{row.type}}</td>\n' +
    '                      <td>{{row.count}}</td>\n' +
    '                    </tr>\n' +
    '                  </tbody>\n' +
    '                </table>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="col-sm-6">\n' +
    '            <h3 class="margin-analytics-xs">Splashpage Impressions\n' +
    '              <button ng-click="eventanalytics.showImpressionsTable=!eventanalytics.showImpressionsTable" class="btn btn-danger pull-right">\n' +
    '                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="eventanalytics.showImpressionsTable"></i>\n' +
    '                <i class="fa fa-table" aria-hidden="true" ng-show="!eventanalytics.showImpressionsTable"></i>\n' +
    '              </button>\n' +
    '            </h3>\n' +
    '            <div class="cWrap">\n' +
    '              <div ng-if="!eventanalytics.showImpressionsTable" class="animated-class chartFront">\n' +
    '                <canvas id="line" class="chart chart-line" chart-data="eventanalytics.splashLoadChart.data" chart-labels="eventanalytics.splashLoadChart.labels" chart-series="eventanalytics.splashLoadChart.series" chart-options="eventanalytics.splashLoadChart.options" chart-colors="eventanalytics.chartColors"></canvas>\n' +
    '              </div>\n' +
    '              <div class="animated-class" ng-if="eventanalytics.showImpressionsTable">\n' +
    '                <table class="table table-responsive table-striped table-bordered">\n' +
    '                  <thead>\n' +
    '                    <tr>\n' +
    '                      <th>Date/Time</th>\n' +
    '                      <th ng-repeat="series in eventanalytics.splashLoadChart.series">{{series}}</th>\n' +
    '                    </tr>\n' +
    '                  </thead>\n' +
    '                  <tbody>\n' +
    '                    <tr ng-repeat="label in eventanalytics.splashLoadChart.labels">\n' +
    '                      <td>{{label}}</td>\n' +
    '                      <td ng-repeat="item in eventanalytics.splashLoadChart.data"> {{item[$parent.$index]}}</td>\n' +
    '                    </tr>\n' +
    '                  </tbody>\n' +
    '                </table>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="col-sm-6">\n' +
    '            <h3 class="margin-analytics-xs">Average Dwell Time\n' +
    '              <button ng-click="eventanalytics.showDwellTable=!eventanalytics.showDwellTable" class="btn btn-danger pull-right">\n' +
    '                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="eventanalytics.showImpressionsTable"></i>\n' +
    '                <i class="fa fa-table" aria-hidden="true" ng-show="!eventanalytics.showImpressionsTable"></i>\n' +
    '              </button>\n' +
    '            </h3>\n' +
    '            <div ng-show="eventanalytics.dwellLoading" class="loader">\n' +
    '              <img src="./images/gears.gif" alt="">\n' +
    '              <p>Generating Visitor Data</p>\n' +
    '              </dev>\n' +
    '            </div>\n' +
    '            <div class="cWrap" ng-hide="eventanalytics.dwellLoading">\n' +
    '              <div ng-if="!eventanalytics.showDwellTable" class="animated-class chartFront">\n' +
    '                <canvas id="dwell" class="chart chart-bar" chart-data="eventanalytics.dwellChart.data" chart-labels="eventanalytics.dwellChart.labels" chart-options="eventanalytics.dwellChart.options" chart-series="eventanalytics.dwellChart.series" chart-colors="eventanalytics.chartColors">\n' +
    '                </canvas>\n' +
    '              </div>\n' +
    '              <div class="animated-class" ng-if="eventanalytics.showDwellTable">\n' +
    '                <table class="table table-striped table-bordered">\n' +
    '                  <thead>\n' +
    '                    <tr>\n' +
    '                      <th ng-repeat="header in eventanalytics.dwellTable.zoneheaders">{{header}}</th>\n' +
    '                    </tr>\n' +
    '                  </thead>\n' +
    '                  <tbody>\n' +
    '                    <tr ng-repeat="item in eventanalytics.dwellTable.zones | orderBy:\'-time\'">\n' +
    '                      <td>{{item.name}}</td>\n' +
    '                      <td>{{item.time}}</td>\n' +
    '                    </tr>\n' +
    '                  </tbody>\n' +
    '                </table>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div ng-show="eventanalytics.tab === \'users\'">\n' +
    '        <div class="form-row margin_top dateFix">\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="startdate" class="control-label pull-left">START DATE</label>\n' +
    '            <input ng-change="eventanalytics.refresh()" ng-click="eventanalytics.openUserCalSt()" type="text" class="form-control" min-date="eventanalytics.minDate" max-date="eventanalytics.endtime" name="startDate" datepicker-popup="{{eventanalytics.dateFormat}}" ng-model="eventanalytics.starttime" is-open="eventanalytics.status.openedUsrSt" datepicker-options="eventanalytics.dateOptions" ng-required="true" close-text="Close" />\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <button type="button" class="btn btn-date" ng-click="eventanalytics.openUserCalSt()">\n' +
    '              <i class="glyphicon glyphicon-calendar"></i>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="endtime" class="control-label pull-left">END DATE</label>\n' +
    '            <input ng-change="eventanalytics.refresh()" ng-click="eventanalytics.openUserCalEnd()" type="text" class="form-control" min-date="eventanalytics.starttime" max-date="eventanalytics.today" datepicker-popup="{{eventanalytics.dateFormat}}" ng-model="eventanalytics.endtime" is-open="eventanalytics.status.openedUsrEnd" datepicker-options="eventanalytics.dateOptions" ng-required="true" close-text="Close" />\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <button type="button" class="btn btn-date" ng-click="eventanalytics.openUserCalEnd()">\n' +
    '              <i class="glyphicon glyphicon-calendar"></i>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="margin_top col-sm-12">\n' +
    '            <div class="row tabrow">\n' +
    '              <div class="col-sm-2">\n' +
    '                <button ng-click="eventanalytics.showProfileTable=!eventanalytics.showProfileTable" class="btn btn-danger">\n' +
    '                  <i class="fa fa-list" aria-hidden="true" ng-show="!eventanalytics.showProfileTable"></i>\n' +
    '                  <i class="fa fa-th-large" aria-hidden="true" ng-show="eventanalytics.showProfileTable"></i>\n' +
    '                </button>\n' +
    '                <button type="button" ng-click="eventanalytics.downloadCSV" class="btn btn-danger">Export</button>\n' +
    '              </div>\n' +
    '              <div class="col-sm-10">\n' +
    '                <div class="col-sm-4">\n' +
    '                  <div class="input-group">\n' +
    '                    <span class="input-group-addon">\n' +
    '                      <i class="fa fa-search"></i>\n' +
    '                    </span>\n' +
    '                    <input type="text" class="form-control" placeholder="Search Users" ng-model="userSearch">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <div class="col-sm-4">\n' +
    '                  <select class="form-control" ng-model="eventanalytics.limit" ng-options="limit for limit in eventanalytics.limits">\n' +
    '                    <option value="">Limit Results</option>\n' +
    '                  </select>\n' +
    '                </div>\n' +
    '                <div class="col-sm-4">\n' +
    '                  <select class="form-control" ng-model="activity" ng-options="filter.value as filter.label for filter in  eventanalytics.activityFilter" ng-change="eventanalytics.setLimit(activity)">\n' +
    '                    <option value>Limit by Activity</option>\n' +
    '                  </select>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-if="!eventanalytics.showProfileTable" class="animated-class fix">\n' +
    '              <div class="col-md-2 col-sm-3 cardFrame" ng-repeat="user in eventanalytics.userProfiles | orderBy:\'-logins\' | filter:userSearch | limitTo:eventanalytics.limit | filter: {isLive :eventanalytics.activity || undefined}">\n' +
    '                <div class="col-xs-12 border card">\n' +
    '                  <div class="row">\n' +
    '                    <div ng-show="user.socialdata.provider == \'google\'">\n' +
    '                      <div class="col-xs-8 col-xs-offset-2" style="background-image: url(user.socialdata.photos[0].value); background-size : cover;">\n' +
    '                        <div class="liveAlert">\n' +
    '                          <div class="alert alert-success" role="alert" ng-if="user.isLive === \'true\'">Online</div>\n' +
    '                          <div class="alert alert-danger" role="alert" ng-if="user.isLive === \'false\'">Offline</div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                    <div ng-show="user.socialdata.provider == \'facebook\'">\n' +
    '                      <div class="col-xs-12 bckIMG" style="background-image: url(https://graph.facebook.com/{{user.socialdata.id}}/picture?type=large); background-size : cover;">\n' +
    '                        <div class="liveAlert">\n' +
    '                          <div class="alert alert-success" role="alert" ng-if="user.isLive === \'true\'">Online</div>\n' +
    '                          <div class="alert alert-danger" role="alert" ng-if="user.isLive === \'false\'">Offline</div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                    <div ng-show="user.socialdata.provider == \'twitter\'">\n' +
    '                      <div class="col-xs-12 bckIMG" style="background-image: url(https://twitter.com/{{user.socialdata.username}}/profile_image?size=original); background-size : cover;">\n' +
    '                        <div class="liveAlert">\n' +
    '                          <div class="alert alert-success" role="alert" ng-if="user.isLive === \'true\'">Online</div>\n' +
    '                          <div class="alert alert-danger" role="alert" ng-if="user.isLive === \'false\'">Offline</div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                    <div ng-show="!user.socialdata.provider">\n' +
    '                      <div class="col-xs-12 bckIMG" style="background-image: url(images/usericon.jpg); background-size : cover;">\n' +
    '                        <div class="liveAlert">\n' +
    '                          <div class="alert alert-success" role="alert" ng-if="user.isLive === \'true\'">Online</div>\n' +
    '                          <div class="alert alert-danger" role="alert" ng-if="user.isLive === \'false\'">Offline</div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                  <div class="row rowbtm">\n' +
    '                    <div class="col-xs-12">\n' +
    '                      <a href="#/admin/userprofile/{{eventanalytics.venueid}}/{{user.id}}/{{eventanalytics.venue.infrastructure.provider}}">Name: {{user.name}}</a>\n' +
    '                      <div class="row">\n' +
    '                        <div class="col-xs-8">\n' +
    '                          <p>Visits: {{user.logins}}</p>\n' +
    '                        </div>\n' +
    '                        <div class="col-xs-4">\n' +
    '                          <a ng-show="user.socialdata.provider == \'facebook\'" class="btn btn-primary btn-social-link pull-right" ng-href="{{user.socialdata.profileUrl}}" target="-_blank"><i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i></a>\n' +
    '                          <a ng-show="user.socialdata.provider == \'google\'" class="btn btn-danger btn-social-link" ng-href="https://plus.google.com/u/0/{{user.socialdata.id}}" target="-_blank">\n' +
    '                            <i class="fa fa-google-plus-square fa-2x" aria-hidden="true"></i>\n' +
    '                            </a>\n' +
    '                          <a ng-show="user.socialdata.provider == \'linkedin\'" class="btn btn-primary btn-social-link" ng-href="https://www.linkedin.com/in/{{user.socialdata.id}}" target="-_blank">\n' +
    '                            <i class="fa fa-linkedin-square fa-2x" aria-hidden="true"></i>\n' +
    '                            </a>\n' +
    '                          <a ng-show="user.socialdata.provider == \'twitter\'" class="btn btn-primary btn-social-link" ng-href="https://twitter.com/{{user.socialdata.username}}" target="-_blank">\n' +
    '                            <i class="fa fa-twitter-square fa-2x" aria-hidden="true"></i>\n' +
    '                            </a>\n' +
    '                          <a ng-show="!user.socialdata.provider" class="btn btn-warning btn-social-link pull-right" ng-href="https://twitter.com/{{user.socialdata.username}}" target="-_blank">\n' +
    '                                <i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i>\n' +
    '                                </a>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                      <div class="row">\n' +
    '                        <ul class="list-group tagList">\n' +
    '                          <li ng-repeat="tag in user.tag" class="list-group-item list-group-item-success">{{tag}}</li>\n' +
    '                        </ul>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-if="eventanalytics.showProfileTable" class="animated-class">\n' +
    '              <table class="table table-responsive eventsTable">\n' +
    '                <thead>\n' +
    '                  <tr>\n' +
    '                    <th>Photo</th>\n' +
    '                    <th>Name</th>\n' +
    '                    <th>Visits</th>\n' +
    '                    <th>Social Media Profile</th>\n' +
    '                    <th>Tags</th>\n' +
    '                  </tr>\n' +
    '                </thead>\n' +
    '                <tbody ng-repeat="user in eventanalytics.userProfiles | orderBy:\'-logins\' | filter:userSearch | limitTo:eventanalytics.limit | filter: {isLive :activity || undefined}">\n' +
    '                  <tr>\n' +
    '                    <td class="profileList">\n' +
    '                      <div ng-show="user.socialdata.provider == \'google\'">\n' +
    '                        <a href="#/admin/userprofile/{{eventanalytics.venueid}}/{{user.id}}/{{eventanalytics.venue.infrastructure.provider}}">\n' +
    '                          <img ng-src="{{user.socialdata.photos[0].value}}" class="img-responsive" />\n' +
    '                        </a>\n' +
    '                      </div>\n' +
    '                      <div ng-show="user.socialdata.provider == \'facebook\'">\n' +
    '                        <a href="#/admin/userprofile/{{eventanalytics.venueid}}/{{user.id}}/{{eventanalytics.venue.infrastructure.provider}}">\n' +
    '                          <img ng-src="https://graph.facebook.com/{{user.socialdata.id}}/picture?type=large" class="scale-down" />\n' +
    '                        </a>\n' +
    '                      </div>\n' +
    '                      <div ng-show="user.socialdata.provider == \'twitter\'">\n' +
    '                        <a href="#/admin/userprofile/{{eventanalytics.venueid}}/{{user.id}}/{{eventanalytics.venue.infrastructure.provider}}">\n' +
    '                          <img ng-src="https://twitter.com/{{user.socialdata.username}}/profile_image?size=original" class="img-responsive" />\n' +
    '                        </a>\n' +
    '                      </div>\n' +
    '                      <div ng-show="!user.socialdata.provider">\n' +
    '                        <img ng-src="images/usericon.jpg" class="img-responsive" />\n' +
    '                      </div>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <a href="#/admin/userprofile/{{eventanalytics.venueid}}/{{user.id}}/{{eventanalytics.venue.infrastructure.provider}}">\n' +
    '                        {{user.name}}\n' +
    '                      </a>\n' +
    '                      <ul class="list-group tagList">\n' +
    '                        <li class="list-group-item list-group-item-success" ng-if="user.isLive === \'true\'">Online</li>\n' +
    '                        <li class="list-group-item list-group-item-danger" ng-if="user.isLive === \'false\'">Offline</li>\n' +
    '                      </ul>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <p>Visits: {{user.logins}}</p>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <div ng-show="user.socialdata.provider == \'facebook\'">\n' +
    '                        <a class="btn btn-primary btn-social-link" ng-href="{{user.socialdata.profileUrl}}" target="-_blank">\n' +
    '                          <i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i>\n' +
    '                        </a>\n' +
    '                      </div>\n' +
    '                      <div ng-show="user.socialdata.provider == \'google\'">\n' +
    '                        <a class="btn btn-danger btn-social-link" ng-href="https://plus.google.com/u/0/{{user.socialdata.id}}" target="-_blank">\n' +
    '                          <i class="fa fa-google-plus-square fa-2x" aria-hidden="true"></i>\n' +
    '                        </a>\n' +
    '                      </div>\n' +
    '                      <div ng-show="user.socialdata.provider == \'linkedin\'">\n' +
    '                        <a class="btn btn-primary btn-social-link" ng-href="https://www.linkedin.com/in/{{user.socialdata.id}}" target="-_blank">\n' +
    '                          <i class="fa fa-linkedin-square fa-2x" aria-hidden="true"></i>\n' +
    '                        </a>\n' +
    '                      </div>\n' +
    '                      <div ng-show="user.socialdata.provider == \'twitter\'">\n' +
    '                        <a class="btn btn-primary btn-social-link" ng-href="https://twitter.com/{{user.socialdata.username}}" target="-_blank">\n' +
    '                          <i class="fa fa-twitter-square fa-2x" aria-hidden="true"></i>\n' +
    '                        </a>\n' +
    '                      </div>\n' +
    '                      <div ng-show="!user.socialdata.provider">\n' +
    '                        <a class="btn btn-warning btn-social-link" ng-href="" target="-_blank">\n' +
    '                        <i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i>\n' +
    '                        </a>\n' +
    '                      </div>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <ul class="list-group tagList">\n' +
    '                        <li ng-repeat="tag in user.tag" class="list-group-item list-group-item-success">{{tag}}</li>\n' +
    '                      </ul>\n' +
    '                    </td>\n' +
    '                  </tr>\n' +
    '                </tbody>\n' +
    '              </table>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('event/views/event.tpl.html',
    '<h2>{{event.event.eventName}}</h2>\n' +
    '<div class="panel panel-default">\n' +
    '  <div class="eventPadding">\n' +
    '    <div class="row">\n' +
    '      <div class="col-md-6">\n' +
    '        <div class="panel panel-default">\n' +
    '          <div class="panel-heading">\n' +
    '            <h4>Event Summary</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <p>Name: {{event.event.name}}</p>\n' +
    '            <p>Description: {{event.event.description}}\n' +
    '            <p>Start Date: {{event.event.starttime | date:\'medium\'}}</p>\n' +
    '            <p>End Date: {{event.event.endtime | date:\'medium\'}}</p>\n' +
    '            <p>Network Name: {{event.event.ssidname}}</p>\n' +
    '            <p>Network Type: {{event.event.networktypename}}</p>\n' +
    '            <p ng-if="event.event.categories[0].provider === 1">Bandwith Limit: {{event.event.trafficlimitsta}}</p>\n' +
    '            <p>Splash Page Type: {{event.event.splashtypename}}</p>\n' +
    '            <p>Redirection: {{event.event.redirectionurl}}</p>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <!--<div class="col-md-6">\n' +
    '        <div class="col-md-8 col-md-offset-2">\n' +
    '          <div class="splashBackground" ng-style="{ \'background-image\': \'url(\' + staticUrl + event.event.backgroundimage + \')\' }">\n' +
    '            <div class="content">\n' +
    '              <div class="row venueLogo">\n' +
    '                <div class="col-xs-8 col-xs-offset-2">\n' +
    '                  <img ng-src="{{staticUrl}}{{event.event.logoimage}}" class="img-responsive" />\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row splashLogin">\n' +
    '                <div class="col-xs-10 col-xs-offset-1">\n' +
    '                  <div class="scaled">\n' +
    '                    <div class="content">\n' +
    '                      <div class="col-xs-12 socIcons">\n' +
    '                        <ul class="socIconsList">\n' +
    '                          <li ng-show="event.socialLoginTypes.indexOf(1) > -1">\n' +
    '                            <button class="btn btn-social btn-facebook">\n' +
    '                              <div class="content">\n' +
    '\n' +
    '                              </div>\n' +
    '                            </button>\n' +
    '                          </li>\n' +
    '                          <li ng-show="event.socialLoginTypes.indexOf(2) > -1">\n' +
    '                            <button class="btn btn-social btn-google">\n' +
    '                              <div class="content">\n' +
    '\n' +
    '                              </div>\n' +
    '                            </button>\n' +
    '                          </li>\n' +
    '                          <li ng-show="event.socialLoginTypes.indexOf(3) > -1">\n' +
    '                            <button class="btn btn-social btn-twitter">\n' +
    '                              <div class="content">\n' +
    '\n' +
    '                              </div>\n' +
    '                            </button>\n' +
    '                          </li>\n' +
    '                          <li ng-show="event.socialLoginTypes.indexOf(4) > -1">\n' +
    '                            <button class="btn btn-social btn-linkedin">\n' +
    '                              <div class="content">\n' +
    '\n' +
    '                              </div>\n' +
    '                            </button>\n' +
    '                          </li>\n' +
    '                        </ul>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row liquidLogo">\n' +
    '                <div class="col-xs-6 col-xs-offset-3">\n' +
    '                  <img ng-src="./images/le-logo.png" class="img-responsive" />\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div> -->\n' +
    '      <!-- new code -->\n' +
    '      <div class="col-xs-9 col-sm-6 col-md-5 col-md-offset-1">\n' +
    '        <div class="col-md-10 col-md-offset-1">\n' +
    '          <div class="splashBackground" ng-style="{ \'background-image\': \'url(\' + staticUrl + event.event.backgroundimage + \')\' }">\n' +
    '            <div class="content">\n' +
    '              <div class="row venueLogo">\n' +
    '                <div class="col-xs-8 col-xs-offset-2">\n' +
    '                  <img ng-src="{{staticUrl}}{{event.event.logoimage}}" class="img-responsive" />\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row login-mod well well-sm" ng-show="event.event.splashtypeid === 3">\n' +
    '                <div class="scaled">\n' +
    '                  <p class="railtext"> Connect to our FREE WIFI with </p>\n' +
    '                  <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>\n' +
    '                    <div class="form-group" ng-class="{ \'has-error\' : loginForm.email.$error && authentication.formSubmitted }">\n' +
    '                      <input type="email" name="email" class="form-control" placeholder="Email">\n' +
    '                    </div>\n' +
    '                    <div class="form-group railtext">\n' +
    '                      <input type="checkbox" ng-model="authentication.terms" required name="terms">\n' +
    '                      <a ng-click="">Terms and Conditions</a>\n' +
    '                    </div>\n' +
    '                    <div class="row text-center">\n' +
    '                      <div class="col-md-12 col-xs-12 col-sm-12">\n' +
    '                        <button type="submit" class="btn btn-danger">\n' +
    '                          CONNECT\n' +
    '                        </button>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </form>\n' +
    '                  <div class="leLogo leLogo-mod">\n' +
    '                    <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row video-mod" ng-show="event.event.splashtypeid === 1">\n' +
    '                <div class="scaled">\n' +
    '                  <div class="leLogo">\n' +
    '                    <img class="img-responsive" src="./images/youtube.jpg" alt="Youtube Video">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="footer-mod" ng-show="event.event.splashtypeid === 1">\n' +
    '                <div class="centered">\n' +
    '                  <strong class="railtext"> Connect to our FREE WIFI with </strong>\n' +
    '                  <div class="btn-deck">\n' +
    '                    <button type="submit" class="btn btn-danger btn-xs" ng-click="">\n' +
    '                      More Info\n' +
    '                    </button>\n' +
    '                    <button type="submit" class="btn btn-info btn-xs" ng-click="">\n' +
    '                      No, Thanks\n' +
    '                    </button>\n' +
    '                  </div>\n' +
    '                  <div class="leLogo">\n' +
    '                    <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="footer-mod" ng-show="event.event.splashtypeid === 4">\n' +
    '                <div class="centered">\n' +
    '                  <strong class="railtext"> Connect to our FREE WIFI with </strong>\n' +
    '                  </br>\n' +
    '                  <button type="submit" class="btn btn-danger btn-deck btn-xs" ng-click="">\n' +
    '                    CONNECT\n' +
    '                  </button>\n' +
    '                  <div class="leLogo">\n' +
    '                    <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="footer-mod" ng-show="event.event.splashtypeid === 2">\n' +
    '                <div class="socialCentred">\n' +
    '                  <div class="DocumentList">\n' +
    '                    <strong class="railtext"> Connect to our FREE WIFI with </strong>\n' +
    '                    <div class="row">\n' +
    '                      <!-- <div class="socIconsList" ng-repeat="social in event.event.socialLoginTypes" ng-show="event.socialLoginTypes.indexOf(4) > -1">\n' +
    '                        <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;">\n' +
    '                          <div class="content">\n' +
    '                          </div>\n' +
    '                        </button>\n' +
    '                      </div> -->\n' +
    '                      <div class="row splashLogin">\n' +
    '                        <div class="col-xs-10 col-xs-offset-1">\n' +
    '                          <div class="scaled">\n' +
    '                            <div class="content">\n' +
    '                              <div class="col-xs-12 socIcons">\n' +
    '                                <div class="socIconsList">\n' +
    '                                  <div ng-show="event.socialLoginTypes.indexOf(1) > -1">\n' +
    '                                    <button class="btn btn-social btn-facebook">\n' +
    '                                      <div class="content">\n' +
    '                                      </div>\n' +
    '                                    </button>\n' +
    '                                  </div>\n' +
    '                                  <div ng-show="event.socialLoginTypes.indexOf(2) > -1">\n' +
    '                                    <button class="btn btn-social btn-google">\n' +
    '                                      <div class="content">\n' +
    '                                      </div>\n' +
    '                                    </button>\n' +
    '                                  </div>\n' +
    '                                  <div ng-show="event.socialLoginTypes.indexOf(3) > -1">\n' +
    '                                    <button class="btn btn-social btn-twitter">\n' +
    '                                      <div class="content">\n' +
    '                                      </div>\n' +
    '                                    </button>\n' +
    '                                  </div>\n' +
    '                                  <div ng-show="event.socialLoginTypes.indexOf(4) > -1">\n' +
    '                                    <button class="btn btn-social btn-linkedin">\n' +
    '                                      <div class="content">\n' +
    '                                      </div>\n' +
    '                                    </button>\n' +
    '                                  </div>\n' +
    '                                </div>\n' +
    '                              </div>\n' +
    '                            </div>\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                    <div class="leLogo">\n' +
    '                      <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <!-- new code -->\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editvenuemap/views/editvenuemap.tpl.html',
    '<div class="editvenuemap">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <div class="row row-align">\n' +
    '        <h3>Edit Venue Map</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-12">\n' +
    '          <div class="col-md-6">\n' +
    '            <h4>Map Name</h4>\n' +
    '            <input type="text" class="form-control" ng-model="editvenuemap.mapName" placeholder="eg. Floor One, Two, Three, Etc ..." required>\n' +
    '            <br/>\n' +
    '            <br/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel panel-default" ng-show="editvenuemap.mapLoad">\n' +
    '        <div class="panel-heading">\n' +
    '          <div class="row row-align">\n' +
    '            <h3>Floor Map</h3>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel-body">\n' +
    '          <div class="row">\n' +
    '            <div class="col-sm-12">\n' +
    '              <div class="col-sm-2">\n' +
    '                <h3>Accesspoints</h3>\n' +
    '                <ul style="list-style-type: none; list-style-position:inside; margin:0; padding:0;" ng-repeat="ap in editvenuemap.accesspoints">\n' +
    '                  <li>\n' +
    '                    <div id="{{ap.name}}|{{ap.hwaddr}}" class="apItem" style="border: 1px solid #000;padding: 5px; margin: 5px;" drag-edit-aps>\n' +
    '                      <img  ng-hide="ap.ongraph" id="dragicon" class="pull-right" src="./images/apicon.png" alt="Smiley face" height="16" width="16">\n' +
    '                      <div>{{ap.name}}</div>\n' +
    '                      <div>{{ap.hwaddr}}</div>\n' +
    '                    </div>\n' +
    '                  </li>\n' +
    '                </ul>\n' +
    '              </div>\n' +
    '              <div class="col-sm-10">\n' +
    '                <div id="heatMapContainer" style="position: relative; height: {{ editvenuemap.mapHeight }}px;">\n' +
    '                  <canvas id="backDrop" style="position: absolute; left: 0; top: 0; z-index: 0;"></canvas>\n' +
    '                  <canvas id="pointMap" style="position: absolute; left: 0; top: 0; z-index: 1;" drop-edit-aps></canvas>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="col-sm-11">\n' +
    '          <button  ng-show="editvenuemap.mapLoad" type="submit" class="btn btn-danger pull-right" ng-click="editvenuemap.submit()">Submit</button>\n' +
    '        </div>\n' +
    '        <div class="col-sm-1">\n' +
    '          <button type="submit" class="btn btn-info pull-right" onclick="history.back()">Cancel</button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/ssidchecks.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/magnetnetworks/magnet_logo_small.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>{{modalService.message.messageHeader}}</h4>\n' +
    '  <p>{{modalService.message.messageBody}}</p>\n' +
    '  <table class="table table-responsive eventsTable">\n' +
    '    <thead>\n' +
    '      <tr>\n' +
    '        <th>Access Point</th>\n' +
    '        <th>Zone</th>\n' +
    '      </tr>\n' +
    '    </thead>\n' +
    '    <tbody>\n' +
    '      <tr ng-repeat="ap in modalService.message.accesspoints">\n' +
    '        <td>\n' +
    '          {{ap.ipaddress}}\n' +
    '        </td>\n' +
    '        <td>\n' +
    '          {{ap.zonename}}\n' +
    '        </td>\n' +
    '      </tr>\n' +
    '    </tbody>\n' +
    '  </table>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <div ng-hide="modalService.message.cancel.length > 0">\n' +
    '    <button class="btn btn-primary btn-block" type="button" ng-click="modalService.ok()">Done</button>\n' +
    '  </div>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/eventtimemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/magnetnetworks/magnet_logo_small.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Event duration is less than one hour.</h4>\n' +
    '  <h4>Would you like to confirm this duration or change it?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="cancelModal.ok()">Confirm</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="cancelModal.cancel()">Change</button>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/eventcreation.tpl.html',
    '<form name="createEvent" novalidate>\n' +
    '  <h2 class="text-center">Create Event</h2>\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <ul class="breadcrumb">\n' +
    '        <li ui-sref-active="active">\n' +
    '          <a ui-sref="admin.eventcreation.evcsetup" ng-show="eventcreation.setupPassed"><i class="fa fa-2x fa-sliders"></i></br>Setup</a>\n' +
    '          <a href="" ng-click="eventcreation.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, \'admin.eventcreation.evcsetup\')" ng-show="!eventcreation.setupPassed"><i class="fa fa-2x fa-sliders"></i></br>Setup</a>\n' +
    '        </li>\n' +
    '        <li ui-sref-active="active">\n' +
    '          <a ui-sref="admin.eventcreation.evcnetwork" ng-show="eventcreation.netPassed"><i class="fa fa-2x fa-cogs"></i></br>Network</a>\n' +
    '          <a ng-click="eventcreation.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, \'admin.eventcreation.evcnetwork\')" href="" ng-show="!eventcreation.netPassed"><i class="fa fa-2x fa-cogs"></i></br>Network</a>\n' +
    '        </li>\n' +
    '        <li ui-sref-active="active">\n' +
    '          <a ui-sref="admin.eventcreation.evcsplash" ng-hide="true"><i class="fa fa-2x fa-mobile"></i></br>Splash Page</a>\n' +
    '          <a ng-click="eventcreation.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, \'admin.eventcreation.evcsplash\')" href=""><i class="fa fa-2x fa-mobile"></i></br>Splash Page</a>\n' +
    '        </li>\n' +
    '        <li ui-sref-active="active" ng-if="false">\n' +
    '          <a ui-sref="admin.eventcreation.evcfeature">\n' +
    '          Feature Page\n' +
    '        </a>\n' +
    '        </li>\n' +
    '        <li ui-sref-active="active" role="presentation">\n' +
    '          <a ui-sref="admin.eventcreation.evcconfirm" ng-hide="true"><i class="fa fa-2x fa-check-circle-o"></i></br>Confirm</a>\n' +
    '          <a href="" ng-click="eventcreation.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, \'admin.eventcreation.evcconfirm\')"><i class="fa fa-2x fa-check-circle-o"></i></br>Confirm</a>\n' +
    '        </li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div data-ui-view autoscroll="true"></div>\n' +
    '    </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/evcsplash.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-9 col-sm-6 col-ms-6">\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Splash Page Type</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <select class="form-control" name="splashType" ng-model="eventcreation.splashType" ng-options="type as type.name for type in eventcreation.splashPageTypes" ng-change="eventcreation.setSplashPageType(eventcreation.splashType)">\n' +
    '        </select>\n' +
    '        <div class="panel-body">\n' +
    '          <input type="checkbox" name="marketingOn" ng-model="eventcreation.marketingOn">\n' +
    '          Advertising On\n' +
    '          </input>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div ng-show="eventcreation.splashType <= 0 && eventcreation.stepThree">\n' +
    '        <p class="text-danger">\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <strong> Splash Type is required</strong>\n' +
    '        </p>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Background Image</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group">\n' +
    '          <div class="row">\n' +
    '            <div ng-show="eventcreation.bckgrndImgAdded" class="col-md-4 col-md-offset-4">\n' +
    '              <img ng-src="{{staticUrl}}{{eventcreation.bckgrndImg}}" class="img-responsive" ng-if="eventcreation.bckgrndImg"/>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-12">\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.selectBckgrndImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.uploadBckgrndImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="!eventcreation.bckgrndImgAdded && eventcreation.stepThree">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Background Image is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Venue Logo</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group">\n' +
    '          <div class="row">\n' +
    '            <div ng-show="eventcreation.venueLogoAdded" class="col-md-4 col-md-offset-4">\n' +
    '              <img ng-src="{{staticUrl}}{{eventcreation.venueLogo}}" class="img-responsive" ng-if="eventcreation.venueLogo"/>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-12">\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.selectLogoImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.uploadLogoImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="!eventcreation.venueLogoAdded && eventcreation.stepThree">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Venue Logo is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel panel-default panel-form" ng-show="eventcreation.marketingOn">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Offer Image 1</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group">\n' +
    '          <div class="row">\n' +
    '            <div ng-show="eventcreation.offerImage1Added" class="col-md-4 col-md-offset-4">\n' +
    '              <img ng-src="{{staticUrl}}{{eventcreation.offerImage1Uri}}" class="img-responsive" ng-if="eventcreation.offerImage1Uri"/>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-12">\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.selectOfferImage1()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.uploadOfferImage1()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-12">\n' +
    '              Enter Ad URL: <input type="text" ng-model="eventcreation.offerImage1Link" style="width:100%">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="!eventcreation.bckgrndImgAdded && eventcreation.stepThree">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Offers Image is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel panel-default panel-form" ng-show="eventcreation.marketingOn">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Offer Image 2</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group">\n' +
    '          <div class="row">\n' +
    '            <div ng-show="eventcreation.offerImage1Added" class="col-md-4 col-md-offset-4">\n' +
    '              <img ng-src="{{staticUrl}}{{eventcreation.offerImage2Uri}}" class="img-responsive" ng-if="eventcreation.offerImage2Uri"/>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-12">\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.selectOfferImage2()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.uploadOfferImage2()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-12">\n' +
    '              Enter Ad URL: <input type="text" ng-model="eventcreation.offerImage2Link" style="width:100%">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="!eventcreation.bckgrndImgAdded && eventcreation.stepThree">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Offers Image is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel panel-default panel-form" ng-show="eventcreation.marketingOn">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Offer Image 3</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group">\n' +
    '          <div class="row">\n' +
    '            <div ng-show="eventcreation.offerImage3Added" class="col-md-4 col-md-offset-4">\n' +
    '              <img ng-src="{{staticUrl}}{{eventcreation.offerImage3Uri}}" class="img-responsive" ng-if="eventcreation.offerImage3Uri"/>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-12">\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.selectOfferImage3()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.uploadOfferImage3()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-12">\n' +
    '              Enter Ad URL: <input type="text" ng-model="eventcreation.offerImage3Link" style="width:100%">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="!eventcreation.bckgrndImgAdded && eventcreation.stepThree">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Offers Image is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel panel-default panel-form" ng-show="eventcreation.marketingOn">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Offer Image 4</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group">\n' +
    '          <div class="row">\n' +
    '            <div ng-show="eventcreation.offerImage1Added" class="col-md-4 col-md-offset-4">\n' +
    '              <img ng-src="{{staticUrl}}{{eventcreation.offerImage4Uri}}" class="img-responsive" ng-if="eventcreation.offerImage4Uri"/>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-12">\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.selectOfferImage4()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.uploadOfferImage4()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-12">\n' +
    '              Enter Ad URL: <input type="text" ng-model="eventcreation.offerImage4Link" style="width:100%">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="!eventcreation.bckgrndImgAdded && eventcreation.stepThree">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Offers Image is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel panel-default panel-form" ng-show="eventcreation.marketingOn">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Offer Image 5</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group">\n' +
    '          <div class="row">\n' +
    '            <div ng-show="eventcreation.offerImage5Added" class="col-md-4 col-md-offset-4">\n' +
    '              <img ng-src="{{staticUrl}}{{eventcreation.offerImage5Uri}}" class="img-responsive" ng-if="eventcreation.offerImage5Uri"/>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-12">\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.selectOfferImage5()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.uploadOfferImage5()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-12">\n' +
    '              Enter Ad URL: <input type="text" ng-model="eventcreation.offerImage5Link" style="width:100%">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="!eventcreation.bckgrndImgAdded && eventcreation.stepThree">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Offers Image is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel panel-default panel-form" ng-show="eventcreation.marketingOn">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Offer Image 6</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group">\n' +
    '          <div class="row">\n' +
    '            <div ng-show="eventcreation.offerImage6Added" class="col-md-4 col-md-offset-4">\n' +
    '              <img ng-src="{{staticUrl}}{{eventcreation.offerImage6Uri}}" class="img-responsive" ng-if="eventcreation.offerImage6Uri"/>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-12">\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.selectOfferImage6()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.uploadOfferImage6()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-12">\n' +
    '              Enter Ad URL: <input type="text" ng-model="eventcreation.offerImage6Link" style="width:100%">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="!eventcreation.bckgrndImgAdded && eventcreation.stepThree">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Offers Image is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div class="panel panel-default panel-form" ng-show="eventcreation.spSocial">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Social Media Login</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="row">\n' +
    '          <div class="col-xs-12">\n' +
    '            <div class="col-sm-3" ng-repeat="social in eventcreation.socialLogins">\n' +
    '              <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;" ng-click="eventcreation.addOpenAuth(social.id)" ng-disabled="social.name ===\'Twilio\' && !eventcreation.hasTwilio" title="{{social.name}}">\n' +
    '                <div class="content">\n' +
    '                  <span class="badge badgeSocial" ng-show="eventcreation.openAuthList.indexOf(social.id) > -1"><i class="fa fa-check-circle-o fa-2x"></i></span>\n' +
    '                </div>\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row" ng-show="eventcreation.anonymousLogin.name === \'Anonymous\'">\n' +
    '          <div class="col-xs-12">\n' +
    '            <h5><input type="checkbox" id="anonymousLogin" name="anonymousLogin" ng-model="eventcreation.anonymousLogin.on" /> Allow anonymous signin.</h5>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="eventcreation.openAuthList.length < 1 && eventcreation.stepThree">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Please select social log in</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="eventcreation.spVideo">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Video Url</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : createEvent.videoUrl.$error && eventcreation.stepThree}">\n' +
    '          <input type="text" class="form-control" id="videoUrl" ng-model="eventcreation.newEvent.splashDetails.videoUrl" name="videoUrl" placeholder="eg. http://mysite.com" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="createEvent.videoUrl.$error" ng-show="createEvent.videoUrl.$error && eventcreation.stepThree">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Video Url is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="eventcreation.spAmount">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Amount</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="input-group" ng-class="{ \'has-error\' : createEvent.amount.$error && eventcreation.stepThree}">\n' +
    '          <div class="input-group-addon">&euro;</div>\n' +
    '          <input type="number" min="0" step="1" class="form-control" id="amount" ng-model="eventcreation.newEvent.splashDetails.amount" name="amount" placeholder="0" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="createEvent.amount.$error" ng-show="createEvent.amount.$error && eventcreation.stepThree">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Amount is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="eventcreation.spAmount">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Duration (hrs)</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="input-group" ng-class="{ \'has-error\' : createEvent.period.$error && eventcreation.stepThree}">\n' +
    '          <input type="number" min="0" step="1" class="form-control" id="period" ng-model="eventcreation.newEvent.splashDetails.period" name="period" placeholder="0" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="createEvent.amount.$error" ng-show="createEvent.period.$error && eventcreation.stepThree">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Duration is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-hide="true">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Survey</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : eventcreation.stepThree}">\n' +
    '          Survey Enabled\n' +
    '          <input type="checkbox" id="surveyCheckbox" name="surveyCheckbox" ng-model="eventcreation.selectedSurvey" />\n' +
    '        </div>\n' +
    '        <div class="form-group" ng-show="eventcreation.selectedSurvey">\n' +
    '          <div ng-repeat="survey in eventcreation.surveyObjects">\n' +
    '            <div class="panel panel-default panel-form" ng-show="eventcreation.surveyObjects[survey.id].show">\n' +
    '              <div class="panel-heading clearfix">\n' +
    '                <h5>Question:</h5>\n' +
    '                <input type="text" class="form-control" ng-model="eventcreation.surveyObjects[survey.id].survey.question" placeholder="eg. Which event are you on?" required>\n' +
    '              </div>\n' +
    '              <div class="panel-body">\n' +
    '                <div class="form-group">\n' +
    '                  <input type="text" class="form-control" ng-model="eventcreation.surveyObjects[survey.id].survey.answer1" placeholder="Option A">\n' +
    '                  <input type="text" class="form-control" ng-model="eventcreation.surveyObjects[survey.id].survey.answer2" placeholder="Option B">\n' +
    '                  <input type="text" class="form-control" ng-model="eventcreation.surveyObjects[survey.id].survey.answer3" placeholder="Option C">\n' +
    '                  <input type="text" class="form-control" ng-model="eventcreation.surveyObjects[survey.id].survey.answer4" placeholder="Option D">\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div>\n' +
    '            <button ng-if="eventcreation.numberOfQuestions < eventcreation.numberOfQuestionsTotal" ng-click="eventcreation.addQuestion()" class="btn btn-danger"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '            <button ng-if="eventcreation.numberOfQuestions > 0" ng-click="eventcreation.removeQuestion()" class="btn btn-danger"><i class="fa fa-minus" aria-hidden="true"></i></button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="eventcreation.spSocial">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Social Media Settings</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : eventcreation.stepThree}">\n' +
    '          <h5><input type="checkbox" id="addlikebutton" name="addlikebutton" ng-model="eventcreation.addlikebutton" ng-change="eventcreation.setVenueDetails()"/> Facebook Like Button </h5>\n' +
    '          <div ng-show="eventcreation.addlikebutton">\n' +
    '            <div class="row" style="border-bottom: none; padding: 5px" ng-show="eventcreation.venue.customFbLikeUrl">\n' +
    '              <div class="col-xs-12">\n' +
    '                <div style="margin-top: 5px"><input type="checkbox" id="defaultFbLikeUrl" name="defaultFbLikeUrl" ng-model="eventcreation.defaultFbLikeUrl" ng-change="eventcreation.customFbLike = false;"/> Default - {{eventcreation.venue.customFbLikeUrl}}</div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row" style="border-bottom: none; padding: 5px">\n' +
    '              <div class="col-xs-4">\n' +
    '                <div style="margin-top: 5px"><input type="checkbox" id="customFbLike" name="customFbLike" ng-model="eventcreation.customFbLike" ng-change="eventcreation.defaultFbLikeUrl = false;"/> Custom.</div>\n' +
    '              </div>\n' +
    '              <div class="col-xs-8" ng-hide="eventcreation.defaultFbLikeUrl">\n' +
    '                <input type="url" class="form-control pull-right" id="customFbLikeUrl" ng-model="eventcreation.newEvent.splashDetails.customFbLikeUrl" name="customFbLikeUrl" placeholder="Custom Facebook Page Url" ng-change="eventcreation.defaultFbLikeUrl = false;">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row" style="border-bottom: none; padding: 5px">\n' +
    '              <div class="col-xs-offset-6 col-xs-6">\n' +
    '                <div ng-show="!createEvent.customFbLikeUrl.$valid && eventcreation.stepThree" class="pull-right">\n' +
    '                  <p class="text-danger">\n' +
    '                    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                    <strong> Custom Url must be a valid url. Example https://www.facebook.com/Liquidedge/</strong>\n' +
    '                  </p>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel panel-default panel-form" ng-show="eventcreation.conference">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Conference Settings</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : eventcreation.stepThree}">\n' +
    '          <div >\n' +
    '            <div class="row" style="border-bottom: none; padding: 5px">\n' +
    '              <div class="col-xs-8" >\n' +
    '                <input type="text" class="form-control pull-right" id="conferencepass" ng-model="eventcreation.newEvent.splashDetails.conferencepass" name="conferencepass" placeholder="Conference Passcode">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- <div class="panel panel-default panel-form" ng-show="eventcreation.isMagnet">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>County Dropdown</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <label>\n' +
    '          <input type="checkbox" ng-model="eventcreation.newEvent.splashDetails.countyDropDown" ng-true-value="true" ng-false-value="false"> Include County Dropdown\n' +
    '        </label>\n' +
    '      </div>\n' +
    '    </div> -->\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Redirection Url</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : createEvent.redirection.$error && eventcreation.stepThree}">\n' +
    '          <input type="text" class="form-control" id="redirectionURL" ng-model="eventcreation.newEvent.redirectionURL" name="redirection" placeholder="eg. http://mysite.com" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="createEvent.redirection.$error" ng-show="createEvent.redirection.$error && eventcreation.stepThree">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Redirection Url is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="col-xs-9 col-sm-6 col-md-5 col-md-offset-1">\n' +
    '    <h3 class="text-center">Splash Page</h3>\n' +
    '    <div class="col-md-10 col-md-offset-1">\n' +
    '      <div class="splashBackground" ng-style="{ \'background-image\': \'url(\' + staticUrl + eventcreation.bckgrndImg + \')\' }" ng-if="eventcreation.bckgrndImg">\n' +
    '        <div class="content">\n' +
    '          <div class="row venueLogo">\n' +
    '            <div class="col-xs-8 col-xs-offset-2" ng-show="eventcreation.venueLogoAdded">\n' +
    '              <img ng-src="{{staticUrl}}{{eventcreation.venueLogo}}" class="img-responsive" ng-if="eventcreation.venueLogo"/>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row login-mod well well-sm" ng-if="eventcreation.bckgrndImgAdded && eventcreation.spEmail">\n' +
    '            <div class="scaled">\n' +
    '              <p class="railtext"> Connect to our FREE WIFI with </p>\n' +
    '              <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>\n' +
    '                <div class="form-group" ng-class="{ \'has-error\' : loginForm.email.$error && authentication.formSubmitted }">\n' +
    '                  <input type="email" name="email" class="form-control" placeholder="Email">\n' +
    '                </div>\n' +
    '                <div class="form-group railtext">\n' +
    '                  <input type="checkbox" ng-model="authentication.terms" required name="terms">\n' +
    '                  <a ng-click="">Terms and Conditions</a>\n' +
    '                </div>\n' +
    '                <div class="row text-center">\n' +
    '                  <div class="col-md-12 col-xs-12 col-sm-12">\n' +
    '                    <button type="submit" class="btn btn-danger">\n' +
    '                      CONNECT\n' +
    '                    </button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </form>\n' +
    '              <div class="leLogo">\n' +
    '                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row login-mod well well-sm" ng-show="eventcreation.bckgrndImgAdded && eventcreation.spRegister">\n' +
    '            <div class="scaled">\n' +
    '              <p class="railtext"> Connect to our FREE WIFI with </p>\n' +
    '              <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>\n' +
    '                <div class="form-group" ng-class="{ \'has-error\' : loginForm.email.$error && authentication.formSubmitted }">\n' +
    '                  <input type="email" name="email" class="form-control" placeholder="Email">\n' +
    '                </div>\n' +
    '                <div class="form-group" ng-class="{ \'has-error\' : loginForm.password.$error && authentication.formSubmitted }">\n' +
    '                  <input type="password" name="password" class="form-control" placeholder="password">\n' +
    '                </div>\n' +
    '                <div class="form-group railtext">\n' +
    '                  <input type="checkbox" ng-model="authentication.terms" required name="terms">\n' +
    '                  <a ng-click="">Terms and Conditions</a>\n' +
    '                </div>\n' +
    '                <div class="row text-center">\n' +
    '                  <div class="col-md-12 col-xs-12 col-sm-12">\n' +
    '                    <button type="submit" class="btn btn-danger">\n' +
    '                      CONNECT\n' +
    '                    </button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </form>\n' +
    '              <div class="leLogo">\n' +
    '                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row video-mod" ng-show="eventcreation.bckgrndImgAdded && eventcreation.spVideo">\n' +
    '            <div class="scaled">\n' +
    '              <div class="leLogo">\n' +
    '                <img class="img-responsive" src="./images/youtube.jpg" alt="Youtube Video">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="footer-mod" ng-show="eventcreation.bckgrndImgAdded && eventcreation.spVideo">\n' +
    '            <div class="centered">\n' +
    '              <strong class="railtext"> Connect to our FREE WIFI with </strong>\n' +
    '              <div class="btn-deck">\n' +
    '                <button type="submit" class="btn btn-danger btn-xs" ng-click="">\n' +
    '                  More Info\n' +
    '                </button>\n' +
    '                <button type="submit" class="btn btn-info btn-xs" ng-click="">\n' +
    '                  No, Thanks\n' +
    '                </button>\n' +
    '              </div>\n' +
    '              <div class="leLogo">\n' +
    '                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="footer-mod" ng-show="eventcreation.bckgrndImgAdded && eventcreation.spLink">\n' +
    '            <div class="centered">\n' +
    '              <strong class="railtext"> Connect to our FREE WIFI with </strong>\n' +
    '              </br>\n' +
    '              <button type="submit" class="btn btn-danger btn-deck btn-xs" ng-click="">\n' +
    '                CONNECT\n' +
    '              </button>\n' +
    '              <div class="leLogo">\n' +
    '                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="footer-mod" ng-show="eventcreation.bckgrndImgAdded && eventcreation.spSocial">\n' +
    '            <div class="socialCentred">\n' +
    '              <div class="DocumentList">\n' +
    '                <strong class="railtext"> Connect to our FREE WIFI with </strong>\n' +
    '                <div class="row">\n' +
    '                  <div class="socIconsList" ng-repeat="social in eventcreation.socialLogins" ng-if="eventcreation.openAuthList.indexOf(social.id) > -1">\n' +
    '                    <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;">\n' +
    '                      <div class="content">\n' +
    '                      </div>\n' +
    '                    </button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <div class="row" ng-show="eventcreation.anonymousLogin.on">\n' +
    '                  <a>Continue Without Login</a> <br />\n' +
    '                  <br />\n' +
    '                </div>\n' +
    '                <div class="" style="margin-left: auto;margin-right: auto;  display: block;width: 10vw;height: auto;">\n' +
    '                  <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '<div class="panel-footer">\n' +
    '  <div class="pull-right">\n' +
    '    <button class="btn btn-default" ng-click="eventcreation.cancelCreate()">Cancel</button>\n' +
    '    <button class="btn btn-default btn-danger" ng-click="eventcreation.nextStep({splash : createEvent.redirection.$error }, \'admin.eventcreation.evcconfirm\')">Next Step</button>\n' +
    '  </div>\n' +
    '  <div class="clearfix"></div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/evcsetup.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-11 col-sm-7 col-ms-7">\n' +
    '    <div class="panel panel-default panel-form" ng-hide="activeUser.role === 2">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Venue</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <select class="form-control" ng-model="eventcreation.newEvent.venueId" ng-options="venue.id as venue.venuetitle for venue in eventcreation.venues" ng-change="eventcreation.getZones(eventcreation.newEvent.venueId)">\n' +
    '        </select>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Name &amp; Description</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : createEvent.eventname.$error && eventcreation.stepOne}">\n' +
    '          <label for="eventname" class="control-label pull-left">EVENT NAME</label>\n' +
    '          <input type="text" class="form-control" id="eventname" name="eventname" ng-model="eventcreation.newEvent.name" placeholder="eg. Big Match Day" required>\n' +
    '        </div>\n' +
    '        <div class="form-group">\n' +
    '          <label for="eventdescription" class="control-label pull-left">DESCRIPTION</label>\n' +
    '          <textarea rows="5" class="form-control" id="eventdescription" name="eventdescription" ng-model="eventcreation.newEvent.description" placeholder="brief description of this event"></textarea>\n' +
    '        </div>\n' +
    '        <div ng-messages="createEvent.eventname.$error" ng-show="createEvent.eventname.$error && eventcreation.stepOne">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> EVENT NAME is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '        <div ng-show="eventcreation.thesamename && eventcreation.stepOne">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Name is not unique </strong>\n' +
    '            <button class="btn btn-default btn-primary pull-right" ng-click="eventcreation.nameCheckResult()">\n' +
    '              <i class="fa fa-question-circle-o"></i>\n' +
    '              More Info</button>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Duration</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-row">\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="startdate" class="control-label pull-left">START DATE</label>\n' +
    '            <input type="text" class="form-control" min-date="eventcreation.minDate" name="startDate" datepicker-popup="{{eventcreation.dateFormat}}" ng-model="eventcreation.dtStart" is-open="eventcreation.status.openedSt" datepicker-options="eventcreation.dateOptions" ng-required="true" close-text="Close" />\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <button type="button" class="btn btn-date" ng-click="eventcreation.openCalSt()">\n' +
    '              <span class="glyphicon glyphicon-calendar"></span>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '          <fieldset ng-disabled="eventcreation.noend">\n' +
    '            <div class="form-group text-box long cell cell-pad">\n' +
    '              <label for="enddate" class="control-label pull-left">END DATE</label>\n' +
    '              <input type="text" class="form-control" min-date="eventcreation.minDate" datepicker-popup="{{eventcreation.dateFormat}}" ng-model="eventcreation.dtEnd" is-open="eventcreation.status.openedEnd" datepicker-options="eventcreation.dateOptions" ng-required="true" close-text="Close" />\n' +
    '            </div>\n' +
    '          </fieldset>\n' +
    '          <div class="short cell">\n' +
    '            <button type="button" class="btn btn-date" ng-click="eventcreation.openCalEnd()" ng-disabled="eventcreation.noend">\n' +
    '              <span class="glyphicon glyphicon-calendar"></span>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="form-row">\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="starttime" class="control-label pull-left timelable">START TIME</label>\n' +
    '            <timepicker ng-model="eventcreation.startTime" hour-step="eventcreation.hstep" minute-step="eventcreation.mstep" show-meridian="ismeridian" show-spinners="true"></timepicker>\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '\n' +
    '          </div>\n' +
    '          <fieldset ng-disabled="eventcreation.noend">\n' +
    '            <div class="form-group text-box long cell cell-pad">\n' +
    '              <label for="endtime" class="control-label pull-left timelable">END TIME</label>\n' +
    '              <timepicker ng-model="eventcreation.endTime" hour-step="eventcreation.hstep" minute-step="eventcreation.mstep" show-meridian="ismeridian" show-spinners="true"></timepicker>\n' +
    '            </div>\n' +
    '          </fieldset>\n' +
    '          <div class="short cell" style="vertical-align: top; padding-top: 5px">\n' +
    '            <p><strong>No End Date</strong></p>\n' +
    '            <label class="noend">\n' +
    '              <input type="checkbox" ng-model="eventcreation.noend">\n' +
    '              <span class="checkmark"></span>\n' +
    '            </label>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div>\n' +
    '          <p class="text-danger" ng-show="eventcreation.dateError">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>Start Date/End Date Are Required Fields</strong>\n' +
    '          </p>\n' +
    '          <p class="text-danger" ng-show="eventcreation.stTimeInPast">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>Start Date/Time cannot be in the past</strong>\n' +
    '          </p>\n' +
    '          <p class="text-danger" ng-show="eventcreation.endTimeInPast">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>End Date/Time cannot be in the past</strong>\n' +
    '          </p>\n' +
    '          <p class="text-danger" ng-show="eventcreation.endBeforeStart">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>End Date/Time cannot be prior to Start Date/Time</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>EVENT IMAGE</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group">\n' +
    '          <div class="row">\n' +
    '            <div ng-show="eventcreation.eventImageAdded" class="col-md-4 col-md-offset-4">\n' +
    '              <img ng-src="{{staticUrl}}{{eventcreation.eventImage}}" class="img-responsive" ng-if="eventcreation.eventImage"/>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-12">\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.selectEventImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '              <button class="btn btn-danger" ng-click="eventcreation.uploadEventImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="!eventcreation.eventImageAdded && eventcreation.stepOne">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Event Image is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-8 col-sm-5 col-ms-5">\n' +
    '    <h3>Make your WiFi work for you!</h3>\n' +
    '    <p>Set up fast, secure WiFi the way you want it. Limit access time, allow or deny access to any site you want and set user bandwidth to prevent users hogging the network</p>\n' +
    '    <!-- <a href="#">Need Help?</a> -->\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '<div class="panel-footer">\n' +
    '  <div class="pull-right">\n' +
    '    <button class="btn btn-default" ng-click="eventcreation.cancelCreate()">Cancel</button>\n' +
    '    <button class="btn btn-default btn-danger" ng-click="eventcreation.nextStep({setUp : createEvent.eventname.$error}, \'admin.eventcreation.evcnetwork\')">Next Step</button>\n' +
    '  </div>\n' +
    '  <div class="clearfix"></div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/evcnetwork.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-11 col-sm-7 col-ms-7">\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Network Name</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : createEvent.networkName.$error && eventcreation.stepTwo}">\n' +
    '          <input type="text" class="form-control" id="networkname" name="networkName" ng-model="eventcreation.newEvent.networkName" ng-pattern="/^[a-zA-Z0-9]*$/" placeholder="eg. nowhitespace" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="createEvent.networkName.$error" ng-show="createEvent.networkName.$error && eventcreation.stepTwo">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Network Name is required</strong>\n' +
    '          </p>\n' +
    '          <p class="text-danger" ng-message="pattern">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Network Name must not contain spaces</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '        <div ng-show="eventcreation.ssidExists && eventcreation.stepTwo">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>Network Name clash during selected time period</strong>\n' +
    '            <button class="btn btn-default btn-primary pull-right" ng-click="eventcreation.nameCheckResult()">\n' +
    '              <i class="fa fa-question-circle-o"></i>\n' +
    '              More Info</button>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Network Type</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <select class="form-control" ng-model="eventcreation.newEvent.networkType" ng-change="eventcreation.setNetworkType(eventcreation.newEvent.networkType)">\n' +
    '          <option value="">Choose Network Type</option>\n' +
    '          <option ng-repeat="type in eventcreation.networkTypes" value="{{type.id}}">{{type.name}} - VLAN: {{type.vlanid}}</option>\n' +
    '        </select>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form"  ng-show="eventcreation.provider === 1">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Network Optimization</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <ul class="">\n' +
    '          <li class="checkbox" ng-repeat="category in eventcreation.optCatList">\n' +
    '            <label>\n' +
    '              <input type="checkbox" checklist-model="eventcreation.newEvent.optCatList" checklist-value="category.id">\n' +
    '              {{category.name}}\n' +
    '              <a href="" tooltip-placement="right" tooltip="{{category.description}}">\n' +
    '                <i class="fa fa-question-circle"></i>\n' +
    '              </a>\n' +
    '            </label>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="eventcreation.provider === 1">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Set Bandwidth Limit</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div>\n' +
    '          <rzslider rz-slider-model="eventcreation.priceSlider.value" rz-slider-options="eventcreation.priceSlider.options"></rzslider>\n' +
    '          <p ng-show="eventcreation.priceSlider.value === 0">Setting Zero gives the user unlimited bandwidth. </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Network Zones</h4>\n' +
    '        <div class="input-group">\n' +
    '          <div class="input-group-addon">\n' +
    '            <i class="fa fa-search"></i>\n' +
    '          </div>\n' +
    '          <input type="text" class="form-control" placeholder="Search Zones" ng-model="zoneSearch">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <ul class="">\n' +
    '          <!-- <li class="checkbox" ng-repeat="zone in eventcreation.zoneList"> !-->\n' +
    '          <li class="checkbox" dir-paginate="zone in eventcreation.zoneList | orderBy: \'name\'| filter:zoneSearch | itemsPerPage: 10">\n' +
    '            <label>\n' +
    '              <input type="checkbox" checklist-model="eventcreation.newEvent.zoneList" checklist-value="zone.id">\n' +
    '              {{zone.name}}\n' +
    '            </label>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '        <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>\n' +
    '        <div ng-show="eventcreation.newEvent.zoneList.length < 1 && eventcreation.stepTwo">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Please select at least one zone</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-show="eventcreation.ssidCount && eventcreation.stepTwo">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>Network count exceeded</strong>\n' +
    '            <button class="btn btn-default btn-primary pull-right" ng-click="eventcreation.ssidCountResult()">\n' +
    '              <i class="fa fa-question-circle-o"></i>\n' +
    '              More Info</button>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-8 col-sm-5 col-ms-5">\n' +
    '    <h3>Managing Application Settings</h3>\n' +
    '    <p>Optimize your WiFi network to suit your event</p>\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '<div class="panel-footer">\n' +
    '<div class="pull-right">\n' +
    '  <button class="btn btn-default" ng-click="eventcreation.cancelCreate()">Cancel</button>\n' +
    '  <button class="btn btn-default btn-danger" ng-click="eventcreation.nextStep({network : createEvent.networkName.$error}, \'admin.eventcreation.evcsplash\')">Next Step</button>\n' +
    '</div>\n' +
    '<div class="clearfix"></div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/evcfeature.tpl.html',
    '<div class="panel-heading">\n' +
    '  <ul class="breadcrumb" role="tablist">\n' +
    '    <li role="presentation" class="active">\n' +
    '      <a href="#">\n' +
    '        Setup\n' +
    '      </a>\n' +
    '    </li>\n' +
    '    <li role="presentation" class="active">\n' +
    '      <a href="#">\n' +
    '        Network\n' +
    '      </a>\n' +
    '    </li>\n' +
    '    <li role="presentation" class="active">\n' +
    '      <a href="#">\n' +
    '        Splash Page\n' +
    '      </a>\n' +
    '    </li>\n' +
    '    <li role="presentation" class="active">\n' +
    '      <a href="#">\n' +
    '        Feature Page\n' +
    '      </a>\n' +
    '    </li>\n' +
    '  </ul>\n' +
    '</div>\n' +
    '<div class="panel-body">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-7 col-sm-5 col-ms-5 pillar">\n' +
    '      <tabset class="tab-content">\n' +
    '        <tab ng-repeat="tab in eventcreation.tabs" class="tab-pane" heading="{{tab.title}}">\n' +
    '          <div ng-include="tab.content"></div>\n' +
    '        </tab>\n' +
    '      </tabset>\n' +
    '    </div>\n' +
    '    <div class="col-xs-11 col-sm-7 col-ms-7">\n' +
    '      <div ng-repeat="row in eventcreation.featurePage track by $index">\n' +
    '        <div ng-include="row.template"> </div>\n' +
    '      </div>\n' +
    '      <div class="col-xs-12" data-drop="true" ng-model="eventcreation.featurePage" data-jqyoui-options="" jqyoui-droppable="{multiple:true,onDrop: \'eventcreation.dropped()\'}" style="min-height: 300px;">\n' +
    '        <img class= "tool-image-add" src="images/plus.png" alt="Image404" width="42" height=-"42">\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row row-align button-end">\n' +
    '    <div class="pull-left">\n' +
    '    </div>\n' +
    '    <div class="pull-right">\n' +
    '      <button class="btn btn-default" ng-click="eventcreation.cancelCreate()">Cancel</button>\n' +
    '      <button class="btn btn-danger" data-ui-sref="admin.eventcreation.evcconfirm">Next Step</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/evcconfirm.tpl.html',
    '<div class="row">\n' +
    '  <div ng-show="eventcreation.setupPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confSuccess">\n' +
    '        <i class="fa fa-4x fa-check-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Setup</h4>\n' +
    '      <ul class="confUl">\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Event Name,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Start/End Date,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Event Image,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Access Points</li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.eventcreation.evcsetup">Edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div ng-hide="eventcreation.setupPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confFail">\n' +
    '        <i class="fa fa-4x fa-times-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Setup</h4>\n' +
    '      <p>Setup Incomplete</p>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.eventcreation.evcsetup">Edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="row">\n' +
    '  <div ng-show="eventcreation.netPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confSuccess">\n' +
    '        <i class="fa fa-4x fa-check-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Network</h4>\n' +
    '      <ul class="confUl">\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Network Name,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Network Optomization,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Network Bandwidth</li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.eventcreation.evcnetwork">Edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div ng-hide="eventcreation.netPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confFail">\n' +
    '        <i class="fa fa-4x fa-times-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Network</h4>\n' +
    '      <p>Network Incomplete</p>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.eventcreation.evcnetwork">Edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="row">\n' +
    '  <div ng-show="eventcreation.splashPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confSuccess">\n' +
    '        <i class="fa fa-4x fa-check-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Splash Page</h4>\n' +
    '      <ul class="confUl">\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Background Image,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Venue Logo,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Social Media,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Redirection Url</li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.eventcreation.evcsplash">Edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div ng-hide="eventcreation.splashPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confFail">\n' +
    '        <i class="fa fa-4x fa-times-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Splash Page</h4>\n' +
    '      <p>Splash Page Incomplete</p>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.eventcreation.evcsplash">Edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '<div class="panel-footer">\n' +
    '  <div class="pull-right">\n' +
    '    <button class="btn btn-default" ng-click="eventcreation.cancelCreate()">Cancel</button>\n' +
    '    <button class="btn btn-default btn-danger" ng-click="eventcreation.createEvent()" ng-disabled="!eventcreation.splashPassed || !eventcreation.netPassed ||  !eventcreation.setupPassed">Create Event</button>\n' +
    '  </div>\n' +
    '  <div class="clearfix"></div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('eventcreation/views/cancelmodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/magnetnetworks/magnet_logo_small.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Cancel creating this network?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="cancelModal.ok()">Confirm</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="cancelModal.cancel()">Don\'t Cancel</button>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editzone/views/editzone.tpl.html',
    '<div id="editzone">\n' +
    '  <h2 class="text-center">Edit Zone</h2>\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createzone" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>ZONE NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="zonename" name="zonename" ng-model="editzone.zone.name" placeholder="eg. Some zone" required>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>DESCRIPTION</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="details" name="details" ng-model="editzone.zone.details.description" placeholder="eg. zone covers conference centre main suite" required>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="editzone.provider == 2">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>WLAN GROUP</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="details" name="details" ng-model="editzone.zone.details.wlangroup" placeholder="eg. zone covers conference centre main suite">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-if="editzone.provider == 6">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>ZONE UUID</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="details" name="details" ng-model="editzone.zone.details.siteid" placeholder="eg. zone Id from ruckus controller">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>ACCESSPOINTS</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <ul id="double">\n' +
    '              <li class="iplisting" dir-paginate="ap in editzone.accesspoints | orderBy: \'name\'| filter:apSearch | itemsPerPage: 2500">\n' +
    '                <label>\n' +
    '                  <input type="checkbox" checklist-model="editzone.selectedaccesspoints" checklist-value="ap"> {{ap.name}}\n' +
    '                </label>\n' +
    '              </li>\n' +
    '            </ul>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="pull-right">\n' +
    '          <button class="btn btn-default" ng-click="editzone.cancel()">Cancel</button>\n' +
    '          <button ng-click="editzone.updateZone()" class="btn btn-default btn-danger">Submit</button>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editsurvey/views/editsurvey.tpl.html',
    '<div id="editsurvey">\n' +
    '  <h2>Edit Survey</h2>\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <!-- <div class="panel-heading">\n' +
    '    </div> -->\n' +
    '    <div class="panel-body">\n' +
    '        <div ng-show="!editsurvey.surveyLoaded">\n' +
    '          <p> No Survey found for this event. </p>\n' +
    '          <button ng-click="editsurvey.addSurvey()" class="btn btn-danger" tooltip-placement="right" tooltip="Add Survey"><i class="fa fa-plus" aria-hidden="true">Add Survey</i></button>\n' +
    '        </div>\n' +
    '        <div class="form-group">\n' +
    '          <div ng-show="editsurvey.surveyLoaded">\n' +
    '          Survey Enabled <input type="checkbox" id="surveyCheckbox" name="surveyCheckbox" ng-model="editsurvey.isActive"/>\n' +
    '          <div ng-repeat="survey in editsurvey.surveyObjects">\n' +
    '            <div class="panel panel-default panel-form" ng-show="editsurvey.surveyObjects[survey.id].show">\n' +
    '              <div class="panel-heading clearfix">\n' +
    '                <h5>Question:</h5>\n' +
    '                <input type="text" class="form-control" ng-model="editsurvey.surveyObjects[survey.id].survey.question" placeholder="eg. Which event are you on?" required>\n' +
    '              </div>\n' +
    '              <div class="panel-body">\n' +
    '                <div class="form-group">\n' +
    '                  <input type="text" class="form-control" ng-model="editsurvey.answers[survey.id].answers[0].answer" placeholder="Option A">\n' +
    '                  <input type="text" class="form-control" ng-model="editsurvey.answers[survey.id].answers[1].answer" placeholder="Option B">\n' +
    '                  <input type="text" class="form-control" ng-model="editsurvey.answers[survey.id].answers[2].answer" placeholder="Option C">\n' +
    '                  <input type="text" class="form-control" ng-model="editsurvey.answers[survey.id].answers[3].answer" placeholder="Option D">\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div>\n' +
    '            <button ng-if="editsurvey.numberOfQuestions < editsurvey.numberOfQuestionsTotal" ng-click="editsurvey.addQuestion()" class="btn btn-danger" tooltip-placement="right" tooltip="Add Question"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '            <button ng-if="editsurvey.numberOfQuestions > 0" ng-click="editsurvey.removeQuestion()" class="btn btn-danger" tooltip-placement="right" tooltip="Remove Question"><i class="fa fa-minus" aria-hidden="true"></i></button>\n' +
    '            <button ng-click="editsurvey.updateSurvey()" class="btn btn-danger" tooltip-placement="right" tooltip="Update Survey"><span class="fa fa-pencil-square-o" aria-hidden="true"></span></button>\n' +
    '            <button class="btn btn-danger btn-md" ng-click="editsurvey.deleteSurvey()" tooltip-placement="left" tooltip="Delete Survey">\n' +
    '              <span class="glyphicon glyphicon-trash" aria-hidden="true"></span></button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editsurvey/views/delsurveymodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this survey?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="delsurveymodal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="delsurveymodal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editvenue/views/editvenue.tpl.html',
    '<div id="newVenue">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Edit\n' +
    '        {{editvenue.venue.venueTitle}}\n' +
    '        Venue</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createVenue" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>VENUE NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.venuename.$invalid && editvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="venuename" name="venuename" ng-model="editvenue.venue.venueTitle" placeholder="eg. Some Venue" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.venuename.$error" ng-if="editvenue.submitted">\n' +
    '              <p ng-message="required">venue name is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>VENUE IMAGE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-4 col-md-offset-4">\n' +
    '                  <img ng-src="{{staticUrl}}{{editvenue.venue.imageUrl}}" onerror="this.src=\'./images/le-sq.png\'" class="img-responsive"/>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  <!-- <button class="btn btn-danger" ng-click="editvenue.selectImage()">\n' +
    '                    <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '                  </button> -->\n' +
    '                  <button class="btn btn-danger" ng-click="editvenue.uploadImage()">\n' +
    '                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-show="!editvenue.venue.imageUrl && editvenue.submitted">\n' +
    '              <p>Image is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>EMAIL</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.contactEmail.$invalid && editvenue.submitted }">\n' +
    '              <input type="email" class="form-control" id="contactEmail" name="contactEmail" ng-model="editvenue.venue.contactEmail" placeholder="eg. name@email.com" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.contactEmail.$error" ng-if="editvenue.submitted">\n' +
    '              <p ng-message="required">email is required.</p>\n' +
    '              <p ng-message="email">This needs to be a valid email</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>PHONE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.contactPhone.$invalid && editvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="contactPhone" name="contactPhone" ng-model="editvenue.venue.contactPhone" placeholder="eg. 081 555 556" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.contactPhone.$error" ng-if="editvenue.submitted">\n' +
    '              <p ng-message="required">phone is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>DESCRIPTION</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.description.$invalid && editvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="description" name="description" ng-model="editvenue.venue.description" placeholder="eg. description ..." required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.description.$error" ng-if="editvenue.submitted">\n' +
    '              <p ng-message="required">description is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>LOCATION</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.location.$invalid && editvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="location" name="location" ng-model="editvenue.venue.location" placeholder="eg. Address, County" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.location.$error" ng-if="editvenue.submitted">\n' +
    '              <p ng-message="required">location is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>WEBSITE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createVenue.website.$invalid && editvenue.submitted }">\n' +
    '              <input type="text" class="form-control" id="website" name="website" ng-model="editvenue.venue.website" placeholder="eg. someurl.com" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="createVenue.website.$error" ng-if="editvenue.submitted">\n' +
    '              <p ng-message="required">website is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Social Media Accounts</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="row form-group" ng-repeat="socialmedia in editvenue.socialMediaAccounts" style="border-bottom: none; padding: 5px">\n' +
    '              <div class="col-xs-4 col-sm-4 col-md-3">\n' +
    '                <div style="margin-top: 10px;">{{socialmedia.title}}</div>\n' +
    '              </div>\n' +
    '              <div class="col-xs-8 col-sm-8 col-md-9">\n' +
    '                <input type="url" name="{{socialmedia.title}}" class="form-control pull-right" ng-model="editvenue.socialMediaAccounts[$index].value" placeholder="{{socialmedia.example}}">\n' +
    '              </div>\n' +
    '              <div class="ccol-xs-12">\n' +
    '                <div ng-show="!createVenue[socialmedia.title].$valid" class="pull-right">\n' +
    '                  <p class="text-danger">\n' +
    '                    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                    <strong> Custom Url must be a valid url. Example https://www.facebook.com/Liquidedge/</strong>\n' +
    '                  </p>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="editvenue.updateVenue(!createVenue.$invalid)" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editnetworktype/views/editnetworktype.tpl.html',
    '<div id="editnetworktype">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Edit Network Type</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="editnetworkType" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="name" name="username" ng-model="editnetworktype.networkType.name" placeholder="eg. Guest" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="editnetworkType.username.$error" ng-if="editnetworktype.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Name is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-if="editnetworktype.provider == 2">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>USER GROUP</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="name" name="name" ng-model="editnetworktype.networkType.details.usergroup" placeholder="eg. Guest" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="editnetworkType.name.$error" ng-if="editnetworktype.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Name is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-if="editnetworktype.provider == 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>VLAN</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="number" class="form-control" id="vlan" name="vlan" ng-model="editnetworktype.networkType.vlanid" placeholder="eg. 700" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="editnetworkType.vlan.$error" ng-if="editnetworktype.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Vlan is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-if="editnetworktype.provider == 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>NETWORK OPTIMISATION STRATEGIES</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-heading">\n' +
    '            <div class="row">\n' +
    '              <div class="col-md-8 col-md-offset-2">\n' +
    '                <div class="input-group">\n' +
    '                  <div class="input-group-addon">\n' +
    '                    <i class="fa fa-search"></i>\n' +
    '                  </div>\n' +
    '                  <input type="text" class="form-control" placeholder="Search Strategies" ng-model="stSearch">\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <ul class="">\n' +
    '                <li class="checkbox" ng-repeat="strategy in editnetworktype.ntstrategies">\n' +
    '                  <label>\n' +
    '                    <input type="checkbox" data-checklist-model="editnetworktype.selectedStrategies" checklist-value="strategy.id"> {{strategy.name}} - {{strategy.description}}\n' +
    '                  </label>\n' +
    '                </li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="pull-right">\n' +
    '          <button class="btn btn-default" ng-click="editnetworktype.cancel()">Cancel</button>\n' +
    '          <button ng-click="editnetworktype.updateNetworkType(!editnetworkType.$invalid)" class="btn btn-default btn-danger">Submit</button>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editinfrastructure/views/editinfrastructure.tpl.html',
    '<div id="infra">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Edit Infrastructure</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createinfrastructure" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Provider</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <select class="form-control" name="provider" ng-options="option.id as option.name for option in editinfrastructure.providerTypes" ng-model="editinfrastructure.infrastructure.provider" ng-change="editinfrastructure.setProvider(editinfrastructure.infrastructure.provider)"></select>\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.provider.$error" ng-if="editinfrastructure.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Provider is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-hide="editinfrastructure.infrastructure.provider === 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Controller Address</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="controlleraddress" name="controlleraddress" ng-model="editinfrastructure.infrastructure.controlleraddress" placeholder="eg. controller.example.com" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.controlleraddress.$error" ng-if="editinfrastructure.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Controller Address is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-hide="editinfrastructure.infrastructure.provider === 1 || editinfrastructure.infrastructure.provider === 4">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4 ng-if="editinfrastructure.infrastructure.provider === 3">Authentication</h4>\n' +
    '            <h4 ng-if="editinfrastructure.infrastructure.provider === 2">Site ID</h4>\n' +
    '            <h4 ng-if="editinfrastructure.infrastructure.provider === 5">Api Key</h4>\n' +
    '            <h4 ng-if="editinfrastructure.infrastructure.provider === 6">Northbound Portal Interface Password</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="authentication" name="authentication" ng-model="editinfrastructure.infrastructure.authentication" placeholder="">\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.authentication.$error" ng-if="editinfrastructure.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Authentication is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-hide="editinfrastructure.infrastructure.provider === 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Username</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="username" name="username" ng-model="editinfrastructure.infrastructure.username" placeholder="">\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.username.$error" ng-if="editinfrastructure.submitted">\n' +
    '              <p ng-message="required" class="text-danger">username is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-hide="editinfrastructure.infrastructure.provider === 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Password</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="password" class="form-control" id="password" name="password" ng-model="editinfrastructure.infrastructure.password" placeholder="">\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.password.$error" ng-if="editinfrastructure.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Controller Address is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>SSID Limit</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="number" class="form-control" id="ssidlimit" name="ssidlimit" ng-model="editinfrastructure.infrastructure.ssidlimit" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.ssidlimit.$error" ng-if="editinfrastructure.submitted">\n' +
    '              <p ng-message="required" class="text-danger">SSID Limit is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-hide="editinfrastructure.infrastructure.provider === 1 || editinfrastructure.infrastructure.provider === 2 || editinfrastructure.infrastructure.provider === 4 || editinfrastructure.infrastructure.provider === 5 || editinfrastructure.infrastructure.provider === 6">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Community</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="community" name="community" ng-model="editinfrastructure.infrastructure.details.communityId" placeholder="">\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.community.$error" ng-if="editinfrastructure.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Community is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-hide="editinfrastructure.infrastructure.provider === 1 || editinfrastructure.infrastructure.provider === 2 || editinfrastructure.infrastructure.provider === 4 || editinfrastructure.infrastructure.provider === 3 || editinfrastructure.infrastructure.provider === 6">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Location API Validator</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="validator" name="validator" ng-model="editinfrastructure.infrastructure.details.validator" placeholder="">\n' +
    '            </div>\n' +
    '            <div ng-messages="createinfrastructure.validator.$error" ng-if="editinfrastructure.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Validator is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="pull-right">\n' +
    '          <button class="btn btn-default" ng-click="editinfrastructure.cancel()">Cancel</button>\n' +
    '          <button ng-click="editinfrastructure.edit(!createinfrastructure.$invalid)" class="btn btn-default btn-danger">Submit</button>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editoperator/views/passwordmodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div class="panel panel-default panel-form">\n' +
    '    <div class="panel-heading clearfix">\n' +
    '      <h4>PASSWORD</h4>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div class="form-group">\n' +
    '        <p>{{modal.message}}</p>\n' +
    '        <input type="password" class="form-control" id="password" name="password" ng-model="modal.password" placeholder="******" required>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="modal.cancel()">Cancel</button>\n' +
    '  <button class="btn btn-primary" type="button" ng-click="modal.ok()">OK</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editoperator/views/editoperator.tpl.html',
    '<div id="editoperator">\n' +
    '  <h2 class="text-center">Edit Operator</h2>\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="editOperator" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>OPERATOR NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': editOperator.operatorname.$invalid && editoperator.submitted }">\n' +
    '              <input type="text" class="form-control" id="operatorname" name="operatorname" ng-model="editoperator.operator.name" placeholder="eg. First Last" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="editOperator.operatorname.$error" ng-if="editoperator.submitted">\n' +
    '              <p ng-message="required">operator name is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>USER NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': editOperator.username.$invalid && editoperator.submitted }">\n' +
    '              <input type="email" class="form-control" id="username" name="username" ng-model="editoperator.operator.login" placeholder="eg. somename@someemail.com" required>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="editOperator.username.$error" ng-if="editoperator.submitted">\n' +
    '              <p ng-message="required">user name is required.</p>\n' +
    '              <p ng-message="email">This needs to be a valid email</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Password</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <a class="btn btn-danger btn-md" ng-click="editoperator.updatePassword(editoperator.operator.id)" tooltip-placement="left" tooltip="Edit Password">Update Password</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-if="admin.profileInfo.role === 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>ROLE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': editOperator.role.$invalid && editoperator.submitted }">\n' +
    '              <select id="role" name="role" class="form-control" ng-model="editoperator.operator.roleId" ng-options="role.id as role.name for role in editoperator.roles" required>\n' +
    '                <option value="">Select A Role</option>\n' +
    '              </select>\n' +
    '            </div>\n' +
    '            <div class="help-block" ng-messages="editOperator.role.$error" ng-if="editoperator.submitted">\n' +
    '              <p ng-message="required">role is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="editoperator.operator.role == 2" ng-if="admin.profileInfo.role === 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Venue</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\': createOperator.role.$invalid && newoperator.submitted }">\n' +
    '              <select id="venue" name="venue" class="form-control" ng-model="editoperator.operator.details.venue" ng-options="venue.id as venue.venuetitle for venue in editoperator.venues" ng-change="editoperator.setVenue(editoperator.operator.details.venue)">\n' +
    '                <option value="">Select A Venue</option>\n' +
    '              </select>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="help-block" ng-messages="createOperator.role.$error" ng-if="newoperator.submitted">\n' +
    '              <p ng-message="required">role is required.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="editoperator.updateOperator(!editOperator.$invalid)" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editeventnew/views/eventsplash.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="eventPadding">\n' +
    '    <div class="row">\n' +
    '      <div class="col-xs-9 col-sm-6 col-ms-6">\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Splash Page Type</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <select class="form-control" name="splashType" ng-model="eventsplashmodal.params.splashType" ng-options="type as type.name for type in eventsplashmodal.params.splashPageTypes" ng-change="eventsplashmodal.setSplashPageType(eventsplashmodal.params.splashType, false)">\n' +
    '            </select>\n' +
    '            <div class="panel-body">\n' +
    '              <input type="checkbox" name="marketingOn" ng-model="eventsplashmodal.params.splashDetails.marketingOn" ng-checked="{{eventsplashmodal.params.splashDetails.marketingOn}}" value="{{eventsplashmodal.params.splashDetails.marketingOn}}">\n' +
    '              Advertising On\n' +
    '              </input>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div ng-show="eventsplashmodal.splashType.id <= 0">\n' +
    '            <p class="text-danger">\n' +
    '              <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '              <strong>\n' +
    '                Splash Type is required</strong>\n' +
    '            </p>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Background Image</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <div class="row">\n' +
    '                <div ng-show="eventsplashmodal.params.bckgrndImgAdded" class="col-md-4 col-md-offset-4">\n' +
    '                  <img ng-src="{{staticUrl}}{{eventsplashmodal.params.bckgrndImg}}" class="img-responsive" />\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <button class="btn btn-danger" ng-click="eventsplashmodal.selectBckgrndImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '                <button class="btn btn-danger" ng-click="eventsplashmodal.uploadBckgrndImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-show="!eventsplashmodal.params.bckgrndImgAdded">\n' +
    '              <p class="text-danger">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong>\n' +
    '                  Background Image is required</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Venue Logo</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <div class="row">\n' +
    '                <div ng-show="eventsplashmodal.params.venueLogoAdded" class="col-md-4 col-md-offset-4">\n' +
    '                  <img ng-src="{{staticUrl}}{{eventsplashmodal.params.venueLogo}}" class="img-responsive" />\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <button class="btn btn-danger" ng-click="eventsplashmodal.selectLogoImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '                <button class="btn btn-danger" ng-click="eventsplashmodal.uploadLogoImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-show="!eventsplashmodal.params.venueLogoAdded">\n' +
    '              <p class="text-danger">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong>\n' +
    '                  Venue Logo is required</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel panel-default panel-form" ng-show="eventsplashmodal.params.splashDetails.marketingOn">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Offer Image 1</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <div class="row">\n' +
    '                <div ng-show="eventsplashmodal.params.offerImage1Added" class="col-md-4 col-md-offset-4">\n' +
    '                  <img ng-src="{{staticUrl}}{{eventsplashmodal.params.offerImage1Uri}}" class="img-responsive" ng-if="eventsplashmodal.params.offerImage1Uri"/>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  <button class="btn btn-danger" ng-click="eventsplashmodal.selectOfferImage1()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '                  <button class="btn btn-danger" ng-click="eventsplashmodal.uploadOfferImage1()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  Enter Ad URL: <input type="text" ng-model="eventsplashmodal.params.offerImage1Link" style="width:100%" value="{{eventsplash.params.offerImage1Link}}">{{eventsplash.params.offerImage1Link}}</input>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-show="!eventsplashmodal.params.offerImage1Added">\n' +
    '              <p class="text-danger">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong> Offers Image is required</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel panel-default panel-form" ng-show="eventsplashmodal.params.splashDetails.marketingOn">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Offer Image 2</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <div class="row">\n' +
    '                <div ng-show="eventsplashmodal.params.offerImage2Added" class="col-md-4 col-md-offset-4">\n' +
    '                  <img ng-src="{{staticUrl}}{{eventsplashmodal.params.offerImage2Uri}}" class="img-responsive" ng-if="eventsplashmodal.params.offerImage2Uri"/>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  <button class="btn btn-danger" ng-click="eventsplashmodal.selectOfferImage2()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '                  <button class="btn btn-danger" ng-click="eventsplashmodal.uploadOfferImage2()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  Enter Ad URL: <input type="text" ng-model="eventsplashmodal.params.offerImage2Link" style="width:100%" value="{{eventsplash.params.offerImage2Link}}">\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel panel-default panel-form" ng-show="eventsplashmodal.params.splashDetails.marketingOn">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Offer Image 3</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <div class="row">\n' +
    '                <div ng-show="eventsplashmodal.params.offerImage3Added" class="col-md-4 col-md-offset-4">\n' +
    '                  <img ng-src="{{staticUrl}}{{eventsplashmodal.params.offerImage3Uri}}" class="img-responsive" ng-if="eventsplashmodal.params.offerImage3Uri"/>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  <button class="btn btn-danger" ng-click="eventsplashmodal.selectOfferImage3()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '                  <button class="btn btn-danger" ng-click="eventsplashmodal.uploadOfferImage3()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  Enter Ad URL: <input type="text" ng-model="eventsplashmodal.params.offerImage3Link" style="width:100%" value="{{eventsplash.params.offerImage3Link}}">\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel panel-default panel-form" ng-show="eventsplashmodal.params.splashDetails.marketingOn">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Offer Image 4</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <div class="row">\n' +
    '                <div ng-show="eventsplashmodal.params.offerImage4Added" class="col-md-4 col-md-offset-4">\n' +
    '                  <img ng-src="{{staticUrl}}{{eventsplashmodal.params.offerImage4Uri}}" class="img-responsive" ng-if="eventsplashmodal.params.offerImage4Uri"/>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  <button class="btn btn-danger" ng-click="eventsplashmodal.selectOfferImage4()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '                  <button class="btn btn-danger" ng-click="eventsplashmodal.uploadOfferImage4()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  Enter Ad URL: <input type="text" ng-model="eventsplashmodal.params.offerImage4Link" style="width:100%" value="{{eventsplash.params.offerImage4Link}}">\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel panel-default panel-form" ng-show="eventsplashmodal.params.splashDetails.marketingOn">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Offer Image 5</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <div class="row">\n' +
    '                <div ng-show="eventsplashmodal.params.offerImage5Added" class="col-md-4 col-md-offset-4">\n' +
    '                  <img ng-src="{{staticUrl}}{{eventsplashmodal.params.offerImage5Uri}}" class="img-responsive" ng-if="eventsplashmodal.params.offerImage5Uri"/>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  <button class="btn btn-danger" ng-click="eventsplashmodal.selectOfferImage5()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '                  <button class="btn btn-danger" ng-click="eventsplashmodal.uploadOfferImage5()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  Enter Ad URL: <input type="text" ng-model="eventsplashmodal.params.offerImage5Link" style="width:100%" value="{{eventsplash.params.offerImage5Link}}">\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel panel-default panel-form" ng-show="eventsplashmodal.params.splashDetails.marketingOn">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Offer Image 6</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <div class="row">\n' +
    '                <div ng-show="eventsplashmodal.params.offerImage6Added" class="col-md-4 col-md-offset-4">\n' +
    '                  <img ng-src="{{staticUrl}}{{eventsplashmodal.params.offerImage6Uri}}" class="img-responsive" ng-if="eventsplashmodal.params.offerImage6Uri"/>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  <button class="btn btn-danger" ng-click="eventsplashmodal.selectOfferImage6()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '                  <button class="btn btn-danger" ng-click="eventsplashmodal.uploadOfferImage6()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <div class="col-md-12">\n' +
    '                  Enter Ad URL: <input type="text" ng-model="eventsplashmodal.params.offerImage6Link" style="width:100%" value="{{eventsplash.params.offerImage6Link}}">\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <div class="panel panel-default panel-form" ng-show="eventsplashmodal.spSocial">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Social Media Login</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12">\n' +
    '                <div class="col-sm-3" ng-repeat="social in eventsplashmodal.params.socialLogins">\n' +
    '                  <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;" ng-click="eventsplashmodal.addOpenAuth(social.id)" ng-disabled="social.name ===\'Twilio\' && !eventsplashmodal.params.hasTwilio">\n' +
    '                    <div class="content">\n' +
    '                      <span class="badge badgeSocial" ng-show="eventsplashmodal.params.openAuthList.indexOf(social.id) > -1"><i class="fa fa-check-circle-o fa-2x"></i></span>\n' +
    '                    </div>\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row" ng-show="eventsplashmodal.anonymousLogin.name === \'Anonymous\'">\n' +
    '              <div class="col-xs-12">\n' +
    '                <h5><input type="checkbox" id="anonymousLogin" name="anonymousLogin" ng-model="eventsplashmodal.anonymousLogin.on" /> Allow anonymous signin.</h5>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-show="eventsplashmodal.params.openAuthList.length < 1">\n' +
    '              <p class="text-danger">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong>\n' +
    '                  Please select social log in</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="eventsplashmodal.spVideo">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Video Url</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\' : createEvent.videoUrl.$error}">\n' +
    '              <input type="text" class="form-control" id="videoUrl" ng-model="eventsplashmodal.params.splashDetails.videoUrl" name="videoUrl" placeholder="eg. http://mysite.com" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createEvent.videoUrl.$error" ng-show="createEvent.videoUrl.$error">\n' +
    '              <p class="text-danger" ng-message="required">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong>\n' +
    '                  Video Url is required</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="eventsplashmodal.spAmount">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Amount</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="input-group" ng-class="{ \'has-error\' : createEvent.amount.$error}">\n' +
    '              <div class="input-group-addon">&euro;</div>\n' +
    '              <input type="number" min="0" step="1" class="form-control" id="amount" ng-model="eventsplashmodal.event.splashDetails.amount" name="amount" placeholder="0" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createEvent.amount.$error" ng-show="createEvent.amount.$error">\n' +
    '              <p class="text-danger" ng-message="required">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong>\n' +
    '                  Amount is required</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="eventsplashmodal.spAmount">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Duration (hrs)</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="input-group" ng-class="{ \'has-error\' : createEvent.period.$error}">\n' +
    '              <input type="number" min="0" step="1" class="form-control" id="period" ng-model="eventsplashmodal.event.splashDetails.period" name="period" placeholder="0" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createEvent.amount.$error" ng-show="createEvent.period.$error">\n' +
    '              <p class="text-danger" ng-message="required">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong>\n' +
    '                  Duration is required</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '  <div class="panel panel-default panel-form" ng-show="eventsplashmodal.spSocial">\n' +
    '    <div class="panel-heading clearfix">\n' +
    '      <h4>Social Media Settings</h4>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div class="form-group" ng-class="{ \'has-error\' : eventcreation.stepThree}">\n' +
    '        <h5><input type="checkbox" id="addlikebutton" name="addlikebutton" ng-model="eventsplashmodal.addlikebutton"/> Facebook Like Button </h5>\n' +
    '        <div ng-show="eventsplashmodal.addlikebutton">\n' +
    '          <div class="row" style="border-bottom: none; padding: 5px" ng-show="eventsplashmodal.venue.customFbLikeUrl">\n' +
    '            <div class="col-xs-12">\n' +
    '              <div style="margin-top: 5px"><input type="checkbox" id="defaultFbLikeUrl" name="defaultFbLikeUrl" ng-model="eventsplashmodal.defaultFbLikeUrl" ng-change="eventsplashmodal.customFbLike = false;"/> Default - {{eventsplashmodal.venue.customFbLikeUrl}}</div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row" style="border-bottom: none; padding: 5px">\n' +
    '            <div class="col-xs-4">\n' +
    '              <div style="margin-top: 5px"><input type="checkbox" id="customFbLike" name="customFbLike" ng-model="eventsplashmodal.customFbLike" ng-change="eventsplashmodal.defaultFbLikeUrl = false;"/> Custom.</div>\n' +
    '            </div>\n' +
    '            <div class="col-xs-8" ng-hide="eventsplashmodal.defaultFbLikeUrl">\n' +
    '              <input type="url" class="form-control pull-right" id="customFbLikeUrl" ng-model="eventsplashmodal.params.splashDetails.customFbLikeUrl" name="customFbLikeUrl" placeholder="Custom Facebook Page Url" ng-change="eventsplashmodal.defaultFbLikeUrl = false;">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row" style="border-bottom: none; padding: 5px">\n' +
    '            <div class="col-xs-offset-6 col-xs-6">\n' +
    '              <div ng-show="!createEvent.customFbLikeUrl.$valid && eventcreation.stepThree" class="pull-right">\n' +
    '                <p class="text-danger">\n' +
    '                  <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                  <strong> Custom Url must be a valid url. Example https://www.facebook.com/Liquidedge/</strong>\n' +
    '                </p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '        <!-- <div class="panel panel-default panel-form" ng-show="eventsplashmodal.isMagnet">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>County Dropdown</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <label>\n' +
    '            <input type="checkbox" ng-model="eventsplashmodal.params.splashDetails.countyDropDown"\n' +
    '               ng-true-value="true" ng-false-value="false" ng-change="checkChanged()">\n' +
    '               Include County Dropdown\n' +
    '            </label>\n' +
    '          </div>\n' +
    '        </div> -->\n' +
    '        <div class="panel panel-default panel-form" ng-show="eventsplashmodal.params.conference">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Conference Settings</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" >\n' +
    '              <div >\n' +
    '                <div class="row" style="border-bottom: none; padding: 5px">\n' +
    '                  <div class="col-xs-8" >\n' +
    '                    <input type="text" class="form-control pull-right" id="conferencepass" ng-model="eventsplashmodal.params.splashDetails.conferencepass" name="conferencepass" value={{eventsplashmodal.params.splashDetails.conferencepass}}>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Redirection Url</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\' : eventsplashmodal.params.redirectionurl.$error}">\n' +
    '              <input type="text" class="form-control" id="redirectionURL" ng-model="eventsplashmodal.params.redirectionurl" name="redirection" placeholder="eg. http://mysite.com" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="eventsplashmodal.params.redirectionurl.$error" ng-show="eventsplashmodal.params.redirectionurl.$error">\n' +
    '              <p class="text-danger" ng-message="required">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong>\n' +
    '                  Redirection Url is required</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="col-xs-9 col-sm-6 col-md-5 col-md-offset-1">\n' +
    '        <h3 class="text-center">Splash Page</h3>\n' +
    '        <div class="col-md-10 col-md-offset-1">\n' +
    '          <div class="splashBackground" ng-style="{ \'background-image\': \'url(\' + staticUrl + eventsplashmodal.params.bckgrndImg + \')\' }">\n' +
    '            <div class="content">\n' +
    '              <div class="row venueLogo">\n' +
    '                <div class="col-xs-8 col-xs-offset-2" ng-show="eventsplashmodal.params.venueLogoAdded">\n' +
    '                  <img ng-src="{{staticUrl}}{{eventsplashmodal.params.venueLogo}}" class="img-responsive" />\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row login-mod well well-sm" ng-show="eventsplashmodal.params.bckgrndImgAdded && eventsplashmodal.spEmail">\n' +
    '                <div class="scaled">\n' +
    '                  <p class="railtext">\n' +
    '                    Connect to our FREE WIFI with\n' +
    '                  </p>\n' +
    '                  <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>\n' +
    '                    <div class="form-group" ng-class="{ \'has-error\' : loginForm.email.$error && authentication.formSubmitted }">\n' +
    '                      <input type="email" name="email" class="form-control" placeholder="Email">\n' +
    '                    </div>\n' +
    '                    <div class="form-group railtext">\n' +
    '                      <input type="checkbox" ng-model="authentication.terms" required name="terms">\n' +
    '                      <a ng-click="">Terms and Conditions</a>\n' +
    '                    </div>\n' +
    '                    <div class="row text-center">\n' +
    '                      <div class="col-md-12 col-xs-12 col-sm-12">\n' +
    '                        <button type="submit" class="btn btn-danger">\n' +
    '                          CONNECT\n' +
    '                        </button>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </form>\n' +
    '                  <div class="leLogo">\n' +
    '                    <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row login-mod well well-sm" ng-show="eventsplashmodal.params.bckgrndImgAdded && eventsplashmodal.spRegister">\n' +
    '                <div class="scaled">\n' +
    '                  <p class="railtext">\n' +
    '                    Connect to our FREE WIFI with\n' +
    '                  </p>\n' +
    '                  <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>\n' +
    '                    <div class="form-group" ng-class="{ \'has-error\' : loginForm.email.$error && authentication.formSubmitted }">\n' +
    '                      <input type="email" name="email" class="form-control" placeholder="Email">\n' +
    '                    </div>\n' +
    '                    <div class="form-group" ng-class="{ \'has-error\' : loginForm.email.$error && authentication.formSubmitted }">\n' +
    '                      <input type="password" name="password" class="form-control" placeholder="Password">\n' +
    '                    </div>\n' +
    '                    <div class="form-group railtext">\n' +
    '                      <input type="checkbox" ng-model="authentication.terms" required name="terms">\n' +
    '                      <a ng-click="">Terms and Conditions</a>\n' +
    '                    </div>\n' +
    '                    <div class="row text-center">\n' +
    '                      <div class="col-md-12 col-xs-12 col-sm-12">\n' +
    '                        <button type="submit" class="btn btn-danger">\n' +
    '                          CONNECT\n' +
    '                        </button>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </form>\n' +
    '                  <div class="leLogo">\n' +
    '                    <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row video-mod" ng-show="eventsplashmodal.params.bckgrndImgAdded && eventsplashmodal.spVideo">\n' +
    '                <div class="scaled">\n' +
    '                  <div class="leLogo">\n' +
    '                    <img class="img-responsive" src="./images/youtube.jpg" alt="Youtube Video">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="footer-mod" ng-show="eventsplashmodal.params.bckgrndImgAdded && eventsplashmodal.spVideo">\n' +
    '                <div class="centered">\n' +
    '                  <strong class="railtext">\n' +
    '                    Connect to our FREE WIFI with\n' +
    '                  </strong>\n' +
    '                  <div class="btn-deck">\n' +
    '                    <button type="submit" class="btn btn-danger btn-xs" ng-click="">\n' +
    '                      More Info\n' +
    '                    </button>\n' +
    '                    <button type="submit" class="btn btn-info btn-xs" ng-click="">\n' +
    '                      No, Thanks\n' +
    '                    </button>\n' +
    '                  </div>\n' +
    '                  <div class="leLogo">\n' +
    '                    <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="footer-mod" ng-show="eventsplashmodal.params.bckgrndImgAdded && eventsplashmodal.spLink">\n' +
    '                <div class="centered">\n' +
    '                  <strong class="railtext">\n' +
    '                    Connect to our FREE WIFI with\n' +
    '                  </strong>\n' +
    '                  </br>\n' +
    '                  <button type="submit" class="btn btn-danger btn-deck btn-xs" ng-click="">\n' +
    '                    CONNECT\n' +
    '                  </button>\n' +
    '                  <div class="leLogo">\n' +
    '                    <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="footer-mod" ng-show="eventsplashmodal.params.bckgrndImgAdded && eventsplashmodal.spSocial">\n' +
    '                <div class="socialCentred">\n' +
    '                  <div class="DocumentList">\n' +
    '                    <strong class="railtext">\n' +
    '                      Connect to our FREE WIFI with\n' +
    '                    </strong>\n' +
    '                    <div class="row">\n' +
    '                      <div class="socIconsList" ng-repeat="social in eventsplashmodal.params.socialLogins" ng-show="eventsplashmodal.params.openAuthList.indexOf(social.id) > -1">\n' +
    '                        <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;">\n' +
    '                          <div class="content">\n' +
    '                          </div>\n' +
    '                        </button>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                    <div class="row" ng-show="eventsplashmodal.anonymousLogin.on">\n' +
    '                      <a>Continue Without Login</a> <br />\n' +
    '                      <br />\n' +
    '                    </div>\n' +
    '                    <div class="" style="margin-left: auto;margin-right: auto;  display: block;width: 10vw;height: auto;">\n' +
    '                      <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                    </div>\n' +
    '\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel-footer">\n' +
    '      <div class="pull-right">\n' +
    '        <button class="btn btn-default" ng-click="eventsplashmodal.cancel()">Cancel</button>\n' +
    '        <button class="btn btn-default btn-danger" ng-click="eventsplashmodal.ok()">Submit</button>\n' +
    '      </div>\n' +
    '      <div class="clearfix"></div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editeventnew/views/eventnetwork.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="eventPadding">\n' +
    '    <div class="row">\n' +
    '      <!-- <div class="col-xs-11 col-sm-7 col-ms-7"> -->\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Network Name</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\' : createEvent.networkName.$error}">\n' +
    '              <input type="text" class="form-control" id="networkname" name="networkName" ng-model="eventnetworkmodal.params.ssidname" ng-pattern="/^[a-zA-Z0-9]*$/" placeholder="eg. nowhitespace" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createEvent.networkName.$error" ng-show="createEvent.networkName.$error">\n' +
    '              <p class="text-danger" ng-message="required">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong> Network Name is required</strong>\n' +
    '              </p>\n' +
    '              <p class="text-danger" ng-message="pattern">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong> Network Name must not contain spaces</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Network Type</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <select class="form-control" ng-model="eventnetworkmodal.params.networkType" ng-change="eventnetworkmodal.setNetworkType(eventnetworkmodal.params.networkType)" ng-options="type.id as type.name for type in eventnetworkmodal.networkTypes">\n' +
    '            </select>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="eventnetworkmodal.params.provider === 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Network Optimization</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <ul class="">\n' +
    '              <li class="checkbox" ng-repeat="category in eventnetworkmodal.optCatList">\n' +
    '                <label>\n' +
    '                  <input type="checkbox" checklist-model="eventnetworkmodal.params.categories" checklist-value="category.id"> {{category.name}}\n' +
    '                  <a href="" tooltip-placement="right" tooltip="{{category.description}}"> <i class="fa fa-question-circle"></i></a>\n' +
    '                </label>\n' +
    '              </li>\n' +
    '            </ul>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="eventnetworkmodal.provider === 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Set Bandwidth Limit</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div>\n' +
    '              <rzslider rz-slider-model="eventnetworkmodal.priceSlider.value" rz-slider-options="eventnetworkmodal.priceSlider.options"></rzslider>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Network Zones</h4>\n' +
    '            <div class="input-group">\n' +
    '                <div class="input-group-addon"><i class="fa fa-search"></i></div>\n' +
    '                <input type="text" class="form-control" placeholder="Search Zones" ng-model="zoneSearch">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <ul class="">\n' +
    '              <!-- <li class="checkbox" ng-repeat="zone in eventnetworkmodal.zoneList"> -->\n' +
    '              <li class="checkbox" dir-paginate="zone in eventnetworkmodal.zoneList | orderBy: \'name\' | filter:zoneSearch | itemsPerPage: 10">\n' +
    '                <label>\n' +
    '                  <input type="checkbox" checklist-model="eventnetworkmodal.params.zones" checklist-value="zone.id"> {{zone.name}}\n' +
    '                </label>\n' +
    '              </li>\n' +
    '            </ul>\n' +
    '            <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>\n' +
    '            <div ng-show="eventnetworkmodal.zoneList.length < 1">\n' +
    '              <p class="text-danger">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong> Please select at least one zone</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '    <!--  </div> -->\n' +
    '      <!-- <div class="col-xs-8 col-sm-5 col-ms-5">\n' +
    '        <h3>Managing Application Settings</h3>\n' +
    '        <p>Optimize your WiFi network to suit your event</p>\n' +
    '        <a href="">Learn More</a>\n' +
    '      </div> -->\n' +
    '    </div>\n' +
    '    </div>\n' +
    '    <div class="panel-footer">\n' +
    '      <div class="pull-right">\n' +
    '        <button class="btn btn-default" ng-click="eventnetworkmodal.cancel()">Cancel</button>\n' +
    '        <button class="btn btn-default btn-danger" ng-click="eventnetworkmodal.ok()">Submit</button>\n' +
    '      </div>\n' +
    '      <div class="clearfix"></div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editeventnew/views/endevent.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to End this Event?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="endevent.ok()">Yes</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="endevent.cancel()">No</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editeventnew/views/editeventsummary.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="eventPadding">\n' +
    '    <div class="row">\n' +
    '      <!-- <div class="col-xs-11 col-sm-7 col-ms-7"> -->\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Name &amp; Duration</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group" ng-class="{ \'has-error\' : createEvent.eventname.$error && editsummary.stepOne}">\n' +
    '              <label for="eventname" class="control-label pull-left">EVENT NAME</label>\n' +
    '              <input type="text" class="form-control" id="eventname" name="eventname" ng-model="editsummary.params.name" placeholder="eg. Big Match Day" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createEvent.eventname.$error" ng-show="createEvent.eventname.$error && editsummary.stepOne">\n' +
    '              <p class="text-danger" ng-message="required">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong>\n' +
    '                  EVENT NAME is required</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-class="{ \'has-error\' : createEvent.eventdescription.$error && editsummary.stepOne}">\n' +
    '              <label for="eventdescription" class="control-label pull-left">EVENT DESCRIPTION</label>\n' +
    '              <input type="text" class="form-control" id="eventdescription" name="eventdescription" ng-model="editsummary.params.description" placeholder="eg. Big Match Day" required>\n' +
    '            </div>\n' +
    '            <div ng-messages="createEvent.eventdescription.$error" ng-show="createEvent.eventdescription.$error && editsummary.stepOne">\n' +
    '              <p class="text-danger" ng-message="required">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong>\n' +
    '                  EVENT DESCRIPTION is required</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '            <div class="form-row">\n' +
    '              <div class="form-group text-box long cell cell-pad">\n' +
    '                <label for="startdate" class="control-label pull-left">START DATE</label>\n' +
    '                <input type="text" class="form-control" min-date="editsummary.minDate" name="startDate" datepicker-popup="{{editsummary.dateFormat}}" ng-model="editsummary.params.starttime" is-open="editsummary.status.openedSt" datepicker-options="editsummary.dateOptions" ng-required="true" close-text="Close"/>\n' +
    '              </div>\n' +
    '              <div class="short cell">\n' +
    '                <button type="button" class="btn btn-date" ng-click="editsummary.openCalSt()">\n' +
    '                  <i class="glyphicon glyphicon-calendar"></i>\n' +
    '                </button>\n' +
    '              </div>\n' +
    '              <fieldset ng-disabled="editsummary.noend">\n' +
    '                <div class="form-group text-box long cell cell-pad">\n' +
    '                  <label for="endtime" class="control-label pull-left">END DATE</label>\n' +
    '                  <input type="text" class="form-control" min-date="editsummary.minDate" datepicker-popup="{{editsummary.dateFormat}}" ng-model="editsummary.params.endtime" is-open="editsummary.status.openedEnd" datepicker-options="editsummary.dateOptions" ng-required="true" close-text="Close"/>\n' +
    '                </div>\n' +
    '              </fieldset>\n' +
    '              <div class="short cell">\n' +
    '                <button type="button" class="btn btn-date" ng-click="editsummary.openCalEnd()" ng-disabled="editsummary.noend">\n' +
    '                  <i class="glyphicon glyphicon-calendar"></i>\n' +
    '                </button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="form-row">\n' +
    '              <div class="form-group text-box long cell cell-pad">\n' +
    '                <label for="starttime" class="control-label pull-left timelable">START TIME</label>\n' +
    '                <timepicker ng-model="editsummary.params.starttime" hour-step="editsummary.hstep" minute-step="editsummary.mstep" show-meridian="ismeridian" show-spinners="true"></timepicker>\n' +
    '              </div>\n' +
    '              <div class="short cell">\n' +
    '                <span class="glyphicon glyphicon-time"></span>\n' +
    '              </div>\n' +
    '              <fieldset ng-disabled="editsummary.noend">\n' +
    '                <div class="form-group text-box long cell cell-pad">\n' +
    '                  <label for="endtime" class="control-label pull-left timelable">END TIME</label>\n' +
    '                  <timepicker ng-model="editsummary.params.endtime" hour-step="editsummary.hstep" minute-step="editsummary.mstep" show-meridian="ismeridian" show-spinners="true"></timepicker>\n' +
    '                </div>\n' +
    '              </fieldset>\n' +
    '              <div class="short cell" style="vertical-align: top; padding-top: 5px">\n' +
    '                <p><strong>No End Date</strong></p>\n' +
    '                <label class="noend">\n' +
    '                  <input type="checkbox" ng-model="editsummary.noend">\n' +
    '                  <span class="checkmark"></span>\n' +
    '                </label>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div>\n' +
    '              <p class="text-danger" ng-show="editsummary.dateError">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong>Start Date/End Date Are Required Fields</strong>\n' +
    '              </p>\n' +
    '              <p class="text-danger" ng-show="editsummary.stTimeInPast">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong>Start Date/Time cannot be in the past</strong>\n' +
    '              </p>\n' +
    '              <p class="text-danger" ng-show="editsummary.endTimeInPast">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong>End Date/Time cannot be in the past</strong>\n' +
    '              </p>\n' +
    '              <p class="text-danger" ng-show="editsummary.endBeforeStart">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong>End Date/Time cannot be prior to Start Date/Time</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel panel-default panel-form">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>Redirection Url</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group" ng-class="{ \'has-error\' : editsummary.params.redirectionurl.$error}">\n' +
    '                <input type="text" class="form-control" id="redirectionURL" ng-model="editsummary.params.redirectionurl" name="redirection" placeholder="eg. http://mysite.com" required>\n' +
    '              </div>\n' +
    '              <div ng-messages="editsummary.params.redirectionurl.$error" ng-show="editsummary.params.redirectionurl.$error">\n' +
    '                <p class="text-danger" ng-message="required">\n' +
    '                  <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                  <strong>\n' +
    '                    Redirection Url is required</strong>\n' +
    '                </p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <!-- <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>EVENT IMAGE</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <div class="row">\n' +
    '                <div ng-show="editevent.eventImageAdded" class="col-md-4 col-md-offset-4">\n' +
    '                  <img ng-src="{{staticUrl}}{{editevent.eventImage}}" class="img-responsive"/>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row">\n' +
    '                <button class="btn btn-danger" ng-click="editevent.selectEventImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '                <button class="btn btn-danger" ng-click="editevent.uploadEventImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-show="!editevent.eventImageAdded && editevent.stepOne">\n' +
    '              <p class="text-danger">\n' +
    '                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                <strong>\n' +
    '                  Event Image is required</strong>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div> -->\n' +
    '      <!-- </div> -->\n' +
    '      <!-- <div class="col-xs-8 col-sm-5 col-ms-5">\n' +
    '        <h3>Make your WiFi work for you!</h3>\n' +
    '        <p>Set up fast, secure WiFi the way you want it. Limit access time, allow or deny access to any site you want and set user bandwidth to prevent users hogging the network</p>\n' +
    '       <a href="">Need Help?</a>\n' +
    '      </div> -->\n' +
    '    </div>\n' +
    '    </div>\n' +
    '    <div class="panel-footer">\n' +
    '    <div class="pull-right">\n' +
    '      <button class="btn btn-default" ng-click="editsummary.cancel()">Cancel</button>\n' +
    '      <button class="btn btn-default btn-danger" ng-click="editsummary.ok()">Submit</button>\n' +
    '    </div>\n' +
    '    <div class="clearfix"></div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editeventnew/views/editeventnew.tpl.html',
    '<h2>{{editeventnew.event.eventName}}</h2>\n' +
    '<div class="panel panel-default">\n' +
    '  <div class="eventPadding">\n' +
    '    <div class="row">\n' +
    '      <div class="col-md-6">\n' +
    '        <div class="panel panel-default">\n' +
    '          <div class="panel-heading">\n' +
    '            <h4>Event Summary\n' +
    '               <button class="btn btn-danger btn-md pull-right" ng-click="editeventnew.updateSummary()" tooltip-placement="left" tooltip="Edit Event Details">\n' +
    '              <span class="fa fa-pencil-square-o" aria-hidden="true"></span></button></h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <p>Name: {{editeventnew.event.name}}</p>\n' +
    '            <p>Description: {{editeventnew.event.description}}\n' +
    '            <p>Start Date: {{editeventnew.event.starttime | date:\'medium\'}}</p>\n' +
    '            <p ng-hide="editeventnew.noend">End Date: {{editeventnew.event.endtime | date:\'medium\'}}</p>\n' +
    '            <p>Redirection URL: {{editeventnew.event.redirectionurl}}\n' +
    '          </div>\n' +
    '          <div class="panel-heading">\n' +
    '            <h4>Event Network <button class="btn btn-danger btn-md" ng-click="editeventnew.updateNetwork()" tooltip-placement="left" tooltip="Edit Event Details" ng-show="editeventnew.networkEditEnabled">\n' +
    '              <span class="fa fa-pencil-square-o" aria-hidden="true"></span></button></h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <p>Network Name: {{editeventnew.event.ssidname}}</p>\n' +
    '            <p>Network Type: {{editeventnew.event.networktypename}}</p>\n' +
    '            <!-- <p>Bandwith Limit: {{editeventnew.event.trafficlimitsta / 1000}}MB</p>\n' +
    '            {{editeventnew.event.accesspoints}} -->\n' +
    '            <p ng-if="editeventnew.event.accesspoints[0].provider === 1">Bandwith Limit: {{editeventnew.event.trafficlimitsta}}</p>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button class="btn btn-danger btn-md pull-right" ng-click="editeventnew.endEvent()" tooltip-placement="left" tooltip="End Event">\n' +
    '       <span class="fa fa-stop" aria-hidden="true"></span></button>\n' +
    '      </div>\n' +
    '      <!-- new code -->\n' +
    '      <div class="col-xs-9 col-sm-6 col-md-5 col-md-offset-1">\n' +
    '        <!-- <h3 class="text-center">Splash Page</h3> -->\n' +
    '        <div class="col-md-10 col-md-offset-1">\n' +
    '          <div class="splashBackground" ng-style="{ \'background-image\': \'url(\' + staticUrl + editeventnew.bckgrndImg + \')\' }">\n' +
    '            <div class="content">\n' +
    '              <button class="btn btn-danger btn-md pull-right" ng-click="editeventnew.updateSplash()" tooltip-placement="left" tooltip="Edit Event Details">\n' +
    '                <span class="fa fa-pencil-square-o" aria-hidden="true"></span></button>\n' +
    '              <div class="row venueLogo">\n' +
    '                <div class="col-xs-8 col-xs-offset-2" ng-show="editeventnew.venueLogoAdded">\n' +
    '                  <img ng-src="{{staticUrl}}{{editeventnew.venueLogo}}" class="img-responsive" />\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row login-mod well well-sm" ng-show="editeventnew.bckgrndImgAdded && editeventnew.spEmail">\n' +
    '                <div class="scaled">\n' +
    '                  <p class="railtext">\n' +
    '                    Connect to our FREE WIFI with\n' +
    '                  </p>\n' +
    '                  <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>\n' +
    '                    <div class="form-group" ng-class="{ \'has-error\' : loginForm.email.$error && authentication.formSubmitted }">\n' +
    '                      <input type="email" name="email" class="form-control" placeholder="Email">\n' +
    '                    </div>\n' +
    '                    <div class="form-group railtext">\n' +
    '                      <input type="checkbox" ng-model="authentication.terms" required name="terms">\n' +
    '                      <a ng-click="">Terms and Conditions</a>\n' +
    '                    </div>\n' +
    '                    <div class="row text-center">\n' +
    '                      <div class="col-md-12 col-xs-12 col-sm-12">\n' +
    '                        <button type="submit" class="btn btn-danger">\n' +
    '                          CONNECT\n' +
    '                        </button>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </form>\n' +
    '                  <div class="leLogo">\n' +
    '                    <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row login-mod well well-sm" ng-show="editeventnew.bckgrndImgAdded && editeventnew.spRegister">\n' +
    '                <div class="scaled">\n' +
    '                  <p class="railtext">\n' +
    '                    Connect to our FREE WIFI with\n' +
    '                  </p>\n' +
    '                  <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>\n' +
    '                    <div class="form-group" ng-class="{ \'has-error\' : loginForm.email.$error && authentication.formSubmitted }">\n' +
    '                      <input type="email" name="email" class="form-control" placeholder="Email">\n' +
    '                    </div>\n' +
    '                    <div class="form-group" ng-class="{ \'has-error\' : loginForm.email.$error && authentication.formSubmitted }">\n' +
    '                      <input type="password" name="password" class="form-control" placeholder="Password">\n' +
    '                    </div>\n' +
    '                    <div class="form-group railtext">\n' +
    '                      <input type="checkbox" ng-model="authentication.terms" required name="terms">\n' +
    '                      <a ng-click="">Terms and Conditions</a>\n' +
    '                    </div>\n' +
    '                    <div class="row text-center">\n' +
    '                      <div class="col-md-12 col-xs-12 col-sm-12">\n' +
    '                        <button type="submit" class="btn btn-danger">\n' +
    '                          CONNECT\n' +
    '                        </button>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </form>\n' +
    '                  <div class="leLogo">\n' +
    '                    <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="row video-mod" ng-show="editeventnew.bckgrndImgAdded && editeventnew.spVideo">\n' +
    '                <div class="scaled">\n' +
    '                  <div class="leLogo">\n' +
    '                    <img class="img-responsive" src="./images/youtube.jpg" alt="Youtube Video">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="footer-mod" ng-show="editeventnew.bckgrndImgAdded && editeventnew.spVideo">\n' +
    '                <div class="centered">\n' +
    '                  <strong class="railtext">\n' +
    '                    Connect to our FREE WIFI with\n' +
    '                  </strong>\n' +
    '                  <div class="btn-deck">\n' +
    '                    <button type="submit" class="btn btn-danger btn-xs" ng-click="">\n' +
    '                      More Info\n' +
    '                    </button>\n' +
    '                    <button type="submit" class="btn btn-info btn-xs" ng-click="">\n' +
    '                      No, Thanks\n' +
    '                    </button>\n' +
    '                  </div>\n' +
    '                  <div class="leLogo">\n' +
    '                    <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="footer-mod" ng-show="editeventnew.bckgrndImgAdded && editeventnew.spLink">\n' +
    '                <div class="centered">\n' +
    '                  <strong class="railtext">\n' +
    '                    Connect to our FREE WIFI with\n' +
    '                  </strong>\n' +
    '                  </br>\n' +
    '                  <button type="submit" class="btn btn-danger btn-deck btn-xs" ng-click="">\n' +
    '                    CONNECT\n' +
    '                  </button>\n' +
    '                  <div class="leLogo">\n' +
    '                    <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="footer-mod" ng-show="editeventnew.bckgrndImgAdded && editeventnew.spSocial">\n' +
    '                <div class="socialCentred">\n' +
    '                  <div class="DocumentList">\n' +
    '                    <strong class="railtext">\n' +
    '                      Connect to our FREE WIFI with\n' +
    '                    </strong>\n' +
    '                    <div class="row">\n' +
    '                      <div class="socIconsList" ng-repeat="social in editeventnew.socialLogins" ng-show="editeventnew.openAuthList.indexOf(social.id) > -1">\n' +
    '                        <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;">\n' +
    '                          <div class="content">\n' +
    '                          </div>\n' +
    '                        </button>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                    <div class="row" ng-show="editeventnew.anonymousLogin.on">\n' +
    '                      <a>Continue Without Login</a> <br />\n' +
    '                      <br />\n' +
    '                    </div>\n' +
    '                    <div class="" style="margin-left: auto;margin-right: auto;  display: block;width: 10vw;height: auto;">\n' +
    '                      <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                    </div>\n' +
    '\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editevent/views/editsplash.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-9 col-sm-6 col-ms-6">\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Splash Page Type</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <select class="form-control" name="splashType" ng-model="editevent.splashType" ng-options="type as type.name for type in editevent.splashPageTypes" ng-change="editevent.setSplashPageType(editevent.splashType, false)">\n' +
    '        </select>\n' +
    '      </div>\n' +
    '      <div ng-show="editevent.splashType <= 0 && editevent.stepThree">\n' +
    '        <p class="text-danger">\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <strong>\n' +
    '            Splash Type is required</strong>\n' +
    '        </p>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Background Image</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group">\n' +
    '          <div class="row">\n' +
    '            <div ng-show="editevent.bckgrndImgAdded" class="col-md-4 col-md-offset-4">\n' +
    '              <img ng-src="{{staticUrl}}{{editevent.bckgrndImg}}" class="img-responsive" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <button class="btn btn-danger" ng-click="editevent.selectBckgrndImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '            <button class="btn btn-danger" ng-click="editevent.uploadBckgrndImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="!editevent.bckgrndImgAdded && editevent.stepThree">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Background Image is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Venue Logo</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group">\n' +
    '          <div class="row">\n' +
    '            <div ng-show="editevent.venueLogoAdded" class="col-md-4 col-md-offset-4">\n' +
    '              <img ng-src="{{staticUrl}}{{editevent.venueLogo}}" class="img-responsive" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <button class="btn btn-danger" ng-click="editevent.selectLogoImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '            <button class="btn btn-danger" ng-click="editevent.uploadLogoImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="!editevent.venueLogoAdded && editevent.stepThree">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Venue Logo is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="editevent.spSocial">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Social Media Login</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="row">\n' +
    '          <div class="col-xs-12">\n' +
    '            <div class="col-sm-3" ng-repeat="social in editevent.socialLogins">\n' +
    '              <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;" ng-click="editevent.addOpenAuth(social.id)">\n' +
    '                <div class="content">\n' +
    '                  <span class="badge badgeSocial" ng-show="editevent.openAuthList.indexOf(social.id) > -1"><i class="fa fa-check-circle-o fa-2x"></i></span>\n' +
    '                </div>\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="editevent.openAuthList.length < 1 && editevent.stepThree">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Please select social log in</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="editevent.spVideo">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Video Url</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : createEvent.videoUrl.$error && editevent.stepThree}">\n' +
    '          <input type="text" class="form-control" id="videoUrl" ng-model="editevent.event.splashDetails.videoUrl" name="videoUrl" placeholder="eg. http://mysite.com" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="createEvent.videoUrl.$error" ng-show="createEvent.videoUrl.$error && editevent.stepThree">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Video Url is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="editevent.spAmount">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Amount</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="input-group" ng-class="{ \'has-error\' : createEvent.amount.$error && editevent.stepThree}">\n' +
    '          <div class="input-group-addon">&euro;</div>\n' +
    '          <input type="number" min="0" step="1" class="form-control" id="amount" ng-model="editevent.event.splashDetails.amount" name="amount" placeholder="0" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="createEvent.amount.$error" ng-show="createEvent.amount.$error && editevent.stepThree">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Amount is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="editevent.spAmount">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Duration (hrs)</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="input-group" ng-class="{ \'has-error\' : createEvent.period.$error && editevent.stepThree}">\n' +
    '          <input type="number" min="0" step="1" class="form-control" id="period" ng-model="editevent.event.splashDetails.period" name="period" placeholder="0" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="createEvent.amount.$error" ng-show="createEvent.period.$error && editevent.stepThree">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Duration is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Redirection Url</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : createEvent.redirection.$error && editevent.stepThree}">\n' +
    '          <input type="text" class="form-control" id="redirectionURL" ng-model="editevent.event.redirectionurl" name="redirection" placeholder="eg. http://mysite.com" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="createEvent.redirection.$error" ng-show="createEvent.redirection.$error && editevent.stepThree">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Redirection Url is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="col-xs-9 col-sm-6 col-md-5 col-md-offset-1">\n' +
    '    <h3 class="text-center">Splash Page</h3>\n' +
    '    <div class="col-md-10 col-md-offset-1">\n' +
    '      <div class="splashBackground" ng-style="{ \'background-image\': \'url(\' + staticUrl + editevent.bckgrndImg + \')\' }">\n' +
    '        <div class="content">\n' +
    '          <div class="row venueLogo">\n' +
    '            <div class="col-xs-8 col-xs-offset-2" ng-show="editevent.venueLogoAdded">\n' +
    '              <img ng-src="{{staticUrl}}{{editevent.venueLogo}}" class="img-responsive" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row login-mod well well-sm" ng-show="editevent.bckgrndImgAdded && editevent.spEmail">\n' +
    '            <div class="scaled">\n' +
    '              <p class="railtext">\n' +
    '                Connect to our FREE WIFI with\n' +
    '              </p>\n' +
    '              <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>\n' +
    '                <div class="form-group" ng-class="{ \'has-error\' : loginForm.email.$error && authentication.formSubmitted }">\n' +
    '                  <input type="email" name="email" class="form-control" placeholder="Email">\n' +
    '                </div>\n' +
    '                <div class="form-group railtext">\n' +
    '                  <input type="checkbox" ng-model="authentication.terms" required name="terms">\n' +
    '                  <a ng-click="">Terms and Conditions</a>\n' +
    '                </div>\n' +
    '                <div class="row text-center">\n' +
    '                  <div class="col-md-12 col-xs-12 col-sm-12">\n' +
    '                    <button type="submit" class="btn btn-danger">\n' +
    '                      CONNECT\n' +
    '                    </button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </form>\n' +
    '              <div class="leLogo">\n' +
    '                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row login-mod well well-sm" ng-show="editevent.bckgrndImgAdded && editevent.spRegister">\n' +
    '            <div class="scaled">\n' +
    '              <p class="railtext">\n' +
    '                Connect to our FREE WIFI with\n' +
    '              </p>\n' +
    '              <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>\n' +
    '                <div class="form-group" ng-class="{ \'has-error\' : loginForm.email.$error && authentication.formSubmitted }">\n' +
    '                  <input type="email" name="email" class="form-control" placeholder="Email">\n' +
    '                </div>\n' +
    '                <div class="form-group" ng-class="{ \'has-error\' : loginForm.email.$error && authentication.formSubmitted }">\n' +
    '                  <input type="password" name="password" class="form-control" placeholder="Password">\n' +
    '                </div>\n' +
    '                <div class="form-group railtext">\n' +
    '                  <input type="checkbox" ng-model="authentication.terms" required name="terms">\n' +
    '                  <a ng-click="">Terms and Conditions</a>\n' +
    '                </div>\n' +
    '                <div class="row text-center">\n' +
    '                  <div class="col-md-12 col-xs-12 col-sm-12">\n' +
    '                    <button type="submit" class="btn btn-danger">\n' +
    '                      CONNECT\n' +
    '                    </button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </form>\n' +
    '              <div class="leLogo">\n' +
    '                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row video-mod" ng-show="editevent.bckgrndImgAdded && editevent.spVideo">\n' +
    '            <div class="scaled">\n' +
    '              <div class="leLogo">\n' +
    '                <img class="img-responsive" src="./images/youtube.jpg" alt="Youtube Video">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="footer-mod" ng-show="editevent.bckgrndImgAdded && editevent.spVideo">\n' +
    '            <div class="centered">\n' +
    '              <strong class="railtext">\n' +
    '                Connect to our FREE WIFI with\n' +
    '              </strong>\n' +
    '              <div class="btn-deck">\n' +
    '                <button type="submit" class="btn btn-danger btn-xs" ng-click="">\n' +
    '                  More Info\n' +
    '                </button>\n' +
    '                <button type="submit" class="btn btn-info btn-xs" ng-click="">\n' +
    '                  No, Thanks\n' +
    '                </button>\n' +
    '              </div>\n' +
    '              <div class="leLogo">\n' +
    '                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="footer-mod" ng-show="editevent.bckgrndImgAdded && editevent.spLink">\n' +
    '            <div class="centered">\n' +
    '              <strong class="railtext">\n' +
    '                Connect to our FREE WIFI with\n' +
    '              </strong>\n' +
    '              </br>\n' +
    '              <button type="submit" class="btn btn-danger btn-deck btn-xs" ng-click="">\n' +
    '                CONNECT\n' +
    '              </button>\n' +
    '              <div class="leLogo">\n' +
    '                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="footer-mod" ng-show="editevent.bckgrndImgAdded && editevent.spSocial">\n' +
    '            <div class="socialCentred">\n' +
    '              <div class="DocumentList">\n' +
    '                <strong class="railtext">\n' +
    '                  Connect to our FREE WIFI with\n' +
    '                </strong>\n' +
    '                <div class="row">\n' +
    '                  <div class="socIconsList" ng-repeat="social in editevent.socialLogins" ng-show="editevent.openAuthList.indexOf(social.id) > -1">\n' +
    '                    <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;">\n' +
    '                      <div class="content">\n' +
    '                      </div>\n' +
    '                    </button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="leLogo">\n' +
    '                  <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                </div>\n' +
    '\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="panel-footer">\n' +
    '  <div class="pull-right">\n' +
    '    <button class="btn btn-default" ng-click="editevent.cancel()">Cancel</button>\n' +
    '    <button class="btn btn-default btn-danger" ng-click="editevent.nextStep({splash : createEvent.redirection.$error }, \'admin.editevent.confirm\')">Next Step</button>\n' +
    '  </div>\n' +
    '  <div class="clearfix"></div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editevent/views/editsetup.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-11 col-sm-7 col-ms-7">\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Name &amp; Duration</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : createEvent.eventname.$error && editevent.stepOne}">\n' +
    '          <label for="eventname" class="control-label pull-left">EVENT NAME</label>\n' +
    '          <input type="text" class="form-control" id="eventname" name="eventname" ng-model="editevent.event.name" placeholder="eg. Big Match Day" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="createEvent.eventname.$error" ng-show="createEvent.eventname.$error && editevent.stepOne">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              EVENT NAME is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '        <div class="form-row">\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="startdate" class="control-label pull-left">START DATE</label>\n' +
    '            <input type="text" class="form-control" min-date="editevent.minDate" name="startDate" datepicker-popup="{{editevent.dateFormat}}" ng-model="editevent.event.starttime" is-open="editevent.status.openedSt" datepicker-options="editevent.dateOptions" ng-required="true" close-text="Close"/>\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <button type="button" class="btn btn-date" ng-click="editevent.openCalSt()">\n' +
    '              <i class="glyphicon glyphicon-calendar"></i>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="enddate" class="control-label pull-left">END DATE</label>\n' +
    '            <input type="text" class="form-control" min-date="editevent.minDate" datepicker-popup="{{editevent.dateFormat}}" ng-model="editevent.event.endtime" is-open="editevent.status.openedEnd" datepicker-options="editevent.dateOptions" ng-required="true" close-text="Close"/>\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <button type="button" class="btn btn-date" ng-click="editevent.openCalEnd()">\n' +
    '              <i class="glyphicon glyphicon-calendar"></i>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="form-row">\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="starttime" class="control-label pull-left timelable">START TIME</label>\n' +
    '            <timepicker ng-model="editevent.event.starttime" hour-step="editevent.hstep" minute-step="editevent.mstep" show-meridian="ismeridian" show-spinners="true"></timepicker>\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <span class="glyphicon glyphicon-time"></span>\n' +
    '          </div>\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="endtime" class="control-label pull-left timelable">END TIME</label>\n' +
    '            <timepicker ng-model="editevent.event.endtime" hour-step="editevent.hstep" minute-step="editevent.mstep" show-meridian="ismeridian" show-spinners="true"></timepicker>\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <span class="glyphicon glyphicon-time"></span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div>\n' +
    '          <p class="text-danger" ng-show="editevent.dateError">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>Start Date/End Date Are Required Fields</strong>\n' +
    '          </p>\n' +
    '          <p class="text-danger" ng-show="editevent.stTimeInPast">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>Start Date/Time cannot be in the past</strong>\n' +
    '          </p>\n' +
    '          <p class="text-danger" ng-show="editevent.endTimeInPast">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>End Date/Time cannot be in the past</strong>\n' +
    '          </p>\n' +
    '          <p class="text-danger" ng-show="editevent.endBeforeStart">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>End Date/Time cannot be prior to Start Date/Time</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>EVENT IMAGE</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group">\n' +
    '          <div class="row">\n' +
    '            <div ng-show="editevent.eventImageAdded" class="col-md-4 col-md-offset-4">\n' +
    '              <img ng-src="{{staticUrl}}{{editevent.eventImage}}" class="img-responsive"/>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <button class="btn btn-danger" ng-click="editevent.selectEventImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '            <button class="btn btn-danger" ng-click="editevent.uploadEventImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="!editevent.eventImageAdded && editevent.stepOne">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Event Image is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-8 col-sm-5 col-ms-5">\n' +
    '    <h3>Make your WiFi work for you!</h3>\n' +
    '    <p>Set up fast, secure WiFi the way you want it. Limit access time, allow or deny access to any site you want and set user bandwidth to prevent users hogging the network</p>\n' +
    '    <!-- <a href="">Need Help?</a> -->\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '<div class="panel-footer">\n' +
    '<div class="pull-right">\n' +
    '  <button class="btn btn-default" ng-click="editevent.cancel()">Cancel</button>\n' +
    '  <button class="btn btn-default btn-danger" ng-click="editevent.nextStep({setUp : createEvent.eventname.$error}, \'admin.editevent.editnetwork\')">Next Step</button>\n' +
    '</div>\n' +
    '<div class="clearfix"></div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editevent/views/editnetwork.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-11 col-sm-7 col-ms-7">\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Network Name</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : createEvent.networkName.$error && editevent.stepTwo}">\n' +
    '          <input type="text" class="form-control" id="networkname" name="networkName" ng-model="editevent.event.ssidname" ng-pattern="/^[a-zA-Z0-9]*$/" placeholder="eg. nowhitespace" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="createEvent.networkName.$error" ng-show="createEvent.networkName.$error && editevent.stepTwo">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Network Name is required</strong>\n' +
    '          </p>\n' +
    '          <p class="text-danger" ng-message="pattern">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Network Name must not contain spaces</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Network Type</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <select class="form-control" ng-model="editevent.event.networkType" ng-change="editevent.setNetworkType(editevent.event.networkType)" ng-options="type.id as type.name for type in editevent.networkTypes">\n' +
    '        </select>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="editevent.provider === 1">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Network Optimization</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <ul class="">\n' +
    '          <li class="checkbox" ng-repeat="category in editevent.optCatList">\n' +
    '            <label>\n' +
    '              <input type="checkbox" checklist-model="editevent.categories" checklist-value="category.id"> {{category.name}}\n' +
    '              <a href="" tooltip-placement="right" tooltip="{{category.description}}"> <i class="fa fa-question-circle"></i></a>\n' +
    '            </label>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="editevent.provider === 1">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Set Bandwidth Limit</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div>\n' +
    '          <rzslider rz-slider-model="editevent.priceSlider.value" rz-slider-options="editevent.priceSlider.options"></rzslider>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Network Zones</h4>\n' +
    '        <div class="input-group">\n' +
    '            <div class="input-group-addon"><i class="fa fa-search"></i></div>\n' +
    '            <input type="text" class="form-control" placeholder="Search Zones" ng-model="zoneSearch">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <ul class="">\n' +
    '          <!-- <li class="checkbox" ng-repeat="zone in editevent.zoneList"> -->\n' +
    '          <li class="checkbox" dir-paginate="zone in editevent.zoneList | orderBy: \'name\' | filter:zoneSearch | itemsPerPage: 10">\n' +
    '            <label>\n' +
    '              <input type="checkbox" checklist-model="editevent.zones" checklist-value="zone.id"> {{zone.name}}\n' +
    '            </label>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '        <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>\n' +
    '        <div ng-show="editevent.event.zoneList.length < 1 && editevent.stepTwo">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Please select at least one zone</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-8 col-sm-5 col-ms-5">\n' +
    '    <h3>Managing Application Settings</h3>\n' +
    '    <p>Optimize your WiFi network to suit your event</p>\n' +
    '    <a href="">Learn More</a>\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '<div class="panel-footer">\n' +
    '  <div class="pull-right">\n' +
    '    <button class="btn btn-default" ng-click="editevent.cancel()">Cancel</button>\n' +
    '    <button class="btn btn-default btn-danger" ng-click="editevent.nextStep({network : createEvent.networkName.$error}, \'admin.editevent.editsplash\')">Next Step</button>\n' +
    '  </div>\n' +
    '  <div class="clearfix"></div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editevent/views/editevent.tpl.html',
    '<form name="createEvent" novalidate>\n' +
    '  <h2 class="text-center">Edit Event - {{editevent.event.name}}</h2>\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <ul class="breadcrumb">\n' +
    '        <li ui-sref-active="active">\n' +
    '          <a ui-sref="admin.editevent.editsetup" ng-hide="true"><i class="fa fa-2x fa-sliders"></i></br>Setup</a>\n' +
    '          <a href="" ng-click="editevent.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, \'admin.editevent.editsetup\')"><i class="fa fa-2x fa-sliders"></i></br>Setup</a>\n' +
    '        </li>\n' +
    '        <li ui-sref-active="active">\n' +
    '          <a ui-sref="admin.editevent.editnetwork" ng-hide="true"><i class="fa fa-2x fa-cogs"></i></br>Network</a>\n' +
    '          <a ng-click="editevent.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, \'admin.editevent.editnetwork\')" href=""><i class="fa fa-2x fa-cogs"></i></br>Network</a>\n' +
    '        </li>\n' +
    '        <li ui-sref-active="active">\n' +
    '          <a ui-sref="admin.editevent.editsplash" ng-hide="true"><i class="fa fa-2x fa-mobile"></i></br>Splash Page</a>\n' +
    '          <a ng-click="editevent.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, \'admin.editevent.editsplash\')" href=""><i class="fa fa-2x fa-mobile"></i></br>Splash Page</a>\n' +
    '        </li>\n' +
    '        <li ui-sref-active="active" role="presentation">\n' +
    '          <a ui-sref="admin.editevent.editconfirm" ng-hide="true"><i class="fa fa-2x fa-check-circle-o"></i></br>Confirm</a>\n' +
    '          <a href="" ng-click="editevent.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, \'admin.editevent.confirm\')"><i class="fa fa-2x fa-check-circle-o"></i></br>Confirm</a>\n' +
    '        </li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '    <div data-ui-view></div>\n' +
    '    </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editevent/views/editconfirm.tpl.html',
    '<div class="row">\n' +
    '  <div ng-show="editevent.setupPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confSuccess">\n' +
    '        <i class="fa fa-4x fa-check-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Setup</h4>\n' +
    '      <ul class="confUl">\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Event Name,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Start/End Date,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Event Image,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Access Points</li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.editevent.editsetup">Edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div ng-hide="editevent.setupPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confFail">\n' +
    '        <i class="fa fa-4x fa-times-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Setup</h4>\n' +
    '      <p>Setup Incomplete</p>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.editevent.editsetup">Edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="row">\n' +
    '  <div ng-show="editevent.netPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confSuccess">\n' +
    '        <i class="fa fa-4x fa-check-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Network</h4>\n' +
    '      <ul class="confUl">\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Network Name,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Network Optomization,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Network Bandwidth</li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.editevent.editnetwork">Edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div ng-hide="editevent.netPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confFail">\n' +
    '        <i class="fa fa-4x fa-times-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Network</h4>\n' +
    '      <p>Network Incomplete</p>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.editevent.editnetwork">Edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="row">\n' +
    '  <div ng-show="editevent.splashPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confSuccess">\n' +
    '        <i class="fa fa-4x fa-check-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Splash Page</h4>\n' +
    '      <ul class="confUl">\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Background Image,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Venue Logo,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Social Media,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Redirection Url</li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.editevent.editsplash">Edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div ng-hide="editevent.splashPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confFail">\n' +
    '        <i class="fa fa-4x fa-times-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Splash Page</h4>\n' +
    '      <p>Splash Page Incomplete</p>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.editevent.editsplash">Edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '<div class="panel-footer">\n' +
    '  <div class="pull-right">\n' +
    '    <button class="btn btn-default" ng-click="editevent.cancel()">Cancel</button>\n' +
    '    <button class="btn btn-default btn-danger" ng-click="editevent.updateEvent()" ng-disabled="!editevent.splashPassed || !editevent.netPassed ||  !editevent.setupPassed">Update Event</button>\n' +
    '  </div>\n' +
    '  <div class="clearfix"></div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editevent/views/canceleditevent.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Cancel editing this Event?</h4>\n' +
    '  <p>All changes will be lost</p>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button" ng-click="cancelEditModal.ok()">Confirm</button>\n' +
    '  <button class="btn btn-warning" type="button" ng-click="cancelEditModal.cancel()">Don\'t Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editclservice/views/editclservice.tpl.html',
    '<div id="newitem">\n' +
    '  <div class="col-md-8 col-md-offset-2">\n' +
    '    <div ng-show="editclservice.errorMessage">\n' +
    '      <div class="alert alert-danger" role="alert">\n' +
    '        <button ng-click="editclservice.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '          <span aria-hidden="true">&times;</span>\n' +
    '        </button>\n' +
    '        <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '        <span class="sr-only">Error: </span>\n' +
    '        {{editclservice.error}}\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Edit Cloud Service: {{editclservice.cloudserviceDetails.type}}</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div ng-show="editclservice.cloudserviceDetails.type === \'Twilio\'" class="col-sm-8 col-sm-offset-2">\n' +
    '        <form name="twilioForm" novalidate>\n' +
    '          <div class="panel panel-default panel-form">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>Twilio Settings</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group" ng-class="{ \'has-error\': twilioForm.apiKey.$invalid && editclservice.submitted }">\n' +
    '                <h4>AUTH TOKEN:</h4>\n' +
    '                <input type="text" class="form-control" id="apiKey" name="apiKey" ng-model="editclservice.cloudserviceDetails.apikey" placeholder="eg. xxxxxxxxxx" required>\n' +
    '              </div>\n' +
    '              <div class="help-block" ng-messages="twilioForm.apiKey.$error" ng-if="editclservice.submitted">\n' +
    '                <p class="text-danger" ng-message="required"><strong>AUTH TOKEN is required.</strong></p>\n' +
    '              </div>\n' +
    '              <div class="form-group" ng-class="{ \'has-error\': twilioForm.sid.$invalid && editclservice.submitted }">\n' +
    '                <h4>ACCOUNT SID:</h4>\n' +
    '                <input type="text" class="form-control" id="sid" name="sid" ng-model="editclservice.cloudserviceDetails.accountsid" placeholder="eg. xxxxxxxxxx" required>\n' +
    '              </div>\n' +
    '              <div class="help-block" ng-messages="twilioForm.sid.$error" ng-if="editclservice.submitted">\n' +
    '                <p class="text-danger" ng-message="required"><strong>ACCOUNT SID is required.</strong></p>\n' +
    '              </div>\n' +
    '              <div class="form-group" ng-class="{ \'has-error\': twilioForm.phone.$invalid && editclservice.submitted }">\n' +
    '                <h4>ACCOUNT PHONE:</h4>\n' +
    '                <input type="text" class="form-control" id="phone" name="phone" ng-model="editclservice.cloudserviceDetails.phonenumber" placeholder="eg. 353861212122" required>\n' +
    '              </div>\n' +
    '              <div class="help-block" ng-messages="twilioForm.phone.$error" ng-if="editclservice.submitted">\n' +
    '                <p class="text-danger" ng-message="required"><strong>ACCOUNT PHONE is required.</strong></p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '        <button class="btn btn-danger" ng-click="editclservice.udpateCloudService(!twilioForm.$invalid, editclservice.cloudserviceDetails)">Submit</button>\n' +
    '      </div>\n' +
    '      <div ng-show="editclservice.cloudserviceDetails.type === \'MailChimp\'" class="col-sm-8 col-sm-offset-2">\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>MailChimp Settings</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <form name="mailchimpForm" novalidate>\n' +
    '              <div class="form-group" ng-class="{ \'has-error\': mailchimpForm.apiKey.$invalid && editclservice.submitted }">\n' +
    '                <h4>API KEY:</h4>\n' +
    '                <input type="text" class="form-control" id="apiKey" name="apiKey" ng-model="editclservice.cloudserviceDetails.apikey" placeholder="eg. xxxxxxxxxx-xyz" ng-change="editclservice.updateApiKey()" required>\n' +
    '              </div>\n' +
    '              <div class="help-block" ng-messages="mailchimpForm.apiKey.$error" ng-if="editclservice.submitted">\n' +
    '                <p class="text-danger" ng-message="required"><strong>API KEY is required.</strong></p>\n' +
    '              </div>\n' +
    '              <div ng-show="editclservice.updatedApi">\n' +
    '                <div class="alert alert-success" role="alert" style="width: 40%;">\n' +
    '                  <button ng-click="editclservice.dismiss();" type="button" class="close" aria-label="Close">\n' +
    '                    <span aria-hidden="true">&times;</span>\n' +
    '                  </button>\n' +
    '                  <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                  <span class="sr-only">Alert:</span>\n' +
    '                  {{editclservice.apiMessage}}\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="form-group">\n' +
    '                <div class="row">\n' +
    '                  <div class="col-xs-12">\n' +
    '                    <h4>Select Default List:</h4>\n' +
    '                    <p>Choose a mailing list from the dropdown menu or create a\n' +
    '                      <button ng-click="editclservice.createNewList()" type="button" class="btn btn-danger btn-sm">New List</button>\n' +
    '                    </p>\n' +
    '                    <select class="form-control" ng-model="editclservice.cloudserviceDetails.listidentifier">\n' +
    '                      <option ng-repeat="list in editclservice.lists" value="{{list.id}}">{{list.name}} - {{list.id}}</option>\n' +
    '                    </select>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </form>\n' +
    '            <button class="btn btn-danger" ng-click="editclservice.udpateCloudService(!mailchimpForm.$invalid, editclservice.cloudserviceDetails)">Submit</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('duplicateevent/views/duplicatessidchecks.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>{{modalService.message.messageHeader}}</h4>\n' +
    '  <p>{{modalService.message.messageBody}}</p>\n' +
    '  <table class="table table-responsive eventsTable">\n' +
    '    <thead>\n' +
    '      <tr>\n' +
    '        <th>Access Point</th>\n' +
    '        <th>Zone</th>\n' +
    '      </tr>\n' +
    '    </thead>\n' +
    '    <tbody>\n' +
    '      <tr ng-repeat="ap in modalService.message.accesspoints">\n' +
    '        <td>\n' +
    '          {{ap.ipaddress}}\n' +
    '        </td>\n' +
    '        <td>\n' +
    '          {{ap.zonename}}\n' +
    '        </td>\n' +
    '      </tr>\n' +
    '    </tbody>\n' +
    '  </table>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <div ng-hide="modalService.message.cancel.length > 0">\n' +
    '    <button class="btn btn-primary btn-block" type="button" ng-click="modalService.ok()">Done</button>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('duplicateevent/views/duplicatesplash.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-9 col-sm-6 col-ms-6">\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Splash Page Type</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <select class="form-control" name="splashType" ng-model="duplicateevent.splashType" ng-change="duplicateevent.setSplashPageType(duplicateevent.splashType, false)" ng-options="type as type.name for type in duplicateevent.splashPageTypes">\n' +
    '        </select>\n' +
    '      </div>\n' +
    '      <div ng-show="duplicateevent.splashType <= 0 && duplicateevent.stepThree">\n' +
    '        <p class="text-danger">\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <strong> Splash Type is required</strong>\n' +
    '        </p>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Background Image</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group">\n' +
    '          <div class="row">\n' +
    '            <div ng-show="duplicateevent.bckgrndImgAdded" class="col-md-4 col-md-offset-4">\n' +
    '              <img ng-src="{{staticUrl}}{{duplicateevent.bckgrndImg}}" class="img-responsive" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <button class="btn btn-danger" ng-click="duplicateevent.selectBckgrndImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '            <button class="btn btn-danger" ng-click="duplicateevent.uploadBckgrndImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="!duplicateevent.bckgrndImgAdded && duplicateevent.stepThree">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Background Image is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Venue Logo</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group">\n' +
    '          <div class="row">\n' +
    '            <div ng-show="duplicateevent.venueLogoAdded" class="col-md-4 col-md-offset-4">\n' +
    '              <img ng-src="{{staticUrl}}{{duplicateevent.venueLogo}}" class="img-responsive" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <button class="btn btn-danger" ng-click="duplicateevent.selectLogoImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '            <button class="btn btn-danger" ng-click="duplicateevent.uploadLogoImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="!duplicateevent.venueLogoAdded && duplicateevent.stepThree">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Venue Logo is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="duplicateevent.spSocial">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Social Media Login</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="row">\n' +
    '          <div class="col-xs-12">\n' +
    '            <div class="col-sm-3" ng-repeat="social in duplicateevent.socialLogins">\n' +
    '              <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;" ng-click="duplicateevent.addOpenAuth(social.id)" ng-disabled="social.name ===\'Twilio\' && !duplicateevent.hasTwilio">\n' +
    '                <div class="content">\n' +
    '                  <span class="badge badgeSocial" ng-show="duplicateevent.openAuthList.indexOf(social.id) > -1"><i class="fa fa-check-circle-o fa-2x"></i></span>\n' +
    '                </div>\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="duplicateevent.openAuthList.length < 1 && duplicateevent.stepThree">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Please select social log in</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="duplicateevent.spVideo">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Video Url</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : duplicateevent.videoUrl.$error && duplicateevent.stepThree}">\n' +
    '          <input type="text" class="form-control" id="videoUrl" ng-model="duplicateevent.event.splashDetails.videoUrl" name="videoUrl" placeholder="eg. http://mysite.com" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="duplicateevent.videoUrl.$error" ng-show="duplicateevent.videoUrl.$error && duplicateevent.stepThree">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Video Url is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="duplicateevent.spAmount">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Amount</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="input-group" ng-class="{ \'has-error\' : duplicateevent.amount.$error && duplicateevent.stepThree}">\n' +
    '          <div class="input-group-addon">&euro;</div>\n' +
    '          <input type="number" min="0" step="1" class="form-control" id="amount" ng-model="duplicateevent.event.splashDetails.amount" name="amount" placeholder="0" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="duplicateevent.amount.$error" ng-show="duplicateevent.amount.$error && duplicateevent.stepThree">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Amount is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="duplicateevent.spAmount">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Duration (hrs)</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="input-group" ng-class="{ \'has-error\' : duplicateevent.period.$error && duplicateevent.stepThree}">\n' +
    '          <input type="number" min="0" step="1" class="form-control" id="period" ng-model="duplicateevent.newEvent.splashDetails.period" name="period" placeholder="0" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="duplicateevent.amount.$error" ng-show="duplicateevent.period.$error && duplicateevent.stepThree">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Duration is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="duplicateevent.spSocial">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Social Media Settings</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : duplicateevent.stepThree}">\n' +
    '          <h5>\n' +
    '            <input type="checkbox" id="addlikebutton" name="addlikebutton" ng-model="duplicateevent.addlikebutton" /> Facebook Like Button </h5>\n' +
    '          <div ng-show="duplicateevent.addlikebutton">\n' +
    '            <!-- <div class="row" style="border-bottom: none; padding: 5px">\n' +
    '              <div class="col-xs-6">\n' +
    '                <div style="margin-top: 5px">\n' +
    '                  <input type="checkbox" id="defaultFbLikeUrl" name="defaultFbLikeUrl" ng-model="duplicateevent.defaultFbLikeUrl" ng-change="duplicateevent.event.splashdetails.customFbLikeUrl = \'\';" /> Use Default.</div>\n' +
    '              </div>\n' +
    '              <div class="col-xs-6">\n' +
    '                <input type="url" class="form-control pull-right" id="customFbLikeUrl" ng-model="duplicateevent.event.splashdetails.customFbLikeUrl" name="customFbLikeUrl" placeholder="Custom Facebook Page Url" ng-change="duplicateevent.defaultFbLikeUrl = false;">\n' +
    '              </div>\n' +
    '            </div> -->\n' +
    '            <div class="row" style="border-bottom: none; padding: 5px" ng-show="duplicateevent.venue.customFbLikeUrl">\n' +
    '              <div class="col-xs-12">\n' +
    '                <div style="margin-top: 5px"><input type="checkbox" id="defaultFbLikeUrl" name="defaultFbLikeUrl" ng-model="duplicateevent.defaultFbLikeUrl" ng-change="duplicateevent.customFbLike = false;"/> Default - {{duplicateevent.venue.customFbLikeUrl}}</div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row" style="border-bottom: none; padding: 5px">\n' +
    '              <div class="col-xs-4" ng-show="duplicateevent.venue.customFbLikeUrl">\n' +
    '                <div style="margin-top: 5px"><input type="checkbox" id="customFbLike" name="customFbLike" ng-model="duplicateevent.customFbLike" ng-change="duplicateevent.defaultFbLikeUrl = false;"/> Custom.</div>\n' +
    '              </div>\n' +
    '              <div class="col-xs-8" ng-hide="duplicateevent.defaultFbLikeUrl">\n' +
    '                <input type="url" class="form-control pull-right" id="customFbLikeUrl" ng-model="duplicateevent.event.splashdetails.customFbLikeUrl" name="customFbLikeUrl" placeholder="Custom Facebook Page Url" ng-change="duplicateevent.defaultFbLikeUrl = false;">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row" style="border-bottom: none; padding: 5px">\n' +
    '              <div class="col-xs-offset-6 col-xs-6">\n' +
    '                <div ng-show="!createEvent.customFbLikeUrl.$valid && eventcreation.stepThree" class="pull-right">\n' +
    '                  <p class="text-danger">\n' +
    '                    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                    <strong> Custom Url must be a valid url. Example https://www.facebook.com/Liquidedge/</strong>\n' +
    '                  </p>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div class="row" style="border-bottom: none; padding: 5px">\n' +
    '              <div class="col-xs-offset-6 col-xs-6">\n' +
    '                <div ng-show="!createEvent.customFbLikeUrl.$valid && duplicateevent.stepThree" class="pull-right">\n' +
    '                  <p class="text-danger">\n' +
    '                    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                    <strong> Custom Url must be a valid url. Example https://www.facebook.com/Liquidedge/</strong>\n' +
    '                  </p>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <!-- <div class="panel panel-default panel-form" ng-show="duplicateevent.isMagnet">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>County Dropdown</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <label>\n' +
    '        <input type="checkbox" ng-model="duplicateevent.event.splashdetails.countyDropDown"\n' +
    '           ng-true-value="true" ng-false-value="false">\n' +
    '           Include County Dropdown\n' +
    '        </label>\n' +
    '      </div>\n' +
    '    </div> -->\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Redirection Url</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : duplicateevent.redirection.$error && duplicateevent.stepThree}">\n' +
    '          <input type="text" class="form-control" id="redirectionURL" ng-model="duplicateevent.event.redirectionurl" name="redirection" placeholder="eg. http://mysite.com" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="duplicateevent.redirection.$error" ng-show="duplicateevent.redirection.$error && duplicateevent.stepThree">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Redirection Url is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="col-xs-9 col-sm-6 col-md-5 col-md-offset-1">\n' +
    '    <h3 class="text-center">Splash Page</h3>\n' +
    '    <div class="col-md-10 col-md-offset-1">\n' +
    '      <div class="splashBackground" ng-style="{ \'background-image\': \'url(\'+ staticUrl + duplicateevent.bckgrndImg + \')\' }">\n' +
    '        <div class="content">\n' +
    '          <div class="row venueLogo">\n' +
    '            <div class="col-xs-8 col-xs-offset-2" ng-show="duplicateevent.venueLogoAdded">\n' +
    '              <img ng-src="{{staticUrl}}{{duplicateevent.venueLogo}}" class="img-responsive" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row login-mod well well-sm" ng-show="duplicateevent.bckgrndImgAdded && duplicateevent.spEmail">\n' +
    '            <div class="scaled">\n' +
    '              <p class="railtext"> Connect to our FREE WIFI with </p>\n' +
    '              <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>\n' +
    '                <div class="form-group" ng-class="{ \'has-error\' : loginForm.email.$error && authentication.formSubmitted }">\n' +
    '                  <input type="email" name="email" class="form-control" placeholder="Email">\n' +
    '                </div>\n' +
    '                <div class="form-group railtext">\n' +
    '                  <input type="checkbox" ng-model="authentication.terms" required name="terms">\n' +
    '                  <a ng-click="">Terms and Conditions</a>\n' +
    '                </div>\n' +
    '                <div class="row text-center">\n' +
    '                  <div class="col-md-12 col-xs-12 col-sm-12">\n' +
    '                    <button type="submit" class="btn btn-danger">\n' +
    '                      CONNECT\n' +
    '                    </button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </form>\n' +
    '              <div class="leLogo">\n' +
    '                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row video-mod" ng-show="duplicateevent.bckgrndImgAdded && duplicateevent.spVideo">\n' +
    '            <div class="scaled">\n' +
    '              <div class="leLogo">\n' +
    '                <img class="img-responsive" src="./images/youtube.jpg" alt="Youtube Video">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="footer-mod" ng-show="duplicateevent.bckgrndImgAdded && duplicateevent.spVideo">\n' +
    '            <div class="centered">\n' +
    '              <strong class="railtext"> Connect to our FREE WIFI with </strong>\n' +
    '              <div class="btn-deck">\n' +
    '                <button class="btn btn-danger btn-xs" ng-click="">\n' +
    '                  More Info\n' +
    '                </button>\n' +
    '                <button class="btn btn-info btn-xs" ng-click="">\n' +
    '                  No, Thanks\n' +
    '                </button>\n' +
    '              </div>\n' +
    '              <div class="leLogo">\n' +
    '                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="footer-mod" ng-show="duplicateevent.bckgrndImgAdded && duplicateevent.spLink">\n' +
    '            <div class="centered">\n' +
    '              <strong class="railtext"> Connect to our FREE WIFI with </strong>\n' +
    '              </br>\n' +
    '              <button class="btn btn-danger btn-deck btn-xs" ng-click="">\n' +
    '                CONNECT\n' +
    '              </button>\n' +
    '              <div class="leLogo">\n' +
    '                <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="footer-mod" ng-show="duplicateevent.bckgrndImgAdded && duplicateevent.spSocial">\n' +
    '            <div class="socialCentred">\n' +
    '              <div class="DocumentList">\n' +
    '                <strong class="railtext"> Connect to our FREE WIFI with </strong>\n' +
    '                <div class="row">\n' +
    '                  <div class="socIconsList" ng-repeat="social in duplicateevent.socialLogins" ng-show="duplicateevent.openAuthList.indexOf(social.id) > -1">\n' +
    '                    <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;">\n' +
    '                      <div class="content">\n' +
    '                      </div>\n' +
    '                    </button>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <div class="leLogo">\n' +
    '                  <img class="img-responsive" src="./images/magnetnetworks/magnet_logo_brang.png" alt="Liquidedge">\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '<div class="panel-footer">\n' +
    '  <div class="pull-right">\n' +
    '    <button class="btn btn-default" ng-click="duplicateevent.cancel()">Cancel</button>\n' +
    '    <button class="btn btn-default btn-danger" ng-click="duplicateevent.nextStep({splash : createEvent.redirection.$error }, \'admin.duplicateevent.confirm\')">Next Step</button>\n' +
    '  </div>\n' +
    '  <div class="clearfix"></div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('duplicateevent/views/duplicatesetup.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-11 col-sm-7 col-ms-7">\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Name &amp; Duration</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : createEvent.eventname.$error && duplicateevent.stepOne}">\n' +
    '          <label for="eventname" class="control-label pull-left">EVENT NAME</label>\n' +
    '          <input type="text" class="form-control" id="eventname" name="eventname" ng-model="duplicateevent.event.name" placeholder="eg. Big Match Day" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="createEvent.eventname.$error" ng-show="createEvent.eventname.$error && duplicateevent.stepOne">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> EVENT NAME is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : createEvent.eventdescription.$error && duplicateevent.stepOne}">\n' +
    '          <label for="eventdescription" class="control-label pull-left">EVENT DESCRIPTION</label>\n' +
    '          <input type="text" class="form-control" id="eventdescription" name="eventdescription" ng-model="duplicateevent.event.description" placeholder="eg. Big Match Day" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="createEvent.eventdescription.$error" ng-show="createEvent.eventdescription.$error && duplicateevent.stepOne">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> EVENT DESCRIPTION is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '        <div class="form-row">\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="startdate" class="control-label pull-left">START DATE</label>\n' +
    '            <input type="text" class="form-control" min-date="duplicateevent.minDate" name="startDate" datepicker-popup="{{duplicateevent.dateFormat}}" ng-model="duplicateevent.event.starttime" is-open="duplicateevent.status.openedSt" datepicker-options="duplicateevent.dateOptions" ng-required="true" close-text="Close" />\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <button type="button" class="btn btn-date" ng-click="duplicateevent.openCalSt()"><i class="glyphicon glyphicon-calendar"></i></button>\n' +
    '          </div>\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="enddate" class="control-label pull-left">END DATE</label>\n' +
    '            <input type="text" class="form-control" min-date="duplicateevent.minDate" datepicker-popup="{{duplicateevent.dateFormat}}" ng-model="duplicateevent.event.endtime" is-open="duplicateevent.status.openedEnd" datepicker-options="duplicateevent.dateOptions" ng-required="true" close-text="Close" />\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <button type="button" class="btn btn-date" ng-click="duplicateevent.openCalEnd()"><i class="glyphicon glyphicon-calendar"></i></button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="form-row">\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="starttime" class="control-label pull-left timelable">START TIME</label>\n' +
    '            <timepicker ng-model="duplicateevent.event.starttime" hour-step="duplicateevent.hstep" minute-step="duplicateevent.mstep" show-meridian="ismeridian" show-spinners="true"></timepicker>\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <span class="glyphicon glyphicon-time"></span>\n' +
    '          </div>\n' +
    '          <div class="form-group text-box long cell cell-pad">\n' +
    '            <label for="endtime" class="control-label pull-left timelable">END TIME</label>\n' +
    '            <timepicker ng-model="duplicateevent.event.endtime" hour-step="duplicateevent.hstep" minute-step="duplicateevent.mstep" show-meridian="ismeridian" show-spinners="true"></timepicker>\n' +
    '          </div>\n' +
    '          <div class="short cell">\n' +
    '            <span class="glyphicon glyphicon-time"></span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div>\n' +
    '          <p class="text-danger" ng-show="duplicateevent.dateError">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>Start Date/End Date Are Required Fields</strong>\n' +
    '          </p>\n' +
    '          <p class="text-danger" ng-show="duplicateevent.stTimeInPast">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>Start Date/Time cannot be in the past</strong>\n' +
    '          </p>\n' +
    '          <p class="text-danger" ng-show="duplicateevent.endTimeInPast">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>End Date/Time cannot be in the past</strong>\n' +
    '          </p>\n' +
    '          <p class="text-danger" ng-show="duplicateevent.endBeforeStart">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>End Date/Time cannot be prior to Start Date/Time</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>EVENT IMAGE</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group">\n' +
    '          <div class="row">\n' +
    '            <div ng-show="duplicateevent.eventImageAdded" class="col-md-4 col-md-offset-4">\n' +
    '              <img ng-src="{{staticUrl}}{{duplicateevent.eventImage}}" class="img-responsive" />\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="row">\n' +
    '            <button class="btn btn-danger" ng-click="duplicateevent.selectEventImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '            <button class="btn btn-danger" ng-click="duplicateevent.uploadEventImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="!duplicateevent.eventImageAdded && duplicateevent.stepOne">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong> Event Image is required</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-8 col-sm-5 col-ms-5">\n' +
    '    <h3>Make your WiFi work for you!</h3>\n' +
    '    <p>Set up fast, secure WiFi the way you want it. Limit access time, allow or deny access to any site you want and set user bandwidth to prevent users hogging the network</p>\n' +
    '    <!-- <a href="">Need Help?</a> -->\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '<div class="panel-footer">\n' +
    '  <div class="pull-right">\n' +
    '    <button class="btn btn-default" ng-click="duplicateevent.cancel()">Cancel</button>\n' +
    '    <button class="btn btn-default btn-danger" ng-click="duplicateevent.nextStep({setUp : createEvent.eventname.$error}, \'admin.duplicateevent.duplicatenetwork\')">Next Step</button>\n' +
    '  </div>\n' +
    '  <div class="clearfix"></div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('duplicateevent/views/duplicatenetwork.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-11 col-sm-7 col-ms-7">\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Network Name</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : createEvent.networkName.$error && duplicateevent.stepTwo}">\n' +
    '          <input type="text" class="form-control" id="networkname" name="networkName" ng-model="duplicateevent.event.ssidname" ng-pattern="/^[a-zA-Z0-9]*$/" placeholder="eg. nowhitespace" required>\n' +
    '        </div>\n' +
    '        <div ng-messages="createEvent.networkName.$error" ng-show="createEvent.networkName.$error && duplicateevent.stepTwo">\n' +
    '          <p class="text-danger" ng-message="required">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Network Name is required</strong>\n' +
    '          </p>\n' +
    '          <p class="text-danger" ng-message="pattern">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Network Name must not contain spaces</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '        <div ng-show="duplicateevent.ssidExists && duplicateevent.stepTwo">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>Network Name clash during selected time period</strong>\n' +
    '            <button class="btn btn-default btn-primary pull-right" ng-click="duplicateevent.nameCheckResult()"><i class="fa fa-question-circle-o"></i>  More Info</button>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Network Type</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <select class="form-control" ng-model="duplicateevent.event.networkType" ng-change="duplicateevent.setNetworkType(duplicateevent.event.networkType)" ng-options="type.id as type.name for type in duplicateevent.networkTypes">\n' +
    '        </select>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="duplicateevent.provider === 1">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Network Optimization</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <ul class="">\n' +
    '          <li class="checkbox" ng-repeat="category in duplicateevent.optCatList">\n' +
    '            <label>\n' +
    '              <input type="checkbox" checklist-model="duplicateevent.categories" checklist-value="category.id"> {{category.name}}\n' +
    '              <a href="" tooltip-placement="right" tooltip="{{category.description}}"> <i class="fa fa-question-circle"></i></a>\n' +
    '            </label>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form" ng-show="duplicateevent.provider === 1">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Set Bandwidth Limit</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div>\n' +
    '          <rzslider rz-slider-model="duplicateevent.priceSlider.value" rz-slider-options="duplicateevent.priceSlider.options"></rzslider>\n' +
    '          <p ng-show="duplicateevent.priceSlider.value === 0">Setting Zero gives the user unlimited bandwidth. </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Network Zones</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <ul class="">\n' +
    '          <li class="checkbox" dir-paginate="zone in duplicateevent.zoneList | orderBy: \'name\' | filter:zoneSearch | itemsPerPage: 10">\n' +
    '            <label>\n' +
    '              <input type="checkbox" checklist-model="duplicateevent.zones" checklist-value="zone.id"> {{zone.name}}\n' +
    '            </label>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '        <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>\n' +
    '        <div ng-show="duplicateevent.zones.length < 1 && duplicateevent.stepTwo">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>\n' +
    '              Please select at least one zone</strong>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '        <div ng-show="duplicateevent.ssidCount && duplicateevent.stepTwo">\n' +
    '          <p class="text-danger">\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <strong>Network count exceeded</strong>\n' +
    '            <button class="btn btn-default btn-primary pull-right" ng-click="duplicateevent.ssidCountResult()"><i class="fa fa-question-circle-o"></i>  More Info</button>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-8 col-sm-5 col-ms-5">\n' +
    '    <h3>Managing Application Settings</h3>\n' +
    '    <p>Optimize your WiFi network to suit your event</p>\n' +
    '    <a href="">Learn More</a>\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '<div class="panel-footer">\n' +
    '<div class="pull-right">\n' +
    '  <button class="btn btn-default" ng-click="duplicateevent.cancel()">Cancel</button>\n' +
    '  <button class="btn btn-default btn-danger" ng-click="duplicateevent.nextStep({network : createEvent.networkName.$error}, \'admin.duplicateevent.duplicatesplash\')">Next Step</button>\n' +
    '</div>\n' +
    '<div class="clearfix"></div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('duplicateevent/views/duplicateevent.tpl.html',
    '<form name="createEvent" novalidate>\n' +
    '  <h2 class="text-center">duplicate Event - {{duplicateevent.event.name}}</h2>\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <ul class="breadcrumb">\n' +
    '        <li ui-sref-active="active">\n' +
    '          <a ui-sref="admin.duplicateevent.duplicatesetup" ng-show="duplicateevent.setupPassed"><i class="fa fa-2x fa-sliders"></i></br>Setup</a>\n' +
    '          <a href="" ng-show="!duplicateevent.setupPassed" ng-click="duplicateevent.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, \'admin.duplicateevent.duplicatesetup\')"><i class="fa fa-2x fa-sliders"></i></br>Setup</a>\n' +
    '        </li>\n' +
    '        <li ui-sref-active="active">\n' +
    '          <a ui-sref="admin.duplicateevent.duplicatenetwork" ng-show="duplicateevent.netPassed"><i class="fa fa-2x fa-cogs"></i></br>Network</a>\n' +
    '          <a ng-show="!duplicateevent.netPassed" ng-click="duplicateevent.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, \'admin.duplicateevent.duplicatenetwork\')" href=""><i class="fa fa-2x fa-cogs"></i></br>Network</a>\n' +
    '        </li>\n' +
    '        <li ui-sref-active="active">\n' +
    '          <a ui-sref="admin.duplicateevent.duplicatesplash" ng-show="duplicateevent.splashPassed"><i class="fa fa-2x fa-mobile"></i></br>Splash Page</a>\n' +
    '          <a ng-show="!duplicateevent.splashPassed" ng-click="duplicateevent.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, \'admin.duplicateevent.duplicatesplash\')" href=""><i class="fa fa-2x fa-mobile"></i></br>Splash Page</a>\n' +
    '        </li>\n' +
    '        <li ui-sref-active="active" role="presentation">\n' +
    '          <a ui-sref="admin.duplicateevent.confirm" ng-hide="true"><i class="fa fa-2x fa-check-circle-o"></i></br>Confirm</a>\n' +
    '          <a href="" ng-click="duplicateevent.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, \'admin.duplicateevent.confirm\')"><i class="fa fa-2x fa-check-circle-o"></i></br>Confirm</a>\n' +
    '        </li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '    <div data-ui-view></div>\n' +
    '    </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('duplicateevent/views/duplicateconfirm.tpl.html',
    '<div class="row">\n' +
    '  <div ng-show="duplicateevent.setupPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confSuccess">\n' +
    '        <i class="fa fa-4x fa-check-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Setup</h4>\n' +
    '      <ul class="confUl">\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Event Name,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Start/End Date,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Event Image,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Access Points</li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.duplicateevent.duplicatesetup">edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div ng-hide="duplicateevent.setupPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confFail">\n' +
    '        <i class="fa fa-4x fa-times-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Setup</h4>\n' +
    '      <p>Setup Incomplete</p>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.duplicateevent.duplicatesetup">edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="row">\n' +
    '  <div ng-show="duplicateevent.netPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confSuccess">\n' +
    '        <i class="fa fa-4x fa-check-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Network</h4>\n' +
    '      <ul class="confUl">\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Network Name,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Network Optomization,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Network Bandwidth</li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.duplicateevent.duplicatenetwork">edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div ng-hide="duplicateevent.netPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confFail">\n' +
    '        <i class="fa fa-4x fa-times-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Network</h4>\n' +
    '      <p>Network Incomplete</p>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.duplicateevent.duplicatenetwork">edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="row">\n' +
    '  <div ng-show="duplicateevent.splashPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confSuccess">\n' +
    '        <i class="fa fa-4x fa-check-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Splash Page</h4>\n' +
    '      <ul class="confUl">\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Background Image,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Venue Logo,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Social Media,</li>\n' +
    '        <li>\n' +
    '          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Redirection Url</li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.duplicateevent.duplicatesplash">edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div ng-hide="duplicateevent.splashPassed">\n' +
    '    <div class="col-md-2">\n' +
    '      <div class="confFail">\n' +
    '        <i class="fa fa-4x fa-times-circle"></i>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-md-8">\n' +
    '      <h4 class="text-left">Splash Page</h4>\n' +
    '      <p>Splash Page Incomplete</p>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '      <button class="btn btn-button btn-block btn-select" ui-sref="admin.duplicateevent.duplicatesplash">edit</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '<div class="panel-footer">\n' +
    '  <div class="pull-right">\n' +
    '    <button class="btn btn-default" ng-click="duplicateevent.cancel()">Cancel</button>\n' +
    '    <button class="btn btn-default btn-danger" ng-click="duplicateevent.updateEvent()" ng-disabled="!duplicateevent.splashPassed || !duplicateevent.netPassed ||  !duplicateevent.setupPassed">Create Event</button>\n' +
    '  </div>\n' +
    '  <div class="clearfix"></div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('editap/views/editap.tpl.html',
    '<div id="addap">\n' +
    '  <div ng-show="editap.ErrorMessage">\n' +
    '    <div class="alert alert-danger" role="alert">\n' +
    '      <button ng-click="editap.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '        <span aria-hidden="true">&times;</span>\n' +
    '      </button>\n' +
    '      <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '      <span class="sr-only">Error:</span> {{editap.Message}}\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Edit Accesspoint</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createAccesspoint" class="col-md-8 col-md-offset-2" ng-submit="editap.updateAccesspoint(createAccesspoint.$valid)" role="form" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Name</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="name" name="name" ng-model="editap.accesspoint.name" placeholder="eg. name" ng-pattern="string" required>\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.name.$invalid && editap.submitted">\n' +
    '              <p>Name is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>MAC ADDRESS</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="hwAddr" name="hwAddr" ng-model="editap.accesspoint.hwAddr" placeholder="eg. 01:23:45:67:89:ab" ng-pattern="/^([a-f0-9]{2}\\:[a-f0-9]{2}\\:[a-f0-9]{2}\\:[a-f0-9]{2}\\:[a-f0-9]{2}\\:[a-f0-9]{2}$)/" required>\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.hwAddr.$invalid && editap.submitted">\n' +
    '              <p>Mac Address is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>ipAddress</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="ipAddress" name="ipAddress" ng-model="editap.accesspoint.ipAddress" placeholder="eg. 10.37.23.126" ng-pattern=\'/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/\' ng-model-options="{ updateOn: \'blur\' }" required>\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.ipAddress.$invalid && editap.submitted">\n' +
    '              <p>IP Address is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="editap.accesspoint.provider == 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>USERNAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="sshUsername" name="sshUsername" ng-model="editap.accesspoint.sshUsername" placeholder="eg. Accesspoint login username">\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.sshUsername.$invalid && editap.submitted">\n' +
    '              <p>USERNAME is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="editap.accesspoint.provider == 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>PASSWORD</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="password" class="form-control" id="sshPassword" name="sshPassword" ng-model="editap.accesspoint.sshPassword" placeholder="eg. Accesspoint login password">\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.sshPassword.$invalid && editap.submitted">\n' +
    '              <p>PASSWORD is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="pull-right">\n' +
    '          <button type="submit" class="btn btn-default " onclick="history.back()">Cancel</button>\n' +
    '          <button type="submit" class="btn btn-default btn-danger">Submit</button>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('cloudservices/views/clservicedeletemodal.tpl.html',
    '<div class="modal-header">\n' +
    '  <img class="modal-img" src="./images/login/modal_image.png">\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <h4>Are you sure you want to delete this Service?</h4>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-danger" type="button"\n' +
    '  ng-click="clservicedeleteModal.ok()">Delete</button>\n' +
    '  <button class="btn btn-warning" type="button"\n' +
    '  ng-click="clservicedeleteModal.cancel()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('cloudservices/views/cloudservices.tpl.html',
    '<div class="panel panel-default admin">\n' +
    '  <div class="panel-heading">\n' +
    '    <div class="row row-align">\n' +
    '      <h3>Cloud Service\n' +
    '        <button class="btn btn-md btn-danger pull-right"\n' +
    '        data-ui-sref="admin.addclservice({venueid: cloudservices.venueid})"\n' +
    '        tooltip-placement="left" tooltip="Add CloudService">\n' +
    '          <i class="fa fa-plus" aria-hidden="true"></i>\n' +
    '        </button>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="row tabrow">\n' +
    '      <div class="col-md-3 col-md-offset-9">\n' +
    '        <div class="input-group">\n' +
    '          <div class="input-group-addon">\n' +
    '            <i class="fa fa-search"></i>\n' +
    '          </div>\n' +
    '          <input type="text" class="form-control"\n' +
    '          placeholder="Search Service" ng-model="categorySearch">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <table class="table table-responsive">\n' +
    '      <tbody>\n' +
    '        <tr dir-paginate="services in cloudservices.cloudservices | filter:categorySearch | itemsPerPage: 10">\n' +
    '          <td>\n' +
    '            <h4>Type:</h4>\n' +
    '            <p>{{services.type}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <h4>APIKEY:</h4>\n' +
    '            <p>{{services.apikey}}</p>\n' +
    '          </td>\n' +
    '          <td>\n' +
    '            <div class="pull-right">\n' +
    '              <button class="btn btn-danger btn-md"\n' +
    '              ng-click="cloudservices.deleteEvent(services.id)" tooltip-placement="left" tooltip="Delete Cloud Service">\n' +
    '                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>\n' +
    '              </button>\n' +
    '              <a class="btn btn-danger btn-md"\n' +
    '              ui-sref="admin.editclservice({venueid: cloudservices.venueid, orgid: cloudservices.orgid, cloudservicesid: services.id})" tooltip-placement="left" tooltip="Edit Cloud Service">\n' +
    '                <i class="fa fa-pencil-square-o"></i>\n' +
    '              </a>\n' +
    '            </div>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('addzone/views/addzone.tpl.html',
    '<div id="addzone">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Create Zone</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createzone" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>ZONE NAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="zonename" name="zonename" ng-model="addzone.zone.name" placeholder="eg. Some zone" required>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>DESCRIPTION</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="details" name="details" ng-model="addzone.zone.details.description" placeholder="eg. zone covers conference centre main suite" required>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="addzone.provider == 2 || addzone.provider == 6">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4 ng-show="addzone.provider == 2">WLAN Group</h4>\n' +
    '            <h4 ng-show="addzone.provider == 6">Zone UUID</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input ng-if="addzone.provider == 2" type="text" class="form-control" id="details" name="details" ng-model="addzone.zone.details.wlangroup" placeholder="eg. default">\n' +
    '              <input ng-if="addzone.provider == 6" type="text" class="form-control" id="details" name="details" ng-model="addzone.zone.details.siteid" placeholder="eg. default">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Accesspoints</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <ul id="double">\n' +
    '                <li class="iplisting" dir-paginate="ap in addzone.accesspoints | orderBy: \'name\'| filter:apSearch | itemsPerPage: 2500">\n' +
    '                  <label>\n' +
    '                    <input type="checkbox" checklist-model="addzone.selectedAccesspoints" checklist-value="ap.id"> {{ap.name}}\n' +
    '                  </label>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '            <!-- <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls> -->\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="addzone.createZone()" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('admin/views/admin.tpl.html',
    '<div id="wrapper" ng-class="{\'toggled\' : toggle}">\n' +
    '  <!-- Sidebar -->\n' +
    '  <div class="hidden-xs">\n' +
    '    <div id="sidebar-wrapper">\n' +
    '      <ul class="sidebar-nav">\n' +
    '        <li class="sidebar-header">\n' +
    '          <a data-ui-sref="admin.events">\n' +
    '            <!-- class="modal-img" -->\n' +
    '            <!-- <img src="./images/magnetnetworks/magnet_logo_brand_logo.png"> -->\n' +
    '            <!-- <div class="row">\n' +
    '                           \n' +
    '            </div> -->\n' +
    '          </a>\n' +
    '        </li>\n' +
    '        <li title="Events">\n' +
    '          <a data-ui-sref="admin.events">\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '                <i class="fa fa-calendar-o"></i>\n' +
    '              </div>\n' +
    '              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '                Events\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </a>\n' +
    '        </li>\n' +
    '        <li title="Images">\n' +
    '          <a data-ui-sref="admin.manageimages">\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '                <i class="fa fa-picture-o"></i>\n' +
    '              </div>\n' +
    '              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '                Images\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </a>\n' +
    '        </li>\n' +
    '        <!--<li>\n' +
    '        <a href="">\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '              <i class="fa fa-bullhorn"></i>\n' +
    '            </div>\n' +
    '            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '              Ads\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </a>\n' +
    '      </li> -->\n' +
    '        <!--<li>\n' +
    '        <a data-ui-sref="admin.analytics">\n' +
    '          <div class="row">\n' +
    '            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '              <i class="fa fa-bar-chart"></i>\n' +
    '            </div>\n' +
    '            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '              Reports\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </a>\n' +
    '      </li> -->\n' +
    '        <li title="Operators" ng-hide="activeUser.role === 2">\n' +
    '          <a data-ui-sref="admin.operators">\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '                <i class="fa fa-user"></i>\n' +
    '              </div>\n' +
    '              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '                Operators\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </a>\n' +
    '        </li>\n' +
    '        <li title="Venues" ng-hide="activeUser.role === 2">\n' +
    '          <a data-ui-sref="admin.venues">\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '                <i class="fa fa-building"></i>\n' +
    '              </div>\n' +
    '              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '                Venues\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </a>\n' +
    '        </li>\n' +
    '        <li title="Infrastructures" ng-hide="activeUser.role === 2">\n' +
    '          <a data-ui-sref="admin.orgInfrastructures">\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '                <i class="fa fa-wifi"></i>\n' +
    '              </div>\n' +
    '              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '                Infrastructures\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </a>\n' +
    '        </li>\n' +
    '        <li title="Venue Analytics" ng-hide="activeUser.role === 1">\n' +
    '          <a data-ui-sref="admin.newvenueanalytics({venueid: activeUser.details.venue})">\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '                <i class="fa fa-line-chart"></i>\n' +
    '              </div>\n' +
    '              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '                {{activeUser.details.venueName}}\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </a>\n' +
    '        </li>\n' +
    '        <li title="Customer Segmentation" ng-hide="activeUser.role === 1">\n' +
    '          <a data-ui-sref="admin.tags({venueid: activeUser.details.venue})">\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '                <i class="fa fa-user-plus"></i>\n' +
    '              </div>\n' +
    '              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '                Segmentation\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </a>\n' +
    '        </li>\n' +
    '        <li title="terms">\n' +
    '          <a data-ui-sref="admin.tandceditor({venueid: activeUser.details.venue})">\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '                <i class="fa fa-file"></i>\n' +
    '              </div>\n' +
    '              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '                Terms &amp; Conditions\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </a>\n' +
    '        </li>\n' +
    '        <!-- <li>\n' +
    '          <a data-ui-sref="admin.tags">\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '                <i class="fa fa-bullhorn"></i>\n' +
    '              </div>\n' +
    '              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '                {{activeUser.details.venueName}}\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </a>\n' +
    '        </li> -->\n' +
    '        <!-- <li>\n' +
    '          <a href="">\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">\n' +
    '                <i class="fa fa-mobile fa-lg"></i>\n' +
    '              </div>\n' +
    '              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">\n' +
    '                Send Message\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </a>\n' +
    '        </li> -->\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <!-- /#sidebar-wrapper -->\n' +
    '  <!-- Page Content -->\n' +
    '  <div id="page-content-wrapper">\n' +
    '    <div class="hidden-xs">\n' +
    '      <nav class="navbar navbar-default navbar-static-top">\n' +
    '        <div class="container-fluid">\n' +
    '          <div class="navbar-header">\n' +
    '            <a class="navbar-brand" data-ng-init=\'toggle = false\' ng-click="toggle = !toggle" href="">\n' +
    '              <i class="fa fa-bars"></i>\n' +
    '            </a>\n' +
    '          </div>\n' +
    '          <ul class="nav navbar-nav navbar-right">\n' +
    '            <li dropdown is-open="it.isOpen" class="dropdownWidth">\n' +
    '              <a href="" dropdown-toggle ng-disabled="isDisabled" role="button">{{admin.profileInfo.name}}\n' +
    '              -\n' +
    '              <span ng-show="admin.profileInfo.role === 2">{{admin.profileInfo.details.venueName}}</span>\n' +
    '              <span ng-show="admin.profileInfo.role === 1">Admin</span>\n' +
    '              <span class="caret"></span>\n' +
    '            </a>\n' +
    '              <ul class="dropdown-menu options-menu">\n' +
    '                <li role="separator" class="divider"></li>\n' +
    '                <li>\n' +
    '                  <a href="" ui-sref="admin.editoperator({operatorid: admin.profileInfo.id})">\n' +
    '                    <i class="fa fa-user"></i> Profile\n' +
    '                  </a>\n' +
    '                </li>\n' +
    '                <li role="separator" class="divider"></li>\n' +
    '                <li>\n' +
    '                  <a href="" ng-click="admin.support(activeUser.details.venue)">\n' +
    '                    <i class="fa fa-pencil-square-o"></i> Support</a>\n' +
    '                </li>\n' +
    '                <li role="separator" class="divider"></li>\n' +
    '                <li>\n' +
    '                  <a href="" ng-click="admin.logout()">\n' +
    '                    <i class="fa fa-sign-out"></i> Log Out</a>\n' +
    '                </li>\n' +
    '                <li role="separator" class="divider"></li>\n' +
    '              </ul>\n' +
    '            </li>\n' +
    '            <!--<li class="profileCircle">\n' +
    '            <img class="img-circle img-responsive" alt="Brand" ng-src="{{admin.profileInfo.userImg}}">\n' +
    '          </li> -->\n' +
    '          </ul>\n' +
    '        </div>\n' +
    '      </nav>\n' +
    '    </div>\n' +
    '    <div class=" hidden-sm hidden-md hidden-lg">\n' +
    '      <div class="navbar navbar-default">\n' +
    '        <div class="row">\n' +
    '          <div class="col-xs-8">\n' +
    '            <div class="navbar-header">\n' +
    '              <a class="navbar-brand no-padding" data-ui-sref="admin.events">\n' +
    '                <img class="img-responsive" alt="Liquidedge" src="./images/magnetnetworks/magnet_logo_brand.png">\n' +
    '              </a>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="col-xs-2 col-xs-offset-2">\n' +
    '            <div class="btn-group" dropdown is-open="admin.status.isopen">\n' +
    '              <a href="" id="single-button" class="sm-toggle" dropdown-toggle ng-disabled="disabled">\n' +
    '                <i class="fa fa-bars fa-2x" aria-hidden="true"></i>\n' +
    '              </a>\n' +
    '              <ul class="dropdown-menu" role="menu" aria-labelledby="single-button">\n' +
    '                <li role="menuitem">\n' +
    '                  <a href="" ui-sref="admin.editoperator({operatorid: admin.profileInfo.id})">\n' +
    '                    <i class="fa fa-user"></i> Profile\n' +
    '                  </a>\n' +
    '                </li>\n' +
    '                <li role="menuitem">\n' +
    '                  <a href="" ng-click="admin.support(activeUser.details.venue)">\n' +
    '                    <i class="fa fa-question" aria-hidden="true"></i> Support</a>\n' +
    '                </li>\n' +
    '                <li class="divider"></li>\n' +
    '                <li role="menuitem">\n' +
    '                  <a href="" ng-click="admin.logout()">\n' +
    '                    <i class="fa fa-sign-out"></i> Log Out</a>\n' +
    '                </li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="container-fluid">\n' +
    '      <div data-ui-view></div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="navbar navbar-default nav-btm navbar-fixed-bottom hidden-sm hidden-md hidden-lg">\n' +
    '    <div class="container">\n' +
    '      <div class="btn-group btn-group-justified" role="group" aria-label="...">\n' +
    '        <div class="btn-group" role="group" aria-label="...">\n' +
    '          <button type="button" class="btn btn-default" data-ui-sref="admin.events" ng-class="admin.getClass(\'/admin/events\')"><i class="fa fa-calendar-o"></i> Events</button>\n' +
    '        </div>\n' +
    '        <div class="btn-group" role="group" aria-label="..." ng-hide="activeUser.role === 1">\n' +
    '          <button type="button" class="btn btn-default" data-ui-sref="admin.newvenueanalytics({venueid: activeUser.details.venue})" ng-class="admin.getClass(\'/admin/newvenueanalytics\')"><i class="fa fa-bar-chart" aria-hidden="true"></i> Analytics</button>\n' +
    '        </div>\n' +
    '        <div class="btn-group" role="group" aria-label="..." ng-hide="activeUser.role === 2">\n' +
    '          <button type="button" class="btn btn-default" data-ui-sref="admin.venues" ng-class="admin.getClass(\'/admin/venues\')"><i class="fa fa-building"></i> Venues</button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('addvenuemap/views/addvenuemap.tpl.html',
    '<div class="addvenuemap">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <div class="row row-align">\n' +
    '        <h3>Add Venue Map</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-12">\n' +
    '          <div class="col-md-6">\n' +
    '            <h4>Map Name</h4>\n' +
    '            <input type="text" class="form-control" ng-model="addvenuemap.mapName" placeholder="eg. Floor One, Two, Three, Etc ..." required>\n' +
    '          </div>\n' +
    '          <div class="col-md-6">\n' +
    '            <h4>Add Map Image  </h4>\n' +
    '            <button class="btn btn-danger" ng-click="addvenuemap.uploadImage()" tooltip-placement="left" tooltip="Upload Image">\n' +
    '              <i class="fa fa-cloud-upload" aria-hidden="true"></i>\n' +
    '            </button>\n' +
    '            <br/>\n' +
    '            <br/>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel panel-default" ng-show="addvenuemap.backgroundSelected">\n' +
    '        <div class="panel-heading">\n' +
    '          <div class="row row-align">\n' +
    '            <h3>Floor Map</h3>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel-body">\n' +
    '          <div class="row">\n' +
    '            <div class="col-sm-12">\n' +
    '              <div class="col-sm-2">\n' +
    '                <h3>Accesspoints</h3>\n' +
    '                <ul style="list-style-type: none; list-style-position:inside; margin:0; padding:0;" ng-repeat="ap in addvenuemap.accesspoints">\n' +
    '                  <li>\n' +
    '                    <div id="{{ap.name}}|{{ap.hwaddr}}" class="apItem" style="border: 1px solid #000;padding: 5px; margin: 5px;" drag-canvas-aps>\n' +
    '                      <img  ng-hide="ap.ongraph" id="dragicon" class="pull-right" src="./images/apicon.png" alt="Smiley face" height="16" width="16">\n' +
    '                      <div>{{ap.name}}</div>\n' +
    '                      <div>{{ap.hwaddr}}</div>\n' +
    '                    </div>\n' +
    '                  </li>\n' +
    '                </ul>\n' +
    '              </div>\n' +
    '              <div class="col-sm-10">\n' +
    '                <div id="heatMapContainer" style="position: relative; height: {{ addvenuemap.mapHeight }}px;">\n' +
    '                  <canvas id="backDrop" style="position: absolute; left: 0; top: 0; z-index: 0;"></canvas>\n' +
    '                  <!-- <canvas id="heatMap" style="position: absolute; left: 0; top: 0; z-index: 1;"></canvas> -->\n' +
    '                  <canvas id="pointMap" style="position: absolute; left: 0; top: 0; z-index: 1;" drop-canvas-aps></canvas>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="col-sm-11">\n' +
    '          <button  ng-show="addvenuemap.backgroundSelected" type="submit" class="btn btn-danger pull-right" ng-click="addvenuemap.submit()">Submit</button>\n' +
    '        </div>\n' +
    '        <div class="col-sm-1">\n' +
    '          <button type="submit" class="btn btn-info pull-right" onclick="history.back()">Cancel</button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('addap/views/addap.tpl.html',
    '<div id="addap">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Create Accesspoint</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div ng-show="addap.ErrorMessage">\n' +
    '        <div class="alert alert-danger" role="alert">\n' +
    '          <button ng-click="addap.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '            <span aria-hidden="true">&times;</span>\n' +
    '          </button>\n' +
    '          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '          <span class="sr-only">Error:</span> {{addap.Message}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <form name="createAccesspoint" class="col-md-8 col-md-offset-2" ng-submit="addap.createAccesspoint(createAccesspoint.$valid)" role="form" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Name</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="name" name="name" ng-model="addap.accesspoint.name" placeholder="eg. name" ng-pattern="string" required>\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.name.$invalid && addap.submitted">\n' +
    '              <p>Name is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>MAC ADDRESS</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="hwAddr" name="hwAddr" ng-model="addap.accesspoint.hwAddr" placeholder="eg. 01:23:45:67:89:ab" ng-pattern="/^([a-f0-9]{2}\\:[a-f0-9]{2}\\:[a-f0-9]{2}\\:[a-f0-9]{2}\\:[a-f0-9]{2}\\:[a-f0-9]{2}$)/" required>\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.hwAddr.$invalid && addap.submitted">\n' +
    '              <p>Mac Address is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>ipAddress</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="ipAddress" name="ipAddress" ng-model="addap.accesspoint.ipAddress" placeholder="eg. 10.37.23.126" ng-pattern=\'/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/\' ng-model-options="{ updateOn: \'blur\' }" required>\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.ipAddress.$invalid && addap.submitted">\n' +
    '              <p>IP Address is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="addap.accesspoint.provider == 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>USERNAME</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="text" class="form-control" id="sshUsername" name="sshUsername" ng-model="addap.accesspoint.sshUsername" placeholder="eg. Accesspoint login username">\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.sshUsername.$invalid && addap.submitted">\n' +
    '              <p>USERNAME is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form" ng-show="addap.accesspoint.provider == 1">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>PASSWORD</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <input type="password" class="form-control" id="sshPassword" name="sshPassword" ng-model="addap.accesspoint.sshPassword" placeholder="eg. Accesspoint login password">\n' +
    '            </div>\n' +
    '            <div ng-show="createAccesspoint.sshPassword.$invalid && addap.submitted">\n' +
    '              <p>PASSWORD is required!\n' +
    '                <p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button type="submit" class="btn btn-danger">Submit</button>\n' +
    '        <button type="submit" class="btn btn-info " onclick="history.back()">Cancel</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('addsurvey/views/addsurvey.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-9 col-sm-6 col-ms-6">\n' +
    '    <div class="panel panel-default panel-form">\n' +
    '      <div class="panel-heading clearfix">\n' +
    '        <h4>Survey</h4>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="form-group" ng-class="{ \'has-error\' : eventcreation.stepThree}">\n' +
    '           Survey Enabled <input type="checkbox" id="surveyCheckbox" name="surveyCheckbox" ng-model="addsurvey.selectedSurvey"/>\n' +
    '        </div>\n' +
    '        <div class="form-group" ng-show="addsurvey.selectedSurvey">\n' +
    '          <div ng-repeat="survey in addsurvey.surveyObjects">\n' +
    '            <div class="panel panel-default panel-form" ng-show="addsurvey.surveyObjects[survey.id].show">\n' +
    '              <div class="panel-heading clearfix">\n' +
    '                <h5>Question:</h5>\n' +
    '                <input type="text" class="form-control" ng-model="addsurvey.surveyObjects[survey.id].survey.question" placeholder="eg. Which event are you on?" required>\n' +
    '              </div>\n' +
    '              <div class="panel-body">\n' +
    '                <div class="form-group">\n' +
    '                  <input type="text" class="form-control" ng-model="addsurvey.surveyObjects[survey.id].survey.answer1" placeholder="Option A">\n' +
    '                  <input type="text" class="form-control" ng-model="addsurvey.surveyObjects[survey.id].survey.answer2" placeholder="Option B">\n' +
    '                  <input type="text" class="form-control" ng-model="addsurvey.surveyObjects[survey.id].survey.answer3" placeholder="Option C">\n' +
    '                  <input type="text" class="form-control" ng-model="addsurvey.surveyObjects[survey.id].survey.answer4" placeholder="Option D">\n' +
    '               </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div >\n' +
    '            <button ng-if="addsurvey.numberOfQuestions < addsurvey.numberOfQuestionsTotal" ng-click="addsurvey.addQuestion()" class="btn btn-danger"><i class="fa fa-plus" aria-hidden="true"></i></button>\n' +
    '            <button ng-if="addsurvey.numberOfQuestions > 0" ng-click="addsurvey.removeQuestion()" class="btn btn-danger"><i class="fa fa-minus" aria-hidden="true"></i></button>\n' +
    '            <button ng-if="addsurvey.numberOfQuestions > 0" ng-click="addsurvey.createSurvey()" class="btn btn-danger">Submit</button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('add-infrastructure/views/add-infrastructure.tpl.html',
    '<div id="infra">\n' +
    '  <div class="panel panel-default evcConf">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Create Infrastructure</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <form name="createinfrastructure" class="col-md-8 col-md-offset-2" novalidate>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Provider</h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <select class="form-control" name="provider" ng-model="addInfrastructure.infrastructure.provider" ng-options="option.id as option.name for option in addInfrastructure.providerTypes" ng-change="addInfrastructure.setProvider(addInfrastructure.infrastructure.provider)"></select>\n' +
    '            <div ng-messages="createinfrastructure.provider.$error" ng-if="addInfrastructure.submitted">\n' +
    '              <p ng-message="required" class="text-danger">Provider is required</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="addInfrastructure.providerSelected">\n' +
    '          <div class="panel panel-default panel-form" ng-hide="addInfrastructure.infrastructure.provider === 1">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>Controller Address</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <input type="text" class="form-control" id="controlleraddress" name="controlleraddress" ng-model="addInfrastructure.infrastructure.controlleraddress" placeholder="eg. controller.example.com" required>\n' +
    '              </div>\n' +
    '              <div ng-messages="createinfrastructure.controlleraddress.$error" ng-if="addInfrastructure.submitted">\n' +
    '                <p ng-message="required" class="text-danger">Controller Address is required</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel panel-default panel-form" ng-hide="addInfrastructure.infrastructure.provider === 1 || addInfrastructure.infrastructure.provider === 4">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4 ng-if="addInfrastructure.infrastructure.provider === 3">Authentication</h4>\n' +
    '              <h4 ng-if="addInfrastructure.infrastructure.provider === 2">Site ID</h4>\n' +
    '              <h4 ng-if="addInfrastructure.infrastructure.provider === 5">API Key</h4>\n' +
    '              <h4 ng-if="addInfrastructure.infrastructure.provider === 6">Northbound Portal Interface Password</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <input type="text" class="form-control" id="authentication" name="authentication" ng-model="addInfrastructure.infrastructure.authentication" placeholder="">\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel panel-default panel-form" ng-hide="addInfrastructure.infrastructure.provider === 1">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>Username</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <input type="text" class="form-control" id="username" name="username" ng-model="addInfrastructure.infrastructure.username" placeholder="">\n' +
    '              </div>\n' +
    '              <div ng-messages="createinfrastructure.username.$error" ng-if="addInfrastructure.submitted">\n' +
    '                <p ng-message="required" class="text-danger">username is required</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel panel-default panel-form" ng-hide="addInfrastructure.infrastructure.provider === 1">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>Password</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <input type="password" class="form-control" id="password" name="password" ng-model="addInfrastructure.infrastructure.password" placeholder="">\n' +
    '              </div>\n' +
    '              <div ng-messages="createinfrastructure.password.$error" ng-if="addInfrastructure.submitted">\n' +
    '                <p ng-message="required" class="text-danger">Controller Address is required</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel panel-default panel-form">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>SSID Limit</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <input type="number" class="form-control" id="ssidlimit" name="ssidlimit" ng-model="addInfrastructure.infrastructure.ssidlimit" required>\n' +
    '              </div>\n' +
    '              <div ng-messages="createinfrastructure.ssidlimit.$error" ng-if="addInfrastructure.submitted">\n' +
    '                <p ng-message="required" class="text-danger">SSID Limit is required</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel panel-default panel-form" ng-hide="addInfrastructure.infrastructure.provider === 1 || addInfrastructure.infrastructure.provider === 2 || addInfrastructure.infrastructure.provider === 4 || addInfrastructure.infrastructure.provider === 5 || addInfrastructure.infrastructure.provider === 6">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>Community</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <input type="text" class="form-control" id="community" name="community" ng-model="addInfrastructure.infrastructure.details.communityId" placeholder="">\n' +
    '              </div>\n' +
    '              <div ng-messages="createinfrastructure.community.$error" ng-if="addInfrastructure.submitted">\n' +
    '                <p ng-message="required" class="text-danger">Community is required</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="panel panel-default panel-form" ng-hide="addInfrastructure.infrastructure.provider === 1 || addInfrastructure.infrastructure.provider === 2 || addInfrastructure.infrastructure.provider === 4 || addInfrastructure.infrastructure.provider === 3 || addInfrastructure.infrastructure.provider === 6">\n' +
    '            <div class="panel-heading clearfix">\n' +
    '              <h4>Location API Validator</h4>\n' +
    '            </div>\n' +
    '            <div class="panel-body">\n' +
    '              <div class="form-group">\n' +
    '                <input type="text" class="form-control" id="validator" name="validator" ng-model="addInfrastructure.infrastructure.details.validator" placeholder="">\n' +
    '              </div>\n' +
    '              <div ng-messages="createinfrastructure.validator.$error" ng-if="addInfrastructure.submitted">\n' +
    '                <p ng-message="required" class="text-danger">Validator is required</p>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <button ng-click="addInfrastructure.create(!createinfrastructure.$invalid)" class="btn btn-danger">Submit</button>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('addclservice/views/addclservice.tpl.html',
    '<div id="cloudservices">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="text-center">Add Cloud Service</h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <div class="col-md-8 col-md-offset-2">\n' +
    '        <div ng-show="addclservice.errorMessage">\n' +
    '          <div class="alert alert-danger" role="alert">\n' +
    '            <button ng-click="addclservice.dismiss()" type="button" class="close" aria-label="Close">\n' +
    '              <span aria-hidden="true">&times;</span>\n' +
    '            </button>\n' +
    '            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '            <span class="sr-only">Error: </span>\n' +
    '            {{addclservice.error}}\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="panel panel-default panel-form">\n' +
    '          <div class="panel-heading clearfix">\n' +
    '            <h4>Cloud Service Type </h4>\n' +
    '          </div>\n' +
    '          <div class="panel-body">\n' +
    '            <div class="form-group">\n' +
    '              <select name="type" id="type" ng-options="type.name for type in addclservice.cloudservicetypes track by type.id" ng-model="addclservice.selectedType" class="form-control">\n' +
    '              </select>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '          <div>\n' +
    '            <div ng-show="addclservice.selectedType.name === \'MailChimp\'">\n' +
    '              <div class="panel panel-default panel-form">\n' +
    '                <div class="panel-heading clearfix">\n' +
    '                  <h4>MailChimp Settings</h4>\n' +
    '                </div>\n' +
    '                <div class="panel-body">\n' +
    '                  <form name="mailchimpForm" novalidate>\n' +
    '                    <div class="form-group" ng-class="{ \'has-error\': mailchimpForm.apiKey.$invalid && addclservice.submitted }">\n' +
    '                      <h4>API KEY:</h4>\n' +
    '                      <input type="text" class="form-control" id="apiKey" name="apiKey" ng-model="addclservice.apiKey" placeholder="eg. xxxxxxxxxx-xyz" ng-change="addclservice.updateApiKey()" required>\n' +
    '                    </div>\n' +
    '                    <div class="help-block" ng-messages="mailchimpForm.apiKey.$error" ng-if="addclservice.submitted">\n' +
    '                      <p class="text-danger" ng-message="required"><strong>API KEY is required.</strong></p>\n' +
    '                    </div>\n' +
    '                    <div ng-show="addclservice.updatedApi">\n' +
    '                      <div class="alert alert-success" role="alert" style="width: 40%;">\n' +
    '                        <button ng-click="addclservice.dismiss();" type="button" class="close" aria-label="Close">\n' +
    '                          <span aria-hidden="true">&times;</span>\n' +
    '                        </button>\n' +
    '                        <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>\n' +
    '                        <span class="sr-only">Alert:</span>\n' +
    '                        {{addclservice.apiMessage}}\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                    <div class="form-group">\n' +
    '                      <div class="row">\n' +
    '                        <div class="col-xs-12">\n' +
    '                          <h4>Select Default List:</h4>\n' +
    '                          <p>Choose a mailing list from the dropdown menu or create a\n' +
    '                            <button ng-click="addclservice.createNewList()" type="button" class="btn btn-danger btn-sm">New List</button>\n' +
    '                          </p>\n' +
    '                          <select class="form-control" ng-model="addclservice.newListId">\n' +
    '                            <option ng-repeat="list in addclservice.lists" value="{{list.id}}">{{list.name}} - {{list.id}}</option>\n' +
    '                          </select>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </div>\n' +
    '                  </form>\n' +
    '                  <button class="btn btn-danger" ng-click="addclservice.createCloudService(!mailchimpForm.$invalid, {apikey: addclservice.apiKey, listidentifier: addclservice.newListId})">Submit</button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-show="addclservice.selectedType.name === \'Twilio\'">\n' +
    '              <form name="twilioForm" novalidate>\n' +
    '                <div class="panel panel-default panel-form">\n' +
    '                  <div class="panel-heading clearfix">\n' +
    '                    <h4>Twilio Settings</h4>\n' +
    '                  </div>\n' +
    '                  <div class="panel-body">\n' +
    '                    <div class="form-group" ng-class="{ \'has-error\': twilioForm.apiKey.$invalid && addclservice.submitted }">\n' +
    '                      <h4>AUTH TOKEN:</h4>\n' +
    '                      <input type="text" class="form-control" id="apiKey" name="apiKey" ng-model="addclservice.apiKey" placeholder="eg. xxxxxxxxxx" required>\n' +
    '                    </div>\n' +
    '                    <div class="help-block" ng-messages="twilioForm.apiKey.$error" ng-if="addclservice.submitted">\n' +
    '                      <p class="text-danger" ng-message="required"><strong>AUTH TOKEN is required.</strong></p>\n' +
    '                    </div>\n' +
    '                    <div class="form-group" ng-class="{ \'has-error\': twilioForm.sid.$invalid && addclservice.submitted }">\n' +
    '                      <h4>ACCOUNT SID:</h4>\n' +
    '                      <input type="text" class="form-control" id="sid" name="sid" ng-model="addclservice.accountsid" placeholder="eg. xxxxxxxxxx" required>\n' +
    '                    </div>\n' +
    '                    <div class="help-block" ng-messages="twilioForm.sid.$error" ng-if="addclservice.submitted">\n' +
    '                      <p class="text-danger" ng-message="required"><strong>ACCOUNT SID is required.</strong></p>\n' +
    '                    </div>\n' +
    '                    <div class="form-group" ng-class="{ \'has-error\': twilioForm.phone.$invalid && addclservice.submitted }">\n' +
    '                      <h4>ACCOUNT PHONE:</h4>\n' +
    '                      <input type="text" class="form-control" id="phone" name="phone" ng-model="addclservice.phonenumber" placeholder="eg. 353861212122" required>\n' +
    '                    </div>\n' +
    '                    <div class="help-block" ng-messages="twilioForm.phone.$error" ng-if="addclservice.submitted">\n' +
    '                      <p class="text-danger" ng-message="required"><strong>ACCOUNT PHONE is required.</strong></p>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </form>\n' +
    '              <button class="btn btn-danger" ng-click="addclservice.createCloudService(!twilioForm.$invalid, {apikey: addclservice.apiKey, accountsid: addclservice.accountsid, phonenumber: addclservice.phonenumber})">Submit</button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('leoperations').run(['$templateCache', function($templateCache) {
  $templateCache.put('directives/back-img-directive.tpl.html',
    '<div>{{backImg.name}}</div>');
}]);

(function () {
  'use strict';

  /* @ngdoc object
   * @name userprofile
   * @description
   *
   */
  angular
    .module('userprofile', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name userprofile.controller:UserprofileCtrl
   *
   * @description
   *
   */
  UserprofileCtrl.$inject = ["ApiAnalytic", "AnalyticsApi", "$stateParams"];
  angular
    .module('userprofile')
    .controller('UserprofileCtrl', UserprofileCtrl);

  function UserprofileCtrl(ApiAnalytic, AnalyticsApi, $stateParams) {
    var vm = this;
    vm.venueId = parseInt($stateParams.venueid);
    vm.userId = parseInt($stateParams.userid);
    vm.providerId = parseInt($stateParams.providerid);
    vm.firstSeen = '';
    vm.lastSeen = '';
    vm.user = {};
    vm.userJourney = [];
    vm.isLive = false;

    var checkUserOnline = function () {
      ApiAnalytic.checkUserOnline(vm.venueId, vm.providerId, {'macs':vm.user.usermac}).then(function (response) {
        vm.isLive = response.data.online;
        console.log(response.data.online);
      }, function (err) {
        console.log(err);
      });
    };

    vm.getUserProfile = function () {
      ApiAnalytic.getUserProfile(vm.venueId, vm.userId).then(function (response) {
        vm.user = response.data;
        vm.firstSeen = vm.user.logins[0].timestamp;
        vm.lastSeen = vm.user.logins[vm.user.logins.length - 1].timestamp;
        checkUserOnline();
        // console.log(response);
        console.log(vm.user);
      }, function (err) {
        console.log(err);
      });
    };

    vm.getUserProfile();

    vm.dateOf = function (utcDateStr) {
      return new Date(utcDateStr);
    };

    /* Access Points */

    vm.submit = function (macAddress, date) {
      // console.log('called Journey');
      AnalyticsApi.getUserJourney(vm.venueId, macAddress, (new Date(date)).getTime()).then(function (response) {
        // console.log(response);
        vm.userJourney = response.data.aps;
      }, function (err) {
        console.log(JSON.stringify(err));
      });
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name venues
   * @description
   *
   */
  angular
    .module('venues', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:VenuesCtrl
   *
   * @description
   *
   */
  VenuesCtrl.$inject = ["Auth", "Api", "$log", "$rootScope", "$state", "$modal"];
  angular
    .module('venues')
    .controller('VenuesCtrl', VenuesCtrl);

  function VenuesCtrl(Auth, Api, $log, $rootScope, $state, $modal) {

    if($rootScope.activeUser.role === 2){
      $state.go('admin.events');
    }
    var orgid = Auth.getAuthData().operator.organisation;
    var vm = this;
    vm.venues = [];
    vm.zones = [];
    vm.aps = [];
    vm.limits = [1, 5, 10, 15, 20, 50, 'all'];
    vm.all = 0;
    vm.limit = 0;

    vm.venueSelected = {
      selected: false,
      venue: 0,
      zone: ''
    };

    vm.newvenue = function() {
      var newModalInstance = $modal.open({
        templateUrl: 'venues/views/venuecreatemodal.tpl.html',
        controller: 'VenuecreatemodalCtrl as venueCreateModal'
      });

      newModalInstance.result.then(function(data) {
        if (data.choice) {
          var newModalInstance = $modal.open({
            templateUrl: 'modalservice/views/bulkVenueCreateModal.tpl.html',
            controller: 'BulkVenueCreateModalCtrl as bulkVenueCreateModal',
            resolve: {
              infrastructure: function(){
                return data.infrastructure;
              }
            }
          });
          newModalInstance.result.then(function() {
            var modalMessage = {
              ok: 'Ok',
              messageHeader: 'Your venue has been queued for creation, it will appear in the venue list on completion.',
            };

            var modalInstance = $modal.open({
              templateUrl: 'modalservice/views/modalservice.tpl.html',
              controller: 'ModalserviceCtrl as modalService',
              resolve: {
                message: function () {
                  return modalMessage;
                }
              }
            });
            modalInstance.result.then(function () {
              getVenues();
            });
          });
        } else {
          $state.go('admin.newvenue');
        }
      });

    };

    vm.getZones = function (id) {
      if (vm.venueSelected.venue === id) {
        vm.venueSelected.selected = false;
        vm.venueSelected.venue = 0;
        vm.venueSelected.zone = '';
        vm.zones =[];
        vm.aps = [];
      } else {
        vm.zones =[];
        vm.aps = [];
        Api.getZones(id).then(function (res) {
          vm.venueSelected.selected = true;
          vm.venueSelected.venue = id;
          vm.zones = res.data.zones;
          console.log(res);
        }, function(err){
          console.log(err);
        });
      }
    };

    vm.getAccessPoints = function (zone) {
      vm.aps = [];
      vm.venueSelected.zone = zone.name;
      Api.getApByZone(vm.venueSelected.venue, zone.id).then(function (res) {
        vm.aps = res.data.apoints;
      }, function(err){
        console.log(err);
      });
    };

    vm.setLimit = function (limit) {
      if (limit === 'all') {
        vm.limit = vm.all;
      }
    };

    vm.viewAnalytics = function(id) {
      $state.go('admin.venueanalytics', {
        venueid: id
      });
    };

    vm.viewNewAnalytics = function(id) {
      $state.go('admin.newvenueanalytics', {
        venueid: id
      });
    };

    vm.viewCustomerSeg = function(id) {
      $state.go('admin.tags', {
        venueid: id
      });
    };

    vm.manageNetwork = function(id) {
      $state.go('admin.managenetwork', {
        venueid: id,
        orgid: orgid
      });
    };

    vm.externalServices = function(id) {
      console.log('venueid: ' + id);
      $state.go('admin.cloudservices', {
        venueid: id
      });
    };

    vm.editvenue = function(id) {
      $state.go('admin.editvenue', {
        venueid: id
      });
    };

    vm.deleteVenue = function(id) {

      var newModalInstance = $modal.open({
        templateUrl: 'venues/views/deletevenuemodal.tpl.html',
        controller: 'DeletevenuemodalCtrl as deleteVenueModal'
      });

      newModalInstance.result.then(function() {
        Api.deleteVenue(id).success(function() {
          getVenues();
        }).error(function(err) {
          var failedModalInstance = $modal.open({
            templateUrl: 'venues/views/deletefailedmodal.tpl.html',
            controller: 'DeletevenuemodalCtrl as deleteVenueModal'
          });

          failedModalInstance.result.then(function() {
            $log.error(err);
          });
        });
      });
    };

    function getVenues() {
      Api.getVenues().success(function (data) {
        vm.venues = data.venues;
        vm.limit = vm.venues.length;
        vm.all = vm.venues.length;
        $log.info(vm.venues);
      }).error(function () {
        $log.info('get venues error');
      });
    }

    getVenues();
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:DeletevenuemodalCtrl
   *
   * @description
   *
   */
  VenuecreatemodalCtrl.$inject = ["$modalInstance", "$log", "Api"];
  angular
    .module('venues')
    .controller('VenuecreatemodalCtrl', VenuecreatemodalCtrl);

  function VenuecreatemodalCtrl($modalInstance, $log, Api) {
    var vm = this;
    vm.ctrlName = 'VenuecreatemodalCtrl';
    vm.infrastructures = [];
    vm.selected = '';
    vm.infrastructure = {};
    vm.disabled = true;
    vm.error = '';

    vm.getInfrastructures = function(){
      Api.getOrgInfrastructures().success(function(data) {
        vm.infrastructures = data.infrastructures;
      }).error(function() {
        $log.info('get Infrastructure error');
      });
    };

    vm.selectedInfrastructure = function(option){
      var infraIndex = 0;
      for(infraIndex = 0; infraIndex < vm.infrastructures.length; infraIndex++) {
        if (vm.infrastructures[infraIndex].id === option) {
          vm.infrastructure = vm.infrastructures[infraIndex];
        }
      }
      vm.disabled = false;
    };


    Api.getProviders().then(function(res){
      vm.providers = res.data.providers;
      console.log(vm.providers);
    }, function(err){
      console.log(err);
    });
    vm.matchProvider = function(input, id){
      var returning = false;
      vm.providers.forEach(function(item){
        if(item.id === id){
          if (item.name.toLowerCase() === input){
            returning = true;
          }
        }
      });
      return returning;
    };

    vm.getInfrastructures();

    vm.ok = function () {
      var data = {};
      if (vm.error.length > 1) {
        data = {
          choice: false
        };
        $modalInstance.close(data);
      } else {
        data = {
          choice: true,
          infrastructure: vm.infrastructure
        };
        if (vm.matchProvider('unifi', data.infrastructure.provider) || vm.matchProvider('ruckus', data.infrastructure.provider) || vm.matchProvider('xirrus', data.infrastructure.provider) || vm.matchProvider('meraki', data.infrastructure.provider)) {
          $modalInstance.close(data);
        } else {
          vm.error = 'Selected Infrastructure does not support bulk create would you like to continue with creating a venue ?';
        }
      }
    };
    vm.cancel = function () {
      $modalInstance.dismiss();
    };
    vm.no = function () {
      var data = {
        choice: false
      };
      $modalInstance.close(data);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:DeletevenuemodalCtrl
   *
   * @description
   *
   */
  DeletevenuemodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('venues')
    .controller('DeletevenuemodalCtrl', DeletevenuemodalCtrl);

  function DeletevenuemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'DeletevenuemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name uploadorgimg
   * @description
   *
   */
  angular
    .module('uploadorgimg', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name uploadorgimg.controller:UploadorgimgCtrl
   *
   * @description
   *
   */
  UploadorgimgCtrl.$inject = ["$modalInstance", "adminbase", "Auth", "Upload", "$timeout"];
  angular
    .module('uploadorgimg')
    .controller('UploadorgimgCtrl', UploadorgimgCtrl);

  function UploadorgimgCtrl($modalInstance, adminbase, Auth, Upload, $timeout) {

    var vm = this,
      token = '';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    var imgurl = adminbase + '/admin-web/v1' + '/images';

    vm.uploadFiles = function (file) {
      vm.f = file;
      if (file) {
        file.upload = Upload.upload({
          url: imgurl,
          data: {
            image: file,
            details: {
              description: vm.description
            }
          },
          headers: {
            'x-access-token': token
          }
        });
        file.upload.then(function (response) {
          $timeout(function () {
            file.result = response.data;
            vm.imageAdded = true;
            vm.image = response.data.imageURI;
            vm.imageID = response.data.id;
            $modalInstance.close(response.data);
          });
        }, function (response) {
          if (response.status > 0) {
            vm.errorMsg = response.status + ': ' + response.data;
            console.log(String(vm.errorMsg));
          }
        }, function (evt) {
          file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        });
      }
    };

    vm.exit = function () {
      $modalInstance.dismiss();
    };
    
    vm.dismiss = function () {
      vm.message = {};
      console.log('was called');
    };



  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name twilioModal
   * @description
   *
   */
  angular
    .module('twilioModal', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name twilioModal.controller:TwilioModalCtrl
   *
   * @description
   *
   */
  TwilioModalCtrl.$inject = ["$modalInstance", "config"];
  angular
    .module('twilioModal')
    .controller('TwilioModalCtrl', TwilioModalCtrl);

  function TwilioModalCtrl($modalInstance, config) {
    var vm = this;
    vm.config = config;
    console.log(vm.config);
    vm.message = '';
    vm.remainingLength = 140;
    vm.setLimit = function(){
      vm.remainingLength = 140 - vm.message.length;
    };


    vm.ok = function () {
      $modalInstance.close(vm.message);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

'use strict';

if (typeof module !== 'undefined') module.exports = simpleheat;

function simpleheat(canvas) {
    if (!(this instanceof simpleheat)) return new simpleheat(canvas);

    this._canvas = canvas = typeof canvas === 'string' ? document.getElementById(canvas) : canvas;

    this._ctx = canvas.getContext('2d');
    this._width = canvas.width;
    this._height = canvas.height;

    this._max = 1;
    this._data = [];
}

simpleheat.prototype = {

    defaultRadius: 25,

    defaultGradient: {
        0.4: 'blue',
        0.6: 'cyan',
        0.7: 'lime',
        0.8: 'yellow',
        1.0: 'red'
    },

    data: function (data) {
        this._data = data;
        return this;
    },

    max: function (max) {
        this._max = max;
        return this;
    },

    add: function (point) {
        this._data.push(point);
        return this;
    },

    clear: function () {
        this._data = [];
        return this;
    },

    radius: function (r, blur) {
        blur = blur === undefined ? 15 : blur;

        // create a grayscale blurred circle image that we'll use for drawing points
        var circle = this._circle = this._createCanvas(),
            ctx = circle.getContext('2d'),
            r2 = this._r = r + blur;

        circle.width = circle.height = r2 * 2;

        ctx.shadowOffsetX = ctx.shadowOffsetY = r2 * 2;
        ctx.shadowBlur = blur;
        ctx.shadowColor = 'black';

        ctx.beginPath();
        ctx.arc(-r2, -r2, r, 0, Math.PI * 2, true);
        ctx.closePath();
        ctx.fill();

        return this;
    },

    resize: function () {
        this._width = this._canvas.width;
        this._height = this._canvas.height;
    },

    gradient: function (grad) {
        // create a 256x1 gradient that we'll use to turn a grayscale heatmap into a colored one
        var canvas = this._createCanvas(),
            ctx = canvas.getContext('2d'),
            gradient = ctx.createLinearGradient(0, 0, 0, 256);

        canvas.width = 1;
        canvas.height = 256;

        for (var i in grad) {
            gradient.addColorStop(+i, grad[i]);
        }

        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, 1, 256);

        this._grad = ctx.getImageData(0, 0, 1, 256).data;

        return this;
    },

    draw: function (minOpacity) {
        if (!this._circle) this.radius(this.defaultRadius);
        if (!this._grad) this.gradient(this.defaultGradient);

        var ctx = this._ctx;

        ctx.clearRect(0, 0, this._width, this._height);

        // draw a grayscale heatmap by putting a blurred circle at each data point
        for (var i = 0, len = this._data.length, p; i < len; i++) {
            p = this._data[i];
            ctx.globalAlpha = Math.max(p[2] / this._max, minOpacity === undefined ? 0.05 : minOpacity);
            ctx.drawImage(this._circle, p[0] - this._r, p[1] - this._r);
        }

        // colorize the heatmap, using opacity value of each pixel to get the right color from our gradient
        var colored = ctx.getImageData(0, 0, this._width, this._height);
        this._colorize(colored.data, this._grad);
        ctx.putImageData(colored, 0, 0);

        return this;
    },

    _colorize: function (pixels, gradient) {
        for (var i = 0, len = pixels.length, j; i < len; i += 4) {
            j = pixels[i + 3] * 4; // get gradient color from opacity value

            if (j) {
                pixels[i] = gradient[j];
                pixels[i + 1] = gradient[j + 1];
                pixels[i + 2] = gradient[j + 2];
            }
        }
    },

    _createCanvas: function () {
        if (typeof document !== 'undefined') {
            return document.createElement('canvas');
        } else {
            // create a new canvas instance in node.js
            // the canvas class needs to have a default constructor without any parameter
            return new this._canvas.constructor();
        }
    }
};

(function () {
  'use strict';

  /* @ngdoc object
   * @name tags
   * @description
   *
   */
  angular
    .module('tags', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name tags.controller:TagupdateCtrl
   *
   * @description
   *
   */
  TagupdateCtrl.$inject = ["Tags", "data", "$modalInstance", "Api"];
  angular
    .module('tags')
    .controller('TagupdateCtrl', TagupdateCtrl);

  function TagupdateCtrl(Tags, data, $modalInstance, Api) {
    var vm = this;
    var tagid = data.tagid;
    var venueid = data.venueid;
    vm.oldTag = {};
    vm.newTag = {};
    vm.zones = [];
    vm.selectedZones = [];
    vm.oldZones = [];

    var getTag = function () {
      Tags.getTag(tagid).then(function (result) {
        vm.oldTag = JSON.parse(JSON.stringify(result.data));
        vm.newTag = JSON.parse(JSON.stringify(result.data));
        for (var i = 0; i < vm.newTag.zones.length; i++) {
          vm.selectedZones.push({
            'id': vm.newTag.zones[i].zoneId
          });
          vm.oldZones.push({
            'zoneId': vm.newTag.zones[i].zoneId
          });
        }
      });
    };

    var getZones = function () {
      Api.getZones(venueid).then(function (result) {
        console.log(result);
        vm.zones = result.data.zones;
        console.log(vm.zones);
      });
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

    vm.ok = function () {
      vm.newTag.zones = [];
      for (var i = 0; i < vm.selectedZones.length; i++) {
        vm.newTag.zones.push({
          zoneId: vm.selectedZones[i].id
        });
      }
      var update = {
        update: false,
        name: vm.newTag.name,
        description: vm.newTag.description,
        duration: vm.newTag.duration,
        zones: vm.newTag.zones
      };

      var tagid = vm.newTag._id;

      console.log(vm.newTag);
      console.log(JSON.stringify(vm.oldZones));
      console.log(JSON.stringify(vm.newTag.zones));

      console.log(JSON.stringify(vm.oldZones) === JSON.stringify(vm.newTag.zones));

      if(JSON.stringify(vm.oldZones) !== JSON.stringify(vm.newTag.zones)){
        update.update = true;
      }

      if(vm.oldTag.duration !== vm.newTag.duration){
        update.update = true;
      }

      var result ={
        tagid:tagid,
        tag: update
      };
      $modalInstance.close(result);
    };

    getTag();
    getZones();
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name tags.controller:TagsCtrl
   *
   * @description
   *
   */
  TagsCtrl.$inject = ["Tags", "$modal", "$q", "$stateParams", "$state", "Api", "Cloudservices"];
  angular
    .module('tags')
    .controller('TagsCtrl', TagsCtrl);

  function TagsCtrl(Tags, $modal, $q, $stateParams, $state, Api, Cloudservices) {

    var vm = this;
    vm.venueid = $stateParams.venueid;

    vm.chartColors = ['#b71c1c', '#1a237e', '#f57f17', '#004d40', '#212121', '#880e4f', '#bf360c', '#1b5e20', '#263238', '#4a148c', '#0277bd', '#3e2723', '#558b2f', '#616161', '#311b92', '#ffd740', '#c6ff00', '#607d8b', '#d50000', '#0288d1', '#ffca28', '#795548', '#cddc39', '#757575', '#9c27b0', '#26c6da', '#ff9100', '#d4e157', '#7c4dff'];

    vm.aps = [];
    vm.zones = [];
    vm.users = [];
    vm.tags = [];
    vm.selectedList = '';
    vm.socialtags = [];
    vm.selectFrom = [];
    vm.include = [];
    vm.exclude = [];
    vm.emailList = [];
    vm.mailChimpList = [];
    vm.apiKey = '';
    vm.gotemail = false;
    vm.barChart = {
      data: [],
      series: [],
      labels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: []
        }
      }
    };

    var getTags = function () {
      vm.barChart.series = [];
      vm.barChart.data = [];
      Tags.getTags(vm.venueid).then(function (result) {
        console.log(result);
        vm.tags = result.data.tags.slice();
        vm.selectFrom = result.data.tags.slice();
        if (vm.tags.length > 0) {
          var length = vm.tags.length;
          for (var i = 0; i < length; i++) {
            vm.barChart.series.push(vm.tags[i].name);
            vm.barChart.data.push([vm.tags[i].users.length]);
          }
        }
        if (vm.barChart.data.length <= 1) {
          vm.barChart.options.scales.xAxes.push({
            barPercentage: 0.3
          });
        } else {
          vm.barChart.options.scales.xAxes = [];
        }
      });
    };

    var getAccessPoints = function () {
      Api.getAccessPoints(vm.venueid).then(function (result) {
        console.log(result);
        vm.aps = result.data.apoints;
      });
    };

    var getZones = function () {
      Api.getZones(vm.venueid).then(function (result) {
        console.log(result);
        vm.zones = result.data.zones;
        console.log(vm.zones);
      });
    };

    var createTag = function (tag) {
      var thisTag = tag;
      Tags.createTag(thisTag).then(function (result) {
        console.log(result);
        getTags();
      });
    };

    var getMailChimpLists = function () {
      Cloudservices.getCloudServices(vm.venueid).then(function (result) {
        var services = result.data.cloudServices;
        var length = services.length;
        var apiKey = '';
        console.log(services);
        for (var i = 0; i < length; i++) {
          if (services[i].type === 'MailChimp') {
            apiKey = services[i].apikey;
            vm.apiKey = services[i].apikey;
            console.log(vm.apiKey);
            break;
          }
        }
        return Api.getLists(apiKey);
      }).then(function (result) {
        vm.mailChimpList = [];
        vm.mailChimpList = result.data.lists;
      });
    };

    vm.createTag = function () {

      var modalInstance = $modal.open({
        templateUrl: 'tags/views/createtag.tpl.html',
        controller: 'TagmodalCtrl as tagmodal',
        resolve: {
          'data': {
            'zones': vm.zones,
            'aps': vm.aps
          }
        }
      });

      modalInstance.result.then(function (result) {
        var tag = result;
        tag.venueId = vm.venueid;
        createTag(tag);
      }, function () {
        console.log('canceled');
      });
    };

    vm.deleteTag = function (tagid) {
      Tags.deleteTag(tagid).then(function (result) {
        console.log(result);
        getTags();
      });
    };

    var updateTag = function (tagid, tag) {
      Tags.updateTag(tagid, tag).then(function (result) {
        console.log(result);
        getTags();
      });
    };

    vm.venueanalytics = function (page) {
      $state.go('admin.newvenueanalytics', {
        'venueid': vm.venueid,
        'class': page
      });
    };

    vm.generateEmailList = function () {
      var body = {
        'include': [],
        'exclude': []
      };

      for (var i = 0; i < vm.include.length; i++) {
        body.include.push(vm.include[i]._id);
      }

      for (var j = 0; j < vm.exclude.length; j++) {
        body.exclude.push(vm.exclude[j]._id);
      }

      Tags.getEmailList(vm.venueid, body).then(function (result) {
        console.log(result.data);
        vm.emailList = result.data.slice();
        vm.gotemail = true;
      });
    };

    var testEmailList = function () {
      Tags.getMocks().then(function (result) {
        vm.emailList = result.data.slice();
        vm.gotemail = true;
      });
    };

    vm.updateTag = function (tagid) {
      var modalInstance = $modal.open({
        templateUrl: 'tags/views/tagupdate.tpl.html',
        controller: 'TagupdateCtrl as tagupdatemodal',
        resolve: {
          'data': {
            'tagid': tagid,
            'venueid': vm.venueid
          }
        }
      });

      modalInstance.result.then(function (result) {
        var tag = result.tag;
        var tagid = result.tagid;
        updateTag(tagid, tag);
      }, function () {
        console.log('canceled');
      });

    };

    vm.sendToList = function () {
      var length = vm.emailList.length;
      var body = {
        'list':{
          'operations': []
        }
      };
      for (var i = 0; i < length; i++) {
        var object = {
          'method': 'POST',
          'path': 'lists/' + vm.selectedList + '/members',
          'body': JSON.stringify({'email_address':vm.emailList[i].login,
          status: 'subscribed'})
        };
        body.list.operations.push(object);
      }
      console.log(body);
      Tags.bulkMailChimp(vm.selectedList, vm.apiKey, body).then(function (response) {
        console.log(response);
      });
    };

    vm.resetEmail = function () {
      vm.selectFrom = vm.tags.slice();
      vm.include = [];
      vm.exclude = [];
      vm.gotemail = false;
    };

    getTags();
    getAccessPoints();
    getZones();
    getMailChimpLists();
    // testEmailList();
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name tags.controller:TagmodalCtrl
   *
   * @description
   *
   */
  TagmodalCtrl.$inject = ["data", "$modalInstance"];
  angular
    .module('tags')
    .controller('TagmodalCtrl', TagmodalCtrl);

  function TagmodalCtrl(data, $modalInstance) {
    var vm = this;
    vm.zones = data.zones;
    vm.aps = data.aps;
    vm.tagDescrtiption = '';
    vm.tagDuration= 0;
    vm.zoneList = [];

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

    vm.ok = function(){
      var tag = {
        name: vm.tagName,
        description: vm.tagDescription,
        duration: vm.tagDuration,
        zones: vm.zoneList
      };
      $modalInstance.close(tag);
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name tandceditor
   * @description
   *
   */
  angular
    .module('tandceditor', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name tandceditor.controller:TandceditorCtrl
   *
   * @description
   *
   */
  TandceditorCtrl.$inject = ["$stateParams", "Auth", "Tandc"];
  angular
    .module('tandceditor')
    .controller('TandceditorCtrl', TandceditorCtrl);

  function TandceditorCtrl($stateParams, Auth, Tandc) {
    var vm = this;
    vm.venue = $stateParams.venueid || undefined;
    vm.htmlVariable = '';
    vm.edit = false;
    vm.tandcID;
    console.log(vm.venue);

    var orgid = Auth.getAuthData().operator.organisation;

    var checkTandC = function () {
      if (vm.venue) {
        Tandc.getTandCbyVenue(orgid, vm.venue).then(function (result) {
          console.log(result);
          vm.htmlVariable = result.data.tandc;
          vm.tandcID = result.data.id;
          if (result.status === 200 && result.data.tandc.length > 0) {
            vm.edit = true;
          }
          console.log(vm.edit);
          console.log(result);
        }, function (err) {
          console.log(err);
        });
      } else {
        Tandc.getTandCbyOrg(orgid).then(function (result) {
          vm.htmlVariable = result.data.tandc;
          vm.tandcID = result.data.id;
          if (result.status === 200 && result.data.tandc.length > 0) {
            vm.edit = true;
          }
          console.log(vm.edit);
          console.log(result);
        }, function (err) {
          console.log(err);
        });
      }
    };

    vm.createTandC = function () {
      var tandc = {
        org: orgid,
        tandc: vm.htmlVariable
      };

      if (vm.venue) {
        tandc.venue = vm.venue;
      }

      if (!vm.edit) {
        Tandc.createTandC(tandc).then(function (result) {
          console.log(result);
        }, function (err) {
          console.log(err);
        });
      } else {
        tandc.id = vm.tandcID;
        console.log(vm.tandc);
        Tandc.updateTandC(tandc).then(function (result) {
          console.log(result);
        }, function (err) {
          console.log(err);
        });
      }
    };

    vm.deleteTandC = function () {
      var tandc = {
        org: orgid,
        id: vm.tandcID
      };
      Tandc.deleteTandC(tandc).then(function (result) {
        vm.htmlVariable = '';
        vm.edit = false;
        console.log(result);
      }, function (err) {
        console.log(err);
      });
    };
    checkTandC();
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name selectimage
   * @description
   *
   */
  angular
    .module('selectimage', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name selectimage.controller:SelectimageCtrl
   *
   * @description
   *
   */
  SelectimageCtrl.$inject = ["$modalInstance", "Api", "venue"];
  angular
    .module('selectimage')
    .controller('SelectimageCtrl', SelectimageCtrl);

  function SelectimageCtrl($modalInstance, Api, venue) {
    var vm = this;


      Api.getImagesVenue(venue).success(function (data) {
        vm.imageList = data.images;
        // console.log(data.images);
      });
    

    vm.selectedImage = {};

    vm.ok = function () {
      $modalInstance.close(vm.selectedImage);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name support
   * @description
   *
   */
  angular
    .module('support', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name support.controller:SupportCtrl
   *
   * @description
   *
   */
  SupportCtrl.$inject = ["$rootScope", "$stateParams", "$state", "$log", "Api", "Auth"];
  angular
    .module('support')
    .controller('SupportCtrl', SupportCtrl);

  function SupportCtrl($rootScope, $stateParams, $state, $log, Api, Auth) {
    var vm = this;
    vm.ctrlName = 'SupportCtrl';
    vm.venueid = $stateParams.venueid;
    vm.venues = [];
    vm.Message = [];
    vm.ErrorMessage = false;
    vm.EmailConfirmMessage = false;
    vm.EmailErrorMessage = false;
    vm.EmailMessage = [];
    $rootScope.activeUser = '';
    vm.subbmited = false;

    vm.subjects = ['Dashboard', 'Splashpage', 'Other'];

    if (Auth.getAuthData()) {
      vm.profileInfo = Auth.getAuthData().operator;
      $rootScope.activeUser = Auth.getAuthData().operator;
    }
    //console.log('admin', vm.profileInfo.role);

    function getVenues() {
      Api.getVenues().success(function (data) {
        vm.venues = data.venues;
        vm.limit = vm.venues.length;
        vm.all = vm.venues.length;
      }).error(function () {
        $log.info('get venues error');
      });
    }
    getVenues();

    vm.createTicket = function (isvalid) {
      vm.submitted = true;
      var data = '';
      data = JSON.stringify({
        name: vm.name,
        email: vm.email,
        venue: vm.venue,
        subject: vm.subject,
        query: vm.query
      });
      console.log('data', data);
      if (isvalid) {
        Api.createTicket(data).success(function () {
          vm.EmailConfirmMessage = true;
          vm.EmailMessage = 'Email sent, thank you';
          $state.go('admin.support');
        }).error(function (err) {
          vm.ErrorMessage = true;
          vm.Message = err.message;
        });
      }
    };

    vm.dismissError = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name ppolicy
   * @description
   *
   */
  angular
    .module('ppolicy', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ppolicy.controller:PpolicyCtrl
   *
   * @description
   *
   */
  PpolicyCtrl.$inject = ["$rootScope"];
  angular
    .module('ppolicy')
    .controller('PpolicyCtrl', PpolicyCtrl);

  function PpolicyCtrl($rootScope) {
    var vm = this;
    vm.ctrlName = 'PpolicyCtrl';
    $rootScope.bodyClass = 'home';

    var setBodyclass = function () {
      $rootScope.bodyClass = 'home';
    };

    setBodyclass();
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name orgInfrastructures
   * @description
   *
   */
  angular
    .module('orgInfrastructures', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:DeletevenuemodalCtrl
   *
   * @description
   *
   */
  OrgInfraeditmodalCtrl.$inject = ["$modalInstance", "Api", "$log", "infrastructureId"];
  angular
    .module('orgInfrastructures')
    .controller('OrgInfraeditmodalCtrl', OrgInfraeditmodalCtrl);

  function OrgInfraeditmodalCtrl($modalInstance, Api, $log, infrastructureId) {
    var vm = this;
    vm.ctrlName = 'NewInfrastructuremodalCtrl';

    vm.control = {
      provider: false,
      submitted: false
    };

    vm.infrastructureId = infrastructureId;
    vm.selectedProvider = 0;
    vm.providers = [];

    vm.orgs =[];

    vm.infrastructure = {
      provider: 0,
      controlleraddress: '',
      authentication: '',
      password: '',
      username: '',
      ssidlimit: 0
    };

    vm.matchProvider = function(input, id){
      var returning = false;
      vm.providers.forEach(function(item){
        if(item.id === id){
          if (item.name.toLowerCase() === input){
            returning = true;
          }
        }
      });
      return returning;
    };

    vm.getOrgForInfrastructure = function(){
      Api.getInfraOrgs(5, vm.infrastructure).then(function(res){
        vm.orgs = res.data.organisations.map(function(org){
          return {
            id: '' + org.id,
            name: org.name
          }
        });
        var optionSelect = vm.infrastructure.username;
        vm.infrastructure.username = optionSelect;
      }, function(err){
        console.log(err);
      });
    }

    vm.setProvider = function (provider) {
      vm.control.provider = true;
      vm.selectedProvider = provider;
      if(vm.matchProvider('xirrus', provider)){
        vm.infrastructure.controlleraddress = 'api.cloud.xirrus.com';
      }  else if (vm.matchProvider('meraki', provider)){
        vm.infrastructure.controlleraddress = 'api.meraki.com';
      }else {
        vm.infrastructure.controlleraddress = '';
      }
    };


    Api.getProviders().then(function(res){
      res.data.providers.forEach(function(item){
        if (item.name.toLowerCase() === 'unifi' || item.name.toLowerCase() === 'meraki' || item.name.toLowerCase() === 'ruckus' || item.name.toLowerCase() === 'xirrus') {
          vm.providers.push(item);
        }
      });
    }, function(err){
      console.log(err);
    });
    vm.editInfrastructure = function() {
      Api.updateOrgInfrastructure(vm.infrastructureId, vm.infrastructure).success(function(data) {
        $modalInstance.close();
      }).error(function() {
        $log.info('get Infrastructure error');
      });
    };

    vm.getInfrastructure = function() {
      Api.getOrgInfrastructure(vm.infrastructureId).success(function(data) {
        vm.selectedProvider = parseInt(data.infrastructure.provider);
        vm.control.provider = true;
        vm.infrastructure = data.infrastructure;
        vm.getOrgForInfrastructure();
      }).error(function() {
        $log.info('get Infrastructure error');
      });
    };

    vm.getInfrastructure();
    vm.ok = function () {
      vm.control.submitted = true;
      vm.editInfrastructure();
      //$modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name organisations.controller:OrgdeletemodalCtrl
   *
   * @description
   *
   */
  OrgInfradeletemodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('orgInfrastructures')
    .controller('OrgInfradeletemodalCtrl', OrgInfradeletemodalCtrl);

  function OrgInfradeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'OrgInfradeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name orgInfrastructures.controller:OrgInfrastructuresCtrl
   *
   * @description
   *
   */
  OrgInfrastructuresCtrl.$inject = ["Api", "$log", "$q", "$stateParams", "$state", "$modal"];
  angular
    .module('orgInfrastructures')
    .controller('OrgInfrastructuresCtrl', OrgInfrastructuresCtrl);

  function OrgInfrastructuresCtrl(Api, $log, $q, $stateParams, $state, $modal) {
    var vm = this;
    vm.ctrlName = 'OrgInfrastructuresCtrl';

    vm.infrastructures = [];
    vm.providers = [];

    vm.getInfrastructures = function(){
      Api.getOrgInfrastructures().success(function(data) {
        vm.infrastructures = data.infrastructures;
        console.log(vm.infrastructures);
      }).error(function() {
        $log.info('get Infrastructure error');
      });
    };
    Api.getProviders().then(function(res){
      vm.providers = res.data.providers;
    }, function(err){
      console.log(err);
    });
    vm.createInfrastructure = function() {
      var newModalInstance = $modal.open({
        templateUrl: 'org-infrastructures/views/newinfrastructuremodel.tpl.html',
        controller: 'NewInfrastructuremodalCtrl as newinfrastructuremodel'
      });

      newModalInstance.result.then(function() {
        vm.getInfrastructures();
        console.log('submitted');
      });
    };

    vm.deleteInfrastructure = function(id) {
      var newModalInstance = $modal.open({
        templateUrl: 'org-infrastructures/views/orginfradeletemodal.tpl.html',
        controller: 'OrgInfradeletemodalCtrl as orginfradeleteModal'
      });

      newModalInstance.result.then(function() {
        Api.deleteOrgInfrastructure(id).success(function(data) {
          $log.info(data);
          vm.getInfrastructures();
        }).error(function() {
          $log.info('delete Infrastructure error');
        });
      });
    };

    vm.editInfrastructure = function (id) {
      var newModalInstance = $modal.open({
        templateUrl: 'org-infrastructures/views/orginfraeditmodal.tpl.html',
        controller: 'OrgInfraeditmodalCtrl as orginfraeditModal',
        resolve: {
          infrastructureId: id
        }
      });

      newModalInstance.result.then(function() {
        vm.getInfrastructures();
        console.log('submitted');
      });
    };

    vm.configController = function (infrastructure) {
      var newModalInstance = $modal.open({
        templateUrl: 'modalservice/views/bulkVenueCreateModal.tpl.html',
        controller: 'BulkVenueCreateModalCtrl as bulkVenueCreateModal',
        resolve: {
          infrastructure: function(){
            return infrastructure;
          }
        }
      });
      newModalInstance.result.then(function() {
        var modalMessage = {
          ok: 'Ok',
          messageHeader: 'Your venue has been queued for creation, it will appear in the venue list on completion.',
        };

        var modalInstance = $modal.open({
          templateUrl: 'modalservice/views/modalservice.tpl.html',
          controller: 'ModalserviceCtrl as modalService',
          resolve: {
            message: function () {
              return modalMessage;
            }
          }
        });
        modalInstance.result.then(function () {
          $state.go('admin.venues');
        });
      });
    };

    vm.matchProvider = function(input, id){
      var returning = false;
      vm.providers.forEach(function(item){
        if(item.id === id){
          if (item.name.toLowerCase() === input){
            returning = true;
          }
        }
      });
      return returning;
    };


    vm.getInfrastructures();

  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:DeletevenuemodalCtrl
   *
   * @description
   *
   */
  NewInfrastructuremodalCtrl.$inject = ["$modalInstance", "Api", "$log"];
  angular
    .module('orgInfrastructures')
    .controller('NewInfrastructuremodalCtrl', NewInfrastructuremodalCtrl);

  function NewInfrastructuremodalCtrl($modalInstance, Api, $log) {
    var vm = this;
    vm.ctrlName = 'NewInfrastructuremodalCtrl';

    vm.control = {
      provider: false,
      submitted: false
    };

    vm.selectedProvider = 0;
    vm.providers = [];

    vm.validApiKey = false;
    vm.orgs =[];

    vm.infrastructure = {
      provider: 0,
      controlleraddress: '',
      authentication: '',
      password: '',
      username: '',
      ssidlimit: 0
    };

    vm.matchProvider = function(input, id){
      var returning = false;
      vm.providers.forEach(function(item){
        if(item.id === id){
          if (item.name.toLowerCase() === input){
            returning = true;
          }
        }
      });
      return returning;
    };

    vm.getOrgForInfrastructure = function(){
      Api.getInfraOrgs(5, vm.infrastructure).then(function(res){
        vm.orgs = res.data.organisations.map(function(org){
          return {
            id: '' + org.id,
            name: org.name
          }
        });
        vm.validApiKey = true;
      }, function(err){
        console.log(err);
      });
    }

    vm.setProvider = function (provider) {
      vm.control.provider = true;
      vm.selectedProvider = provider;
      vm.infrastructure.controlleraddress = '';
      if (vm.selectedProvider === 5) {
        vm.infrastructure.controlleraddress = 'api.meraki.com';
      } else if (vm.selectedProvider === 1){
        vm.infrastructure.controlleraddress = 'api.cloud.xirrus.com';
      }
    };


    Api.getProviders().then(function(res){
      res.data.providers.forEach(function(item){
        if (item.name.toLowerCase() === 'unifi'  || item.name.toLowerCase() === 'meraki' || item.name.toLowerCase() === 'ruckus' || item.name.toLowerCase() === 'xirrus') {
          vm.providers.push(item);
        }
      });
    }, function(err){
      console.log(err);
    });

    vm.addInfrastructure = function() {
      Api.createOrgInfrastructure(vm.infrastructure).success(function(data) {
        $modalInstance.close();
      }).error(function() {
        $log.info('get Infrastructure error');
      });
    };




    vm.ok = function () {
      vm.control.submitted = true;
      vm.addInfrastructure();
      //$modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name operators
   * @description
   *
   */
  angular
    .module('operators', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name operators.controller:OperatorsCtrl
   *
   * @description
   *
   */
  OperatorsCtrl.$inject = ["Api", "$log", "Auth", "$modal", "$rootScope", "$state"];
  angular
    .module('operators')
    .controller('OperatorsCtrl', OperatorsCtrl);

  function OperatorsCtrl(Api, $log, Auth, $modal, $rootScope, $state) {

    if($rootScope.activeUser.role === 2){
      $state.go('admin.events');
    }

    var vm = this;
    vm.ctrlName = 'OperatorsCtrl';
    vm.operators = [];

    vm.limits = [1,5,10,15,20,50,'all'];
    vm.all= 0;
    vm.limit = 0;

    vm.setLimit = function(limit){
      console.log(limit);
      if(limit === 'all'){
        vm.limit = vm.all;
      }
    };

    if (Auth.getAuthData()) {
      vm.thisOperator = Auth.getAuthData().operator;
    }

    function getOperators() {
      Api.getOperators().success(function (data) {
        vm.operators = data.operators;
        vm.limit = vm.operators.length;
        vm.all = vm.operators.length;
        $log.info(vm.operators);
      }).error(function (err) {
        $log.info(err);
      });
    }

    vm.deleteOperator = function (operator) {
      if (operator.login === vm.thisOperator.login) {
        var modalInstance = $modal.open({
          templateUrl: 'operators/views/modal.tpl.html',
          controller: 'ModalCtrl as modal',
          resolve: {
            message: {
              message: 'Cannot delete logged in Operator'
            }
          }
        });

        modalInstance.result.then(function () {
          $log.info('ok');
        }, function () {
          $log.info('canceled');
        });

      } else {
        var thisModalInstance = $modal.open({
          templateUrl: 'operators/views/modal.tpl.html',
          controller: 'ModalCtrl as modal',
          resolve: {
            message: {
              message: 'Are you sure you want to delete operator: ' + operator.name
            }
          }
        });

        thisModalInstance.result.then(function () {
          Api.deleteOperator(operator.id).success(function () {
            $log.info('operator ' + operator.name + ' deleted');
            getOperators();
          }).error(function (err) {
            $log.info(err);
          });
        }, function () {
          $log.info('canceled');
        });
      }
    };

    vm.resendEmail = function(user) {
      var thisModalInstance = $modal.open({
        templateUrl: 'operators/views/emailRetryModal.tpl.html',
        controller: 'EmailRetryModalCtrl as emailModal',
        resolve: {
          user: user
        }
      });

      thisModalInstance.result.then(function (result) {
        $log.info('successclose');
        Api.resendEmail(user).success(function () {
          var modalMessage = {
            ok: 'Ok',
            messageHeader: 'Email Sent to Operator With Login Details.',
          };
          var modalInstance = $modal.open({
            templateUrl: 'modalservice/views/modalservice.tpl.html',
            controller: 'ModalserviceCtrl as modalService',
            resolve: {
              message: function () {
                return modalMessage;
              }
            }
          });
          modalInstance.result.then(function () {
              getOperators();
          });
        }).error(function (err) {
          $log.info(err);
        });
        // update with resend
      }, function () {
        var modalMessage = {
          ok: 'Ok',
          messageHeader: 'Failed To Re-Send Email',
        };
        var modalInstance = $modal.open({
          templateUrl: 'modalservice/views/modalservice.tpl.html',
          controller: 'ModalserviceCtrl as modalService',
          resolve: {
            message: function () {
              return modalMessage;
            }
          }
        });
        modalInstance.result.then(function () {
            getOperators();
        });
      });
    };


    // vm.updatePassword = function(id) {
    //   var modalInstance = $modal.open({
    //     templateUrl: 'operators/views/passwordmodal.tpl.html',
    //     controller: 'PasswordmodalCtrl as modal',
    //     resolve: {
    //       message: {
    //         message: 'Update Operator Password'
    //       }
    //     }
    //   });
    //   modalInstance.result.then(function (password) {
    //     var data = {
    //       password: password
    //     };
    //     Api.updateOperatorPassword(id, data).success(function (result) {
    //       $log.info(result);
    //       getOperators();
    //     }).error(function (err) {
    //       $log.info(err);
    //     });
    //   }, function () {
    //     $log.info('canceled');
    //   });
    // };

    getOperators();
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name operators.controller:ModalCtrl
   *
   * @description
   *
   */
  ModalCtrl.$inject = ["$modalInstance", "message"];
  angular
    .module('operators')
    .controller('ModalCtrl', ModalCtrl);

  function ModalCtrl($modalInstance, message) {
    var vm = this;

    vm.message = message.message;
    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name operators.controller:ModalCtrl
   *
   * @description
   *
   */
  ModalCtrl.$inject = ["$modalInstance", "user"];
  angular
    .module('operators')
    .controller('EmailRetryModalCtrl', ModalCtrl);

  function ModalCtrl($modalInstance, user) {
    var vm = this;
    vm.user = user;
    vm.error = '';
    vm.ok = function () {
      if (vm.user.login === vm.user.compare) {
        $modalInstance.close();
      } else {
        vm.error = 'Email does not match operator.'
      }
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name newvenueanalytics
   * @description
   *
   */
  angular
    .module('newvenueanalytics', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name newvenueanalytics.controller:NewvenueanalyticsCtrl
   *
   * @description
   *
   */
  NewvenueanalyticsCtrl.$inject = ["ApiAnalytic", "Api", "baseurl", "$scope", "$log", "$modal", "$window", "$sce", "$rootScope", "$stateParams", "$interval", "$cookies", "$state", "Tags"];
  angular.module('newvenueanalytics').controller('NewvenueanalyticsCtrl', NewvenueanalyticsCtrl);

  function NewvenueanalyticsCtrl(ApiAnalytic, Api, baseurl, $scope, $log, $modal, $window, $sce, $rootScope, $stateParams, $interval, $cookies, $state, Tags) {
    var vm = this;
    vm.tab = 'live';

    vm.venueid = $stateParams.venueid;
    vm.venue = {};
    var date = new Date();
    vm.today = date;
    vm.starttime = new Date(date.getFullYear(), date.getMonth(), 1);
    vm.activityDate = new Date();
    vm.activityDate.setDate(vm.activityDate.getDate() - 1);
    vm.yesterday = new Date();
    vm.yesterday.setDate(vm.yesterday.getDate() - 1);

    vm.endtime = new Date();
    vm.endtime.setHours(23);
    vm.endtime.setMinutes(59);
    vm.endtime.setSeconds(59);
    vm.loading = true;
    vm.dwellLoading = true;
    vm.showActivityZoneTable = false;
    vm.showFootfallTable = false;
    vm.showLoginTable = false;
    vm.showgenderTable = false;
    vm.showDwellTable = false;
    vm.showImpressionsTable = false;
    vm.showProfileTable = false;
    vm.totalImpressions = 0;
    vm.userProfiles = [];
    var tmpProfiles = [];
    vm.tags = [];
    vm.activity = '';

    //user dates
    vm.userStartTime = vm.starttime;
    vm.userEndTime = vm.endtime;
    vm.uniqueUsers = 0;
    vm.dwellStart = vm.starttime;
    vm.dwellEnd = vm.endtime;

    vm.apActivity = {};

    vm.status = {
      openedSt: false,
      openedEnd: false,
      openedHeatMap: false,
      openedJourneyDate: false,
      openedUsrSt: false,
      openedUsrEnd: false,
      openedApCal: false
    };

    vm.dateOptions = {
      formatYear: 'yy',
      startingDay: 0
    };

    vm.activityFilter = [{
      'label': 'Online',
      'value': 'true'
    }, {
      'label': 'Offline',
      'value': 'false'
    }, {
      'label': 'All',
      'value': ''
    }];

    vm.dateFormat = 'dd-MMMM-yyyy';

    vm.openUserCalSt = function () {
      vm.status.openedUsrSt = true;
    };

    vm.openUserCalEnd = function () {
      vm.status.openedUsrEnd = true;
    };

    vm.openCalSt = function () {
      vm.status.openedSt = true;
    };

    vm.openApCal = function () {
      vm.status.openedApCal = true;
    };

    vm.openCalEnd = function () {
      vm.status.openedEnd = true;
    };

    vm.liveDataChart = {
      labels: [
        '', '', '', '', ''
      ],
      series: ['Total'],
      data: [
        [0, 0, 0, 0, 0]
      ],
      datasetOverride: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMin: 0
            }
          }]
        }
      }
    };

    vm.apZone = 'zone';

    vm.connectedUsersBarChart = {
      data: [],
      series: [],
      labels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMin: 0
            }
          }],
          xAxes: []
        }
      }
    };

    vm.visitorsLoginData = {
      colors: [
        '#45b7cd', '#ff6384', '#ff8e72'
      ],
      labels: [],
      data: [],
      datasetOverride: [{
        label: 'new visitors',
        borderWidth: 1,
        type: 'bar'
      }, {
        label: 'returned visitors',
        borderWidth: 1,
        type: 'bar'
      }, {
        label: 'total visitors',
        borderWidth: 3,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        type: 'line'
      }]
    };

    vm.horizontal = {
      data: [],
      series: [],
      labels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: []
        }
      }
    };

    vm.genderBarChart = {
      data: [],
      series: [],
      labels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: []
        }
      }
    };

    vm.splashLoadChart = {
      data: [],
      series: [
        'Impressions', 'Logins', 'Bounces'
      ],
      labels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: []
        }
      }
    };

    vm.dwellChart = {
      data: [],
      apData: [],
      zoneData: [],
      series: [],
      labels: [],
      apLabels: [],
      zoneLabels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: []
        }
      }
    };

    var getTags = function () {
      Tags.getTags(vm.venueid).then(function (result) {
        vm.tags = result.data.tags.slice();
      });
    };

    vm.dwellZone = 'zone';

    vm.setDwellZone = function (zone) {
      if (zone === 'ap') {
        vm.dwellChart.data = vm.dwellChart.apData;
        vm.dwellChart.series = vm.dwellChart.apLabels;
      } else if (zone === 'zone') {
        vm.dwellChart.data = vm.dwellChart.zoneData;
        vm.dwellChart.series = vm.dwellChart.zoneLabels;
      }
    };

    vm.dwellTable = {
      apheaders: [
        'accesspoints', 'avg dwell time(mins)'
      ],
      zoneheaders: [
        'zones', 'avg dwell time(mins)'
      ],
      aps: [],
      zones: []
    };

    vm.getDwellTime = function (start, end) {
      vm.dwellLoading = true;
      vm.dwellTable.aps = [];
      vm.dwellTable.zones = [];
      vm.dwellChart.apData = [];
      vm.dwellChart.zoneData = [];
      vm.dwellChart.apLabels = [];
      vm.dwellChart.zoneLabels = [];
      ApiAnalytic.getDwellTimeByVenue(vm.venueid, start, end).then(function (response) {
        var data = response.data;
        vm.dwellChart.apData = data.accesspoints.map(function (ap) {
          var newAp = 0;
          vm.dwellChart.apLabels.push(ap.name);
          var value = Math.round((ap.avgconnection / 1000) / 60);
          newAp = [value];
          vm.dwellTable.aps.push({
            name: ap.name,
            time: value
          });
          return newAp;
        });
        vm.dwellChart.zoneData = data.zones.map(function (ap) {
          var newAp = 0;
          vm.dwellChart.zoneLabels.push(ap.name);
          var value = Math.round((ap.avgconnection / 1000) / 60);
          newAp = [value];
          vm.dwellTable.zones.push({
            name: ap.name,
            time: value
          });
          return newAp;
        });
        if (vm.dwellZone === 'zone') {
          vm.dwellChart.data = vm.dwellChart.zoneData;
          vm.dwellChart.series = vm.dwellChart.zoneLabels;
        } else {
          vm.dwellChart.data = vm.dwellChart.apData;
          vm.dwellChart.series = vm.dwellChart.apLabels;
        }
        vm.dwellLoading = false;
      });
    };

    vm.footfallTable = {
      headers: [
        '', 'New Visitors', 'Returning Visitors', 'Total'
      ],
      rows: []
    };

    vm.getAllNewReturningVis = function (start, end) {
      ApiAnalytic.getFootFallByVenue(vm.venueid, start, end).success(function (data) {

        vm.mixedChartOptions = {
          scales: {
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: data.timeScale
              }
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true,
                suggestedMin: 0
              }
            }]
          }
        };
        vm.footfallTable.rows = [];

        var rowItem = {};

        if (data.timeScale === 'Weeks') {
          var labels = [];
          data.labels.shift();
          data.newVisitors.shift();
          data.returningVisitors.shift();
          data.allVisitors.shift();
          for (var i = 0; i < data.labels.length; i++) {
            rowItem = {
              type: '',
              newVisitors: 0,
              returningVisitors: 0,
              total: 0
            };
            labels[i] = 'Week ' + data.labels[i];
            rowItem.type = 'Week ' + data.labels[i];
            rowItem.newVisitors = data.newVisitors[i];
            rowItem.returningVisitors = data.returningVisitors[i];
            rowItem.total = data.allVisitors[i];
            vm.footfallTable.rows.push(rowItem);
          }
          vm.visitorsLoginData.labels = labels;
          vm.visitorsLoginData.data = [data.newVisitors, data.returningVisitors, data.allVisitors];
        } else if (data.timeScale === 'Hours') {
          var hourLabels = [];
          var now = new Date();
          var offset = (now.getTimezoneOffset() * -1) / 60;
          for (var k = 0; k < offset; k++) {
            data.newVisitors.unshift(data.newVisitors[data.newVisitors.length - 1]);
            data.returningVisitors.unshift(data.returningVisitors[data.returningVisitors.length - 1]);
            data.allVisitors.unshift(data.allVisitors[data.allVisitors.length - 1]);
            data.newVisitors.pop();
            data.returningVisitors.pop();
            data.allVisitors.pop();
          }
          for (var j = 0; j < data.labels.length; j++) {
            hourLabels[j] = data.labels[j] + ':00';
            rowItem = {
              type: '',
              newVisitors: 0,
              returningVisitors: 0,
              total: 0
            };
            rowItem.type = data.labels[j] + ':00';
            rowItem.newVisitors = data.newVisitors[j];
            rowItem.returningVisitors = data.returningVisitors[j];
            rowItem.total = data.allVisitors[j];
            vm.footfallTable.rows.push(rowItem);
          }

          vm.visitorsLoginData.labels = hourLabels;
          vm.visitorsLoginData.data = [data.newVisitors, data.returningVisitors, data.allVisitors];
        } else if (data.timeScale === 'Days') {
          // data.labels.shift(); data.newVisitors.shift();
          // data.returningVisitors.shift(); data.allVisitors.shift();
          var dayLabels = [];
          var monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ];

          for (var z = 0; z < data.labels.length; z++) {
            rowItem = {
              type: '',
              newVisitors: 0,
              returningVisitors: 0,
              total: 0
            };
            var date = new Date((new Date().getYear()), 0);
            date.setDate(data.labels[z]);
            dayLabels.push(date.getDate() + ' ' + monthNames[date.getMonth()]);
            rowItem.type = date.getDate() + ' ' + monthNames[date.getMonth()];
            rowItem.newVisitors = data.newVisitors[z];
            rowItem.returningVisitors = data.returningVisitors[z];
            rowItem.total = data.allVisitors[z];
            vm.footfallTable.rows.push(rowItem);
          }

          vm.visitorsLoginData.labels = dayLabels;
          vm.visitorsLoginData.data = [data.newVisitors, data.returningVisitors, data.allVisitors];
        } else {
          data.labels.shift();
          data.newVisitors.shift();
          data.returningVisitors.shift();
          data.allVisitors.shift();

          for (var b = 0; b < data.labels.length; b++) {
            rowItem = {
              type: '',
              newVisitors: 0,
              returningVisitors: 0,
              total: 0
            };
            rowItem.type = data.labels[b];
            rowItem.newVisitors = data.newVisitors[b];
            rowItem.returningVisitors = data.returningVisitors[b];
            rowItem.total = data.allVisitors[b];
            vm.footfallTable.rows.push(rowItem);
          }

          vm.visitorsLoginData.labels = data.labels;
          vm.visitorsLoginData.data = [data.newVisitors, data.returningVisitors, data.allVisitors];
        }
        vm.loading = false;
      }).error(function () {
        $log.info('get gender error');
      });
    };

    vm.genderChartColors = ['#2962ff', '#c51162', '#004d40', '#37474f'];
    vm.typeChartColors = [];
    vm.chartColors = [
      '#b71c1c',
      '#1a237e',
      '#f57f17',
      '#004d40',
      '#212121',
      '#880e4f',
      '#bf360c',
      '#1b5e20',
      '#263238',
      '#4a148c',
      '#0277bd',
      '#3e2723',
      '#558b2f',
      '#616161',
      '#311b92',
      '#ffd740',
      '#c6ff00',
      '#607d8b',
      '#d50000',
      '#0288d1',
      '#ffca28',
      '#795548',
      '#cddc39',
      '#757575',
      '#9c27b0',
      '#26c6da',
      '#ff9100',
      '#d4e157',
      '#7c4dff'
    ];

    vm.getVenue = function () {
      Api.getVenue(vm.venueid).then(function (response) {
        vm.venue = response.data.venue;
        vm.setLive();
      });
    };

    var setLimits = function (n) {
      if (n <= 100) {
        vm.limits = [
          Math.ceil((n / 5) / 10) * 10,
          Math.ceil((n / 5) / 10) * 10 * 2,
          Math.ceil((n / 5) / 10) * 10 * 3,
          Math.ceil((n / 5) / 10) * 10 * 4,
          'all'
        ];
      } else if (n <= 500) {
        vm.limits = [
          Math.ceil((n / 5) / 50) * 50,
          Math.ceil((n / 5) / 50) * 50 * 2,
          Math.ceil((n / 5) / 50) * 50 * 3,
          Math.ceil((n / 5) / 50) * 50 * 4,
          'all'
        ];
      } else {
        vm.limits = [
          100, Math.ceil((n / 5) / 100) * 100,
          Math.ceil((n / 5) / 100) * 100 * 2,
          Math.ceil((n / 5) / 100) * 100 * 3,
          Math.ceil((n / 5) / 100) * 100 * 4,
          'all'
        ];
      }
      vm.limit = vm.limits[0];
    };

    vm.setLimit = function (activity) {
      vm.activity = activity;
      if (activity === 'true') {
        vm.limit = 'all';
      } else {
        vm.limit = vm.limits[0];
      }
    };

    var getUserProfiles = function (start, end) {
      ApiAnalytic.getUserProfilesVenue(vm.venueid, start, end).then(function (response) {
        tmpProfiles = response.data.map(function (user) {
          return user;
        });
        vm.userProfiles = response.data.map(function (user) {
          user.isLive = isLive(user.usermac);
          return user;
        });
        setLimits(response.data.length);
      });
    };

    vm.getMarketingEmailList = function()
    {
      var emailList = [];

      emailList = vm.userProfiles
      .filter(function (userItem){
        return isNaN(userItem.login)
      })
      .map(function (userItem){
          if(userItem.tag && userItem.tag.length > 0)
          {
            return { Name:userItem.name, Email:userItem.login, Tag:userItem.tag[0]}
          }
          else {
            return { Name:userItem.name, Email:userItem.login}
          }
      })
      return emailList;
    };


    function convertArrayOfObjectsToCSV(args) {
            var result, ctr, keys, columnDelimiter, lineDelimiter, data;

            data = args.data || null;
            if (data == null || !data.length) {
                return null;
            }

            columnDelimiter = args.columnDelimiter || ',';
            lineDelimiter = args.lineDelimiter || '\n';

            keys = Object.keys(data[0]);

            result = '';
            result += keys.join(columnDelimiter);
            result += lineDelimiter;

            data.forEach(function(item) {
                ctr = 0;
                keys.forEach(function(key) {
                    if (ctr > 0) result += columnDelimiter;

                    result += item[key];
                    ctr++;
                });
                result += lineDelimiter;
            });

            return result;
    }

    vm.downloadCSV = function()
    {
      var data, filename, link;
      var csv = convertArrayOfObjectsToCSV({
        data: vm.getMarketingEmailList()
      });
      if (csv == null)  return;

      var now = new Date();
      var nowString = now.toISOString().split('.')[0];
      filename = 'export_'+nowString+'.csv';

      var blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});

      if (navigator.msSaveBlob)
      { // IE 10+
        navigator.msSaveBlob(blob, filename)
      }
      else
      {
          var link = document.createElement("a");
          if (link.download !== undefined)
          {

            // feature detection, Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
      }
    }


    vm.getSplashLoadChartData = function (start, end) {
      ApiAnalytic.getImpressionsByVenue(vm.venueid, start, end).then(function successCallback(response) {
        var data = JSON.parse(JSON.stringify(response.data));
        vm.totalImpressions = data.totalImpressions;
        vm.splashLoadChart.data = [];
        vm.splashLoadChart.labels = [];
        var monthNames = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ];
        // Sort Returned Data
        data.impressions.sort(function sortNumber(a, b) {
          return a._id[
            data.type.substring(0, data.type.length - 1)
          ] - b._id[
            data.type.substring(0, data.type.length - 1)
          ];
        });
        data.logins.sort(function sortNumber(a, b) {
          return a._id[
            data.type.substring(0, data.type.length - 1)
          ] - b._id[
            data.type.substring(0, data.type.length - 1)
          ];
        });
        data.bounces.sort(function sortNumber(a, b) {
          return a._id[
            data.type.substring(0, data.type.length - 1)
          ] - b._id[
            data.type.substring(0, data.type.length - 1)
          ];
        });

        // correct the data set
        var impressions = [];
        var logins = [];
        var bounces = [];

        data.impressions.forEach(function (item) {
          var label = item._id[
            data.type.substring(0, data.type.length - 1)
          ];
          if (data.type === 'hours') {
            vm.splashLoadChart.labels.push(label + ':00');
          } else if (data.type === 'days') {
            var date = new Date((new Date().getYear()), 0);
            date.setDate(label);
            vm.splashLoadChart.labels.push(date.getDate() + ' ' + monthNames[date.getMonth()]);
          } else if (data.type === 'months') {
            vm.splashLoadChart.labels.push(monthNames[label - 1]);
          } else if (data.type === 'years') {
            var year = new Date(label, 0).getFullYear();
            vm.splashLoadChart.labels.push('' + year);
          } else {
            vm.splashLoadChart.labels.push(data.type.charAt(0).toUpperCase() + data.type.substring(1, data.type.length - 1) + ' ' + label);
          }
          impressions.push(item.count);
        });
        data.logins.forEach(function (item) {
          logins.push(item.count);
        });
        data.bounces.forEach(function (item) {
          bounces.push(item.count);
        });

        // ofsetcorrection temp
        if (data.type === 'hours') {
          impressions.unshift(impressions[impressions.length - 1]);
          logins.unshift(logins[logins.length - 1]);
          bounces.unshift(bounces[bounces.length - 1]);

          impressions.pop();
          logins.pop();
          bounces.pop();
        }

        vm.splashLoadChart.data.push(impressions);
        vm.splashLoadChart.data.push(logins);
        vm.splashLoadChart.data.push(bounces);
      }, function errorCallback(response) {
        console.log(response);
      });
    };

    vm.connectedTable = {
      zoneheaders: [
        'zones', 'count'
      ],
      zones: []
    };

    vm.getLiveAnalytics = function () {
      vm.connectedUsersBarChart.series = [];
      vm.connectedTable.zones = [];
      vm.connectedUsersBarChart.data = [];
      vm.connectedUsersBarChart.options.scales.xAxes = [];

      ApiAnalytic.getClientsOnlineByVenue(vm.venueid, vm.venue.infrastructure.provider).then(function (response) {
        var expireDate = new Date((new Date()).getTime() + 10 * 60000);
        $cookies.put('unifitoken', response.data.cookie, {
          'expires': expireDate
        });

        /* Live User Totals */
        vm.liveLoading = false;

        // total
        vm.liveDataChart.data[0].push(response.data.totalUsers);
        vm.liveDataChart.data[0].shift();
        //ssids
        response.data.ssids.forEach(function (ssid) {
          if (vm.liveDataChart.series.indexOf(ssid.name) === -1) {
            vm.liveDataChart.series.push(ssid.name);
            var tempArray = [0, 0, 0, 0, 0];
            tempArray.push(ssid.count);
            tempArray.shift();
            vm.liveDataChart.data.push(tempArray);
          } else {
            var index = vm.liveDataChart.series.indexOf(ssid.name);
            vm.liveDataChart.data[index].push(ssid.count);
            vm.liveDataChart.data[index].shift();
          }
        });

        vm.totalUsers = response.data.totalUsers;
        vm.authedUsers = response.data.authedUsers;

        var dataLength = response.data.zones.length;
        for (var item = 0; item < dataLength; item++) {
          var newAp = 0;
          var value = response.data.zones[item].count;
          newAp = [value];
          var name = response.data.zones[item].name;
          vm.connectedUsersBarChart.series.push(name);
          vm.connectedUsersBarChart.data.push(newAp);
          vm.connectedTable.zones.push({
            name: name,
            count: value
          });
        }
        if (vm.connectedUsersBarChart.data.length <= 1) {
          vm.connectedUsersBarChart.options.scales.xAxes.push({
            barPercentage: 0.3
          });
        } else {
          vm.connectedUsersBarChart.options.scales.xAxes = [];
        }
        vm.setHistoric(vm.starttime.getTime(), vm.endtime.getTime());
      }).catch(function (error) {
        if (error.data.message === 'This Provider is Not Supported') {
          vm.liveData = false;
        }
      });
    };

    var getApActivity = function (date) {
      vm.apActivity = {};
      vm.AccessPointsTable = {};
      Api.getAccessPoints(vm.venueid).then( function (res) {
        for ( var i = 0 ; i < res.data.rowCount ; i++)
        {
          vm.AccessPointsTable[res.data.apoints[i].hwaddr] = res.data.apoints[i].name;
        }
        ApiAnalytic.getApActivity(vm.venueid, date).then(function (res) {
          vm.apActivity = res.data;
          for (var i = 0 ; i < vm.apActivity.series.length ; i++)
          {
            vm.apActivity.series[i] = vm.AccessPointsTable[vm.apActivity.series[i]];
          }
          vm.apActivity.options = {
            legend: {
              display: true,
              position: 'left'
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  suggestedMin: 0
                }
              }]
            }
          };
        });
      })
    };

    var getBusyAP = function (date) {
      ApiAnalytic.getBusyAP(vm.venueid, date).then(function (res) {
        vm.busyAp = res.data;
        if(vm.AccessPointsTable){
          busyAp.mostBusy.ap  = vm.AccessPointsTable[busyAp.mostBusy.ap];
          busyAp.leastBusy.ap  = vm.AccessPointsTable[busyAp.leastBusy.ap];
        }
      });
    };

    var getJourney = function () {
      var date = new Date();
      date.setDate(date.getDate() - 1);
      var dateN = date.getTime();
      ApiAnalytic.getJourney(vm.venueid, dateN).then(function (res) {
        var aps = res.data.aps;
        var length = aps.length;
        var labels = [];
        var data = [];
        for (var i = 0; i < length; i++) {
          if (data.length < 1) {
            for (var k = 0; k < 10; k++) {
              var key = k + 1;
              labels.push(aps[0].apMac);
              data.push(aps[0].position[key]);
            }
          } else {
            for (var j = 0; j < data.length; j++) {
              var newKey = j + 1;
              if (aps[i].position[newKey] > data[j]) {
                var test = j - 1;
                if (labels[test] !== aps[i].apMac) {
                  data[j] = aps[i].position[newKey];
                  labels[j] = aps[i].apMac;
                }
              }
            }
          }
        }
        vm.usrJourney = {
          labels: labels,
          data: [data],
          series: ['Users']
        };
        vm.usrJourney.options = {
          legend: {
            display: false,
            position: 'right'
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                suggestedMin: 0
              }
            }]
          }
        };
        // vm.usrJourney.options.elements = {
        //   line: {
        //     fill: false
        //   }
        // };
      });
    };

    var intervalPromise;

    vm.startInterval = function () {
      vm.stopInterval();
      intervalPromise = $interval(function () {
        vm.setLive();
      }, 30000);
    };

    vm.stopInterval = function () {
      $interval.cancel(intervalPromise);
    };

    $rootScope.$on('$stateChangeStart', function () {
      vm.stopInterval();
      return;
    });

    vm.setLive = function () {
      vm.getLiveAnalytics();
      getLiveUsers();
      refreshLive();
      vm.startInterval();
    };
    vm.genderTable = {
      headers: [
        'demographic', 'count'
      ],
      rows: []
    };

    var processGender = function (genderArray) {
      if (genderArray.length <= 1) {
        vm.genderBarChart.options.scales.xAxes.push({
          barPercentage: 0.3
        });
      } else {
        vm.genderBarChart.options.scales.xAxes = [];
      }
      var total = parseInt(genderArray[0].female) + parseInt(genderArray[0].male);
      if (parseInt(genderArray[0].male) > 0) {
        vm.genderMale = Math.round((parseInt(genderArray[0].male) / total) * 100) + '%';
      } else if (parseInt(genderArray[0].male) <= 0 && parseInt(genderArray.female) > 0) {
        vm.genderMale = 0 + '%';
      } else {
        vm.genderMale = 'No Data';
      }

      if (parseInt(genderArray[0].female) > 0) {
        vm.genderFemale = Math.round((parseInt(genderArray[0].female) / total) * 100) + '%';
      } else if (parseInt(genderArray[0].female) <= 0 && parseInt(genderArray[0].male) > 0) {
        vm.genderFemale = 0 + '%';
      } else {
        vm.genderFemale = 'No Data';
      }
      var male = parseInt(genderArray[0].male);
      var female = parseInt(genderArray[0].female);
      var other = parseInt(genderArray[0].other);

      vm.genderBarChart.series = [];
      vm.genderBarChart.data = [];
      vm.genderTable.rows = [];

      var rowMale = {
        type: 'Male',
        count: male
      };
      var rowFemale = {
        type: 'Female',
        count: female
      };
      var rowOther = {
        type: 'Other',
        count: other
      };

      if (male > 0) {
        vm.genderBarChart.series.push('Male');
        vm.genderBarChart.data.push([male]);
        vm.genderTable.rows.push(rowMale);
      }
      if (female > 0) {
        vm.genderBarChart.series.push('Female');
        vm.genderBarChart.data.push([female]);
        vm.genderTable.rows.push(rowFemale);
      }
      if (other > 0) {
        vm.genderBarChart.series.push('Other');
        vm.genderBarChart.data.push([other]);
        vm.genderTable.rows.push(rowOther);
      }

      if (vm.genderBarChart.data.length <= 1) {
        vm.genderBarChart.options.scales.xAxes.push({
          barPercentage: 0.3
        });
      } else {
        vm.genderBarChart.options.scales.xAxes = [];
      }
    };

    vm.loginTable = {
      headers: [
        'type', 'count'
      ],
      rows: []
    };

    var processLoginTypes = function (logins) {
      vm.horizontal.series = [];
      vm.horizontal.data = [];
      vm.loginTable.rows = [];
      for (var x = 0; x < logins.length; x++) {
        var rowItem = {
          type: '',
          count: 0
        };
        vm.horizontal.data.push([parseInt(logins[x].count)]);
        rowItem.count = parseInt(logins[x].count);
        var type = logins[x].logintype;
        if (type === 'anonymous') {
          vm.horizontal.series.push('email');
          rowItem.type = 'email';
        } else {
          vm.horizontal.series.push(type);
          rowItem.type = type;
        }
        vm.loginTable.rows.push(rowItem);
        switch (type) {
          case 'anonymous':
            vm.typeChartColors.push('#455a64');
            break;
          case 'facebook':
            vm.typeChartColors.push('#304ffe');
            break;
          case 'linkedin':
            vm.typeChartColors.push('#1e88e5');
            break;
          case 'twitter':
            vm.typeChartColors.push('#00b0ff');
            break;
          case 'google':
            vm.typeChartColors.push('#d50000');
            break;
          case 'twilio':
            vm.typeChartColors.push('#263238');
            break;
        }
      }
    };

    var getLoginAnalytics = function (start, end) {
      ApiAnalytic.getLoginAnalyticsVenue(vm.venueid, start, end).then(function (response) {
        vm.uniqueUsers = response.data.unique;
        vm.totalLogin = response.data.total;
        processGender(response.data.gender);
        processLoginTypes(response.data.loginType);
      });
    };

    vm.setHistoric = function (start, end) {
      vm.getAllNewReturningVis(start, end);
      vm.getSplashLoadChartData(start, end);
      vm.getDwellTime(start, end);
      getLoginAnalytics(start, end);
    };

    var getLiveUsers = function () {
      ApiAnalytic.getOnlineUsersVenue(vm.venueid, vm.venue.infrastructure.provider).then(function (result) {
        vm.liveUsers = result.data;
        if (vm.userProfiles.length <= 0) {
          var thisStart = vm.starttime;
          var thisEnd = vm.endtime;
          thisStart.setHours(0);
          thisStart.setMinutes(0);
          thisStart.setSeconds(0);
          thisEnd.setHours(23);
          thisEnd.setMinutes(59);
          thisEnd.setSeconds(59);
          getUserProfiles(thisStart.getTime(), thisEnd.getTime());
        }
      });
    };

    var refreshLive = function () {
      if (tmpProfiles.length > 0) {
        vm.userProfiles = tmpProfiles.map(function (user) {
          user.isLive = isLive(user.usermac);
          return user;
        });
      } else {
        var thisStart = vm.starttime;
        var thisEnd = vm.endtime;
        thisStart.setHours(0);
        thisStart.setMinutes(0);
        thisStart.setSeconds(0);
        thisEnd.setHours(23);
        thisEnd.setMinutes(59);
        thisEnd.setSeconds(59);
        getUserProfiles(thisStart.getTime(), thisEnd.getTime());
      }
    };

    var isLive = function (macarray) {
      var length = macarray.length;
      for (var i = 0; i < length; i++) {
        if (vm.liveUsers.indexOf(macarray[i]) >= 0) {
          return 'true';
        }
      }
      return 'false';
    };

    vm.setUsers = function (start, end) {
      getUserProfiles(start, end);
    };

    vm.refresh = function () {
      vm.loading = true;
      var thisStart = vm.starttime;
      var thisEnd = vm.endtime;
      vm.visitorsLoginData.data = [];
      thisStart.setHours(0);
      thisStart.setMinutes(0);
      thisStart.setSeconds(0);
      thisEnd.setHours(23);
      thisEnd.setMinutes(59);
      thisEnd.setSeconds(59);
      vm.setHistoric(thisStart.getTime(), thisEnd.getTime());
      vm.setUsers(thisStart.getTime(), thisEnd.getTime());
    };

    vm.switchTab = function (tab) {
      switch (tab) {
        case 'live':
          vm.tab = tab;
          break;
        case 'historic':
          vm.tab = tab;
          break;
        case 'users':
          vm.tab = tab;
          break;
        case 'Segmentation':
          $state.go('admin.tags', {
          'venueid': vm.venueid
        });
          break;
        default:
          vm.tab = 'live';
      }
    };

    vm.setClass = function () {
      if ($stateParams.class) {
        vm.tab = $stateParams.class;
      } else {
        vm.tab = 'live';
      }
    };

    /* Heat Map */
    vm.getMessage = function () {
      switch (vm.mapType.toLowerCase()) {
        case 'users':
          return 'users';
          break;
        case 'dwell':
          return 'dwell';
          break;
        case 'active minutes':
          return 'active minutes';
          break;
        default:

      }
    };
    // Data
    vm.maps = [];
    vm.heatmap = [];
    vm.map = {};
    vm.mapHeight = 480;
    vm.mapWidth = 854;
    vm.heatMapDay = [];
    vm.heatMapDate = new Date().setDate((new Date()).getDate() - 1);
    vm.heatmapSlider = {
      value: 0,
      options: {
        showTicks: true,
        floor: 0,
        ceil: 23,
        translate: function (value) {
          if (value < 10) {
            value = '0' + value;
          }
          return value + ':00';
        }
      }
    };
    vm.mapType = 'Users';
    vm.heatMax = 0;
    vm.scaleHeight = 0;
    vm.scaleTextHeight = 0;
    vm.drawAps = false;
    vm.originalPoints = [];
    vm.toolTipMessage = vm.getMessage();
    vm.heatmapData = [];

    // Page Objects
    var mapBackground, mapHeatMap, mapPointMap, mapContainer, mapClockMap;

    // Context
    var backCtx, pointCtx, heatCtx, clockCtx;

    // Image Data
    vm.backdrop = '';
    vm.backgroundSelected = false;
    vm.apicon = './images/apicon.png';
    vm.logoLoaded = false;
    vm.percent = 0;
    var logoImage = new Image();

    vm.initMap = function () {
      mapBackground = document.getElementById('mapBackground');
      mapHeatMap = document.getElementById('mapHeatMap');
      mapClockMap = document.getElementById('mapHeatMap');
      mapPointMap = document.getElementById('mapPointMap');
      mapContainer = document.getElementById('mapContainer');

      backCtx = mapBackground.getContext('2d');
      pointCtx = mapPointMap.getContext('2d');
      heatCtx = mapHeatMap.getContext('2d');
      clockCtx = mapClockMap.getContext('2d');
      backCtx.globalAlpha = 1.0;
      pointCtx.globalAlpha = 1.0;
      heatCtx.globalAlpha = 1.0;
      vm.drawBackground();
    };

    vm.getMaps = function () {
      ApiAnalytic.getMapsByVenue(vm.venueid).then(function (res) {
        if (res.data.length > 0) {
          vm.maps = res.data;
          vm.map = vm.maps[0];
          vm.originalPoints = JSON.stringify(vm.map.points);
        }
      }, function (err) {
        console.log(err);
      });
    };

    vm.typeChange = function () {
      vm.toolTipMessage = vm.getMessage();
      vm.getHeatMap();
    };

    vm.mapChange = function () {
      vm.originalPoints = JSON.stringify(vm.map.points);
      vm.drawBackground();
    };

    vm.getHeatMap = function () {
      vm.heatMapDay = [];
      var macarray = [];
      vm.map.points.forEach(function (item) {
        macarray.push(item.hwaddr);
      });
      var date = new Date(vm.heatMapDate);
      vm.heatMax = 0;
      ApiAnalytic.getMapForDay(vm.mapType, vm.venueid, date, macarray).then(function (res) {
        res.data.mapData.forEach(function (point) {
          var dataLength = point.mapdata.length;
          var index = 0;
          var hour = {
            hour: point.hour,
            mapdata: []
          };
          for (index; index < dataLength; index++) {
            vm.map.points.forEach(function (ap) {
              if (ap.hwaddr === point.mapdata[index].mac) {
                vm.heatMax = (point.mapdata[index].weight > vm.heatMax) ? point.mapdata[index].weight : vm.heatMax;
                hour.mapdata.push([ap.x, ap.y, point.mapdata[index].weight]);
              }
            });
          }
          vm.heatMapDay.push(hour);
        });
        vm.renderHour();
      }, function (err) {
        console.log(err);
      });
    };

    vm.renderHour = function () {
      vm.heatmap = [];
      vm.heatmap = vm.heatMapDay[vm.heatmapSlider.value].mapdata;
      vm.draw(vm.map.points);
    };

    // Draw Functions
    vm.draw = function (data) {
      pointCtx.clearRect(0, 0, mapPointMap.width, mapPointMap.height);

      for (var i = 0; i < data.length; i++) {
        vm.drawAp(vm.heatmap[i]);
      }

      $window.requestAnimationFrame(vm.drawHeatmap);
    };

    function degreesToRadians(degrees) {
      return degrees * (Math.PI / 180);
    }

    vm.drawAp = function (data) {
      if (vm.mapType === 'Dwell') {
        if (data) {
          pointCtx.beginPath();
          console.log(data);

          var angle = (360 / 60) * (data[2] / 60000);

          pointCtx.lineWidth = 1;
          pointCtx.arc(data[0], data[1], (vm.mapWidth / 28), 0, 360 * 0.0174533);

          pointCtx.stroke();
          pointCtx.closePath();

          pointCtx.fillStyle = 'red';
          pointCtx.beginPath();
          pointCtx.moveTo(data[0], data[1]);
          pointCtx.arc(data[0], data[1], (vm.mapWidth / 28), degreesToRadians(0) - Math.PI / 2, degreesToRadians(angle) - Math.PI / 2);
          pointCtx.fill();
          pointCtx.closePath();

          pointCtx.beginPath();

          pointCtx.lineWidth = 5;
          // Top Line
          pointCtx.moveTo(data[0], data[1] - (vm.mapWidth / 28) + 3);
          pointCtx.lineTo(data[0], data[1] - (vm.mapWidth / 28) - 3);

          // Bottom Line
          pointCtx.moveTo(data[0], data[1] + (vm.mapWidth / 28) + 3);
          pointCtx.lineTo(data[0], data[1] + (vm.mapWidth / 28) - 3);

          // Left Line
          pointCtx.moveTo(data[0] - (vm.mapWidth / 28) + 3, data[1]);
          pointCtx.lineTo(data[0] - (vm.mapWidth / 28) - 3, data[1]);

          // Right Line
          pointCtx.moveTo(data[0] + (vm.mapWidth / 28) + 3, data[1]);
          pointCtx.lineTo(data[0] + (vm.mapWidth / 28) - 3, data[1]);
          //pointCtx.arc(data[0], data[1], (vm.mapWidth/32), 0, (60/360) * (data[2]/60000));

          pointCtx.stroke();
          pointCtx.closePath();
        }
      }
    };

    vm.drawBackground = function () {
      backCtx.clearRect(0, 0, mapBackground.width, mapBackground.height);
      var background = new Image();

      background.onload = function () {
        background.height = vm.map.originalHeight;
        background.width = vm.map.originalWidth;

        if (mapContainer.offsetWidth < background.width) {
          var max = background.width;
          var reduced = mapContainer.offsetWidth;
          var diff = max - reduced;
          vm.percent = (diff / max) * 100;
          background.width = reduced;
          background.height = (background.height / 100) * (100 - vm.percent);
          //background.height = parseInt((background.height/100)*percent);
        } else {
          var min = background.width;
          var scaled = mapContainer.offsetWidth;
          var diff = scaled - min;
          vm.percent = (diff / min) * 100;
          background.width = scaled;
          background.height = background.height + (background.height / 100) * vm.percent;
        }

        vm.map.points = JSON.parse(vm.originalPoints);

        vm.map.points.forEach(function (ap) {
          ap.x = ap.x * (background.width / vm.map.originalWidth);
          ap.y = ap.y * (background.height / vm.map.originalHeight);
        });

        vm.mapHeight = background.height;
        vm.mapWidth = background.width;

        mapPointMap.width = vm.mapWidth;
        mapPointMap.height = vm.mapHeight;
        mapBackground.width = vm.mapWidth;
        mapBackground.height = vm.mapHeight;
        mapClockMap.width = vm.mapWidth;
        mapClockMap.height = vm.mapHeight;
        mapHeatMap.width = vm.mapWidth;
        mapHeatMap.height = vm.mapHeight;

        vm.scaleHeight = vm.mapHeight * .6;
        vm.scaleTextHeight = vm.scaleHeight * .98;

        $scope.$apply();

        backCtx.drawImage(background, 0, 0, vm.mapWidth, vm.mapHeight);
        vm.logoLoaded = true;
        vm.getHeatMap();
      };
      background.src = baseurl + '/' + vm.map.imageUri;
    };

    vm.drawHeatmap = function () {
      heatCtx.clearRect(0, 0, mapHeatMap.width, mapHeatMap.height);
      var heat = simpleheat(mapHeatMap);
      // find max

      heat.max(vm.heatMax);
      // set radius
      heat.radius(vm.mapWidth / 12, vm.mapWidth / 12);

      heat.data(vm.heatmap);

      vm.scaleMax = (vm.mapType === 'Active Minutes') ? parseInt(vm.heatMax / 60000) : vm.heatMax;

      $scope.$apply();

      heat.draw();
    };

    // Slider
    $scope.$on('slideEnded', function () {
      vm.renderHour();
    });

    // Date Picker
    vm.mapDayChange = function () {
      vm.getHeatMap();
    };
    vm.openCalHeatMap = function () {
      vm.status.openedHeatMap = true;
    };

    vm.refreshApActivity = function () {
      getApActivity(vm.activityDate.getTime());
      getBusyAP(vm.activityDate.getTime());
    };

    vm.getAPs = function ()
    {
      vm.AccessPointsTable = {};
      Api.getAccessPoints(vm.venueid).then( function (res) {
        for ( var i = 0 ; i < res.rowCount ; i++)
        {
          vm.AccessPointsTable[res.apoints[i].hwaddr] = res.apoints[i].name;
        }
      })
    };

    vm.getVenue();
    vm.setClass();
    getTags();
    vm.getMaps();
    vm.getAPs();
    getApActivity(vm.activityDate.getTime());
    getBusyAP(vm.activityDate.getTime());
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name newoperator
   * @description
   *
   */
  angular
    .module('newoperator', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name newoperator.controller:NewoperatorCtrl
   *
   * @description
   *
   */
  NewoperatorCtrl.$inject = ["Api", "$log", "$state", "$rootScope", "$modal"];
  angular
    .module('newoperator')
    .controller('NewoperatorCtrl', NewoperatorCtrl);

  function NewoperatorCtrl(Api, $log, $state, $rootScope, $modal) {
    var vm = this;
    vm.operator = {};
    vm.venues = [];
    vm.submitted = false;


    Api.getRoles().success(function (data) {
      vm.roles = data.organisationRoles;
      $log.info(vm.roles);
    }).error(function (err) {
      $log.info(err);
    });

    Api.getVenues().then(function(result){
      vm.venues = result.data.venues;
    }, function (err){
      console.log(err);
    });

    vm.setVenue = function(venue){
      for (var i = 0; i < vm.venues.length; i++){
        if(vm.venues[i].id === venue){
        vm.operator.details.venueName = vm.venues[i].venuetitle;
          break;
        }
      }

    };

    vm.createOperator = function (valid) {
      vm.submitted = true;
      if (valid) {
        Api.createOperator(vm.operator).success(function (data) {
          $log.info(data);
          var modalMessage = {
            ok: 'Ok',
            messageHeader: 'Email Sent to Operator With Login Details.',
          };

          var modalInstance = $modal.open({
            templateUrl: 'modalservice/views/modalservice.tpl.html',
            controller: 'ModalserviceCtrl as modalService',
            resolve: {
              message: function () {
                return modalMessage;
              }
            }
          });
          modalInstance.result.then(function () {
            $state.go('admin.operators');
          });
        }).error(function (err) {
          $log.info(err);
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name newvenue
   * @description
   *
   */
  angular
    .module('newvenue', [
      'ui.router'
    ]);
}());

(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name newvenue.controller:NewvenueCtrl
   *
   * @description
   *
   */
  NewvenueCtrl.$inject = ["Api", "$modal", "$state", "$stateParams"];
  angular
    .module('newvenue')
    .controller('NewvenueCtrl', NewvenueCtrl);

  function NewvenueCtrl(Api, $modal, $state, $stateParams) {
    var vm = this;
    vm.venue = {};
    vm.submitted = false;
    vm.socialMediaAccounts = [{
      title: 'Facebook Page',
      example: 'eg. https://www.facebook.com/Liquidedge/',
      value: '',
      key: 'fbpage'
    }];
    var orgid = $stateParams.orgid;
    vm.status = {
      isopen: false
    };

    vm.updateSelection = function(position, images) {
      angular.forEach(images, function(image, index) {
        if (position !== index) {
          image.checked = false;
        } else {
          vm.venue.imageUrl = image.imageuri;
          vm.venue.imageId = image.id;
        }
      });
    };

    // vm.selectImage = function() {
    //
    //   vm.modalMessage = {
    //     orgid: orgid
    //   };
    //
    //   var modalInstance = $modal.open({
    //     templateUrl: 'selectimage/views/selectimage.tpl.html',
    //     controller: 'SelectimageCtrl as selectimage',
    //     size: 'lg',
    //     resolve: {
    //       'venue': 0
    //     }
    //   });
    //
    //   modalInstance.result.then(function(image) {
    //     var thisImage = angular.fromJson(image);
    //     vm.venue.imageUrl = thisImage.imageuri;
    //     vm.venue.imageId = thisImage.id;
    //   }, function() {
    //     console.log('canceled');
    //   });
    // };

    vm.uploadImage = function() {
      vm.modalMessage = {
        orgid: orgid
      };

      var modalInstance = $modal.open({
        templateUrl: 'uploadorgimg/views/uploadorgimg.tpl.html',
        controller: 'UploadorgimgCtrl as modal',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });

      modalInstance.result.then(function(image) {
        console.log(image);
        vm.venue.imageUrl = image.imageUri;
        vm.venue.imageId = image.id;
        console.log(vm.venue);
        console.log('success');
      }, function() {
        console.log('canceled');
      });
    };

    vm.createVenue = function(valid) {
      vm.submitted = true;
      if (valid) {
        vm.venue.details = {};
        vm.socialMediaAccounts.forEach(function(item){
          if(item.value.length > 0) {
            vm.venue.details[item.key] = item.value;
          }
        });
        Api.createVenue(vm.venue).success(function(data) {
          console.log(data);
          $state.go('admin.venues', {
            orgid: orgid
          });
        }, function(err) {
          console.log(err);
        });
      }
    };

    Api.getImages().success(function(data) {
      vm.imageList = data.images;
      for (var i = 0; i < vm.imageList.length; i++) {
        vm.imageList[i].checked = false;
      }
      console.log(vm.imageList);
    });

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name networktype
   * @description
   *
   */
  angular
    .module('networktype', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name networktype.controller:NetworktypeCtrl
   *
   * @description
   *
   */
  NetworktypeCtrl.$inject = ["$stateParams", "Api", "$log", "$state"];
  angular
    .module('networktype')
    .controller('NetworktypeCtrl', NetworktypeCtrl);

  function NetworktypeCtrl($stateParams, Api, $log, $state) {
    var vm = this;

    var venueid = $stateParams.venueid;

    vm.networkType = {};
    vm.selectedStrategies = [];
    vm.submitted = false;
    vm.provider = '';

    vm.setProvider = function(){
      vm.provider = $stateParams.provider;
      if(vm.provider !== '1'){
        vm.networkType.vlanId = 700;
      } else {
        vm.networkType.vlanId = '';
      }
    };

    vm.createNetworkType = function (valid) {
      vm.networkType.networkStrategies = vm.selectedStrategies;
      //$log.info(vm.networkType);
      vm.submitted = true;
      if (valid) {
        Api.createNetworkType(venueid, vm.networkType).then(function (success) {
          $log.info(success);
          $state.go('admin.managenetwork', {
            venueid: venueid
          });
        }, function (err) {
          $log.info(err);
        });
      }
    };
    vm.setProvider();
  
    Api.getCategories(vm.provider).then(function (data) {
      vm.ntstrategies = data.data.categories;
      $log.info(vm.ntstrategies);
    }, function (err) {
      $log.info('Error: ', err);
    });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name newlist
   * @description
   *
   */
  angular
    .module('newlist', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name newlist.controller:NewlistCtrl
   *
   * @description
   *
   */
  NewlistCtrl.$inject = ["Api", "$state", "$stateParams"];
  angular
    .module('newlist')
    .controller('NewlistCtrl', NewlistCtrl);

  function NewlistCtrl(Api, $state, $stateParams) {
    var vm = this;
    vm.ctrlName = 'NewlistCtrl';
    vm.list = {};
    vm.submitted = false;
    vm.venueid = $stateParams.venueid;
    vm.createList = function(valid) {
      vm.submitted = true;
      if (valid) {
        vm.list.apiKey = $stateParams.apiKey;
        Api.createList(vm.list).success(function(data) {
          console.log(data);
          $state.go('admin.mailchimp', {
            venueid: vm.venueid
          });
        }, function(err) {
          console.log(err);
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name modalservice
   * @description
   *
   */
  angular
    .module('modalservice', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name modalservice.controller:NetworktypemodalCtrl
   *
   * @description
   *
   */
  NetworktypemodalCtrl.$inject = ["$modalInstance", "$log", "Api", "infrastructure"];
  angular
    .module('modalservice')
    .controller('NetworktypemodalCtrl', NetworktypemodalCtrl);

  function NetworktypemodalCtrl($modalInstance, $log, Api, infrastructure) {
    var vm = this;
    vm.ctrlName = 'NetworktypemodalCtrl';
    vm.selected = '';
    vm.infrastructure = infrastructure;
    vm.disabled = true;
    vm.error = '';

    vm.ok = function () {
      var data = {
        choice: true,
        infrastructure: vm.infrastructure
      };
      $modalInstance.close(data);
    };

    vm.no = function () {
      var data = {
        choice: false
      };
      $modalInstance.close(data);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name modalservice.controller:ModalserviceCtrl
   *
   * @description
   *
   */
  ModalserviceCtrl.$inject = ["$modalInstance", "message"];
  angular
    .module('modalservice')
    .controller('ModalserviceCtrl', ModalserviceCtrl);

  function ModalserviceCtrl($modalInstance, message) {
    var vm = this;
    vm.ctrlName = 'ModalserviceCtrl';

    vm.message = message;

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:DeletevenuemodalCtrl
   *
   * @description
   *
   */
  CreateFromControllerCtrl.$inject = ["$modalInstance", "$log", "Api", "infrastructure"];
  angular
    .module('venues')
    .controller('CreateFromControllerCtrl', CreateFromControllerCtrl);

  function CreateFromControllerCtrl($modalInstance, $log, Api, infrastructure) {
    var vm = this;
    vm.ctrlName = 'CreateFromControllerCtrl';
    vm.selected = '';
    vm.infrastructure = infrastructure;
    vm.disabled = true;
    vm.error = '';

    vm.readCsv = function(file, callback) {
      var lines = file.split('\n');
      var first = true;
      var mapValues = [];
      var aps = [];
      lines.forEach(function (ap) {
        var apVar = ap.split(',');
        if(first){
          apVar.forEach(function(plotMap) {
            mapValues.push(plotMap);
          });
          first = false;
        } else {
          var length = apVar.length;
          var index = 0;
          var ap = {};
          for(index; index<length; index++) {
            ap[mapValues[index]] = apVar[index].replace(/\"/g, '');
          }
          aps.push(ap);
        }
      });
      callback(aps);
    };

    vm.ok = function () {
      if (vm.csvfile) {
        vm.readCsv(vm.csvfile, function(result){
          var mappedAps = result.map(function(ap) {
            return {
              mac:Object.entries(ap)[1][1],
              name:ap.Hostname,
              type:"uap",
              wlangroup:"default"
            }
          });
          var data = {
            choice: true,
            aps: mappedAps,
            infrastructure: vm.infrastructure
          };
         $modalInstance.close(data);
        });
      } else {
        var data = {
          choice: true,
          infrastructure: vm.infrastructure
        };
       $modalInstance.close(data);
      }
    };

    vm.no = function () {
      var data = {
        choice: false
      };
      $modalInstance.close(data);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

  }
}());
angular.module('venues').directive("fileread", [function () {
    return {
        scope: {
            fileread: "="
        },
        link: function (scope, element, attributes) {
            element.bind("change", function (changeEvent) {
                var reader = new FileReader();
                reader.onload = function (loadEvent) {
                    scope.$apply(function () {
                        scope.fileread = loadEvent.target.result;
                    });
                }
                reader.readAsText(changeEvent.target.files[0]);
            });
        }
    }
}]);

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name organisations.controller:OrgdeletemodalCtrl
   *
   * @description
   *
   */
  BulkZoneCreateModalCtrl.$inject = ["$modalInstance", "Api", "infrastructure", "$cookies"];
  angular
    .module('modalservice')
    .controller('BulkZoneCreateModalCtrl', BulkZoneCreateModalCtrl);

  function BulkZoneCreateModalCtrl($modalInstance, Api, infrastructure, $cookies) {
    var vm = this;
    vm.ctrlName = 'BulkZoneCreateModalCtrl';
    vm.controller = infrastructure;
    vm.wlans = [];
    vm.zones = [];
    vm.selected = [];
    vm.error = '';
    vm.selectAllButton = 'Select All';

    vm.getZones= function () {
      Api.getZones(vm.controller.venue).success(function(data){
        data.zones.forEach(function(item){
          if(item.details.wlangroup){
            vm.zones.push(item);
          } else if (item.details.siteid){
            item.details.wlangroup = item.details.siteid;
            vm.zones.push(item);
          }
        });
      }).error(function(error){
        vm.error = 'There was a problem Creating your Zones. Please check you controller.';
      });
    };

    vm.getWlans = function () {
      Api.getWlans(vm.controller.provider, vm.controller).success(function(data){
        var cookie = $cookies.get('unifitoken');
        if (cookie === undefined) {
          var expireDate =  new Date((new Date()).getTime() + 10*60000);
          $cookies.put('unifitoken', data.cookie, {'expires': expireDate});
        }
        var siteIndex;
        for(siteIndex = 0; siteIndex < data.data.length; siteIndex++) {
          var wlan = data.data[siteIndex];
          wlan.status = 'Select';
          vm.zones.forEach(function(item){
            if (item.details.wlangroup === wlan._id) {
              wlan.status = 'Created';
            }
          });
          vm.wlans.push(wlan);
        }
      }).error(function(error){
        vm.error = 'There was a problem Creating your Zones. Please check you controller.';
      });
    };
    vm.getZones();
    vm.getWlans();

    vm.selectedSite = function(wlan) {
      vm.selected.push(wlan);
      var siteIndex;
      for(siteIndex = 0; siteIndex < vm.wlans.length; siteIndex++) {
        if (wlan._id ===  vm.wlans[siteIndex]._id) {
          vm.wlans[siteIndex].status = 'Selected';
        }
      }
    };

    vm.deselectSite = function(wlan) {
      vm.selectAllButton = 'Select All';
      var selectIndex;
      for(selectIndex = 0; selectIndex < vm.selected.length; selectIndex++) {
        if (wlan.name ===  vm.selected[selectIndex].name) {
          vm.selected.splice(selectIndex, selectIndex+1);
        }
      }
      var siteIndex;
      for(siteIndex = 0; siteIndex < vm.wlans.length; siteIndex++) {
        if (wlan._id ===  vm.wlans[siteIndex]._id) {
          vm.wlans[siteIndex].status = 'Select';
        }
      }
    };

    vm.selectAll = function() {
      var siteIndex;
      if (vm.selectAllButton === 'Select All') {
        for(siteIndex = 0; siteIndex < vm.wlans.length; siteIndex++) {
          if (vm.wlans[siteIndex].status === 'Select') {
            vm.selected.push(vm.wlans[siteIndex]);
            vm.wlans[siteIndex].status = 'Selected';
          }
        }
        vm.selectAllButton = 'Deselect All';
      } else {
        for(siteIndex = 0; siteIndex < vm.wlans.length; siteIndex++) {
          if (vm.wlans[siteIndex].status === 'Selected') {
            vm.selected = [];
            vm.wlans[siteIndex].status = 'Select';
          }
        }
        vm.selectAllButton = 'Select All';
      }
    };

    vm.bulkCreate = function() {
      var bulk = {
        venueId: vm.controller.venue,
        zones: []
      };
      vm.selected.forEach(function(item){
        var zone = {
          name: item.name,
          details: {wlangroup:item._id}
        };
        if (vm.controller.provider === 6) {
          zone.details.siteid = zone.details.wlangroup;
          delete zone.details['wlangroup'];
        }
        bulk.zones.push(zone);
      });
      Api.bulkZone(vm.controller.venue, bulk).success(function(data){
        data = {};
        $modalInstance.close();
      }).error(function(error){
        vm.error = 'There was a problem Creating your Zones. Please check you controller.';
      });
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name organisations.controller:OrgdeletemodalCtrl
   *
   * @description
   *
   */
  BulkVenueCreateModalCtrl.$inject = ["$modalInstance", "Api", "infrastructure", "$cookies"];
  angular
    .module('modalservice')
    .controller('BulkVenueCreateModalCtrl', BulkVenueCreateModalCtrl);

  function BulkVenueCreateModalCtrl($modalInstance, Api, infrastructure, $cookies) {
    var vm = this;
    vm.ctrlName = 'BulkVenueCreateModalCtrl';
    vm.controller = infrastructure;
    vm.sites = [];
    vm.venues = [];
    vm.selected = [];
    vm.error = '';
    vm.selectAllButton = 'Select All';

    vm.getVenues= function () {
      Api.getVenues().success(function(data){
        data.venues.forEach(function(item){
          if(item.siteid){
            vm.venues.push(item);
          }
        });
      }).error(function(error){
        console.log(error.message);
        vm.error = 'There was a problem Creating your Venues. Please check you controller.';
      });
    };

    vm.getSites = function () {
      Api.getSites(vm.controller.provider, vm.controller).success(function(data){
        var siteIndex;
        if(data.cookie){
          var cookie = $cookies.get('unifitoken');
          if (cookie === undefined) {
            var expireDate =  new Date((new Date()).getTime() + 10*60000);
            $cookies.put('unifitoken', data.cookie, {'expires': expireDate});
          }
        }
        for(siteIndex = 0; siteIndex < data.data.length; siteIndex++) {
          var site = data.data[siteIndex];
          site.status = 'Select';
          vm.venues.forEach(function(item){
            if (item.siteid === site.id) {
              site.status = 'Created';
            }
          });
          vm.sites.push(site);
        }
      }).error(function(error){
        vm.error = 'There was a problem Creating your Venues. Please check you controller.';
      });
    };
    vm.getVenues();
    vm.getSites();

    vm.selectedSite = function(site) {
      vm.selected.push(site);
      var siteIndex;
      for(siteIndex = 0; siteIndex < vm.sites.length; siteIndex++) {
        if (site.id ===  vm.sites[siteIndex].id) {
          vm.sites[siteIndex].status = 'Selected';
        }
      }
      vm.selectAllButton = 'Deselect All';
    };

    vm.deselectSite = function(site) {
      var selectIndex;
      for(selectIndex = 0; selectIndex < vm.selected.length; selectIndex++) {
        if (site.id ===  vm.selected[selectIndex].id) {
          vm.selected.splice(selectIndex, selectIndex+1);
        }
      }
      var siteIndex;
      for(siteIndex = 0; siteIndex < vm.sites.length; siteIndex++) {
        if (site.id ===  vm.sites[siteIndex].id) {
          vm.sites[siteIndex].status = 'Select';
        }
      }
      if (vm.selected.length === 0) {
        vm.selectAllButton = 'Select All';
      }
    };

    vm.selectAll = function() {
      var siteIndex;
      if (vm.selectAllButton === 'Select All') {
        for(siteIndex = 0; siteIndex < vm.sites.length; siteIndex++) {
          if (vm.sites[siteIndex].status === 'Select') {
            vm.selected.push(vm.sites[siteIndex]);
            vm.sites[siteIndex].status = 'Selected';
          }
        }
        vm.selectAllButton = 'Deselect All';
      } else {
        for(siteIndex = 0; siteIndex < vm.sites.length; siteIndex++) {
          if (vm.sites[siteIndex].status === 'Selected') {
            vm.selected = [];
            vm.sites[siteIndex].status = 'Select';
          }
        }
        vm.selectAllButton = 'Select All';
      }
    };

    vm.bulkCreate = function() {
      var bulk = {
        infrastructure: vm.controller,
        venues: vm.selected.map(function(item){
          return {
            id: item.id,
            name: item.site
          }
        })
      };
      bulk.infrastructure.providerId = bulk.infrastructure.provider;

      if (bulk.infrastructure.providerId === 5) {
        bulk.infrastructure.details = {"mOrgId": bulk.infrastructure.username};
      } else {
        bulk.infrastructure.details = JSON.stringify({});
      }


      Api.setGuestSettings(bulk.infrastructure.provider, bulk).then(function successCallback(response) {
        Api.createBulkVenues(bulk.infrastructure.provider, bulk).success(function(data){
          data = {};
          $modalInstance.close();
        }).error(function(error){
          console.log(error.message);
          vm.error = 'There was a problem Creating your Venues. Please check you controller.';
        });
        // }
      }, function errorCallback(response) {
          console.log(error.message);
          vm.error = 'There was a problem Creating your Venues. Please check you controller.';
      });

    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name modalservice.controller:BulkNetworkTypeCreateModalCtrl
   *
   * @description
   *
   */
  BulkNetworkTypeCreateModalCtrl.$inject = ["$modalInstance", "Api", "infrastructure", "$cookies"];
  angular
    .module('modalservice')
    .controller('BulkNetworkTypeCreateModalCtrl', BulkNetworkTypeCreateModalCtrl);

  function BulkNetworkTypeCreateModalCtrl($modalInstance, Api, infrastructure, $cookies) {
    var vm = this;
    vm.ctrlName = 'BulkNetworkTypeCreateModalCtrl';
    vm.controller = infrastructure;
    vm.netTypes = [];
    vm.userGroups = [];
    vm.selected = [];
    vm.error = '';
    vm.selectAllButton = 'Select All';

    vm.getNetTypes= function () {
      Api.getNetworkTypes(vm.controller.venue).success(function(data){
        data.networkTypes.forEach(function(item){
          if(item.details.usergroup){
            vm.netTypes.push(item);
          }
        });
      }).error(function(error){
        vm.error = 'There was a problem Creating your Network Type. Please check you controller.';
      });
    };

    vm.getUserGroups = function () {
      Api.getUserGroups(vm.controller.provider, vm.controller).success(function(data){
        var siteIndex;
        var cookie = $cookies.get('unifitoken');
        if (cookie === undefined) {
          var expireDate =  new Date((new Date()).getTime() + 10*60000);
          $cookies.put('unifitoken', data.cookie, {'expires': expireDate});
        }
        for(siteIndex = 0; siteIndex < data.data.length; siteIndex++) {
          var usergroup = data.data[siteIndex];
          usergroup.status = 'Select';
          vm.netTypes.forEach(function(item){
            if (item.details.usergroup === usergroup._id) {
              usergroup.status = 'Created';
            }
          });
          vm.userGroups.push(usergroup);
        }
      }).error(function(error){
        vm.error = 'There was a problem Creating your Network Type. Please check you controller.';
      });
    };
    vm.getNetTypes();
    vm.getUserGroups();

    vm.selectedSite = function(userGroup) {
      vm.selected.push(userGroup);
      var siteIndex;
      for(siteIndex = 0; siteIndex < vm.userGroups.length; siteIndex++) {
        if (userGroup._id ===  vm.userGroups[siteIndex]._id) {
          vm.userGroups[siteIndex].status = 'Selected';
        }
      }
    };

    vm.deselectSite = function(userGroup) {
      var selectIndex;
      for(selectIndex = 0; selectIndex < vm.selected.length; selectIndex++) {
        if (userGroup.name ===  vm.selected[selectIndex].name) {
          vm.selected.splice(selectIndex, selectIndex+1);
        }
      }
      var siteIndex;
      for(siteIndex = 0; siteIndex < vm.userGroups.length; siteIndex++) {
        if (userGroup._id ===  vm.userGroups[siteIndex]._id) {
          vm.userGroups[siteIndex].status = 'Select';
        }
      }
    };

    vm.selectAll = function() {
      var siteIndex;
      if (vm.selectAllButton === 'Select All') {
        for(siteIndex = 0; siteIndex < vm.userGroups.length; siteIndex++) {
          if (vm.userGroups[siteIndex].status === 'Select') {
            vm.selected.push(vm.userGroups[siteIndex]);
            vm.userGroups[siteIndex].status = 'Selected';
          }
        }
        vm.selectAllButton = 'Deselect All';
      } else {
        for(siteIndex = 0; siteIndex < vm.userGroups.length; siteIndex++) {
          if (vm.userGroups[siteIndex].status === 'Selected') {
            vm.selected = [];
            vm.userGroups[siteIndex].status = 'Select';
          }
        }
        vm.selectAllButton = 'Select All';
      }
    };

    vm.bulkCreate = function() {
      var bulk = {
        venueId: vm.controller.venue,
        networkTypes: []
      };
        vm.selected.forEach(function(item){
          var networkType = {
            name: item.name,
            details: {usergroup:item._id},
            vlanId: 700,
            overallbandwidth:null
          };
          bulk.networkTypes.push(networkType);
        });
        Api.createBulkNetworkType(vm.controller.venue, bulk).success(function(data){
          data = {};
          $modalInstance.close();
        }).error(function(error){
          vm.error = 'There was a problem Creating your Network Type. Please check you controller.';
        });
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name organisations.controller:OrgdeletemodalCtrl
   *
   * @description
   *
   */
  BulkApCreateModalCtrl.$inject = ["$modalInstance", "Api", "infrastructure", "aps", "$cookies"];
  angular
    .module('modalservice')
    .controller('BulkApCreateModalCtrl', BulkApCreateModalCtrl);

  function BulkApCreateModalCtrl($modalInstance, Api, infrastructure, aps, $cookies) {
    var vm = this;
    vm.ctrlName = 'BulkApCreateModalCtrl';
    vm.controller = infrastructure;
    vm.accesspoints = [];
    vm.aps = [];
    vm.zones = [];
    vm.selected = [];
    vm.error = '';
    vm.selectAllButton = 'Select All';

    vm.getZones = function () {
      Api.getZones(vm.controller.venue).success(function(data){
        data.zones.forEach(function(item){
          if(item.details.wlangroup){
            vm.zones.push(item);
          } else if (item.details.siteid){
            item.details.wlangroup = item.details.siteid;
            vm.zones.push(item);
          }
        });
      }).error(function(error){
        vm.error = 'There was a problem Creating your Accesspoints. Please check you have create all the required Zones.';
      });
    };

    vm.getAps= function () {
      Api.getAccessPoints(vm.controller.venue).success(function(data){
        data.apoints.forEach(function(item){
            vm.accesspoints.push(item);
        });
        vm.getApoints();
      }).error(function(error){
        vm.error = 'There was a problem Creating your Accesspoints. Please check you have create all the required Zones.';
      });
    };

    vm.getApoints = function () {
      if (aps.length > 1) {
        var siteIndex;
        for(siteIndex = 0; siteIndex < aps.length; siteIndex++) {
          var ap = aps[siteIndex];
          ap.status = 'Select';
          vm.accesspoints.forEach(function(item){
            if (item.hwaddr === ap.mac) {
              ap.status = 'Created';
            }
          });
          vm.aps.push(ap);
        }
      } else {
        Api.getApoints(vm.controller.provider, vm.controller).success(function(data){
          var cookie = $cookies.get('unifitoken');
          if (cookie === undefined) {
            var expireDate =  new Date((new Date()).getTime() + 10*60000);
            $cookies.put('unifitoken', data.cookie, {'expires': expireDate});
          }
          var siteIndex;
          for(siteIndex = 0; siteIndex < data.data.length; siteIndex++) {
            var ap = data.data[siteIndex];
            if (ap.type === 'uap') {
              ap.status = 'Select';
              vm.accesspoints.forEach(function(item){
                if (item.hwaddr === ap.mac) {
                  ap.status = 'Created';
                }
              });
              vm.aps.push(ap);
            }
          }
        }).error(function(error){
          vm.error = 'There was a problem Creating your Accesspoints. Please check you have create all the required Zones.';
        });
      }
    };
    vm.getZones();
    vm.getAps();

    vm.selectedSite = function(ap) {
      vm.selected.push(ap);
      var siteIndex;
      for(siteIndex = 0; siteIndex < vm.aps.length; siteIndex++) {
        if (ap.mac ===  vm.aps[siteIndex].mac) {
          vm.aps[siteIndex].status = 'Selected';
        }
      }
    };

    vm.deselectSite = function(ap) {
      vm.selectAllButton = 'Select All';
      var selectIndex;
      for(selectIndex = 0; selectIndex < vm.selected.length; selectIndex++) {
        if (ap.mac ===  vm.selected[selectIndex].mac) {
          vm.selected.splice(selectIndex, selectIndex+1);
        }
      }
      var siteIndex;
      for(siteIndex = 0; siteIndex < vm.aps.length; siteIndex++) {
        if (ap.mac ===  vm.aps[siteIndex].mac) {
          vm.aps[siteIndex].status = 'Select';
        }
      }
    };

    vm.selectAll = function() {
      var siteIndex;
      if (vm.selectAllButton === 'Select All') {
        for(siteIndex = 0; siteIndex < vm.aps.length; siteIndex++) {
          if (vm.aps[siteIndex].status === 'Select') {
            vm.selected.push(vm.aps[siteIndex]);
            vm.aps[siteIndex].status = 'Selected';
          }
        }
        vm.selectAllButton = 'Deselect All';
      } else {
        for(siteIndex = 0; siteIndex < vm.aps.length; siteIndex++) {
          if (vm.aps[siteIndex].status === 'Selected') {
            vm.selected = [];
            vm.aps[siteIndex].status = 'Select';
          }
        }
        vm.selectAllButton = 'Select All';
      }
    };

    vm.bulkCreate = function() {
      var bulk = {
        venueId: vm.controller.venue,
        accesspoints: []
      };
      var siteIndex;
      var ap = {};
      console.log(vm.selected);
      vm.selected.forEach(function(item){
        for(siteIndex = 0; siteIndex < vm.zones.length; siteIndex++) {
          if (item.wlangroup_id_na === vm.zones[siteIndex].details.wlangroup || item.wlangroup_id_ng === vm.zones[siteIndex].details.wlangroup || item.wlangroup === vm.zones[siteIndex].details.wlangroup) {
            ap = {
              hwAddr: item.mac,
              ipAddress: item.ip,
              sshUsername: '',
              sshPassword: '',
              name: item.name,
              zoneId: vm.zones[siteIndex].id
            };
            bulk.accesspoints.push(ap);
          }
        }
      });
      Api.bulkAccessPoint(vm.controller.venue, vm.controller.provider, bulk).success(function(data){
          data = {};
          $modalInstance.close();
        }).error(function(error){
          vm.error = 'There was a problem Creating your Accesspoints. Please check you have create all the required Zones.';
        });
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name managenetwork
   * @description
   *
   */
  angular
    .module('managenetwork', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name managenetwork.controller:ManagenetworkCtrl
   *
   * @description
   *
   */
  ManagenetworkCtrl.$inject = ["Auth", "Api", "ApiAnalytic", "$log", "$stateParams", "$state", "$modal", "$cookies"];
  angular
    .module('managenetwork')
    .controller('ManagenetworkCtrl', ManagenetworkCtrl);

  function ManagenetworkCtrl(Auth, Api, ApiAnalytic, $log, $stateParams, $state, $modal, $cookies) {
    var vm = this;

    vm.zones = [];
    vm.accesspoints = [];
    vm.infrastructures = [];
    vm.networkTypes = [];
    vm.venue = {};

    var venueid = $stateParams.venueid;
    var orgid = Auth.getAuthData().operator.organisation;
    console.log('orgid', orgid);

    vm.showZones = false;
    vm.showAPoints = false;
    vm.showTypes = false;
    vm.showVenueMap = false;
    vm.showInfra = true;

    vm.setNetworkView = function (view) {
      switch (view) {
      case 'zone':
        vm.showZones = true;
        vm.showAPoints = false;
        vm.showTypes = false;
        vm.showVenueMap = false;
        vm.showInfra = false;
        break;
      case 'apoints':
        vm.showZones = false;
        vm.showAPoints = true;
        vm.showTypes = false;
        vm.showVenueMap = false;
        vm.showInfra = false;
        break;
      case 'types':
        vm.showZones = false;
        vm.showAPoints = false;
        vm.showTypes = true;
        vm.showVenueMap = false;
        vm.showInfra = false;
        break;
      case 'infra':
        vm.showZones = false;
        vm.showAPoints = false;
        vm.showTypes = false;
        vm.showVenueMap = false;
        vm.showInfra = true;
        break;
      case 'venuemap':
        vm.showZones = false;
        vm.showAPoints = false;
        vm.showTypes = false;
        vm.showInfra = false;
        vm.showVenueMap = true;
        break;
      }
    };
    if ($cookies.get('view')) {
      vm.setNetworkView($cookies.get('view'));
    }

    function getZones(venueid) {
      Api.getZones(venueid).then(function (res) {
        vm.zones = res.data.zones;
        $log.info(vm.zones);
      }, function (err) {
        $log.info(err);
      });
    }

    function getAccessPoints(venueid) {
      Api.getAccessPoints(venueid).then(function (res) {
        vm.accesspoints = res.data.apoints;
        $log.info(vm.accesspoints);
      }, function (err) {
        $log.info(err);
      });
    }

    function getVenue(venueid) {
      Api.getVenue(venueid).then(function (res) {
        vm.venue = res.data.venue;
        $log.info(vm.venue);
      }, function (err) {
        $log.info(err);
      });
    }

    function getNetworkTypes(venueid) {
      Api.getNetworkTypes(venueid).then(function (res) {
        vm.networkTypes = res.data.networkTypes;
        $log.info(vm.networkTypes);
      }, function (err) {
        $log.info(err);
      });
    }
    function getMapsByVenue(venueid) {
      ApiAnalytic.getMapsByVenue(venueid).then(function (res) {
        vm.maps = res.data;
        $log.info(vm.maps);
      }, function (err) {
        $log.info(err);
      });
    }

    function getInfrastructures(venueid) {
      Api.getInfrastructures(venueid).then(function (res) {
        var infra = {};
        for (var item in res.data.infrastructures) {
          infra = res.data.infrastructures[item];
          var provider = JSON.parse(res.data.infrastructures[item].provider);
          infra.provider = provider;
          vm.infrastructures.push(infra);
        }
        $log.info(vm.infrastructures);
      }, function (err) {
        $log.info(err);
      });
    }

    Api.getProviders().then(function(res){
      vm.providers = res.data.providers;
    }, function(err){
      console.log(err);
    });

    vm.matchProvider = function(input, id){
      var returning = false;
      vm.providers.forEach(function(item){
        if(item.id === id){
          if (item.name.toLowerCase() === input){
            returning = true;
          }
        }
      });
      return returning;
    };

    vm.deleteZone = function (id) {
      $cookies.put('view', 'zone');
      vm.modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Zone, are you sure?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {
        Api.deleteZone(id, venueid).success(function (data) {
          $log.info(data);
          getZones(venueid);
        }).error(function () {
          vm.modalMessage = {
            ok: 'OK',
            messageHeader: 'Cannot delete zones with accesspoints attached',
          };

          var modalInstance = $modal.open({
            templateUrl: 'modalservice/views/modalservice.tpl.html',
            controller: 'ModalserviceCtrl as modalService',
            resolve: {
              message: function () {
                return vm.modalMessage;
              }
            }
          });
          modalInstance.result.then(function () {});
        });
      });
    };

    vm.deleteAccesspoint = function (id) {
      $cookies.put('view', 'apoints');
      vm.modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Access Point?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {
        Api.deleteAccessPoint(id, venueid).success(function (data) {
          $log.info(data);
          getAccessPoints(venueid);
        }).error(function () {
          $log.info('get Zones error');
        });
      });
    };

    vm.createZone = function () {
      $cookies.put('view', 'zone');
      if (vm.matchProvider('unifi', vm.infrastructures[0].provider) || vm.matchProvider('ruckus', vm.infrastructures[0].provider)) {
        var newModalInstance = $modal.open({
          templateUrl: 'modalservice/views/createfromcontroller.tpl.html',
          controller: 'CreateFromControllerCtrl as createFromController',
          resolve: {
            infrastructure: function(){
              return vm.infrastructures[0];
            }
          }
        });

        newModalInstance.result.then(function(data) {
          if (data.choice) {
            var newModalInstance = $modal.open({
              templateUrl: 'modalservice/views/bulkzonecreatemodal.tpl.html',
              controller: 'BulkZoneCreateModalCtrl as bulkZoneCreateModal',
              resolve: {
                infrastructure: function(){
                  return data.infrastructure;
                }
              }
            });
            newModalInstance.result.then(function() {
              getZones(venueid);
            });
          } else {
            $state.go('admin.addzone', {
              venueid: venueid,
              provider: vm.infrastructures[0].provider
            });
          }
        });
      } else {
        $state.go('admin.addzone', {
          venueid: venueid,
          provider: vm.infrastructures[0].provider
        });
      }
    };

    vm.createAccesspoint = function () {
      $cookies.put('view', 'apoints');
      if (vm.matchProvider('unifi', vm.infrastructures[0].provider) || vm.matchProvider('ruckus', vm.infrastructures[0].provider) || vm.matchProvider('xirrus', vm.infrastructures[0].provider) || vm.matchProvider('meraki', vm.infrastructures[0].provider)) {
        var newModalInstance = $modal.open({
          templateUrl: 'modalservice/views/createfromcontroller.tpl.html',
          controller: 'CreateFromControllerCtrl as createFromController',
          resolve: {
            infrastructure: function(){
              return vm.infrastructures[0];
            }
          }
        });

        newModalInstance.result.then(function(data) {
          if (data.choice) {
            var newModalInstance;
            if (data.aps) {
              newModalInstance = $modal.open({
                templateUrl: 'modalservice/views/bulkapcreatemodal.tpl.html',
                controller: 'BulkApCreateModalCtrl as bulkApCreateModal',
                resolve: {
                  infrastructure: function(){
                    return data.infrastructure;
                  },
                  aps: function(){
                    return data.aps;
                  }
                }
              });
            } else {
              newModalInstance = $modal.open({
                templateUrl: 'modalservice/views/bulkapcreatemodal.tpl.html',
                controller: 'BulkApCreateModalCtrl as bulkApCreateModal',
                resolve: {
                  infrastructure: function(){
                    return data.infrastructure;
                  },
                  aps: function(){
                    return {};
                  }
                }
              });
            }
            newModalInstance.result.then(function() {
              getAccessPoints(venueid);
            });
          } else {
            $state.go('admin.addap', {
              venueid: venueid,
              provider: vm.infrastructures[0].provider
            });
          }
        });
      } else {
        $state.go('admin.addap', {
          venueid: venueid,
          provider: vm.infrastructures[0].provider
        });
      }
    };

    vm.createInfrastructure = function () {
      $cookies.put('view', 'infra');
      $state.go('admin.addInfrastructure', {
        venueid: venueid
      });
    };

    vm.editInfrastructure = function (infraid) {
      $cookies.put('view', 'infra');
      $state.go('admin.editinfrastructure', {
        infraid: infraid,
        venueid: venueid
      });
    };

    vm.editZone = function (zoneid) {
      $cookies.put('view', 'zone');
      $state.go('admin.editzone', {
        zoneid: zoneid,
        venueid: venueid,
        orgid: orgid,
        provider: vm.infrastructures[0].provider
      });
    };

    vm.editAccesspoint = function (apid) {
      $cookies.put('view', 'apoints');
      $state.go('admin.editap', {
        apid: apid,
        venueid: venueid
      });
    };

    vm.createNetworkType = function () {
      $cookies.put('view', 'types');
      if (vm.matchProvider('unifi', vm.infrastructures[0].provider)) {
        var newModalInstance = $modal.open({
          templateUrl: 'modalservice/views/createfromcontroller.tpl.html',
          controller: 'CreateFromControllerCtrl as createFromController',
          resolve: {
            infrastructure: function(){
              return vm.infrastructures[0];
            }
          }
        });

        newModalInstance.result.then(function(data) {
          if (data.choice) {
            var newModalInstance = $modal.open({
              templateUrl: 'modalservice/views/bulknetworktypecreatemodal.tpl.html',
              controller: 'BulkNetworkTypeCreateModalCtrl as bulkNetworkTypeCreateModal',
              resolve: {
                infrastructure: function(){
                  return data.infrastructure;
                }
              }
            });
            newModalInstance.result.then(function() {
              getNetworkTypes(venueid);
            });
          } else {
            $state.go('admin.networktype', {
              venueid: venueid,
              provider: vm.infrastructures[0].provider
            });
          }
        });
      } else {
        $state.go('admin.networktype', {
          venueid: venueid,
          provider: vm.infrastructures[0].provider
        });
      }
    };

    vm.editNetworkType = function (id) {
      $cookies.put('view', 'types');
      $state.go('admin.editnetworktype', {
        venueid: venueid,
        typeid: id,
        provider: vm.infrastructures[0].provider
      });
    };

    vm.deleteNetworkType = function (id) {
      $cookies.put('view', 'types');
      vm.modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Network Type?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {
        Api.deleteNetworkType(id, venueid).then(function () {
          getNetworkTypes(venueid);
        }, function (err) {
          $log.info(err);
        });
      });
    };

    // map calls
    vm.createVenueMap = function () {
      $cookies.put('view', 'venuemap');
      $state.go('admin.addvenuemap', {
        venueid: venueid
      });
    };
    vm.editMap = function (id) {
      $cookies.put('view', 'venuemap');
      $state.go('admin.editvenuemap', {
        venueid: venueid,
        mapId: id,
      });
    };
    vm.deleteMap = function (id) {
      $cookies.put('view', 'venuemap');
      vm.modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Network Type?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {
        ApiAnalytic.deleteMap(venueid, id).then(function () {
          getMapsByVenue(venueid);
        }, function (err) {
          $log.info(err);
        });
      });
    };

    getZones(venueid);
    getVenue(venueid);
    getAccessPoints(venueid);
    getNetworkTypes(venueid);
    getMapsByVenue(venueid);
    getInfrastructures(venueid);
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name manageimages
   * @description
   *
   */
  angular
    .module('manageimages', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';
  /**
   * @ngdoc object
   * @name manageimages.controller:UploadmodalCtrl
   *
   * @description
   *
   */
  UploadmodalCtrl.$inject = ["$modalInstance", "Auth", "baseurl", "baseendpoint", "Upload", "$timeout", "Api", "venue"];
  angular.module('manageimages').controller('UploadmodalCtrl', UploadmodalCtrl);

  function UploadmodalCtrl($modalInstance, Auth, baseurl, baseendpoint, Upload, $timeout, Api, venue) {
    var vm = this,
      token = '',
      venueid;

    vm.venues = [];
    vm.venueId = venue;

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
      venueid = Auth.getAuthData().operator.venueid;

    }

    Api.getVenues().success(function (data) {
      vm.venues = data.venues;
    }).error(function () {
      console.log("cannot get venues");
    });

    vm.uploadFiles = function (file) {
      vm.f = file;
      if (file) {
        file.upload = Upload.upload({
          url: baseurl + baseendpoint + '/images/venues/' + vm.venueId,
          data: {
            image: file,
            details: {
              description: vm.description
            }
          },
          headers: {
            'x-access-token': token
          }
        });
        file.upload.then(function (response) {
          $timeout(function () {
            file.result = response.data;
            vm.imageAdded = true;
            vm.image = response.data.imageURI;
            vm.imageID = response.data.id;
            response.data.venue = vm.venueId;
            $modalInstance.close(response.data);
          });
        }, function (response) {
          if (response.status > 0) {
            vm.errorMsg = response.status + ': ' + response.data;
            console.log(String(vm.errorMsg));
          }
        }, function (evt) {
          file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        });
      }
    };
    vm.exit = function () {
      $modalInstance.dismiss();
    };
    vm.dismiss = function () {
      vm.message = {};
      console.log('was called');
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name manageimages.controller:ManageimagesCtrl
   *
   * @description
   *
   */
  ManageimagesCtrl.$inject = ["$modal", "Api", "Auth"];
  angular
    .module('manageimages')
    .controller('ManageimagesCtrl', ManageimagesCtrl);

  function ManageimagesCtrl($modal, Api, Auth) {
    var vm = this;

    vm.imageList = [];
    vm.limits = [1, 5, 10, 15, 20, 50, 'all'];
    vm.all = 0;
    vm.limit = 0;
    vm.venues = [];
    vm.venueId = 0;
    vm.role = 0;

    if (Auth.getAuthData()) {
      vm.profileInfo = Auth.getAuthData().operator;
      vm.role = vm.profileInfo.role;
      console.log(vm.profileInfo);
      if (vm.role === 2) {
        vm.venueId = vm.profileInfo.details.venue;
        Api.getImagesVenue(vm.venueId).then(function (res) {
          vm.imageList = res.data.images;
          vm.limit = vm.imageList.length;
          vm.all = vm.imageList.length;
        }, function (err) {
          console.log(err);
        });
      }
      else{
        Api.getVenues().success(function (data) {
          vm.venues = data.venues;
          if (vm.venues.length > 0) {
            vm.venueId = vm.venues[0].id;
            vm.getImagesByVenue(vm.venueId);
          }
        }).error(function () {
          console.log('cannot get venues');
        });
      }
    }

    vm.setLimit = function (limit) {
      console.log(limit);
      if (limit === 'all') {
        vm.limit = vm.all;
      }
    };

    // function getImages() {
    //   Api.getImages().success(function (data) {
    //     vm.imageList = data.images;
    //     vm.limit = vm.imageList.length;
    //     vm.all = vm.imageList.length;
    //     console.log(vm.imageList);
    //   });
    // }

    vm.getImagesByVenue = function (venueid) {
      Api.getImagesVenue(venueid).then(function (res) {
        vm.imageList = res.data.images;
        vm.limit = vm.imageList.length;
        vm.all = vm.imageList.length;
      }, function (err) {
        console.log(err);
      });
    };

    vm.deleteImage = function (image) {
      var modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Image, are you sure?',
        messageBody: 'Note! Images associated with a Venue/Event cannot be deleted.'
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function () {
        Api.deleteImage(image.id, vm.venueId).success(function (resp) {
          console.log(resp);
          vm.getImagesByVenue(vm.venueId);
        }).error(function () {
          var modalMessage = {
            ok: 'Done',
            cancel: '',
            messageHeader: 'Cannot Delete Image',
            messageBody: ''
          };

          var modalInstance = $modal.open({
            templateUrl: 'modalservice/views/modalservice.tpl.html',
            controller: 'ModalserviceCtrl as modalService',
            resolve: {
              message: function () {
                return modalMessage;
              }
            }
          });

          modalInstance.result.then(function () {
          });
        });
      });
    };

    vm.uploadImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log('IMG', image);
        var venueid  = parseInt(image.venue);
        vm.venueId = venueid;
        vm.getImagesByVenue(vm.venueId);
      }, function () {
        console.log('canceled');
      });
    };
    //getImages();
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name main
   * @description
   *
   */
  angular
    .module('main', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name main.controller:MainCtrl
   *
   * @description
   *
   */
  MainCtrl.$inject = ["$rootScope", "Auth", "$state", "$cookies", "$modal"];
  angular
    .module('main')
    .controller('MainCtrl', MainCtrl);

  function MainCtrl($rootScope, Auth, $state, $cookies, $modal) {
    var vm = this;
    vm.ctrlName = 'MainCtrl';

    vm.launchLogin = function () {
      var modalInstance = $modal.open({
        templateUrl: 'main/views/login.tpl.html',
        controller: 'LoginmodalCtrl as modal'
      });

      modalInstance.result.then(function () {
        $state.go('admin.events');
      }, function () {
        console.log('canceled');
      });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name main.controller:LoginmodalCtrl
   *
   * @description
   *
   */
  LoginmodalCtrl.$inject = ["$modalInstance", "Auth", "$cookies"];
  angular
    .module('main')
    .controller('LoginmodalCtrl', LoginmodalCtrl);

  function LoginmodalCtrl($modalInstance, Auth, $cookies) {
    var vm = this;
    vm.ctrlName = 'LoginmodalCtrl';
    vm.loginDetails = {};
    var token;

    vm.login = function () {
      Auth.login(vm.loginDetails).success(function (data) {
        $cookies.putObject('leData', data);
        Auth.setIslogged(true);
        if (Auth.getAuthData()) {
          token = Auth.getAuthData().token;
        }
        $modalInstance.close();
      })
      .error(function () {
        vm.message = {
          type: 'err',
          msg: 'Invalid Credentials. Please try again'
        };
      });
    };

    vm.dismiss = function () {
      vm.message = {};
      console.log('was called');
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name mailchimp
   * @description
   *
   */
  angular
    .module('mailchimp', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mailchimp.controller:MailchimpCtrl
   *
   * @description
   *
   */
  MailchimpCtrl.$inject = ["Api", "$scope", "$log", "$stateParams", "$state"];
  angular
    .module('mailchimp')
    .controller('MailchimpCtrl', MailchimpCtrl);

  function MailchimpCtrl(Api, $scope, $log, $stateParams, $state) {
    var vm = this;
    vm.ctrlName = 'MailchimpCtrl';

    var venueid = $stateParams.venueid;
    vm.venue = {};
    vm.venueDetails = {};
    vm.ConfirmMessage = false;
    vm.ErrorMessage = false;
    vm.Message = [];
    vm.ListConfirmMessage = false;
    vm.ListErrorMessage = false;
    vm.ListMessage = [];

    vm.getApiKey = function(){
      Api.getVenue(venueid).then(function(resp) {
        $log.info(resp);
        vm.venue.venueTitle = resp.data.venue.venuetitle;
        vm.venue.imageUrl = resp.data.venue.imageurl;
        vm.venue.imageId = resp.data.venue.imageid;
        vm.venue.contactEmail = resp.data.venue.contactemail;
        vm.venue.contactPhone = resp.data.venue.contactphone;
        vm.venue.description = resp.data.venue.description;
        vm.venue.location = resp.data.venue.location;
        vm.venue.website =  resp.data.venue.website;
        vm.venueDetails = resp.data.venue.details;
        vm.venueDetails = JSON.parse(vm.venueDetails);
        if(vm.venueDetails.hasOwnProperty('mailchimp')){
          if(vm.venueDetails.mailchimp.hasOwnProperty('apiKey')){
            vm.apiKey = vm.venueDetails.mailchimp.apiKey;
            vm.getLists();
          }
          if(vm.venueDetails.mailchimp.hasOwnProperty('listId')){
            vm.listId = vm.venueDetails.mailchimp.listId;
            vm.newListId = vm.listId;
          }
        }
      }, function(err) {
        $log.info(err);
      });
    };

    vm.getApiKey();


    vm.getLists = function(){
      vm.lists = [];
      Api.getLists(vm.apiKey).then(function(resp) {
        for(var i=0; i<resp.data.lists.length; i++){
          vm.lists.push({id: resp.data.lists[i].id, name: resp.data.lists[i].name});
        }
      }, function(err) {
        $log.info(err);
        vm.lists.length=0;
      });
    };

    vm.updateApiKey = function() {
      vm.submitted = true;
      console.log(vm.venue);
      if(vm.venueDetails.hasOwnProperty('mailchimp')){
        vm.venueDetails.mailchimp.apiKey = vm.apiKey;
        vm.venueDetails.mailchimp.listId = vm.listId;
        vm.venue.details = JSON.stringify(vm.venueDetails);
      }else{
        vm.venueDetails.mailchimp = {};
        vm.venueDetails.mailchimp.apiKey = vm.apiKey;
        vm.venueDetails.mailchimp.listId = vm.listId;
        vm.venue.details = JSON.stringify(vm.venueDetails);
      }

      Api.updateVenue(venueid, vm.venue).success(function(data) {
        console.log('Data - ' + JSON.stringify(data));
        vm.ConfirmMessage = true;
        vm.Message = 'Api Key Updated';
        vm.getLists();
      }, function(err) {
        vm.Message = true;
        vm.Message = err.message;
        console.log('Error = ' + err);
      });
    };

    vm.updateList = function(){
      if(vm.venueDetails.hasOwnProperty('mailchimp')){
          vm.venueDetails.mailchimp.listId = vm.newListId;
          vm.venue.details = JSON.stringify(vm.venueDetails);
        }else{
          vm.venueDetails.mailchimp = {};
          vm.venueDetails.mailchimp.listId = vm.newListId;
          vm.venue.details = JSON.stringify(vm.venueDetails);
        }

        Api.updateVenue(venueid, vm.venue).success(function(data) {
          console.log(data);
          vm.ListConfirmMessage = true;
          vm.ListMessage = 'List Updated';
          vm.getApiKey();
        }, function(err) {
          console.log('Error = ' + err);
        });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ConfirmMessage = false;
    };

    vm.dismissError = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

    vm.listDismiss = function () {
      vm.ListMessage = {};
      vm.ListConfirmMessage = false;
    };

    vm.listDismissError = function () {
      vm.ListMessage = {};
      vm.ListErrorMessage = false;
    };

    vm.createNewList = function(){
      $state.go('admin.newlist', {
        apiKey: vm.apiKey,
        venueid: venueid
      });
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name mailListModal
   * @description
   *
   */
  angular
    .module('mailListModal', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mailListModal.controller:MailListModalCtrl
   *
   * @description
   *
   */
  MailListModalCtrl.$inject = ["mailList", "$modalInstance"];
  angular
    .module('mailListModal')
    .controller('MailListModalCtrl', MailListModalCtrl);

  function MailListModalCtrl(mailList, $modalInstance) {
    var vm = this;
    vm.ctrlName = 'MailListModalCtrl';
    vm.mailList = mailList.data;
    vm.include = mailList.include;
    vm.chimpList = [{'id': 1, 'name':'Guests that used Gym'}, {'id': 2, 'name':'Gym Users'}, {'id': 3, 'name':'Guests No Gym'}];

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name home
   * @description
   *
   */
  angular
    .module('home', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.controller:HomeCtrl
   *
   * @description
   *
   */
  HomeCtrl.$inject = ["$rootScope", "Auth", "$cookies", "$state"];
  angular
    .module('home')
    .controller('HomeCtrl', HomeCtrl);

  function HomeCtrl($rootScope, Auth, $cookies, $state) {
    var vm = this;
    vm.ctrlName = 'HomeCtrl';

    $rootScope.bodyClass = 'home';
    vm.loginDetails={};

    vm.login = function () {
      console.log('called');
      Auth.login(vm.loginDetails).success(function (data) {
        $cookies.putObject('leData', data);
        Auth.setIslogged(true);
        $state.go('admin.events');
      })
      .error(function () {
        vm.message = {
          type: 'err',
          msg: 'Invalid Credentials. Please try again'
        };
      });
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name eventanalytics
   * @description
   *
   */
  angular
    .module('eventanalytics', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name eventanalytics.controller:EventanalyticsCtrl
   *
   * @description
   *
   */
  EventanalyticsCtrl.$inject = ["Api", "$log", "ApiAnalytic", "$stateParams", "$state", "AnalyticsApi", "$interval", "$rootScope", "$cookies"];
  angular.module('eventanalytics').controller('EventanalyticsCtrl', EventanalyticsCtrl);

  function EventanalyticsCtrl(Api, $log, ApiAnalytic, $stateParams, $state, AnalyticsApi, $interval, $rootScope, $cookies) {
    var vm = this;

    vm.venueid = $stateParams.venueid;
    vm.eventid = $stateParams.eventid;
    vm.loading = true;
    vm.dwellLoading = true;
    vm.showActivityZoneTable = false;
    vm.showImpressionsTable = false;
    vm.showDwellTable = false;
    vm.showLoginTable = false;
    vm.showgenderTable = false;
    vm.showFootfallTable = false;
    vm.showProfileTable = false;
    vm.totalImpressions = 0;
    vm.userProfiles = [];
    var tmpProfiles = [];
    vm.activity = '';
    vm.genderChartColors = ['#2962ff', '#c51162', '#004d40', '#37474f'];
    vm.typeChartColors = [];
    vm.chartColors = [
      '#b71c1c',
      '#1a237e',
      '#f57f17',
      '#004d40',
      '#212121',
      '#880e4f',
      '#bf360c',
      '#1b5e20',
      '#263238',
      '#4a148c',
      '#0277bd',
      '#3e2723',
      '#558b2f',
      '#616161',
      '#311b92',
      '#ffd740',
      '#c6ff00',
      '#607d8b',
      '#d50000',
      '#0288d1',
      '#ffca28',
      '#795548',
      '#cddc39',
      '#757575',
      '#9c27b0',
      '#26c6da',
      '#ff9100',
      '#d4e157',
      '#7c4dff'
    ];

    vm.event = {};
    /* dates */

    var date = new Date();
    vm.today = date;
    vm.starttime = new Date(date.getFullYear(), date.getMonth(), 1);
    vm.endtime = new Date();
    vm.endtime.setHours(23);
    vm.endtime.setMinutes(59);
    vm.endtime.setSeconds(59);

    // user dates
    vm.userStartTime = vm.starttime;
    vm.userEndTime = vm.endtime;
    vm.uniqueUsers = 0;
    vm.dwellStart = vm.starttime;
    vm.dwellEnd = vm.endtime;

    vm.status = {
      openedSt: false,
      openedEnd: false,
      openedJourneyDate: false,
      openedUsrSt: false,
      openedUsrEnd: false
    };

    vm.activityFilter = [{
      'label': 'Online',
      'value': 'true'
    }, {
      'label': 'Offline',
      'value': 'false'
    }, {
      'label': 'All',
      'value': ''
    }];

    vm.dateOptions = {
      formatYear: 'yy',
      startingDay: 0
    };

    vm.dateFormat = 'dd-MMMM-yyyy';

    vm.openUserCalSt = function () {
      vm.status.openedUsrSt = true;
    };

    vm.openUserCalEnd = function () {
      vm.status.openedUsrEnd = true;
    };

    vm.openCalSt = function () {
      vm.status.openedSt = true;
    };

    vm.openCalEnd = function () {
      vm.status.openedEnd = true;
    };

    vm.liveDataChart = {
      labels: [
        '', '', '', '', ''
      ],
      series: [
        'Total', 'Logins', 'Inactive'
      ],
      data: [
        [
          0, 0, 0, 0, 0
        ],
        [
          0, 0, 0, 0, 0
        ],
        [0, 0, 0, 0, 0]
      ],
      datasetOverride: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMin: 0
            }
          }]
        }
      }
    };
    vm.visitorsLoginData = {
      colors: [
        '#45b7cd', '#ff6384', '#ff8e72'
      ],
      labels: [],
      data: [],
      datasetOverride: [{
        label: 'new visitors',
        borderWidth: 1,
        type: 'bar'
      }, {
        label: 'returned visitors',
        borderWidth: 1,
        type: 'bar'
      }, {
        label: 'total visitors',
        borderWidth: 3,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        type: 'line'
      }]
    };

    vm.connectedUsersBarChart = {
      data: [],
      apDAta: [],
      zoneData: [],
      series: [],
      labels: [],
      apLabels: [],
      zoneLabels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMin: 0
            }
          }],
          xAxes: []
        }
      }
    };

    vm.connectedTable = {
      zoneheaders: [
        'zones', 'count'
      ],
      aps: []
    };

    vm.getLiveAnalytics = function () {
      vm.connectedUsersBarChart.series = [];
      vm.connectedTable.zones = [];
      vm.connectedUsersBarChart.data = [];
      vm.connectedUsersBarChart.options.scales.xAxes = [];

      ApiAnalytic.getClientsOnlineByEvent(vm.venueid, vm.venue.infrastructure.provider, vm.eventid).then(function (response) {
        var expireDate = new Date((new Date()).getTime() + 10 * 60000);
        $cookies.put('unifitoken', response.data.cookie, {
          'expires': expireDate
        });
        /* Live User Totals */
        vm.liveLoading = false;
        vm.liveDataChart.data[0].push(response.data.totalUsers);
        vm.liveDataChart.data[1].push(response.data.logins);
        vm.liveDataChart.data[2].push(response.data.unauthorized);
        vm.liveDataChart.data[0].shift();
        vm.liveDataChart.data[1].shift();
        vm.liveDataChart.data[2].shift();

        vm.totalUsers = response.data.totalUsers;
        vm.logins = response.data.logins;
        vm.unauthorized = response.data.unauthorized;
        /* Live User Totals */
        var dataLength = response.data.zones.length;
        for (var item = 0; item < dataLength; item++) {
          var newAp = 0;
          var value = response.data.zones[item].count;
          newAp = [value];
          var name = response.data.zones[item].name;
          vm.connectedUsersBarChart.series.push(name);
          vm.connectedUsersBarChart.data.push(newAp);
          vm.connectedTable.zones.push({
            name: name,
            count: value
          });
        }

        if (vm.connectedUsersBarChart.data.length <= 1) {
          vm.connectedUsersBarChart.options.scales.xAxes.push({
            barPercentage: 0.3
          });
        } else {
          vm.connectedUsersBarChart.options.scales.xAxes = [];
        }
      }).catch(function (error) {
        if (error.data.message === 'This Provider is Not Supported') {
          vm.liveData = false;
        }
      });
    };

    var getLiveUsers = function () {
      ApiAnalytic.getOnlineUsersEvent(vm.venueid, vm.eventid, vm.venue.infrastructure.provider).then(function (result) {
        vm.liveUsers = result.data;
        if (vm.userProfiles.length <= 0) {
          var thisStart = vm.starttime;
          var thisEnd = vm.endtime;
          thisStart.setHours(0);
          thisStart.setMinutes(0);
          thisStart.setSeconds(0);
          thisEnd.setHours(23);
          thisEnd.setMinutes(59);
          thisEnd.setSeconds(59);
          getUserProfiles(thisStart.getTime(), thisEnd.getTime());
        }
      }, function (err) {
        console.log(err);
      });
    };

    var isLive = function (macarray) {
      var length = macarray.length;
      for (var i = 0; i < length; i++) {
        if (vm.liveUsers.indexOf(macarray[i]) >= 0) {
          return 'true';
        }
      }
      return 'false';
    };

    var intervalPromise;

    vm.startInterval = function () {
      vm.stopInterval();
      intervalPromise = $interval(function () {
        vm.setLive();
      }, 30000);
    };

    vm.stopInterval = function () {
      $interval.cancel(intervalPromise);
    };

    $rootScope.$on('$stateChangeStart', function () {
      vm.stopInterval();
      return;
    });

    var refreshLive = function () {
      if (tmpProfiles.length > 0) {
        vm.userProfiles = tmpProfiles.map(function (user) {
          user.isLive = isLive(user.usermac);
          return user;
        });
      } else {
        var thisStart = vm.starttime;
        var thisEnd = vm.endtime;
        thisStart.setHours(0);
        thisStart.setMinutes(0);
        thisStart.setSeconds(0);
        thisEnd.setHours(23);
        thisEnd.setMinutes(59);
        thisEnd.setSeconds(59);
        getUserProfiles(thisStart.getTime(), thisEnd.getTime());
      }
    };

    vm.setLive = function () {
      vm.getLiveAnalytics();
      getLiveUsers();
      refreshLive();
      vm.startInterval();
    };

    vm.footfallTable = {
      headers: [
        '', 'New Visitors', 'Returning Visitors', 'Total'
      ],
      rows: []
    };

    vm.getAllNewReturningVis = function (start, end) {
      ApiAnalytic.getFootFallByEvent(vm.eventid, start, end).success(function (data) {
        // console.log('footfall'); console.log(data);
        vm.footfallTable.rows = [];

        vm.mixedChartOptions = {
          scales: {
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: data.timeScale
              }
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true,
                suggestedMin: 0
              }
            }]
          }
        };

        var rowItem = {};

        if (data.timeScale === 'Weeks') {
          var labels = [];
          data.labels.shift();
          data.newVisitors.shift();
          data.returningVisitors.shift();
          data.allVisitors.shift();
          for (var i = 0; i < data.labels.length; i++) {
            rowItem = {
              type: '',
              newVisitors: 0,
              returningVisitors: 0,
              total: 0
            };
            labels[i] = 'Week ' + data.labels[i];
            rowItem.type = 'Week ' + data.labels[i];
            rowItem.newVisitors = data.newVisitors[i];
            rowItem.returningVisitors = data.returningVisitors[i];
            rowItem.total = data.allVisitors[i];
            vm.footfallTable.rows.push(rowItem);
          }
          vm.visitorsLoginData.labels = labels;
          vm.visitorsLoginData.data = [data.newVisitors, data.returningVisitors, data.allVisitors];
        } else if (data.timeScale === 'Hours') {
          var hourLabels = [];
          var now = new Date();
          var offset = (now.getTimezoneOffset() * -1) / 60;
          for (var k = 0; k < offset; k++) {
            data.newVisitors.unshift(data.newVisitors[data.newVisitors.length - 1]);
            data.returningVisitors.unshift(data.returningVisitors[data.returningVisitors.length - 1]);
            data.allVisitors.unshift(data.allVisitors[data.allVisitors.length - 1]);
            data.newVisitors.pop();
            data.returningVisitors.pop();
            data.allVisitors.pop();
          }
          for (var j = 0; j < data.labels.length; j++) {
            hourLabels[j] = data.labels[j] + ':00';
            rowItem = {
              type: '',
              newVisitors: 0,
              returningVisitors: 0,
              total: 0
            };
            rowItem.type = data.labels[j] + ':00';
            rowItem.newVisitors = data.newVisitors[j];
            rowItem.returningVisitors = data.returningVisitors[j];
            rowItem.total = data.allVisitors[j];
            vm.footfallTable.rows.push(rowItem);
          }

          vm.visitorsLoginData.labels = hourLabels;
          vm.visitorsLoginData.data = [data.newVisitors, data.returningVisitors, data.allVisitors];
        } else if (data.timeScale === 'Days') {
          // data.labels.shift(); data.newVisitors.shift();
          // data.returningVisitors.shift(); data.allVisitors.shift();
          var dayLabels = [];
          var monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ];

          for (var z = 0; z < data.labels.length; z++) {
            rowItem = {
              type: '',
              newVisitors: 0,
              returningVisitors: 0,
              total: 0
            };
            var date = new Date((new Date().getYear()), 0);
            date.setDate(data.labels[z]);
            dayLabels.push(date.getDate() + ' ' + monthNames[date.getMonth()]);
            rowItem.type = date.getDate() + ' ' + monthNames[date.getMonth()];
            rowItem.newVisitors = data.newVisitors[z];
            rowItem.returningVisitors = data.returningVisitors[z];
            rowItem.total = data.allVisitors[z];
            vm.footfallTable.rows.push(rowItem);
          }

          vm.visitorsLoginData.labels = dayLabels;
          vm.visitorsLoginData.data = [data.newVisitors, data.returningVisitors, data.allVisitors];
        } else {
          data.labels.shift();
          data.newVisitors.shift();
          data.returningVisitors.shift();
          data.allVisitors.shift();

          for (var b = 0; b < data.labels.length; b++) {
            rowItem = {
              type: '',
              newVisitors: 0,
              returningVisitors: 0,
              total: 0
            };
            rowItem.type = data.labels[b];
            rowItem.newVisitors = data.newVisitors[b];
            rowItem.returningVisitors = data.returningVisitors[b];
            rowItem.total = data.allVisitors[b];
            vm.footfallTable.rows.push(rowItem);
          }

          vm.visitorsLoginData.labels = data.labels;
          vm.visitorsLoginData.data = [data.newVisitors, data.returningVisitors, data.allVisitors];
        }
        vm.loading = false;
      }).error(function () {
        $log.info('get gender error');
      });
    };

    vm.horizontal = {
      data: [],
      series: [],
      labels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: []
        }
      }
    };

    vm.genderBarChart = {
      data: [],
      series: [],
      labels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: []
        }
      }
    };

    vm.splashLoadChart = {
      data: [],
      series: [
        'Impressions', 'Logins', 'Bounces'
      ],
      labels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: []
        }
      }
    };

    vm.getSplashLoadChartData = function (start, end) {
      ApiAnalytic.getImpressionsByEvent(vm.eventid, start, end).then(function successCallback(response) {
        var data = JSON.parse(JSON.stringify(response.data));
        vm.totalImpressions = data.totalImpressions;
        vm.splashLoadChart.data = [];
        vm.splashLoadChart.labels = [];
        var monthNames = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ];
        // Sort Returned Data
        data.impressions.sort(function sortNumber(a, b) {
          return a._id[
            data.type.substring(0, data.type.length - 1)
          ] - b._id[
            data.type.substring(0, data.type.length - 1)
          ];
        });
        data.logins.sort(function sortNumber(a, b) {
          return a._id[
            data.type.substring(0, data.type.length - 1)
          ] - b._id[
            data.type.substring(0, data.type.length - 1)
          ];
        });
        data.bounces.sort(function sortNumber(a, b) {
          return a._id[
            data.type.substring(0, data.type.length - 1)
          ] - b._id[
            data.type.substring(0, data.type.length - 1)
          ];
        });

        // correct the data set
        var impressions = [];
        var logins = [];
        var bounces = [];

        data.impressions.forEach(function (item) {
          var label = item._id[
            data.type.substring(0, data.type.length - 1)
          ];
          if (data.type === 'hours') {
            vm.splashLoadChart.labels.push(label + ':00');
          } else if (data.type === 'days') {
            var date = new Date((new Date().getYear()), 0);
            date.setDate(label);
            vm.splashLoadChart.labels.push(date.getDate() + ' ' + monthNames[date.getMonth()]);
          } else if (data.type === 'months') {
            vm.splashLoadChart.labels.push(monthNames[label - 1]);
          } else if (data.type === 'years') {
            var year = new Date(label, 0).getFullYear();
            vm.splashLoadChart.labels.push('' + year);
          } else {
            vm.splashLoadChart.labels.push(data.type.charAt(0).toUpperCase() + data.type.substring(1, data.type.length - 1) + ' ' + label);
          }
          impressions.push(item.count);
        });
        data.logins.forEach(function (item) {
          logins.push(item.count);
        });
        data.bounces.forEach(function (item) {
          bounces.push(item.count);
        });

        if (data.type === 'hours') {
          impressions.unshift(impressions[impressions.length - 1]);
          logins.unshift(logins[logins.length - 1]);
          bounces.unshift(bounces[bounces.length - 1]);

          impressions.pop();
          logins.pop();
          bounces.pop();
        }

        vm.splashLoadChart.data.push(impressions);
        vm.splashLoadChart.data.push(logins);
        vm.splashLoadChart.data.push(bounces);
      }, function errorCallback(response) {
        console.log(response);
      });
    };

    vm.dwellChart = {
      data: [],
      apData: [],
      zoneData: [],
      series: [],
      labels: [],
      apLabels: [],
      zoneLabels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: []
        }
      }
    };

    vm.dwellTable = {
      zoneheaders: [
        'zones', 'avg dwell time(mins)'
      ],
      zones: []
    };

    vm.getDwellTime = function (start, end) {
      vm.dwellLoading = true;
      vm.dwellTable.zones = [];
      vm.dwellChart.series = [];
      vm.dwellChart.data = [];

      ApiAnalytic.getDwellTimeByEvent(vm.venueid, vm.eventid, start, end).then(function (resp) {
        vm.dwellChart.data = resp.data.zones.map(function (ap) {
          var newAp = 0;
          vm.dwellChart.series.push(ap.name);
          var value = Math.round((ap.avgconnection / 1000) / 60);
          newAp = [value];
          vm.dwellTable.zones.push({
            name: ap.name,
            time: value
          });
          return newAp;
        });
        vm.dwellLoading = false;
      }, function (err) {
        console.log(err);
      });
    };

    vm.refresh = function () {
      vm.loading = true;
      var thisStart = vm.starttime;
      var thisEnd = vm.endtime;
      vm.visitorsLoginData.data = [];
      thisStart.setHours(0);
      thisStart.setMinutes(0);
      thisStart.setSeconds(0);
      thisEnd.setHours(23);
      thisEnd.setMinutes(59);
      thisEnd.setSeconds(59);
      vm.setHistoric(thisStart.getTime(), thisEnd.getTime());
      vm.setUsers(thisStart.getTime(), thisEnd.getTime());
    };

    vm.genderTable = {
      headers: [
        'demographic', 'count'
      ],
      rows: []
    };

    var processGender = function (genderArray) {
      if (genderArray.length <= 1) {
        vm.genderBarChart.options.scales.xAxes.push({
          barPercentage: 0.3
        });
      } else {
        vm.genderBarChart.options.scales.xAxes = [];
      }
      var total = parseInt(genderArray[0].female) + parseInt(genderArray[0].male);
      if (parseInt(genderArray[0].male) > 0) {
        vm.genderMale = Math.round((parseInt(genderArray[0].male) / total) * 100) + '%';
      } else if (parseInt(genderArray[0].male) <= 0 && parseInt(genderArray.female) > 0) {
        vm.genderMale = 0 + '%';
      } else {
        vm.genderMale = 'No Data';
      }

      if (parseInt(genderArray[0].female) > 0) {
        vm.genderFemale = Math.round((parseInt(genderArray[0].female) / total) * 100) + '%';
      } else if (parseInt(genderArray[0].female) <= 0 && parseInt(genderArray[0].male) > 0) {
        vm.genderFemale = 0 + '%';
      } else {
        vm.genderFemale = 'No Data';
      }

      var male = parseInt(genderArray[0].male);
      var female = parseInt(genderArray[0].female);
      var other = parseInt(genderArray[0].other);

      vm.genderBarChart.series = [];
      vm.genderBarChart.data = [];
      vm.genderTable.rows = [];
      var rowMale = {
        type: 'Male',
        count: male
      };
      var rowFemale = {
        type: 'Female',
        count: female
      };
      var rowOther = {
        type: 'Other',
        count: other
      };

      if (male > 0) {
        vm.genderBarChart.series.push('Male');
        vm.genderBarChart.data.push([male]);
        vm.genderTable.rows.push(rowMale);
      }
      if (female > 0) {
        vm.genderBarChart.series.push('Female');
        vm.genderBarChart.data.push([female]);
        vm.genderTable.rows.push(rowFemale);
      }
      if (other > 0) {
        vm.genderBarChart.series.push('Other');
        vm.genderBarChart.data.push([other]);
        vm.genderTable.rows.push(rowOther);
      }

      if (vm.genderBarChart.data.length <= 1) {
        vm.genderBarChart.options.scales.xAxes.push({
          barPercentage: 0.3
        });
      } else {
        vm.genderBarChart.options.scales.xAxes = [];
      }
    };

    vm.loginTable = {
      headers: [
        'type', 'count'
      ],
      rows: []

    };

    var processLoginTypes = function (logins) {
      vm.horizontal.series = [];
      vm.horizontal.data = [];
      vm.loginTable.rows = [];
      for (var x = 0; x < logins.length; x++) {
        var rowItem = {
          type: '',
          count: 0
        };
        vm.horizontal.data.push([parseInt(logins[x].count)]);
        rowItem.count = parseInt(logins[x].count);
        var type = logins[x].logintype;
        if (type === 'anonymous') {
          vm.horizontal.series.push('anonymous');
          rowItem.type = 'anonymous';
        } else {
          vm.horizontal.series.push(type);
          rowItem.type = type;
        }
        vm.loginTable.rows.push(rowItem);
        switch (type) {
          case 'anonymous':
            vm.typeChartColors.push('#455a64');
            break;
          case 'facebook':
            vm.typeChartColors.push('#304ffe');
            break;
          case 'linkedin':
            vm.typeChartColors.push('#1e88e5');
            break;
          case 'twitter':
            vm.typeChartColors.push('#00b0ff');
            break;
          case 'google':
            vm.typeChartColors.push('#d50000');
            break;
          case 'twilio':
            vm.typeChartColors.push('#263238');
            break;
        }
      }
    };

    var setLimits = function (n) {
      if (n <= 100) {
        vm.limits = [
          Math.ceil((n / 5) / 10) * 10,
          Math.ceil((n / 5) / 10) * 10 * 2,
          Math.ceil((n / 5) / 10) * 10 * 3,
          Math.ceil((n / 5) / 10) * 10 * 4,
          'all'
        ];
      } else if (n <= 500) {
        vm.limits = [
          Math.ceil((n / 5) / 50) * 50,
          Math.ceil((n / 5) / 50) * 50 * 2,
          Math.ceil((n / 5) / 50) * 50 * 3,
          Math.ceil((n / 5) / 50) * 50 * 4,
          'all'
        ];
      } else {
        vm.limits = [
          100, Math.ceil((n / 5) / 100) * 100,
          Math.ceil((n / 5) / 100) * 100 * 2,
          Math.ceil((n / 5) / 100) * 100 * 3,
          Math.ceil((n / 5) / 100) * 100 * 4,
          'all'
        ];
      }
      vm.limit = vm.limits[0];
    };

    vm.setLimit = function (activity) {
      console.log('activity: ' + activity);
      vm.activity = activity;
      if (activity === 'true') {
        vm.limit = 'all';
      } else {
        vm.limit = vm.limits[0];
      }
    };

    var getLoginAnalytics = function (start, end) {
      // console.log('called getlogin');
      ApiAnalytic.getLoginAnalyticsEvent(vm.venueid, vm.eventid, start, end).then(function (response) {
        // console.log(response);
        vm.uniqueUsers = response.data.unique;
        vm.totalLogin = response.data.total;
        processGender(response.data.gender);
        processLoginTypes(response.data.loginType);
      });
    };

    vm.setHistoric = function (start, end) {
      vm.getAllNewReturningVis(start, end);
      vm.getSplashLoadChartData(start, end);
      vm.getDwellTime(start, end);
      getLoginAnalytics(start, end);
    };

    var getUserProfiles = function (start, end) {
      ApiAnalytic.getUserProfilesEvent(vm.venueid, vm.eventid, start, end).then(function (response) {
        tmpProfiles = response.data.map(function (user) {
          return user;
        });
        vm.userProfiles = response.data.map(function (user) {
          user.isLive = isLive(user.usermac);
          return user;
        });
        setLimits(response.data.length);
      });
    };

    vm.setUsers = function (start, end) {
      getUserProfiles(start, end);
    };


        vm.getMarketingEmailList = function()
        {
          var emailList = [];

          emailList = vm.userProfiles
          .filter(function (userItem){
            return isNaN(userItem.login)
          })
          .map(function (userItem){
              if(userItem.tag && userItem.tag.length > 0)
              {
                return { Name:userItem.name, Email:userItem.login, Tag:userItem.tag[0]}
              }
              else {
                return { Name:userItem.name, Email:userItem.login}
              }
          })
          return emailList;
        };


        function convertArrayOfObjectsToCSV(args) {
                var result, ctr, keys, columnDelimiter, lineDelimiter, data;

                data = args.data || null;
                if (data == null || !data.length) {
                    return null;
                }

                columnDelimiter = args.columnDelimiter || ',';
                lineDelimiter = args.lineDelimiter || '\n';

                keys = Object.keys(data[0]);

                result = '';
                result += keys.join(columnDelimiter);
                result += lineDelimiter;

                data.forEach(function(item) {
                    ctr = 0;
                    keys.forEach(function(key) {
                        if (ctr > 0) result += columnDelimiter;

                        result += item[key];
                        ctr++;
                    });
                    result += lineDelimiter;
                });

                return result;
        }

        vm.downloadCSV = function()
        {
          var data, filename, link;
          var csv = convertArrayOfObjectsToCSV({
            data: vm.getMarketingEmailList()
          });
          if (csv == null)  return;

          var now = new Date();
          var nowString = now.toISOString().split('.')[0];
          filename = 'export_'+nowString+'.csv';

          var blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});

          if (navigator.msSaveBlob)
          { // IE 10+
            navigator.msSaveBlob(blob, filename)
          }
          else
          {
              var link = document.createElement("a");
              if (link.download !== undefined)
              {

                // feature detection, Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style = "visibility:hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
          }
        }

    vm.getVenue = function () {
      Api.getVenue(vm.venueid).then(function (response) {
        vm.venue = response.data.venue;
        // console.log(vm.venue);
        vm.setLive();
        vm.setHistoric(vm.starttime.getTime(), vm.endtime.getTime());
      });
    };

    vm.getEvent = function () {
      Api.getEvent(vm.eventid, vm.venueid).then(function (response) {
        vm.event = response.data.event;
        if (vm.event.eventstatus === 2) {
          var newStart = new Date(vm.event.starttime);
          var newEnd = new Date(vm.event.endtime);
          newStart.setHours(0);
          newStart.setMinutes(0);
          newStart.setSeconds(0);
          newEnd.setHours(23);
          newEnd.setMinutes(59);
          newEnd.setSeconds(59);
          vm.starttime = newStart;
          vm.endtime = newEnd;
          vm.tab = 'historic';
        }
        // console.log('event'); console.log(response);
      }, function (err) {
        console.log(err);
      });
    };

    vm.switchTab = function (tab) {
      switch (tab) {
        case 'live':
          vm.tab = tab;
          break;
        case 'historic':
          vm.tab = tab;
          break;
        case 'users':
          vm.tab = tab;
          break;
        case 'Segmentation':
          $state.go('admin.tags', {
          'venueid': vm.venueid
        });
          break;
        default:
          vm.tab = 'live';
      }
    };

    vm.setClass = function () {
      // console.log('class'); console.log($stateParams.class);
      if ($stateParams.class) {
        vm.tab = $stateParams.class;
      } else {
        vm.tab = 'live';
      }
    };

    vm.setClass();
    vm.getVenue();
    vm.getEvent();
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editzone
   * @description
   *
   */
  angular
    .module('editzone', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editzone.controller:EditzoneCtrl
   *
   * @description
   *
   */
  EditzoneCtrl.$inject = ["Api", "$stateParams", "$state", "$log", "Auth"];
  angular
    .module('editzone')
    .controller('EditzoneCtrl', EditzoneCtrl);

  function EditzoneCtrl(Api, $stateParams, $state, $log, Auth) {
    var vm = this;
    vm.ctrlName = 'EditzoneCtrl';

    var orgid = Auth.getAuthData().operator.organisation;

    var zoneid = $stateParams.zoneid;
    var venueid = $stateParams.venueid;

    console.log('orgidedit', orgid);

    vm.zone = {};
    vm.accesspoints = [];
    vm.selectedaccesspoints = [];
    vm.originalaccesspoints = [];
    vm.provider = $stateParams.provider;

    Api.getAccessPoints($stateParams.venueid).success(function(data) {
      $log.info(data);
      vm.accesspoints = data.apoints;
      $log.info(vm.accesspoints);
    }).error(function() {
      vm.accesspoints = [];
      $log.info('get Zones error');
    });

    vm.removeAp = function(key){
      vm.selectedaccesspoints.splice(key, 1);
    };

    Api.getZone(zoneid, venueid).then(function(resp){
      vm.zone = resp.data.zone;
      vm.selectedaccesspoints = vm.zone.accesspoints;
      vm.zone.accesspoints.forEach(function(element) {
        vm.originalaccesspoints.push(element.id);
      });
      $log.info(vm.zone);
    }, function(err){
      $log.info(err);
    });

    vm.updateZone = function(){
      vm.zone.orgid = orgid;
      var addArray = [];
      vm.selectedaccesspoints.forEach(function(element) {
        addArray.push(element.id);
      });
      console.log(addArray);
      vm.apsToRemove = {
        orgId: orgid,
        venueId: venueid,
        zoneId: zoneid,
        accessPoints: vm.originalaccesspoints
      };
      vm.apsToAdd = {
        orgId: orgid,
        venueId: venueid,
        zoneId: zoneid,
        accessPoints: addArray
      };
      if (vm.apsToRemove.accessPoints.length < 1) {
        if(vm.apsToAdd.accessPoints.length < 1){
          Api.updateZone(zoneid, venueid, vm.zone).then(function(resp){
            $log.info(resp);
            $state.go('admin.managenetwork', {
              venueid: venueid,
              orgid: orgid
            });
          }, function(err){
            $log.info(err);
          });
        } else {
          Api.updateApsZone(vm.apsToAdd).then(function(resp){
            $log.info(resp);
            Api.updateZone(zoneid, venueid, vm.zone).then(function(resp){
              $log.info(resp);
              $state.go('admin.managenetwork', {
                venueid: venueid,
                orgid: orgid
              });
            }, function(err){
              $log.info(err);
            });
          }, function(err){
            $log.info(err);
          });
        }
      } else {
        Api.removeApsZone(vm.apsToRemove).then(function(resp){
          $log.info(resp);
          if(vm.apsToAdd.accessPoints.length >= 1){
            Api.updateApsZone(vm.apsToAdd).then(function(resp){
              $log.info(resp);
              Api.updateZone(zoneid, venueid, vm.zone).then(function(resp){
                $log.info(resp);
                $state.go('admin.managenetwork', {
                  venueid: venueid,
                  orgid: orgid
                });
              }, function(err){
                $log.info(err);
              });
            }, function(err){
              $log.info(err);
            });
          } else {
            Api.updateZone(zoneid, venueid, vm.zone).then(function(resp){
              $log.info(resp);
              $state.go('admin.managenetwork', {
                venueid: venueid,
                orgid: orgid
              });
            }, function(err){
              $log.info(err);
            });
          }
        }, function(err){
          $log.info(err);
        });
      }
    };

    vm.cancel = function(){
      $state.go('admin.managenetwork', {
        venueid: venueid,
        orgid: orgid
      });
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name eventcreation
   * @description
   *
   */
  angular
    .module('eventcreation', [
      'ui.router',
      'ngDragDrop'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name eventcreation.controller:SsidchecksCtrl
   *
   * @description
   *
   */
  SsidchecksCtrl.$inject = ["$modalInstance", "message"];
  angular
    .module('eventcreation')
    .controller('SsidchecksCtrl', SsidchecksCtrl);

  function SsidchecksCtrl($modalInstance, message) {
    var vm = this;
    vm.ctrlName = 'SsidchecksCtrl';
    vm.message = message;

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name eventcreation.controller:EventcreationCtrl
   *
   * @description
   *
   */
  EventcreationCtrl.$inject = ["$rootScope", "$state", "Api", "$log", "Auth", "baseurl", "baseendpoint", "$modal", "$localStorage", "Cloudservices"];
  angular
    .module('eventcreation')
    .controller('EventcreationCtrl', EventcreationCtrl);

  function EventcreationCtrl($rootScope, $state, Api, $log, Auth, baseurl, baseendpoint, $modal, $localStorage, Cloudservices) {
    var vm = this;

    vm.newEvent = {};
    vm.openAuthList = [];
    vm.newEvent.optCatList = [];
    vm.newEvent.splashDetails = {};
    vm.newEvent.zoneList=[];
    vm.newEvent.org = '';
    vm.storage = $localStorage;
    vm.ssidExists = false;
    vm.marketingOn = false;
    vm.nameCheckAP = [];
    vm.ssidCount = false;
    vm.ssidCountAP = [];
    vm.newEvent.networktype = '';
    vm.networkTypes = [];
    vm.splashPageTypes = [];
    vm.splashType = 0;
    vm.spVideo = false;
    vm.spSocial = false;
    vm.spAmount = false;
    vm.spEmail = false;
    vm.spLink = false;
    vm.spRegister = false;
    vm.defaultFbLikeUrl = true;
    vm.addlikebutton = false;
    vm.customFbLikeUrl =  '';
    vm.provider = '';
    vm.selectedSurvey = false;
    vm.numberOfQuestionsTotal = 5;
    vm.numberOfQuestions = 0;
    vm.surveyObjects = [];
    vm.surveyId = 0;
    vm.eventnames = [];
    vm.thesamename = false;
    vm.venues = [];
    vm.netPassed = false;
    vm.setupPassed = false;
    vm.splashPassed = false;

    if (Auth.getAuthData()) {
      vm.newEvent.org = Auth.getAuthData().operator.organisation;
    }

    // Important!!! remove this sesction once magnet county feature is complete

    vm.isMagnet = false;
    var magnetID = 9104885; //add real magnetID

    if (vm.newEvent.org === magnetID) {
      vm.isMagnet = true;
    }

    // Start new event updates

    // state changes

    // redirects on page to load to load correct start page
    // if ($state.current.name === 'admin.eventcreation') {
    //   $state.go('admin.eventcreation.evcsetup');
    // }

    // handles navigation away from incomplete form
    vm.allowStateChange = false;
    $rootScope.$on('$stateChangeStart',
      function (event, toState, toParams, fromState) {
        console.log('state called');
        console.log(toState);
        console.log(vm.allowStateChange);
        if (vm.allowStateChange === true) {
          return;
        } else {
          if (fromState.name === 'admin.eventcreation.evcconfirm') {
            if (toState.name === 'admin.eventcreation.evcsetup' || toState.name === 'admin.eventcreation.evcnetwork' || toState.name === 'admin.eventcreation.evcsplash') {
              // vm.allowStateChange = true;
              // $state.go(toState.name);
              return;
            }
          } else if (fromState.name === 'admin.eventcreation.evcnetwork' && toState.name === 'admin.eventcreation.evcsetup') {
            // vm.allowStateChange = true;
            // $state.go(toState.name);
            return;
          } else if ($state.current.name === 'admin.eventcreation.evcsplash' && toState.name === 'admin.eventcreation.evcsetup' || toState.name === 'admin.eventcreation.evcnetwork') {
            return;
          } else if (toState.name !== 'admin.eventcreation.evcsetup' && toState.name !== 'admin.eventcreation.evcnetwork' && toState.name !== 'admin.eventcreation.evcsplash' && toState.name !== 'admin.eventcreation.evcconfirm') {
            console.log(toState.name);
            console.log('called');
            event.preventDefault();
            var modalInstance = $modal.open({
              templateUrl: 'eventcreation/views/cancelmodal.tpl.html',
              controller: 'CancelmodalCtrl as cancelModal'
            });
            modalInstance.result.then(function () {
              vm.allowStateChange = true;
              $state.go(toState.name);
            }, function () {
              vm.allowStateChange = false;
            });
          }

        }
        vm.allowStateChange = false;
      });
    // end state changes

    /* setp calls
     * ensure required resources are available
     */

    function getNetworkTypes(venueid) {
      Api.getNetworkTypes(venueid).then(function (response) {
        $log.info(response.data.networkTypes);
        vm.networkTypes = response.data.networkTypes;
      }, function (err) {
        $log.info(err);
      });
    }

    // venue setup
    Api.getVenues().success(function (data) {
      vm.venues = data.venues;
      if ($rootScope.activeUser.role === 2) {
        vm.newEvent.venueId = $rootScope.activeUser.details.venue;
        Cloudservices.getCloudServiceByName(vm.newEvent.venueId, 'twilio').then(function (resp) {
          if(resp.data.rowCount >= 1){
            vm.hasTwilio = true;
          }
        }, function (err) {
          console.log(err);
        });
        Api.getInfrastructures(vm.newEvent.venueId).then(function (res) {
          if(res.data.rowCount > 0) {
            var infra = res.data.infrastructures[0];
            vm.provider = JSON.parse(infra.provider);
          } else {
            vm.provider = {};
          }
          getSplashPageTypes();
        }, function (err) {
          console.log(err);
        });
      } else {
        vm.newEvent.venueId = data.venues[0].id;
        Cloudservices.getCloudServiceByName(vm.newEvent.venueId, 'twilio').then(function (resp) {
          if(resp.data.rowCount >= 1){
            vm.hasTwilio = true;
          }
        }, function (err) {
          console.log(err);
        });
        Api.getInfrastructures(vm.newEvent.venueId).then(function (res) {
          if(res.data.rowCount > 0) {
            var infra = res.data.infrastructures[0];
            vm.provider = JSON.parse(infra.provider);
          } else {
            vm.provider = {};
          }
        }, function (err) {
          console.log(err);
        });
      }
      vm.getZones(vm.newEvent.venueId);
      $log.info(vm.venues);
    }).error(function () {
      $log.info('get venues error');
    });
    // done venue setup

    // categories setup
    function getCategories() {
      Api.getCategories(vm.provider).success(function (data) {
        vm.optCatList = data.categories;
        $log.info(vm.optCatList);
      }).error(function () {
        $log.info('get categories error');
      });
    }
    // end categories setup

    // social logins setup
    function getSocialLogins() {
      Api.getSocialLogins(vm.provider).success(function (data) {
        vm.anonymousLogin = undefined;
        for (var i = 0; i < data.socialLoginTypes.length; i++) {
          if(data.socialLoginTypes[i].name.toLowerCase() === 'connect2fi_facebook')
          {
            data.socialLoginTypes.splice(i, 1);
            i--;
          }
          if(data.socialLoginTypes[i].name.toLowerCase() === 'anonymous') {
            vm.anonymousLogin = data.socialLoginTypes[i];
            vm.anonymousLogin.on = false;
            data.socialLoginTypes.splice(i, 1);
            i--;
          }
        }
        vm.socialLogins = data.socialLoginTypes;
        $log.info('social');
        $log.info(vm.socialLogins);
      }).error(function () {
        $log.info('get social error');
      });
    }
    // end social logins setup

    //get splash page types
    function getSplashPageTypes() {
      Api.getSplashPageTypes(vm.provider).success(function (data) {
        vm.splashPageTypes = data.splashTypes;
        getSocialLogins();
        getCategories();
        $log.info(vm.splashPageTypes);
      }).error(function () {
        $log.info('get splash page types error');
      });
    }
    //end get splash page types

    //setSurvey
    function addEmptySurveyObjects() {
      for (var i = 0; i < vm.numberOfQuestionsTotal; i++) {
        var surveyObj = {
          'id': i,
          'show': false
        };
        vm.surveyObjects.push(surveyObj);
      }
    }

    /*
    {"survey":[{"question":"Do you like apples?","answers":[{"answer":"yes","count":0},{"answer":"no","count":0}]},{"question":"Do you like pears?","answers":[{"answer":"yes","count":0},{"answer":"no","count":4}]},{"question":"Do you like kiwi?","answers":[{"answer":"yes","count":0},{"answer":"no","count":4}]}]}
    */
    vm.noend = true;

    vm.noEndChanged = function () {

    }

    addEmptySurveyObjects();

    vm.addQuestion = function () {
      if (vm.numberOfQuestions < vm.numberOfQuestionsTotal) {
        vm.surveyObjects[vm.numberOfQuestions].show = true;
        vm.numberOfQuestions = vm.numberOfQuestions + 1;
      }
    };

    vm.removeQuestion = function () {
      if (vm.numberOfQuestions > 0) {
        vm.numberOfQuestions = vm.numberOfQuestions - 1;
        vm.surveyObjects[vm.numberOfQuestions].show = false;
      }
    };

    // accesspoint setup
    vm.getAccessPoints = function (id) {
      vm.newEvent.venueId = id;
      vm.pointList = [];
      Api.getAccessPoints(id).success(function (data) {
        vm.pointList = data.apoints;
        $log.info(vm.pointList);
      }).error(function () {
        $log.info('get ap error');
      });
      $log.info(vm.newEvent);
    };

    vm.setSurveyObject = function () {
      var details = [];
      //add survey
      for (var i = 0; i < vm.numberOfQuestions; i++) {
        if (vm.surveyObjects[i].survey !== null && vm.surveyObjects[i].survey && vm.surveyObjects[i].survey.question !== null && vm.surveyObjects[i].survey.question) {
          var answers = [];
          if (vm.surveyObjects[i].survey.answer1 !== null && vm.surveyObjects[i].survey.answer1) {
            answers.push({
              'answer': vm.surveyObjects[i].survey.answer1,
              'count': 0
            });
          }
          if (vm.surveyObjects[i].survey.answer2 !== null && vm.surveyObjects[i].survey.answer2) {
            answers.push({
              'answer': vm.surveyObjects[i].survey.answer2,
              'count': 0
            });
          }
          if (vm.surveyObjects[i].survey.answer3 !== null && vm.surveyObjects[i].survey.answer3) {
            answers.push({
              'answer': vm.surveyObjects[i].survey.answer3,
              'count': 0
            });
          }
          if (vm.surveyObjects[i].survey.answer4 !== null && vm.surveyObjects[i].survey.answer4) {
            answers.push({
              'answer': vm.surveyObjects[i].survey.answer4,
              'count': 0
            });
          }
          var surveyObj = {
            'question': vm.surveyObjects[i].survey.question,
            'answers': answers
          };
          details.push(surveyObj);
        }
      }
      var eventName = vm.newEvent.name;
      var surveyObj = {
        survey: details
      };
      var survey = {
        name: eventName,
        isActive: true,
        details: surveyObj
      };
      return survey;
    };
    // end survey fucntionality

    // zone setup
    vm.getZones = function (venueid) {
      Api.getInfrastructures(venueid).then(function (res) {
        var infra = res.data.infrastructures[0];
        vm.provider = JSON.parse(infra.provider);
        getSplashPageTypes();
        console.log(vm.provider);
      }, function (err) {
        console.log(err);
      });
      vm.zoneList = [];
      Api.getZones(venueid).success(function (data) {
        vm.zoneList = data.zones;
        $log.info(vm.zoneList);
      }).error(function () {
        $log.info('get zone error');
      });
      getNetworkTypes(venueid);
      $log.info(vm.newEvent);
    };
    // end zones setup

    vm.setNetworkType = function (typeid) {
      vm.newEvent.networktype = typeid;
      vm.newEvent.optCatList = [];
      Api.getNetworkType(typeid, vm.newEvent.venueId).then(function (response) {
        $log.info(response);
        if (response.data.networkType.networkStrategies.length > 0) {
          console.log(response.data.networkType.networkStrategies.length);
          for (var i = 0; i < response.data.networkType.networkStrategies.length; i++) {
            vm.newEvent.optCatList.push(response.data.networkType.networkStrategies[i].id);
          }
        }
      }, function (err) {
        $log.info(err);
      });
    };
    // end setup calls

    //set Splash Page Type
    vm.setSplashPageType = function (type) {
      console.log(type);
      vm.spVideo = false;
      vm.spSocial = false;
      vm.spAmount = false;
      vm.spEmail = false;
      vm.spLink = false;
      vm.spRegister = false;
      vm.conference = false;
      var name = type.name;
      switch (name) {
      case 'Video':
        vm.spVideo = true;
        break;
      case 'Social':
        vm.spSocial = true;
        break;
      case 'Email':
        vm.spEmail = true;
        break;
      case 'Link':
        vm.spLink = true;
        break;
      case 'Payment':
        vm.spAmount = true;
        break;
      case 'Register':
        vm.spRegister = true;
        break;
      case 'Conference':
        vm.spEmail = true;
        vm.conference = true;
        break;
      }
    };

    // Time And Date
    vm.today = function () {
      var newDate = new Date();
      vm.dtStart = new Date(newDate);
      vm.dtStart.setMinutes(newDate.getMinutes() + 10);
      vm.dtEnd = new Date(vm.dtStart);
      vm.dtEnd.setDate(vm.dtStart.getDate() + 1);
      vm.minDate = newDate;
    };

    vm.today();

    vm.dateOptions = {
      formatYear: 'yy',
      startingDay: 0
    };

    vm.dateFormat = 'dd-MMMM-yyyy';

    vm.status = {
      openedSt: false,
      openedEnd: false
    };

    vm.openCalSt = function () {
      vm.status.openedSt = true;
    };

    vm.openCalEnd = function () {
      vm.status.openedEnd = true;
    };

    vm.hstep = 1;
    vm.mstep = 1;

    vm.setTime = function () {
      vm.startTime = new Date(vm.dtStart);
      vm.endTime = new Date(vm.dtEnd);
    };

    vm.setTime();

    vm.eventTime = function () {
      vm.eventStart = new Date(vm.dtStart);
      vm.eventStart.setHours(vm.startTime.getHours());
      vm.eventStart.setMinutes(vm.startTime.getMinutes());
      vm.eventStart.setSeconds(0);

      vm.eventEnd = new Date(vm.dtEnd);
      vm.eventEnd.setHours(vm.endTime.getHours());
      vm.eventEnd.setMinutes(vm.endTime.getMinutes());
      vm.eventEnd.setSeconds(0);
    };

    // end Time and Date

    // upload Images

    // end upload Images
    vm.eventImageAdded = false;
    vm.venueLogoAdded = false;
    vm.bckgrndImgAdded = false;

    vm.eventImage = '';
    vm.venueLogo = '';
    vm.bckgrndImg = '';

    vm.eventImageID = '';
    vm.venueLogoID = '';
    vm.bckgrndImgID = '';

    vm.offerImage1ID = '';
    vm.offerImage1Uri = '';
    vm.offerImage1Link = '';
    vm.offerImage1Added = false;

    vm.offerImage2ID = '';
    vm.offerImage2Uri = '';
    vm.offerImage2Link = '';
    vm.offerImage2Added = false;

    vm.offerImage3ID = '';
    vm.offerImage3Uri = '';
    vm.offerImage3Link = '';
    vm.offerImage3Added = false;

    vm.offerImage4ID = '';
    vm.offerImage4Uri = '';
    vm.offerImage4Link = '';
    vm.offerImage4Added = false;

    vm.offerImage5ID = '';
    vm.offerImage5Uri = '';
    vm.offerImage5Link = '';
    vm.offerImage5Added = false;

    vm.offerImage6ID = '';
    vm.offerImage6Uri = '';
    vm.offerImage6Link = '';
    vm.offerImage6Added = false;

    vm.uploadEventImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.eventImageAdded = true;
        vm.eventImage = image.imageUri;
        vm.eventImageID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectEventImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.eventImageAdded = true;
        vm.eventImage = thisImage.imageuri;
        vm.eventImageID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadLogoImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.venueLogoAdded = true;
        vm.venueLogo = image.imageUri;
        vm.venueLogoID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectLogoImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.venueLogoAdded = true;
        vm.venueLogo = thisImage.imageuri;
        vm.venueLogoID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadBckgrndImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.bckgrndImgAdded = true;
        vm.bckgrndImg = image.imageUri;
        vm.bckgrndImgID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectBckgrndImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.bckgrndImgAdded = true;
        vm.bckgrndImg = thisImage.imageuri;
        vm.bckgrndImgID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadOfferImage1 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage1Added = true;
        vm.offerImage1Uri = image.imageUri;
        vm.offerImage1ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage1 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage1Added = true;
        vm.offerImage1Uri = thisImage.imageuri;
        vm.offerImage1ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    /// offer 2

    vm.uploadOfferImage2 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage2Added = true;
        vm.offerImage2Uri = image.imageUri;
        vm.offerImage2ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage2 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage2Added = true;
        vm.offerImage2Uri = thisImage.imageuri;
        vm.offerImage2ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    ///offer 3

    vm.uploadOfferImage3 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage3Added = true;
        vm.offerImage3Uri = image.imageUri;
        vm.offerImage3ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage3 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage3Added = true;
        vm.offerImage3Uri = thisImage.imageuri;
        vm.offerImage3ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    /// offer 4

    vm.uploadOfferImage4 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage4Added = true;
        vm.offerImage4Uri = image.imageUri;
        vm.offerImage4ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage4 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage4Added = true;
        vm.offerImage4Uri = thisImage.imageuri;
        vm.offerImage4ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    //offer 5

    vm.uploadOfferImage5 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage5Added = true;
        vm.offerImage5Uri = image.imageUri;
        vm.offerImage5ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage5 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage5Added = true;
        vm.offerImage5Uri = thisImage.imageuri;
        vm.offerImage5ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    //offer 6

    vm.uploadOfferImage6 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage6Added = true;
        vm.offerImage6Uri = image.imageUri;
        vm.offerImage6ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage6 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage6Added = true;
        vm.offerImage6Uri = thisImage.imageuri;
        vm.offerImage6ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    // end upload Images

    //get eventsnames
    Api.getVenues().success(function (data) {
      vm.venues = data.venues;
      $log.info(vm.venues);
    }).error(function () {
      $log.info('get venues error');
    });

    function getEvents() {
      vm.events = [];
      vm.eventnames = [];
      vm.eventname = '';
      if ($rootScope.activeUser.role === 2) {
        Api.getAllEventsByVenue(parseInt($rootScope.activeUser.details.venue)).then(function (res) {
          vm.events = res.data.events;
          vm.limit = vm.events.length;
          vm.all = vm.events.length;
          for (var i = 0; i < vm.venues.length; i++) {
            for (var j = 0; j < vm.events.length; j++) {
              if (vm.events[j].venue === vm.venues[i].id) {
                vm.events[j].venueName = vm.venues[i].venuetitle;
              }
            }
          }
        }, function (err) {
          console.log(err);
        });
      } else {
        Api.getAllEvents().success(function (data) {
          vm.events = data.events;
          vm.limit = vm.events.length;
          vm.all = vm.events.length;
          for (var i = 0; i < vm.venues.length; i++) {
            for (var j = 0; j < vm.events.length; j++) {
              var nameofEvent = vm.events[j].name;
              vm.eventnames.push(nameofEvent);
            }
          }

        }).error(function () {
          $log.info('get events error');
        });
      }
    }
    getEvents();
    // state change steps
    vm.nextStep = function (data, state) {
      var nameerror = false;
      if ($state.current.name === 'admin.eventcreation.evcsetup') {
        if (vm.eventnames.indexOf(vm.newEvent.name)> -1) {
          vm.thesamename = true;
          nameerror = true;
        }
        var timeError = false,
          now = new Date(),
          hour = 3600000,
          modalInstance;
        vm.stepOne = true;
        vm.stTimeInPast = false;
        vm.endTimeInPast = false;
        vm.endBeforeStart = false;
        vm.dateError = false;
        vm.eventTime();
        if (!vm.dtStart || !vm.dtEnd) {
          timeError = true;
          vm.dateError = true;
        }

        if (vm.eventStart.getTime() < now.getTime()) {
          timeError = true;
          vm.stTimeInPast = true;
        }

        if (vm.eventEnd.getTime() < now.getTime()) {
          timeError = true;
          vm.endTimeInPast = true;
        }

        if (vm.eventEnd.getTime() < vm.eventStart.getTime()) {
          timeError = true;
          vm.endBeforeStart = true;
        }
        // && vm.newEvent.accessPointList.length >= 1
        if (!data.setUp.required && vm.eventImageAdded && !timeError && !nameerror) {
          if (vm.eventEnd.getTime() - vm.eventStart.getTime() < hour) {
            modalInstance = $modal.open({
              templateUrl: 'eventcreation/views/eventtimemodal.tpl.html',
              controller: 'CancelmodalCtrl as cancelModal'
            });

            modalInstance.result.then(function () {

              vm.setupPassed = true;
              vm.allowStateChange = true;
              $state.go(state);
            }, function () {
            });
          } else {
            vm.setupPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          }
        }
      }

      if ($state.current.name === 'admin.eventcreation.evcnetwork') {
        vm.stepTwo = true;
        var thisList = {
          zones: vm.newEvent.zoneList,
          ssidname: vm.newEvent.networkName
        };
        Api.checkSSIDName(vm.eventStart.getTime(), vm.eventEnd.getTime(), thisList).then(function (resp) {
          Api.checkEventCount(vm.eventStart.getTime(), vm.eventEnd.getTime(), thisList).then(function () {
            if (!data.network.required && !data.network.pattern && vm.newEvent.zoneList.length >= 1) {
              vm.netPassed = true;
              vm.allowStateChange = true;
              $state.go(state);
            }
          }, function (err) {
            vm.ssidCount = true;
            vm.ssidCountAP = err.data.accesspoints;
          });
        }, function (err) {
          vm.ssidExists = true;
          vm.nameCheckAP = err.data.accesspoints;
          Api.checkEventCount(vm.eventStart.getTime(), vm.eventEnd.getTime(), thisList).then(function () {

          }, function (err) {
            vm.ssidCount = true;
            vm.ssidCountAP = err.data.accesspoints;
          });
        });

      }

      if ($state.current.name === 'admin.eventcreation.evcsplash') {
        vm.stepThree = true;
        var thisSplashType = vm.splashType.name;
        if (!data.splash.required && vm.bckgrndImgAdded && vm.splashType.id > 0) {
          // console.log(vm.newEvent.videoUrl);
          // console.log(vm.splashType.length);

          if (thisSplashType === 'Social' && vm.openAuthList.length >= 1) {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else if (thisSplashType === 'Video' && vm.newEvent.splashDetails.videoUrl.length > 0) {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else if (thisSplashType === 'Payment' && vm.newEvent.splashDetails.amount > 0 && vm.newEvent.splashDetails.amount > 0) {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          }
        }
      }
      vm.allowStateChange = false;
    };

    vm.nameCheckResult = function () {
      var modalMessage = {
        messageHeader: 'Network Name Error',
        messageBody: 'Only one instance of a network name can run in a given time period, the access points below have already been assigned "' + vm.newEvent.networkName + '" for the given time period',
        accesspoints: vm.nameCheckAP
      };

      var modalInstance = $modal.open({
        templateUrl: 'eventcreation/views/ssidchecks.tpl.html',
        controller: 'SsidchecksCtrl as modalService',
        resolve: {
          message: function () {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function () {});

    };

    vm.ssidCountResult = function () {
      var modalMessage = {
        messageHeader: 'Network Limit Error',
        messageBody: 'A maximum of seven event can run simultainiously, the access points below will exceed this limit and cannot be created, remove them to cntinue',
        accesspoints: vm.ssidCountAP
      };

      var modalInstance = $modal.open({
        templateUrl: 'eventcreation/views/ssidchecks.tpl.html',
        controller: 'SsidchecksCtrl as modalService',
        resolve: {
          message: function () {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function () {});

    };

    // end state change

    // bandwidth
    vm.priceSlider = {
      value: 2,
      options: {
        floor: 0,
        ceil: 50,
        translate: function (value) {
          return value + ' MB';
        }
      }
    };
    // end bandwidth

    // open Auth
    vm.addOpenAuth = function (value) {
      if (vm.openAuthList.indexOf(value) > -1) {
        vm.openAuthList.splice(vm.openAuthList.indexOf(value), 1);
      } else {
        vm.openAuthList.push(value);
      }
    };
    // end openauth

    vm.cancelCreate = function () {
      $state.go('admin.events');
    };

    vm.setVenueDetails = function () {
      var loopIndex;
      var detailsJson;
      vm.venue = {};
      for(loopIndex = 0; loopIndex < vm.venues.length;loopIndex++) {
        if(vm.newEvent.venueId === vm.venues[loopIndex].id) {
          if (vm.venues[loopIndex].details) {
            if(typeof vm.venues[loopIndex].details === 'string'){
              detailsJson = JSON.parse(vm.venues[loopIndex].details);
            } else {
              detailsJson = vm.venues[loopIndex].details;
            }
            if (detailsJson.fbpage) {
               vm.venue.customFbLikeUrl = detailsJson.fbpage;
            } else {
              vm.defaultFbLikeUrl = false;
              vm.customFbLike = true;
            }
          }
        }
      }
    };

    // create event
    vm.createEvent = function () {
      vm.eventTime();
      var thisSplash = vm.newEvent.splashDetails;

      if (thisSplash.amount) {
        thisSplash.amount = thisSplash.amount * 100;
      }
      if(vm.defaultFbLikeUrl && vm.addlikebutton){
        thisSplash.customFbLikeUrl = vm.venue.customFbLikeUrl;
      } else if (!vm.addlikebutton) {
        thisSplash.customFbLikeUrl = undefined;
      }

      if(vm.marketingOn)
      {
        thisSplash.marketingOn = true;
        thisSplash.ads = [];
        thisSplash.adLinks = [];
        thisSplash.adURIs = [];
        if(vm.offerImage1ID != '' )
        {
          thisSplash.ads.push(vm.offerImage1ID);
          thisSplash.adURIs.push(vm.offerImage1Uri);
          thisSplash.adLinks.push(vm.offerImage1Link);
        }
        if(vm.offerImage2ID != '' )
        {
          thisSplash.ads.push(vm.offerImage2ID);
          thisSplash.adURIs.push(vm.offerImage2Uri);
          thisSplash.adLinks.push(vm.offerImage2Link);
        }
        if(vm.offerImage3ID != '' )
        {
          thisSplash.ads.push(vm.offerImage3ID);
          thisSplash.adURIs.push(vm.offerImage3Uri);
          thisSplash.adLinks.push(vm.offerImage3Link);
        }
        if(vm.offerImage4ID != '' )
        {
          thisSplash.ads.push(vm.offerImage4ID);
          thisSplash.adURIs.push(vm.offerImage4Uri);
          thisSplash.adLinks.push(vm.offerImage4Link);
        }
        if(vm.offerImage5ID != '' )
        {
          thisSplash.ads.push(vm.offerImage5ID);
          thisSplash.adURIs.push(vm.offerImage5Uri);
          thisSplash.adLinks.push(vm.offerImage5Link);
        }
        if(vm.offerImage6ID != '' )
        {
          thisSplash.ads.push(vm.offerImage6ID);
          thisSplash.adURIs.push(vm.offerImage6Uri);
          thisSplash.adLinks.push(vm.offerImage6Link);
        }
      }

      if (thisSplash.period) {
        thisSplash.period = thisSplash.period * 3600000;
      }

      var surveyObj = vm.setSurveyObject();

      if (vm.anonymousLogin !== undefined) {
        if(vm.anonymousLogin.on) {
          vm.addOpenAuth(vm.anonymousLogin.id);
        }
      }


      var thisBandwidth = vm.priceSlider.value * 1000,
        thisEvent = {
          orgId: vm.newEvent.org,
          venueId: vm.newEvent.venueId,
          name: vm.newEvent.name,
          description: vm.newEvent.description,
          eventimage: vm.eventImageID,
          ssidname: vm.newEvent.networkName,
          starttime: vm.eventStart,
          endtime: (vm.noend) ? new Date(253402300740905):vm.eventEnd,
          accesspoints: vm.newEvent.accessPointList,
          zones: vm.newEvent.zoneList,
          categories: vm.newEvent.optCatList,
          trafficlimitsta: thisBandwidth,
          splashpagebackgroundimage: vm.bckgrndImgID,
          splashpagelogoimage: vm.venueLogoID,
          sociallogintypes: vm.openAuthList,
          redirectionurl: vm.newEvent.redirectionURL,
          splashDetails: thisSplash,
          details: vm.newEvent.details,
          networkTypeId: vm.newEvent.networkType,
          splashTypeId: vm.splashType.id,
          survey: surveyObj
        };

      $log.info(thisEvent);

      Api.createEvent(thisEvent, vm.newEvent.venueId).success(function () {
        vm.allowStateChange = true;
        vm.storage.alerts.push({
          messageType: 'event',
          message: 'New Event Created'
        });
        $state.go('admin.events');
      }).error(function (error) {
        $log.info(error);
      });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name eventcreation.controller:CancelmodalCtrl
   *
   * @description
   *
   */
  CancelmodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('eventcreation')
    .controller('CancelmodalCtrl', CancelmodalCtrl);

  function CancelmodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'CancelmodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name events
   * @description
   *
   */
  angular
    .module('events', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.controller:EventsCtrl
   *
   * @description
   *
   */
  EventsCtrl.$inject = ["$state", "Api", "$log", "$modal", "$rootScope", "Cloudservices", "Twilio"];
  angular.module('events').controller('EventsCtrl', EventsCtrl);

  function EventsCtrl($state, Api, $log, $modal, $rootScope, Cloudservices, Twilio) {
    var vm = this;
    vm.events = [];
    vm.venues = [];

    vm.showArchieved = false;
    vm.showCurrent = true;

    vm.maxDate = new Date(253402300740905).toISOString();

    vm.setEventView = function () {
      if (vm.showCurrent) {
        vm.showArchieved = true;
        vm.showCurrent = false;
      } else {
        vm.showArchieved = false;
        vm.showCurrent = true;
      }
      console.log(vm.showCurrent, vm.showArchieved);
    };

    vm.limits = [1, 5, 10, 15, 20, 50, 'all'];
    vm.all = 0;
    vm.limit = 0;

    vm.setLimit = function (limit) {
      console.log(limit);
      if (limit === 'all') {
        vm.limit = vm.all;
      }
    };

    Api.getVenues().success(function (data) {
      vm.venues = data.venues;
      $log.info(vm.venues);
    }).error(function () {
      $log.info('get venues error');
    });

    function getEvents() {
      vm.events = [];
      if ($rootScope.activeUser.role === 2) {
        Api.getAllEventsByVenue(parseInt($rootScope.activeUser.details.venue)).then(function (res) {
          vm.events = res.data.events;

          vm.limit = vm.events.length;
          vm.all = vm.events.length;
          for (var i = 0; i < vm.venues.length; i++) {
            for (var j = 0; j < vm.events.length; j++) {
              if (vm.events[j].venue === vm.venues[i].id) {
                vm.events[j].venueName = vm.venues[i].venuetitle;
              }
            }
          }
        }, function (err) {
          console.log(err);

        });
      } else {
        Api.getAllEvents().success(function (data) {
          vm.events = data.events;

          vm.limit = vm.events.length;
          vm.all = vm.events.length;
          for (var i = 0; i < vm.venues.length; i++) {
            for (var j = 0; j < vm.events.length; j++) {
              if (vm.events[j].venue === vm.venues[i].id) {
                vm.events[j].venueName = vm.venues[i].venuetitle;
              }
            }
          }
          $log.info(data.events);

        }).error(function () {
          $log.info('get events error');
        });
      }
    }

    vm.showErrors = function (details) {
      var newDetails = details;
      var modalMessage = {};
      if (!newDetails.unifi) {
        modalMessage.xirrus = true;
        modalMessage.messageHeader = 'Unreachable Access Points';
        modalMessage.messageBody = 'The following access points were unreachable';
        modalMessage.accesspoints = details.unreachable_ap;
      } else {
        modalMessage.messageHeader = 'Event Error';
        modalMessage.messageBody = 'Configuration error, check venue network configuration';
      }

      var modalInstance = $modal.open({
        templateUrl: 'events/views/errormodal.tpl.html',
        controller: 'ErrormodalCtrl as modalService',
        resolve: {
          message: function () {
            return modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {});
    };

    vm.deleteEvent = function (eventid, venueid) {
      var modalInstance = $modal.open({
        templateUrl: 'events/views/deletemodal.tpl.html',
        controller: 'DeletemodalCtrl as deleteModal'
      });

      modalInstance.result.then(function () {
        Api.deleteEvent(eventid, venueid).success(function (data) {
          $log.info(data);
          getEvents();
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.sendMessage = function (venue, event) {
      var twilio = {};
      var provider = 0;
      var config = {
        test: 'test'
      };
      Cloudservices.getCloudServiceByName(venue, 'twilio').then(function (resp) {
        twilio = resp.data.cloudservices[0];
        console.log(twilio);
      }, function (err) {
        console.log(err);
      });
      Api.getInfrastructures(venue).then(function (resp) {
        provider = resp.data.infrastructures[0].provider;
      }, function (err) {
        console.log(err);
      });
      var modalInstance = $modal.open({
        templateUrl: 'twilio-modal/views/twilio-modal.tpl.html',
        controller: 'TwilioModalCtrl as TwilioModalCtrl',
        resolve: {
          config: function () {
            return config;
          }
        }
      });

      modalInstance.result.then(function (message) {
        var callObj = {
          venueid: venue,
          providerid: provider,
          eventid: event,
          accountSid: twilio.accountsid,
          authToken: twilio.apikey,
          fromNum: twilio.phonenumber,
          message: message
        };
        Twilio.sendSMS(callObj).then(function (res) {
          console.log(res);
        }, function (err) {
          console.log(err);
        });
      });
    };

    getEvents();
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.controller:ErrormodalCtrl
   *
   * @description
   *
   */
  ErrormodalCtrl.$inject = ["$modalInstance", "message"];
  angular
    .module('events')
    .controller('ErrormodalCtrl', ErrormodalCtrl);

  function ErrormodalCtrl($modalInstance, message) {
    var vm = this;
    vm.message = message;

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.controller:DeletemodalCtrl
   *
   * @description
   *
   */
  DeletemodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('events')
    .controller('DeletemodalCtrl', DeletemodalCtrl);

  function DeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'DeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name event
   * @description
   *
   */
  angular
    .module('event', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name event.controller:EventCtrl
   *
   * @description
   *
   */
  EventCtrl.$inject = ["$stateParams", "Api"];
  angular
    .module('event')
    .controller('EventCtrl', EventCtrl);

  function EventCtrl($stateParams, Api) {
    var vm = this;
    vm.ctrlName = 'EventCtrl';
    vm.socialLoginTypes = [];
    Api.getEvent($stateParams.eventid, $stateParams.venueid).success(function (data) {
      vm.event = data.event;
      for (var i =0; i < vm.event.socialLoginTypes.length; i++){
        vm.socialLoginTypes.push(vm.event.socialLoginTypes[i].id);
      }
    });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editvenuemap
   * @description
   *
   */
  angular
    .module('editvenuemap', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editvenuemap.controller:EditvenuemapCtrl
   *
   * @description
   *
   */
  EditvenuemapCtrl.$inject = ["baseurl", "$stateParams", "$window", "Api", "ApiAnalytic", "$state", "$scope"];
  angular
    .module('editvenuemap')
    .controller('EditvenuemapCtrl', EditvenuemapCtrl);

  function EditvenuemapCtrl(baseurl, $stateParams, $window, Api, ApiAnalytic, $state, $scope) {
    var vm = this;
    vm.ctrlName = 'EditvenuemapCtrl';

    vm.venueid = $stateParams.venueid;
    var orgid = $stateParams.orgid;
    vm.mapId = $stateParams.mapId;
    vm.mapName = '';

    // Data Sets
    vm.accesspoints = [];
    vm.onmapaps = [];
    vm.accesspointData = [];

    // Page Objects
    var backDrop = document.getElementById('backDrop');
    var pointMap = document.getElementById('pointMap');
    var heatMapContainer = document.getElementById('heatMapContainer');

    /* Mouse Control */
    vm.dragging = false;
    vm.canvasApDrag = {};
    pointMap.addEventListener('mousedown', function(event) {
      if(vm.accesspointData.length !== 0) {
        for(var i = 0; i < vm.accesspointData.length; i++){
          // Do drag
          var a = event.offsetX - vm.accesspointData[i].x;
          var b = event.offsetY - vm.accesspointData[i].y;
          var dist = Math.sqrt( a*a + b*b );
          if (Math.abs(dist) < 10) {
            vm.canvasApDrag = vm.accesspointData[i];
            vm.accesspointData.splice(i,1);
            vm.dragging = true;
          }
          // Do clear
          if (!vm.dragging) {
            var aX = event.offsetX - (vm.accesspointData[i].x+(vm.mapWidth/32)*.6);
            var bX = event.offsetY - (vm.accesspointData[i].y-(vm.mapWidth/32)*.6);
            var distX = Math.sqrt( aX*aX + bX*bX );
            if (Math.abs(distX) < 6) {
              var ap = vm.accesspointData[i];
              vm.accesspointData.splice(i,1);
              for(var j = 0; j < vm.onmapaps.length; j++){
                if (ap.hwaddr === vm.onmapaps[j].hwaddr) {
                  vm.accesspoints.push(vm.onmapaps[j]);
                  vm.onmapaps.splice(j,1);
                  vm.draw(vm.accesspointData);
                  $scope.$apply();
                  j = vm.onmapaps.length;
                }
              }
            }
          }

        }
      }
    }, false);
    pointMap.addEventListener('mousemove', function(e) {
      if (vm.dragging){
        var x =  event.offsetX;
        var y = event.offsetY;
        if (vm.canvasApDrag !== {}) {
          vm.canvasApDrag.x = x;
          vm.canvasApDrag.y = y;
        }
        vm.draw(vm.accesspointData);
      }
    }, true);
    pointMap.addEventListener('mouseup', function(event) {
      if (vm.dragging) {
        vm.accesspointData.push(vm.canvasApDrag);
        vm.canvasApDrag = {};
        vm.dragging = false;
        vm.draw(vm.accesspointData);
      }
    }, false);

    // Context
    var context = pointMap.getContext('2d');
    var backCtx = backDrop.getContext('2d');
    backCtx.globalAlpha = 1.0;
    context.globalAlpha = 1.0;

    // Setup Data
    vm.mapHeight = 480;
    vm.mapWidth = 854;
    vm.dragingAp = {};

    // image Data
    vm.backdrop = '';
    vm.backgroundSelected = false;
    vm.backdropId = 0;
    vm.backdropUri = '';
    vm.apicon = './images/apicon.png';
    vm.imageloaded = false;
    vm.percent = 0;
    vm.originalHeight = 0;
    vm.originalWidth = 0;
    var imageObj = new Image();


    vm.getMap = function(mapId, venueid) {
      ApiAnalytic.getMap(venueid, mapId).then(function (res) {
        vm.backdrop = baseurl + '/' + res.data.map.imageUri;
        vm.backdropUri = res.data.map.imageUri;
        vm.backdropId = res.data.map.imageId;
        vm.accesspointData = res.data.map.points;
        vm.mapName = res.data.map.name;
        vm.mapId = res.data.map._id;

        vm.originalHeight = res.data.map.originalHeight;
        vm.originalWidth = res.data.map.originalWidth;

        vm.mapLoad = true;
        vm.startMap();
      }, function (err) {
        console.log(err);
      });
    };
    vm.submit = function() {
      var map = {
        'name': vm.mapName,
        'venue': vm.venueid,
        'originalHeight': vm.mapHeight,
        'originalWidth': vm.mapWidth,
        'imageId': vm.backdropId,
        'imageUri': vm.backdropUri,
        'points': vm.accesspointData
      };
      ApiAnalytic.editMap(map, vm.venueid, vm.mapId).then(function (res) {
        $state.go('admin.managenetwork', {
          'venueid': $stateParams.venueid
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.startMap = function(){
      // fetch Aps
      Api.getAccessPoints(vm.venueid).then(function (res) {
        vm.accesspoints = res.data.apoints;
        for(var i = 0;i<vm.accesspoints.length;i++){
          vm.accesspointData.forEach(function(point){
            if (point.hwaddr === vm.accesspoints[i].hwaddr) {
              vm.onmapaps.push(vm.accesspoints[i]);
              vm.accesspoints.splice(i,1);
            }
          });
        };
        vm.drawBackground();
      }, function (err) {
        console.log(err);
      });
    };

    // Drawing
    vm.draw = function(data) {
      context.clearRect(0, 0, pointMap.width, pointMap.height);
      for(var i=0; i<data.length; i++) {
        vm.drawAp(data[i]);
      }
      if(vm.dragging) {
        vm.drawAp(vm.canvasApDrag);
      }
    };
    vm.drawX = function(x, y) {
      context.beginPath();
      var size = 5;

      context.strokeStyle = '#ff0000';
      context.lineWidth = 2;

      context.moveTo(x - size, y - size);
      context.lineTo(x + size, y + size);

      context.moveTo(x + size, y - size);
      context.lineTo(x - size, y + size);

      context.stroke();
    }
    vm.drawBackground = function () {
      var background = new Image();

      background.onload = function() {
        background.height = vm.originalHeight;
        background.width = vm.originalWidth;
        if (heatMapContainer.offsetWidth < background.width) {
          var max = background.width;
          var reduced = heatMapContainer.offsetWidth;
          var diff = max - reduced;
          vm.percent = parseInt((diff/max)*100);
          background.width = reduced;
          background.height = parseInt((background.height/100)*(100 - vm.percent));
          //background.height = parseInt((background.height/100)*percent);
        } else {
          var min = background.width;
          var scaled = heatMapContainer.offsetWidth;
          var diff = scaled - min;
          vm.percent = parseInt((diff/min)*100);
          background.width = scaled;
          background.height = background.height + parseInt((background.height/100)*vm.percent);
        }

        vm.accesspointData.forEach(function(ap) {
          ap.x = ap.x*(background.width/vm.originalWidth);
          ap.y = ap.y*(background.height/vm.originalHeight);
        });


        vm.mapHeight = background.height;
        vm.mapWidth = background.width;

        pointMap.width = vm.mapWidth;
        pointMap.height = vm.mapHeight;
        backDrop.width = vm.mapWidth;
        backDrop.height = vm.mapHeight;

        $scope.$apply();

        backCtx.drawImage(background, 0,0, vm.mapWidth, vm.mapHeight);
        vm.loadIcon();
        vm.draw(vm.accesspointData);
      };
      background.src = vm.backdrop;
    };
    vm.drawAp = function(data) {
      if (vm.imageloaded) {
        context.font = (vm.mapWidth/32)/3+"px Verdana";
        context.fillStyle = 'white';
        context.strokeStyle = 'black';
        context.lineWidth = .5;
        context.drawImage(imageObj, data.x - (vm.mapWidth/32)/2, data.y - (vm.mapWidth/32)/2, vm.mapWidth/32, vm.mapWidth/32);
        context.fillText(data.name, data.x- (vm.mapWidth/32)/2, data.y+(vm.mapWidth/32)*.8);
        context.strokeText(data.name,data.x- (vm.mapWidth/32)/2,data.y+(vm.mapWidth/32)*.8);

        vm.drawX(data.x + (vm.mapWidth/32)*.6, data.y - (vm.mapWidth/32)*.6)
      }
    };

    // Image Functions
    vm.loadIcon= function () {
      imageObj.onload = function() {
        vm.imageloaded = true;
        vm.draw(vm.accesspointData);
      };
      imageObj.src = vm.apicon;
    }

    // Drag N Drop Onto Canvas
    vm.handleApDrop = function(element) {
      var ap = {
        x: element.x,
        y: element.y,
        name: vm.dragingAp.name,
        hwaddr: vm.dragingAp.hwaddr
      }
      vm.dragingAp = {};

      for(var i = 0; i < vm.accesspoints.length; i++) {
        if (ap.hwaddr === vm.accesspoints[i].hwaddr) {
          vm.accesspointData.push({
            name: ap.name,
            hwaddr: ap.hwaddr,
            x: ap.x,
            y: ap.y
          });
          vm.onmapaps.push(vm.accesspoints[i]);
          vm.accesspoints.splice(i, 1);
        }
      }
      vm.draw(vm.accesspointData);
    }


    vm.getMap(vm.mapId, vm.venueid);
  }
  angular.module('leoperations').directive('dragEditAps', function() {
    return function(scope, element) {
      var el = element[0];

      el.draggable = true;

      el.addEventListener('dragstart',function(e) {
        e.dataTransfer.effectAllowed = 'move';
        //e.dataTransfer.setData('Text', this.id);
        var ap = e.target.id.split("|");
        scope.$parent.editvenuemap.dragingAp = {
          hwaddr: ap[1],
          name: ap[0]
        };
        this.classList.add('drag');
        return false;
      },false);

      el.addEventListener('dragend', function(e) {
        this.classList.remove('drag');
        return false;
      },false);
    }
  });
  angular.module('leoperations').directive('dropEditAps', function() {
    return {
      scope: {},
      link: function(scope, element) {
        var el = element[0];

        el.addEventListener('dragover',function(e) {
          e.dataTransfer.dropEffect = 'move';
          // allows us to drop
          if (e.preventDefault) e.preventDefault();
          this.classList.add('over');
          return false;
        },false);
        el.addEventListener('dragenter',function(e) {
          this.classList.add('over');
          return false;
        },false);
        el.addEventListener('dragleave',function(e) {
          this.classList.remove('over');
          return false;
        },false);

        el.addEventListener('drop',function(e) {
          if (e.stopPropagation) e.stopPropagation();
          this.classList.remove('over');

          scope.$apply(function() {
            scope.$parent.editvenuemap.handleApDrop({
              x: e.layerX,
              y: e.layerY
            });
          });

          return false;
        },false);
      }
    }
  });
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editvenue
   * @description
   *
   */
  angular
    .module('editvenue', [
      'ui.router'
    ]);
}());

(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name editvenue.controller:EditvenueCtrl
   *
   * @description
   *
   */
  EditvenueCtrl.$inject = ["Api", "$modal", "$state", "$stateParams", "$log"];
  angular
    .module('editvenue')
    .controller('EditvenueCtrl', EditvenueCtrl);

  function EditvenueCtrl(Api, $modal, $state, $stateParams, $log) {
    var vm = this;
    vm.ctrlName = 'EditvenueCtrl';

    vm.venue = {};
    vm.submitted = false;
    var venueid = $stateParams.venueid;
    var orgid = $stateParams.orgid;

    vm.socialMediaAccounts = [{
      title: 'Facebook Page',
      example: 'eg. https://www.facebook.com/Liquidedge/',
      value: '',
      key: 'fbpage'
    }];

    vm.status = {
      isopen: false
    };

    // vm.selectImage = function() {
    //
    //   vm.modalMessage = {
    //     orgid: orgid
    //   };
    //
    //   var modalInstance = $modal.open({
    //     templateUrl: 'selectimage/views/selectimage.tpl.html',
    //     controller: 'SelectimageCtrl as selectimage',
    //     resolve: {
    //       message: function() {
    //         return vm.modalMessage;
    //       }
    //     }
    //   });
    //
    //   modalInstance.result.then(function(image) {
    //     var thisImage = angular.fromJson(image);
    //     vm.venue.imageUrl = thisImage.imageuri;
    //     vm.venue.imageId = thisImage.id;
    //   }, function() {
    //     console.log('canceled');
    //   });
    // };

    vm.uploadImage = function() {
      vm.modalMessage = {
        orgid: orgid
      };

      var modalInstance = $modal.open({
        templateUrl: 'uploadorgimg/views/uploadorgimg.tpl.html',
        controller: 'UploadorgimgCtrl as modal',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });

      modalInstance.result.then(function(image) {
        console.log(image);
        vm.venue.imageUrl = image.imageUri;
        vm.venue.imageId = image.id;
        console.log(vm.venue);
        console.log('success');
      }, function() {
        console.log('canceled');
      });
    };

    vm.updateVenue = function(valid) {
      vm.submitted = true;
      if (valid) {
        vm.venue.details = {};
        vm.socialMediaAccounts.forEach(function(item){
          if(item.value.length > 0) {
            vm.venue.details[item.key] = item.value;
          }
        });
        console.log(vm.venue);
        Api.updateVenue(venueid, vm.venue).success(function(data) {
          console.log(data);
          $state.go('admin.venues');
        }, function(err) {
          console.log(err);
        });
      }
    };

    function getVenue(venueid) {
      Api.getVenue(venueid).then(function(resp) {
        $log.info(resp);
        vm.venue.venueTitle = resp.data.venue.venuetitle;
        vm.venue.imageUrl = resp.data.venue.imageuri;
        vm.venue.imageId = resp.data.venue.imageid;
        vm.venue.contactEmail = resp.data.venue.contactemail;
        vm.venue.contactPhone = resp.data.venue.contactphone;
        vm.venue.description = resp.data.venue.description;
        vm.venue.location = resp.data.venue.location;
        vm.venue.website =  resp.data.venue.website;
        vm.venue.details = resp.data.venue.details;
        vm.venue.siteid = resp.data.venue.siteid;
        vm.venue.trapid = resp.data.venue.trapid;
        vm.socialMediaAccounts.forEach(function(item){
          if(vm.venue.details[item.key]) {
            item.value = vm.venue.details[item.key];
          }
        });
      }, function(err) {
        $log.info(err);
      });
    }

    getVenue(venueid);
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name operators.controller:PasswordmodalCtrl
   *
   * @description
   *
   */
  PasswordmodalCtrl.$inject = ["$modalInstance", "message"];
  angular
    .module('operators')
    .controller('PasswordmodalCtrl', PasswordmodalCtrl);

  function PasswordmodalCtrl($modalInstance, message) {
    var vm = this;
    vm.message = message.message;
    vm.password = '';
    vm.ok = function () {
      $modalInstance.close(vm.password);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editoperator
   * @description
   *
   */
  angular
    .module('editoperator', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editoperator.controller:EditoperatorCtrl
   *
   * @description
   *
   */
  EditoperatorCtrl.$inject = ["Api", "$log", "$state", "$modal", "$stateParams"];
  angular
    .module('editoperator')
    .controller('EditoperatorCtrl', EditoperatorCtrl);

  function EditoperatorCtrl(Api, $log, $state, $modal, $stateParams) {
    var vm = this;
    vm.operator = {};
    vm.submitted = false;
    vm.venues = [];

    vm.updatePassword = function(id) {
      var modalInstance = $modal.open({
        templateUrl: 'editoperator/views/passwordmodal.tpl.html',
        controller: 'PasswordmodalCtrl as modal',
        resolve: {
          message: {
            message: 'Update Operator Password'
          }
        }
      });
      modalInstance.result.then(function (password) {
        var data = {
          password: password
        };
        Api.updateOperatorPassword(id, data).success(function (result) {
          $log.info(result);
        }).error(function (err) {
          $log.info(err);
        });
      }, function () {
        $log.info('canceled');
      });
    };
    Api.getRoles().success(function (data) {
      vm.roles = data.organisationRoles;
      $log.info(vm.roles);
    }).error(function (err) {
      $log.info(err);
    });

    Api.getOperator($stateParams.operatorid).success(function (data) {
      vm.operator = data.operator;
      vm.operator.roleId = data.operator.role;
      $log.info(vm.operator);
    }).error(function (err) {
      $log.info(err);
    });

    Api.getVenues().then(function(result){
      vm.venues = result.data.venues;
      console.log(vm.venues);
    }, function (err){
      console.log(err);
    });

    vm.setVenue = function(venue){
      for (var i = 0; i < vm.venues.length; i++){
        if(vm.venues[i].id === venue){
        vm.operator.details.venueName = vm.venues[i].venuetitle;
          break;
        }
      }

    };

    vm.updateOperator = function (valid) {
      vm.submitted = true;
      if (valid) {
        Api.updateOperator($stateParams.operatorid, vm.operator).success(function (data) {
          $log.info(data);
          $state.go('admin.operators');
        }).error(function (err) {
          $log.info(err);
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editsurvey
   * @description
   *
   */
  angular
    .module('editsurvey', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editsurvey.controller:EditsurveyCtrl
   *
   * @description
   *
   */
  EditsurveyCtrl.$inject = ["$rootScope", "$state", "$stateParams", "Api", "$log", "$modal"];
  angular
    .module('editsurvey')
    .controller('EditsurveyCtrl', EditsurveyCtrl);

  function EditsurveyCtrl($rootScope, $state, $stateParams, Api, $log, $modal) {
    var vm = this;
    vm.ctrlName = 'EditsurveyCtrl';
    vm.survey = [];
    vm.surveyObjects = [];
    vm.selectedSurvey = false;
    vm.numberOfQuestionsTotal = 4;
    vm.numberOfQuestions = 0;
    vm.answers = [];
    vm.surveyLoaded = false;
    vm.isActive = false;

    Api.getSurveyById($stateParams.eventid, $stateParams.surveyid).then(function (response) {
      vm.survey = response.data.survey;
      if (vm.survey !== null && vm.survey) {
        vm.surveyLoaded = true;
        setSurveyVariables();
      }
    }, function (err) {
      $log.info(err);
    });

    vm.addQuestion = function () {
      if(vm.numberOfQuestions < vm.numberOfQuestionsTotal) {
        vm.surveyObjects[vm.numberOfQuestions].show = true;
        vm.numberOfQuestions++;
      }
    };

    vm.removeQuestion = function () {
      if(vm.numberOfQuestions > 0) {
        vm.numberOfQuestions--;
        vm.surveyObjects[vm.numberOfQuestions].show = false;
      }
    };

    function setSurveyVariables() {
      vm.numberOfQuestions = vm.survey.details.survey.length;
      console.log(vm.survey);
      vm.isActive = vm.survey.isactive;
      console.log(vm.isActive);
      for (var i=0; i<vm.numberOfQuestions; i++) {
        var surveyObj = { "id": i, "show": true, survey: vm.survey.details.survey[i]};
        var answers = {answers: vm.survey.details.survey[i].answers};
        vm.answers.push(answers);
        vm.surveyObjects.push(surveyObj);
      }
      addEmptySurveyObjects();
    }

    function addEmptySurveyObjects() {
      for (var i=vm.numberOfQuestions; i<vm.numberOfQuestionsTotal; i++){
        var surveyObj = { "id": i, "show": false};
        var answers = [];
        for (var k=0; k<4; k++){
          var ansObj = {"answer": "", "count": 0};
          answers.push(ansObj);
        }
        var answersObj = {answers: answers};
        vm.answers.push(answersObj);
        vm.surveyObjects.push(surveyObj);
      }
    }

    vm.setSurveyObject = function () {
      var details = [];
      for (var i=0; i < vm.numberOfQuestions; i++) {
        if (vm.surveyObjects[i].survey !== null && vm.surveyObjects[i].survey && vm.surveyObjects[i].survey.question !== null && vm.surveyObjects[i].survey.question){
          var answers = [];
          for (var j = 0; j < vm.answers[i].answers.length; j++){
            answers.push({"answer": vm.answers[i].answers[j].answer, "count": vm.answers[i].answers[j].count});
          }
          var surveyObj = {
            "question": vm.surveyObjects[i].survey.question,
            "answers": answers
          };
          details.push(surveyObj);
        }
      }

      var surveyObj = {survey: details};
      var survey = {name: vm.survey.name, isActive: vm.isActive, details: surveyObj};
      return survey;
    };

    vm.updateSurvey = function () {
      var surveyObj = vm.setSurveyObject();
      Api.updateSurvey($stateParams.eventid, $stateParams.surveyid, surveyObj).success(function (data) {
        $log.info(data);
        $state.go('admin.events');
      }).error(function (err) {
        $log.info(err);
      });
    };

    vm.addSurvey = function () {
      $state.go('admin.addsurvey', {eventid: $stateParams.eventid});
    };

    vm.deleteSurvey = function (id) {
      var modalInstance = $modal.open({
        templateUrl: 'editsurvey/views/delsurveymodal.tpl.html',
        controller: 'DelsurveymodalCtrl as delsurveymodal'
      });

      modalInstance.result.then(function () {
        Api.deleteSurvey($stateParams.eventid, $stateParams.surveyid).success(function () {
          $state.go('admin.events');
        });
      }, function (err) {
        console.log(err);
      });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editsurvey.controller:DelsurveymodalCtrl
   *
   * @description
   *
   */
  DelsurveymodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('editsurvey')
    .controller('DelsurveymodalCtrl', DelsurveymodalCtrl);

  function DelsurveymodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'DelsurveymodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editnetworktype
   * @description
   *
   */
  angular
    .module('editnetworktype', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editnetworktype.controller:EditnetworktypeCtrl
   *
   * @description
   *
   */
  EditnetworktypeCtrl.$inject = ["$stateParams", "Api", "$log", "$state"];
  angular
    .module('editnetworktype')
    .controller('EditnetworktypeCtrl', EditnetworktypeCtrl);

  function EditnetworktypeCtrl($stateParams, Api, $log, $state) {
    var vm = this;
    var venueid = $stateParams.venueid;
    var typeid = $stateParams.typeid;

    vm.networkType = {};
    vm.submitted = false;
    vm.selectedStrategies = [];

    vm.setProvider = function () {
      vm.provider = $stateParams.provider;
    };

    vm.updateNetworkType = function (valid) {
      vm.submitted = true;
      console.log(vm.networkType);
      if (valid) {
        var thisNetworkType = {};
        thisNetworkType.name = vm.networkType.name;
        thisNetworkType.vlanId = vm.networkType.vlanid;
        thisNetworkType.details = vm.networkType.details;
        thisNetworkType.networkStrategies = vm.selectedStrategies;
        Api.updateNetworkType(typeid, venueid, thisNetworkType).then(function (success) {
          $log.info(success);
          $state.go('admin.managenetwork', {
            venueid: venueid
          });
        }, function (err) {
          $log.info(err);
        });
      }
    };

    Api.getNetworkType(typeid, venueid).then(function (success) {
      $log.info(success);
      vm.networkType = success.data.networkType;
      for (var j = 0; j < vm.networkType.networkStrategies.length; j++) {
        vm.selectedStrategies.push(vm.networkType.networkStrategies[j].id);
      }
      console.log(vm.networkType);
      // console.log(vm.networkType.networkStrategies);
      // console.log(vm.ntstrategies);
    }, function (err) {
      $log.info(err);
    });
    vm.setProvider();
    Api.getCategories(vm.provider).then(function (data) {
      vm.ntstrategies = data.data.categories;
      $log.info(vm.ntstrategies);
    }, function (err) {
      $log.info('Error: ', err);
    });

    vm.cancel =function(){
      $state.go('admin.managenetwork', {
        venueid: venueid
      });
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editeventnew
   * @description
   *
   */
  angular
    .module('editeventnew', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editeventnew.controller:EventsplashmodalCtrl
   *
   * @description
   *
   */
  EventsplashmodalCtrl.$inject = ["params", "$modalInstance", "Api"];
  angular
    .module('editeventnew')
    .controller('EventsplashmodalCtrl', EventsplashmodalCtrl);

  function EventsplashmodalCtrl(params, $modalInstance, Api) {
    var vm = this;
    vm.ctrlName = 'EventsplashmodalCtrl';
    vm.params = params;
    vm.isMagnet = vm.params.isMagnet;
    vm.venue = {};
    vm.defaultFbLikeUrl = true;

    vm.anonymousLogin = vm.params.anonymousLogin;

    Api.getVenue(params.venueid).success(function (data) {
      var detailsJson = {};
      if(typeof data.venue.details === 'string'){
        detailsJson = JSON.parse(data.venue.details);
      } else {
        detailsJson = data.venue.details;
      }
      if(detailsJson){
        vm.venue.customFbLikeUrl = detailsJson.fbpage;
        if (vm.params.splashDetails.customFbLikeUrl === vm.venue.customFbLikeUrl){
          vm.defaultFbLikeUrl = true;
        } else {
          vm.customFbLike = true;
          if (!vm.params.splashDetails.customFbLikeUrl) {
            vm.customFbLike = false;
          }
        }
      } else {
        vm.defaultFbLikeUrl = false;
        vm.customFbLike = true;
      }
    });

    vm.setSplashPageType = function (type, set) {
      vm.spVideo = false;
      vm.spSocial = false;
      vm.spAmount = false;
      vm.spEmail = false;
      vm.spLink = false;
      vm.spRegister = false;
      vm.conference = false;

      if (vm.params.splashDetails) {
        vm.defaultFbLikeUrl = (vm.params.splashDetails.customFbLikeUrl === vm.venue.customFbLikeUrl) ? true : false;
        vm.addlikebutton = (vm.params.splashDetails.customFbLikeUrl) ? true : false;
      } else {
        vm.addlikebutton = false;
        vm.defaultFbLikeUrl = true;
        vm.customFbLike = false;

      }

      var name = type.name;
      switch (name) {
      case 'Video':
        vm.spVideo = true;
        break;
      case 'Social':
        vm.spSocial = true;
        break;
      case 'Email':
        vm.spEmail = true;
        break;
      case 'Link':
        vm.spLink = true;
        break;
      case 'Payment':
        vm.spAmount = true;
        break;
      case 'Register':
        vm.spRegister = true;
        break;
      case 'Conference':
          vm.conference = true;
          vm.spEmail = true;
          break;
      }
      if (set) {
        for (var splash in vm.params.splashPageTypes) {
          if (vm.params.splashPageTypes[splash].id === type.id) {
            vm.params.splashType = vm.params.splashPageTypes[splash];
          }
        }
      }
    };

    vm.addOpenAuth = function (value) {
      if (vm.params.openAuthList.indexOf(value) > -1) {
        vm.params.openAuthList.splice(vm.params.openAuthList.indexOf(value), 1);
      } else {
        vm.params.openAuthList.push(value);
      }
    };

    vm.selectBckgrndImage = function () {
      vm.params.selectBckgrndImage = true;
      $modalInstance.close(vm.params);
    };

    vm.uploadBckgrndImage = function () {
      vm.params.uploadBckgrndImage = true;
      $modalInstance.close(vm.params);
    };

    vm.uploadLogoImage = function () {
      vm.params.uploadLogoImage = true;
      $modalInstance.close(vm.params);
    };

    vm.selectLogoImage = function () {
      vm.params.selectLogoImage = true;
      $modalInstance.close(vm.params);
    };

    vm.selectOfferImage1 = function () {
      vm.params.selectOfferImage1 = true;
      $modalInstance.close(vm.params);
    };
    vm.uploadOfferImage1 = function () {
      vm.params.uploadOfferImage1 = true;
      $modalInstance.close(vm.params);
    };

    vm.selectOfferImage2 = function () {
      vm.params.selectOfferImage2 = true;
      $modalInstance.close(vm.params);
    };
    vm.uploadOfferImage2 = function () {
      vm.params.uploadOfferImage2 = true;
      $modalInstance.close(vm.params);
    };

    vm.selectOfferImage3 = function () {
      vm.params.selectOfferImage3 = true;
      $modalInstance.close(vm.params);
    };
    vm.uploadOfferImage3 = function () {
      vm.params.uploadOfferImage3 = true;
      $modalInstance.close(vm.params);
    };

    vm.selectOfferImage4 = function () {
      vm.params.selectOfferImage4 = true;
      $modalInstance.close(vm.params);
    };
    vm.uploadOfferImage4 = function () {
      vm.params.uploadOfferImage4 = true;
      $modalInstance.close(vm.params);
    };
    vm.selectOfferImage5 = function () {
      vm.params.selectOfferImage5 = true;
      $modalInstance.close(vm.params);
    };
    vm.uploadOfferImage5 = function () {
      vm.params.uploadOfferImage5 = true;
      $modalInstance.close(vm.params);
    };

    vm.selectOfferImage6 = function () {
      vm.params.selectOfferImage6 = true;
      $modalInstance.close(vm.params);
    };
    vm.uploadOfferImage6 = function () {
      vm.params.uploadOfferImage6 = true;
      $modalInstance.close(vm.params);
    };

    vm.setSplashPageType(vm.params.splashType, true);

    vm.checkChanged = function () {
      vm.params.updateSplash = true;
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

    vm.ok = function () {
      if (vm.params.splashType.name.toLowerCase() !== 'social') {
        vm.params.openAuthList = [];
      }
      if (vm.params.bckgrndImgAdded && vm.params.splashType.id > 0) {
        if (vm.addlikebutton) {
          if (vm.defaultFbLikeUrl) {
            vm.params.splashDetails.customFbLikeUrl = vm.venue.customFbLikeUrl;
          }
        } else {
            vm.params.splashDetails.customFbLikeUrl = undefined;
        }
        vm.params.updateSplash = true;
        $modalInstance.close(vm.params);
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editeventnew.controller:EventnetworkmodalCtrl
   *
   * @description
   *
   */
  EventnetworkmodalCtrl.$inject = ["params", "$modalInstance", "Api"];
  angular
    .module('editeventnew')
    .controller('EventnetworkmodalCtrl', EventnetworkmodalCtrl);

  function EventnetworkmodalCtrl(params, $modalInstance, Api) {
    var vm = this;
    vm.ctrlName = 'EventnetworkmodalCtrl';
    vm.params = params;

    Api.getNetworkTypes(vm.params.venueid).then(function (response) {
      vm.networkTypes = response.data.networkTypes;
    }, function (err) {
      $log.info(err);
    });

    vm.setNetworkType = function (typeid) {
      vm.params.networktype = typeid;
      vm.categories = [];
      Api.getNetworkType(typeid, vm.params.venueid).then(function (response) {
        $log.info(response);
        if (response.data.networkType.networkStrategies.length > 0) {
          for (var i = 0; i < response.data.networkType.networkStrategies.length; i++) {
            vm.categories.push(response.data.networkType.networkStrategies[i].id);
          }
        }
      }, function (err) {
        $log.info(err);
      });
    };
    // categories setup
    function getCategories() {
      Api.getCategories(vm.provider).success(function (data) {
        vm.optCatList = data.categories;
        $log.info(vm.optCatList);
      }).error(function () {
        $log.info('get categories error');
      });
    }

    vm.getZones = function (id) {
      vm.zoneList = [];
      Api.getZones(id).success(function (data) {
        vm.zoneList = data.zones;
        var newList = [];
        for (var i = 0; i < vm.zoneList.length; i++) {
          for (var j = 0; j < vm.params.zones.length; j++) {
            if (vm.zoneList[i].id === vm.params.zones[j].zone) {
              newList.push(vm.zoneList[i]);
            }
          }
        }
        vm.params.zones = newList;
      }).error(function () {
        $log.info('get ap error');
      });
    };

    vm.getZones(vm.params.venueid);

    vm.openCalSt = function () {
      vm.status.openedSt = true;
    };

    vm.openCalEnd = function () {
      vm.status.openedEnd = true;
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

    vm.ok = function () {
      $modalInstance.close(vm.params);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editeventnew.controller:EndeventmodalCtrl
   *
   * @description
   *
   */
  EndeventmodalCtrl.$inject = ["params", "$modalInstance"];
  angular
    .module('editeventnew')
    .controller('EndeventmodalCtrl', EndeventmodalCtrl);

  function EndeventmodalCtrl(params, $modalInstance) {
    var vm = this;
    vm.ctrlName = 'EndeventmodalCtrl';
    vm.params = params;

    vm.ok = function () {
      var MS_PER_MINUTE = 60000;
      var minDate = new Date();
      vm.params.endtime = new Date(minDate - 30 * MS_PER_MINUTE);
      $modalInstance.close(vm.params);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editeventnew.controller:EditeventsummaryCtrl
   *
   * @description
   *
   */
  EditeventsummaryCtrl.$inject = ["params", "$modalInstance"];
  angular
    .module('editeventnew')
    .controller('EditeventsummaryCtrl', EditeventsummaryCtrl);

  function EditeventsummaryCtrl(params, $modalInstance) {
    var vm = this;
    vm.ctrlName = 'EditeventsummaryCtrl';
    vm.minDate = new Date();
    vm.params = params;

    vm.noend = (new Date(vm.params.endtime).getTime() === 253402300740905) ? true: false;

    if (vm.noend) {
      vm.params.endtime = new Date();
    }

    vm.dateOptions = {
      formatYear: 'yy',
      startingDay: 0
    };

    vm.dateFormat = 'dd-MMMM-yyyy';
    vm.hstep = 1;
    vm.mstep = 1;

    vm.status = {
      openedSt: false,
      openedEnd: false
    };

    vm.openCalSt = function () {
      vm.status.openedSt = true;
    };

    vm.openCalEnd = function () {
      vm.status.openedEnd = true;
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

    vm.ok = function () {
      if (vm.noend) {
        vm.params.endtime = new Date(253402300740905);
      }
      $modalInstance.close(vm.params);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editeventnew.controller:EditeventnewCtrl
   *
   * @description
   *
   */
  EditeventnewCtrl.$inject = ["$rootScope", "$state", "$stateParams", "Api", "Upload", "baseurl", "baseendpoint", "$timeout", "$modal", "$localStorage", "$log", "Auth", "Cloudservices"];
  angular
    .module('editeventnew')
    .controller('EditeventnewCtrl', EditeventnewCtrl);

  function EditeventnewCtrl($rootScope, $state, $stateParams, Api, Upload, baseurl, baseendpoint, $timeout, $modal, $localStorage, $log, Auth, Cloudservices) {
    var vm = this;

    vm.noend = false;

    vm.splashPageTypes = [];
    vm.categories = [];
    vm.zones = [];
    vm.splashType = 0;
    vm.spVideo = false;
    vm.spSocial = false;
    vm.spAmount = false;
    vm.spEmail = false;
    vm.spLink = false;
    vm.spRegister = false;
    vm.conference = false;
    vm.networkEditEnabled = false;
    vm.hasTwilio = false;

    // Important!!! remove this sesction once magnet county feature is complete

    vm.isMagnet = false;
    var magnetID = 9104885; //add real magnetID

    if (Auth.getAuthData().operator.organisation === magnetID) {
      vm.isMagnet = true;
    }

    Cloudservices.getCloudServiceByName($stateParams.venueid, 'twilio').then(function (resp) {
      if(resp.data.rowCount >= 1){
        vm.hasTwilio = true;
      }
    }, function (err) {
      console.log(err);
    });

    Api.getNetworkTypes($stateParams.venueid).then(function (response) {
      vm.networkTypes = response.data.networkTypes;
      console.log(response);
    }, function (err) {
      $log.info(err);
    });

    vm.setNetworkType = function (typeid) {
      vm.event.networktype = typeid;
      vm.categories = [];
      Api.getNetworkType(typeid, $stateParams.venueid).then(function (response) {
        $log.info(response);
        if (response.data.networkType.networkStrategies.length > 0) {
          console.log(response.data.networkType.networkStrategies.length);
          for (var i = 0; i < response.data.networkType.networkStrategies.length; i++) {
            vm.categories.push(response.data.networkType.networkStrategies[i].id);
          }
        }
      }, function (err) {
        $log.info(err);
      });
    };

    //get splash page types
    function getSplashPageTypes() {
      Api.getSplashPageTypes(vm.provider).success(function (data) {
        vm.splashPageTypes = data.splashTypes;
        getSocialLogins();
        getCategories();
        $log.info(vm.splashPageTypes);
        vm.setSplashPageType(vm.splashType, true);
      }).error(function () {
        $log.info('get splash page types error');
      });
    }
    vm.anonymousLogin = {};
    function getSocialLogins() {
      Api.getSocialLogins(vm.provider).success(function (data) {
        for (var i = 0; i < data.socialLoginTypes.length; i++) {
          if(data.socialLoginTypes[i].name.toLowerCase() === 'connect2fi_facebook') {
              data.socialLoginTypes.splice(i, 1);
          }

          if(data.socialLoginTypes[i].name.toLowerCase() === 'anonymous') {
            vm.anonymousLogin = data.socialLoginTypes[i];
            vm.anonymousLogin.on = false;
            for (var j = 0; j < vm.openAuthList.length; j++) {
              if (vm.openAuthList[j] === data.socialLoginTypes[i].id) {
                vm.openAuthList.splice(j, 1);
                vm.anonymousLogin.on = true;
              }
            }
            data.socialLoginTypes.splice(i, 1);
          }
        }
        vm.socialLogins = data.socialLoginTypes;
        $log.info('social');
        $log.info(vm.socialLogins);
      }).error(function () {
        $log.info('get social error');
      });
    }

    // categories setup
    function getCategories() {
      Api.getCategories(vm.provider).success(function (data) {
        vm.optCatList = data.categories;
        $log.info(vm.optCatList);
      }).error(function () {
        $log.info('get categories error');
      });
    }
    // end categories setup
    //set Splash Page Type
    vm.setSplashPageType = function (type, set) {
      console.log(type);
      console.log(set);
      vm.spVideo = false;
      vm.spSocial = false;
      vm.spAmount = false;
      vm.spEmail = false;
      vm.spLink = false;
      vm.spRegister = false;
      vm.conference = false;
      var name = type.name;
      switch (name) {
      case 'Video':
        vm.spVideo = true;
        break;
      case 'Social':
        vm.spSocial = true;
        break;
      case 'Email':
        vm.spEmail = true;
        break;
      case 'Link':
        vm.spLink = true;
        break;
      case 'Payment':
        vm.spAmount = true;
        break;
      case 'Register':
        vm.spRegister = true;
        break;
      case 'Conference':
          vm.conference = true;
          vm.spEmail = true;
          break;
      }
      if (set) {
        for (var splash in vm.splashPageTypes) {
          if (vm.splashPageTypes[splash].id === type.id) {
            vm.splashType = vm.splashPageTypes[splash];
          }
        }
        console.log(vm.splashType);
      }
    };

    vm.ctrlName = 'EditeventnewCtrl';
    // redirect to appropriate start page
    if ($state.current.name === 'admin.editevent') {
      $state.go('admin.editevent.editsetup');
    }

    // handles navigation away from incomplete form
    vm.allowStateChange = false;
    $rootScope.$on('$stateChangeStart',
      function (event, toState, toParams, fromState) {
        console.log(toState);
        console.log(vm.allowStateChange);
        if (vm.allowStateChange === true) {
          return;
        }
        if (fromState.name === 'admin.editevent.confirm') {
          if (toState.name === 'admin.editevent.editsetup' || toState.name === 'admin.editevent.editnetwork' || toState.name === 'admin.editevent.editsplash') {
            vm.allowStateChange = true;
            $state.go(toState.name);
          }
        }
        if (fromState.name === 'admin.editevent.editsetup' || fromState.name === 'admin.editevent.editnetwork' || fromState.name === 'admin.editevent.editsplash' || fromState.name === 'admin.editevent.confirm') {
          if (vm.allowStateChange === false) {
            console.log('called');
            event.preventDefault();
            var cancelEditModalInstance = $modal.open({
              templateUrl: 'editevent/views/canceleditevent.tpl.html',
              controller: 'CancelediteventCtrl as cancelEditModal'
            });

            cancelEditModalInstance.result.then(function () {
              vm.allowStateChange = true;
              $state.go(toState.name);
            }, function () {

            });
          } else {
            vm.allowStateChange = false;
            return;
          }
        } else {
          vm.allowStateChange = false;
          return;
        }
        vm.allowStateChange = false;
      });
    //end state changes

    vm.storage = $localStorage;

    // // manage social media

    vm.openAuthList = [];

    vm.addOpenAuth = function (value) {
      if (vm.openAuthList.indexOf(value) > -1) {
        vm.openAuthList.splice(vm.openAuthList.indexOf(value), 1);
      } else {
        vm.openAuthList.push(value);
      }
    };

    vm.priceSlider = {
      value: 2,
      options: {
        floor: 0,
        ceil: 50,
        translate: function (value) {
          if (value === 0) {
            return 'unlimited';
          }
          return value + ' MB';
        }
      }
    };

    // // manage steps

    vm.nextStep = function (data, state) {
      if ($state.current.name === 'admin.editevent.editsetup') {
        var timeError = false,
          now = new Date(),
          startTime = new Date(vm.event.startDate),
          endTime = new Date(vm.event.endDate),
          hour = 3600000,
          modalInstance;
        vm.stepOne = true;
        vm.stTimeInPast = false;
        vm.endTimeInPast = false;
        vm.endBeforeStart = false;
        vm.dateError = false;

        if (!vm.event.starttime || !vm.event.endtime) {
          timeError = true;
          vm.dateError = true;
        }

        if (endTime.getTime() < now.getTime()) {
          timeError = true;
          vm.endTimeInPast = true;
        }

        if (endTime.getTime() < startTime.getTime()) {
          timeError = true;
          vm.endBeforeStart = true;
        }

        if (!data.setUp.required && vm.eventImageAdded && !timeError) {
          if (endTime.getTime() - startTime.getTime() < hour) {
            modalInstance = $modal.open({
              templateUrl: 'eventcreation/views/eventtimemodal.tpl.html',
              controller: 'CancelmodalCtrl as cancelModal'
            });

            modalInstance.result.then(function () {
              vm.setupPassed = true;
              $state.go(state);
            }, function () {
              console.log('canceled');
            });
          } else {
            vm.allowStateChange = true;
            vm.setupPassed = true;
            $state.go(state);
          }
        }
      }

      if ($state.current.name === 'admin.editevent.editnetwork') {
        vm.stepTwo = true;
        if (!data.network.required && !data.network.pattern && vm.zones.length >= 1) {
          vm.netPassed = true;
          vm.allowStateChange = true;
          $state.go(state);
        }
      }

      if ($state.current.name === 'admin.editevent.editsplash') {
        vm.stepThree = true;
        var thisSplashType = vm.splashType.name;
        console.log(thisSplashType);
        if (!data.splash.required && vm.bckgrndImgAdded && vm.splashType.id > 0) {
          // console.log(vm.newEvent.videoUrl);
          // console.log(vm.splashType.length);

          if (thisSplashType === 'Social' && vm.openAuthList.length >= 1) {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else if (thisSplashType === 'Video' && vm.newEvent.splashDetails.videoUrl.length > 0) {
            console.log('called');
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else if (thisSplashType === 'Payment' && vm.newEvent.splashDetails.amount > 0 && vm.newEvent.splashDetails.amount > 0) {
            console.log('called');
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          }
        }
      }
      vm.allowStateChange = false;
    };

    // // manage event date/time

    vm.minDate = new Date();

    vm.dateOptions = {
      formatYear: 'yy',
      startingDay: 0
    };

    vm.dateFormat = 'dd-MMMM-yyyy';
    vm.hstep = 1;
    vm.mstep = 1;

    vm.status = {
      openedSt: false,
      openedEnd: false
    };

    vm.openCalSt = function () {
      vm.status.openedSt = true;
    };

    vm.openCalEnd = function () {
      vm.status.openedEnd = true;
    };

    //get images
    Api.getImages().success(function (data) {
      //multiple image lists required
      vm.eventImageList = data.images;
      for (var i = 0; i < vm.eventImageList.length; i++) {
        vm.eventImageList[i].checked = false;
      }
      vm.venueImageList = angular.copy(vm.eventImageList);
      vm.backgroundImageList = angular.copy(vm.eventImageList);
      $log.info(vm.eventImageList);
      $log.info(vm.venueImageList);
      $log.info(vm.backgroundImageList);

    });

    vm.uploadEventImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.eventImageAdded = true;
        vm.eventImage = image.imageUri;
        vm.eventImageID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectEventImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.eventImageAdded = true;
        vm.eventImage = thisImage.imageuri;
        vm.eventImageID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadLogoImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.venueLogoAdded = true;
        vm.venueLogo = image.imageUri;
        vm.venueLogoID = image.id;
        console.log('success');
        vm.updateSplash();
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectLogoImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.venueLogoAdded = true;
        vm.venueLogo = thisImage.imageuri;
        vm.venueLogoID = thisImage.id;
        vm.updateSplash();
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadBckgrndImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.bckgrndImgAdded = true;
        vm.bckgrndImg = image.imageUri;
        vm.bckgrndImgID = image.id;
        console.log('success');
        vm.updateSplash();
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectBckgrndImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.bckgrndImgAdded = true;
        vm.bckgrndImg = thisImage.imageuri;
        vm.bckgrndImgID = thisImage.id;
        vm.updateSplash();
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadOfferImage1 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage1Added = true;
        vm.offerImage1Uri = image.imageUri;
        vm.offerImage1ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage1 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage1Added = true;
        vm.offerImage1Uri = thisImage.imageuri;
        vm.offerImage1ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    /// offer 2

    vm.uploadOfferImage2 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage2Added = true;
        vm.offerImage2Uri = image.imageUri;
        vm.offerImage2ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage2 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage2Added = true;
        vm.offerImage2Uri = thisImage.imageuri;
        vm.offerImage2ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    ///offer 3

    vm.uploadOfferImage3 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage3Added = true;
        vm.offerImage3Uri = image.imageUri;
        vm.offerImage3ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage3 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage3Added = true;
        vm.offerImage3Uri = thisImage.imageuri;
        vm.offerImage3ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    /// offer 4

    vm.uploadOfferImage4 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage4Added = true;
        vm.offerImage4Uri = image.imageUri;
        vm.offerImage4ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage4 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage4Added = true;
        vm.offerImage4Uri = thisImage.imageuri;
        vm.offerImage4ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    //offer 5

    vm.uploadOfferImage5 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage5Added = true;
        vm.offerImage5Uri = image.imageUri;
        vm.offerImage5ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage5 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage5Added = true;
        vm.offerImage5Uri = thisImage.imageuri;
        vm.offerImage5ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    //offer 6

    vm.uploadOfferImage6 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage6Added = true;
        vm.offerImage6Uri = image.imageUri;
        vm.offerImage6ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage6 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage6Added = true;
        vm.offerImage6Uri = thisImage.imageuri;
        vm.offerImage6ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    // end upload Images


    //modify event details:
    vm.updateSummary = function () {
      var modalParams = {
        name: vm.event.name,
        description: vm.event.description,
        starttime: vm.event.starttime,
        endtime: vm.event.endtime,
        redirectionurl: vm.event.redirectionurl
      };
      var modalInstance = $modal.open({
        templateUrl: 'editeventnew/views/editeventsummary.tpl.html',
        controller: 'EditeventsummaryCtrl as editsummary',
        // size: 'lg',
        resolve: {
          params: function () {
            return modalParams;
          }
        }
      });

      modalInstance.result.then(function (data) {
        vm.event.name = data.name;
        vm.event.description = data.description;
        vm.event.starttime = data.starttime;
        vm.event.endtime = data.endtime;
        vm.event.redirectionurl = data.redirectionurl;
        vm.updateEvent();
      }, function (err) {
        console.log(err);
      });
    };

    vm.updateNetwork = function () {
      var modalParams = {
        ssidname: vm.event.ssidname,
        networkType: vm.event.networkType,
        categories: vm.categories,
        venueid: $stateParams.venueid,
        zones: vm.zones
      };
      var modalInstance = $modal.open({
        templateUrl: 'editeventnew/views/eventnetwork.tpl.html',
        controller: 'EventnetworkmodalCtrl as eventnetworkmodal',
        // size: 'lg',
        resolve: {
          params: function () {
            return modalParams;
          }
        }
      });

      modalInstance.result.then(function (data) {
        vm.event.ssidname = data.ssidname;
        vm.event.networkType = data.networkType;
        vm.event.categories = data.categories;
        vm.event.venue = data.venueid;
        vm.zones = data.zones;
        vm.updateEvent();
      }, function (err) {
        console.log(err);
      });
    };

    vm.updateSplash = function () {
      var modalParams = {
        splashType: vm.splashType,
        splashPageTypes: vm.splashPageTypes,
        bckgrndImgAdded: vm.bckgrndImgAdded,
        bckgrndImg: vm.bckgrndImg,
        bckgrndImgID: vm.bckgrndImgID,
        venueLogoAdded: vm.venueLogoAdded,
        venueLogo: vm.venueLogo,
        venueLogoID: vm.venueLogoID,
        networkType: vm.event.networkType,
        venueid: $stateParams.venueid,
        socialLogins: vm.socialLogins,
        openAuthList: vm.openAuthList,
        redirectionurl: vm.event.redirectionurl,
        splashDetails: vm.event.splashdetails,
        selectBckgrndImage: false,
        uploadBckgrndImage: false,
        selectLogoImage: false,
        uploadLogoImage: false,
        updateSplash: false,
        selectOfferImage1:false,
        selectOfferImage2:false,
        selectOfferImage3:false,
        selectOfferImage4:false,
        selectOfferImage5:false,
        selectOfferImage6:false,
        uploadOfferImage1:false,
        uploadOfferImage2:false,
        uploadOfferImage3:false,
        uploadOfferImage4:false,
        uploadOfferImage5:false,
        uploadOfferImage6:false,
        isMagnet: vm.isMagnet,
        hasTwilio: vm.hasTwilio,
        anonymousLogin: vm.anonymousLogin,
        marketingOn:vm.marketingOn,
        offerImage1ID:vm.offerImage1ID,
        offerImage2ID:vm.offerImage2ID,
        offerImage3ID:vm.offerImage3ID,
        offerImage4ID:vm.offerImage4ID,
        offerImage5ID:vm.offerImage5ID,
        offerImage6ID:vm.offerImage6ID,
        offerImage1Uri:vm.offerImage1Uri,

        offerImage1Link:vm.offerImage1Link,
        offerImage1Added:vm.offerImage1Added,

        offerImage2Uri:vm.offerImage2Uri,

        offerImage2Link:vm.offerImage2Link,
        offerImage2Added:vm.offerImage2Added,

        offerImage3Uri:vm.offerImage3Uri,

        offerImage3Link:vm.offerImage3Link,
        offerImage3Added:vm.offerImage3Added,

        offerImage4Uri:vm.offerImage4Uri,

        offerImage4Link:vm.offerImage4Link,
        offerImage4Added:vm.offerImage4Added,

        offerImage5Uri:vm.offerImage5Uri,

        offerImage5Link:vm.offerImage5Link,
        offerImage5Added:vm.offerImage5Added,

        offerImage6Uri:vm.offerImage6Uri,

        offerImage6Link:vm.offerImage6Link,
        offerImage6Added:vm.offerImage6Added,
        conference:vm.conference,
        conferencepass:vm.event.splashdetails.conferencepass,
      };
      console.log("modalParams");
      console.log(modalParams);
      var modalInstance = $modal.open({
        templateUrl: 'editeventnew/views/eventsplash.tpl.html',
        controller: 'EventsplashmodalCtrl as eventsplashmodal',
        size: 'lg',
        resolve: {
          params: function () {
            return modalParams;
          }
        }
      });

      modalInstance.result.then(function (data) {
        vm.splashType = data.splashType;
        vm.splashPageTypes = data.splashPageTypes;
        vm.bckgrndImgAdded = data.bckgrndImgAdded;
        vm.bckgrndImg = data.bckgrndImg;
        vm.bckgrndImgID = data.bckgrndImgID;
        vm.venueLogoAdded = data.venueLogoAdded;
        vm.venueLogo = data.venueLogo;
        vm.venueLogoID = data.venueLogoID;
        vm.event.networkType = data.networkType;
        vm.socialLogins = data.socialLogins;
        vm.openAuthList = data.openAuthList;
        vm.event.redirectionurl = data.redirectionurl;
        vm.event.splashdetails = data.splashDetails;
        vm.anonymousLogin = data.anonymousLogin;
        vm.offerImage1ID=data.offerImage1ID;
        vm.offerImage2ID=data.offerImage2ID;
        vm.offerImage3ID=data.offerImage3ID;
        vm.offerImage4ID=data.offerImage4ID;
        vm.offerImage5ID=data.offerImage5ID;
        vm.offerImage6ID=data.offerImage6ID;
        vm.offerImage1Uri=data.offerImage1Uri;

        vm.offerImage1Link=data.offerImage1Link;
        vm.offerImage1Added=data.offerImage1Added;

        vm.offerImage2Uri=data.offerImage2Uri;

        vm.offerImage2Link=data.offerImage2Link;
        vm.offerImage2Added=data.offerImage2Added;

        vm.offerImage3Uri=data.offerImage3Uri;

        vm.offerImage3Link=data.offerImage3Link;
        vm.offerImage3Added=data.offerImage3Added;

        vm.offerImage4Uri=data.offerImage4Uri;

        vm.offerImage4Link=data.offerImage4Link;
        vm.offerImage4Added=data.offerImage4Added;

        vm.offerImage5Uri=data.offerImage5Uri;

        vm.offerImage5Link=data.offerImage5Link;
        vm.offerImage5Added=data.offerImage5Added;

        vm.offerImage6Uri=data.offerImage6Uri;

        vm.offerImage6Link=data.offerImage6Link;
        vm.offerImage6Added=data.offerImage6Added

        vm.conference = data.conference;
        vm.conferencepass = data.conferencepass;

        if(data.marketingOn)
        {
          vm.event.splashdetails.marketingOn = true;
          vm.event.splashdetails.ads = [];
          vm.event.splashdetails.adLinks = [];
          vm.event.splashdetails.adURIs = [];
          if(data.offerImage1ID != '' )
          {
            vm.event.splashdetails.ads.push(data.offerImage1ID);
            vm.event.splashdetails.adURIs.push(data.offerImage1Uri);
            vm.event.splashdetails.adLinks.push(data.offerImage1Link);
          }
          if(data.offerImage2ID != '' )
          {
            vm.event.splashdetails.ads.push(data.offerImage2ID);
            vm.event.splashdetails.adURIs.push(data.offerImage2Uri);
            vm.event.splashdetails.adLinks.push(data.offerImage2Link);
          }
          if(data.offerImage3ID != '' )
          {
            vm.event.splashdetails.ads.push(data.offerImage3ID);
            vm.event.splashdetails.adURIs.push(data.offerImage3Uri);
            vm.event.splashdetails.adLinks.push(data.offerImage3Link);
          }
          if(data.offerImage4ID != '' )
          {
            vm.event.splashdetails.ads.push(data.offerImage4ID);
            vm.event.splashdetails.adURIs.push(data.offerImage4Uri);
            vm.event.splashdetails.adLinks.push(data.offerImage4Link);
          }
          if(data.offerImage5ID != '' )
          {
            vm.event.splashdetails.ads.push(data.offerImage5ID);
            vm.event.splashdetails.adURIs.push(data.offerImage5Uri);
            vm.event.splashdetails.adLinks.push(data.offerImage5Link);
          }
          if(data.offerImage6ID != '' )
          {
            vm.event.splashdetails.ads.push(data.offerImage6ID);
            vm.event.splashdetails.adURIs.push(data.offerImage6Uri);
            vm.event.splashdetails.adLinks.push(data.offerImage6Link);
          }
        }


        if (data.selectBckgrndImage) {
          vm.selectBckgrndImage();
        } else if (data.uploadBckgrndImage) {
          vm.uploadBckgrndImage();
        } else if (data.selectLogoImage) {
          vm.selectLogoImage();
        } else if (data.uploadLogoImage) {
          vm.uploadLogoImage();
        }
        else if (data.uploadOfferImage1) {
          vm.uploadOfferImage1();
        } else if (data.selectOfferImage1) {
          vm.selectOfferImage1();
        }
        else if (data.uploadOfferImage2) {
          vm.uploadOfferImage2();
        } else if (data.selectOfferImage2) {
          vm.selectOfferImage2();
        }
        else if (data.uploadOfferImage3) {
          vm.uploadOfferImage3();
        } else if (data.selectOfferImage3) {
          vm.selectOfferImage3();
        }
        else if (data.uploadOfferImage4) {
          vm.uploadOfferImage4();
        } else if (data.selectOfferImage4) {
          vm.selectOfferImage4();
        }
        else if (data.uploadOfferImage5) {
          vm.uploadOfferImage5();
        } else if (data.selectOfferImage5) {
          vm.selectOfferImage5();
        }
        else if (data.uploadOfferImage6) {
          vm.uploadOfferImage6();
        } else if (data.selectOfferImage6) {
          vm.selectOfferImage6();
        }

        else if (data.updateSplash) {
          vm.updateEvent();
        }
        //  vm.updateEvent();
      }, function (err) {
        console.log(err);
      });
    };

    vm.endEvent = function () {
      var modalParams = {
        endtime: vm.event.endtime
      };
      var modalInstance = $modal.open({
        templateUrl: 'editeventnew/views/endevent.tpl.html',
        controller: 'EndeventmodalCtrl as endevent',
        // size: 'lg',
        resolve: {
          params: function () {
            return modalParams;
          }
        }
      });

      modalInstance.result.then(function (data) {
        vm.event.endtime = data.endtime;
        vm.updateEvent();
      }, function (err) {
        console.log(err);
      });
    };

    Api.getEvent($stateParams.eventid, $stateParams.venueid).success(function (data) {
      console.log('event data');
      console.log(data);
      vm.event = data.event;
      vm.zones = data.event.zones;
      vm.noend = (new Date(data.event.endtime).getTime() === 253402300740905) ? true: false;
      console.log('event: ', vm.event);
      // console.log(vm.zones);
      // console.log('slider value' + vm.event.trafficlimitsta / 1000);
      vm.netPassed = true;
      vm.setupPassed = true;
      vm.splashPassed = true;
      vm.getZones(vm.event.venue);
      vm.priceSlider.value = vm.event.trafficlimitsta / 1000;
      var thisSplash;
      if (vm.event.splashdetails) {
        thisSplash = vm.event.splashdetails;
        if (thisSplash.amount) {
          thisSplash.amount = thisSplash.amount / 100;
        }
        if (thisSplash.period) {
          thisSplash.period = thisSplash.period / 3600000;
        }
        if (vm.event.splashdetails.videoUrl) {
          vm.event.splashdetails.videoUrl = vm.event.splashdetails.videoUrl;
        }
      }
      var splashType = {};
      splashType.id = vm.event.splashtypeid;
      splashType.name = vm.event.splashtypename;
      vm.splashType = splashType;
      vm.event.networkType = vm.event.networktype;
      //vm.event.splashDetails.videoUrl = vm.event.splashdetails.videoURL;
      if (vm.event.eventimageurl.length > 0) {
        vm.eventImageAdded = true;
        vm.eventImageID = vm.event.eventimage;
        vm.eventImage = vm.event.eventimageurl;
      }
      if (vm.event.backgroundimage.length > 0) {
        vm.bckgrndImgAdded = true;
        vm.bckgrndImgID = vm.event.splashpagebackgroundimage;
        vm.bckgrndImg = vm.event.backgroundimage;
      }
      if (vm.event.logoimage.length > 0) {
        vm.venueLogoAdded = true;
        vm.venueLogoID = vm.event.splashpagelogoimage;
        vm.venueLogo = vm.event.logoimage;
      }
//Marketing & Offers loaded here

      if( vm.event.splashdetails.marketingOn)
      {
        vm.marketingOn = vm.event.splashdetails.marketingOn;
          vm.offerImage1Added = false;
          vm.offerImage2Added = false;
          vm.offerImage3Added = false;
          vm.offerImage4Added = false;
          vm.offerImage5Added = false;
          vm.offerImage6Added = false;

        if(vm.event.splashdetails.ads)
        {
          if (vm.event.splashdetails.ads.length > 0)
          {
            vm.offerImage1ID = vm.event.splashdetails.ads[0];
            vm.offerImage1Uri = (vm.event.splashdetails.adURIs) ? vm.event.splashdetails.adURIs[0] : '';
            vm.offerImage1Link = (vm.event.splashdetails.adLinks) ? vm.event.splashdetails.adLinks[0] : '';
            vm.offerImage1Added = true;
          }
          if (vm.event.splashdetails.ads.length > 1)
          {
            vm.offerImage2ID = vm.event.splashdetails.ads[1];
            vm.offerImage2Uri = (vm.event.splashdetails.adURIs) ? vm.event.splashdetails.adURIs[1] : '';
            vm.offerImage2Link = (vm.event.splashdetails.adLinks)  ? vm.event.splashdetails.adLinks[1] : '';
            vm.offerImage2Added = true;
          }
          if (vm.event.splashdetails.ads.length > 2)
          {
            vm.offerImage3ID = vm.event.splashdetails.ads[2];
            vm.offerImage3Uri = (vm.event.splashdetails.adURIs) ? vm.event.splashdetails.adURIs[2] : '';
            vm.offerImage3Link = (vm.event.splashdetails.adLinks) ? vm.event.splashdetails.adLinks[2] : '';
            vm.offerImage3Added = true;
          }
          if (vm.event.splashdetails.ads.length > 3)
          {
            vm.offerImage4ID = vm.event.splashdetails.ads[3];
            vm.offerImage4Uri = (vm.event.splashdetails.adURIs) ? vm.event.splashdetails.adURIs[3] : '';
            vm.offerImage4Link = (vm.event.splashdetails.adLinks) ? vm.event.splashdetails.adLinks[3] : '';
            vm.offerImage4Added = true;
          }
          if (vm.event.splashdetails.ads.length > 4)
          {
            vm.offerImage5ID = vm.event.splashdetails.ads[4];
            vm.offerImage5Uri = (vm.event.splashdetails.adURIs) ? vm.event.splashdetails.adURIs[4] : '';
            vm.offerImage5Link = (vm.event.splashdetails.adLinks) ? vm.event.splashdetails.adLinks[4] : '';
            vm.offerImage5Added = true;
          }
          if (vm.event.splashdetails.ads.length > 5)
          {
            vm.offerImage6ID = vm.event.splashdetails.ads[5];
            vm.offerImage6Uri = (vm.event.splashdetails.adURIs) ? vm.event.splashdetails.adURIs[5] : '';
            vm.offerImage6Link = (vm.event.splashdetails.adLinks) ? vm.event.splashdetails.adLinks[5] : '';
            vm.offerImage6Added = true;
          }
        }

      }

      for (var i = 0; i < vm.event.socialLoginTypes.length; i++) {
        if (vm.event.socialLoginTypes[i].id === vm.anonymousLogin.id) {
          vm.anonymousLogin.on = true;
        }
        vm.openAuthList.push(vm.event.socialLoginTypes[i].id);
      }
      for (var j = 0; j < vm.event.categories.length; j++) {
        vm.categories.push(vm.event.categories[j].id);
      }
      if (Auth.getAuthData()) {
        vm.event.org = Auth.getAuthData().operator.organisation;
      }
      Api.getInfrastructures(vm.event.venue).then(function (res) {
        var infra = res.data.infrastructures[0];
        vm.provider = JSON.parse(infra.provider);
        console.log(infra);
        getSplashPageTypes();
      }, function (err) {
        console.log(err);
      });
    });

    //accesspoint setup
    vm.getZones = function (id) {
      vm.zoneList = [];
      Api.getZones(id).success(function (data) {
        vm.zoneList = data.zones;
        var newList = [];
        for (var i = 0; i < vm.zoneList.length; i++) {
          for (var j = 0; j < vm.event.zones.length; j++) {
            if (vm.zoneList[i].id === vm.event.zones[j].zone) {
              newList.push(vm.zoneList[i]);
            }
          }
        }
        vm.event.zones = newList;
      }).error(function () {
        $log.info('get ap error');
      });
    };

    vm.cancel = function () {
      $state.go('admin.events');
    };

    vm.updateEvent = function () {
      var thisSplash = vm.event.splashdetails;
      if (thisSplash) {
        if (thisSplash.amount) {
          thisSplash.amount = thisSplash.amount * 100;
        }
        if (thisSplash.period) {
          thisSplash.period = thisSplash.period * 3600000;
        }
      }

      if(vm.marketingOn)
      {
        thisSplash.marketingOn = true;
        thisSplash.ads = [];
        thisSplash.adLinks = [];
        thisSplash.adURIs = [];
        if(vm.offerImage1ID != '' )
        {
          thisSplash.ads.push(vm.offerImage1ID);
          thisSplash.adURIs.push(vm.offerImage1Uri);
          thisSplash.adLinks.push(vm.offerImage1Link);
        }
        if(vm.offerImage2ID != '' )
        {
          thisSplash.ads.push(vm.offerImage2ID);
          thisSplash.adURIs.push(vm.offerImage2Uri);
          thisSplash.adLinks.push(vm.offerImage2Link);
        }
        if(vm.offerImage3ID != '' )
        {
          thisSplash.ads.push(vm.offerImage3ID);
          thisSplash.adURIs.push(vm.offerImage3Uri);
          thisSplash.adLinks.push(vm.offerImage3Link);
        }
        if(vm.offerImage4ID != '' )
        {
          thisSplash.ads.push(vm.offerImage4ID);
          thisSplash.adURIs.push(vm.offerImage4Uri);
          thisSplash.adLinks.push(vm.offerImage4Link);
        }
        if(vm.offerImage5ID != '' )
        {
          thisSplash.ads.push(vm.offerImage5ID);
          thisSplash.adURIs.push(vm.offerImage5Uri);
          thisSplash.adLinks.push(vm.offerImage5Link);
        }
        if(vm.offerImage6ID != '' )
        {
          thisSplash.ads.push(vm.offerImage6ID);
          thisSplash.adURIs.push(vm.offerImage6Uri);
          thisSplash.adLinks.push(vm.offerImage6Link);
        }
      }


      if (vm.anonymousLogin.on) {
        vm.addOpenAuth(vm.anonymousLogin.id);
      } else {
        for (var j = 0; j < vm.openAuthList.length; j++) {
          if (vm.openAuthList[j] === vm.anonymousLogin.id) {
            vm.openAuthList.splice(j, 1);
          }
        }
      }
      var thisBandwidth = vm.priceSlider.value * 1000,
        thisEvent = {
          orgId: vm.event.org,
          venueId: vm.event.venue,
          name: vm.event.name,
          description: vm.event.description,
          eventimage: vm.eventImageID,
          ssidname: vm.event.ssidname,
          starttime: vm.event.starttime,
          endtime: vm.event.endtime,
          zones: vm.zones,
          details: vm.event.details,
          categories: vm.categories,
          trafficlimitsta: thisBandwidth,
          splashpagebackgroundimage: vm.bckgrndImgID,
          splashpagelogoimage: vm.venueLogoID,
          sociallogintypes: vm.openAuthList,
          redirectionurl: vm.event.redirectionurl,
          eventstatus: vm.event.eventstatus,
          splashDetails: thisSplash,
          networkTypeId: vm.event.networkType,
          splashTypeId: vm.splashType.id,
          splashpage: vm.event.splashpage
        };

      if (vm.event.description === null) {
        thisEvent.description = '';
      }

      console.log('thisEvent !!!');
      console.log(thisEvent);

      Api.updateEvent(vm.event.id, vm.event.venue, thisEvent).success(function () {
        vm.storage.alerts.push({
          messageType: 'event',
          message: 'Event Update'
        });
        vm.allowStateChange = true;
        $state.go('admin.events');
      }).error(function (error) {
        console.log(error);
      });
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editinfrastructure
   * @description
   *
   */
  angular
    .module('editinfrastructure', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editinfrastructure.controller:EditinfrastructureCtrl
   *
   * @description
   *
   */
  EditinfrastructureCtrl.$inject = ["Api", "$stateParams", "$state", "$log"];
  angular
    .module('editinfrastructure')
    .controller('EditinfrastructureCtrl', EditinfrastructureCtrl);

  function EditinfrastructureCtrl(Api, $stateParams, $state, $log) {
    var vm = this;

    vm.infrastructure = {};
    vm.submitted = false;

    vm.providerTypes = [];
    vm.selectedProvider = '';

    vm.setProvider = function (provider) {
      console.log(provider);
      vm.providerSelected = true;
      vm.selectedProvider = provider;
      if(provider === 1){
        vm.infrastructure.controlleraddress = 'Xirrus';
        console.log(vm.infrastructure.controlleraddress);
      } else {
        console.log(vm.infrastructure.controlleraddress);
      }
    };

    Api.getProviders().then(function(res){
      console.log(res);
      vm.providerTypes = res.data.providers;
    }, function(err){
      console.log(err);
    });

    Api.getInfrastructure($stateParams.infraid, $stateParams.venueid).then(function (res) {
      $log.info(res);
      vm.infrastructure = res.data.infrastructure;
      vm.setProvider(vm.infrastructure.provider);
    }, function (err) {
      $log.error(err);
    });

    vm.edit = function (isValid) {
      var thisInfrastructure = vm.infrastructure;
      vm.submitted = true;
      if (isValid) {
        Api.updateInfrastructure($stateParams.infraid, $stateParams.venueid, thisInfrastructure).then(function (res) {
          $log.info(res);
          $state.go('admin.managenetwork', {
            'venueid': $stateParams.venueid
          });
        }, function (err) {
          $log.error(err);
        });
      }
    };

    vm.cancel = function(){
      $state.go('admin.managenetwork', {
        'venueid': $stateParams.venueid
      });
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editevent
   * @description
   *
   */
  angular
    .module('editevent', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editevent.controller:EditeventCtrl
   *
   * @description
   *
   */
  EditeventCtrl.$inject = ["$rootScope", "$state", "$stateParams", "Api", "Upload", "baseurl", "baseendpoint", "$timeout", "$modal", "$localStorage", "$log", "Auth"];
  angular
    .module('editevent')
    .controller('EditeventCtrl', EditeventCtrl);

  function EditeventCtrl($rootScope, $state, $stateParams, Api, Upload, baseurl, baseendpoint, $timeout, $modal, $localStorage, $log, Auth) {
    var vm = this;

    vm.splashPageTypes = [];
    vm.categories = [];
    vm.zones = [];
    vm.splashType = 0;
    vm.spVideo = false;
    vm.spSocial = false;
    vm.spAmount = false;
    vm.spEmail = false;
    vm.spLink = false;
    vm.spRegister = false;

    Api.getNetworkTypes($stateParams.venueid).then(function (response) {
      vm.networkTypes = response.data.networkTypes;
      console.log(response);
    }, function (err) {
      $log.info(err);
    });

    vm.setNetworkType = function (typeid) {
      vm.event.networktype = typeid;
      vm.categories = [];
      Api.getNetworkType(typeid, $stateParams.venueid).then(function (response) {
        $log.info(response);
        if (response.data.networkType.networkStrategies.length > 0) {
          console.log(response.data.networkType.networkStrategies.length);
          for (var i = 0; i < response.data.networkType.networkStrategies.length; i++) {
            vm.categories.push(response.data.networkType.networkStrategies[i].id);
          }
        }
      }, function (err) {
        $log.info(err);
      });
    };

    //get splash page types
    function getSplashPageTypes() {
      Api.getSplashPageTypes(vm.provider).success(function (data) {
        vm.splashPageTypes = data.splashTypes;
        getSocialLogins();
        getCategories();
        $log.info(vm.splashPageTypes);
        vm.setSplashPageType(vm.splashType, true);
      }).error(function () {
        $log.info('get splash page types error');
      });
    }

    function getSocialLogins() {
      Api.getSocialLogins(vm.provider).success(function (data) {
        vm.socialLogins = data.socialLoginTypes;
        $log.info('social');
        $log.info(vm.socialLogins);
      }).error(function () {
        $log.info('get social error');
      });
    }

    // categories setup
    function getCategories() {
      Api.getCategories(vm.provider).success(function (data) {
        vm.optCatList = data.categories;
        $log.info(vm.optCatList);
      }).error(function () {
        $log.info('get categories error');
      });
    }
    // end categories setup
    //set Splash Page Type
    vm.setSplashPageType = function (type, set) {
      console.log(type);
      console.log(set);
      vm.spVideo = false;
      vm.spSocial = false;
      vm.spAmount = false;
      vm.spEmail = false;
      vm.spLink = false;
      vm.spRegister = false;
      var name = type.name;
      switch (name) {
      case 'Video':
        vm.spVideo = true;
        break;
      case 'Social':
        vm.spSocial = true;
        break;
      case 'Email':
        vm.spEmail = true;
        break;
      case 'Link':
        vm.spLink = true;
        break;
      case 'Payment':
        vm.spAmount = true;
        break;
      case 'Register':
        vm.spRegister = true;
        break;
      }
      if (set) {
        for (var splash in vm.splashPageTypes) {
          if (vm.splashPageTypes[splash].id === type.id) {
            vm.splashType = vm.splashPageTypes[splash];
          }
        }
        console.log(vm.splashType);
      }
    };

    vm.ctrlName = 'EditeventCtrl';
    // redirect to appropriate start page
    if ($state.current.name === 'admin.editevent') {
      $state.go('admin.editevent.editsetup');
    }

    // handles navigation away from incomplete form
    vm.allowStateChange = false;
    $rootScope.$on('$stateChangeStart',
      function (event, toState, toParams, fromState) {
        console.log(toState);
        console.log(vm.allowStateChange);
        if (vm.allowStateChange === true) {
          return;
        }
        if (fromState.name === 'admin.editevent.confirm') {
          if (toState.name === 'admin.editevent.editsetup' || toState.name === 'admin.editevent.editnetwork' || toState.name === 'admin.editevent.editsplash') {
            vm.allowStateChange = true;
            $state.go(toState.name);
          }
        }
        if (fromState.name === 'admin.editevent.editsetup' || fromState.name === 'admin.editevent.editnetwork' || fromState.name === 'admin.editevent.editsplash' || fromState.name === 'admin.editevent.confirm') {
          if (vm.allowStateChange === false) {
            console.log('called');
            event.preventDefault();
            var cancelEditModalInstance = $modal.open({
              templateUrl: 'editevent/views/canceleditevent.tpl.html',
              controller: 'CancelediteventCtrl as cancelEditModal'
            });

            cancelEditModalInstance.result.then(function () {
              vm.allowStateChange = true;
              $state.go(toState.name);
            }, function () {

            });
          } else {
            vm.allowStateChange = false;
            return;
          }
        } else {
          vm.allowStateChange = false;
          return;
        }
        vm.allowStateChange = false;
      });
    //end state changes

    vm.storage = $localStorage;

    // // manage social media

    vm.openAuthList = [];

    vm.addOpenAuth = function (value) {
      if (vm.openAuthList.indexOf(value) > -1) {
        vm.openAuthList.splice(vm.openAuthList.indexOf(value), 1);
      } else {
        vm.openAuthList.push(value);
      }
    };

    vm.priceSlider = {
      value: 2,
      options: {
        floor: 0,
        ceil: 50,
        translate: function (value) {
          if (value === 0) {
            return 'unlimited';
          }
          return value + ' MB';
        }
      }
    };

    // // manage steps

    vm.nextStep = function (data, state) {
      if ($state.current.name === 'admin.editevent.editsetup') {
        var timeError = false,
          now = new Date(),
          startTime = new Date(vm.event.startDate),
          endTime = new Date(vm.event.endDate),
          hour = 3600000,
          modalInstance;
        vm.stepOne = true;
        vm.stTimeInPast = false;
        vm.endTimeInPast = false;
        vm.endBeforeStart = false;
        vm.dateError = false;

        if (!vm.event.starttime || !vm.event.endtime) {
          timeError = true;
          vm.dateError = true;
        }

        if (endTime.getTime() < now.getTime()) {
          timeError = true;
          vm.endTimeInPast = true;
        }

        if (endTime.getTime() < startTime.getTime()) {
          timeError = true;
          vm.endBeforeStart = true;
        }

        if (!data.setUp.required && vm.eventImageAdded && !timeError) {
          if (endTime.getTime() - startTime.getTime() < hour) {
            modalInstance = $modal.open({
              templateUrl: 'eventcreation/views/eventtimemodal.tpl.html',
              controller: 'CancelmodalCtrl as cancelModal'
            });

            modalInstance.result.then(function () {
              vm.setupPassed = true;
              $state.go(state);
            }, function () {
              console.log('canceled');
            });
          } else {
            vm.allowStateChange = true;
            vm.setupPassed = true;
            $state.go(state);
          }
        }
      }

      if ($state.current.name === 'admin.editevent.editnetwork') {
        vm.stepTwo = true;
        if (!data.network.required && !data.network.pattern && vm.zones.length >= 1) {
          vm.netPassed = true;
          vm.allowStateChange = true;
          $state.go(state);
        }
      }

      if ($state.current.name === 'admin.editevent.editsplash') {
        vm.stepThree = true;
        var thisSplashType = vm.splashType.name;
        console.log(thisSplashType);
        if (!data.splash.required && vm.bckgrndImgAdded && vm.splashType.id > 0) {
          // console.log(vm.newEvent.videoUrl);
          // console.log(vm.splashType.length);

          if (thisSplashType === 'Social' && vm.openAuthList.length >= 1) {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else if (thisSplashType === 'Video' && vm.newEvent.splashDetails.videoUrl.length > 0) {
            console.log('called');
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else if (thisSplashType === 'Payment' && vm.newEvent.splashDetails.amount > 0 && vm.newEvent.splashDetails.amount > 0) {
            console.log('called');
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          }
        }
      }
      vm.allowStateChange = false;
    };

    // // manage event date/time

    vm.minDate = new Date();

    vm.dateOptions = {
      formatYear: 'yy',
      startingDay: 0
    };

    vm.dateFormat = 'dd-MMMM-yyyy';
    vm.hstep = 1;
    vm.mstep = 1;

    vm.status = {
      openedSt: false,
      openedEnd: false
    };

    vm.openCalSt = function () {
      vm.status.openedSt = true;
    };

    vm.openCalEnd = function () {
      vm.status.openedEnd = true;
    };

    //get images
    Api.getImages().success(function (data) {
      //multiple image lists required
      vm.eventImageList = data.images;
      for (var i = 0; i < vm.eventImageList.length; i++) {
        vm.eventImageList[i].checked = false;
      }
      vm.venueImageList = angular.copy(vm.eventImageList);
      vm.backgroundImageList = angular.copy(vm.eventImageList);
      $log.info(vm.eventImageList);
      $log.info(vm.venueImageList);
      $log.info(vm.backgroundImageList);

    });

    vm.uploadEventImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.eventImageAdded = true;
        vm.eventImage = image.imageUri;
        vm.eventImageID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectEventImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.eventImageAdded = true;
        vm.eventImage = thisImage.imageuri;
        vm.eventImageID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadLogoImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.venueLogoAdded = true;
        vm.venueLogo = image.imageUri;
        vm.venueLogoID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectLogoImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.venueLogoAdded = true;
        vm.venueLogo = thisImage.imageuri;
        vm.venueLogoID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadBckgrndImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.bckgrndImgAdded = true;
        vm.bckgrndImg = image.imageUri;
        vm.bckgrndImgID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectBckgrndImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.bckgrndImgAdded = true;
        vm.bckgrndImg = thisImage.imageuri;
        vm.bckgrndImgID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    Api.getEvent($stateParams.eventid, $stateParams.venueid).success(function (data) {
      vm.event = data.event;
      vm.zones = data.event.zones;
      console.log(vm.event);
      // console.log(vm.zones);
      // console.log('slider value' + vm.event.trafficlimitsta / 1000);
      vm.netPassed = true;
      vm.setupPassed = true;
      vm.splashPassed = true;
      vm.getZones(vm.event.venue);
      vm.priceSlider.value = vm.event.trafficlimitsta / 1000;
      var thisSplash;
      if (vm.event.splashdetails) {
        thisSplash = vm.event.splashdetails;
        if (thisSplash.amount) {
          thisSplash.amount = thisSplash.amount / 100;
        }
        if (thisSplash.period) {
          thisSplash.period = thisSplash.period / 3600000;
        }
      }
      var splashType = {};
      splashType.id = vm.event.splashtypeid;
      splashType.name = vm.event.splashtypename;
      vm.splashType = splashType;
      vm.event.networkType = vm.event.networktype;
      //vm.event.splashDetails.videoUrl = vm.event.splashdetails.videoURL;
      if (vm.event.eventimageurl.length > 0) {
        vm.eventImageAdded = true;
        vm.eventImageID = vm.event.eventimage;
        vm.eventImage = vm.event.eventimageurl;
      }
      if (vm.event.backgroundimage.length > 0) {
        vm.bckgrndImgAdded = true;
        vm.bckgrndImgID = vm.event.splashpagebackgroundimage;
        vm.bckgrndImg = vm.event.backgroundimage;
      }
      if (vm.event.logoimage.length > 0) {
        vm.venueLogoAdded = true;
        vm.venueLogoID = vm.event.splashpagelogoimage;
        vm.venueLogo = vm.event.logoimage;
      }
      for (var i = 0; i < vm.event.socialLoginTypes.length; i++) {
        vm.openAuthList.push(vm.event.socialLoginTypes[i].id);
      }
      for (var j = 0; j < vm.event.categories.length; j++) {
        vm.categories.push(vm.event.categories[j].id);
      }
      if (Auth.getAuthData()) {
        vm.event.org = Auth.getAuthData().operator.organisation;
      }
      Api.getInfrastructures(vm.event.venue).then(function (res) {
        var infra = res.data.infrastructures[0];
        vm.provider = JSON.parse(infra.provider);
        getSplashPageTypes();
      }, function (err) {
        console.log(err);
      });
    });

    //accesspoint setup
    vm.getZones = function (id) {
      vm.zoneList = [];
      Api.getZones(id).success(function (data) {
        vm.zoneList = data.zones;
        var newList = [];
        for (var i = 0; i < vm.zoneList.length; i++) {
          for (var j = 0; j < vm.event.zones.length; j++) {
            if (vm.zoneList[i].id === vm.event.zones[j].zone) {
              newList.push(vm.zoneList[i]);
            }
          }
        }
        vm.event.zones = newList;
      }).error(function () {
        $log.info('get ap error');
      });
    };

    vm.cancel = function () {
      $state.go('admin.events');
    };

    vm.updateEvent = function () {
      var thisSplash = vm.event.splashDetails;
      if (thisSplash) {
        if (thisSplash.amount) {
          thisSplash.amount = thisSplash.amount * 100;
        }
        if (thisSplash.period) {
          thisSplash.period = thisSplash.period * 3600000;
        }
      }
      var thisBandwidth = vm.priceSlider.value * 1000,
        thisEvent = {
          orgId: vm.event.org,
          venueId: vm.event.venue,
          name: vm.event.name,
          description: vm.event.description,
          eventimage: vm.eventImageID,
          ssidname: vm.event.ssidname,
          starttime: vm.event.starttime,
          endtime: vm.event.endtime,
          zones: vm.zones,
          categories: vm.categories,
          trafficlimitsta: thisBandwidth,
          splashpagebackgroundimage: vm.bckgrndImgID,
          splashpagelogoimage: vm.venueLogoID,
          sociallogintypes: vm.openAuthList,
          redirectionurl: vm.event.redirectionurl,
          eventstatus: vm.event.eventstatus,
          splashDetails: thisSplash,
          networkTypeId: vm.event.networkType,
          splashTypeId: vm.splashType.id,
          splashpage: vm.event.splashpage
        };

      if (vm.event.description === null) {
        thisEvent.description = '';
      }

      console.log(thisEvent);

      Api.updateEvent(vm.event.id, vm.event.venue, thisEvent).success(function () {
        vm.storage.alerts.push({
          messageType: 'event',
          message: 'Event Update'
        });
        vm.allowStateChange = true;
        $state.go('admin.events');
      }).error(function (error) {
        console.log(error);
      });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editevent.controller:CancelediteventCtrl
   *
   * @description
   *
   */
  CancelediteventCtrl.$inject = ["$modalInstance"];
  angular
    .module('editevent')
    .controller('CancelediteventCtrl', CancelediteventCtrl);

  function CancelediteventCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'CancelediteventCtrl';
    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editap
   * @description
   *
   */
  angular
    .module('editap', [
      'ui.router'
    ]);
}());

(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name editap.controller:EditapCtrl
   *
   * @description
   *
   */
  EditapCtrl.$inject = ["Api", "$stateParams", "$state", "$log", "Auth"];
  angular
    .module('editap')
    .controller('EditapCtrl', EditapCtrl);

  function EditapCtrl(Api, $stateParams, $state, $log, Auth) {
    var vm = this;
    vm.ctrlName = 'EditapCtrl';

    var apid = $stateParams.apid;
    var venueid = $stateParams.venueid;
    var orgid = Auth.getAuthData().operator.organisation;

    vm.accesspoint = {};


    Api.getAccessPoint(venueid, apid).then(function(resp) {
      $log.info(resp);
      vm.accesspoint.provider = resp.data.apoint.provider;
      vm.accesspoint.hwAddr = resp.data.apoint.hwaddr;
      vm.accesspoint.name = resp.data.apoint.name;
      vm.accesspoint.ipAddress = resp.data.apoint.ipaddress;
      vm.accesspoint.sshUsername = resp.data.apoint.sshusername;
      vm.accesspoint.sshPassword = resp.data.apoint.sshpassword;
      $log.info(vm.accesspoint);
    }, function(err) {
      $log.info(err);
    });

    vm.updateAccesspoint = function(isValid) {
      vm.submitted = true;
      if (isValid) {
        vm.accesspoint.orgId = orgid;
        Api.updateAccesspoint(venueid, apid, vm.accesspoint).then(function(resp) {
          $log.info(resp);
          $state.go('admin.managenetwork', {
            venueid: venueid
          });
        }, function(err) {
          $log.info(err);
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name editclservice
   * @description
   *
   */
  angular
    .module('editclservice', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editclservice.controller:EditclserviceCtrl
   *
   * @description
   *
   */
  EditclserviceCtrl.$inject = ["$state", "$log", "$scope", "$stateParams", "Cloudservices", "Cloudservicetype", "Api"];
  angular
    .module('editclservice')
    .controller('EditclserviceCtrl', EditclserviceCtrl);

  function EditclserviceCtrl($state, $log, $scope, $stateParams, Cloudservices, Cloudservicetype, Api) {
    var vm = this;
    vm.ctrlName = 'EditclserviceCtrl';

    var venueid = $stateParams.venueid;
    var cloudservicesid = $stateParams.cloudservicesid;

    // errors and messages
    vm.updatedApi = false;
    vm.errorMessage = false;
    vm.apiMessage = 'API Updated';
    vm.error = '';

    // Objects
    vm.venueid = $stateParams.venueid;
    vm.cloudserviceDetails = {};
    vm.lists = [];

    Cloudservices.getCloudServiceById(venueid, cloudservicesid).success(function (data) {
      vm.cloudserviceDetails = data.cloudService;
      console.log(vm.cloudserviceDetails);
      if(vm.cloudserviceDetails.type === 'MailChimp'){
        vm.getLists();
      }
    }).error(function (err) {
      console.log('Error: ', err);
    });

    vm.getLists = function(){
      vm.lists = [];
      Api.getLists(vm.cloudserviceDetails.apikey).then(function(resp) {
        for(var i=0; i<resp.data.lists.length; i++){
          vm.lists.push({
            id: resp.data.lists[i].id,
            name: resp.data.lists[i].name});
        }
      }, function(err) {
        vm.errorMessage = true;
        vm.error = 'Cloud Services could not find valid list, check your api key is correct. If this message persists please submit a support ticket.';
        console.log('Error: ', err);
      });
    };

    vm.updateApiKey = function() {
      if (vm.cloudserviceDetails.apikey) {
        vm.updatedApi = true;
        vm.getLists();
      } else {
        vm.cloudserviceDetails.listidentifier = '';
        vm.lists = [];
      }
    };

    vm.udpateCloudService = function (isValid, cloudService) {
      vm.submitted = true;
      var thisCldSrv = cloudService;
      if (cloudService.phonenumber) {
        thisCldSrv.phonenumber = cloudService.phonenumber.replace('+', '');
      }
      console.log(thisCldSrv);
      if (isValid) {
        Cloudservices.udpateCloudService($stateParams.venueid, $stateParams.cloudservicesid, thisCldSrv).then(function (resp) {
          console.log(resp);
          $state.go('admin.cloudservices', {
            'venueid': $stateParams.venueid
          });
        }, function (err) {
          console.log(err);
          vm.errorMessage = true;
          vm.error = 'Cloud Services could not update, check you have completed the required fields. If this message persists please submit a support ticket.';
        });
      }
    };

    vm.createNewList = function () {
      $state.go('admin.newlist', {
        apiKey: vm.cloudserviceDetails.apikey,
        venueid: venueid
      });
    };

    vm.dismiss = function () {
      vm.updatedApi = false;
      vm.errorMessage = false;
      vm.error = '';
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name cloudservices
   * @description
   *
   */
  angular
    .module('cloudservices', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name cloudservicetypes.controller:CloudtypedeletemodalCtrl
   *
   * @description
   *
   */
  ClservicedeletemodalCtrl.$inject = ["$modalInstance"];
  angular
    .module('cloudservices')
    .controller('ClservicedeletemodalCtrl', ClservicedeletemodalCtrl);

  function ClservicedeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'CloudtypedeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name cloudservices.controller:CloudservicesCtrl
   *
   * @description
   *
   */
  CloudservicesCtrl.$inject = ["$state", "Cloudservices", "$modal", "$stateParams"];
  angular
    .module('cloudservices')
    .controller('CloudservicesCtrl', CloudservicesCtrl);

  function CloudservicesCtrl($state, Cloudservices, $modal, $stateParams) {
    var vm = this;
    vm.ctrlName = 'CloudservicesCtrl';

    vm.cloudservicesLoaded = false;
    vm.cloudservicesLoadedError = false;
    vm.cloudservices = [];
    vm.ErrorMessage = false;
    vm.Message = [];
    vm.venueid = $stateParams.venueid;

    Cloudservices.getCloudServices($stateParams.venueid).success(function (data) {
      vm.cloudservices = data.cloudServices;
      vm.cloudservicesLoaded = true;
    }).error(function (err) {
      vm.cloudservicesLoadedError = true;
      vm.cloudservicesLoaded = true;
      console.log('Error: ', err);
    });

    function reloadAll() {
      Cloudservices.getCloudServices($stateParams.venueid).success(function (data) {
        vm.cloudservices = data.cloudServices;
        vm.cloudservicesLoaded = true;
      }).error(function (err) {
        vm.cloudservicesLoadedError = true;
        vm.cloudservicesLoaded = true;
        console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (cloudservicesid) {
      var modalInstance = $modal.open({
        templateUrl: 'cloudservices/views/clservicedeletemodal.tpl.html',
        controller: 'ClservicedeletemodalCtrl as clservicedeleteModal'
      });

      modalInstance.result.then(function () {

        Cloudservices.deleteCloudService(vm.venueid, cloudservicesid).success(function () {
          reloadAll(vm.venueid, cloudservicesid);
        }).error(function (err) {
          console.log('Delete Error: ', err);
          if (err.message.code === '23503') {
            console.log('Error Code: ', err.message.code);
            vm.ErrorMessage = true;
            vm.Message = 'Error Deleting Cloud Service!';
          } else {
            vm.ErrorMessage = true;
            vm.Message = err.message;
          }
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
    
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name duplicateevent
   * @description
   *
   */
  angular
    .module('duplicateevent', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name duplicateevent.controller:DuplicatessidchecksCtrl
   *
   * @description
   *
   */
  DuplicatessidchecksCtrl.$inject = ["$modalInstance", "message"];
  angular
    .module('duplicateevent')
    .controller('DuplicatessidchecksCtrl', DuplicatessidchecksCtrl);

  function DuplicatessidchecksCtrl($modalInstance, message) {
    var vm = this;
    vm.ctrlName = 'DuplicatessidchecksCtrl';

    vm.message = message;

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name duplicateevent.controller:DuplicateeventCtrl
   *
   * @description
   *
   */
  DuplicateeventCtrl.$inject = ["$rootScope", "$state", "$stateParams", "Api", "Upload", "baseurl", "baseendpoint", "$timeout", "$modal", "$localStorage", "$log", "Auth", "Cloudservices"];
  angular
    .module('duplicateevent')
    .controller('DuplicateeventCtrl', DuplicateeventCtrl);

  function DuplicateeventCtrl($rootScope, $state, $stateParams, Api, Upload, baseurl, baseendpoint, $timeout, $modal, $localStorage, $log, Auth, Cloudservices) {
    var vm = this;
    vm.ctrlName = 'DuplicateeventCtrl';
    // redirect to appropriate start page
    if ($state.current.name === 'admin.duplicateevent') {
      $state.go('admin.duplicateevent.duplicatesetup');
    }

    vm.ssidCount = false;
    vm.ssidCountAP = [];
    vm.ssidExists = false;
    vm.nameCheckAP = [];
    vm.networkTypes = [];
    vm.zones = [];
    vm.splashPageTypes = [];
    vm.splashPageTypes = [];
    vm.splashType = 0;
    vm.spVideo = false;
    vm.spSocial = false;
    vm.spAmount = false;
    vm.spEmail = false;
    vm.spLink = false;
    vm.networktypesLookup = [];
    vm.categories = [];
    vm.defaultFbLikeUrl = true;
    vm.addlikebutton = false;
    vm.venue = {};
    vm.netPassed = false;
    vm.setupPassed = false;
    vm.splashPassed = false;
    vm.hasTwilio = false;


    // Important!!! remove this sesction once magnet county feature is complete

    vm.isMagnet = false;
    var magnetID = 9104885; //add real magnetID

    if (Auth.getAuthData().operator.organisation === magnetID) {
      vm.isMagnet = true;
    }

    Cloudservices.getCloudServiceByName($stateParams.venueid, 'twilio').then(function (resp) {
      if(resp.data.rowCount >= 1){
        vm.hasTwilio = true;
      }
    }, function (err) {
      console.log(err);
    });

    // handles navigation away from incomplete form
    vm.allowStateChange = false;
    $rootScope.$on('$stateChangeStart',
      function (event, toState, toParams, fromState) {
        console.log(toState);
        console.log(vm.allowStateChange);
        if (vm.allowStateChange === true) {
          return;
        }
        if (fromState.name === 'admin.duplicateevent.confirm') {
          if (toState.name === 'admin.duplicateevent.duplicatesetup' || toState.name === 'admin.duplicateevent.duplicatenetwork' || toState.name === 'admin.duplicateevent.duplicatesplash') {
            vm.allowStateChange = true;
            $state.go(toState.name);
          }
        }
        if (fromState.name === 'admin.duplicateevent.duplicatesetup' || fromState.name === 'admin.duplicateevent.duplicatenetwork' || fromState.name === 'admin.duplicateevent.duplicatesplash' || fromState.name === 'admin.duplicateevent.confirm') {
          if (vm.allowStateChange === false) {
            console.log('called');
            event.preventDefault();
            var modalMessage = {
              ok: 'Ok',
              cancel: 'Don\'t Cancel',
              messageHeader: 'Cancel creating event?',
            };

            var modalInstance = $modal.open({
              templateUrl: 'modalservice/views/modalservice.tpl.html',
              controller: 'ModalserviceCtrl as modalService',
              resolve: {
                message: function () {
                  return modalMessage;
                }
              }
            });

            modalInstance.result.then(function () {
              vm.allowStateChange = true;
              $state.go(toState.name);
            }, function () {

            });
          } else {
            vm.allowStateChange = false;
            return;
          }
        } else {
          vm.allowStateChange = false;
          return;
        }
        vm.allowStateChange = false;
      });
    // end state changes

    vm.storage = $localStorage;

    //get network types
    function getNetworkTypes(venueid) {
      Api.getNetworkTypes(venueid).then(function (response) {
        $log.info(response.data.networkTypes);
        vm.networkTypes = response.data.networkTypes;
        for (var i = 0; i < vm.networkTypes.length; i++) {
          vm.networktypesLookup[vm.networkTypes[i].id] = vm.networkTypes[i];
        }
        if (vm.event.networktype) {
          vm.networkType = vm.networktypesLookup[vm.event.networktype];
        }
      }, function (err) {
        $log.info(err);
      });
    }

    //get splash page types
    function getSplashPageTypes() {
      Api.getSplashPageTypes(vm.provider).success(function (data) {
        vm.splashPageTypes = data.splashTypes;
        getSocialLogins();
        getCategories();
        $log.info(vm.splashPageTypes);
        vm.setSplashPageType(vm.splashType, true);
      }).error(function () {
        $log.info('get splash page types error');
      });
    }

    function getSocialLogins() {
      Api.getSocialLogins(vm.provider).success(function (data) {
        vm.socialLogins = data.socialLoginTypes;
        $log.info('social');
        $log.info(vm.socialLogins);
      }).error(function () {
        $log.info('get social error');
      });
    }

    // categories setup
    function getCategories() {
      Api.getCategories(vm.provider).success(function (data) {
        vm.optCatList = data.categories;
        $log.info(vm.optCatList);
      }).error(function () {
        $log.info('get categories error');
      });
    }
    // end categories setup

    // manage social media

    vm.openAuthList = [];

    vm.addOpenAuth = function (value) {
      if (vm.openAuthList.indexOf(value) > -1) {
        vm.openAuthList.splice(vm.openAuthList.indexOf(value), 1);
      } else {
        vm.openAuthList.push(value);
      }
    };

    vm.priceSlider = {
      value: 2,
      options: {
        floor: 0,
        ceil: 50,
        translate: function (value) {
          return value + ' MB';
        }
      }
    };

    //set Splash Page Type
    vm.setSplashPageType = function (type, set) {
      console.log(type);
      console.log(set);
      vm.spVideo = false;
      vm.spSocial = false;
      vm.spAmount = false;
      vm.spEmail = false;
      vm.spLink = false;
      var name = type.name;
      switch (name) {
      case 'Video':
        vm.spVideo = true;
        break;
      case 'Social':
        vm.spSocial = true;
        break;
      case 'Email':
        vm.spEmail = true;
        break;
      case 'Link':
        vm.spLink = true;
        break;
      case 'Payment':
        vm.spAmount = true;
        break;
      }
      if (set) {
        for (var splash in vm.splashPageTypes) {
          if (vm.splashPageTypes[splash].id === type.id) {
            vm.splashType = vm.splashPageTypes[splash];
          }
        }
        console.log(vm.splashType);
      }
    };

    // Time And Date
    vm.today = function () {
      var newDate = new Date();
      vm.dtStart = new Date(newDate);
      vm.dtStart.setMinutes(newDate.getMinutes() + 10);
      vm.dtEnd = new Date(vm.dtStart);
      vm.dtEnd.setDate(vm.dtStart.getDate() + 1);
      vm.minDate = newDate;
    };

    vm.today();

    // manage steps

    vm.eventTime = function () {
      vm.event.starttime = new Date(vm.event.starttime);
      vm.event.starttime.setHours(vm.event.starttime.getHours());
      vm.event.starttime.setMinutes(vm.event.starttime.getMinutes());
      vm.event.starttime.setSeconds(0);

      vm.event.endtime = new Date(vm.event.endtime);
      vm.event.endtime.setHours(vm.event.endtime.getHours());
      vm.event.endtime.setMinutes(vm.event.endtime.getMinutes());
      vm.event.endtime.setSeconds(0);
    };

    vm.nextStep = function (data, state) {
      if ($state.current.name === 'admin.duplicateevent.duplicatesetup') {
        var timeError = false,
          now = new Date(),
          startTime = new Date(vm.event.startDate),
          endTime = new Date(vm.event.endDate),
          hour = 3600000,
          modalInstance;
        vm.stepOne = true;
        vm.stTimeInPast = false;
        vm.endTimeInPast = false;
        vm.endBeforeStart = false;
        vm.dateError = false;

        vm.eventTime();

        if (!vm.event.starttime || !vm.event.endtime) {
          timeError = true;
          vm.dateError = true;
        }

        if (endTime.getTime() < now.getTime()) {
          timeError = true;
          vm.endTimeInPast = true;
        }

        if (endTime.getTime() < startTime.getTime()) {
          timeError = true;
          vm.endBeforeStart = true;
        }

        if (!data.setUp.required && vm.eventImageAdded && !timeError) {
          if (endTime.getTime() - startTime.getTime() < hour) {
            modalInstance = $modal.open({
              templateUrl: 'eventcreation/views/eventtimemodal.tpl.html',
              controller: 'CancelmodalCtrl as cancelModal'
            });

            modalInstance.result.then(function () {
              vm.setupPassed = true;
              vm.allowStateChange = true;
              $state.go(state);
            }, function () {
              console.log('canceled');
            });
          } else {
            vm.allowStateChange = true;
            vm.setupPassed = true;
            $state.go(state);
          }
        }
      }

      if ($state.current.name === 'admin.duplicateevent.duplicatenetwork') {
        vm.stepTwo = true;
        var newZoneList = [];
        for (var i = 0; i < vm.zones.length; i++) {
          newZoneList.push(vm.zones[i].id);
        }
        var thisList = {
          zones: newZoneList,
          ssidname: vm.event.ssidname
        };

        Api.checkSSIDName(vm.event.starttime.getTime(), vm.event.endtime.getTime(), thisList).then(function (resp) {
          console.log(resp.status);
          Api.checkEventCount(vm.event.starttime.getTime(), vm.event.endtime.getTime(), thisList).then(function () {
            if (!data.network.required && !data.network.pattern && vm.zones.length >= 1) {
              vm.netPassed = true;
              vm.allowStateChange = true;
              $state.go(state);
            }
          }, function (err) {
            vm.ssidCount = true;
            vm.ssidCountAP = err.data.accesspoints;
          });
        }, function (err) {
          vm.ssidExists = true;
          vm.nameCheckAP = err.data.accesspoints;
          Api.checkEventCount(vm.event.starttime.getTime(), vm.event.endtime.getTime(), thisList).then(function () {

          }, function (err) {
            vm.ssidCount = true;
            vm.ssidCountAP = err.data.accesspoints;
          });
        });
      }

      if ($state.current.name === 'admin.duplicateevent.duplicatesplash') {
        vm.stepThree = true;
        var thisSplashType = vm.splashType.name;

        if (!data.splash.required && vm.bckgrndImgAdded && vm.splashType.id > 0) {
          if (thisSplashType === 'Social' && vm.openAuthList.length >= 1) {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else if (thisSplashType === 'Video' && vm.event.splashDetails.videoUrl.length > 0) {
            console.log('called');
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else if (thisSplashType === 'Payment' && vm.event.splashDetails.amount > 0 && vm.event.splashDetails.amount > 0) {
            console.log('called');
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          }
        }
      }
      vm.allowStateChange = false;
    };

    // manage event date/time

    vm.minDate = new Date();

    vm.dateOptions = {
      formatYear: 'yy',
      startingDay: 0
    };

    vm.dateFormat = 'dd-MMMM-yyyy';
    vm.hstep = 1;
    vm.mstep = 1;

    vm.status = {
      openedSt: false,
      openedEnd: false
    };

    vm.openCalSt = function () {
      vm.status.openedSt = true;
    };

    vm.openCalEnd = function () {
      vm.status.openedEnd = true;
    };

    // get images
    Api.getImages().success(function (data) {
      // multiple image lists required
      vm.eventImageList = data.images;
      for (var i = 0; i < vm.eventImageList.length; i++) {
        vm.eventImageList[i].checked = false;
      }
      vm.venueImageList = angular.copy(vm.eventImageList);
      vm.backgroundImageList = angular.copy(vm.eventImageList);
      $log.info(vm.eventImageList);
      $log.info(vm.venueImageList);
      $log.info(vm.backgroundImageList);
    });

    vm.uploadEventImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal'
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.eventImageAdded = true;
        vm.eventImage = image.imageUri;
        vm.eventImageID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectEventImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.eventImageAdded = true;
        vm.eventImage = thisImage.imageuri;
        vm.eventImageID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadLogoImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.venueLogoAdded = true;
        vm.venueLogo = image.imageUri;
        vm.venueLogoID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectLogoImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.venueLogoAdded = true;
        vm.venueLogo = thisImage.imageuri;
        vm.venueLogoID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadBckgrndImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.bckgrndImgAdded = true;
        vm.bckgrndImg = image.imageUri;
        vm.bckgrndImgID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectBckgrndImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.bckgrndImgAdded = true;
        vm.bckgrndImg = thisImage.imageuri;
        vm.bckgrndImgID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    vm.nameCheckResult = function () {
      var modalMessage = {
        messageHeader: 'Network Name Error',
        messageBody: 'Only one instance of a network name can run in a given time period, the access points below have already been assigned "' + vm.event.ssidname + '" for the given time period',
        accesspoints: vm.nameCheckAP
      };

      var modalInstance = $modal.open({
        templateUrl: 'duplicateevent/views/duplicatessidchecks.tpl.html',
        controller: 'DuplicatessidchecksCtrl as modalService',
        resolve: {
          message: function () {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function () {});

    };

    vm.ssidCountResult = function () {
      var modalMessage = {
        messageHeader: 'Network Limit Error',
        messageBody: 'A maximum of seven event can run simultainiously, the access points below will exceed this limit and cannot be created, remove them to cntinue',
        accesspoints: vm.ssidCountAP
      };

      var modalInstance = $modal.open({
        templateUrl: 'duplicateevent/views/duplicatessidchecks.tpl.html',
        controller: 'DuplicatessidchecksCtrl as modalService',
        resolve: {
          message: function () {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function () {});

    };

    Api.getEvent($stateParams.eventid, $stateParams.venueid).success(function (data) {
      vm.event = data.event;
      vm.zones = data.event.zones;
      console.log('event');
      console.log(vm.event);
      console.log('slider value' + vm.event.trafficlimitsta / 1000);
      vm.getZones(vm.event.venue);
      vm.splashType = vm.event.splashtypeid;
      vm.priceSlider.value = vm.event.trafficlimitsta / 1000;
      var thisSplash;
      if (vm.event.splashdetails) {
        thisSplash = vm.event.splashdetails;
        if (thisSplash.amount) {
          thisSplash.amount = thisSplash.amount / 100;
        }
        if (thisSplash.period) {
          thisSplash.period = thisSplash.period / 3600000;
        }
      }
      var splashType = {};
      splashType.id = vm.event.splashtypeid;
      splashType.name = vm.event.splashtypename;
      vm.splashType = splashType;
      vm.event.networkType = vm.event.networktype;
      //vm.event.splashDetails.videoUrl = vm.event.splashdetails.videoURL;
      if (vm.event.eventimageurl.length > 0) {
        vm.eventImageAdded = true;
        vm.eventImageID = vm.event.eventimage;
        vm.eventImage = vm.event.eventimageurl;
      }
      if (vm.event.backgroundimage.length > 0) {
        vm.bckgrndImgAdded = true;
        vm.bckgrndImgID = vm.event.splashpagebackgroundimage;
        vm.bckgrndImg = vm.event.backgroundimage;
      }
      if (vm.event.logoimage.length > 0) {
        vm.venueLogoAdded = true;
        vm.venueLogoID = vm.event.splashpagelogoimage;
        vm.venueLogo = vm.event.logoimage;
      }
      for (var i = 0; i < vm.event.socialLoginTypes.length; i++) {
        vm.openAuthList.push(vm.event.socialLoginTypes[i].id);
      }
      for (var j = 0; j < vm.event.categories.length; j++) {
        vm.categories.push(vm.event.categories[j].id);
      }
      if (Auth.getAuthData()) {
        vm.event.org = Auth.getAuthData().operator.organisation;
      }
      vm.event.starttime = vm.dtStart;
      vm.event.endtime = vm.dtEnd;
      Api.getVenue(vm.event.venue).success(function (data) {
        var detailsJson = {};
        if(typeof data.venue.details === 'string'){
          detailsJson = JSON.parse(data.venue.details);
        } else {
          detailsJson = data.venue.details;
        }
        if(detailsJson){
          vm.venue.customFbLikeUrl = detailsJson.fbpage;
          if (vm.venue.customFbLikeUrl) {
            vm.defaultFbLikeUrl = true;
            if (vm.event.splashdetails) {
              if (vm.event.splashdetails.customFbLikeUrl) {
                vm.customFbLike = true;
                if (vm.event.splashdetails.customFbLikeUrl !== vm.venue.customFbLikeUrl) {
                  vm.defaultFbLikeUrl = false;
                }
              } else {
                vm.customFbLike = false;
              }
            }
          } else {
            vm.customFbLike = true;
            vm.defaultFbLikeUrl = false;
          }
        } else {
          vm.venue.customFbLikeUrl = null;
          vm.customFbLike = true;
        }
      });
      if (vm.event.splashdetails) {
        vm.addlikebutton = (vm.event.splashdetails.customFbLikeUrl) ? true : false;
      } else {
        vm.addlikebutton = false;
        vm.defaultFbLikeUrl = true;
        vm.customFbLike = false;
      }
      Api.getInfrastructures(vm.event.venue).then(function (res) {
        var infra = res.data.infrastructures[0];
        vm.provider = JSON.parse(infra.provider);
        getSplashPageTypes();
      }, function (err) {
        console.log(err);
      });
    });

    //accesspoint setup
    vm.getZones = function (id) {
      vm.zoneList = [];
      Api.getZones(id).success(function (data) {
        vm.zoneList = data.zones;
        var newList = [];
        for (var i = 0; i < vm.zoneList.length; i++) {
          for (var j = 0; j < vm.event.zones.length; j++) {
            if (vm.zoneList[i].id === vm.event.zones[j].zone) {
              newList.push(vm.zoneList[i]);
            }
          }
        }
        vm.event.zones = newList;
      }).error(function () {
        $log.info('get ap error');
      });
      getNetworkTypes(id);
    };

    vm.setNetworkType = function (typeid) {
      vm.event.networktype = typeid;
      vm.event.optCatList = [];
      Api.getNetworkType(typeid, vm.event.venue).then(function (response) {
        $log.info(response);
        if (response.data.networkType.networkStrategies.length > 0) {
          console.log(response.data.networkType.networkStrategies.length);
          for (var i = 0; i < response.data.networkType.networkStrategies.length; i++) {
            vm.event.optCatList.push(response.data.networkType.networkStrategies[i].id);
          }
          //vm.categories = vm.event.optCatList;
        }
      }, function (err) {
        $log.info(err);
      });
    };

    vm.cancel = function () {
      $state.go('admin.events');
    };

    vm.updateEvent = function () {
      vm.eventTime();
      var thisSplash = vm.event.splashdetails;
      if (thisSplash) {
        if (thisSplash.amount) {
          thisSplash.amount = thisSplash.amount * 100;
        }
        if (thisSplash.period) {
          thisSplash.period = thisSplash.period * 3600000;
        }
      }
      if (vm.addlikebutton) {
        if (vm.defaultFbLikeUrl) {
          vm.event.splashdetails.customFbLikeUrl = vm.venue.customFbLikeUrl;
        }
      } else {
          vm.event.splashdetails.customFbLikeUrl = undefined;
      }
      // if(vm.defaultFbLikeUrl){
      //   vm.event.splashdetails.customFbLikeUrl = vm.defaultFbPage;
      // } else if (!vm.addlikebutton) {
      //   vm.event.splashdetails.customFbLikeUrl = undefined;
      // }
      var thisBandwidth = vm.priceSlider.value * 1000,
        thisEvent = {
          orgId: vm.event.org,
          venueId: vm.event.venue,
          name: vm.event.name,
          description: vm.event.description,
          eventimage: vm.eventImageID,
          ssidname: vm.event.ssidname,
          starttime: vm.event.starttime,
          endtime: vm.event.endtime,
          zones: vm.zones,
          categories: vm.event.categories,
          trafficlimitsta: thisBandwidth,
          splashpagebackgroundimage: vm.bckgrndImgID,
          splashpagelogoimage: vm.venueLogoID,
          sociallogintypes: vm.openAuthList,
          splashTypeId: vm.splashType.id,
          splashDetails: thisSplash,
          redirectionurl: vm.event.redirectionurl,
          networkTypeId: vm.event.networkType,
          eventstatus: vm.event.eventstatus
        };

      if (vm.event.description === null) {
        thisEvent.description = '';
      }
      if (vm.splashType.name.toLowerCase() !== 'social') {
        thisEvent.sociallogintypes = [];
      }
      var catList = [];
      for (var j = 0; j < vm.event.categories.length; j++) {
        catList.push(vm.event.categories[j].id);
      }

      Api.createEvent(thisEvent, vm.event.venue).success(function () {
        vm.storage.alerts.push({
          messageType: 'event',
          message: 'Event Update'
        });
        vm.allowStateChange = true;
        $state.go('admin.events');
      }).error(function (error) {
        console.log(error);
      });
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name admin
   * @description
   *
   */
  angular
    .module('admin', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name admin.controller:AdminCtrl
   *
   * @description
   *
   */
  AdminCtrl.$inject = ["$rootScope", "Auth", "$cookies", "$state", "$localStorage", "$location"];
  angular
    .module('admin')
    .controller('AdminCtrl', AdminCtrl);

  function AdminCtrl($rootScope, Auth, $cookies, $state, $localStorage, $location) {
    var vm = this;
    vm.ctrlName = 'AdminCtrl';

    $rootScope.bodyClass = 'admin';

    $rootScope.activeUser = '';

    vm.profileInfo = {};

    if (Auth.getAuthData()) {
      vm.profileInfo = Auth.getAuthData().operator;
      $rootScope.activeUser = Auth.getAuthData().operator;
    }
    vm.storage = $localStorage;

    if (angular.isUndefined(vm.storage.alerts)) {
      vm.storage.alerts = [];
    }

    vm.logout = function () {
      $cookies.remove('leData');
      Auth.setIslogged(false);
      $state.go('main.home');
    };

    vm.dismissAlert = function (alert) {
      var index = vm.storage.alerts.indexOf(alert);
      if (index > -1) {
        vm.storage.alerts.splice(index, 1);
      }
    };

    vm.getAlerts = function () {
      if (vm.storage.alerts.length >= 1) {
        return true;
      }
      return false;
    };
    vm.items = [
      'The first choice!',
      'And another choice for you.',
      'but wait! A third!'
    ];

    vm.status = {
      isopen: false
    };

    vm.toggled = function (open) {
      console.log('Dropdown is now: ', open);
    };

    vm.toggleDropdown = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      vm.status.isopen = !vm.status.isopen;
    };

    vm.support = function () {
      $state.go('admin.support');
    };
    
    vm.getClass = function (path) {
      return ($location.path().substr(0, path.length) === path) ? 'active' : '';
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addvenuemap
   * @description
   *
   */
  angular
    .module('addvenuemap', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addvenuemap.controller:AddvenuemapCtrl
   *
   * @description
   *
   */
  AddvenuemapCtrl.$inject = ["baseurl", "$stateParams", "$window", "Api", "ApiAnalytic", "$modal", "$state", "$scope"];
  angular
    .module('addvenuemap')
    .controller('AddvenuemapCtrl', AddvenuemapCtrl);

  function AddvenuemapCtrl(baseurl, $stateParams, $window, Api, ApiAnalytic, $modal, $state, $scope) {
    var vm = this;
    vm.ctrlName = 'AddvenuemapCtrl';

    vm.venueid = $stateParams.venueid;
    var orgid = $stateParams.orgid;
    vm.mapName = '';

    // Data Sets
    vm.accesspoints = [];
    vm.onmapaps = [];
    vm.accesspointData = [];

    // Page Objects
    var backDrop = document.getElementById('backDrop');
    var pointMap = document.getElementById('pointMap');
    var heatMapContainer = document.getElementById('heatMapContainer');

    /* Mouse Control */
    vm.dragging = false;
    vm.canvasApDrag = {};
    pointMap.addEventListener('mousedown', function(event) {
      if(vm.accesspointData.length !== 0) {
        for(var i = 0; i < vm.accesspointData.length; i++){
          // Do drag
          var a = event.offsetX - vm.accesspointData[i].x;
          var b = event.offsetY - vm.accesspointData[i].y;
          var dist = Math.sqrt( a*a + b*b );
          if (Math.abs(dist) < 10) {
            vm.canvasApDrag = vm.accesspointData[i];
            vm.accesspointData.splice(i,1);
            vm.dragging = true;
          }
          // Do clear
          if (!vm.dragging) {
            var aX = event.offsetX - (vm.accesspointData[i].x+(vm.mapWidth/32)*.6);
            var bX = event.offsetY - (vm.accesspointData[i].y-(vm.mapWidth/32)*.6);
            var distX = Math.sqrt( aX*aX + bX*bX );
            if (Math.abs(distX) < 6) {
              var ap = vm.accesspointData[i];
              vm.accesspointData.splice(i,1);
              for(var j = 0; j < vm.onmapaps.length; j++){
                if (ap.hwaddr === vm.onmapaps[j].hwaddr) {
                  vm.accesspoints.push(vm.onmapaps[j]);
                  vm.onmapaps.splice(j,1);
                  vm.draw(vm.accesspointData);
                  $scope.$apply();
                  j = vm.onmapaps.length;
                }
              }
            }
          }

        }
      }
    }, false);
    pointMap.addEventListener('mousemove', function(e) {
      if (vm.dragging){
        var x =  event.offsetX;
        var y = event.offsetY;
        if (vm.canvasApDrag !== {}) {
          vm.canvasApDrag.x = x;
          vm.canvasApDrag.y = y;
        }
        vm.draw(vm.accesspointData);
      }
    }, true);
    pointMap.addEventListener('mouseup', function(event) {
      if (vm.dragging) {
        vm.accesspointData.push(vm.canvasApDrag);
        vm.canvasApDrag = {};
        vm.dragging = false;
        vm.draw(vm.accesspointData);
      }
    }, false);

    // Context
    var context = pointMap.getContext('2d');
    var backCtx = backDrop.getContext('2d');
    backCtx.globalAlpha = 1.0;
    context.globalAlpha = 1.0;

    // Setup Data
    vm.mapHeight = 480;
    vm.mapWidth = 854;
    vm.dragingAp = {};

    // image Data
    vm.backdrop = '';
    vm.backgroundSelected = false;
    vm.backdropId = 0;
    vm.backdropUri = '';
    vm.apicon = './images/apicon.png';
    vm.imageloaded = false;
    var imageObj = new Image();

    vm.submit = function() {
      var map = {
        'name': vm.mapName,
        'venue': vm.venueid,
        'originalHeight': vm.mapHeight,
        'originalWidth': vm.mapWidth,
        'imageId': vm.backdropId,
        'imageUri': vm.backdropUri,
        'points': vm.accesspointData
      };
      ApiAnalytic.createMap(map).then(function (res) {
        $state.go('admin.managenetwork', {
          'venueid': $stateParams.venueid
        });
        console.log(res);
      }, function (err) {
        console.log(err);
      });
    };

    vm.startMap = function(){
      // fetch Aps
      Api.getAccessPoints(vm.venueid).then(function (res) {
        vm.accesspoints = res.data.apoints;
        vm.drawBackground();
      }, function (err) {
        console.log(err);
      });
    };

    // Drawing
    vm.draw = function(data) {
      context.clearRect(0, 0, pointMap.width, pointMap.height);
      for(var i=0; i<data.length; i++) {
        vm.drawAp(data[i]);
      }
      if(vm.dragging) {
        vm.drawAp(vm.canvasApDrag);
      }
    };
    vm.drawX = function(x, y) {
      context.beginPath();
      var size = 5;

      context.strokeStyle = '#ff0000';
      context.lineWidth = 2;

      context.moveTo(x - size, y - size);
      context.lineTo(x + size, y + size);

      context.moveTo(x + size, y - size);
      context.lineTo(x - size, y + size);

      context.stroke();
    }
    vm.drawBackground = function () {
      var background = new Image();

      background.onload = function() {
        if (heatMapContainer.offsetWidth < background.width) {
          var max = background.width;
          var reduced = heatMapContainer.offsetWidth;
          var diff = max - reduced;
          var percent = parseInt((diff/max)*100);
          background.width = reduced;
          background.height = parseInt((background.height/100)*percent);
        } else {
          var min = background.width;
          var scaled = heatMapContainer.offsetWidth;
          var diff = scaled - min;
          var percent = parseInt((diff/min)*100);
          background.width = scaled;
          background.height = background.height + parseInt((background.height/100)*percent);
        }


        vm.mapHeight = background.height;
        vm.mapWidth = background.width;

        pointMap.width = vm.mapWidth;
        pointMap.height = vm.mapHeight;
        backDrop.width = vm.mapWidth;
        backDrop.height = vm.mapHeight;

        backCtx.drawImage(background, 0,0, vm.mapWidth, vm.mapHeight);
        vm.loadIcon();
        vm.draw(vm.accesspointData);
      };
      background.src = vm.backdrop;
    };
    vm.drawAp = function(data) {
      if (vm.imageloaded) {
        context.font = (vm.mapWidth/32)/3+"px Verdana";
        context.fillStyle = 'white';
        context.strokeStyle = 'black';
        context.lineWidth = .5;
        context.drawImage(imageObj, data.x - (vm.mapWidth/32)/2, data.y - (vm.mapWidth/32)/2, vm.mapWidth/32, vm.mapWidth/32);
        context.fillText(data.name, data.x- (vm.mapWidth/32)/2, data.y+(vm.mapWidth/32)*.8);
        context.strokeText(data.name,data.x- (vm.mapWidth/32)/2,data.y+(vm.mapWidth/32)*.8);

        vm.drawX(data.x + (vm.mapWidth/32)*.6, data.y - (vm.mapWidth/32)*.6)
      }
    };

    // Image Functions
    vm.loadIcon= function () {
      imageObj.onload = function() {
        vm.imageloaded = true;
      };
      imageObj.src = vm.apicon;
    }
    vm.uploadImage = function(){
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': parseInt(vm.venueid)
        }
      });

      modalInstance.result.then(function (image) {
        vm.backdrop = baseurl + '/' +image.imageUri;
        vm.backdropUri = image.imageUri;
        vm.backdropId = image.id;
        vm.backgroundSelected = true;
        vm.startMap();
      }, function () {
        console.log('canceled');
      });
    };

    // Drag N Drop Onto Canvas
    vm.handleApDrop = function(element) {
      var ap = {
        x: element.x,
        y: element.y,
        name: vm.dragingAp.name,
        hwaddr: vm.dragingAp.hwaddr
      }
      vm.dragingAp = {};

      for(var i = 0; i < vm.accesspoints.length; i++) {
        if (ap.hwaddr === vm.accesspoints[i].hwaddr) {
          vm.accesspointData.push({
            name: ap.name,
            hwaddr: ap.hwaddr,
            x: ap.x,
            y: ap.y
          });
          vm.onmapaps.push(vm.accesspoints[i]);
          vm.accesspoints.splice(i, 1);
        }
      }
      vm.draw(vm.accesspointData);
    }
  }
  angular.module('leoperations').directive('dragCanvasAps', function() {
    return function(scope, element) {
      var el = element[0];

      el.draggable = true;

      el.addEventListener('dragstart',function(e) {
        e.dataTransfer.effectAllowed = 'move';
        //e.dataTransfer.setData('Text', this.id);
        var ap = e.target.id.split("|");
        scope.$parent.addvenuemap.dragingAp = {
          hwaddr: ap[1],
          name: ap[0]
        };
        this.classList.add('drag');
        return false;
      },false);

      el.addEventListener('dragend', function(e) {
        this.classList.remove('drag');
        return false;
      },false);
    }
  });
  angular.module('leoperations').directive('dropCanvasAps', function() {
    return {
      scope: {},
      link: function(scope, element) {
        var el = element[0];

        el.addEventListener('dragover',function(e) {
          e.dataTransfer.dropEffect = 'move';
          // allows us to drop
          if (e.preventDefault) e.preventDefault();
          this.classList.add('over');
          return false;
        },false);
        el.addEventListener('dragenter',function(e) {
          this.classList.add('over');
          return false;
        },false);
        el.addEventListener('dragleave',function(e) {
          this.classList.remove('over');
          return false;
        },false);

        el.addEventListener('drop',function(e) {
          if (e.stopPropagation) e.stopPropagation();
          this.classList.remove('over');

          scope.$apply(function() {
            scope.$parent.addvenuemap.handleApDrop({
              x: e.layerX,
              y: e.layerY
            });
          });

          return false;
        },false);
      }
    }
  });
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addzone
   * @description
   *
   */
  angular
    .module('addzone', [
      'ui.router'
    ]);
}());

(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name addzone.controller:AddzoneCtrl
   *
   * @description
   *
   */
  AddzoneCtrl.$inject = ["Api", "$stateParams", "$log", "$state", "Auth"];
  angular
    .module('addzone')
    .controller('AddzoneCtrl', AddzoneCtrl);

  function AddzoneCtrl(Api, $stateParams, $log, $state, Auth) {
    var vm = this;
    vm.status = {
      isopen: false
    };
    vm.zone = {};
    vm.accesspoints = [];
    vm.selectedAccesspoints = [];
    vm.provider = $stateParams.provider;
    var orgid = Auth.getAuthData().operator.organisation;
    var zoneId = 0;

    Api.getAccessPoints($stateParams.venueid).success(function(data) {
      $log.info(data);
      vm.accesspoints = data.apoints;
      $log.info(vm.accesspoints);
    }).error(function() {
      vm.accesspoints = [];
      $log.info('get Zones error');
    });

    vm.createZone = function() {
      //console.log(vm.selectedAccesspoints);
      vm.zone.orgid = $stateParams.orgid;
      Api.newZone($stateParams.venueid, vm.zone).success(function(data) {
        //console.log(data);
        zoneId = data.id;
        console.log(vm.selectedAccesspoints);
        if (vm.selectedAccesspoints.length > 0) {
          var strSelectedAccesspoints = vm.selectedAccesspoints;
          var apZoneUpateObj = {
            venueId: $stateParams.venueid,
            zoneId: zoneId,
            orgId: orgid,
            accessPoints: strSelectedAccesspoints
          };
          console.log(apZoneUpateObj);
          Api.updateApsZone(apZoneUpateObj).success(function(data) {
            console.log(data);
            $state.go('admin.managenetwork', {
              'venueid': $stateParams.venueid
            });
          }).error(function(err) {
            console.log(err);
          });
        } else {
          $state.go('admin.managenetwork', {
            'venueid': $stateParams.venueid
          });
        }
      }, function(err) {
        console.log(err);
      });
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addsurvey
   * @description
   *
   */
  angular
    .module('addsurvey', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addsurvey.controller:AddsurveyCtrl
   *
   * @description
   *
   */
  AddsurveyCtrl.$inject = ["$state", "$stateParams", "Api"];
  angular
    .module('addsurvey')
    .controller('AddsurveyCtrl', AddsurveyCtrl);

  function AddsurveyCtrl($state, $stateParams, Api) {
    var vm = this;
    vm.ctrlName = 'AddsurveyCtrl';
    vm.selectedSurvey = false;
    vm.numberOfQuestionsTotal = 5;
    vm.numberOfQuestions = 0;
    vm.surveyObjects = [];
    vm.surveyId = 0;

    //setSurvey
    function addEmptySurveyObjects(){
      for (var i=0; i<vm.numberOfQuestionsTotal; i++){
        var surveyObj = { 'id': i, 'show': false};
        vm.surveyObjects.push(surveyObj);
      }
    }

    /*
    {"survey":[{"question":"Do you like apples?","answers":[{"answer":"yes","count":0},{"answer":"no","count":0}]},{"question":"Do you like pears?","answers":[{"answer":"yes","count":0},{"answer":"no","count":4}]},{"question":"Do you like kiwi?","answers":[{"answer":"yes","count":0},{"answer":"no","count":4}]}]}
    */

    addEmptySurveyObjects();

    vm.addQuestion = function () {
      if(vm.numberOfQuestions < vm.numberOfQuestionsTotal) {
        vm.surveyObjects[vm.numberOfQuestions].show = true;
        vm.numberOfQuestions = vm.numberOfQuestions + 1;
      }
    };

    vm.removeQuestion = function () {
      if(vm.numberOfQuestions > 0) {
        vm.numberOfQuestions = vm.numberOfQuestions - 1;
        vm.surveyObjects[vm.numberOfQuestions].show = false;
      }
    };

    vm.setSurveyObject = function () {
      var details = [];
      //add survey
      for (var i=0; i < vm.numberOfQuestions; i++) {
        if (vm.surveyObjects[i].survey !==null && vm.surveyObjects[i].survey && vm.surveyObjects[i].survey.question !== null && vm.surveyObjects[i].survey.question){
          var answers = [];
          if (vm.surveyObjects[i].survey.answer1 !== null && vm.surveyObjects[i].survey.answer1){
            answers.push({'answer': vm.surveyObjects[i].survey.answer1, 'count':0});
          }
          if (vm.surveyObjects[i].survey.answer2 !== null && vm.surveyObjects[i].survey.answer2){
            answers.push({'answer': vm.surveyObjects[i].survey.answer2, 'count':0});
          }
          if (vm.surveyObjects[i].survey.answer3 !== null && vm.surveyObjects[i].survey.answer3){
            answers.push({'answer': vm.surveyObjects[i].survey.answer3, 'count':0});
          }
          if (vm.surveyObjects[i].survey.answer4 !== null && vm.surveyObjects[i].survey.answer4){
            answers.push({'answer': vm.surveyObjects[i].survey.answer4, 'count':0});
          }
          var surveyObj = {
            'question': vm.surveyObjects[i].survey.question,
            'answers': answers
          };
          details.push(surveyObj);
        }
      }
      //var eventName = vm.newEvent.name;
      var surveyObj = { survey: details};
      var survey = {name: "name", isActive: true, details: surveyObj};
      return survey;
    };

    vm.createSurvey = function () {
      var surveyObj = vm.setSurveyObject();
      Api.createSurvey(surveyObj, $stateParams.eventid).success(function () {
        $state.go('admin.events');
      }).error(function (error) {
        $log.info(error);
      });
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addclservice
   * @description
   *
   */
  angular
    .module('addclservice', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addclservice.controller:AddclserviceCtrl
   *
   * @description
   *
   */
  AddclserviceCtrl.$inject = ["$state", "$log", "$scope", "Cloudservices", "$stateParams", "Cloudservicetype", "Api"];
  angular
    .module('addclservice')
    .controller('AddclserviceCtrl', AddclserviceCtrl);

  function AddclserviceCtrl($state, $log, $scope, Cloudservices, $stateParams, Cloudservicetype, Api) {
    var vm = this;
    vm.ctrlName = 'AddclserviceCtrl';
    var venueid = $stateParams.venueid;

    // errors and messages
    vm.updatedApi = false;
    vm.errorMessage = false;
    vm.apiMessage = 'API Updated';
    vm.error = '';

    // Objects
    vm.venueid = $stateParams.venueid;
    vm.cloudservicetypes = [];
    vm.selectedType = {};
    vm.cloudserviceDetails = {};
    vm.lists = [];

    Cloudservicetype.getCloudServiceTypes().success(function (data) {
      if (data.cloudServiceTypes) {
        vm.cloudservicetypes = data.cloudServiceTypes;
      } else {
        vm.error = 'Cloud Service types could not be located, if this message persists please submit a support ticket.';
        vm.errorMessage = true;
      }
    }).error(function (err) {
      vm.errorMessage = true;
      vm.error = 'Cloud Services error on-load, if this message persists please submit a support ticket.';
      console.log('Error: ', err);
    });

    vm.getLists = function () {
      vm.lists = [];
      Api.getLists(vm.apiKey).then(function (resp) {
        for (var i = 0; i < resp.data.lists.length; i++) {
          vm.lists.push({
            id: resp.data.lists[i].id,
            name: resp.data.lists[i].name
          });
        }
      }, function (err) {
        vm.errorMessage = true;
        vm.error = 'Cloud Services could not find valid list, check your api key is correct. If this message persists please submit a support ticket.';
        console.log('Error: ', err);
      });
    };

    vm.updateApiKey = function () {
      if (vm.apiKey) {
        vm.updatedApi = true;
        vm.getLists();
      } else {
        vm.newListId = '';
        vm.lists = [];
      }
    };

    vm.createCloudService = function (isValid, cloudService) {
      vm.submitted = true;
      var thisCldSrv = cloudService;
      thisCldSrv.typeid = vm.selectedType.id;
      if (cloudService.phonenumber) {
        thisCldSrv.phonenumber = cloudService.phonenumber.replace('+', '');
      }
      // console.log(thisCldSrv);
      if (isValid) {
        Cloudservices.createCloudService($stateParams.venueid, thisCldSrv).then(function (resp) {
          console.log(resp);
          $state.go('admin.cloudservices', {
            'venueid': $stateParams.venueid
          });
        }, function (err) {
          console.log(err);
          vm.errorMessage = true;
          vm.error = 'Cloud Services could not create, check you have completed the required fields. If this message persists please submit a support ticket.';
        });
      }
    };

    vm.createNewList = function () {
      $state.go('admin.newlist', {
        apiKey: vm.apiKey,
        venueid: venueid
      });
    };

    vm.dismiss = function () {
      vm.updatedApi = false;
      vm.errorMessage = false;
      vm.error = '';
    };

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addap
   * @description
   *
   */
  angular
    .module('addap', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addap.controller:AddapCtrl
   *
   * @description
   *
   */
  AddapCtrl.$inject = ["Api", "$stateParams", "$log", "$state"];
  angular
    .module('addap')
    .controller('AddapCtrl', AddapCtrl);

  function AddapCtrl(Api, $stateParams, $log, $state) {
    var vm = this;
    vm.accesspoint = {};
    vm.accesspoint.isAccessable = 'false';
    vm.ErrorMessage = false;
    vm.Message = [];
    vm.submitted = false;

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

    vm.setProvider = function () {
      vm.accesspoint.provider = $stateParams.provider;
    };

    vm.createAccesspoint = function (isValid) {
      console.log('called');
      vm.submitted = true;
      if (isValid) {
        Api.addAccessPoint($stateParams.venueid, vm.accesspoint).success(function (data) {
          console.log(data);
          $state.go('admin.managenetwork', {
            'venueid': $stateParams.venueid
          });
        }).error(function (err) {
          vm.ErrorMessage = true;
          vm.Message = err.message;
          console.log(err);
        });
      }
    };
    vm.setProvider();
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name addInfrastructure
   * @description
   *
   */
  angular
    .module('addInfrastructure', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addInfrastructure.controller:AddInfrastructureCtrl
   *
   * @description
   *
   */
  AddInfrastructureCtrl.$inject = ["Api", "$stateParams", "$log", "$state"];
  angular.module('addInfrastructure').controller('AddInfrastructureCtrl', AddInfrastructureCtrl);

  function AddInfrastructureCtrl(Api, $stateParams, $log, $state) {
    var vm = this;

    vm.infrastructure = {};
    vm.submitted = false;
    vm.providerSelected = false;

    vm.providerTypes = [];

    vm.selectedProvider = '';

    vm.setProvider = function (provider) {
      vm.providerSelected = true;
      vm.selectedProvider = provider;
      if (provider === 1) {
        vm.infrastructure.controlleraddress = 'Xirrus';
      } else {
        vm.infrastructure.controlleraddress = '';
      }
    };
    Api.getVenue($stateParams.venueid).then(function (res) {
      vm.venue = res.data.venue;
    }, function (err) {
      console.log(err);
    });

    Api.getProviders().then(function (res) {
      console.log(res);
      vm.providerTypes = res.data.providers;
    }, function (err) {
      console.log(err);
    });

    vm.create = function (isValid) {
      var thisInfrastructure = vm.infrastructure;
      vm.submitted = true;
      var data = {
        infrastructure: vm.infrastructure,
        venues: []
      };
      data.venues.push({
        id: vm.infrastructure.authentication,
        details: '{}'
      });
      vm.venue.siteid = vm.infrastructure.authentication;
      vm.venue.venueTitle = vm.venue.venuetitle;
      vm.venue.imageId = vm.venue.imageid;

      if (isValid) {
        Api.setGuestSettings(vm.infrastructure.provider, data).then(function successCallback(response) {
          console.log(response.statusCode);
          Api.updateVenue($stateParams.venueid, vm.venue).then(function successCallback(response) {
            Api.addInfrastructure($stateParams.venueid, thisInfrastructure).then(function (res) {
              $log.info(res);
              $state.go('admin.managenetwork', {
                'venueid': $stateParams.venueid
              });
            }, function (err) {
              $log.console.error(err);
            });
          }, function errorCallback(response) {
            console.log(response);
            vm.error = response;
          });
        }, function errorCallback(response) {
          console.log(response);
          vm.error = response;
        });
      }
    };
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('venues')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.venues', {
        url: '/venues/:orgid',
        templateUrl: 'venues/views/venues.tpl.html',
        controller: 'VenuesCtrl',
        controllerAs: 'venues'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('userprofile')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.userprofile', {
        url: '/userprofile/:venueid/:userid/:providerid',
        templateUrl: 'userprofile/views/userprofile.tpl.html',
        controller: 'UserprofileCtrl',
        controllerAs: 'userprofile'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('tandceditor')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.tandceditor', {
        url: '/tandceditor/:venueid',
        templateUrl: 'tandceditor/views/tandceditor.tpl.html',
        controller: 'TandceditorCtrl',
        controllerAs: 'tandceditor'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('tags')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.tags', {
        url: '/segmentation/:venueid',
        templateUrl: 'tags/views/tags.tpl.html',
        controller: 'TagsCtrl',
        controllerAs: 'tags'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('support')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.support', {
        url: '/support',
        templateUrl: 'support/views/support.tpl.html',
        controller: 'SupportCtrl',
        controllerAs: 'support'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('ppolicy')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('main.privacypolicy', {
        url: 'privacypolicy',
        templateUrl: 'ppolicy/views/ppolicy.tpl.html',
        controller: 'PpolicyCtrl',
        controllerAs: 'ppolicy'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('orgInfrastructures')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.orgInfrastructures', {
        url: '/org-infrastructures/:orgid',
        templateUrl: 'org-infrastructures/views/org-infrastructures.tpl.html',
        controller: 'OrgInfrastructuresCtrl',
        controllerAs: 'orgInfrastructures'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('operators')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.operators', {
        url: '/operators',
        templateUrl: 'operators/views/operators.tpl.html',
        controller: 'OperatorsCtrl',
        controllerAs: 'operators'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('newvenueanalytics')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.newvenueanalytics', {
        url: '/newvenueanalytics/:venueid/:class',
        templateUrl: 'newvenueanalytics/views/newvenueanalytics.tpl.html',
        controller: 'NewvenueanalyticsCtrl',
        controllerAs: 'newvenueanalytics'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('newoperator')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.newoperator', {
        url: '/newoperator',
        templateUrl: 'newoperator/views/newoperator.tpl.html',
        controller: 'NewoperatorCtrl',
        controllerAs: 'newoperator'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('newvenue')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.newvenue', {
        url: '/newvenue',
        templateUrl: 'newvenue/views/newvenue.tpl.html',
        controller: 'NewvenueCtrl',
        controllerAs: 'newvenue'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('newlist')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.newlist', {
        url: '/newlist/:apiKey/:venueid',
        templateUrl: 'newlist/views/newlist.tpl.html',
        controller: 'NewlistCtrl',
        controllerAs: 'newlist'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('networktype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.networktype', {
        url: '/networktype/:venueid/:provider',
        templateUrl: 'networktype/views/networktype.tpl.html',
        controller: 'NetworktypeCtrl',
        controllerAs: 'networktype'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('managenetwork')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.managenetwork', {
        url: '/managenetwork/:venueid/:orgid',
        templateUrl: 'managenetwork/views/managenetwork.tpl.html',
        controller: 'ManagenetworkCtrl',
        controllerAs: 'managenetwork'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('manageimages')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.manageimages', {
        url: '/manageimages',
        templateUrl: 'manageimages/views/manageimages.tpl.html',
        controller: 'ManageimagesCtrl',
        controllerAs: 'manageimages'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('main')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('main', {
        url: '/',
        templateUrl: 'main/views/main.tpl.html',
        controller: 'MainCtrl',
        controllerAs: 'main',
        data: {
          requireLogin: false
        }
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('mailchimp')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.mailchimp', {
        url: '/mailchimp/:venueid',
        templateUrl: 'mailchimp/views/mailchimp.tpl.html',
        controller: 'MailchimpCtrl',
        controllerAs: 'mailchimp'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('mailListModal')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('mailListModal', {
        url: '/mail-list-modal',
        templateUrl: 'mail-list-modal/views/mail-list-modal.tpl.html',
        controller: 'MailListModalCtrl',
        controllerAs: 'mailListModal'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('home')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('main.home', {
        url: 'home',
        templateUrl: 'home/views/home.tpl.html',
        controller: 'HomeCtrl',
        controllerAs: 'home'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.factory:Twilio
   *
   * @description
   *
   */
  Twilio.$inject = ["baseurl", "baseendpoint", "$http", "Auth"];
  angular
    .module('leoperations')
    .factory('Twilio', Twilio);

  function Twilio(baseurl, baseendpoint, $http, Auth) {
    var TwilioBase = {};
    var token = Auth.getAuthData().token;
    var orgid = Auth.getAuthData().operator.organisation;

    TwilioBase.sendSMS = function(message){
      return $http.post(baseurl + baseendpoint + '/sms/sendmessage?org=' + orgid, message, {
        headers: {
          'x-access-token': token
        }
      });



    };

    return TwilioBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.factory:Tandc
   *
   * @description
   *
   */
  Tandc.$inject = ["baseurl", "baseendpoint", "$http", "Auth"];
  angular
    .module('leoperations')
    .factory('Tandc', Tandc);

  function Tandc(baseurl, baseendpoint, $http, Auth) {
    var TandcBase = {},
        token = Auth.getAuthData().token;

    TandcBase.createTandC = function (tandc) {
      var data = {
        tandc: tandc.tandc
      };
      if (tandc.venue) {
        return $http.post(baseurl + baseendpoint + '/tandc/org/' + tandc.org + '?venue=' + tandc.venue, data, {
          headers: {
            'x-access-token': token
          }
        });
      }
      return $http.post(baseurl + baseendpoint + '/tandc/org/' + tandc.org, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TandcBase.getTandCbyVenue = function (org, venue) {
      return $http.get(baseurl + baseendpoint + '/tandc/org/' + org + '/venue/' + venue, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TandcBase.getTandCbyOrg = function (org) {
      return $http.get(baseurl + baseendpoint + '/tandc/org/' + org, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TandcBase.updateTandC = function (tandc) {
      return $http.put(baseurl + baseendpoint + '/tandc/' + tandc.id + '/org/' + tandc.org, tandc, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TandcBase.deleteTandC = function (tandc) {
      return $http.delete(baseurl + baseendpoint + '/tandc/' + tandc.id + '/org/' + tandc.org, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return TandcBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.factory:Tags
   *
   * @description
   *
   */
  Tags.$inject = ["baseurl", "baseendpoint", "$http", "Auth"];
  angular
    .module('leoperations')
    .factory('Tags', Tags);

  function Tags(baseurl, baseendpoint, $http, Auth) {
    var TagsBase = {};
    var token = Auth.getAuthData().token;

    TagsBase.createTag = function (tag) {
      return $http.post(baseurl + baseendpoint + '/tags', tag, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TagsBase.getTags = function (venue) {
      return $http.get(baseurl + baseendpoint + '/tags/venue/' + venue, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TagsBase.getTag = function (tagid) {
      return $http.get(baseurl + baseendpoint + '/tags/' + tagid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TagsBase.updateTag = function (tagid, tag) {
      return $http.put(baseurl + baseendpoint + '/tags/' + tagid, tag, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TagsBase.deleteTag = function (tagid) {
      return $http.delete(baseurl + baseendpoint + '/tags/' + tagid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TagsBase.getTagsByUser = function (venueid, usermac) {
      return $http.get(baseurl + baseendpoint + '/tags/venue/' + venueid + '/user/' + usermac, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TagsBase.tagUser = function (tagid, usermac) {
      return $http.put(baseurl + baseendpoint + '/tags/' + tagid + '/user/' + usermac, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TagsBase.getEmailList = function (venueid, data) {
      return $http.post(baseurl + baseendpoint + '/tags/venue/' + venueid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TagsBase.bulkMailChimp = function (listid, apikey, data) {
      return $http.post(baseurl + baseendpoint + '/mailchimp/lists/' + listid + '/' + apikey, data, {
        headers: {
          'x-access-token': token
        }
      });

    };

    TagsBase.getMocks = function () {
      return $http.get('./images/mockdata/MOCK_DATA.json');
    };
    
    return TagsBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.factory:GoogleAnalyticsApi
   *
   * @description
   *
   */

  GoogleAnalyticsApi.$inject = ["baseurl", "baseendpoint", "$http"];
  angular
    .module('leoperations')
    .factory('GoogleAnalyticsApi', GoogleAnalyticsApi);

  function GoogleAnalyticsApi(baseurl, baseendpoint, $http) {
    var GoogleAnalyticsApiBase = {};
    GoogleAnalyticsApiBase.factoryName = 'GoogleAnalyticsApi';

    GoogleAnalyticsApiBase.getToken = function () {
      return $http.get(baseurl + baseendpoint + '/analytics/googletoken', {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    };

    GoogleAnalyticsApiBase.getPageViewsData = function (token) {
      return $http.get('https://www.googleapis.com/analytics/v3/data/ga?ids=ga%3A110558735&start-date=30daysAgo&end-date=yesterday&metrics=ga%3Apageviews&dimensions=ga%3ApagePath&access_token=' + token, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      });
    };

    GoogleAnalyticsApiBase.getOsData = function (token) {
      return $http.get('https://www.googleapis.com/analytics/v3/data/ga?ids=ga%3A110558735&start-date=30daysAgo&end-date=yesterday&metrics=ga%3Apageviews&dimensions=ga%3AoperatingSystem&access_token=' + token, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      });
    };

    GoogleAnalyticsApiBase.getBrowserData = function (token) {
      return $http.get('https://www.googleapis.com/analytics/v3/data/ga?ids=ga%3A110558735&start-date=30daysAgo&end-date=yesterday&metrics=ga%3Apageviews&dimensions=ga%3Abrowser&access_token=' + token, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      });
    };

    GoogleAnalyticsApiBase.getClickEvents = function (token) {
      return $http.get('https://www.googleapis.com/analytics/v3/data/ga?ids=ga%3A110558735&start-date=30daysAgo&end-date=yesterday&metrics=ga%3AtotalEvents&dimensions=ga%3AeventCategory%2Cga%3AeventAction%2Cga%3AeventLabel&access_token=' + token, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      });
    };

    return GoogleAnalyticsApiBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name cloudservicetypes.factory:Cloudservicetype
   *
   * @description
   *
   */
  Cloudservicetype.$inject = ["baseurl", "$http", "Auth", "adminbase"];
  angular
    .module('leoperations')
    .factory('Cloudservicetype', Cloudservicetype);

  function Cloudservicetype(baseurl, $http, Auth, adminbase) {
    var CloudservicetypeBase = {},
      token = '';
    CloudservicetypeBase.someValue = 'Cloudservicetype';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    CloudservicetypeBase.getCloudServiceTypes = function () {
      return $http.get(adminbase + '/admin-web/v1'  + '/cloudservicetypes', {
        headers: {
          'x-access-token': token
        }
      });
    };

    CloudservicetypeBase.createCloudServiceType = function (data) {
      return $http.post(adminbase + '/admin-web/v1' + '/cloudservicetypes', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    CloudservicetypeBase.getCloudServiceTypeById = function (id) {
      return $http.get(adminbase + '/admin-web/v1'+ '/cloudservicetypes/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    CloudservicetypeBase.udpateCloudServiceType = function (id, data) {
      return $http.put(adminbase + '/admin-web/v1' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    CloudservicetypeBase.deleteCloudServiceType = function (id) {
      return $http.delete(adminbase + '/admin-web/v1'+ '/cloudservicetypes/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return CloudservicetypeBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name cloudservices.factory:Cloudservices
   *
   * @description
   *
   */
  Cloudservices.$inject = ["baseurl", "$http", "Auth", "adminbase"];
  angular
    .module('leoperations')
    .factory('Cloudservices', Cloudservices);

  function Cloudservices(baseurl, $http, Auth, adminbase) {
    var CloudservicesBase = {},
      token = '';
    CloudservicesBase.someValue = 'Cloudservices';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    CloudservicesBase.getCloudServices = function (venueid) {
      return $http.get(adminbase + '/admin-web/v1' + '/venues/' + venueid + '/cloudservices', {
        headers: {
          'x-access-token': token
        }
      });
    };

    CloudservicesBase.createCloudService = function (venueid, data) {
      return $http.post(adminbase + '/admin-web/v1' + '/venues/' + venueid + '/cloudservices/', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    CloudservicesBase.getCloudServiceById = function (venueid, cloudservicesid) {
      return $http.get(adminbase + '/admin-web/v1' + '/venues/' + venueid + '/cloudservices/' + cloudservicesid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    CloudservicesBase.getCloudServiceByName = function (venueid, cloudservice) {
      return $http.get(adminbase + '/admin-web/v1' + '/venues/' + venueid + '/cloudservices/type/' + cloudservice, {
        headers: {
          'x-access-token': token
        }
      });
    };

    CloudservicesBase.udpateCloudService = function (venueid, cloudservicesid, data) {
      return $http.put(adminbase + '/admin-web/v1' + '/venues/' + venueid + '/cloudservices/' + cloudservicesid, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    CloudservicesBase.deleteCloudService = function (venueid, cloudservicesid) {
      return $http.delete(adminbase + '/admin-web/v1' + '/venues/' + venueid + '/cloudservices/' + cloudservicesid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return CloudservicesBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.factory:Auth
   *
   * @description
   *
   */
  Auth.$inject = ["$cookies", "$http", "baseurl", "baseendpoint"];
  angular
    .module('leoperations')
    .factory('Auth', Auth)
    .run(["$rootScope", "Auth", "$cookies", "baseurl", "adminbase", function ($rootScope, Auth, $cookies, baseurl, adminbase) {
      var rootScope = $rootScope;
      //static url for images
      rootScope.staticUrl = baseurl + '/';
      rootScope.adminStaticUrl = adminbase + '/';

      rootScope.$on('$stateChangeStart', function (event, toState) {
        var requireLogin = toState.data.requireLogin;
        if ($cookies.get('leData')) {
          Auth.setIslogged(true);
        }
        if (requireLogin && Auth.getIslogged() === false) {
          event.preventDefault();
        }
      });
    }]);

  function Auth($cookies, $http, baseurl, baseendpoint) {
    var AuthBase = {},
        isLogged = false;

    AuthBase.factoryName = 'Auth';

    AuthBase.getIslogged = function () {
      return isLogged;
    };

    AuthBase.setIslogged = function (state) {
      isLogged = state;
    };

    AuthBase.getAuthData = function () {
      var data;

      if ($cookies.get('unifitoken')) {
        $cookies.remove('unifitoken');
      }

      if ($cookies.get('leData')) {
        data = $cookies.getObject('leData');
      }

      return data;
    };

    AuthBase.login = function (data) {
      return $http.post(baseurl + baseendpoint + '/operators/session', data);
    };
    return AuthBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.factory:ApiSettingsFactory
   *
   * @description
   *
   */
  ApiSettingsFactory.$inject = ["baseurl", "baseendpoint", "$http", "$base64", "Auth"];
  angular
    .module('leoperations')
    .factory('ApiSettingsFactory', ApiSettingsFactory);

  function ApiSettingsFactory(baseurl, baseendpoint, $http, $base64, Auth) {
    var ApiSettingsFactoryBase = {};

    ApiSettingsFactoryBase.factoryName = 'Api Settings';

    ApiSettingsFactoryBase.someValue = 'ApiSettingsFactory';
    ApiSettingsFactoryBase.someMethod = function () {
      return 'ApiSettingsFactory';
    };

    // AccessPoints

    ApiSettingsFactoryBase.createAp = function (venueId, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgid = orgid;
      return $http.post(baseurl + baseendpoint + '/apoints/venues/' + venueId+ '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.updateAp = function (id, venueId, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgid = orgid;
      return $http.put(baseurl + baseendpoint + '/apoints/' + id + '/venues/' + venueId+ '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.getAp = function (id, venueId) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/apoints/' + id + '/venues/' + venueId+ '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.deleteAp = function (id, venueId) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.delete(baseurl + baseendpoint + '/apoints/' + id + '/venues/' + venueId+ '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.getAps = function (venueId) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/apoints/venues/' + venueId+ '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };
    
    ApiSettingsFactoryBase.getApByZone = function (venueId, zoneId) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/apoints/venues/' + venueId +'/zones/' + zoneId+ '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.updateApsZone = function (data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgId = orgid;
      return $http.post(baseurl + baseendpoint + '/apoints/updateapzone', data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // Zones

    ApiSettingsFactoryBase.createZone = function (venueId, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgid = orgid;
      return $http.post(baseurl + baseendpoint + '/zones/venues/' + venueId + '?org=' + orgid, data , {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.updateZone = function (id, venueId, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;

      data.orgid = orgid;

      return $http.put(baseurl + baseendpoint + '/zones/' + id + '/venues/' + venueId + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.getZone = function (id, venueId) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/zones/' + id + '/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.deleteZone = function (id, venueId) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.delete(baseurl + baseendpoint + '/zones/' + id + '/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.getZones = function (venueId) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      //console.log(token);
      return $http.get(baseurl + baseendpoint + '/zones/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };
    return ApiSettingsFactoryBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.factory:Api
   *
   * @description
   *
   */
  Api.$inject = ["baseurl", "baseendpoint", "adminbase", "$http", "$base64", "Auth", "$cookies"];
  angular
    .module('leoperations')
    .factory('Api', Api);

  function Api(baseurl, baseendpoint, adminbase, $http, $base64, Auth, $cookies) {
    var ApiBase = {};
    var adminEndpoint = '/admin-web/v1';

    ApiBase.factoryName = 'Api';

    // Images

    ApiBase.updateImage = function (id, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(baseurl + baseendpoint + '/images/' + id + '/organisations/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getImage = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/images/' + id + '/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getSingleImage = function (id,venue) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/images/' + id + '/venues/' + venue, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.deleteImage = function (id, venueid) {
      var token = Auth.getAuthData().token;
      return $http.delete(baseurl + baseendpoint + '/images/' + id + '/venues/' + venueid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getImages = function () {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/images/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getImagesVenue = function (venueid) {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + baseendpoint + '/images/venues/' + venueid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // Venues

    ApiBase.createVenue = function (data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(adminbase + '/admin-web/v1' + '/venues/organisations/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.createBulkVenue = function (data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(adminbase + '/admin-web/v1' + '/venues/bulk/organisations/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateVenue = function (id, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.put(adminbase + '/admin-web/v1' + '/venues/' + id + '/organisations/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getVenue = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint + '/venues/' + id + '/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.deleteVenue = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.delete(adminbase + '/admin-web/v1' + '/venues/' + id + '/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getVenues = function () {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint + '/venues/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    //events

    ApiBase.createEvent = function (data, id) {
      var token = Auth.getAuthData().token;
      return $http.post(baseurl + baseendpoint + '/events/venues/' + id, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateEvent = function (eventid, venueid, data) {
      var token = Auth.getAuthData().token;
      return $http.put(baseurl + baseendpoint + '/events/' + eventid + '/venues/' + venueid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getAllEvents = function () {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/events' + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getAllEventsByVenue = function (venueid) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/events/venues/' + venueid + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getEvent = function (eventid, venueid) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/events/' + eventid + '/venues/' + venueid + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.deleteEvent = function (eventid, venueid) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.delete(baseurl + baseendpoint + '/events/' + eventid + '/venues/' + venueid + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.checkSSIDName = function (startTime, endTime, data) {
      var token = Auth.getAuthData().token;
      return $http.post(baseurl + baseendpoint + '/events/checkssidname/' + startTime + '/' + endTime, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.checkEventCount = function (startTime, endTime, data) {
      var token = Auth.getAuthData().token;

      return $http.post(baseurl + baseendpoint + '/events/checkssidcount/' + startTime + '/' + endTime, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getAccessPoints = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint +'/apoints/venues/' + id + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getAccessPoint = function (venueid, apid) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint + '/apoints/' + apid + '/venues/' + venueid + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateAccesspoint = function (venueid, apid, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgid = orgid;
      return $http.put(adminbase + adminEndpoint + '/apoints/' + apid + '/venues/' + venueid + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.addAccessPoint = function (id, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgid = orgid;
      return $http.post(adminbase + adminEndpoint + '/apoints/venues/' + id + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiBase.bulkAccessPoint = function (id, providerid, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgid = orgid;
      return $http.post(adminbase + adminEndpoint + '/apoints/bulk/venues/' + id + '/provider/' + providerid + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.deleteAccessPoint = function (apid, venueid) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.delete(adminbase + adminEndpoint + '/apoints/' + apid + '/venues/' + venueid + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateApsZone = function (data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(adminbase + adminEndpoint + '/apoints/addtozone' + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.removeApsZone = function (data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(adminbase + adminEndpoint + '/apoints/removefromzone' + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getZones = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint + '/zones/venues/' + id + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiBase.getApByZone = function (venueId, zoneId) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint  + '/apoints/venues/' + venueId +'/zones/' + zoneId+ '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.newZone = function (id, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(adminbase + adminEndpoint  + '/zones/venues/' + id + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiBase.bulkZone = function (id, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(adminbase + adminEndpoint  + '/zones/bulk/venues/' + id + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getZone = function (zoneid, venueid) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint  + '/zones/' + zoneid + '/venues/' + venueid + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.deleteZone = function (zoneid, venueid) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.delete(adminbase + adminEndpoint  + '/zones/' + zoneid + '/venues/' + venueid + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateZone = function (zoneid, venueid, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.put(adminbase + adminEndpoint  + '/zones/' + zoneid + '/venues/' + venueid + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };


    ApiBase.getNetworkTypes = function (venueid) {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint  + '/networktype/venues/' + venueid, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    ApiBase.createNetworkType = function (venueid, data) {
      var token = Auth.getAuthData().token;
      return $http.post(adminbase + adminEndpoint  + '/networktype/venues/' + venueid, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    ApiBase.createBulkNetworkType = function (venueid, data) {
      var token = Auth.getAuthData().token;
      return $http.post(adminbase + adminEndpoint  + '/networktype/bulk/venues/' + venueid, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    ApiBase.getNetworkType = function (typeid, venueid) {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint  + '/networktype/' + typeid + '/venues/' + venueid, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    ApiBase.updateNetworkType = function (typeid, venueid, data) {
      var token = Auth.getAuthData().token;
      return $http.put(adminbase + adminEndpoint  +'/networktype/' + typeid + '/venues/' + venueid, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    ApiBase.deleteNetworkType = function (typeid, venueid) {
      var token = Auth.getAuthData().token;
      return $http.delete(adminbase + adminEndpoint  + '/networktype/' + typeid + '/venues/' + venueid, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    // Orgoperators

    ApiBase.createOperator = function (data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(adminbase + adminEndpoint + '/operators/organisations/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.resendEmail = function (data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgId = orgid;
      return $http.post(adminbase + adminEndpoint + '/operators/resend/' + data.id, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getOperators = function () {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint + '/operators/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getOperator = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint + '/operators/' + id + '/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateOperator = function (id, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgId = orgid;
      return $http.put(adminbase + adminEndpoint + '/operators/' + id, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateOperatorPassword = function (id, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgId = orgid;
      return $http.put(adminbase + adminEndpoint + '/operators/' + id + '?password=true', data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.deleteOperator = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.delete(adminbase + adminEndpoint + '/operators/' + id + '/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // Infrastructure
    ApiBase.getInfrastructures = function (id) {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint + '/infrastructure/venues/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.addInfrastructure = function (id, data) {
      var token = Auth.getAuthData().token;
      return $http.post(adminbase + adminEndpoint + '/infrastructure/venues/' + id, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getInfrastructure = function (infraid, venueid) {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint + '/infrastructure/' + infraid + '/venues/' + venueid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateInfrastructure = function (infraid, venueid, data) {
      var token = Auth.getAuthData().token;
      return $http.put(adminbase + adminEndpoint + '/infrastructure/' + infraid + '/venues/' + venueid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    //Survey
    ApiBase.createSurvey = function (data, id) {
      var token = Auth.getAuthData().token;
      return $http.post(baseurl + baseendpoint + '/survey/event/' + id, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getSurveyById = function (eventid, surveyid) {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + baseendpoint + '/survey/' + surveyid + '/event/' + eventid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateSurvey = function (eventid, surveyid, data) {
      var token = Auth.getAuthData().token;
      return $http.put(baseurl + baseendpoint + '/survey/' + surveyid + '/event/' + eventid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.deleteSurvey = function (eventid, surveyid) {
      var token = Auth.getAuthData().token;
      return $http.delete(baseurl + baseendpoint + '/survey/' + surveyid + '/event/' + eventid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    //Mailchimp

    ApiBase.createList = function (data) {
      var token = Auth.getAuthData().token;
      return $http.post(baseurl + baseendpoint + '/mailchimp/lists/', data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getLists = function (apiKey) {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + baseendpoint + '/mailchimp/lists/' + apiKey, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getListById = function (listid, apiKey) {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + baseendpoint + '/mailchimp/lists/' + listid + '/' + apiKey, {
        headers: {
          'x-access-token': token
        }
      });
    };



    // Settings and Types
    ApiBase.getRoles = function () {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint + '/roles', {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getCategories = function (provider) {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint + '/categories/provider/' + provider, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getSocialLogins = function (provider) {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint + '/sociallogintypes/provider/' + provider, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getSplashPageTypes = function (provider) {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint + '/splashtypes/provider/' + provider , {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getProviders = function () {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint + '/providers' , {
        headers: {
          'x-access-token': token
        }
      });
    };

    //Support, send a ticket

    ApiBase.createTicket = function (data) {
      var token = Auth.getAuthData().token;
      return $http.post(baseurl + baseendpoint + '/support/ticket/', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    // OrgInfrastructure

    ApiBase.createOrgInfrastructure = function (data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(adminbase + '/admin-web/v1' + '/infrastructure/organisation/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateOrgInfrastructure = function (id, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.put(adminbase + '/admin-web/v1' + '/infrastructure/' + id + '/organisation/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getOrgInfrastructure = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + '/admin-web/v1' + '/infrastructure/' + id + '/organisation/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getOrgInfrastructures = function () {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + '/admin-web/v1' + '/infrastructure/organisation/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.deleteOrgInfrastructure = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.delete(adminbase + '/admin-web/v1' + '/infrastructure/'+id+'/organisation/'+orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getSites = function (id, data) {
      var token = Auth.getAuthData().token;
      var unifitoken = $cookies.get('unifitoken');
      if (unifitoken) {
        data.cookie = unifitoken;
      }
      return $http.post(adminbase + '/admin-web/v1' + '/onboarding/provider/'+id+'/sites', data,{
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.createBulkVenues = function (id, data) {
      var token = Auth.getAuthData().token;
      var unifitoken = $cookies.get('unifitoken');
      data.cookie = unifitoken;
      return $http.post(adminbase + '/admin-web/v1' + '/onboarding/provider/'+id+'/sites/create', data,{
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getUserGroups = function (id, data) {
      var token = Auth.getAuthData().token;
      var unifitoken = $cookies.get('unifitoken');
      data.cookie = unifitoken;
      return $http.post(adminbase + '/admin-web/v1' + '/onboarding/provider/'+id+'/infra/usergroups', data,{
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getWlans = function (id, data) {
      var token = Auth.getAuthData().token;
      var unifitoken = $cookies.get('unifitoken');
      data.orgid = Auth.getAuthData().operator.organisation;
      data.cookie = unifitoken;
      return $http.post(adminbase + '/admin-web/v1' + '/onboarding/provider/'+id+'/infra/wlangroups', data,{
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getInfraOrgs = function (id, data) {
      var token = Auth.getAuthData().token;
      var unifitoken = $cookies.get('unifitoken');
      data.cookie = unifitoken;
      return $http.post(adminbase + '/admin-web/v1' + '/onboarding/provider/'+id+'/infra/orginisations', data,{
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getApoints = function (id, data) {
      var token = Auth.getAuthData().token;
      var unifitoken = $cookies.get('unifitoken');
      data.cookie = unifitoken;
      data.orgid = Auth.getAuthData().operator.organisation;
      console.log(data);
      return $http.post(adminbase + '/admin-web/v1' + '/onboarding/provider/'+id+'/infra/apoints', data,{
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.setGuestSettings = function (id, data) {
      var token = Auth.getAuthData().token;
      var unifitoken = $cookies.get('unifitoken');
      data.cookie = unifitoken;
      return $http.post(adminbase + '/admin-web/v1' + '/onboarding/provider/'+id+'/createinfra/guestsettings', data,{
        headers: {
          'x-access-token': token
        }
      });
    };
    return ApiBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.factory:ApiAnalytic
   *
   * @description
   *
   */
  ApiAnalytic.$inject = ["baseurl", "baseendpoint", "analyticsbase", "analyticsendpoint", "$http", "$base64", "Auth"];
  angular
    .module('leoperations')
    .factory('ApiAnalytic', ApiAnalytic);

  function ApiAnalytic(baseurl, baseendpoint, analyticsbase, analyticsendpoint, $http, $base64, Auth) {
    var ApiAnalyticBase = {};
    ApiAnalyticBase.someValue = 'ApiAnalytic';
    //analytics Api
    ApiAnalyticBase.getClientsOnlineByVenue = function (venueid, provider) {
      var orgid = Auth.getAuthData().operator.organisation;
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/liveanalytic/venue/' + venueid + '/provider/' + provider + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getClientsOnlineByEvent = function (venueid, provider, eventid) {
      var orgid = Auth.getAuthData().operator.organisation;
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/liveanalytic/venue/' + venueid + '/provider/' + provider + '/event/' + eventid + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // Dwell Time
    ApiAnalyticBase.getDwellTimeByVenue = function (venueid, start, end) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(analyticsbase + analyticsendpoint + '/dwelltime/venue/' + venueid + '?org=' + orgid + '&start=' + start + '&end=' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getDwellTimeByEvent = function (venueid, eventid, start, end) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(analyticsbase + analyticsendpoint + '/dwelltime/venue/' + venueid + '/event/' + eventid + '?org=' + orgid + '&start=' + start + '&end=' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    //User Journey
    ApiAnalyticBase.getUserJourney = function (venueid, user, date) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/userjourney/venue/' + venueid + '/user/' + user + '?date=' + date, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // User Impressions
    ApiAnalyticBase.getImpressionsByVenue = function (venueid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/performance/impressions/venue/' + venueid + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getImpressionsByEvent = function (eventid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/performance/impressions/event/' + eventid + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // Footfall
    ApiAnalyticBase.getFootFallByVenue = function (venueid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/footfall/venue/' + venueid + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getFootFallByEvent = function (eventid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/footfall/event/' + eventid + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getLoginDetails = function (venueId, eventId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/login/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getLoginDetailsByVenue = function (venueId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/loginzone/' + venueId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getLoginDetailsByZone = function (venueId, zoneId, eventId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/loginzone/' + zoneId + '/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getGenderDetails = function (venueId, eventId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/gender/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getGenderDetailsByZone = function (venueId, zoneId, eventId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/genderzone/' + zoneId + '/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getSocialLoginAnalyticsByZone = function (venueId, zoneId, eventId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/loginzone/' + zoneId + '/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getValidatedEmailsByVenue = function (venueId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/e-mails/' + venueId, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getValidatedEmailsByEvent = function (venueId, eventId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/e-mails/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getOnlineUsers = function (eventId) {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + baseendpoint + '/enduser/currentusers/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getLoginByDay = function (eventId, venueId, start, end) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/' + eventId + '/userlogin/' + start + '/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getValidatedEmails = function (eventId, venueId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/e-mails/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getLoginByDay = function (eventId, venueId, start, end) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/' + eventId + '/userlogin/' + start + '/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getMostFrequentVisitors = function (venueId, numUsers, starttime, endtime) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/mostfrequentvisitors/' + numUsers + '/' + starttime + '/' + endtime, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getMostFrequentVisitorsByEvent = function (eventId, numUsers, starttime, endtime) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + eventId + '/mostfrequentvisitorsbyevent/' + numUsers + '/' + starttime + '/' + endtime, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getUserProfile = function (venueId, userId) {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + baseendpoint + '/profile/venue/' + venueId + '/user/' + userId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getAllNewReturningVisitors = function (venueId, starttime, endtime) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/allnewreturningvisitors/' + starttime + '/' + endtime, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getAllNewReturningVisitorsForEvents = function (eventId, starttime, endtime) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + eventId + '/allnewreturningvisitorsevents/' + starttime + '/' + endtime, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getUsersJourney = function (venueid, providerid, macAddress, date) {
      console.log('called api');
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(analyticsbase + analyticsendpoint + '/usersjourney/venue/' + venueid + '/provider/' + providerid + '?MacAddress=' + macAddress + '&date=' + date + '&org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getLoginByVenue = function (venueid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/performance/login/venue/' + venueid + '/start/' + start.getTime() + '/end/' + end.getTime(), {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getLoginByVenue2 = function (venueid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/performance/login/venue/' + venueid + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getLoginByEvent = function (eventid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/performance/login/event/' + eventid + '/start/' + start.getTime() + '/end/' + end.getTime(), {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getEmailCountyListEvent = function (venueID, eventID, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + baseendpoint + '/enduser/county/venue/' + venueID + '/event/' + eventID + '/' + start + '/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getEmailCountyListVenue = function (venueID, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + baseendpoint + '/enduser/county/venue/' + venueID + '/' + start + '/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getLoginAnalyticsVenue = function (venue, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/login/venue/' + venue + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getLoginAnalyticsEvent = function (venue, eventid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/login/venue/' + venue + '/event/' + eventid + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getUserProfilesVenue = function (venue, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/customerprofiles/venue/' + venue + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getUserProfilesEvent = function (venue, eventid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/customerprofiles/venue/' + venue + '/event/' + eventid + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getOnlineUsersVenue = function (venue, provider) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/customersonline/venue/' + venue + '/provider/' + provider, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getOnlineUsersEvent = function (venue, eventid, provider) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(analyticsbase + analyticsendpoint + '/customersonline/venue/' + venue + '/event/' + eventid + '/provider/' + provider + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.checkUserOnline = function (venue, provider, data) {
      var token = Auth.getAuthData().token;
      return $http.post(analyticsbase + analyticsendpoint + '/customeronline/venue/' + venue + '/provider/' + provider, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getApActivity = function (venue, date) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/apactivity/venue/' + venue + '/date/' + date, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getBusyAP = function (venue, date) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/busyap/venue/' + venue + '/date/' + date, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getJourney = function (venue, date) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/journey/venue/' + venue + '/date/' + date, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // Map Endpoints
    ApiAnalyticBase.createMap = function (map) {
      var token = Auth.getAuthData().token;
      return $http.post(analyticsbase + analyticsendpoint + '/map', map, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getMapsByVenue = function (venue) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/map/venue/' + venue, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getMap = function (venue, id) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/map/venue/' + venue +'/map/'+id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.editMap = function (map, venue, id) {
      var token = Auth.getAuthData().token;
      return $http.put(analyticsbase + analyticsendpoint + '/map/venue/' + venue +'/map/'+id, map, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.deleteMap = function (venue, id) {
      var token = Auth.getAuthData().token;
      return $http.delete(analyticsbase + analyticsendpoint + '/map/venue/' + venue +'/map/'+id, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getMapForDay = function (type, venue, date, macarray) {
      var token = Auth.getAuthData().token;
      return $http.post(analyticsbase + analyticsendpoint + '/map/'+type+'/venue/' + venue +'/date/'+date.getTime(), {macarray: macarray}, {
        headers: {
          'x-access-token': token
        }
      });
    };



    return ApiAnalyticBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Oprrator Analytics Factory
   *
   * @description  For communication with the liquidedge analytics api
   *
   */
  Analytics.$inject = ["analyticsbase", "$http", "$base64", "Auth", "$cookies"];
  angular
    .module('leoperations')
    .factory('AnalyticsApi', Analytics);

  function Analytics(analyticsbase, $http, $base64, Auth, $cookies) {
    var AnalyticsBase = {};
    AnalyticsBase.someValue = 'Analytics';
    var endpoint = '/analytics-web/v1';
    var getHeader = function() {
      var token = Auth.getAuthData().token;
      var unifitoken = $cookies.get('unifitoken');
      var header = {
        'x-access-token': token
      };
      if (unifitoken) {
        header.unifitoken = unifitoken;
      }
      return header;
    };

    // Live Anayltics
    AnalyticsBase.getClientsOnlineByVenue = function (venueid, provider) {
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(analyticsbase + endpoint + '/liveanalytic/venue/' + venueid + '/provider/' + provider + '?org=' + orgid, {
        headers: getHeader()
      });
    };
    AnalyticsBase.getClientsOnlineByEvent = function (venueid, provider, eventid) {
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(analyticsbase + endpoint + '/liveanalytic/venue/' + venueid + '/provider/' + provider + '/event/' + eventid + '?org=' + orgid, {
        headers: getHeader()
      });
    };

    // Dwell Time
    AnalyticsBase.getDwellTimeByVenue = function (venueid, start, end) {
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(analyticsbase + endpoint + '/dwelltime/venue/' + venueid + '?org=' + orgid + '&start=' + start + '&end=' + end, {
        headers: getHeader()
      });
    };
    AnalyticsBase.getDwellTimeByEvent = function (venueid, eventid, start, end) {
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(analyticsbase + endpoint + '/dwelltime/venue/' + venueid + '/event/' + eventid + '?org=' + orgid + '&start=' + start + '&end=' + end, {
        headers: getHeader()
      });
    };

    //User Journey
    AnalyticsBase.getUserJourney = function (venueid, user, date) {
      return $http.get(analyticsbase + endpoint + '/userjourney/venue/' + venueid + '/user/' + user + '?date=' + date, {
        headers: getHeader()
      });
    };

    return AnalyticsBase;
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('events')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.events', {
        url: '/events',
        templateUrl: 'events/views/events.tpl.html',
        controller: 'EventsCtrl',
        cache: false,
        controllerAs: 'events'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('eventcreation')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.eventcreation', {
        url: '/eventcreation',
        templateUrl: 'eventcreation/views/eventcreation.tpl.html',
        controller: 'EventcreationCtrl',
        controllerAs: 'eventcreation'
      })
      .state('admin.eventcreation.evcsetup', {
        url: '/evcsetup',
        templateUrl: 'eventcreation/views/evcsetup.tpl.html'
      })
      .state('admin.eventcreation.evcnetwork', {
        url: '/evcnetwork',
        templateUrl: 'eventcreation/views/evcnetwork.tpl.html'
      })
      .state('admin.eventcreation.evcsplash', {
        url: '/evcsplash',
        templateUrl: 'eventcreation/views/evcsplash.tpl.html'
      })
      .state('admin.eventcreation.evcfeature', {
        url: '/evcfeature',
        templateUrl: 'eventcreation/views/evcfeature.tpl.html'
      })
      .state('admin.eventcreation.evcconfirm', {
        url: '/evcconfirm',
        templateUrl: 'eventcreation/views/evcconfirm.tpl.html'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('eventanalytics')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.eventanalytics', {
        url: '/eventanalytics/:venueid/:eventid/:class',
        templateUrl: 'eventanalytics/views/eventanalytics.tpl.html',
        controller: 'EventanalyticsCtrl',
        controllerAs: 'eventanalytics'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('event')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.event', {
        url: '/event/:eventid/:venueid',
        templateUrl: 'event/views/event.tpl.html',
        controller: 'EventCtrl',
        controllerAs: 'event'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editzone')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editzone', {
        url: '/editzone/:zoneid/:venueid/:orgid/:provider',
        templateUrl: 'editzone/views/editzone.tpl.html',
        controller: 'EditzoneCtrl',
        controllerAs: 'editzone'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editvenuemap')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editvenuemap', {
        url: '/editvenuemap/:venueid/map/:mapId',
        templateUrl: 'editvenuemap/views/editvenuemap.tpl.html',
        controller: 'EditvenuemapCtrl',
        controllerAs: 'editvenuemap'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editvenue')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editvenue', {
        url: '/editvenue/:venueid',
        templateUrl: 'editvenue/views/editvenue.tpl.html',
        controller: 'EditvenueCtrl',
        controllerAs: 'editvenue'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editsurvey')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editsurvey', {
        url: '/editsurvey/:surveyid/event/:eventid',
        templateUrl: 'editsurvey/views/editsurvey.tpl.html',
        controller: 'EditsurveyCtrl',
        controllerAs: 'editsurvey'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editoperator')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editoperator', {
        url: '/editoperator/:operatorid',
        templateUrl: 'editoperator/views/editoperator.tpl.html',
        controller: 'EditoperatorCtrl',
        controllerAs: 'editoperator'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editnetworktype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editnetworktype', {
        url: '/editnetworktype/:venueid/:typeid/:provider',
        templateUrl: 'editnetworktype/views/editnetworktype.tpl.html',
        controller: 'EditnetworktypeCtrl',
        controllerAs: 'editnetworktype'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editinfrastructure')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editinfrastructure', {
        url: '/editinfrastructure/:infraid/:venueid',
        templateUrl: 'editinfrastructure/views/editinfrastructure.tpl.html',
        controller: 'EditinfrastructureCtrl',
        controllerAs: 'editinfrastructure'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editeventnew')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editeventnew', {
        url: '/editeventnew/:eventid/:venueid',
        templateUrl: 'editeventnew/views/editeventnew.tpl.html',
        controller: 'EditeventnewCtrl',
        controllerAs: 'editeventnew'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editevent')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editevent', {
        url: '/editevent/:eventid/:venueid',
        templateUrl: 'editevent/views/editevent.tpl.html',
        controller: 'EditeventCtrl',
        controllerAs: 'editevent'
      }).state('admin.editevent.editsetup', {
        url: '/editsetup',
        templateUrl: 'editevent/views/editsetup.tpl.html'
      })
      .state('admin.editevent.editnetwork', {
        url: '/editnetwork',
        templateUrl: 'editevent/views/editnetwork.tpl.html'
      })
      .state('admin.editevent.editsplash', {
        url: '/editsplash',
        templateUrl: 'editevent/views/editsplash.tpl.html'
      })
      .state('admin.editevent.confirm', {
        url: '/editconfirm',
        templateUrl: 'editevent/views/editconfirm.tpl.html'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editclservice')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editclservice', {
        url: '/editclservice/:venueid/:cloudservicesid',
        templateUrl: 'editclservice/views/editclservice.tpl.html',
        controller: 'EditclserviceCtrl',
        controllerAs: 'editclservice'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('editap')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editap', {
        url: '/editap/:apid/:venueid',
        templateUrl: 'editap/views/editap.tpl.html',
        controller: 'EditapCtrl',
        controllerAs: 'editap'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('duplicateevent')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.duplicateevent', {
        url: '/duplicateevent/:eventid/:venueid',
        templateUrl: 'duplicateevent/views/duplicateevent.tpl.html',
        controller: 'DuplicateeventCtrl',
        controllerAs: 'duplicateevent'
      }).state('admin.duplicateevent.duplicatesetup', {
        url: '/duplicatesetup',
        templateUrl: 'duplicateevent/views/duplicatesetup.tpl.html'
      })
      .state('admin.duplicateevent.duplicatenetwork', {
        url: '/duplicatenetwork',
        templateUrl: 'duplicateevent/views/duplicatenetwork.tpl.html'
      })
      .state('admin.duplicateevent.duplicatesplash', {
        url: '/duplicatesplash',
        templateUrl: 'duplicateevent/views/duplicatesplash.tpl.html'
      })
      .state('admin.duplicateevent.confirm', {
        url: '/duplicateconfirm',
        templateUrl: 'duplicateevent/views/duplicateconfirm.tpl.html'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name leoperations.directive:backImg
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="leoperations">
       <file name="index.html">
        <back-img></back-img>
       </file>
     </example>
   *
   */
  angular
    .module('leoperations')
    .directive('backImg', backImg);

  function backImg() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'directives/back-img-directive.tpl.html',
      replace: false,
      controllerAs: 'backImg',
      controller: function () {
        // var vm = this;
        // vm.name = 'backImg';
      },
      link: function (scope, element, attrs) {
        var url = attrs.backImg;
        element.css({
            'background-image': 'url(' + url +')',
            'background-size' : 'cover'
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.constant:baseurl
   *
   * @description
   *
   */
  angular
    .module('leoperations')
    .constant('baseurl', 'https://api.liquidedge.io/operator-api');

}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.constant:baseendpoint
   *
   * @description
   *
   */
  angular
    .module('leoperations')
    .constant('baseendpoint', '/operator-web/v1');
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.constant:analyticsendpoint
   *
   * @description
   *
   */
  angular
    .module('leoperations')
    .constant('analyticsendpoint', '/analytics-web/v1');
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.constant:adminbase
   *
   * @description
   *
   */
  angular
    .module('leoperations')
    .constant('analyticsbase', 'https://api.liquidedge.io/analytics-api');
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.constant:adminbase
   *
   * @description
   *
   */
  angular
    .module('leoperations')
    .constant('adminbase', 'https://api.liquidedge.io/admin-api');

}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('cloudservices')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.cloudservices', {
        url: '/cloudservices/:venueid/:cloudservicesid',
        templateUrl: 'cloudservices/views/cloudservices.tpl.html',
        controller: 'CloudservicesCtrl',
        controllerAs: 'cloudservices'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('admin')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin', {
        url: '/admin',
        templateUrl: 'admin/views/admin.tpl.html',
        controller: 'AdminCtrl',
        cache: false,
        controllerAs: 'admin',
        data: {
          requireLogin: true
        }
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addvenuemap')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addvenuemap', {
        url: '/addvenuemap/:venueid',
        templateUrl: 'addvenuemap/views/addvenuemap.tpl.html',
        controller: 'AddvenuemapCtrl',
        controllerAs: 'addvenuemap'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addzone')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addzone', {
        url: '/addzone/:venueid/:provider',
        templateUrl: 'addzone/views/addzone.tpl.html',
        controller: 'AddzoneCtrl',
        controllerAs: 'addzone'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addsurvey')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addsurvey', {
        url: '/addsurvey/event/:eventid',
        templateUrl: 'addsurvey/views/addsurvey.tpl.html',
        controller: 'AddsurveyCtrl',
        controllerAs: 'addsurvey'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addclservice')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addclservice', {
        url: '/addclservice/:venueid',
        templateUrl: 'addclservice/views/addclservice.tpl.html',
        controller: 'AddclserviceCtrl',
        controllerAs: 'addclservice'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addap')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addap', {
        url: '/addap/:venueid/:provider',
        templateUrl: 'addap/views/addap.tpl.html',
        controller: 'AddapCtrl',
        controllerAs: 'addap'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('addInfrastructure')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addInfrastructure', {
        url: '/add-infrastructure/:venueid',
        templateUrl: 'add-infrastructure/views/add-infrastructure.tpl.html',
        controller: 'AddInfrastructureCtrl',
        controllerAs: 'addInfrastructure'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$urlRouterProvider"];
  angular
    .module('leoperations')
    .config(config);

  function config($urlRouterProvider) {
    $urlRouterProvider.otherwise('/home');
  }
}());
