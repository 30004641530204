<div class="newvenueanalytics">
  <div class="panel panel-default">
    <div class="panel-heading">
      <div class="row row-align">
        <h3>Venue Analytics: {{newvenueanalytics.venue.venuetitle}}</h3>
      </div>
    </div>
    <div class="panel-body">
      <ul class="nav nav-tabs">
        <li ng-class="{'active': (newvenueanalytics.tab === 'live')}"><a href="" ng-click="newvenueanalytics.switchTab('live')">Live</a></li>
        <li ng-class="{'active': (newvenueanalytics.tab === 'historic')}"><a href="" ng-click="newvenueanalytics.switchTab('historic')">Historic</a></li>
        <li ng-class="{'active': (newvenueanalytics.tab === 'users')}"><a href="" ng-click="newvenueanalytics.switchTab('users')">Customer Profiles</a></li>
      </ul>
      <div ng-show="newvenueanalytics.tab === 'live'">
        <div class="row">
          <div class="col-sm-6">
            <h3 class="margin-analytics-xs">Activity</h3>
            <canvas id="line" class="chart chart-line" chart-data="newvenueanalytics.liveDataChart.data" chart-labels="newvenueanalytics.liveDataChart.labels" chart-series="newvenueanalytics.liveDataChart.series" chart-options="newvenueanalytics.liveDataChart.options" chart-colors="newvenueanalytics.chartColors">
            </canvas>
          </div>
          <div class="col-sm-6">
            <h3 class="margin-analytics-xs">Activity By Zone
              <button ng-click="newvenueanalytics.showActivityZoneTable=!newvenueanalytics.showActivityZoneTable" class="btn btn-danger pull-right">
                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="newvenueanalytics.showActivityZoneTable"></i>
                <i class="fa fa-table" aria-hidden="true" ng-show="!newvenueanalytics.showActivityZoneTable"></i>
              </button>
            </h3>
            <div class="cWrap">
              <div ng-if="!newvenueanalytics.showActivityZoneTable" class="animated-class chartFront">
                <canvas id="base" class="chart chart-bar" chart-data="newvenueanalytics.connectedUsersBarChart.data" chart-labels="newvenueanalytics.connectedUsersBarChart.labels" chart-options="newvenueanalytics.connectedUsersBarChart.options" chart-series="newvenueanalytics.connectedUsersBarChart.series" chart-colors="newvenueanalytics.chartColors" chart-click="newvenueanalytics.setShowActivityZoneTable">
              </div>
              <div class="animated-class" ng-if="newvenueanalytics.showActivityZoneTable">
                <table class="table table-responsive table-striped table-bordered">
                  <thead>
                    <tr>
                      <th ng-repeat="labels in newvenueanalytics.connectedTable.zoneheaders">{{labels}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ng-repeat="item in newvenueanalytics.connectedTable.zones | orderBy:'-count'">
                      <td>{{item.name}}</td>
                      <td>{{item.count}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ng-show="newvenueanalytics.tab === 'historic'">
        <div class="form-row margin_top dateFix">
          <div class="form-group text-box long cell cell-pad">
            <label for="startdate" class="control-label pull-left">START DATE</label>
            <input ng-change="newvenueanalytics.refresh()" ng-click="newvenueanalytics.openCalSt()" type="text" class="form-control" min-date="newvenueanalytics.minDate" max-date="newvenueanalytics.endtime" name="startDate" datepicker-popup="{{newvenueanalytics.dateFormat}}" ng-model="newvenueanalytics.starttime" is-open="newvenueanalytics.status.openedSt" datepicker-options="newvenueanalytics.dateOptions" ng-required="true" close-text="Close" />
          </div>
          <div class="short cell">
            <button type="button" class="btn btn-date" ng-click="newvenueanalytics.openCalSt()">
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </div>
          <div class="form-group text-box long cell cell-pad">
            <label for="endtime" class="control-label pull-left">END DATE</label>
            <input ng-change="newvenueanalytics.refresh()" ng-click="newvenueanalytics.openCalEnd()" type="text" class="form-control" min-date="newvenueanalytics.starttime" max-date="newvenueanalytics.today" datepicker-popup="{{newvenueanalytics.dateFormat}}" ng-model="newvenueanalytics.endtime" is-open="newvenueanalytics.status.openedEnd" datepicker-options="newvenueanalytics.dateOptions" ng-required="true" close-text="Close" />
          </div>
          <div class="short cell">
            <button type="button" class="btn btn-date" ng-click="newvenueanalytics.openCalEnd()">
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <h3 class="margin-analytics-xs">Footfall
              <button ng-click="newvenueanalytics.showFootfallTable=!newvenueanalytics.showFootfallTable" class="btn btn-danger pull-right">
                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="newvenueanalytics.showFootfallTable"></i>
                <i class="fa fa-table" aria-hidden="true" ng-show="!newvenueanalytics.showFootfallTable"></i>
              </button>
            </h3>
            <div ng-show="newvenueanalytics.loading" class="loader">
              <img src="./images/gears.gif" alt="">
              <p>Generating Visitor Data</p>
              </dev>
            </div>
            <div class="cWrap" ng-hide="newvenueanalytics.loading">
              <div ng-if="!newvenueanalytics.showFootfallTable" class="animated-class chartFront">
                <canvas id="base" class="chart chart-bar" chart-data="newvenueanalytics.visitorsLoginData.data" chart-labels="newvenueanalytics.visitorsLoginData.labels" chart-colors="newvenueanalytics.chartColors" chart-dataset-override="newvenueanalytics.visitorsLoginData.datasetOverride" chart-options="newvenueanalytics.mixedChartOptions">
                </canvas>
              </div>
              <div class="animated-class" ng-if="newvenueanalytics.showFootfallTable">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th ng-repeat="header in newvenueanalytics.footfallTable.headers">{{header}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ng-repeat="item in newvenueanalytics.footfallTable.rows">
                      <td>{{item.type}}</td>
                      <td>{{item.newVisitors}}</td>
                      <td>{{item.returningVisitors}}</td>
                      <td>{{item.total}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-sm-2 col-sm-offset-3 col-xs-offset-2 analytics-block-sm">
            <div class="row margin-analytics-xs margin-analytics-top">
              <p>Gender</p>
              <ul class="list-unstyled gender-list">
                <li class="blueicon">
                  <strong><i class="fa fa-male" aria-hidden="true"></i> : {{newvenueanalytics.genderMale}}</strong>
                </li>
                <li class="pinkicon">
                  <strong><i class="fa fa-female" aria-hidden="true"></i> : {{newvenueanalytics.genderFemale}}</strong>
                </li>
              </ul>
            </div>
            <div class="row margin-analytics-xs">
              <p>Unique Visitors</p>
              <p class="analytictext greenicon">
                <strong><i class="fa fa-users" aria-hidden="true"></i> : {{newvenueanalytics.uniqueUsers}}</strong>
              </p>
            </div>
            <div class="row margin-analytics-xs">
              <p>Total Logins</p>
              <p class="analytictext darkicon">
                <strong><i class="fa fa-users" aria-hidden="true"></i> : {{newvenueanalytics.totalLogin}}</strong>
              </p>
            </div>
            <div class="row margin-analytics-xs">
              <p>Total Impressions</p>
              <p class="analytictext darkicon">
                <strong><i class="fa fa-mobile" aria-hidden="true"></i> : {{newvenueanalytics.totalImpressions}}</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <h3 class="margin-analytics-xs">Login Method
              <button ng-click="newvenueanalytics.showLoginTable=!newvenueanalytics.showLoginTable" class="btn btn-danger pull-right">
                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="newvenueanalytics.showLoginTable"></i>
                <i class="fa fa-table" aria-hidden="true" ng-show="!newvenueanalytics.showLoginTable"></i>
              </button>
            </h3>
            <div class="cWrap">
              <div ng-if="!newvenueanalytics.showLoginTable" class="animated-class chartFront">
                <canvas id="base" class="chart-horizontal-bar" chart-data="newvenueanalytics.horizontal.data" chart-labels="newvenueanalytics.horizontal.labels" chart-options="newvenueanalytics.horizontal.options" chart-series="newvenueanalytics.horizontal.series" chart-colors="newvenueanalytics.typeChartColors">
                </canvas>
              </div>
              <div class="animated-class" ng-if="newvenueanalytics.showLoginTable">
                <table class="table table-responsive table-striped table-bordered">
                  <thead>
                    <tr>
                      <th ng-repeat="header in newvenueanalytics.loginTable.headers">{{header}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ng-repeat="row in newvenueanalytics.loginTable.rows">
                      <td>{{row.type}}</td>
                      <td>{{row.count}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <h3 class="margin-analytics-xs">Gender Demographics
              <button ng-click="newvenueanalytics.showgenderTable=!newvenueanalytics.showgenderTable" class="btn btn-danger pull-right">
                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="newvenueanalytics.showgenderTable"></i>
                <i class="fa fa-table" aria-hidden="true" ng-show="!newvenueanalytics.showgenderTable"></i>
              </button>
            </h3>
            <div class="cWrap">
              <div ng-if="!newvenueanalytics.showgenderTable" class="animated-class chartFront">
                <canvas id="base" class="chart chart-bar" chart-data="newvenueanalytics.genderBarChart.data" chart-labels="newvenueanalytics.genderBarChart.labels" chart-options="newvenueanalytics.genderBarChart.options" chart-series="newvenueanalytics.genderBarChart.series" chart-colors="newvenueanalytics.genderChartColors">
              </div>
              <div class="animated-class" ng-if="newvenueanalytics.showgenderTable">
                <table class="table table-responsive table-striped table-bordered">
                  <thead>
                    <tr>
                      <th ng-repeat="header in newvenueanalytics.genderTable.headers">{{header}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ng-repeat="row in newvenueanalytics.genderTable.rows">
                      <td>{{row.type}}</td>
                      <td>{{row.count}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <h3 class="margin-analytics-xs">Splashpage Impressions
              <button ng-click="newvenueanalytics.showImpressionsTable=!newvenueanalytics.showImpressionsTable" class="btn btn-danger pull-right">
                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="newvenueanalytics.showImpressionsTable"></i>
                <i class="fa fa-table" aria-hidden="true" ng-show="!newvenueanalytics.showImpressionsTable"></i>
              </button>
            </h3>
            <div class="cWrap">
              <div ng-if="!newvenueanalytics.showImpressionsTable" class="animated-class chartFront">
                <canvas id="line" class="chart chart-line" chart-data="newvenueanalytics.splashLoadChart.data" chart-labels="newvenueanalytics.splashLoadChart.labels" chart-series="newvenueanalytics.splashLoadChart.series" chart-options="newvenueanalytics.splashLoadChart.options" chart-colors="newvenueanalytics.chartColors"></canvas>
              </div>
              <div class="animated-class" ng-if="newvenueanalytics.showImpressionsTable">
                <table class="table table-responsive table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Date/Time</th>
                      <th ng-repeat="series in newvenueanalytics.splashLoadChart.series">{{series}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ng-repeat="label in newvenueanalytics.splashLoadChart.labels">
                      <td>{{label}}</td>
                      <td ng-repeat="item in newvenueanalytics.splashLoadChart.data"> {{item[$parent.$index]}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <h3 class="margin-analytics-xs">Average Dwell Time
              <button ng-click="newvenueanalytics.showDwellTable=!newvenueanalytics.showDwellTable" class="btn btn-danger pull-right">
                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="newvenueanalytics.showImpressionsTable"></i>
                <i class="fa fa-table" aria-hidden="true" ng-show="!newvenueanalytics.showImpressionsTable"></i>
              </button>
            </h3>
            <div ng-show="newvenueanalytics.dwellLoading" class="loader">
              <img src="./images/gears.gif" alt="">
              <p>Generating Visitor Data</p>
              </dev>
            </div>
            <div class="cWrap" ng-hide="newvenueanalytics.dwellLoading">
              <div ng-if="!newvenueanalytics.showDwellTable" class="animated-class chartFront">
                <canvas id="dwell" class="chart chart-bar" chart-data="newvenueanalytics.dwellChart.data" chart-labels="newvenueanalytics.dwellChart.labels" chart-options="newvenueanalytics.dwellChart.options" chart-series="newvenueanalytics.dwellChart.series" chart-colors="newvenueanalytics.chartColors">
                </canvas>
              </div>
              <div class="animated-class" ng-if="newvenueanalytics.showDwellTable">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th ng-repeat="header in newvenueanalytics.dwellTable.zoneheaders">{{header}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ng-repeat="item in newvenueanalytics.dwellTable.zones | orderBy:'-time'">
                      <td>{{item.name}}</td>
                      <td>{{item.time}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row" ng-if="newvenueanalytics.tab === 'historic' && newvenueanalytics.maps.length > 0">
          <div class="col-sm-12">
            <h3 class="margin-analytics-xs">HeatMap - {{newvenueanalytics.mapType}} </h3>
            <div class="mapControls row">
              <!-- DatePicker -->
              <div class="col-sm-4 dateFix">
                <div class="form-group text-box long cell cell-pad">
                  <label class="control-label pull-left">MAP TYPE <i class="fa fa-info-circle" aria-hidden="true" tooltip-placement="right" tooltip="{{newvenueanalytics.toolTipMessage}}"></i></label>
                  <select class="form-control" ng-model="newvenueanalytics.mapType" ng-change="newvenueanalytics.typeChange()">
                    <option value="Users">Users</option>
                    <option value="Dwell">Dwell</option>
                    <option value="Active Minutes">Active Minutes</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4 dateFix">
                <div class="form-group text-box long cell cell-pad">
                  <label class="control-label pull-left">HEATMAP DATE</label>
                  <input ng-change="newvenueanalytics.mapDayChange()" ng-click="newvenueanalytics.openCalHeatMap()" type="text" class="form-control" min-date="newvenueanalytics.minDate" max-date="newvenueanalytics.today" name="startDate" datepicker-popup="{{newvenueanalytics.dateFormat}}" ng-model="newvenueanalytics.heatMapDate" is-open="newvenueanalytics.status.openedHeatMap" datepicker-options="newvenueanalytics.dateOptions" ng-required="true" close-text="Close" />
                </div>
              </div>
              <!-- Floor Select -->
              <div class="col-sm-4">
                <div class="form-group text-box long cell cell-pad">
                  <label class="control-label pull-left">SELECT MAP</label>
                  <select class="form-control" ng-model="newvenueanalytics.map" ng-options="map.name for map in newvenueanalytics.maps" ng-change="newvenueanalytics.mapChange()">
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- Slider -->
              <div class="col-sm-12">
                <br/>
                <br/>
                <label class="control-label pull-left">Select Hour</label>
                <rzslider rz-slider-model="newvenueanalytics.heatmapSlider.value" rz-slider-options="newvenueanalytics.heatmapSlider.options"></rzslider>
              </div>
            </div>
            <div class="row">
              <!-- Map -->
              <div class="col-sm-1">
                <br/>
                <br/>
                <br/>
                <div ng-hide="newvenueanalytics.mapType === 'Dwell'">
                  <label class="control-label pull-left">Heat Scale</label>
                  <br/>
                  <br/>
                  <div id="HeatmapGradiant" class="pull-left" style="height: {{newvenueanalytics.scaleHeight}}px; width: 25%"></div>
                  <div class="pull-right" style="height: {{newvenueanalytics.scaleTextHeight}}px; width: 70%">
                    Max: {{newvenueanalytics.scaleMax}}
                  </div>
                  <div class="pull-right" style="width: 70%">Min: 0</div>
                </div>
              </div>
              <div class="col-sm-10" ng-init="newvenueanalytics.initMap()">
                <br/>
                <div id="mapContainer" style="position: relative; height: {{ newvenueanalytics.mapHeight }}px;">
                  <canvas id="mapBackground" style="position: absolute; left: 0; top: 0; z-index: 0;"></canvas>
                  <canvas id="mapHeatMap" style="position: absolute; left: 0; top: 0; z-index: 1;" ng-hide="newvenueanalytics.mapType === 'Dwell'"></canvas>
                  <canvas id="mapPointMap" style="position: absolute; left: 0; top: 0; z-index: 2;"></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row margin_top dateFix">
          <div class="col-sm-8">
            <div class="form-group text-box long cell cell-pad">
              <label for="startdate" class="control-label pull-left">START DATE</label>
              <input ng-change="newvenueanalytics.refreshApActivity()" ng-click="newvenueanalytics.openApCal()" type="text" class="form-control" max-date="newvenueanalytics.yesterday" name="activityDate" datepicker-popup="{{newvenueanalytics.dateFormat}}" ng-model="newvenueanalytics.activityDate" is-open="newvenueanalytics.status.openedApCal" datepicker-options="newvenueanalytics.dateOptions" ng-required="true" close-text="Close" />
            </div>
            <div class="short cell">
              <button type="button" class="btn btn-date" ng-click="newvenueanalytics.openApCal()">
                <i class="glyphicon glyphicon-calendar"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <h3 class="margin-analytics-xs">Activity By AP</h3>
            <canvas id="line" class="chart chart-line" chart-data="newvenueanalytics.apActivity.data" chart-labels="newvenueanalytics.apActivity.labels" chart-series="newvenueanalytics.apActivity.series" chart-options="newvenueanalytics.apActivity.options" chart-colors="newvenueanalytics.chartColors">
            </canvas>
          </div>
        <!--  <div class="col-sm-4 analytics-block-sm">
            <div class="row margin-analytics-xs margin-analytics-top">
              <h3 class="margin-analytics-xs">Associations</h3>
              <ul class="list-unstyled busy-list">
                <li>Busy AP : {{newvenueanalytics.busyAp.mostBusy.ap}}</li>
                <li>associations : {{newvenueanalytics.busyAp.mostBusy.count}}</li>
                <li>
                  </br>
                </li>
                <li>Least Busy AP : {{newvenueanalytics.busyAp.leastBusy.ap}}</li>
                <li>associations : {{newvenueanalytics.busyAp.leastBusy.count}}</li>
              </ul>
            </div>
          </div>
          -->
        </div>
        <!-- <div class="row">
          <div class="col-sm-10">
            <h3 class="margin-analytics-xs">User Journey (100 user sample)</h3>
            <canvas id="line" class="chart chart-line" chart-data="newvenueanalytics.usrJourney.data" chart-labels="newvenueanalytics.usrJourney.labels" chart-options="newvenueanalytics.usrJourney.options" chart-series="newvenueanalytics.usrJourney.series" chart-colors="newvenueanalytics.chartColors">
            </canvas>
          </div>
        </div> -->
      </div>
      <div ng-show="newvenueanalytics.tab === 'users'">
        <div class="form-row margin_top dateFix">
          <div class="form-group text-box long cell cell-pad">
            <label for="startdate" class="control-label pull-left">START DATE</label>
            <input ng-change="newvenueanalytics.refresh()" ng-click="newvenueanalytics.openUserCalSt()" type="text" class="form-control" min-date="newvenueanalytics.minDate" max-date="newvenueanalytics.endtime" name="startDate" datepicker-popup="{{newvenueanalytics.dateFormat}}" ng-model="newvenueanalytics.starttime" is-open="newvenueanalytics.status.openedUsrSt" datepicker-options="newvenueanalytics.dateOptions" ng-required="true" close-text="Close" />
          </div>
          <div class="short cell">
            <button type="button" class="btn btn-date" ng-click="newvenueanalytics.openUserCalSt()">
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </div>
          <div class="form-group text-box long cell cell-pad">
            <label for="endtime" class="control-label pull-left">END DATE</label>
            <input ng-change="newvenueanalytics.refresh()" ng-click="newvenueanalytics.openUserCalEnd()" type="text" class="form-control" min-date="newvenueanalytics.starttime" max-date="newvenueanalytics.today" datepicker-popup="{{newvenueanalytics.dateFormat}}" ng-model="newvenueanalytics.endtime" is-open="newvenueanalytics.status.openedUsrEnd" datepicker-options="newvenueanalytics.dateOptions" ng-required="true" close-text="Close" />
          </div>
          <div class="short cell">
            <button type="button" class="btn btn-date" ng-click="newvenueanalytics.openUserCalEnd()">
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="margin_top col-sm-12">
            <div class="row tabrow">
              <div class="col-sm-2">
                <button ng-click="newvenueanalytics.showProfileTable=!newvenueanalytics.showProfileTable" class="btn btn-danger">
                  <i class="fa fa-list" aria-hidden="true" ng-show="!newvenueanalytics.showProfileTable"></i>
                  <i class="fa fa-th-large" aria-hidden="true" ng-show="newvenueanalytics.showProfileTable"></i>
                </button>
                  <button type="button" ng-click="newvenueanalytics.downloadCSV()" class="btn btn-danger">Export</button>
              </div>
              <div class="col-sm-10">
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-addon">
                      <i class="fa fa-search"></i>
                    </span>
                    <input type="text" class="form-control" placeholder="Search Users" ng-model="userSearch">
                  </div>
                </div>
                <div class="col-sm-4">
                  <select class="form-control" ng-model="newvenueanalytics.limit" ng-options="limit for limit in newvenueanalytics.limits">
                    <option value="">Limit Results</option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <select class="form-control" ng-model="activity" ng-options="filter.value as filter.label for filter in newvenueanalytics.activityFilter" ng-change="newvenueanalytics.setLimit(activity)">
                    <option value="">Limit by Activity</option>
                  </select>
                </div>
              </div>
            </div>
            <div ng-if="!newvenueanalytics.showProfileTable" class="animated-class fix">
              <div class="col-md-2 col-sm-3 cardFrame" ng-repeat="user in newvenueanalytics.userProfiles | orderBy:'-logins' | filter:userSearch | limitTo:newvenueanalytics.limit | filter: {isLive :newvenueanalytics.activity || undefined}">

                <div class="col-xs-12 border card">
                  <div class="row">
                    <div ng-show="user.socialdata.provider == 'google'">
                      <div class="col-xs-8 col-xs-offset-2" style="background-image: url(user.socialdata.photos[0].value); background-size : cover;">
                        <div class="liveAlert">
                          <div class="alert alert-success" role="alert" ng-if="user.isLive === 'true'">Online</div>
                          <div class="alert alert-danger" role="alert" ng-if="user.isLive === 'false'">Offline</div>
                        </div>
                      </div>
                    </div>
                    <div ng-show="user.socialdata.provider == 'facebook'">
                      <div class="col-xs-12 bckIMG" style="background-image: url(https://graph.facebook.com/{{user.socialdata.id}}/picture?type=large); background-size : cover;">
                        <div class="liveAlert">
                          <div class="alert alert-success" role="alert" ng-if="user.isLive === 'true'">Online</div>
                          <div class="alert alert-danger" role="alert" ng-if="user.isLive === 'false'">Offline</div>
                        </div>
                      </div>
                    </div>
                    <div ng-show="user.socialdata.provider == 'twitter'">
                      <div class="col-xs-12 bckIMG" style="background-image: url(https://twitter.com/{{user.socialdata.username}}/profile_image?size=original); background-size : cover;">
                        <div class="liveAlert">
                          <div class="alert alert-success" role="alert" ng-if="user.isLive === 'true'">Online</div>
                          <div class="alert alert-danger" role="alert" ng-if="user.isLive === 'false'">Offline</div>
                        </div>
                      </div>
                    </div>
                    <div ng-show="!user.socialdata.provider">
                      <div class="col-xs-12 bckIMG" style="background-image: url(images/usericon.jpg); background-size : cover;">
                        <div class="liveAlert">
                          <div class="alert alert-success" role="alert" ng-if="user.isLive === 'true'">Online</div>
                          <div class="alert alert-danger" role="alert" ng-if="user.isLive === 'false'">Offline</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row rowbtm">
                    <div class="col-xs-12">
                      <div class="row">
                        <a href="#/admin/userprofile/{{newvenueanalytics.venueid}}/{{user.id}}/{{newvenueanalytics.venue.infrastructure.provider}}">Name: {{user.name}}</a>
                      </div>
                      <div class="row">
                        <div class="col-xs-8">
                          <p>Visits: {{user.logins}}</p>
                        </div>
                        <div class="col-xs-4">
                          <a ng-show="user.socialdata.provider == 'facebook'" class="btn btn-primary btn-social-link pull-right" ng-href="{{user.socialdata.profileUrl}}" target="-_blank"><i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i></a>
                          <a ng-show="user.socialdata.provider == 'google'" class="btn btn-danger btn-social-link pull-right" ng-href="https://plus.google.com/u/0/{{user.socialdata.id}}" target="-_blank">
                            <i class="fa fa-google-plus-square fa-2x" aria-hidden="true"></i>
                            </a>
                          <a ng-show="user.socialdata.provider == 'linkedin'" class="btn btn-primary btn-social-link pull-right" ng-href="https://www.linkedin.com/in/{{user.socialdata.id}}" target="-_blank">
                            <i class="fa fa-linkedin-square fa-2x" aria-hidden="true"></i>
                            </a>
                          <a ng-show="user.socialdata.provider == 'twitter'" class="btn btn-primary btn-social-link pull-right" ng-href="https://twitter.com/{{user.socialdata.username}}" target="-_blank">
                            <i class="fa fa-twitter-square fa-2x" aria-hidden="true"></i>
                            </a>
                          <a ng-show="!user.socialdata.provider" class="btn btn-warning btn-social-link pull-right" ng-href="https://twitter.com/{{user.socialdata.username}}" target="-_blank">
                              <i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i>
                              </a>
                        </div>
                      </div>
                      <div class="row">
                        <ul class="list-group tagList">
                          <li ng-repeat="tag in user.tag" class="list-group-item list-group-item-success">{{tag}}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div ng-if="newvenueanalytics.showProfileTable" class="animated-class">
              <table class="table table-responsive eventsTable">
                <thead>
                  <tr>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Visits</th>
                    <th>Social Media Profile</th>
                    <th>Tags</th>
                  </tr>
                </thead>
                <tbody ng-repeat="user in newvenueanalytics.userProfiles | orderBy:'-logins' | filter:userSearch | limitTo:newvenueanalytics.limit | filter: {isLive :activity || undefined}">
                  <tr>
                    <td class="profileList">
                      <div ng-show="user.socialdata.provider == 'google'">
                        <a href="#/admin/userprofile/{{newvenueanalytics.venueid}}/{{user.id}}/{{newvenueanalytics.venue.infrastructure.provider}}">
                          <img ng-src="{{user.socialdata.photos[0].value}}" class="img-responsive" />
                        </a>
                      </div>
                      <div ng-show="user.socialdata.provider == 'facebook'">
                        <a href="#/admin/userprofile/{{newvenueanalytics.venueid}}/{{user.id}}/{{newvenueanalytics.venue.infrastructure.provider}}">
                          <img ng-src="https://graph.facebook.com/{{user.socialdata.id}}/picture?type=large" class="scale-down" />
                        </a>
                      </div>
                      <div ng-show="user.socialdata.provider == 'twitter'">
                        <a href="#/admin/userprofile/{{newvenueanalytics.venueid}}/{{user.id}}/{{newvenueanalytics.venue.infrastructure.provider}}">
                          <img ng-src="https://twitter.com/{{user.socialdata.username}}/profile_image?size=original" class="img-responsive" />
                        </a>
                      </div>
                      <div ng-show="!user.socialdata.provider">
                        <img ng-src="images/usericon.jpg" class="img-responsive" />
                      </div>
                    </td>
                    <td>
                      <a href="#/admin/userprofile/{{newvenueanalytics.venueid}}/{{user.id}}/{{newvenueanalytics.venue.infrastructure.provider}}">
                        {{user.name}}
                      </a>
                      <ul class="list-group tagList">
                        <li class="list-group-item list-group-item-success" ng-if="user.isLive === 'true'">Online</li>
                        <li class="list-group-item list-group-item-danger" ng-if="user.isLive === 'false'">Offline</li>
                      </ul>
                    </td>
                    </td>
                    <td>
                      <p>Visits: {{user.logins}}</p>
                    </td>
                    <td>
                      <div ng-show="user.socialdata.provider == 'facebook'">
                        <a class="btn btn-primary btn-social-link" ng-href="{{user.socialdata.profileUrl}}" target="-_blank">
                          <i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div ng-show="user.socialdata.provider == 'google'">
                        <a class="btn btn-danger btn-social-link" ng-href="https://plus.google.com/u/0/{{user.socialdata.id}}" target="-_blank">
                          <i class="fa fa-google-plus-square fa-2x" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div ng-show="user.socialdata.provider == 'linkedin'">
                        <a class="btn btn-primary btn-social-link" ng-href="https://www.linkedin.com/in/{{user.socialdata.id}}" target="-_blank">
                          <i class="fa fa-linkedin-square fa-2x" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div ng-show="user.socialdata.provider == 'twitter'">
                        <a class="btn btn-primary btn-social-link" ng-href="https://twitter.com/{{user.socialdata.username}}" target="-_blank">
                          <i class="fa fa-twitter-square fa-2x" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div ng-show="!user.socialdata.provider">
                        <a class="btn btn-warning btn-social-link" ng-href="" target="-_blank">
                        <i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i>
                        </a>
                      </div>
                    </td>
                    <td>
                      <ul class="list-group tagList">
                        <li ng-repeat="tag in user.tag" class="list-group-item list-group-item-success">{{tag}}</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
