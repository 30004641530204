(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name eventcreation.controller:EventcreationCtrl
   *
   * @description
   *
   */
  angular
    .module('eventcreation')
    .controller('EventcreationCtrl', EventcreationCtrl);

  function EventcreationCtrl($rootScope, $state, Api, $log, Auth, baseurl, baseendpoint, $modal, $localStorage, Cloudservices) {
    var vm = this;

    vm.newEvent = {};
    vm.openAuthList = [];
    vm.newEvent.optCatList = [];
    vm.newEvent.splashDetails = {};
    vm.newEvent.zoneList=[];
    vm.newEvent.org = '';
    vm.storage = $localStorage;
    vm.ssidExists = false;
    vm.marketingOn = false;
    vm.nameCheckAP = [];
    vm.ssidCount = false;
    vm.ssidCountAP = [];
    vm.newEvent.networktype = '';
    vm.networkTypes = [];
    vm.splashPageTypes = [];
    vm.splashType = 0;
    vm.spVideo = false;
    vm.spSocial = false;
    vm.spAmount = false;
    vm.spEmail = false;
    vm.spLink = false;
    vm.spRegister = false;
    vm.defaultFbLikeUrl = true;
    vm.addlikebutton = false;
    vm.customFbLikeUrl =  '';
    vm.provider = '';
    vm.selectedSurvey = false;
    vm.numberOfQuestionsTotal = 5;
    vm.numberOfQuestions = 0;
    vm.surveyObjects = [];
    vm.surveyId = 0;
    vm.eventnames = [];
    vm.thesamename = false;
    vm.venues = [];
    vm.netPassed = false;
    vm.setupPassed = false;
    vm.splashPassed = false;

    if (Auth.getAuthData()) {
      vm.newEvent.org = Auth.getAuthData().operator.organisation;
    }

    // Important!!! remove this sesction once magnet county feature is complete

    vm.isMagnet = false;
    var magnetID = 9104885; //add real magnetID

    if (vm.newEvent.org === magnetID) {
      vm.isMagnet = true;
    }

    // Start new event updates

    // state changes

    // redirects on page to load to load correct start page
    // if ($state.current.name === 'admin.eventcreation') {
    //   $state.go('admin.eventcreation.evcsetup');
    // }

    // handles navigation away from incomplete form
    vm.allowStateChange = false;
    $rootScope.$on('$stateChangeStart',
      function (event, toState, toParams, fromState) {
        console.log('state called');
        console.log(toState);
        console.log(vm.allowStateChange);
        if (vm.allowStateChange === true) {
          return;
        } else {
          if (fromState.name === 'admin.eventcreation.evcconfirm') {
            if (toState.name === 'admin.eventcreation.evcsetup' || toState.name === 'admin.eventcreation.evcnetwork' || toState.name === 'admin.eventcreation.evcsplash') {
              // vm.allowStateChange = true;
              // $state.go(toState.name);
              return;
            }
          } else if (fromState.name === 'admin.eventcreation.evcnetwork' && toState.name === 'admin.eventcreation.evcsetup') {
            // vm.allowStateChange = true;
            // $state.go(toState.name);
            return;
          } else if ($state.current.name === 'admin.eventcreation.evcsplash' && toState.name === 'admin.eventcreation.evcsetup' || toState.name === 'admin.eventcreation.evcnetwork') {
            return;
          } else if (toState.name !== 'admin.eventcreation.evcsetup' && toState.name !== 'admin.eventcreation.evcnetwork' && toState.name !== 'admin.eventcreation.evcsplash' && toState.name !== 'admin.eventcreation.evcconfirm') {
            console.log(toState.name);
            console.log('called');
            event.preventDefault();
            var modalInstance = $modal.open({
              templateUrl: 'eventcreation/views/cancelmodal.tpl.html',
              controller: 'CancelmodalCtrl as cancelModal'
            });
            modalInstance.result.then(function () {
              vm.allowStateChange = true;
              $state.go(toState.name);
            }, function () {
              vm.allowStateChange = false;
            });
          }

        }
        vm.allowStateChange = false;
      });
    // end state changes

    /* setp calls
     * ensure required resources are available
     */

    function getNetworkTypes(venueid) {
      Api.getNetworkTypes(venueid).then(function (response) {
        $log.info(response.data.networkTypes);
        vm.networkTypes = response.data.networkTypes;
      }, function (err) {
        $log.info(err);
      });
    }

    // venue setup
    Api.getVenues().success(function (data) {
      vm.venues = data.venues;
      if ($rootScope.activeUser.role === 2) {
        vm.newEvent.venueId = $rootScope.activeUser.details.venue;
        Cloudservices.getCloudServiceByName(vm.newEvent.venueId, 'twilio').then(function (resp) {
          if(resp.data.rowCount >= 1){
            vm.hasTwilio = true;
          }
        }, function (err) {
          console.log(err);
        });
        Api.getInfrastructures(vm.newEvent.venueId).then(function (res) {
          if(res.data.rowCount > 0) {
            var infra = res.data.infrastructures[0];
            vm.provider = JSON.parse(infra.provider);
          } else {
            vm.provider = {};
          }
          getSplashPageTypes();
        }, function (err) {
          console.log(err);
        });
      } else {
        vm.newEvent.venueId = data.venues[0].id;
        Cloudservices.getCloudServiceByName(vm.newEvent.venueId, 'twilio').then(function (resp) {
          if(resp.data.rowCount >= 1){
            vm.hasTwilio = true;
          }
        }, function (err) {
          console.log(err);
        });
        Api.getInfrastructures(vm.newEvent.venueId).then(function (res) {
          if(res.data.rowCount > 0) {
            var infra = res.data.infrastructures[0];
            vm.provider = JSON.parse(infra.provider);
          } else {
            vm.provider = {};
          }
        }, function (err) {
          console.log(err);
        });
      }
      vm.getZones(vm.newEvent.venueId);
      $log.info(vm.venues);
    }).error(function () {
      $log.info('get venues error');
    });
    // done venue setup

    // categories setup
    function getCategories() {
      Api.getCategories(vm.provider).success(function (data) {
        vm.optCatList = data.categories;
        $log.info(vm.optCatList);
      }).error(function () {
        $log.info('get categories error');
      });
    }
    // end categories setup

    // social logins setup
    function getSocialLogins() {
      Api.getSocialLogins(vm.provider).success(function (data) {
        vm.anonymousLogin = undefined;
        for (var i = 0; i < data.socialLoginTypes.length; i++) {
          if(data.socialLoginTypes[i].name.toLowerCase() === 'connect2fi_facebook')
          {
            data.socialLoginTypes.splice(i, 1);
            i--;
          }
          if(data.socialLoginTypes[i].name.toLowerCase() === 'anonymous') {
            vm.anonymousLogin = data.socialLoginTypes[i];
            vm.anonymousLogin.on = false;
            data.socialLoginTypes.splice(i, 1);
            i--;
          }
        }
        vm.socialLogins = data.socialLoginTypes;
        $log.info('social');
        $log.info(vm.socialLogins);
      }).error(function () {
        $log.info('get social error');
      });
    }
    // end social logins setup

    //get splash page types
    function getSplashPageTypes() {
      Api.getSplashPageTypes(vm.provider).success(function (data) {
        vm.splashPageTypes = data.splashTypes;
        getSocialLogins();
        getCategories();
        $log.info(vm.splashPageTypes);
      }).error(function () {
        $log.info('get splash page types error');
      });
    }
    //end get splash page types

    //setSurvey
    function addEmptySurveyObjects() {
      for (var i = 0; i < vm.numberOfQuestionsTotal; i++) {
        var surveyObj = {
          'id': i,
          'show': false
        };
        vm.surveyObjects.push(surveyObj);
      }
    }

    /*
    {"survey":[{"question":"Do you like apples?","answers":[{"answer":"yes","count":0},{"answer":"no","count":0}]},{"question":"Do you like pears?","answers":[{"answer":"yes","count":0},{"answer":"no","count":4}]},{"question":"Do you like kiwi?","answers":[{"answer":"yes","count":0},{"answer":"no","count":4}]}]}
    */
    vm.noend = true;

    vm.noEndChanged = function () {

    }

    addEmptySurveyObjects();

    vm.addQuestion = function () {
      if (vm.numberOfQuestions < vm.numberOfQuestionsTotal) {
        vm.surveyObjects[vm.numberOfQuestions].show = true;
        vm.numberOfQuestions = vm.numberOfQuestions + 1;
      }
    };

    vm.removeQuestion = function () {
      if (vm.numberOfQuestions > 0) {
        vm.numberOfQuestions = vm.numberOfQuestions - 1;
        vm.surveyObjects[vm.numberOfQuestions].show = false;
      }
    };

    // accesspoint setup
    vm.getAccessPoints = function (id) {
      vm.newEvent.venueId = id;
      vm.pointList = [];
      Api.getAccessPoints(id).success(function (data) {
        vm.pointList = data.apoints;
        $log.info(vm.pointList);
      }).error(function () {
        $log.info('get ap error');
      });
      $log.info(vm.newEvent);
    };

    vm.setSurveyObject = function () {
      var details = [];
      //add survey
      for (var i = 0; i < vm.numberOfQuestions; i++) {
        if (vm.surveyObjects[i].survey !== null && vm.surveyObjects[i].survey && vm.surveyObjects[i].survey.question !== null && vm.surveyObjects[i].survey.question) {
          var answers = [];
          if (vm.surveyObjects[i].survey.answer1 !== null && vm.surveyObjects[i].survey.answer1) {
            answers.push({
              'answer': vm.surveyObjects[i].survey.answer1,
              'count': 0
            });
          }
          if (vm.surveyObjects[i].survey.answer2 !== null && vm.surveyObjects[i].survey.answer2) {
            answers.push({
              'answer': vm.surveyObjects[i].survey.answer2,
              'count': 0
            });
          }
          if (vm.surveyObjects[i].survey.answer3 !== null && vm.surveyObjects[i].survey.answer3) {
            answers.push({
              'answer': vm.surveyObjects[i].survey.answer3,
              'count': 0
            });
          }
          if (vm.surveyObjects[i].survey.answer4 !== null && vm.surveyObjects[i].survey.answer4) {
            answers.push({
              'answer': vm.surveyObjects[i].survey.answer4,
              'count': 0
            });
          }
          var surveyObj = {
            'question': vm.surveyObjects[i].survey.question,
            'answers': answers
          };
          details.push(surveyObj);
        }
      }
      var eventName = vm.newEvent.name;
      var surveyObj = {
        survey: details
      };
      var survey = {
        name: eventName,
        isActive: true,
        details: surveyObj
      };
      return survey;
    };
    // end survey fucntionality

    // zone setup
    vm.getZones = function (venueid) {
      Api.getInfrastructures(venueid).then(function (res) {
        var infra = res.data.infrastructures[0];
        vm.provider = JSON.parse(infra.provider);
        getSplashPageTypes();
        console.log(vm.provider);
      }, function (err) {
        console.log(err);
      });
      vm.zoneList = [];
      Api.getZones(venueid).success(function (data) {
        vm.zoneList = data.zones;
        $log.info(vm.zoneList);
      }).error(function () {
        $log.info('get zone error');
      });
      getNetworkTypes(venueid);
      $log.info(vm.newEvent);
    };
    // end zones setup

    vm.setNetworkType = function (typeid) {
      vm.newEvent.networktype = typeid;
      vm.newEvent.optCatList = [];
      Api.getNetworkType(typeid, vm.newEvent.venueId).then(function (response) {
        $log.info(response);
        if (response.data.networkType.networkStrategies.length > 0) {
          console.log(response.data.networkType.networkStrategies.length);
          for (var i = 0; i < response.data.networkType.networkStrategies.length; i++) {
            vm.newEvent.optCatList.push(response.data.networkType.networkStrategies[i].id);
          }
        }
      }, function (err) {
        $log.info(err);
      });
    };
    // end setup calls

    //set Splash Page Type
    vm.setSplashPageType = function (type) {
      console.log(type);
      vm.spVideo = false;
      vm.spSocial = false;
      vm.spAmount = false;
      vm.spEmail = false;
      vm.spLink = false;
      vm.spRegister = false;
      vm.conference = false;
      var name = type.name;
      switch (name) {
      case 'Video':
        vm.spVideo = true;
        break;
      case 'Social':
        vm.spSocial = true;
        break;
      case 'Email':
        vm.spEmail = true;
        break;
      case 'Link':
        vm.spLink = true;
        break;
      case 'Payment':
        vm.spAmount = true;
        break;
      case 'Register':
        vm.spRegister = true;
        break;
      case 'Conference':
        vm.spEmail = true;
        vm.conference = true;
        break;
      }
    };

    // Time And Date
    vm.today = function () {
      var newDate = new Date();
      vm.dtStart = new Date(newDate);
      vm.dtStart.setMinutes(newDate.getMinutes() + 10);
      vm.dtEnd = new Date(vm.dtStart);
      vm.dtEnd.setDate(vm.dtStart.getDate() + 1);
      vm.minDate = newDate;
    };

    vm.today();

    vm.dateOptions = {
      formatYear: 'yy',
      startingDay: 0
    };

    vm.dateFormat = 'dd-MMMM-yyyy';

    vm.status = {
      openedSt: false,
      openedEnd: false
    };

    vm.openCalSt = function () {
      vm.status.openedSt = true;
    };

    vm.openCalEnd = function () {
      vm.status.openedEnd = true;
    };

    vm.hstep = 1;
    vm.mstep = 1;

    vm.setTime = function () {
      vm.startTime = new Date(vm.dtStart);
      vm.endTime = new Date(vm.dtEnd);
    };

    vm.setTime();

    vm.eventTime = function () {
      vm.eventStart = new Date(vm.dtStart);
      vm.eventStart.setHours(vm.startTime.getHours());
      vm.eventStart.setMinutes(vm.startTime.getMinutes());
      vm.eventStart.setSeconds(0);

      vm.eventEnd = new Date(vm.dtEnd);
      vm.eventEnd.setHours(vm.endTime.getHours());
      vm.eventEnd.setMinutes(vm.endTime.getMinutes());
      vm.eventEnd.setSeconds(0);
    };

    // end Time and Date

    // upload Images

    // end upload Images
    vm.eventImageAdded = false;
    vm.venueLogoAdded = false;
    vm.bckgrndImgAdded = false;

    vm.eventImage = '';
    vm.venueLogo = '';
    vm.bckgrndImg = '';

    vm.eventImageID = '';
    vm.venueLogoID = '';
    vm.bckgrndImgID = '';

    vm.offerImage1ID = '';
    vm.offerImage1Uri = '';
    vm.offerImage1Link = '';
    vm.offerImage1Added = false;

    vm.offerImage2ID = '';
    vm.offerImage2Uri = '';
    vm.offerImage2Link = '';
    vm.offerImage2Added = false;

    vm.offerImage3ID = '';
    vm.offerImage3Uri = '';
    vm.offerImage3Link = '';
    vm.offerImage3Added = false;

    vm.offerImage4ID = '';
    vm.offerImage4Uri = '';
    vm.offerImage4Link = '';
    vm.offerImage4Added = false;

    vm.offerImage5ID = '';
    vm.offerImage5Uri = '';
    vm.offerImage5Link = '';
    vm.offerImage5Added = false;

    vm.offerImage6ID = '';
    vm.offerImage6Uri = '';
    vm.offerImage6Link = '';
    vm.offerImage6Added = false;

    vm.uploadEventImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.eventImageAdded = true;
        vm.eventImage = image.imageUri;
        vm.eventImageID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectEventImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.eventImageAdded = true;
        vm.eventImage = thisImage.imageuri;
        vm.eventImageID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadLogoImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.venueLogoAdded = true;
        vm.venueLogo = image.imageUri;
        vm.venueLogoID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectLogoImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.venueLogoAdded = true;
        vm.venueLogo = thisImage.imageuri;
        vm.venueLogoID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadBckgrndImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.bckgrndImgAdded = true;
        vm.bckgrndImg = image.imageUri;
        vm.bckgrndImgID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectBckgrndImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.bckgrndImgAdded = true;
        vm.bckgrndImg = thisImage.imageuri;
        vm.bckgrndImgID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadOfferImage1 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage1Added = true;
        vm.offerImage1Uri = image.imageUri;
        vm.offerImage1ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage1 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage1Added = true;
        vm.offerImage1Uri = thisImage.imageuri;
        vm.offerImage1ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    /// offer 2

    vm.uploadOfferImage2 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage2Added = true;
        vm.offerImage2Uri = image.imageUri;
        vm.offerImage2ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage2 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage2Added = true;
        vm.offerImage2Uri = thisImage.imageuri;
        vm.offerImage2ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    ///offer 3

    vm.uploadOfferImage3 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage3Added = true;
        vm.offerImage3Uri = image.imageUri;
        vm.offerImage3ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage3 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage3Added = true;
        vm.offerImage3Uri = thisImage.imageuri;
        vm.offerImage3ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    /// offer 4

    vm.uploadOfferImage4 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage4Added = true;
        vm.offerImage4Uri = image.imageUri;
        vm.offerImage4ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage4 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage4Added = true;
        vm.offerImage4Uri = thisImage.imageuri;
        vm.offerImage4ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    //offer 5

    vm.uploadOfferImage5 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage5Added = true;
        vm.offerImage5Uri = image.imageUri;
        vm.offerImage5ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage5 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage5Added = true;
        vm.offerImage5Uri = thisImage.imageuri;
        vm.offerImage5ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    //offer 6

    vm.uploadOfferImage6 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.offerImage6Added = true;
        vm.offerImage6Uri = image.imageUri;
        vm.offerImage6ID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectOfferImage6 = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.newEvent.venueId
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.offerImage6Added = true;
        vm.offerImage6Uri = thisImage.imageuri;
        vm.offerImage6ID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    // end upload Images

    //get eventsnames
    Api.getVenues().success(function (data) {
      vm.venues = data.venues;
      $log.info(vm.venues);
    }).error(function () {
      $log.info('get venues error');
    });

    function getEvents() {
      vm.events = [];
      vm.eventnames = [];
      vm.eventname = '';
      if ($rootScope.activeUser.role === 2) {
        Api.getAllEventsByVenue(parseInt($rootScope.activeUser.details.venue)).then(function (res) {
          vm.events = res.data.events;
          vm.limit = vm.events.length;
          vm.all = vm.events.length;
          for (var i = 0; i < vm.venues.length; i++) {
            for (var j = 0; j < vm.events.length; j++) {
              if (vm.events[j].venue === vm.venues[i].id) {
                vm.events[j].venueName = vm.venues[i].venuetitle;
              }
            }
          }
        }, function (err) {
          console.log(err);
        });
      } else {
        Api.getAllEvents().success(function (data) {
          vm.events = data.events;
          vm.limit = vm.events.length;
          vm.all = vm.events.length;
          for (var i = 0; i < vm.venues.length; i++) {
            for (var j = 0; j < vm.events.length; j++) {
              var nameofEvent = vm.events[j].name;
              vm.eventnames.push(nameofEvent);
            }
          }

        }).error(function () {
          $log.info('get events error');
        });
      }
    }
    getEvents();
    // state change steps
    vm.nextStep = function (data, state) {
      var nameerror = false;
      if ($state.current.name === 'admin.eventcreation.evcsetup') {
        if (vm.eventnames.indexOf(vm.newEvent.name)> -1) {
          vm.thesamename = true;
          nameerror = true;
        }
        var timeError = false,
          now = new Date(),
          hour = 3600000,
          modalInstance;
        vm.stepOne = true;
        vm.stTimeInPast = false;
        vm.endTimeInPast = false;
        vm.endBeforeStart = false;
        vm.dateError = false;
        vm.eventTime();
        if (!vm.dtStart || !vm.dtEnd) {
          timeError = true;
          vm.dateError = true;
        }

        if (vm.eventStart.getTime() < now.getTime()) {
          timeError = true;
          vm.stTimeInPast = true;
        }

        if (vm.eventEnd.getTime() < now.getTime()) {
          timeError = true;
          vm.endTimeInPast = true;
        }

        if (vm.eventEnd.getTime() < vm.eventStart.getTime()) {
          timeError = true;
          vm.endBeforeStart = true;
        }
        // && vm.newEvent.accessPointList.length >= 1
        if (!data.setUp.required && vm.eventImageAdded && !timeError && !nameerror) {
          if (vm.eventEnd.getTime() - vm.eventStart.getTime() < hour) {
            modalInstance = $modal.open({
              templateUrl: 'eventcreation/views/eventtimemodal.tpl.html',
              controller: 'CancelmodalCtrl as cancelModal'
            });

            modalInstance.result.then(function () {

              vm.setupPassed = true;
              vm.allowStateChange = true;
              $state.go(state);
            }, function () {
            });
          } else {
            vm.setupPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          }
        }
      }

      if ($state.current.name === 'admin.eventcreation.evcnetwork') {
        vm.stepTwo = true;
        var thisList = {
          zones: vm.newEvent.zoneList,
          ssidname: vm.newEvent.networkName
        };
        Api.checkSSIDName(vm.eventStart.getTime(), vm.eventEnd.getTime(), thisList).then(function (resp) {
          Api.checkEventCount(vm.eventStart.getTime(), vm.eventEnd.getTime(), thisList).then(function () {
            if (!data.network.required && !data.network.pattern && vm.newEvent.zoneList.length >= 1) {
              vm.netPassed = true;
              vm.allowStateChange = true;
              $state.go(state);
            }
          }, function (err) {
            vm.ssidCount = true;
            vm.ssidCountAP = err.data.accesspoints;
          });
        }, function (err) {
          vm.ssidExists = true;
          vm.nameCheckAP = err.data.accesspoints;
          Api.checkEventCount(vm.eventStart.getTime(), vm.eventEnd.getTime(), thisList).then(function () {

          }, function (err) {
            vm.ssidCount = true;
            vm.ssidCountAP = err.data.accesspoints;
          });
        });

      }

      if ($state.current.name === 'admin.eventcreation.evcsplash') {
        vm.stepThree = true;
        var thisSplashType = vm.splashType.name;
        if (!data.splash.required && vm.bckgrndImgAdded && vm.splashType.id > 0) {
          // console.log(vm.newEvent.videoUrl);
          // console.log(vm.splashType.length);

          if (thisSplashType === 'Social' && vm.openAuthList.length >= 1) {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else if (thisSplashType === 'Video' && vm.newEvent.splashDetails.videoUrl.length > 0) {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else if (thisSplashType === 'Payment' && vm.newEvent.splashDetails.amount > 0 && vm.newEvent.splashDetails.amount > 0) {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          }
        }
      }
      vm.allowStateChange = false;
    };

    vm.nameCheckResult = function () {
      var modalMessage = {
        messageHeader: 'Network Name Error',
        messageBody: 'Only one instance of a network name can run in a given time period, the access points below have already been assigned "' + vm.newEvent.networkName + '" for the given time period',
        accesspoints: vm.nameCheckAP
      };

      var modalInstance = $modal.open({
        templateUrl: 'eventcreation/views/ssidchecks.tpl.html',
        controller: 'SsidchecksCtrl as modalService',
        resolve: {
          message: function () {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function () {});

    };

    vm.ssidCountResult = function () {
      var modalMessage = {
        messageHeader: 'Network Limit Error',
        messageBody: 'A maximum of seven event can run simultainiously, the access points below will exceed this limit and cannot be created, remove them to cntinue',
        accesspoints: vm.ssidCountAP
      };

      var modalInstance = $modal.open({
        templateUrl: 'eventcreation/views/ssidchecks.tpl.html',
        controller: 'SsidchecksCtrl as modalService',
        resolve: {
          message: function () {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function () {});

    };

    // end state change

    // bandwidth
    vm.priceSlider = {
      value: 2,
      options: {
        floor: 0,
        ceil: 50,
        translate: function (value) {
          return value + ' MB';
        }
      }
    };
    // end bandwidth

    // open Auth
    vm.addOpenAuth = function (value) {
      if (vm.openAuthList.indexOf(value) > -1) {
        vm.openAuthList.splice(vm.openAuthList.indexOf(value), 1);
      } else {
        vm.openAuthList.push(value);
      }
    };
    // end openauth

    vm.cancelCreate = function () {
      $state.go('admin.events');
    };

    vm.setVenueDetails = function () {
      var loopIndex;
      var detailsJson;
      vm.venue = {};
      for(loopIndex = 0; loopIndex < vm.venues.length;loopIndex++) {
        if(vm.newEvent.venueId === vm.venues[loopIndex].id) {
          if (vm.venues[loopIndex].details) {
            if(typeof vm.venues[loopIndex].details === 'string'){
              detailsJson = JSON.parse(vm.venues[loopIndex].details);
            } else {
              detailsJson = vm.venues[loopIndex].details;
            }
            if (detailsJson.fbpage) {
               vm.venue.customFbLikeUrl = detailsJson.fbpage;
            } else {
              vm.defaultFbLikeUrl = false;
              vm.customFbLike = true;
            }
          }
        }
      }
    };

    // create event
    vm.createEvent = function () {
      vm.eventTime();
      var thisSplash = vm.newEvent.splashDetails;

      if (thisSplash.amount) {
        thisSplash.amount = thisSplash.amount * 100;
      }
      if(vm.defaultFbLikeUrl && vm.addlikebutton){
        thisSplash.customFbLikeUrl = vm.venue.customFbLikeUrl;
      } else if (!vm.addlikebutton) {
        thisSplash.customFbLikeUrl = undefined;
      }

      if(vm.marketingOn)
      {
        thisSplash.marketingOn = true;
        thisSplash.ads = [];
        thisSplash.adLinks = [];
        thisSplash.adURIs = [];
        if(vm.offerImage1ID != '' )
        {
          thisSplash.ads.push(vm.offerImage1ID);
          thisSplash.adURIs.push(vm.offerImage1Uri);
          thisSplash.adLinks.push(vm.offerImage1Link);
        }
        if(vm.offerImage2ID != '' )
        {
          thisSplash.ads.push(vm.offerImage2ID);
          thisSplash.adURIs.push(vm.offerImage2Uri);
          thisSplash.adLinks.push(vm.offerImage2Link);
        }
        if(vm.offerImage3ID != '' )
        {
          thisSplash.ads.push(vm.offerImage3ID);
          thisSplash.adURIs.push(vm.offerImage3Uri);
          thisSplash.adLinks.push(vm.offerImage3Link);
        }
        if(vm.offerImage4ID != '' )
        {
          thisSplash.ads.push(vm.offerImage4ID);
          thisSplash.adURIs.push(vm.offerImage4Uri);
          thisSplash.adLinks.push(vm.offerImage4Link);
        }
        if(vm.offerImage5ID != '' )
        {
          thisSplash.ads.push(vm.offerImage5ID);
          thisSplash.adURIs.push(vm.offerImage5Uri);
          thisSplash.adLinks.push(vm.offerImage5Link);
        }
        if(vm.offerImage6ID != '' )
        {
          thisSplash.ads.push(vm.offerImage6ID);
          thisSplash.adURIs.push(vm.offerImage6Uri);
          thisSplash.adLinks.push(vm.offerImage6Link);
        }
      }

      if (thisSplash.period) {
        thisSplash.period = thisSplash.period * 3600000;
      }

      var surveyObj = vm.setSurveyObject();

      if (vm.anonymousLogin !== undefined) {
        if(vm.anonymousLogin.on) {
          vm.addOpenAuth(vm.anonymousLogin.id);
        }
      }


      var thisBandwidth = vm.priceSlider.value * 1000,
        thisEvent = {
          orgId: vm.newEvent.org,
          venueId: vm.newEvent.venueId,
          name: vm.newEvent.name,
          description: vm.newEvent.description,
          eventimage: vm.eventImageID,
          ssidname: vm.newEvent.networkName,
          starttime: vm.eventStart,
          endtime: (vm.noend) ? new Date(253402300740905):vm.eventEnd,
          accesspoints: vm.newEvent.accessPointList,
          zones: vm.newEvent.zoneList,
          categories: vm.newEvent.optCatList,
          trafficlimitsta: thisBandwidth,
          splashpagebackgroundimage: vm.bckgrndImgID,
          splashpagelogoimage: vm.venueLogoID,
          sociallogintypes: vm.openAuthList,
          redirectionurl: vm.newEvent.redirectionURL,
          splashDetails: thisSplash,
          details: vm.newEvent.details,
          networkTypeId: vm.newEvent.networkType,
          splashTypeId: vm.splashType.id,
          survey: surveyObj
        };

      $log.info(thisEvent);

      Api.createEvent(thisEvent, vm.newEvent.venueId).success(function () {
        vm.allowStateChange = true;
        vm.storage.alerts.push({
          messageType: 'event',
          message: 'New Event Created'
        });
        $state.go('admin.events');
      }).error(function (error) {
        $log.info(error);
      });
    };
  }
}());
